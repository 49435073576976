/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {
  Benefit,
  McdType,
  McdSubtype,
  SlideType,
  NjmmisCheckResult,
} from './globalTypes' // ====================================================
// GraphQL query operation: ApplicationForView
// ====================================================

import {ApplicationForViewVariables as ApplicationForViewVariablesOrig} from '../types/ApplicationForView'
import {ApplicationForView as ApplicationForViewOrig} from '../types/ApplicationForView'
import {ApplicationForView_application as ApplicationForView_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication as ApplicationForView_application_RyanWhiteApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_accountOnDateOfService as ApplicationForView_application_RyanWhiteApplication_accountOnDateOfServiceOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_benefitOutcomes as ApplicationForView_application_RyanWhiteApplication_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_benefitOutcomes_application as ApplicationForView_application_RyanWhiteApplication_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_benefitOutcomes_person as ApplicationForView_application_RyanWhiteApplication_benefitOutcomes_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_documentFiles as ApplicationForView_application_RyanWhiteApplication_documentFilesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_documentFiles_documents as ApplicationForView_application_RyanWhiteApplication_documentFiles_documentsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_documents as ApplicationForView_application_RyanWhiteApplication_documentsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_documents_application as ApplicationForView_application_RyanWhiteApplication_documents_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_documents_person as ApplicationForView_application_RyanWhiteApplication_documents_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_documents_files as ApplicationForView_application_RyanWhiteApplication_documents_filesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_householdMembers as ApplicationForView_application_RyanWhiteApplication_householdMembersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_householdMembers_person as ApplicationForView_application_RyanWhiteApplication_householdMembers_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_householdMembers_person_assets as ApplicationForView_application_RyanWhiteApplication_householdMembers_person_assetsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_householdMembers_person_documentFiles as ApplicationForView_application_RyanWhiteApplication_householdMembers_person_documentFilesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_householdMembers_person_deductions as ApplicationForView_application_RyanWhiteApplication_householdMembers_person_deductionsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources as ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSourcesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession as ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession_esignWizards as ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes as ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application as ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_SlideApplication as ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication as ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication as ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers as ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession as ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards as ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_assignedTo as ApplicationForView_application_RyanWhiteApplication_assignedToOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person as ApplicationForView_application_RyanWhiteApplication_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_accounts as ApplicationForView_application_RyanWhiteApplication_person_accountsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_assets as ApplicationForView_application_RyanWhiteApplication_person_assetsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_openHouseholdMemberApplications as ApplicationForView_application_RyanWhiteApplication_person_openHouseholdMemberApplicationsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_openApplications as ApplicationForView_application_RyanWhiteApplication_person_openApplicationsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_openApplications_RyanWhiteApplication as ApplicationForView_application_RyanWhiteApplication_person_openApplications_RyanWhiteApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_openApplications_SlideApplication as ApplicationForView_application_RyanWhiteApplication_person_openApplications_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_openApplications_CharityCareApplication as ApplicationForView_application_RyanWhiteApplication_person_openApplications_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_openApplications_MedicaidApplication as ApplicationForView_application_RyanWhiteApplication_person_openApplications_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_documentFiles as ApplicationForView_application_RyanWhiteApplication_person_documentFilesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_relationships as ApplicationForView_application_RyanWhiteApplication_person_relationshipsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson as ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPersonOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_assets as ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_assetsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_deductions as ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_deductionsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources as ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSourcesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession as ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards as ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes as ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application as ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication as ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication as ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication as ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers as ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession as ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards as ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_deductions as ApplicationForView_application_RyanWhiteApplication_person_deductionsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_incomeSources as ApplicationForView_application_RyanWhiteApplication_person_incomeSourcesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession as ApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards as ApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes as ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application as ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication as ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication as ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication as ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers as ApplicationForView_application_RyanWhiteApplication_person_phoneNumbersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession as ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards as ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication as ApplicationForView_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_accountOnDateOfService as ApplicationForView_application_SlideApplication_accountOnDateOfServiceOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_benefitOutcomes as ApplicationForView_application_SlideApplication_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_benefitOutcomes_application as ApplicationForView_application_SlideApplication_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_benefitOutcomes_person as ApplicationForView_application_SlideApplication_benefitOutcomes_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_documentFiles as ApplicationForView_application_SlideApplication_documentFilesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_documentFiles_documents as ApplicationForView_application_SlideApplication_documentFiles_documentsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_documents as ApplicationForView_application_SlideApplication_documentsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_documents_application as ApplicationForView_application_SlideApplication_documents_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_documents_person as ApplicationForView_application_SlideApplication_documents_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_documents_files as ApplicationForView_application_SlideApplication_documents_filesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_householdMembers as ApplicationForView_application_SlideApplication_householdMembersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_householdMembers_person as ApplicationForView_application_SlideApplication_householdMembers_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_householdMembers_person_assets as ApplicationForView_application_SlideApplication_householdMembers_person_assetsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_householdMembers_person_documentFiles as ApplicationForView_application_SlideApplication_householdMembers_person_documentFilesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_householdMembers_person_deductions as ApplicationForView_application_SlideApplication_householdMembers_person_deductionsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources as ApplicationForView_application_SlideApplication_householdMembers_person_incomeSourcesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession as ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession_esignWizards as ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes as ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application as ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_SlideApplication as ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication as ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication as ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers as ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession as ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards as ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_assignedTo as ApplicationForView_application_SlideApplication_assignedToOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person as ApplicationForView_application_SlideApplication_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_accounts as ApplicationForView_application_SlideApplication_person_accountsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_assets as ApplicationForView_application_SlideApplication_person_assetsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_openHouseholdMemberApplications as ApplicationForView_application_SlideApplication_person_openHouseholdMemberApplicationsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_openApplications as ApplicationForView_application_SlideApplication_person_openApplicationsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_openApplications_RyanWhiteApplication as ApplicationForView_application_SlideApplication_person_openApplications_RyanWhiteApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_openApplications_SlideApplication as ApplicationForView_application_SlideApplication_person_openApplications_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_openApplications_CharityCareApplication as ApplicationForView_application_SlideApplication_person_openApplications_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_openApplications_MedicaidApplication as ApplicationForView_application_SlideApplication_person_openApplications_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_documentFiles as ApplicationForView_application_SlideApplication_person_documentFilesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_relationships as ApplicationForView_application_SlideApplication_person_relationshipsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_relationships_otherPerson as ApplicationForView_application_SlideApplication_person_relationships_otherPersonOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_relationships_otherPerson_assets as ApplicationForView_application_SlideApplication_person_relationships_otherPerson_assetsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_relationships_otherPerson_deductions as ApplicationForView_application_SlideApplication_person_relationships_otherPerson_deductionsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources as ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSourcesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession as ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards as ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes as ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application as ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication as ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication as ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication as ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers as ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession as ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards as ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_deductions as ApplicationForView_application_SlideApplication_person_deductionsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_incomeSources as ApplicationForView_application_SlideApplication_person_incomeSourcesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_incomeSources_esignSession as ApplicationForView_application_SlideApplication_person_incomeSources_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_incomeSources_esignSession_esignWizards as ApplicationForView_application_SlideApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_benefitOutcomes as ApplicationForView_application_SlideApplication_person_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_benefitOutcomes_application as ApplicationForView_application_SlideApplication_person_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_SlideApplication as ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication as ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication as ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_phoneNumbers as ApplicationForView_application_SlideApplication_person_phoneNumbersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession as ApplicationForView_application_SlideApplication_person_phoneNumbers_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards as ApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication as ApplicationForView_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_accountOnDateOfService as ApplicationForView_application_CharityCareApplication_accountOnDateOfServiceOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_benefitOutcomes as ApplicationForView_application_CharityCareApplication_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_benefitOutcomes_application as ApplicationForView_application_CharityCareApplication_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_benefitOutcomes_person as ApplicationForView_application_CharityCareApplication_benefitOutcomes_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_documentFiles as ApplicationForView_application_CharityCareApplication_documentFilesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_documentFiles_documents as ApplicationForView_application_CharityCareApplication_documentFiles_documentsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_documents as ApplicationForView_application_CharityCareApplication_documentsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_documents_application as ApplicationForView_application_CharityCareApplication_documents_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_documents_person as ApplicationForView_application_CharityCareApplication_documents_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_documents_files as ApplicationForView_application_CharityCareApplication_documents_filesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_householdMembers as ApplicationForView_application_CharityCareApplication_householdMembersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_householdMembers_person as ApplicationForView_application_CharityCareApplication_householdMembers_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_householdMembers_person_assets as ApplicationForView_application_CharityCareApplication_householdMembers_person_assetsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_householdMembers_person_documentFiles as ApplicationForView_application_CharityCareApplication_householdMembers_person_documentFilesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_householdMembers_person_deductions as ApplicationForView_application_CharityCareApplication_householdMembers_person_deductionsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources as ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSourcesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession as ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession_esignWizards as ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes as ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application as ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_SlideApplication as ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication as ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication as ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers as ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession as ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards as ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_assignedTo as ApplicationForView_application_CharityCareApplication_assignedToOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person as ApplicationForView_application_CharityCareApplication_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_accounts as ApplicationForView_application_CharityCareApplication_person_accountsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_assets as ApplicationForView_application_CharityCareApplication_person_assetsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_openHouseholdMemberApplications as ApplicationForView_application_CharityCareApplication_person_openHouseholdMemberApplicationsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_openApplications as ApplicationForView_application_CharityCareApplication_person_openApplicationsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_openApplications_RyanWhiteApplication as ApplicationForView_application_CharityCareApplication_person_openApplications_RyanWhiteApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_openApplications_SlideApplication as ApplicationForView_application_CharityCareApplication_person_openApplications_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_openApplications_CharityCareApplication as ApplicationForView_application_CharityCareApplication_person_openApplications_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_openApplications_MedicaidApplication as ApplicationForView_application_CharityCareApplication_person_openApplications_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_documentFiles as ApplicationForView_application_CharityCareApplication_person_documentFilesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_relationships as ApplicationForView_application_CharityCareApplication_person_relationshipsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson as ApplicationForView_application_CharityCareApplication_person_relationships_otherPersonOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_assets as ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_assetsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_deductions as ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_deductionsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources as ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSourcesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession as ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards as ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes as ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application as ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication as ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication as ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication as ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers as ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession as ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards as ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_deductions as ApplicationForView_application_CharityCareApplication_person_deductionsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_incomeSources as ApplicationForView_application_CharityCareApplication_person_incomeSourcesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession as ApplicationForView_application_CharityCareApplication_person_incomeSources_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards as ApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_benefitOutcomes as ApplicationForView_application_CharityCareApplication_person_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application as ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication as ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication as ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication as ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_phoneNumbers as ApplicationForView_application_CharityCareApplication_person_phoneNumbersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession as ApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards as ApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication as ApplicationForView_application_MonitorApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_primaryPointOfContact as ApplicationForView_application_MonitorApplication_primaryPointOfContactOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_primaryPointOfContact_deductions as ApplicationForView_application_MonitorApplication_primaryPointOfContact_deductionsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources as ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSourcesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession as ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards as ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes as ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application as ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication as ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication as ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication as ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers as ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession as ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards as ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_accountOnDateOfService as ApplicationForView_application_MonitorApplication_accountOnDateOfServiceOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_benefitOutcomes as ApplicationForView_application_MonitorApplication_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_benefitOutcomes_application as ApplicationForView_application_MonitorApplication_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_benefitOutcomes_person as ApplicationForView_application_MonitorApplication_benefitOutcomes_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_documentFiles as ApplicationForView_application_MonitorApplication_documentFilesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_documentFiles_documents as ApplicationForView_application_MonitorApplication_documentFiles_documentsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_documents as ApplicationForView_application_MonitorApplication_documentsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_documents_application as ApplicationForView_application_MonitorApplication_documents_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_documents_person as ApplicationForView_application_MonitorApplication_documents_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_documents_files as ApplicationForView_application_MonitorApplication_documents_filesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_householdMembers as ApplicationForView_application_MonitorApplication_householdMembersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_householdMembers_person as ApplicationForView_application_MonitorApplication_householdMembers_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_householdMembers_person_assets as ApplicationForView_application_MonitorApplication_householdMembers_person_assetsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_householdMembers_person_documentFiles as ApplicationForView_application_MonitorApplication_householdMembers_person_documentFilesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_householdMembers_person_deductions as ApplicationForView_application_MonitorApplication_householdMembers_person_deductionsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources as ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSourcesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession as ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession_esignWizards as ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes as ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application as ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_SlideApplication as ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication as ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication as ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers as ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession as ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards as ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_assignedTo as ApplicationForView_application_MonitorApplication_assignedToOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person as ApplicationForView_application_MonitorApplication_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_accounts as ApplicationForView_application_MonitorApplication_person_accountsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_assets as ApplicationForView_application_MonitorApplication_person_assetsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_openHouseholdMemberApplications as ApplicationForView_application_MonitorApplication_person_openHouseholdMemberApplicationsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_openApplications as ApplicationForView_application_MonitorApplication_person_openApplicationsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_openApplications_RyanWhiteApplication as ApplicationForView_application_MonitorApplication_person_openApplications_RyanWhiteApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_openApplications_SlideApplication as ApplicationForView_application_MonitorApplication_person_openApplications_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_openApplications_CharityCareApplication as ApplicationForView_application_MonitorApplication_person_openApplications_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_openApplications_MedicaidApplication as ApplicationForView_application_MonitorApplication_person_openApplications_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_documentFiles as ApplicationForView_application_MonitorApplication_person_documentFilesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_relationships as ApplicationForView_application_MonitorApplication_person_relationshipsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_relationships_otherPerson as ApplicationForView_application_MonitorApplication_person_relationships_otherPersonOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_assets as ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_assetsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_deductions as ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_deductionsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources as ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSourcesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession as ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards as ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes as ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application as ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication as ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication as ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication as ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers as ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession as ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards as ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_deductions as ApplicationForView_application_MonitorApplication_person_deductionsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_incomeSources as ApplicationForView_application_MonitorApplication_person_incomeSourcesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_incomeSources_esignSession as ApplicationForView_application_MonitorApplication_person_incomeSources_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_incomeSources_esignSession_esignWizards as ApplicationForView_application_MonitorApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_benefitOutcomes as ApplicationForView_application_MonitorApplication_person_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application as ApplicationForView_application_MonitorApplication_person_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication as ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication as ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication as ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_phoneNumbers as ApplicationForView_application_MonitorApplication_person_phoneNumbersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession as ApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards as ApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {deserializeMcdSubtype} from 'graphql/converters'
import {serializeMcdSubtype} from 'graphql/converters'
import {deserializeMcdType} from 'graphql/converters'
import {serializeMcdType} from 'graphql/converters'
import {ApplicationForView_application_MedicaidApplication as ApplicationForView_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_primaryPointOfContact as ApplicationForView_application_MedicaidApplication_primaryPointOfContactOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_primaryPointOfContact_deductions as ApplicationForView_application_MedicaidApplication_primaryPointOfContact_deductionsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources as ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSourcesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession as ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards as ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes as ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application as ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication as ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication as ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication as ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers as ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession as ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards as ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {deserializeNjmmisCheckResult} from 'graphql/converters'
import {serializeNjmmisCheckResult} from 'graphql/converters'
import {ApplicationForView_application_MedicaidApplication_accountOnDateOfService as ApplicationForView_application_MedicaidApplication_accountOnDateOfServiceOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_benefitOutcomes as ApplicationForView_application_MedicaidApplication_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_benefitOutcomes_application as ApplicationForView_application_MedicaidApplication_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_benefitOutcomes_person as ApplicationForView_application_MedicaidApplication_benefitOutcomes_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_documentFiles as ApplicationForView_application_MedicaidApplication_documentFilesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_documentFiles_documents as ApplicationForView_application_MedicaidApplication_documentFiles_documentsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_documents as ApplicationForView_application_MedicaidApplication_documentsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_documents_application as ApplicationForView_application_MedicaidApplication_documents_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_documents_person as ApplicationForView_application_MedicaidApplication_documents_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_documents_files as ApplicationForView_application_MedicaidApplication_documents_filesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_householdMembers as ApplicationForView_application_MedicaidApplication_householdMembersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_householdMembers_person as ApplicationForView_application_MedicaidApplication_householdMembers_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_householdMembers_person_assets as ApplicationForView_application_MedicaidApplication_householdMembers_person_assetsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_householdMembers_person_documentFiles as ApplicationForView_application_MedicaidApplication_householdMembers_person_documentFilesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_householdMembers_person_deductions as ApplicationForView_application_MedicaidApplication_householdMembers_person_deductionsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources as ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSourcesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession as ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession_esignWizards as ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes as ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application as ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_SlideApplication as ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication as ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication as ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers as ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession as ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards as ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_assignedTo as ApplicationForView_application_MedicaidApplication_assignedToOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person as ApplicationForView_application_MedicaidApplication_personOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_accounts as ApplicationForView_application_MedicaidApplication_person_accountsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_assets as ApplicationForView_application_MedicaidApplication_person_assetsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_openHouseholdMemberApplications as ApplicationForView_application_MedicaidApplication_person_openHouseholdMemberApplicationsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_openApplications as ApplicationForView_application_MedicaidApplication_person_openApplicationsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_openApplications_RyanWhiteApplication as ApplicationForView_application_MedicaidApplication_person_openApplications_RyanWhiteApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_openApplications_SlideApplication as ApplicationForView_application_MedicaidApplication_person_openApplications_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_openApplications_CharityCareApplication as ApplicationForView_application_MedicaidApplication_person_openApplications_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {BenefitType} from 'utils/benefits'
import {deserializeBenefit} from 'graphql/converters'
import {serializeBenefit} from 'graphql/converters'
import {ApplicationForView_application_MedicaidApplication_person_openApplications_MedicaidApplication as ApplicationForView_application_MedicaidApplication_person_openApplications_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_documentFiles as ApplicationForView_application_MedicaidApplication_person_documentFilesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_relationships as ApplicationForView_application_MedicaidApplication_person_relationshipsOrig} from '../types/ApplicationForView'
import {DeserializedFieldsUpdatedAt} from 'utils/form/getFieldsUpdatedAt'
import {parseFieldsUpdatedAt} from 'utils/form/getFieldsUpdatedAt'
import {serializeFieldsUpdatedAt} from 'utils/form/getFieldsUpdatedAt'
import {ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson as ApplicationForView_application_MedicaidApplication_person_relationships_otherPersonOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_assets as ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_assetsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_deductions as ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_deductionsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources as ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSourcesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession as ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards as ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes as ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application as ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication as ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication as ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication as ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers as ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession as ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards as ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_deductions as ApplicationForView_application_MedicaidApplication_person_deductionsOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_incomeSources as ApplicationForView_application_MedicaidApplication_person_incomeSourcesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession as ApplicationForView_application_MedicaidApplication_person_incomeSources_esignSessionOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards as ApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
import {formatISODate} from 'utils/date'
import {ApplicationForView_application_MedicaidApplication_person_benefitOutcomes as ApplicationForView_application_MedicaidApplication_person_benefitOutcomesOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application as ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_applicationOrig} from '../types/ApplicationForView'
import {deserializeSlideType} from 'graphql/converters'
import {serializeSlideType} from 'graphql/converters'
import {ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication as ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication as ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication as ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_phoneNumbers as ApplicationForView_application_MedicaidApplication_person_phoneNumbersOrig} from '../types/ApplicationForView'
import {ApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession as ApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSessionOrig} from '../types/ApplicationForView'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {ApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards as ApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/ApplicationForView'
export interface ApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: ApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): ApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards
): ApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession = (
  serialized: ApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSessionOrig
): ApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession
): ApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_MedicaidApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: ApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession | null
}
export const deserializeApplicationForView_application_MedicaidApplication_person_phoneNumbers = (
  serialized: ApplicationForView_application_MedicaidApplication_person_phoneNumbersOrig
): ApplicationForView_application_MedicaidApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_MedicaidApplication_person_phoneNumbers = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_phoneNumbers
): ApplicationForView_application_MedicaidApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_MedicaidApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplicationOrig
): ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication
): ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplicationOrig
): ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication
): ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication = (
  serialized: ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplicationOrig
): ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication
): ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application =
  | ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication
  | ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication
  | ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication
export const deserializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application = (
  serialized: ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_applicationOrig
): ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application'
  )
}
export const serializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application
): ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application'
  )
}
export interface ApplicationForView_application_MedicaidApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: ApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes = (
  serialized: ApplicationForView_application_MedicaidApplication_person_benefitOutcomesOrig
): ApplicationForView_application_MedicaidApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_benefitOutcomes
): ApplicationForView_application_MedicaidApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: ApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession_esignWizardsOrig
): ApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards
): ApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession = (
  serialized: ApplicationForView_application_MedicaidApplication_person_incomeSources_esignSessionOrig
): ApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession
): ApplicationForView_application_MedicaidApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_MedicaidApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: ApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession | null
}
export const deserializeApplicationForView_application_MedicaidApplication_person_incomeSources = (
  serialized: ApplicationForView_application_MedicaidApplication_person_incomeSourcesOrig
): ApplicationForView_application_MedicaidApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_MedicaidApplication_person_incomeSources = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_incomeSources
): ApplicationForView_application_MedicaidApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_MedicaidApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_MedicaidApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplicationForView_application_MedicaidApplication_person_deductions = (
  serialized: ApplicationForView_application_MedicaidApplication_person_deductionsOrig
): ApplicationForView_application_MedicaidApplication_person_deductions => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_person_deductions = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_deductions
): ApplicationForView_application_MedicaidApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards = (
  serialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizardsOrig
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession = (
  serialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSessionOrig
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession | null
}
export const deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers = (
  serialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbersOrig
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication = (
  serialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplicationOrig
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication = (
  serialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplicationOrig
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication = (
  serialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplicationOrig
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application =
  | ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication
  | ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication
  | ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication
export const deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application = (
  serialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_applicationOrig
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application'
  )
}
export const serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application'
  )
}
export interface ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes = (
  serialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomesOrig
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards = (
  serialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizardsOrig
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession = (
  serialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSessionOrig
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession | null
}
export const deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources = (
  serialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSourcesOrig
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_deductions = (
  serialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_deductionsOrig
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_deductions => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_deductions = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_deductions
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_deductionsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_assets = (
  serialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_assetsOrig
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_assets => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_assets = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_assets
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_assetsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes[]
  incomeSources: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources[]
  deductions: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  updatedAt: Date
  gender: string | null
  emailComment: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  assets: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson = (
  serialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPersonOrig
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_deductions
  ),
  updatedAt: parseDate(serialized.updatedAt),
  assets: serialized.assets.map(
    deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson
): ApplicationForView_application_MedicaidApplication_person_relationships_otherPersonOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_deductions
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  assets: deserialized.assets.map(
    serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface ApplicationForView_application_MedicaidApplication_person_relationships {
  __typename: 'Relationship'
  id: string
  relationshipType: string | null
  reverseRelationshipType: string | null
  liveTogether: boolean | null
  otherPerson: ApplicationForView_application_MedicaidApplication_person_relationships_otherPerson
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeApplicationForView_application_MedicaidApplication_person_relationships = (
  serialized: ApplicationForView_application_MedicaidApplication_person_relationshipsOrig
): ApplicationForView_application_MedicaidApplication_person_relationships => ({
  ...serialized,
  otherPerson: deserializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson(
    serialized.otherPerson
  ),
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeApplicationForView_application_MedicaidApplication_person_relationships = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_relationships
): ApplicationForView_application_MedicaidApplication_person_relationshipsOrig => ({
  ...deserialized,
  otherPerson: serializeApplicationForView_application_MedicaidApplication_person_relationships_otherPerson(
    deserialized.otherPerson
  ),
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface ApplicationForView_application_MedicaidApplication_person_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeApplicationForView_application_MedicaidApplication_person_documentFiles = (
  serialized: ApplicationForView_application_MedicaidApplication_person_documentFilesOrig
): ApplicationForView_application_MedicaidApplication_person_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_MedicaidApplication_person_documentFiles = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_documentFiles
): ApplicationForView_application_MedicaidApplication_person_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_MedicaidApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplicationForView_application_MedicaidApplication_person_openApplications_MedicaidApplication = (
  serialized: ApplicationForView_application_MedicaidApplication_person_openApplications_MedicaidApplicationOrig
): ApplicationForView_application_MedicaidApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_MedicaidApplication_person_openApplications_MedicaidApplication = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_openApplications_MedicaidApplication
): ApplicationForView_application_MedicaidApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_MedicaidApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplicationForView_application_MedicaidApplication_person_openApplications_CharityCareApplication = (
  serialized: ApplicationForView_application_MedicaidApplication_person_openApplications_CharityCareApplicationOrig
): ApplicationForView_application_MedicaidApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_MedicaidApplication_person_openApplications_CharityCareApplication = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_openApplications_CharityCareApplication
): ApplicationForView_application_MedicaidApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_MedicaidApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplicationForView_application_MedicaidApplication_person_openApplications_SlideApplication = (
  serialized: ApplicationForView_application_MedicaidApplication_person_openApplications_SlideApplicationOrig
): ApplicationForView_application_MedicaidApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_MedicaidApplication_person_openApplications_SlideApplication = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_openApplications_SlideApplication
): ApplicationForView_application_MedicaidApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_MedicaidApplication_person_openApplications_RyanWhiteApplication {
  __typename: 'RyanWhiteApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplicationForView_application_MedicaidApplication_person_openApplications_RyanWhiteApplication = (
  serialized: ApplicationForView_application_MedicaidApplication_person_openApplications_RyanWhiteApplicationOrig
): ApplicationForView_application_MedicaidApplication_person_openApplications_RyanWhiteApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_MedicaidApplication_person_openApplications_RyanWhiteApplication = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_openApplications_RyanWhiteApplication
): ApplicationForView_application_MedicaidApplication_person_openApplications_RyanWhiteApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type ApplicationForView_application_MedicaidApplication_person_openApplications =
  | ApplicationForView_application_MedicaidApplication_person_openApplications_MedicaidApplication
  | ApplicationForView_application_MedicaidApplication_person_openApplications_CharityCareApplication
  | ApplicationForView_application_MedicaidApplication_person_openApplications_SlideApplication
  | ApplicationForView_application_MedicaidApplication_person_openApplications_RyanWhiteApplication
export const deserializeApplicationForView_application_MedicaidApplication_person_openApplications = (
  serialized: ApplicationForView_application_MedicaidApplication_person_openApplicationsOrig
): ApplicationForView_application_MedicaidApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeApplicationForView_application_MedicaidApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_MedicaidApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_MedicaidApplication_person_openApplications_SlideApplication(
      serialized
    )
  if (false || serialized.__typename === 'RyanWhiteApplication')
    return deserializeApplicationForView_application_MedicaidApplication_person_openApplications_RyanWhiteApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MedicaidApplication_person_openApplications'
  )
}
export const serializeApplicationForView_application_MedicaidApplication_person_openApplications = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_openApplications
): ApplicationForView_application_MedicaidApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeApplicationForView_application_MedicaidApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_MedicaidApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_MedicaidApplication_person_openApplications_SlideApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'RyanWhiteApplication')
    return serializeApplicationForView_application_MedicaidApplication_person_openApplications_RyanWhiteApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MedicaidApplication_person_openApplications'
  )
}
export interface ApplicationForView_application_MedicaidApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplicationForView_application_MedicaidApplication_person_openHouseholdMemberApplications = (
  serialized: ApplicationForView_application_MedicaidApplication_person_openHouseholdMemberApplicationsOrig
): ApplicationForView_application_MedicaidApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_MedicaidApplication_person_openHouseholdMemberApplications = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_openHouseholdMemberApplications
): ApplicationForView_application_MedicaidApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_MedicaidApplication_person_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeApplicationForView_application_MedicaidApplication_person_assets = (
  serialized: ApplicationForView_application_MedicaidApplication_person_assetsOrig
): ApplicationForView_application_MedicaidApplication_person_assets => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_person_assets = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_assets
): ApplicationForView_application_MedicaidApplication_person_assetsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_person_accounts {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
}
export const deserializeApplicationForView_application_MedicaidApplication_person_accounts = (
  serialized: ApplicationForView_application_MedicaidApplication_person_accountsOrig
): ApplicationForView_application_MedicaidApplication_person_accounts => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
})
export const serializeApplicationForView_application_MedicaidApplication_person_accounts = (
  deserialized: ApplicationForView_application_MedicaidApplication_person_accounts
): ApplicationForView_application_MedicaidApplication_person_accountsOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
})
export interface ApplicationForView_application_MedicaidApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: ApplicationForView_application_MedicaidApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: ApplicationForView_application_MedicaidApplication_person_benefitOutcomes[]
  incomeSources: ApplicationForView_application_MedicaidApplication_person_incomeSources[]
  deductions: ApplicationForView_application_MedicaidApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  relationships: ApplicationForView_application_MedicaidApplication_person_relationships[]
  documentFiles: ApplicationForView_application_MedicaidApplication_person_documentFiles[]
  openApplications: ApplicationForView_application_MedicaidApplication_person_openApplications[]
  openHouseholdMemberApplications: ApplicationForView_application_MedicaidApplication_person_openHouseholdMemberApplications[]
  updatedAt: Date
  gender: string | null
  emailComment: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  assets: ApplicationForView_application_MedicaidApplication_person_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  accounts: ApplicationForView_application_MedicaidApplication_person_accounts[]
}
export const deserializeApplicationForView_application_MedicaidApplication_person = (
  serialized: ApplicationForView_application_MedicaidApplication_personOrig
): ApplicationForView_application_MedicaidApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplicationForView_application_MedicaidApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplicationForView_application_MedicaidApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplicationForView_application_MedicaidApplication_person_deductions
  ),
  relationships: serialized.relationships.map(
    deserializeApplicationForView_application_MedicaidApplication_person_relationships
  ),
  documentFiles: serialized.documentFiles.map(
    deserializeApplicationForView_application_MedicaidApplication_person_documentFiles
  ),
  openApplications: serialized.openApplications.map(
    deserializeApplicationForView_application_MedicaidApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeApplicationForView_application_MedicaidApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: parseDate(serialized.updatedAt),
  assets: serialized.assets.map(
    deserializeApplicationForView_application_MedicaidApplication_person_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  accounts: serialized.accounts.map(
    deserializeApplicationForView_application_MedicaidApplication_person_accounts
  ),
})
export const serializeApplicationForView_application_MedicaidApplication_person = (
  deserialized: ApplicationForView_application_MedicaidApplication_person
): ApplicationForView_application_MedicaidApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplicationForView_application_MedicaidApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_MedicaidApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplicationForView_application_MedicaidApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplicationForView_application_MedicaidApplication_person_deductions
  ),
  relationships: deserialized.relationships.map(
    serializeApplicationForView_application_MedicaidApplication_person_relationships
  ),
  documentFiles: deserialized.documentFiles.map(
    serializeApplicationForView_application_MedicaidApplication_person_documentFiles
  ),
  openApplications: deserialized.openApplications.map(
    serializeApplicationForView_application_MedicaidApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeApplicationForView_application_MedicaidApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  assets: deserialized.assets.map(
    serializeApplicationForView_application_MedicaidApplication_person_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  accounts: deserialized.accounts.map(
    serializeApplicationForView_application_MedicaidApplication_person_accounts
  ),
})
export interface ApplicationForView_application_MedicaidApplication_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeApplicationForView_application_MedicaidApplication_assignedTo = (
  serialized: ApplicationForView_application_MedicaidApplication_assignedToOrig
): ApplicationForView_application_MedicaidApplication_assignedTo => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_assignedTo = (
  deserialized: ApplicationForView_application_MedicaidApplication_assignedTo
): ApplicationForView_application_MedicaidApplication_assignedToOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards = (
  serialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession_esignWizardsOrig
): ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards
): ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession = (
  serialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSessionOrig
): ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession = (
  deserialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession
): ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession | null
}
export const deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers = (
  serialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbersOrig
): ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers = (
  deserialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers
): ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplicationOrig
): ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication
): ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplicationOrig
): ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication
): ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_SlideApplication = (
  serialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_SlideApplicationOrig
): ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_SlideApplication = (
  deserialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_SlideApplication
): ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application =
  | ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication
  | ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication
  | ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_SlideApplication
export const deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application = (
  serialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_applicationOrig
): ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application'
  )
}
export const serializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application
): ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application'
  )
}
export interface ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes = (
  serialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomesOrig
): ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes = (
  deserialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes
): ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession_esignWizards = (
  serialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession_esignWizardsOrig
): ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession_esignWizards
): ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession = (
  serialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSessionOrig
): ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession = (
  deserialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession
): ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession | null
}
export const deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources = (
  serialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSourcesOrig
): ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources = (
  deserialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources
): ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_MedicaidApplication_householdMembers_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_deductions = (
  serialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_deductionsOrig
): ApplicationForView_application_MedicaidApplication_householdMembers_person_deductions => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_householdMembers_person_deductions = (
  deserialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_deductions
): ApplicationForView_application_MedicaidApplication_householdMembers_person_deductionsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_householdMembers_person_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_documentFiles = (
  serialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_documentFilesOrig
): ApplicationForView_application_MedicaidApplication_householdMembers_person_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_MedicaidApplication_householdMembers_person_documentFiles = (
  deserialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_documentFiles
): ApplicationForView_application_MedicaidApplication_householdMembers_person_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_MedicaidApplication_householdMembers_person_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_assets = (
  serialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_assetsOrig
): ApplicationForView_application_MedicaidApplication_householdMembers_person_assets => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_householdMembers_person_assets = (
  deserialized: ApplicationForView_application_MedicaidApplication_householdMembers_person_assets
): ApplicationForView_application_MedicaidApplication_householdMembers_person_assetsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_householdMembers_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: ApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: ApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes[]
  incomeSources: ApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources[]
  deductions: ApplicationForView_application_MedicaidApplication_householdMembers_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  documentFiles: ApplicationForView_application_MedicaidApplication_householdMembers_person_documentFiles[]
  updatedAt: Date
  gender: string | null
  emailComment: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  assets: ApplicationForView_application_MedicaidApplication_householdMembers_person_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
}
export const deserializeApplicationForView_application_MedicaidApplication_householdMembers_person = (
  serialized: ApplicationForView_application_MedicaidApplication_householdMembers_personOrig
): ApplicationForView_application_MedicaidApplication_householdMembers_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_deductions
  ),
  documentFiles: serialized.documentFiles.map(
    deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_documentFiles
  ),
  updatedAt: parseDate(serialized.updatedAt),
  assets: serialized.assets.map(
    deserializeApplicationForView_application_MedicaidApplication_householdMembers_person_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
})
export const serializeApplicationForView_application_MedicaidApplication_householdMembers_person = (
  deserialized: ApplicationForView_application_MedicaidApplication_householdMembers_person
): ApplicationForView_application_MedicaidApplication_householdMembers_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplicationForView_application_MedicaidApplication_householdMembers_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_MedicaidApplication_householdMembers_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplicationForView_application_MedicaidApplication_householdMembers_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplicationForView_application_MedicaidApplication_householdMembers_person_deductions
  ),
  documentFiles: deserialized.documentFiles.map(
    serializeApplicationForView_application_MedicaidApplication_householdMembers_person_documentFiles
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  assets: deserialized.assets.map(
    serializeApplicationForView_application_MedicaidApplication_householdMembers_person_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
})
export interface ApplicationForView_application_MedicaidApplication_householdMembers {
  __typename: 'ApplicationHouseholdMember'
  id: string
  wantsCoverage: boolean | null
  person: ApplicationForView_application_MedicaidApplication_householdMembers_person
  createdAt: Date
}
export const deserializeApplicationForView_application_MedicaidApplication_householdMembers = (
  serialized: ApplicationForView_application_MedicaidApplication_householdMembersOrig
): ApplicationForView_application_MedicaidApplication_householdMembers => ({
  ...serialized,
  person: deserializeApplicationForView_application_MedicaidApplication_householdMembers_person(
    serialized.person
  ),
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_MedicaidApplication_householdMembers = (
  deserialized: ApplicationForView_application_MedicaidApplication_householdMembers
): ApplicationForView_application_MedicaidApplication_householdMembersOrig => ({
  ...deserialized,
  person: serializeApplicationForView_application_MedicaidApplication_householdMembers_person(
    deserialized.person
  ),
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_MedicaidApplication_documents_files {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeApplicationForView_application_MedicaidApplication_documents_files = (
  serialized: ApplicationForView_application_MedicaidApplication_documents_filesOrig
): ApplicationForView_application_MedicaidApplication_documents_files => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_MedicaidApplication_documents_files = (
  deserialized: ApplicationForView_application_MedicaidApplication_documents_files
): ApplicationForView_application_MedicaidApplication_documents_filesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_MedicaidApplication_documents_person {
  __typename: 'Person'
  id: string
}
export const deserializeApplicationForView_application_MedicaidApplication_documents_person = (
  serialized: ApplicationForView_application_MedicaidApplication_documents_personOrig
): ApplicationForView_application_MedicaidApplication_documents_person => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_documents_person = (
  deserialized: ApplicationForView_application_MedicaidApplication_documents_person
): ApplicationForView_application_MedicaidApplication_documents_personOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_documents_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeApplicationForView_application_MedicaidApplication_documents_application = (
  serialized: ApplicationForView_application_MedicaidApplication_documents_applicationOrig
): ApplicationForView_application_MedicaidApplication_documents_application => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_documents_application = (
  deserialized: ApplicationForView_application_MedicaidApplication_documents_application
): ApplicationForView_application_MedicaidApplication_documents_applicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_documents {
  __typename: 'Document'
  id: string
  documentType: string
  complete: boolean | null
  files: ApplicationForView_application_MedicaidApplication_documents_files[]
  updatedAt: Date
  notes: string | null
  person: ApplicationForView_application_MedicaidApplication_documents_person | null
  application: ApplicationForView_application_MedicaidApplication_documents_application
}
export const deserializeApplicationForView_application_MedicaidApplication_documents = (
  serialized: ApplicationForView_application_MedicaidApplication_documentsOrig
): ApplicationForView_application_MedicaidApplication_documents => ({
  ...serialized,
  files: serialized.files.map(
    deserializeApplicationForView_application_MedicaidApplication_documents_files
  ),
  updatedAt: parseDate(serialized.updatedAt),
  person:
    serialized.person != null
      ? deserializeApplicationForView_application_MedicaidApplication_documents_person(
          serialized.person
        )
      : serialized.person,
  application: deserializeApplicationForView_application_MedicaidApplication_documents_application(
    serialized.application
  ),
})
export const serializeApplicationForView_application_MedicaidApplication_documents = (
  deserialized: ApplicationForView_application_MedicaidApplication_documents
): ApplicationForView_application_MedicaidApplication_documentsOrig => ({
  ...deserialized,
  files: deserialized.files.map(
    serializeApplicationForView_application_MedicaidApplication_documents_files
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  person:
    deserialized.person != null
      ? serializeApplicationForView_application_MedicaidApplication_documents_person(
          deserialized.person
        )
      : deserialized.person,
  application: serializeApplicationForView_application_MedicaidApplication_documents_application(
    deserialized.application
  ),
})
export interface ApplicationForView_application_MedicaidApplication_documentFiles_documents {
  __typename: 'Document'
  id: string
}
export const deserializeApplicationForView_application_MedicaidApplication_documentFiles_documents = (
  serialized: ApplicationForView_application_MedicaidApplication_documentFiles_documentsOrig
): ApplicationForView_application_MedicaidApplication_documentFiles_documents => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_documentFiles_documents = (
  deserialized: ApplicationForView_application_MedicaidApplication_documentFiles_documents
): ApplicationForView_application_MedicaidApplication_documentFiles_documentsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
  documents: ApplicationForView_application_MedicaidApplication_documentFiles_documents[]
}
export const deserializeApplicationForView_application_MedicaidApplication_documentFiles = (
  serialized: ApplicationForView_application_MedicaidApplication_documentFilesOrig
): ApplicationForView_application_MedicaidApplication_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
  documents: serialized.documents.map(
    deserializeApplicationForView_application_MedicaidApplication_documentFiles_documents
  ),
})
export const serializeApplicationForView_application_MedicaidApplication_documentFiles = (
  deserialized: ApplicationForView_application_MedicaidApplication_documentFiles
): ApplicationForView_application_MedicaidApplication_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
  documents: deserialized.documents.map(
    serializeApplicationForView_application_MedicaidApplication_documentFiles_documents
  ),
})
export interface ApplicationForView_application_MedicaidApplication_benefitOutcomes_person {
  __typename: 'Person'
  id: string
}
export const deserializeApplicationForView_application_MedicaidApplication_benefitOutcomes_person = (
  serialized: ApplicationForView_application_MedicaidApplication_benefitOutcomes_personOrig
): ApplicationForView_application_MedicaidApplication_benefitOutcomes_person => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_benefitOutcomes_person = (
  deserialized: ApplicationForView_application_MedicaidApplication_benefitOutcomes_person
): ApplicationForView_application_MedicaidApplication_benefitOutcomes_personOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_benefitOutcomes_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeApplicationForView_application_MedicaidApplication_benefitOutcomes_application = (
  serialized: ApplicationForView_application_MedicaidApplication_benefitOutcomes_applicationOrig
): ApplicationForView_application_MedicaidApplication_benefitOutcomes_application => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_MedicaidApplication_benefitOutcomes_application
): ApplicationForView_application_MedicaidApplication_benefitOutcomes_applicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  person: ApplicationForView_application_MedicaidApplication_benefitOutcomes_person
  application: ApplicationForView_application_MedicaidApplication_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_MedicaidApplication_benefitOutcomes = (
  serialized: ApplicationForView_application_MedicaidApplication_benefitOutcomesOrig
): ApplicationForView_application_MedicaidApplication_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  person: deserializeApplicationForView_application_MedicaidApplication_benefitOutcomes_person(
    serialized.person
  ),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_MedicaidApplication_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_MedicaidApplication_benefitOutcomes = (
  deserialized: ApplicationForView_application_MedicaidApplication_benefitOutcomes
): ApplicationForView_application_MedicaidApplication_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  person: serializeApplicationForView_application_MedicaidApplication_benefitOutcomes_person(
    deserialized.person
  ),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_MedicaidApplication_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_MedicaidApplication_accountOnDateOfService {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
  timeOfService: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location: string | null
  insuranceType: string
  status: string
  createdAt: Date
  updatedAt: Date
  notes: string | null
  accountAmount: number | null
  njmmisCheck: string | null
  previousInsurance: string | null
  invoiceMonth: string | null
  invoiceYear: string | null
  dischargeDate: Date | null
  isScheduled: boolean
}
export const deserializeApplicationForView_application_MedicaidApplication_accountOnDateOfService = (
  serialized: ApplicationForView_application_MedicaidApplication_accountOnDateOfServiceOrig
): ApplicationForView_application_MedicaidApplication_accountOnDateOfService => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
})
export const serializeApplicationForView_application_MedicaidApplication_accountOnDateOfService = (
  deserialized: ApplicationForView_application_MedicaidApplication_accountOnDateOfService
): ApplicationForView_application_MedicaidApplication_accountOnDateOfServiceOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
})
export interface ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards = (
  serialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizardsOrig
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession = (
  serialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSessionOrig
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession = (
  deserialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession | null
}
export const deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers = (
  serialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbersOrig
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers = (
  deserialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication = (
  serialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplicationOrig
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication = (
  serialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplicationOrig
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication = (
  serialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplicationOrig
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication = (
  deserialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application =
  | ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication
  | ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication
  | ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication
export const deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application = (
  serialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_applicationOrig
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application'
  )
}
export const serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application'
  )
}
export interface ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes = (
  serialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomesOrig
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes = (
  deserialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards = (
  serialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession_esignWizardsOrig
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession = (
  serialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSessionOrig
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession = (
  deserialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession | null
}
export const deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources = (
  serialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSourcesOrig
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources = (
  deserialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_MedicaidApplication_primaryPointOfContact_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_deductions = (
  serialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_deductionsOrig
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_deductions => ({
  ...serialized,
})
export const serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_deductions = (
  deserialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_deductions
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact_deductionsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MedicaidApplication_primaryPointOfContact {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes[]
  incomeSources: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources[]
  deductions: ApplicationForView_application_MedicaidApplication_primaryPointOfContact_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
}
export const deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact = (
  serialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContactOrig
): ApplicationForView_application_MedicaidApplication_primaryPointOfContact => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_deductions
  ),
})
export const serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact = (
  deserialized: ApplicationForView_application_MedicaidApplication_primaryPointOfContact
): ApplicationForView_application_MedicaidApplication_primaryPointOfContactOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact_deductions
  ),
})
export interface ApplicationForView_application_MedicaidApplication {
  __typename: 'MedicaidApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  readyForAudit: boolean
  person: ApplicationForView_application_MedicaidApplication_person
  assignedTo: ApplicationForView_application_MedicaidApplication_assignedTo | null
  householdMembers: ApplicationForView_application_MedicaidApplication_householdMembers[]
  documents: ApplicationForView_application_MedicaidApplication_documents[]
  documentFiles: ApplicationForView_application_MedicaidApplication_documentFiles[]
  benefitOutcomes: ApplicationForView_application_MedicaidApplication_benefitOutcomes[]
  accountOnDateOfService: ApplicationForView_application_MedicaidApplication_accountOnDateOfService | null
  submitDate: Date | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  initialDateOfService: Date | null
  mcdType: string
  mcdSubtype: string | null
  primaryPointOfContact: ApplicationForView_application_MedicaidApplication_primaryPointOfContact | null
}
export const deserializeApplicationForView_application_MedicaidApplication = (
  serialized: ApplicationForView_application_MedicaidApplicationOrig
): ApplicationForView_application_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  person: deserializeApplicationForView_application_MedicaidApplication_person(
    serialized.person
  ),
  assignedTo:
    serialized.assignedTo != null
      ? deserializeApplicationForView_application_MedicaidApplication_assignedTo(
          serialized.assignedTo
        )
      : serialized.assignedTo,
  householdMembers: serialized.householdMembers.map(
    deserializeApplicationForView_application_MedicaidApplication_householdMembers
  ),
  documents: serialized.documents.map(
    deserializeApplicationForView_application_MedicaidApplication_documents
  ),
  documentFiles: serialized.documentFiles.map(
    deserializeApplicationForView_application_MedicaidApplication_documentFiles
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_MedicaidApplication_benefitOutcomes
  ),
  accountOnDateOfService:
    serialized.accountOnDateOfService != null
      ? deserializeApplicationForView_application_MedicaidApplication_accountOnDateOfService(
          serialized.accountOnDateOfService
        )
      : serialized.accountOnDateOfService,
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  mcdType: deserializeMcdType(serialized.mcdType),
  mcdSubtype:
    serialized.mcdSubtype != null
      ? deserializeMcdSubtype(serialized.mcdSubtype)
      : serialized.mcdSubtype,
  primaryPointOfContact:
    serialized.primaryPointOfContact != null
      ? deserializeApplicationForView_application_MedicaidApplication_primaryPointOfContact(
          serialized.primaryPointOfContact
        )
      : serialized.primaryPointOfContact,
})
export const serializeApplicationForView_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_MedicaidApplication
): ApplicationForView_application_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  person: serializeApplicationForView_application_MedicaidApplication_person(
    deserialized.person
  ),
  assignedTo:
    deserialized.assignedTo != null
      ? serializeApplicationForView_application_MedicaidApplication_assignedTo(
          deserialized.assignedTo
        )
      : deserialized.assignedTo,
  householdMembers: deserialized.householdMembers.map(
    serializeApplicationForView_application_MedicaidApplication_householdMembers
  ),
  documents: deserialized.documents.map(
    serializeApplicationForView_application_MedicaidApplication_documents
  ),
  documentFiles: deserialized.documentFiles.map(
    serializeApplicationForView_application_MedicaidApplication_documentFiles
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_MedicaidApplication_benefitOutcomes
  ),
  accountOnDateOfService:
    deserialized.accountOnDateOfService != null
      ? serializeApplicationForView_application_MedicaidApplication_accountOnDateOfService(
          deserialized.accountOnDateOfService
        )
      : deserialized.accountOnDateOfService,
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  mcdType: serializeMcdType(deserialized.mcdType),
  mcdSubtype:
    deserialized.mcdSubtype != null
      ? serializeMcdSubtype(deserialized.mcdSubtype)
      : deserialized.mcdSubtype,
  primaryPointOfContact:
    deserialized.primaryPointOfContact != null
      ? serializeApplicationForView_application_MedicaidApplication_primaryPointOfContact(
          deserialized.primaryPointOfContact
        )
      : deserialized.primaryPointOfContact,
})
export interface ApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: ApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): ApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards
): ApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession = (
  serialized: ApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSessionOrig
): ApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession = (
  deserialized: ApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession
): ApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_MonitorApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: ApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession | null
}
export const deserializeApplicationForView_application_MonitorApplication_person_phoneNumbers = (
  serialized: ApplicationForView_application_MonitorApplication_person_phoneNumbersOrig
): ApplicationForView_application_MonitorApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_MonitorApplication_person_phoneNumbers = (
  deserialized: ApplicationForView_application_MonitorApplication_person_phoneNumbers
): ApplicationForView_application_MonitorApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_MonitorApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplicationOrig
): ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication
): ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplicationOrig
): ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication
): ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication = (
  serialized: ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_SlideApplicationOrig
): ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication = (
  deserialized: ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication
): ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application =
  | ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication
  | ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication
  | ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication
export const deserializeApplicationForView_application_MonitorApplication_person_benefitOutcomes_application = (
  serialized: ApplicationForView_application_MonitorApplication_person_benefitOutcomes_applicationOrig
): ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application'
  )
}
export const serializeApplicationForView_application_MonitorApplication_person_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application
): ApplicationForView_application_MonitorApplication_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application'
  )
}
export interface ApplicationForView_application_MonitorApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: ApplicationForView_application_MonitorApplication_person_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_MonitorApplication_person_benefitOutcomes = (
  serialized: ApplicationForView_application_MonitorApplication_person_benefitOutcomesOrig
): ApplicationForView_application_MonitorApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_MonitorApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_MonitorApplication_person_benefitOutcomes = (
  deserialized: ApplicationForView_application_MonitorApplication_person_benefitOutcomes
): ApplicationForView_application_MonitorApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_MonitorApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_MonitorApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_MonitorApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: ApplicationForView_application_MonitorApplication_person_incomeSources_esignSession_esignWizardsOrig
): ApplicationForView_application_MonitorApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_MonitorApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_MonitorApplication_person_incomeSources_esignSession_esignWizards
): ApplicationForView_application_MonitorApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_MonitorApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_MonitorApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_MonitorApplication_person_incomeSources_esignSession = (
  serialized: ApplicationForView_application_MonitorApplication_person_incomeSources_esignSessionOrig
): ApplicationForView_application_MonitorApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_MonitorApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_MonitorApplication_person_incomeSources_esignSession = (
  deserialized: ApplicationForView_application_MonitorApplication_person_incomeSources_esignSession
): ApplicationForView_application_MonitorApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_MonitorApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_MonitorApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: ApplicationForView_application_MonitorApplication_person_incomeSources_esignSession | null
}
export const deserializeApplicationForView_application_MonitorApplication_person_incomeSources = (
  serialized: ApplicationForView_application_MonitorApplication_person_incomeSourcesOrig
): ApplicationForView_application_MonitorApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_MonitorApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_MonitorApplication_person_incomeSources = (
  deserialized: ApplicationForView_application_MonitorApplication_person_incomeSources
): ApplicationForView_application_MonitorApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_MonitorApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_MonitorApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplicationForView_application_MonitorApplication_person_deductions = (
  serialized: ApplicationForView_application_MonitorApplication_person_deductionsOrig
): ApplicationForView_application_MonitorApplication_person_deductions => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_person_deductions = (
  deserialized: ApplicationForView_application_MonitorApplication_person_deductions
): ApplicationForView_application_MonitorApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards = (
  serialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizardsOrig
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession = (
  serialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSessionOrig
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession = (
  deserialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession | null
}
export const deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers = (
  serialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbersOrig
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers = (
  deserialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication = (
  serialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplicationOrig
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication = (
  serialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplicationOrig
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication = (
  serialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplicationOrig
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication = (
  deserialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application =
  | ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication
  | ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication
  | ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication
export const deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application = (
  serialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_applicationOrig
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application'
  )
}
export const serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application'
  )
}
export interface ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes = (
  serialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomesOrig
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes = (
  deserialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards = (
  serialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizardsOrig
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession = (
  serialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSessionOrig
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession = (
  deserialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession | null
}
export const deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources = (
  serialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSourcesOrig
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources = (
  deserialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_deductions = (
  serialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_deductionsOrig
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_deductions => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_deductions = (
  deserialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_deductions
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_deductionsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_assets = (
  serialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_assetsOrig
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_assets => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_assets = (
  deserialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_assets
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_assetsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_person_relationships_otherPerson {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes[]
  incomeSources: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources[]
  deductions: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  updatedAt: Date
  gender: string | null
  emailComment: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  assets: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson = (
  serialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPersonOrig
): ApplicationForView_application_MonitorApplication_person_relationships_otherPerson => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_deductions
  ),
  updatedAt: parseDate(serialized.updatedAt),
  assets: serialized.assets.map(
    deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson = (
  deserialized: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson
): ApplicationForView_application_MonitorApplication_person_relationships_otherPersonOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_deductions
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  assets: deserialized.assets.map(
    serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface ApplicationForView_application_MonitorApplication_person_relationships {
  __typename: 'Relationship'
  id: string
  relationshipType: string | null
  reverseRelationshipType: string | null
  liveTogether: boolean | null
  otherPerson: ApplicationForView_application_MonitorApplication_person_relationships_otherPerson
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeApplicationForView_application_MonitorApplication_person_relationships = (
  serialized: ApplicationForView_application_MonitorApplication_person_relationshipsOrig
): ApplicationForView_application_MonitorApplication_person_relationships => ({
  ...serialized,
  otherPerson: deserializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson(
    serialized.otherPerson
  ),
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeApplicationForView_application_MonitorApplication_person_relationships = (
  deserialized: ApplicationForView_application_MonitorApplication_person_relationships
): ApplicationForView_application_MonitorApplication_person_relationshipsOrig => ({
  ...deserialized,
  otherPerson: serializeApplicationForView_application_MonitorApplication_person_relationships_otherPerson(
    deserialized.otherPerson
  ),
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface ApplicationForView_application_MonitorApplication_person_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeApplicationForView_application_MonitorApplication_person_documentFiles = (
  serialized: ApplicationForView_application_MonitorApplication_person_documentFilesOrig
): ApplicationForView_application_MonitorApplication_person_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_MonitorApplication_person_documentFiles = (
  deserialized: ApplicationForView_application_MonitorApplication_person_documentFiles
): ApplicationForView_application_MonitorApplication_person_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_MonitorApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplicationForView_application_MonitorApplication_person_openApplications_MedicaidApplication = (
  serialized: ApplicationForView_application_MonitorApplication_person_openApplications_MedicaidApplicationOrig
): ApplicationForView_application_MonitorApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_MonitorApplication_person_openApplications_MedicaidApplication = (
  deserialized: ApplicationForView_application_MonitorApplication_person_openApplications_MedicaidApplication
): ApplicationForView_application_MonitorApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_MonitorApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplicationForView_application_MonitorApplication_person_openApplications_CharityCareApplication = (
  serialized: ApplicationForView_application_MonitorApplication_person_openApplications_CharityCareApplicationOrig
): ApplicationForView_application_MonitorApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_MonitorApplication_person_openApplications_CharityCareApplication = (
  deserialized: ApplicationForView_application_MonitorApplication_person_openApplications_CharityCareApplication
): ApplicationForView_application_MonitorApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_MonitorApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplicationForView_application_MonitorApplication_person_openApplications_SlideApplication = (
  serialized: ApplicationForView_application_MonitorApplication_person_openApplications_SlideApplicationOrig
): ApplicationForView_application_MonitorApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_MonitorApplication_person_openApplications_SlideApplication = (
  deserialized: ApplicationForView_application_MonitorApplication_person_openApplications_SlideApplication
): ApplicationForView_application_MonitorApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_MonitorApplication_person_openApplications_RyanWhiteApplication {
  __typename: 'RyanWhiteApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplicationForView_application_MonitorApplication_person_openApplications_RyanWhiteApplication = (
  serialized: ApplicationForView_application_MonitorApplication_person_openApplications_RyanWhiteApplicationOrig
): ApplicationForView_application_MonitorApplication_person_openApplications_RyanWhiteApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_MonitorApplication_person_openApplications_RyanWhiteApplication = (
  deserialized: ApplicationForView_application_MonitorApplication_person_openApplications_RyanWhiteApplication
): ApplicationForView_application_MonitorApplication_person_openApplications_RyanWhiteApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type ApplicationForView_application_MonitorApplication_person_openApplications =
  | ApplicationForView_application_MonitorApplication_person_openApplications_MedicaidApplication
  | ApplicationForView_application_MonitorApplication_person_openApplications_CharityCareApplication
  | ApplicationForView_application_MonitorApplication_person_openApplications_SlideApplication
  | ApplicationForView_application_MonitorApplication_person_openApplications_RyanWhiteApplication
export const deserializeApplicationForView_application_MonitorApplication_person_openApplications = (
  serialized: ApplicationForView_application_MonitorApplication_person_openApplicationsOrig
): ApplicationForView_application_MonitorApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeApplicationForView_application_MonitorApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_MonitorApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_MonitorApplication_person_openApplications_SlideApplication(
      serialized
    )
  if (false || serialized.__typename === 'RyanWhiteApplication')
    return deserializeApplicationForView_application_MonitorApplication_person_openApplications_RyanWhiteApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MonitorApplication_person_openApplications'
  )
}
export const serializeApplicationForView_application_MonitorApplication_person_openApplications = (
  deserialized: ApplicationForView_application_MonitorApplication_person_openApplications
): ApplicationForView_application_MonitorApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeApplicationForView_application_MonitorApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_MonitorApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_MonitorApplication_person_openApplications_SlideApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'RyanWhiteApplication')
    return serializeApplicationForView_application_MonitorApplication_person_openApplications_RyanWhiteApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MonitorApplication_person_openApplications'
  )
}
export interface ApplicationForView_application_MonitorApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplicationForView_application_MonitorApplication_person_openHouseholdMemberApplications = (
  serialized: ApplicationForView_application_MonitorApplication_person_openHouseholdMemberApplicationsOrig
): ApplicationForView_application_MonitorApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_MonitorApplication_person_openHouseholdMemberApplications = (
  deserialized: ApplicationForView_application_MonitorApplication_person_openHouseholdMemberApplications
): ApplicationForView_application_MonitorApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_MonitorApplication_person_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeApplicationForView_application_MonitorApplication_person_assets = (
  serialized: ApplicationForView_application_MonitorApplication_person_assetsOrig
): ApplicationForView_application_MonitorApplication_person_assets => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_person_assets = (
  deserialized: ApplicationForView_application_MonitorApplication_person_assets
): ApplicationForView_application_MonitorApplication_person_assetsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_person_accounts {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
}
export const deserializeApplicationForView_application_MonitorApplication_person_accounts = (
  serialized: ApplicationForView_application_MonitorApplication_person_accountsOrig
): ApplicationForView_application_MonitorApplication_person_accounts => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
})
export const serializeApplicationForView_application_MonitorApplication_person_accounts = (
  deserialized: ApplicationForView_application_MonitorApplication_person_accounts
): ApplicationForView_application_MonitorApplication_person_accountsOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
})
export interface ApplicationForView_application_MonitorApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: ApplicationForView_application_MonitorApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: ApplicationForView_application_MonitorApplication_person_benefitOutcomes[]
  incomeSources: ApplicationForView_application_MonitorApplication_person_incomeSources[]
  deductions: ApplicationForView_application_MonitorApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  relationships: ApplicationForView_application_MonitorApplication_person_relationships[]
  documentFiles: ApplicationForView_application_MonitorApplication_person_documentFiles[]
  openApplications: ApplicationForView_application_MonitorApplication_person_openApplications[]
  openHouseholdMemberApplications: ApplicationForView_application_MonitorApplication_person_openHouseholdMemberApplications[]
  updatedAt: Date
  gender: string | null
  emailComment: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  assets: ApplicationForView_application_MonitorApplication_person_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  accounts: ApplicationForView_application_MonitorApplication_person_accounts[]
}
export const deserializeApplicationForView_application_MonitorApplication_person = (
  serialized: ApplicationForView_application_MonitorApplication_personOrig
): ApplicationForView_application_MonitorApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplicationForView_application_MonitorApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_MonitorApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplicationForView_application_MonitorApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplicationForView_application_MonitorApplication_person_deductions
  ),
  relationships: serialized.relationships.map(
    deserializeApplicationForView_application_MonitorApplication_person_relationships
  ),
  documentFiles: serialized.documentFiles.map(
    deserializeApplicationForView_application_MonitorApplication_person_documentFiles
  ),
  openApplications: serialized.openApplications.map(
    deserializeApplicationForView_application_MonitorApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeApplicationForView_application_MonitorApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: parseDate(serialized.updatedAt),
  assets: serialized.assets.map(
    deserializeApplicationForView_application_MonitorApplication_person_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  accounts: serialized.accounts.map(
    deserializeApplicationForView_application_MonitorApplication_person_accounts
  ),
})
export const serializeApplicationForView_application_MonitorApplication_person = (
  deserialized: ApplicationForView_application_MonitorApplication_person
): ApplicationForView_application_MonitorApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplicationForView_application_MonitorApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_MonitorApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplicationForView_application_MonitorApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplicationForView_application_MonitorApplication_person_deductions
  ),
  relationships: deserialized.relationships.map(
    serializeApplicationForView_application_MonitorApplication_person_relationships
  ),
  documentFiles: deserialized.documentFiles.map(
    serializeApplicationForView_application_MonitorApplication_person_documentFiles
  ),
  openApplications: deserialized.openApplications.map(
    serializeApplicationForView_application_MonitorApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeApplicationForView_application_MonitorApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  assets: deserialized.assets.map(
    serializeApplicationForView_application_MonitorApplication_person_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  accounts: deserialized.accounts.map(
    serializeApplicationForView_application_MonitorApplication_person_accounts
  ),
})
export interface ApplicationForView_application_MonitorApplication_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeApplicationForView_application_MonitorApplication_assignedTo = (
  serialized: ApplicationForView_application_MonitorApplication_assignedToOrig
): ApplicationForView_application_MonitorApplication_assignedTo => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_assignedTo = (
  deserialized: ApplicationForView_application_MonitorApplication_assignedTo
): ApplicationForView_application_MonitorApplication_assignedToOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards = (
  serialized: ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession_esignWizardsOrig
): ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards
): ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession = (
  serialized: ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSessionOrig
): ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession = (
  deserialized: ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession
): ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession | null
}
export const deserializeApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers = (
  serialized: ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbersOrig
): ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers = (
  deserialized: ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers
): ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplicationOrig
): ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication
): ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplicationOrig
): ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication
): ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_SlideApplication = (
  serialized: ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_SlideApplicationOrig
): ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_SlideApplication = (
  deserialized: ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_SlideApplication
): ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application =
  | ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication
  | ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication
  | ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_SlideApplication
export const deserializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application = (
  serialized: ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_applicationOrig
): ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application'
  )
}
export const serializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application
): ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application'
  )
}
export interface ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes = (
  serialized: ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomesOrig
): ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes = (
  deserialized: ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes
): ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession_esignWizards = (
  serialized: ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession_esignWizardsOrig
): ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession_esignWizards
): ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession = (
  serialized: ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSessionOrig
): ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession = (
  deserialized: ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession
): ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession | null
}
export const deserializeApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources = (
  serialized: ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSourcesOrig
): ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources = (
  deserialized: ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources
): ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_MonitorApplication_householdMembers_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplicationForView_application_MonitorApplication_householdMembers_person_deductions = (
  serialized: ApplicationForView_application_MonitorApplication_householdMembers_person_deductionsOrig
): ApplicationForView_application_MonitorApplication_householdMembers_person_deductions => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_householdMembers_person_deductions = (
  deserialized: ApplicationForView_application_MonitorApplication_householdMembers_person_deductions
): ApplicationForView_application_MonitorApplication_householdMembers_person_deductionsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_householdMembers_person_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeApplicationForView_application_MonitorApplication_householdMembers_person_documentFiles = (
  serialized: ApplicationForView_application_MonitorApplication_householdMembers_person_documentFilesOrig
): ApplicationForView_application_MonitorApplication_householdMembers_person_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_MonitorApplication_householdMembers_person_documentFiles = (
  deserialized: ApplicationForView_application_MonitorApplication_householdMembers_person_documentFiles
): ApplicationForView_application_MonitorApplication_householdMembers_person_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_MonitorApplication_householdMembers_person_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeApplicationForView_application_MonitorApplication_householdMembers_person_assets = (
  serialized: ApplicationForView_application_MonitorApplication_householdMembers_person_assetsOrig
): ApplicationForView_application_MonitorApplication_householdMembers_person_assets => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_householdMembers_person_assets = (
  deserialized: ApplicationForView_application_MonitorApplication_householdMembers_person_assets
): ApplicationForView_application_MonitorApplication_householdMembers_person_assetsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_householdMembers_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: ApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: ApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes[]
  incomeSources: ApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources[]
  deductions: ApplicationForView_application_MonitorApplication_householdMembers_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  documentFiles: ApplicationForView_application_MonitorApplication_householdMembers_person_documentFiles[]
  updatedAt: Date
  gender: string | null
  emailComment: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  assets: ApplicationForView_application_MonitorApplication_householdMembers_person_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
}
export const deserializeApplicationForView_application_MonitorApplication_householdMembers_person = (
  serialized: ApplicationForView_application_MonitorApplication_householdMembers_personOrig
): ApplicationForView_application_MonitorApplication_householdMembers_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplicationForView_application_MonitorApplication_householdMembers_person_deductions
  ),
  documentFiles: serialized.documentFiles.map(
    deserializeApplicationForView_application_MonitorApplication_householdMembers_person_documentFiles
  ),
  updatedAt: parseDate(serialized.updatedAt),
  assets: serialized.assets.map(
    deserializeApplicationForView_application_MonitorApplication_householdMembers_person_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
})
export const serializeApplicationForView_application_MonitorApplication_householdMembers_person = (
  deserialized: ApplicationForView_application_MonitorApplication_householdMembers_person
): ApplicationForView_application_MonitorApplication_householdMembers_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplicationForView_application_MonitorApplication_householdMembers_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_MonitorApplication_householdMembers_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplicationForView_application_MonitorApplication_householdMembers_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplicationForView_application_MonitorApplication_householdMembers_person_deductions
  ),
  documentFiles: deserialized.documentFiles.map(
    serializeApplicationForView_application_MonitorApplication_householdMembers_person_documentFiles
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  assets: deserialized.assets.map(
    serializeApplicationForView_application_MonitorApplication_householdMembers_person_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
})
export interface ApplicationForView_application_MonitorApplication_householdMembers {
  __typename: 'ApplicationHouseholdMember'
  id: string
  wantsCoverage: boolean | null
  person: ApplicationForView_application_MonitorApplication_householdMembers_person
  createdAt: Date
}
export const deserializeApplicationForView_application_MonitorApplication_householdMembers = (
  serialized: ApplicationForView_application_MonitorApplication_householdMembersOrig
): ApplicationForView_application_MonitorApplication_householdMembers => ({
  ...serialized,
  person: deserializeApplicationForView_application_MonitorApplication_householdMembers_person(
    serialized.person
  ),
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_MonitorApplication_householdMembers = (
  deserialized: ApplicationForView_application_MonitorApplication_householdMembers
): ApplicationForView_application_MonitorApplication_householdMembersOrig => ({
  ...deserialized,
  person: serializeApplicationForView_application_MonitorApplication_householdMembers_person(
    deserialized.person
  ),
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_MonitorApplication_documents_files {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeApplicationForView_application_MonitorApplication_documents_files = (
  serialized: ApplicationForView_application_MonitorApplication_documents_filesOrig
): ApplicationForView_application_MonitorApplication_documents_files => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_MonitorApplication_documents_files = (
  deserialized: ApplicationForView_application_MonitorApplication_documents_files
): ApplicationForView_application_MonitorApplication_documents_filesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_MonitorApplication_documents_person {
  __typename: 'Person'
  id: string
}
export const deserializeApplicationForView_application_MonitorApplication_documents_person = (
  serialized: ApplicationForView_application_MonitorApplication_documents_personOrig
): ApplicationForView_application_MonitorApplication_documents_person => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_documents_person = (
  deserialized: ApplicationForView_application_MonitorApplication_documents_person
): ApplicationForView_application_MonitorApplication_documents_personOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_documents_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeApplicationForView_application_MonitorApplication_documents_application = (
  serialized: ApplicationForView_application_MonitorApplication_documents_applicationOrig
): ApplicationForView_application_MonitorApplication_documents_application => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_documents_application = (
  deserialized: ApplicationForView_application_MonitorApplication_documents_application
): ApplicationForView_application_MonitorApplication_documents_applicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_documents {
  __typename: 'Document'
  id: string
  documentType: string
  complete: boolean | null
  files: ApplicationForView_application_MonitorApplication_documents_files[]
  updatedAt: Date
  notes: string | null
  person: ApplicationForView_application_MonitorApplication_documents_person | null
  application: ApplicationForView_application_MonitorApplication_documents_application
}
export const deserializeApplicationForView_application_MonitorApplication_documents = (
  serialized: ApplicationForView_application_MonitorApplication_documentsOrig
): ApplicationForView_application_MonitorApplication_documents => ({
  ...serialized,
  files: serialized.files.map(
    deserializeApplicationForView_application_MonitorApplication_documents_files
  ),
  updatedAt: parseDate(serialized.updatedAt),
  person:
    serialized.person != null
      ? deserializeApplicationForView_application_MonitorApplication_documents_person(
          serialized.person
        )
      : serialized.person,
  application: deserializeApplicationForView_application_MonitorApplication_documents_application(
    serialized.application
  ),
})
export const serializeApplicationForView_application_MonitorApplication_documents = (
  deserialized: ApplicationForView_application_MonitorApplication_documents
): ApplicationForView_application_MonitorApplication_documentsOrig => ({
  ...deserialized,
  files: deserialized.files.map(
    serializeApplicationForView_application_MonitorApplication_documents_files
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  person:
    deserialized.person != null
      ? serializeApplicationForView_application_MonitorApplication_documents_person(
          deserialized.person
        )
      : deserialized.person,
  application: serializeApplicationForView_application_MonitorApplication_documents_application(
    deserialized.application
  ),
})
export interface ApplicationForView_application_MonitorApplication_documentFiles_documents {
  __typename: 'Document'
  id: string
}
export const deserializeApplicationForView_application_MonitorApplication_documentFiles_documents = (
  serialized: ApplicationForView_application_MonitorApplication_documentFiles_documentsOrig
): ApplicationForView_application_MonitorApplication_documentFiles_documents => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_documentFiles_documents = (
  deserialized: ApplicationForView_application_MonitorApplication_documentFiles_documents
): ApplicationForView_application_MonitorApplication_documentFiles_documentsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
  documents: ApplicationForView_application_MonitorApplication_documentFiles_documents[]
}
export const deserializeApplicationForView_application_MonitorApplication_documentFiles = (
  serialized: ApplicationForView_application_MonitorApplication_documentFilesOrig
): ApplicationForView_application_MonitorApplication_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
  documents: serialized.documents.map(
    deserializeApplicationForView_application_MonitorApplication_documentFiles_documents
  ),
})
export const serializeApplicationForView_application_MonitorApplication_documentFiles = (
  deserialized: ApplicationForView_application_MonitorApplication_documentFiles
): ApplicationForView_application_MonitorApplication_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
  documents: deserialized.documents.map(
    serializeApplicationForView_application_MonitorApplication_documentFiles_documents
  ),
})
export interface ApplicationForView_application_MonitorApplication_benefitOutcomes_person {
  __typename: 'Person'
  id: string
}
export const deserializeApplicationForView_application_MonitorApplication_benefitOutcomes_person = (
  serialized: ApplicationForView_application_MonitorApplication_benefitOutcomes_personOrig
): ApplicationForView_application_MonitorApplication_benefitOutcomes_person => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_benefitOutcomes_person = (
  deserialized: ApplicationForView_application_MonitorApplication_benefitOutcomes_person
): ApplicationForView_application_MonitorApplication_benefitOutcomes_personOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_benefitOutcomes_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeApplicationForView_application_MonitorApplication_benefitOutcomes_application = (
  serialized: ApplicationForView_application_MonitorApplication_benefitOutcomes_applicationOrig
): ApplicationForView_application_MonitorApplication_benefitOutcomes_application => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_MonitorApplication_benefitOutcomes_application
): ApplicationForView_application_MonitorApplication_benefitOutcomes_applicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  person: ApplicationForView_application_MonitorApplication_benefitOutcomes_person
  application: ApplicationForView_application_MonitorApplication_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_MonitorApplication_benefitOutcomes = (
  serialized: ApplicationForView_application_MonitorApplication_benefitOutcomesOrig
): ApplicationForView_application_MonitorApplication_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  person: deserializeApplicationForView_application_MonitorApplication_benefitOutcomes_person(
    serialized.person
  ),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_MonitorApplication_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_MonitorApplication_benefitOutcomes = (
  deserialized: ApplicationForView_application_MonitorApplication_benefitOutcomes
): ApplicationForView_application_MonitorApplication_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  person: serializeApplicationForView_application_MonitorApplication_benefitOutcomes_person(
    deserialized.person
  ),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_MonitorApplication_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_MonitorApplication_accountOnDateOfService {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
  timeOfService: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location: string | null
  insuranceType: string
  status: string
  createdAt: Date
  updatedAt: Date
  notes: string | null
  accountAmount: number | null
  njmmisCheck: string | null
  previousInsurance: string | null
  invoiceMonth: string | null
  invoiceYear: string | null
  dischargeDate: Date | null
  isScheduled: boolean
}
export const deserializeApplicationForView_application_MonitorApplication_accountOnDateOfService = (
  serialized: ApplicationForView_application_MonitorApplication_accountOnDateOfServiceOrig
): ApplicationForView_application_MonitorApplication_accountOnDateOfService => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
})
export const serializeApplicationForView_application_MonitorApplication_accountOnDateOfService = (
  deserialized: ApplicationForView_application_MonitorApplication_accountOnDateOfService
): ApplicationForView_application_MonitorApplication_accountOnDateOfServiceOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
})
export interface ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards = (
  serialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizardsOrig
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession = (
  serialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSessionOrig
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession = (
  deserialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession | null
}
export const deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers = (
  serialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbersOrig
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers = (
  deserialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication = (
  serialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplicationOrig
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication = (
  serialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplicationOrig
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication = (
  serialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplicationOrig
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication = (
  deserialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application =
  | ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication
  | ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication
  | ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication
export const deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application = (
  serialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_applicationOrig
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application'
  )
}
export const serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application'
  )
}
export interface ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes = (
  serialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomesOrig
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes = (
  deserialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards = (
  serialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession_esignWizardsOrig
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession = (
  serialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSessionOrig
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession = (
  deserialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession | null
}
export const deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources = (
  serialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSourcesOrig
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources = (
  deserialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_MonitorApplication_primaryPointOfContact_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_deductions = (
  serialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_deductionsOrig
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_deductions => ({
  ...serialized,
})
export const serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_deductions = (
  deserialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact_deductions
): ApplicationForView_application_MonitorApplication_primaryPointOfContact_deductionsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_MonitorApplication_primaryPointOfContact {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: ApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: ApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes[]
  incomeSources: ApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources[]
  deductions: ApplicationForView_application_MonitorApplication_primaryPointOfContact_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
}
export const deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact = (
  serialized: ApplicationForView_application_MonitorApplication_primaryPointOfContactOrig
): ApplicationForView_application_MonitorApplication_primaryPointOfContact => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact_deductions
  ),
})
export const serializeApplicationForView_application_MonitorApplication_primaryPointOfContact = (
  deserialized: ApplicationForView_application_MonitorApplication_primaryPointOfContact
): ApplicationForView_application_MonitorApplication_primaryPointOfContactOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplicationForView_application_MonitorApplication_primaryPointOfContact_deductions
  ),
})
export interface ApplicationForView_application_MonitorApplication {
  __typename: 'MonitorApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  readyForAudit: boolean
  person: ApplicationForView_application_MonitorApplication_person
  assignedTo: ApplicationForView_application_MonitorApplication_assignedTo | null
  householdMembers: ApplicationForView_application_MonitorApplication_householdMembers[]
  documents: ApplicationForView_application_MonitorApplication_documents[]
  documentFiles: ApplicationForView_application_MonitorApplication_documentFiles[]
  benefitOutcomes: ApplicationForView_application_MonitorApplication_benefitOutcomes[]
  accountOnDateOfService: ApplicationForView_application_MonitorApplication_accountOnDateOfService | null
  submitDate: Date | null
  applicationType: string | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  darSubmitDate: Date | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  primaryPointOfContact: ApplicationForView_application_MonitorApplication_primaryPointOfContact | null
  initialDateOfService: Date | null
}
export const deserializeApplicationForView_application_MonitorApplication = (
  serialized: ApplicationForView_application_MonitorApplicationOrig
): ApplicationForView_application_MonitorApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  person: deserializeApplicationForView_application_MonitorApplication_person(
    serialized.person
  ),
  assignedTo:
    serialized.assignedTo != null
      ? deserializeApplicationForView_application_MonitorApplication_assignedTo(
          serialized.assignedTo
        )
      : serialized.assignedTo,
  householdMembers: serialized.householdMembers.map(
    deserializeApplicationForView_application_MonitorApplication_householdMembers
  ),
  documents: serialized.documents.map(
    deserializeApplicationForView_application_MonitorApplication_documents
  ),
  documentFiles: serialized.documentFiles.map(
    deserializeApplicationForView_application_MonitorApplication_documentFiles
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_MonitorApplication_benefitOutcomes
  ),
  accountOnDateOfService:
    serialized.accountOnDateOfService != null
      ? deserializeApplicationForView_application_MonitorApplication_accountOnDateOfService(
          serialized.accountOnDateOfService
        )
      : serialized.accountOnDateOfService,
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  darSubmitDate:
    serialized.darSubmitDate != null
      ? parseDate(serialized.darSubmitDate)
      : serialized.darSubmitDate,
  primaryPointOfContact:
    serialized.primaryPointOfContact != null
      ? deserializeApplicationForView_application_MonitorApplication_primaryPointOfContact(
          serialized.primaryPointOfContact
        )
      : serialized.primaryPointOfContact,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
})
export const serializeApplicationForView_application_MonitorApplication = (
  deserialized: ApplicationForView_application_MonitorApplication
): ApplicationForView_application_MonitorApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  person: serializeApplicationForView_application_MonitorApplication_person(
    deserialized.person
  ),
  assignedTo:
    deserialized.assignedTo != null
      ? serializeApplicationForView_application_MonitorApplication_assignedTo(
          deserialized.assignedTo
        )
      : deserialized.assignedTo,
  householdMembers: deserialized.householdMembers.map(
    serializeApplicationForView_application_MonitorApplication_householdMembers
  ),
  documents: deserialized.documents.map(
    serializeApplicationForView_application_MonitorApplication_documents
  ),
  documentFiles: deserialized.documentFiles.map(
    serializeApplicationForView_application_MonitorApplication_documentFiles
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_MonitorApplication_benefitOutcomes
  ),
  accountOnDateOfService:
    deserialized.accountOnDateOfService != null
      ? serializeApplicationForView_application_MonitorApplication_accountOnDateOfService(
          deserialized.accountOnDateOfService
        )
      : deserialized.accountOnDateOfService,
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  darSubmitDate:
    deserialized.darSubmitDate != null
      ? formatISODate(deserialized.darSubmitDate)
      : deserialized.darSubmitDate,
  primaryPointOfContact:
    deserialized.primaryPointOfContact != null
      ? serializeApplicationForView_application_MonitorApplication_primaryPointOfContact(
          deserialized.primaryPointOfContact
        )
      : deserialized.primaryPointOfContact,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
})
export interface ApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: ApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): ApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards
): ApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession = (
  serialized: ApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSessionOrig
): ApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession
): ApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_CharityCareApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: ApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession | null
}
export const deserializeApplicationForView_application_CharityCareApplication_person_phoneNumbers = (
  serialized: ApplicationForView_application_CharityCareApplication_person_phoneNumbersOrig
): ApplicationForView_application_CharityCareApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_CharityCareApplication_person_phoneNumbers = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_phoneNumbers
): ApplicationForView_application_CharityCareApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_CharityCareApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplicationOrig
): ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication
): ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplicationOrig
): ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication
): ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication = (
  serialized: ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplicationOrig
): ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication
): ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application =
  | ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication
  | ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication
  | ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication
export const deserializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application = (
  serialized: ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_applicationOrig
): ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application'
  )
}
export const serializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application
): ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application'
  )
}
export interface ApplicationForView_application_CharityCareApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: ApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes = (
  serialized: ApplicationForView_application_CharityCareApplication_person_benefitOutcomesOrig
): ApplicationForView_application_CharityCareApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_benefitOutcomes
): ApplicationForView_application_CharityCareApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: ApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession_esignWizardsOrig
): ApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards
): ApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession = (
  serialized: ApplicationForView_application_CharityCareApplication_person_incomeSources_esignSessionOrig
): ApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession
): ApplicationForView_application_CharityCareApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_CharityCareApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: ApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession | null
}
export const deserializeApplicationForView_application_CharityCareApplication_person_incomeSources = (
  serialized: ApplicationForView_application_CharityCareApplication_person_incomeSourcesOrig
): ApplicationForView_application_CharityCareApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_CharityCareApplication_person_incomeSources = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_incomeSources
): ApplicationForView_application_CharityCareApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_CharityCareApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_CharityCareApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplicationForView_application_CharityCareApplication_person_deductions = (
  serialized: ApplicationForView_application_CharityCareApplication_person_deductionsOrig
): ApplicationForView_application_CharityCareApplication_person_deductions => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_person_deductions = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_deductions
): ApplicationForView_application_CharityCareApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards = (
  serialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizardsOrig
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession = (
  serialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSessionOrig
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession | null
}
export const deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers = (
  serialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbersOrig
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication = (
  serialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplicationOrig
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication = (
  serialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplicationOrig
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication = (
  serialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplicationOrig
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application =
  | ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication
  | ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication
  | ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication
export const deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application = (
  serialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_applicationOrig
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application'
  )
}
export const serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application'
  )
}
export interface ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes = (
  serialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomesOrig
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards = (
  serialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizardsOrig
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession = (
  serialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSessionOrig
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession | null
}
export const deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources = (
  serialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSourcesOrig
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_deductions = (
  serialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_deductionsOrig
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_deductions => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_deductions = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_deductions
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_deductionsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_assets = (
  serialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_assetsOrig
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_assets => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_assets = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_assets
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_assetsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes[]
  incomeSources: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources[]
  deductions: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  updatedAt: Date
  gender: string | null
  emailComment: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  assets: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson = (
  serialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPersonOrig
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_deductions
  ),
  updatedAt: parseDate(serialized.updatedAt),
  assets: serialized.assets.map(
    deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson
): ApplicationForView_application_CharityCareApplication_person_relationships_otherPersonOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_deductions
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  assets: deserialized.assets.map(
    serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface ApplicationForView_application_CharityCareApplication_person_relationships {
  __typename: 'Relationship'
  id: string
  relationshipType: string | null
  reverseRelationshipType: string | null
  liveTogether: boolean | null
  otherPerson: ApplicationForView_application_CharityCareApplication_person_relationships_otherPerson
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeApplicationForView_application_CharityCareApplication_person_relationships = (
  serialized: ApplicationForView_application_CharityCareApplication_person_relationshipsOrig
): ApplicationForView_application_CharityCareApplication_person_relationships => ({
  ...serialized,
  otherPerson: deserializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson(
    serialized.otherPerson
  ),
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeApplicationForView_application_CharityCareApplication_person_relationships = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_relationships
): ApplicationForView_application_CharityCareApplication_person_relationshipsOrig => ({
  ...deserialized,
  otherPerson: serializeApplicationForView_application_CharityCareApplication_person_relationships_otherPerson(
    deserialized.otherPerson
  ),
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface ApplicationForView_application_CharityCareApplication_person_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeApplicationForView_application_CharityCareApplication_person_documentFiles = (
  serialized: ApplicationForView_application_CharityCareApplication_person_documentFilesOrig
): ApplicationForView_application_CharityCareApplication_person_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_CharityCareApplication_person_documentFiles = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_documentFiles
): ApplicationForView_application_CharityCareApplication_person_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_CharityCareApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplicationForView_application_CharityCareApplication_person_openApplications_MedicaidApplication = (
  serialized: ApplicationForView_application_CharityCareApplication_person_openApplications_MedicaidApplicationOrig
): ApplicationForView_application_CharityCareApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_CharityCareApplication_person_openApplications_MedicaidApplication = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_openApplications_MedicaidApplication
): ApplicationForView_application_CharityCareApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_CharityCareApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplicationForView_application_CharityCareApplication_person_openApplications_CharityCareApplication = (
  serialized: ApplicationForView_application_CharityCareApplication_person_openApplications_CharityCareApplicationOrig
): ApplicationForView_application_CharityCareApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_CharityCareApplication_person_openApplications_CharityCareApplication = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_openApplications_CharityCareApplication
): ApplicationForView_application_CharityCareApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_CharityCareApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplicationForView_application_CharityCareApplication_person_openApplications_SlideApplication = (
  serialized: ApplicationForView_application_CharityCareApplication_person_openApplications_SlideApplicationOrig
): ApplicationForView_application_CharityCareApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_CharityCareApplication_person_openApplications_SlideApplication = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_openApplications_SlideApplication
): ApplicationForView_application_CharityCareApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_CharityCareApplication_person_openApplications_RyanWhiteApplication {
  __typename: 'RyanWhiteApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplicationForView_application_CharityCareApplication_person_openApplications_RyanWhiteApplication = (
  serialized: ApplicationForView_application_CharityCareApplication_person_openApplications_RyanWhiteApplicationOrig
): ApplicationForView_application_CharityCareApplication_person_openApplications_RyanWhiteApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_CharityCareApplication_person_openApplications_RyanWhiteApplication = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_openApplications_RyanWhiteApplication
): ApplicationForView_application_CharityCareApplication_person_openApplications_RyanWhiteApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type ApplicationForView_application_CharityCareApplication_person_openApplications =
  | ApplicationForView_application_CharityCareApplication_person_openApplications_MedicaidApplication
  | ApplicationForView_application_CharityCareApplication_person_openApplications_CharityCareApplication
  | ApplicationForView_application_CharityCareApplication_person_openApplications_SlideApplication
  | ApplicationForView_application_CharityCareApplication_person_openApplications_RyanWhiteApplication
export const deserializeApplicationForView_application_CharityCareApplication_person_openApplications = (
  serialized: ApplicationForView_application_CharityCareApplication_person_openApplicationsOrig
): ApplicationForView_application_CharityCareApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeApplicationForView_application_CharityCareApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_CharityCareApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_CharityCareApplication_person_openApplications_SlideApplication(
      serialized
    )
  if (false || serialized.__typename === 'RyanWhiteApplication')
    return deserializeApplicationForView_application_CharityCareApplication_person_openApplications_RyanWhiteApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_CharityCareApplication_person_openApplications'
  )
}
export const serializeApplicationForView_application_CharityCareApplication_person_openApplications = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_openApplications
): ApplicationForView_application_CharityCareApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeApplicationForView_application_CharityCareApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_CharityCareApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_CharityCareApplication_person_openApplications_SlideApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'RyanWhiteApplication')
    return serializeApplicationForView_application_CharityCareApplication_person_openApplications_RyanWhiteApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_CharityCareApplication_person_openApplications'
  )
}
export interface ApplicationForView_application_CharityCareApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplicationForView_application_CharityCareApplication_person_openHouseholdMemberApplications = (
  serialized: ApplicationForView_application_CharityCareApplication_person_openHouseholdMemberApplicationsOrig
): ApplicationForView_application_CharityCareApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_CharityCareApplication_person_openHouseholdMemberApplications = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_openHouseholdMemberApplications
): ApplicationForView_application_CharityCareApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_CharityCareApplication_person_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeApplicationForView_application_CharityCareApplication_person_assets = (
  serialized: ApplicationForView_application_CharityCareApplication_person_assetsOrig
): ApplicationForView_application_CharityCareApplication_person_assets => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_person_assets = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_assets
): ApplicationForView_application_CharityCareApplication_person_assetsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_person_accounts {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
}
export const deserializeApplicationForView_application_CharityCareApplication_person_accounts = (
  serialized: ApplicationForView_application_CharityCareApplication_person_accountsOrig
): ApplicationForView_application_CharityCareApplication_person_accounts => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
})
export const serializeApplicationForView_application_CharityCareApplication_person_accounts = (
  deserialized: ApplicationForView_application_CharityCareApplication_person_accounts
): ApplicationForView_application_CharityCareApplication_person_accountsOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
})
export interface ApplicationForView_application_CharityCareApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: ApplicationForView_application_CharityCareApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: ApplicationForView_application_CharityCareApplication_person_benefitOutcomes[]
  incomeSources: ApplicationForView_application_CharityCareApplication_person_incomeSources[]
  deductions: ApplicationForView_application_CharityCareApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  relationships: ApplicationForView_application_CharityCareApplication_person_relationships[]
  documentFiles: ApplicationForView_application_CharityCareApplication_person_documentFiles[]
  openApplications: ApplicationForView_application_CharityCareApplication_person_openApplications[]
  openHouseholdMemberApplications: ApplicationForView_application_CharityCareApplication_person_openHouseholdMemberApplications[]
  updatedAt: Date
  gender: string | null
  emailComment: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  assets: ApplicationForView_application_CharityCareApplication_person_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  accounts: ApplicationForView_application_CharityCareApplication_person_accounts[]
}
export const deserializeApplicationForView_application_CharityCareApplication_person = (
  serialized: ApplicationForView_application_CharityCareApplication_personOrig
): ApplicationForView_application_CharityCareApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplicationForView_application_CharityCareApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplicationForView_application_CharityCareApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplicationForView_application_CharityCareApplication_person_deductions
  ),
  relationships: serialized.relationships.map(
    deserializeApplicationForView_application_CharityCareApplication_person_relationships
  ),
  documentFiles: serialized.documentFiles.map(
    deserializeApplicationForView_application_CharityCareApplication_person_documentFiles
  ),
  openApplications: serialized.openApplications.map(
    deserializeApplicationForView_application_CharityCareApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeApplicationForView_application_CharityCareApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: parseDate(serialized.updatedAt),
  assets: serialized.assets.map(
    deserializeApplicationForView_application_CharityCareApplication_person_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  accounts: serialized.accounts.map(
    deserializeApplicationForView_application_CharityCareApplication_person_accounts
  ),
})
export const serializeApplicationForView_application_CharityCareApplication_person = (
  deserialized: ApplicationForView_application_CharityCareApplication_person
): ApplicationForView_application_CharityCareApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplicationForView_application_CharityCareApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_CharityCareApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplicationForView_application_CharityCareApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplicationForView_application_CharityCareApplication_person_deductions
  ),
  relationships: deserialized.relationships.map(
    serializeApplicationForView_application_CharityCareApplication_person_relationships
  ),
  documentFiles: deserialized.documentFiles.map(
    serializeApplicationForView_application_CharityCareApplication_person_documentFiles
  ),
  openApplications: deserialized.openApplications.map(
    serializeApplicationForView_application_CharityCareApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeApplicationForView_application_CharityCareApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  assets: deserialized.assets.map(
    serializeApplicationForView_application_CharityCareApplication_person_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  accounts: deserialized.accounts.map(
    serializeApplicationForView_application_CharityCareApplication_person_accounts
  ),
})
export interface ApplicationForView_application_CharityCareApplication_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeApplicationForView_application_CharityCareApplication_assignedTo = (
  serialized: ApplicationForView_application_CharityCareApplication_assignedToOrig
): ApplicationForView_application_CharityCareApplication_assignedTo => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_assignedTo = (
  deserialized: ApplicationForView_application_CharityCareApplication_assignedTo
): ApplicationForView_application_CharityCareApplication_assignedToOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards = (
  serialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession_esignWizardsOrig
): ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards
): ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession = (
  serialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSessionOrig
): ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession = (
  deserialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession
): ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession | null
}
export const deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers = (
  serialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbersOrig
): ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers = (
  deserialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers
): ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplicationOrig
): ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication
): ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplicationOrig
): ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication
): ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_SlideApplication = (
  serialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_SlideApplicationOrig
): ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_SlideApplication = (
  deserialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_SlideApplication
): ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application =
  | ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication
  | ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication
  | ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_SlideApplication
export const deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application = (
  serialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_applicationOrig
): ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application'
  )
}
export const serializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application
): ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application'
  )
}
export interface ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes = (
  serialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomesOrig
): ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes = (
  deserialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes
): ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession_esignWizards = (
  serialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession_esignWizardsOrig
): ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession_esignWizards
): ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession = (
  serialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSessionOrig
): ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession = (
  deserialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession
): ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession | null
}
export const deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources = (
  serialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSourcesOrig
): ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources = (
  deserialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources
): ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_CharityCareApplication_householdMembers_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_deductions = (
  serialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_deductionsOrig
): ApplicationForView_application_CharityCareApplication_householdMembers_person_deductions => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_householdMembers_person_deductions = (
  deserialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_deductions
): ApplicationForView_application_CharityCareApplication_householdMembers_person_deductionsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_householdMembers_person_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_documentFiles = (
  serialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_documentFilesOrig
): ApplicationForView_application_CharityCareApplication_householdMembers_person_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_CharityCareApplication_householdMembers_person_documentFiles = (
  deserialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_documentFiles
): ApplicationForView_application_CharityCareApplication_householdMembers_person_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_CharityCareApplication_householdMembers_person_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_assets = (
  serialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_assetsOrig
): ApplicationForView_application_CharityCareApplication_householdMembers_person_assets => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_householdMembers_person_assets = (
  deserialized: ApplicationForView_application_CharityCareApplication_householdMembers_person_assets
): ApplicationForView_application_CharityCareApplication_householdMembers_person_assetsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_householdMembers_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: ApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: ApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes[]
  incomeSources: ApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources[]
  deductions: ApplicationForView_application_CharityCareApplication_householdMembers_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  documentFiles: ApplicationForView_application_CharityCareApplication_householdMembers_person_documentFiles[]
  updatedAt: Date
  gender: string | null
  emailComment: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  assets: ApplicationForView_application_CharityCareApplication_householdMembers_person_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
}
export const deserializeApplicationForView_application_CharityCareApplication_householdMembers_person = (
  serialized: ApplicationForView_application_CharityCareApplication_householdMembers_personOrig
): ApplicationForView_application_CharityCareApplication_householdMembers_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_deductions
  ),
  documentFiles: serialized.documentFiles.map(
    deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_documentFiles
  ),
  updatedAt: parseDate(serialized.updatedAt),
  assets: serialized.assets.map(
    deserializeApplicationForView_application_CharityCareApplication_householdMembers_person_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
})
export const serializeApplicationForView_application_CharityCareApplication_householdMembers_person = (
  deserialized: ApplicationForView_application_CharityCareApplication_householdMembers_person
): ApplicationForView_application_CharityCareApplication_householdMembers_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplicationForView_application_CharityCareApplication_householdMembers_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_CharityCareApplication_householdMembers_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplicationForView_application_CharityCareApplication_householdMembers_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplicationForView_application_CharityCareApplication_householdMembers_person_deductions
  ),
  documentFiles: deserialized.documentFiles.map(
    serializeApplicationForView_application_CharityCareApplication_householdMembers_person_documentFiles
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  assets: deserialized.assets.map(
    serializeApplicationForView_application_CharityCareApplication_householdMembers_person_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
})
export interface ApplicationForView_application_CharityCareApplication_householdMembers {
  __typename: 'ApplicationHouseholdMember'
  id: string
  wantsCoverage: boolean | null
  person: ApplicationForView_application_CharityCareApplication_householdMembers_person
  createdAt: Date
}
export const deserializeApplicationForView_application_CharityCareApplication_householdMembers = (
  serialized: ApplicationForView_application_CharityCareApplication_householdMembersOrig
): ApplicationForView_application_CharityCareApplication_householdMembers => ({
  ...serialized,
  person: deserializeApplicationForView_application_CharityCareApplication_householdMembers_person(
    serialized.person
  ),
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_CharityCareApplication_householdMembers = (
  deserialized: ApplicationForView_application_CharityCareApplication_householdMembers
): ApplicationForView_application_CharityCareApplication_householdMembersOrig => ({
  ...deserialized,
  person: serializeApplicationForView_application_CharityCareApplication_householdMembers_person(
    deserialized.person
  ),
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_CharityCareApplication_documents_files {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeApplicationForView_application_CharityCareApplication_documents_files = (
  serialized: ApplicationForView_application_CharityCareApplication_documents_filesOrig
): ApplicationForView_application_CharityCareApplication_documents_files => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_CharityCareApplication_documents_files = (
  deserialized: ApplicationForView_application_CharityCareApplication_documents_files
): ApplicationForView_application_CharityCareApplication_documents_filesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_CharityCareApplication_documents_person {
  __typename: 'Person'
  id: string
}
export const deserializeApplicationForView_application_CharityCareApplication_documents_person = (
  serialized: ApplicationForView_application_CharityCareApplication_documents_personOrig
): ApplicationForView_application_CharityCareApplication_documents_person => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_documents_person = (
  deserialized: ApplicationForView_application_CharityCareApplication_documents_person
): ApplicationForView_application_CharityCareApplication_documents_personOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_documents_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeApplicationForView_application_CharityCareApplication_documents_application = (
  serialized: ApplicationForView_application_CharityCareApplication_documents_applicationOrig
): ApplicationForView_application_CharityCareApplication_documents_application => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_documents_application = (
  deserialized: ApplicationForView_application_CharityCareApplication_documents_application
): ApplicationForView_application_CharityCareApplication_documents_applicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_documents {
  __typename: 'Document'
  id: string
  documentType: string
  complete: boolean | null
  files: ApplicationForView_application_CharityCareApplication_documents_files[]
  updatedAt: Date
  notes: string | null
  person: ApplicationForView_application_CharityCareApplication_documents_person | null
  application: ApplicationForView_application_CharityCareApplication_documents_application
}
export const deserializeApplicationForView_application_CharityCareApplication_documents = (
  serialized: ApplicationForView_application_CharityCareApplication_documentsOrig
): ApplicationForView_application_CharityCareApplication_documents => ({
  ...serialized,
  files: serialized.files.map(
    deserializeApplicationForView_application_CharityCareApplication_documents_files
  ),
  updatedAt: parseDate(serialized.updatedAt),
  person:
    serialized.person != null
      ? deserializeApplicationForView_application_CharityCareApplication_documents_person(
          serialized.person
        )
      : serialized.person,
  application: deserializeApplicationForView_application_CharityCareApplication_documents_application(
    serialized.application
  ),
})
export const serializeApplicationForView_application_CharityCareApplication_documents = (
  deserialized: ApplicationForView_application_CharityCareApplication_documents
): ApplicationForView_application_CharityCareApplication_documentsOrig => ({
  ...deserialized,
  files: deserialized.files.map(
    serializeApplicationForView_application_CharityCareApplication_documents_files
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  person:
    deserialized.person != null
      ? serializeApplicationForView_application_CharityCareApplication_documents_person(
          deserialized.person
        )
      : deserialized.person,
  application: serializeApplicationForView_application_CharityCareApplication_documents_application(
    deserialized.application
  ),
})
export interface ApplicationForView_application_CharityCareApplication_documentFiles_documents {
  __typename: 'Document'
  id: string
}
export const deserializeApplicationForView_application_CharityCareApplication_documentFiles_documents = (
  serialized: ApplicationForView_application_CharityCareApplication_documentFiles_documentsOrig
): ApplicationForView_application_CharityCareApplication_documentFiles_documents => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_documentFiles_documents = (
  deserialized: ApplicationForView_application_CharityCareApplication_documentFiles_documents
): ApplicationForView_application_CharityCareApplication_documentFiles_documentsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
  documents: ApplicationForView_application_CharityCareApplication_documentFiles_documents[]
}
export const deserializeApplicationForView_application_CharityCareApplication_documentFiles = (
  serialized: ApplicationForView_application_CharityCareApplication_documentFilesOrig
): ApplicationForView_application_CharityCareApplication_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
  documents: serialized.documents.map(
    deserializeApplicationForView_application_CharityCareApplication_documentFiles_documents
  ),
})
export const serializeApplicationForView_application_CharityCareApplication_documentFiles = (
  deserialized: ApplicationForView_application_CharityCareApplication_documentFiles
): ApplicationForView_application_CharityCareApplication_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
  documents: deserialized.documents.map(
    serializeApplicationForView_application_CharityCareApplication_documentFiles_documents
  ),
})
export interface ApplicationForView_application_CharityCareApplication_benefitOutcomes_person {
  __typename: 'Person'
  id: string
}
export const deserializeApplicationForView_application_CharityCareApplication_benefitOutcomes_person = (
  serialized: ApplicationForView_application_CharityCareApplication_benefitOutcomes_personOrig
): ApplicationForView_application_CharityCareApplication_benefitOutcomes_person => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_benefitOutcomes_person = (
  deserialized: ApplicationForView_application_CharityCareApplication_benefitOutcomes_person
): ApplicationForView_application_CharityCareApplication_benefitOutcomes_personOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_benefitOutcomes_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeApplicationForView_application_CharityCareApplication_benefitOutcomes_application = (
  serialized: ApplicationForView_application_CharityCareApplication_benefitOutcomes_applicationOrig
): ApplicationForView_application_CharityCareApplication_benefitOutcomes_application => ({
  ...serialized,
})
export const serializeApplicationForView_application_CharityCareApplication_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_CharityCareApplication_benefitOutcomes_application
): ApplicationForView_application_CharityCareApplication_benefitOutcomes_applicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_CharityCareApplication_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  person: ApplicationForView_application_CharityCareApplication_benefitOutcomes_person
  application: ApplicationForView_application_CharityCareApplication_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_CharityCareApplication_benefitOutcomes = (
  serialized: ApplicationForView_application_CharityCareApplication_benefitOutcomesOrig
): ApplicationForView_application_CharityCareApplication_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  person: deserializeApplicationForView_application_CharityCareApplication_benefitOutcomes_person(
    serialized.person
  ),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_CharityCareApplication_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_CharityCareApplication_benefitOutcomes = (
  deserialized: ApplicationForView_application_CharityCareApplication_benefitOutcomes
): ApplicationForView_application_CharityCareApplication_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  person: serializeApplicationForView_application_CharityCareApplication_benefitOutcomes_person(
    deserialized.person
  ),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_CharityCareApplication_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_CharityCareApplication_accountOnDateOfService {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
  timeOfService: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location: string | null
  insuranceType: string
  status: string
  createdAt: Date
  updatedAt: Date
  notes: string | null
  accountAmount: number | null
  njmmisCheck: string | null
  previousInsurance: string | null
  invoiceMonth: string | null
  invoiceYear: string | null
  dischargeDate: Date | null
  isScheduled: boolean
}
export const deserializeApplicationForView_application_CharityCareApplication_accountOnDateOfService = (
  serialized: ApplicationForView_application_CharityCareApplication_accountOnDateOfServiceOrig
): ApplicationForView_application_CharityCareApplication_accountOnDateOfService => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
})
export const serializeApplicationForView_application_CharityCareApplication_accountOnDateOfService = (
  deserialized: ApplicationForView_application_CharityCareApplication_accountOnDateOfService
): ApplicationForView_application_CharityCareApplication_accountOnDateOfServiceOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
})
export interface ApplicationForView_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  readyForAudit: boolean
  person: ApplicationForView_application_CharityCareApplication_person
  assignedTo: ApplicationForView_application_CharityCareApplication_assignedTo | null
  householdMembers: ApplicationForView_application_CharityCareApplication_householdMembers[]
  documents: ApplicationForView_application_CharityCareApplication_documents[]
  documentFiles: ApplicationForView_application_CharityCareApplication_documentFiles[]
  benefitOutcomes: ApplicationForView_application_CharityCareApplication_benefitOutcomes[]
  accountOnDateOfService: ApplicationForView_application_CharityCareApplication_accountOnDateOfService | null
  initialDateOfService: Date | null
  dateOfApplication: Date | null
  requestedDateOfApplication: Date | null
  notes: string | null
  facility: string
  coveragePeriod: number | null
  charityCareType: string
}
export const deserializeApplicationForView_application_CharityCareApplication = (
  serialized: ApplicationForView_application_CharityCareApplicationOrig
): ApplicationForView_application_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  person: deserializeApplicationForView_application_CharityCareApplication_person(
    serialized.person
  ),
  assignedTo:
    serialized.assignedTo != null
      ? deserializeApplicationForView_application_CharityCareApplication_assignedTo(
          serialized.assignedTo
        )
      : serialized.assignedTo,
  householdMembers: serialized.householdMembers.map(
    deserializeApplicationForView_application_CharityCareApplication_householdMembers
  ),
  documents: serialized.documents.map(
    deserializeApplicationForView_application_CharityCareApplication_documents
  ),
  documentFiles: serialized.documentFiles.map(
    deserializeApplicationForView_application_CharityCareApplication_documentFiles
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_CharityCareApplication_benefitOutcomes
  ),
  accountOnDateOfService:
    serialized.accountOnDateOfService != null
      ? deserializeApplicationForView_application_CharityCareApplication_accountOnDateOfService(
          serialized.accountOnDateOfService
        )
      : serialized.accountOnDateOfService,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
  requestedDateOfApplication:
    serialized.requestedDateOfApplication != null
      ? parseDate(serialized.requestedDateOfApplication)
      : serialized.requestedDateOfApplication,
})
export const serializeApplicationForView_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_CharityCareApplication
): ApplicationForView_application_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  person: serializeApplicationForView_application_CharityCareApplication_person(
    deserialized.person
  ),
  assignedTo:
    deserialized.assignedTo != null
      ? serializeApplicationForView_application_CharityCareApplication_assignedTo(
          deserialized.assignedTo
        )
      : deserialized.assignedTo,
  householdMembers: deserialized.householdMembers.map(
    serializeApplicationForView_application_CharityCareApplication_householdMembers
  ),
  documents: deserialized.documents.map(
    serializeApplicationForView_application_CharityCareApplication_documents
  ),
  documentFiles: deserialized.documentFiles.map(
    serializeApplicationForView_application_CharityCareApplication_documentFiles
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_CharityCareApplication_benefitOutcomes
  ),
  accountOnDateOfService:
    deserialized.accountOnDateOfService != null
      ? serializeApplicationForView_application_CharityCareApplication_accountOnDateOfService(
          deserialized.accountOnDateOfService
        )
      : deserialized.accountOnDateOfService,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
  requestedDateOfApplication:
    deserialized.requestedDateOfApplication != null
      ? formatISODate(deserialized.requestedDateOfApplication)
      : deserialized.requestedDateOfApplication,
})
export interface ApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: ApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): ApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards
): ApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession = (
  serialized: ApplicationForView_application_SlideApplication_person_phoneNumbers_esignSessionOrig
): ApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession = (
  deserialized: ApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession
): ApplicationForView_application_SlideApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_SlideApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: ApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession | null
}
export const deserializeApplicationForView_application_SlideApplication_person_phoneNumbers = (
  serialized: ApplicationForView_application_SlideApplication_person_phoneNumbersOrig
): ApplicationForView_application_SlideApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_SlideApplication_person_phoneNumbers = (
  deserialized: ApplicationForView_application_SlideApplication_person_phoneNumbers
): ApplicationForView_application_SlideApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_SlideApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplicationForView_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplicationOrig
): ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication
): ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplicationForView_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplicationOrig
): ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication
): ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplicationForView_application_SlideApplication_person_benefitOutcomes_application_SlideApplication = (
  serialized: ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_SlideApplicationOrig
): ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplicationForView_application_SlideApplication_person_benefitOutcomes_application_SlideApplication = (
  deserialized: ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_SlideApplication
): ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type ApplicationForView_application_SlideApplication_person_benefitOutcomes_application =
  | ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication
  | ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication
  | ApplicationForView_application_SlideApplication_person_benefitOutcomes_application_SlideApplication
export const deserializeApplicationForView_application_SlideApplication_person_benefitOutcomes_application = (
  serialized: ApplicationForView_application_SlideApplication_person_benefitOutcomes_applicationOrig
): ApplicationForView_application_SlideApplication_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplicationForView_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_SlideApplication_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_SlideApplication_person_benefitOutcomes_application'
  )
}
export const serializeApplicationForView_application_SlideApplication_person_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_SlideApplication_person_benefitOutcomes_application
): ApplicationForView_application_SlideApplication_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplicationForView_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_SlideApplication_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_SlideApplication_person_benefitOutcomes_application'
  )
}
export interface ApplicationForView_application_SlideApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: ApplicationForView_application_SlideApplication_person_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_SlideApplication_person_benefitOutcomes = (
  serialized: ApplicationForView_application_SlideApplication_person_benefitOutcomesOrig
): ApplicationForView_application_SlideApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_SlideApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_SlideApplication_person_benefitOutcomes = (
  deserialized: ApplicationForView_application_SlideApplication_person_benefitOutcomes
): ApplicationForView_application_SlideApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_SlideApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_SlideApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_SlideApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: ApplicationForView_application_SlideApplication_person_incomeSources_esignSession_esignWizardsOrig
): ApplicationForView_application_SlideApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_SlideApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_SlideApplication_person_incomeSources_esignSession_esignWizards
): ApplicationForView_application_SlideApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_SlideApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_SlideApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_SlideApplication_person_incomeSources_esignSession = (
  serialized: ApplicationForView_application_SlideApplication_person_incomeSources_esignSessionOrig
): ApplicationForView_application_SlideApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_SlideApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_SlideApplication_person_incomeSources_esignSession = (
  deserialized: ApplicationForView_application_SlideApplication_person_incomeSources_esignSession
): ApplicationForView_application_SlideApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_SlideApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_SlideApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: ApplicationForView_application_SlideApplication_person_incomeSources_esignSession | null
}
export const deserializeApplicationForView_application_SlideApplication_person_incomeSources = (
  serialized: ApplicationForView_application_SlideApplication_person_incomeSourcesOrig
): ApplicationForView_application_SlideApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_SlideApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_SlideApplication_person_incomeSources = (
  deserialized: ApplicationForView_application_SlideApplication_person_incomeSources
): ApplicationForView_application_SlideApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_SlideApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_SlideApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplicationForView_application_SlideApplication_person_deductions = (
  serialized: ApplicationForView_application_SlideApplication_person_deductionsOrig
): ApplicationForView_application_SlideApplication_person_deductions => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_person_deductions = (
  deserialized: ApplicationForView_application_SlideApplication_person_deductions
): ApplicationForView_application_SlideApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards = (
  serialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizardsOrig
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession = (
  serialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSessionOrig
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession = (
  deserialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession | null
}
export const deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers = (
  serialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbersOrig
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers = (
  deserialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication = (
  serialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplicationOrig
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication = (
  serialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplicationOrig
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication = (
  serialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplicationOrig
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication = (
  deserialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application =
  | ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication
  | ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication
  | ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication
export const deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application = (
  serialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_applicationOrig
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application'
  )
}
export const serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application'
  )
}
export interface ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes = (
  serialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomesOrig
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes = (
  deserialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards = (
  serialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizardsOrig
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession = (
  serialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSessionOrig
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession = (
  deserialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession | null
}
export const deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources = (
  serialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSourcesOrig
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources = (
  deserialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_SlideApplication_person_relationships_otherPerson_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_deductions = (
  serialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_deductionsOrig
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_deductions => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_deductions = (
  deserialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_deductions
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_deductionsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_person_relationships_otherPerson_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_assets = (
  serialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_assetsOrig
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_assets => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_assets = (
  deserialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_assets
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson_assetsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_person_relationships_otherPerson {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes[]
  incomeSources: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources[]
  deductions: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  updatedAt: Date
  gender: string | null
  emailComment: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  assets: ApplicationForView_application_SlideApplication_person_relationships_otherPerson_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson = (
  serialized: ApplicationForView_application_SlideApplication_person_relationships_otherPersonOrig
): ApplicationForView_application_SlideApplication_person_relationships_otherPerson => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_deductions
  ),
  updatedAt: parseDate(serialized.updatedAt),
  assets: serialized.assets.map(
    deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson = (
  deserialized: ApplicationForView_application_SlideApplication_person_relationships_otherPerson
): ApplicationForView_application_SlideApplication_person_relationships_otherPersonOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_deductions
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  assets: deserialized.assets.map(
    serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface ApplicationForView_application_SlideApplication_person_relationships {
  __typename: 'Relationship'
  id: string
  relationshipType: string | null
  reverseRelationshipType: string | null
  liveTogether: boolean | null
  otherPerson: ApplicationForView_application_SlideApplication_person_relationships_otherPerson
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeApplicationForView_application_SlideApplication_person_relationships = (
  serialized: ApplicationForView_application_SlideApplication_person_relationshipsOrig
): ApplicationForView_application_SlideApplication_person_relationships => ({
  ...serialized,
  otherPerson: deserializeApplicationForView_application_SlideApplication_person_relationships_otherPerson(
    serialized.otherPerson
  ),
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeApplicationForView_application_SlideApplication_person_relationships = (
  deserialized: ApplicationForView_application_SlideApplication_person_relationships
): ApplicationForView_application_SlideApplication_person_relationshipsOrig => ({
  ...deserialized,
  otherPerson: serializeApplicationForView_application_SlideApplication_person_relationships_otherPerson(
    deserialized.otherPerson
  ),
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface ApplicationForView_application_SlideApplication_person_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeApplicationForView_application_SlideApplication_person_documentFiles = (
  serialized: ApplicationForView_application_SlideApplication_person_documentFilesOrig
): ApplicationForView_application_SlideApplication_person_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_SlideApplication_person_documentFiles = (
  deserialized: ApplicationForView_application_SlideApplication_person_documentFiles
): ApplicationForView_application_SlideApplication_person_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_SlideApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplicationForView_application_SlideApplication_person_openApplications_MedicaidApplication = (
  serialized: ApplicationForView_application_SlideApplication_person_openApplications_MedicaidApplicationOrig
): ApplicationForView_application_SlideApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_SlideApplication_person_openApplications_MedicaidApplication = (
  deserialized: ApplicationForView_application_SlideApplication_person_openApplications_MedicaidApplication
): ApplicationForView_application_SlideApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_SlideApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplicationForView_application_SlideApplication_person_openApplications_CharityCareApplication = (
  serialized: ApplicationForView_application_SlideApplication_person_openApplications_CharityCareApplicationOrig
): ApplicationForView_application_SlideApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_SlideApplication_person_openApplications_CharityCareApplication = (
  deserialized: ApplicationForView_application_SlideApplication_person_openApplications_CharityCareApplication
): ApplicationForView_application_SlideApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_SlideApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplicationForView_application_SlideApplication_person_openApplications_SlideApplication = (
  serialized: ApplicationForView_application_SlideApplication_person_openApplications_SlideApplicationOrig
): ApplicationForView_application_SlideApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_SlideApplication_person_openApplications_SlideApplication = (
  deserialized: ApplicationForView_application_SlideApplication_person_openApplications_SlideApplication
): ApplicationForView_application_SlideApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_SlideApplication_person_openApplications_RyanWhiteApplication {
  __typename: 'RyanWhiteApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplicationForView_application_SlideApplication_person_openApplications_RyanWhiteApplication = (
  serialized: ApplicationForView_application_SlideApplication_person_openApplications_RyanWhiteApplicationOrig
): ApplicationForView_application_SlideApplication_person_openApplications_RyanWhiteApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_SlideApplication_person_openApplications_RyanWhiteApplication = (
  deserialized: ApplicationForView_application_SlideApplication_person_openApplications_RyanWhiteApplication
): ApplicationForView_application_SlideApplication_person_openApplications_RyanWhiteApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type ApplicationForView_application_SlideApplication_person_openApplications =
  | ApplicationForView_application_SlideApplication_person_openApplications_MedicaidApplication
  | ApplicationForView_application_SlideApplication_person_openApplications_CharityCareApplication
  | ApplicationForView_application_SlideApplication_person_openApplications_SlideApplication
  | ApplicationForView_application_SlideApplication_person_openApplications_RyanWhiteApplication
export const deserializeApplicationForView_application_SlideApplication_person_openApplications = (
  serialized: ApplicationForView_application_SlideApplication_person_openApplicationsOrig
): ApplicationForView_application_SlideApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeApplicationForView_application_SlideApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_SlideApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_SlideApplication_person_openApplications_SlideApplication(
      serialized
    )
  if (false || serialized.__typename === 'RyanWhiteApplication')
    return deserializeApplicationForView_application_SlideApplication_person_openApplications_RyanWhiteApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_SlideApplication_person_openApplications'
  )
}
export const serializeApplicationForView_application_SlideApplication_person_openApplications = (
  deserialized: ApplicationForView_application_SlideApplication_person_openApplications
): ApplicationForView_application_SlideApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeApplicationForView_application_SlideApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_SlideApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_SlideApplication_person_openApplications_SlideApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'RyanWhiteApplication')
    return serializeApplicationForView_application_SlideApplication_person_openApplications_RyanWhiteApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_SlideApplication_person_openApplications'
  )
}
export interface ApplicationForView_application_SlideApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplicationForView_application_SlideApplication_person_openHouseholdMemberApplications = (
  serialized: ApplicationForView_application_SlideApplication_person_openHouseholdMemberApplicationsOrig
): ApplicationForView_application_SlideApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_SlideApplication_person_openHouseholdMemberApplications = (
  deserialized: ApplicationForView_application_SlideApplication_person_openHouseholdMemberApplications
): ApplicationForView_application_SlideApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_SlideApplication_person_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeApplicationForView_application_SlideApplication_person_assets = (
  serialized: ApplicationForView_application_SlideApplication_person_assetsOrig
): ApplicationForView_application_SlideApplication_person_assets => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_person_assets = (
  deserialized: ApplicationForView_application_SlideApplication_person_assets
): ApplicationForView_application_SlideApplication_person_assetsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_person_accounts {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
}
export const deserializeApplicationForView_application_SlideApplication_person_accounts = (
  serialized: ApplicationForView_application_SlideApplication_person_accountsOrig
): ApplicationForView_application_SlideApplication_person_accounts => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
})
export const serializeApplicationForView_application_SlideApplication_person_accounts = (
  deserialized: ApplicationForView_application_SlideApplication_person_accounts
): ApplicationForView_application_SlideApplication_person_accountsOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
})
export interface ApplicationForView_application_SlideApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: ApplicationForView_application_SlideApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: ApplicationForView_application_SlideApplication_person_benefitOutcomes[]
  incomeSources: ApplicationForView_application_SlideApplication_person_incomeSources[]
  deductions: ApplicationForView_application_SlideApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  relationships: ApplicationForView_application_SlideApplication_person_relationships[]
  documentFiles: ApplicationForView_application_SlideApplication_person_documentFiles[]
  openApplications: ApplicationForView_application_SlideApplication_person_openApplications[]
  openHouseholdMemberApplications: ApplicationForView_application_SlideApplication_person_openHouseholdMemberApplications[]
  updatedAt: Date
  gender: string | null
  emailComment: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  assets: ApplicationForView_application_SlideApplication_person_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  accounts: ApplicationForView_application_SlideApplication_person_accounts[]
}
export const deserializeApplicationForView_application_SlideApplication_person = (
  serialized: ApplicationForView_application_SlideApplication_personOrig
): ApplicationForView_application_SlideApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplicationForView_application_SlideApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_SlideApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplicationForView_application_SlideApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplicationForView_application_SlideApplication_person_deductions
  ),
  relationships: serialized.relationships.map(
    deserializeApplicationForView_application_SlideApplication_person_relationships
  ),
  documentFiles: serialized.documentFiles.map(
    deserializeApplicationForView_application_SlideApplication_person_documentFiles
  ),
  openApplications: serialized.openApplications.map(
    deserializeApplicationForView_application_SlideApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeApplicationForView_application_SlideApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: parseDate(serialized.updatedAt),
  assets: serialized.assets.map(
    deserializeApplicationForView_application_SlideApplication_person_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  accounts: serialized.accounts.map(
    deserializeApplicationForView_application_SlideApplication_person_accounts
  ),
})
export const serializeApplicationForView_application_SlideApplication_person = (
  deserialized: ApplicationForView_application_SlideApplication_person
): ApplicationForView_application_SlideApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplicationForView_application_SlideApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_SlideApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplicationForView_application_SlideApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplicationForView_application_SlideApplication_person_deductions
  ),
  relationships: deserialized.relationships.map(
    serializeApplicationForView_application_SlideApplication_person_relationships
  ),
  documentFiles: deserialized.documentFiles.map(
    serializeApplicationForView_application_SlideApplication_person_documentFiles
  ),
  openApplications: deserialized.openApplications.map(
    serializeApplicationForView_application_SlideApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeApplicationForView_application_SlideApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  assets: deserialized.assets.map(
    serializeApplicationForView_application_SlideApplication_person_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  accounts: deserialized.accounts.map(
    serializeApplicationForView_application_SlideApplication_person_accounts
  ),
})
export interface ApplicationForView_application_SlideApplication_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeApplicationForView_application_SlideApplication_assignedTo = (
  serialized: ApplicationForView_application_SlideApplication_assignedToOrig
): ApplicationForView_application_SlideApplication_assignedTo => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_assignedTo = (
  deserialized: ApplicationForView_application_SlideApplication_assignedTo
): ApplicationForView_application_SlideApplication_assignedToOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards = (
  serialized: ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession_esignWizardsOrig
): ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards
): ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession = (
  serialized: ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSessionOrig
): ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession = (
  deserialized: ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession
): ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession | null
}
export const deserializeApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers = (
  serialized: ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbersOrig
): ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers = (
  deserialized: ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers
): ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplicationOrig
): ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication
): ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplicationOrig
): ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication
): ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_SlideApplication = (
  serialized: ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_SlideApplicationOrig
): ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_SlideApplication = (
  deserialized: ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_SlideApplication
): ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application =
  | ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication
  | ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication
  | ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_SlideApplication
export const deserializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application = (
  serialized: ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_applicationOrig
): ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application'
  )
}
export const serializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application
): ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application'
  )
}
export interface ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes = (
  serialized: ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomesOrig
): ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes = (
  deserialized: ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes
): ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession_esignWizards = (
  serialized: ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession_esignWizardsOrig
): ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession_esignWizards
): ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession = (
  serialized: ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSessionOrig
): ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession = (
  deserialized: ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession
): ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession | null
}
export const deserializeApplicationForView_application_SlideApplication_householdMembers_person_incomeSources = (
  serialized: ApplicationForView_application_SlideApplication_householdMembers_person_incomeSourcesOrig
): ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_SlideApplication_householdMembers_person_incomeSources = (
  deserialized: ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources
): ApplicationForView_application_SlideApplication_householdMembers_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_SlideApplication_householdMembers_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_SlideApplication_householdMembers_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplicationForView_application_SlideApplication_householdMembers_person_deductions = (
  serialized: ApplicationForView_application_SlideApplication_householdMembers_person_deductionsOrig
): ApplicationForView_application_SlideApplication_householdMembers_person_deductions => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_householdMembers_person_deductions = (
  deserialized: ApplicationForView_application_SlideApplication_householdMembers_person_deductions
): ApplicationForView_application_SlideApplication_householdMembers_person_deductionsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_householdMembers_person_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeApplicationForView_application_SlideApplication_householdMembers_person_documentFiles = (
  serialized: ApplicationForView_application_SlideApplication_householdMembers_person_documentFilesOrig
): ApplicationForView_application_SlideApplication_householdMembers_person_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_SlideApplication_householdMembers_person_documentFiles = (
  deserialized: ApplicationForView_application_SlideApplication_householdMembers_person_documentFiles
): ApplicationForView_application_SlideApplication_householdMembers_person_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_SlideApplication_householdMembers_person_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeApplicationForView_application_SlideApplication_householdMembers_person_assets = (
  serialized: ApplicationForView_application_SlideApplication_householdMembers_person_assetsOrig
): ApplicationForView_application_SlideApplication_householdMembers_person_assets => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_householdMembers_person_assets = (
  deserialized: ApplicationForView_application_SlideApplication_householdMembers_person_assets
): ApplicationForView_application_SlideApplication_householdMembers_person_assetsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_householdMembers_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: ApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: ApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes[]
  incomeSources: ApplicationForView_application_SlideApplication_householdMembers_person_incomeSources[]
  deductions: ApplicationForView_application_SlideApplication_householdMembers_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  documentFiles: ApplicationForView_application_SlideApplication_householdMembers_person_documentFiles[]
  updatedAt: Date
  gender: string | null
  emailComment: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  assets: ApplicationForView_application_SlideApplication_householdMembers_person_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
}
export const deserializeApplicationForView_application_SlideApplication_householdMembers_person = (
  serialized: ApplicationForView_application_SlideApplication_householdMembers_personOrig
): ApplicationForView_application_SlideApplication_householdMembers_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplicationForView_application_SlideApplication_householdMembers_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplicationForView_application_SlideApplication_householdMembers_person_deductions
  ),
  documentFiles: serialized.documentFiles.map(
    deserializeApplicationForView_application_SlideApplication_householdMembers_person_documentFiles
  ),
  updatedAt: parseDate(serialized.updatedAt),
  assets: serialized.assets.map(
    deserializeApplicationForView_application_SlideApplication_householdMembers_person_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
})
export const serializeApplicationForView_application_SlideApplication_householdMembers_person = (
  deserialized: ApplicationForView_application_SlideApplication_householdMembers_person
): ApplicationForView_application_SlideApplication_householdMembers_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplicationForView_application_SlideApplication_householdMembers_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_SlideApplication_householdMembers_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplicationForView_application_SlideApplication_householdMembers_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplicationForView_application_SlideApplication_householdMembers_person_deductions
  ),
  documentFiles: deserialized.documentFiles.map(
    serializeApplicationForView_application_SlideApplication_householdMembers_person_documentFiles
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  assets: deserialized.assets.map(
    serializeApplicationForView_application_SlideApplication_householdMembers_person_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
})
export interface ApplicationForView_application_SlideApplication_householdMembers {
  __typename: 'ApplicationHouseholdMember'
  id: string
  wantsCoverage: boolean | null
  person: ApplicationForView_application_SlideApplication_householdMembers_person
  createdAt: Date
}
export const deserializeApplicationForView_application_SlideApplication_householdMembers = (
  serialized: ApplicationForView_application_SlideApplication_householdMembersOrig
): ApplicationForView_application_SlideApplication_householdMembers => ({
  ...serialized,
  person: deserializeApplicationForView_application_SlideApplication_householdMembers_person(
    serialized.person
  ),
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_SlideApplication_householdMembers = (
  deserialized: ApplicationForView_application_SlideApplication_householdMembers
): ApplicationForView_application_SlideApplication_householdMembersOrig => ({
  ...deserialized,
  person: serializeApplicationForView_application_SlideApplication_householdMembers_person(
    deserialized.person
  ),
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_SlideApplication_documents_files {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeApplicationForView_application_SlideApplication_documents_files = (
  serialized: ApplicationForView_application_SlideApplication_documents_filesOrig
): ApplicationForView_application_SlideApplication_documents_files => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_SlideApplication_documents_files = (
  deserialized: ApplicationForView_application_SlideApplication_documents_files
): ApplicationForView_application_SlideApplication_documents_filesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_SlideApplication_documents_person {
  __typename: 'Person'
  id: string
}
export const deserializeApplicationForView_application_SlideApplication_documents_person = (
  serialized: ApplicationForView_application_SlideApplication_documents_personOrig
): ApplicationForView_application_SlideApplication_documents_person => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_documents_person = (
  deserialized: ApplicationForView_application_SlideApplication_documents_person
): ApplicationForView_application_SlideApplication_documents_personOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_documents_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeApplicationForView_application_SlideApplication_documents_application = (
  serialized: ApplicationForView_application_SlideApplication_documents_applicationOrig
): ApplicationForView_application_SlideApplication_documents_application => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_documents_application = (
  deserialized: ApplicationForView_application_SlideApplication_documents_application
): ApplicationForView_application_SlideApplication_documents_applicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_documents {
  __typename: 'Document'
  id: string
  documentType: string
  complete: boolean | null
  files: ApplicationForView_application_SlideApplication_documents_files[]
  updatedAt: Date
  notes: string | null
  person: ApplicationForView_application_SlideApplication_documents_person | null
  application: ApplicationForView_application_SlideApplication_documents_application
}
export const deserializeApplicationForView_application_SlideApplication_documents = (
  serialized: ApplicationForView_application_SlideApplication_documentsOrig
): ApplicationForView_application_SlideApplication_documents => ({
  ...serialized,
  files: serialized.files.map(
    deserializeApplicationForView_application_SlideApplication_documents_files
  ),
  updatedAt: parseDate(serialized.updatedAt),
  person:
    serialized.person != null
      ? deserializeApplicationForView_application_SlideApplication_documents_person(
          serialized.person
        )
      : serialized.person,
  application: deserializeApplicationForView_application_SlideApplication_documents_application(
    serialized.application
  ),
})
export const serializeApplicationForView_application_SlideApplication_documents = (
  deserialized: ApplicationForView_application_SlideApplication_documents
): ApplicationForView_application_SlideApplication_documentsOrig => ({
  ...deserialized,
  files: deserialized.files.map(
    serializeApplicationForView_application_SlideApplication_documents_files
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  person:
    deserialized.person != null
      ? serializeApplicationForView_application_SlideApplication_documents_person(
          deserialized.person
        )
      : deserialized.person,
  application: serializeApplicationForView_application_SlideApplication_documents_application(
    deserialized.application
  ),
})
export interface ApplicationForView_application_SlideApplication_documentFiles_documents {
  __typename: 'Document'
  id: string
}
export const deserializeApplicationForView_application_SlideApplication_documentFiles_documents = (
  serialized: ApplicationForView_application_SlideApplication_documentFiles_documentsOrig
): ApplicationForView_application_SlideApplication_documentFiles_documents => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_documentFiles_documents = (
  deserialized: ApplicationForView_application_SlideApplication_documentFiles_documents
): ApplicationForView_application_SlideApplication_documentFiles_documentsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
  documents: ApplicationForView_application_SlideApplication_documentFiles_documents[]
}
export const deserializeApplicationForView_application_SlideApplication_documentFiles = (
  serialized: ApplicationForView_application_SlideApplication_documentFilesOrig
): ApplicationForView_application_SlideApplication_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
  documents: serialized.documents.map(
    deserializeApplicationForView_application_SlideApplication_documentFiles_documents
  ),
})
export const serializeApplicationForView_application_SlideApplication_documentFiles = (
  deserialized: ApplicationForView_application_SlideApplication_documentFiles
): ApplicationForView_application_SlideApplication_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
  documents: deserialized.documents.map(
    serializeApplicationForView_application_SlideApplication_documentFiles_documents
  ),
})
export interface ApplicationForView_application_SlideApplication_benefitOutcomes_person {
  __typename: 'Person'
  id: string
}
export const deserializeApplicationForView_application_SlideApplication_benefitOutcomes_person = (
  serialized: ApplicationForView_application_SlideApplication_benefitOutcomes_personOrig
): ApplicationForView_application_SlideApplication_benefitOutcomes_person => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_benefitOutcomes_person = (
  deserialized: ApplicationForView_application_SlideApplication_benefitOutcomes_person
): ApplicationForView_application_SlideApplication_benefitOutcomes_personOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_benefitOutcomes_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeApplicationForView_application_SlideApplication_benefitOutcomes_application = (
  serialized: ApplicationForView_application_SlideApplication_benefitOutcomes_applicationOrig
): ApplicationForView_application_SlideApplication_benefitOutcomes_application => ({
  ...serialized,
})
export const serializeApplicationForView_application_SlideApplication_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_SlideApplication_benefitOutcomes_application
): ApplicationForView_application_SlideApplication_benefitOutcomes_applicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_SlideApplication_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  person: ApplicationForView_application_SlideApplication_benefitOutcomes_person
  application: ApplicationForView_application_SlideApplication_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_SlideApplication_benefitOutcomes = (
  serialized: ApplicationForView_application_SlideApplication_benefitOutcomesOrig
): ApplicationForView_application_SlideApplication_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  person: deserializeApplicationForView_application_SlideApplication_benefitOutcomes_person(
    serialized.person
  ),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_SlideApplication_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_SlideApplication_benefitOutcomes = (
  deserialized: ApplicationForView_application_SlideApplication_benefitOutcomes
): ApplicationForView_application_SlideApplication_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  person: serializeApplicationForView_application_SlideApplication_benefitOutcomes_person(
    deserialized.person
  ),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_SlideApplication_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_SlideApplication_accountOnDateOfService {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
  timeOfService: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location: string | null
  insuranceType: string
  status: string
  createdAt: Date
  updatedAt: Date
  notes: string | null
  accountAmount: number | null
  njmmisCheck: string | null
  previousInsurance: string | null
  invoiceMonth: string | null
  invoiceYear: string | null
  dischargeDate: Date | null
  isScheduled: boolean
}
export const deserializeApplicationForView_application_SlideApplication_accountOnDateOfService = (
  serialized: ApplicationForView_application_SlideApplication_accountOnDateOfServiceOrig
): ApplicationForView_application_SlideApplication_accountOnDateOfService => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
})
export const serializeApplicationForView_application_SlideApplication_accountOnDateOfService = (
  deserialized: ApplicationForView_application_SlideApplication_accountOnDateOfService
): ApplicationForView_application_SlideApplication_accountOnDateOfServiceOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
})
export interface ApplicationForView_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  readyForAudit: boolean
  person: ApplicationForView_application_SlideApplication_person
  assignedTo: ApplicationForView_application_SlideApplication_assignedTo | null
  householdMembers: ApplicationForView_application_SlideApplication_householdMembers[]
  documents: ApplicationForView_application_SlideApplication_documents[]
  documentFiles: ApplicationForView_application_SlideApplication_documentFiles[]
  benefitOutcomes: ApplicationForView_application_SlideApplication_benefitOutcomes[]
  accountOnDateOfService: ApplicationForView_application_SlideApplication_accountOnDateOfService | null
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  facility: string
  slideType: string
  dateOfApplication: Date | null
  coveragePeriod: number | null
}
export const deserializeApplicationForView_application_SlideApplication = (
  serialized: ApplicationForView_application_SlideApplicationOrig
): ApplicationForView_application_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  person: deserializeApplicationForView_application_SlideApplication_person(
    serialized.person
  ),
  assignedTo:
    serialized.assignedTo != null
      ? deserializeApplicationForView_application_SlideApplication_assignedTo(
          serialized.assignedTo
        )
      : serialized.assignedTo,
  householdMembers: serialized.householdMembers.map(
    deserializeApplicationForView_application_SlideApplication_householdMembers
  ),
  documents: serialized.documents.map(
    deserializeApplicationForView_application_SlideApplication_documents
  ),
  documentFiles: serialized.documentFiles.map(
    deserializeApplicationForView_application_SlideApplication_documentFiles
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_SlideApplication_benefitOutcomes
  ),
  accountOnDateOfService:
    serialized.accountOnDateOfService != null
      ? deserializeApplicationForView_application_SlideApplication_accountOnDateOfService(
          serialized.accountOnDateOfService
        )
      : serialized.accountOnDateOfService,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  slideType: deserializeSlideType(serialized.slideType),
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
})
export const serializeApplicationForView_application_SlideApplication = (
  deserialized: ApplicationForView_application_SlideApplication
): ApplicationForView_application_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  person: serializeApplicationForView_application_SlideApplication_person(
    deserialized.person
  ),
  assignedTo:
    deserialized.assignedTo != null
      ? serializeApplicationForView_application_SlideApplication_assignedTo(
          deserialized.assignedTo
        )
      : deserialized.assignedTo,
  householdMembers: deserialized.householdMembers.map(
    serializeApplicationForView_application_SlideApplication_householdMembers
  ),
  documents: deserialized.documents.map(
    serializeApplicationForView_application_SlideApplication_documents
  ),
  documentFiles: deserialized.documentFiles.map(
    serializeApplicationForView_application_SlideApplication_documentFiles
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_SlideApplication_benefitOutcomes
  ),
  accountOnDateOfService:
    deserialized.accountOnDateOfService != null
      ? serializeApplicationForView_application_SlideApplication_accountOnDateOfService(
          deserialized.accountOnDateOfService
        )
      : deserialized.accountOnDateOfService,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  slideType: serializeSlideType(deserialized.slideType),
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards
): ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSessionOrig
): ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession
): ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_phoneNumbers = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_phoneNumbersOrig
): ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_phoneNumbers = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers
): ApplicationForView_application_RyanWhiteApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_RyanWhiteApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplicationOrig
): ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication
): ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplicationOrig
): ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication
): ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplicationOrig
): ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication
): ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application =
  | ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication
  | ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication
  | ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication
export const deserializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_applicationOrig
): ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application'
  )
}
export const serializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application
): ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application'
  )
}
export interface ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomesOrig
): ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes
): ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizardsOrig
): ApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards
): ApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSessionOrig
): ApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession
): ApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_RyanWhiteApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: ApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_incomeSources = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_incomeSourcesOrig
): ApplicationForView_application_RyanWhiteApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_incomeSources = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_incomeSources
): ApplicationForView_application_RyanWhiteApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_RyanWhiteApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_deductions = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_deductionsOrig
): ApplicationForView_application_RyanWhiteApplication_person_deductions => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_deductions = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_deductions
): ApplicationForView_application_RyanWhiteApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizardsOrig
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSessionOrig
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbersOrig
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplicationOrig
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplicationOrig
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplicationOrig
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application =
  | ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication
  | ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication
  | ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication
export const deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_applicationOrig
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application'
  )
}
export const serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application'
  )
}
export interface ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomesOrig
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizardsOrig
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSessionOrig
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSourcesOrig
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_deductions = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_deductionsOrig
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_deductions => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_deductions = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_deductions
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_deductionsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_assets = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_assetsOrig
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_assets => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_assets = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_assets
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_assetsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes[]
  incomeSources: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources[]
  deductions: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  updatedAt: Date
  gender: string | null
  emailComment: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  assets: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPersonOrig
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_deductions
  ),
  updatedAt: parseDate(serialized.updatedAt),
  assets: serialized.assets.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson
): ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPersonOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_deductions
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  assets: deserialized.assets.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_relationships {
  __typename: 'Relationship'
  id: string
  relationshipType: string | null
  reverseRelationshipType: string | null
  liveTogether: boolean | null
  otherPerson: ApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_relationships = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_relationshipsOrig
): ApplicationForView_application_RyanWhiteApplication_person_relationships => ({
  ...serialized,
  otherPerson: deserializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson(
    serialized.otherPerson
  ),
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_relationships = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_relationships
): ApplicationForView_application_RyanWhiteApplication_person_relationshipsOrig => ({
  ...deserialized,
  otherPerson: serializeApplicationForView_application_RyanWhiteApplication_person_relationships_otherPerson(
    deserialized.otherPerson
  ),
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_documentFiles = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_documentFilesOrig
): ApplicationForView_application_RyanWhiteApplication_person_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_documentFiles = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_documentFiles
): ApplicationForView_application_RyanWhiteApplication_person_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_RyanWhiteApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_openApplications_MedicaidApplication = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_openApplications_MedicaidApplicationOrig
): ApplicationForView_application_RyanWhiteApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_openApplications_MedicaidApplication = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_openApplications_MedicaidApplication
): ApplicationForView_application_RyanWhiteApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_RyanWhiteApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_openApplications_CharityCareApplication = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_openApplications_CharityCareApplicationOrig
): ApplicationForView_application_RyanWhiteApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_openApplications_CharityCareApplication = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_openApplications_CharityCareApplication
): ApplicationForView_application_RyanWhiteApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_RyanWhiteApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_openApplications_SlideApplication = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_openApplications_SlideApplicationOrig
): ApplicationForView_application_RyanWhiteApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_openApplications_SlideApplication = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_openApplications_SlideApplication
): ApplicationForView_application_RyanWhiteApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_RyanWhiteApplication_person_openApplications_RyanWhiteApplication {
  __typename: 'RyanWhiteApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_openApplications_RyanWhiteApplication = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_openApplications_RyanWhiteApplicationOrig
): ApplicationForView_application_RyanWhiteApplication_person_openApplications_RyanWhiteApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_openApplications_RyanWhiteApplication = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_openApplications_RyanWhiteApplication
): ApplicationForView_application_RyanWhiteApplication_person_openApplications_RyanWhiteApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type ApplicationForView_application_RyanWhiteApplication_person_openApplications =
  | ApplicationForView_application_RyanWhiteApplication_person_openApplications_MedicaidApplication
  | ApplicationForView_application_RyanWhiteApplication_person_openApplications_CharityCareApplication
  | ApplicationForView_application_RyanWhiteApplication_person_openApplications_SlideApplication
  | ApplicationForView_application_RyanWhiteApplication_person_openApplications_RyanWhiteApplication
export const deserializeApplicationForView_application_RyanWhiteApplication_person_openApplications = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_openApplicationsOrig
): ApplicationForView_application_RyanWhiteApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeApplicationForView_application_RyanWhiteApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_RyanWhiteApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_RyanWhiteApplication_person_openApplications_SlideApplication(
      serialized
    )
  if (false || serialized.__typename === 'RyanWhiteApplication')
    return deserializeApplicationForView_application_RyanWhiteApplication_person_openApplications_RyanWhiteApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_RyanWhiteApplication_person_openApplications'
  )
}
export const serializeApplicationForView_application_RyanWhiteApplication_person_openApplications = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_openApplications
): ApplicationForView_application_RyanWhiteApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeApplicationForView_application_RyanWhiteApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_RyanWhiteApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_RyanWhiteApplication_person_openApplications_SlideApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'RyanWhiteApplication')
    return serializeApplicationForView_application_RyanWhiteApplication_person_openApplications_RyanWhiteApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_RyanWhiteApplication_person_openApplications'
  )
}
export interface ApplicationForView_application_RyanWhiteApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_openHouseholdMemberApplications = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_openHouseholdMemberApplicationsOrig
): ApplicationForView_application_RyanWhiteApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_openHouseholdMemberApplications = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_openHouseholdMemberApplications
): ApplicationForView_application_RyanWhiteApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface ApplicationForView_application_RyanWhiteApplication_person_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_assets = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_assetsOrig
): ApplicationForView_application_RyanWhiteApplication_person_assets => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_assets = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_assets
): ApplicationForView_application_RyanWhiteApplication_person_assetsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_person_accounts {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person_accounts = (
  serialized: ApplicationForView_application_RyanWhiteApplication_person_accountsOrig
): ApplicationForView_application_RyanWhiteApplication_person_accounts => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
})
export const serializeApplicationForView_application_RyanWhiteApplication_person_accounts = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person_accounts
): ApplicationForView_application_RyanWhiteApplication_person_accountsOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
})
export interface ApplicationForView_application_RyanWhiteApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: ApplicationForView_application_RyanWhiteApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: ApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes[]
  incomeSources: ApplicationForView_application_RyanWhiteApplication_person_incomeSources[]
  deductions: ApplicationForView_application_RyanWhiteApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  relationships: ApplicationForView_application_RyanWhiteApplication_person_relationships[]
  documentFiles: ApplicationForView_application_RyanWhiteApplication_person_documentFiles[]
  openApplications: ApplicationForView_application_RyanWhiteApplication_person_openApplications[]
  openHouseholdMemberApplications: ApplicationForView_application_RyanWhiteApplication_person_openHouseholdMemberApplications[]
  updatedAt: Date
  gender: string | null
  emailComment: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  assets: ApplicationForView_application_RyanWhiteApplication_person_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  accounts: ApplicationForView_application_RyanWhiteApplication_person_accounts[]
}
export const deserializeApplicationForView_application_RyanWhiteApplication_person = (
  serialized: ApplicationForView_application_RyanWhiteApplication_personOrig
): ApplicationForView_application_RyanWhiteApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_deductions
  ),
  relationships: serialized.relationships.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_relationships
  ),
  documentFiles: serialized.documentFiles.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_documentFiles
  ),
  openApplications: serialized.openApplications.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: parseDate(serialized.updatedAt),
  assets: serialized.assets.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  accounts: serialized.accounts.map(
    deserializeApplicationForView_application_RyanWhiteApplication_person_accounts
  ),
})
export const serializeApplicationForView_application_RyanWhiteApplication_person = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_person
): ApplicationForView_application_RyanWhiteApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_deductions
  ),
  relationships: deserialized.relationships.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_relationships
  ),
  documentFiles: deserialized.documentFiles.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_documentFiles
  ),
  openApplications: deserialized.openApplications.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  assets: deserialized.assets.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  accounts: deserialized.accounts.map(
    serializeApplicationForView_application_RyanWhiteApplication_person_accounts
  ),
})
export interface ApplicationForView_application_RyanWhiteApplication_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeApplicationForView_application_RyanWhiteApplication_assignedTo = (
  serialized: ApplicationForView_application_RyanWhiteApplication_assignedToOrig
): ApplicationForView_application_RyanWhiteApplication_assignedTo => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_assignedTo = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_assignedTo
): ApplicationForView_application_RyanWhiteApplication_assignedToOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards = (
  serialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession_esignWizardsOrig
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession = (
  serialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSessionOrig
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers = (
  serialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbersOrig
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplicationOrig
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplicationOrig
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_SlideApplication = (
  serialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_SlideApplicationOrig
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_SlideApplication = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_SlideApplication
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application =
  | ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication
  | ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication
  | ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_SlideApplication
export const deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application = (
  serialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_applicationOrig
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application'
  )
}
export const serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application'
  )
}
export interface ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes = (
  serialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomesOrig
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession_esignWizards = (
  serialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession_esignWizardsOrig
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession_esignWizards = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession_esignWizards
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession = (
  serialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSessionOrig
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession_esignWizards
  ),
})
export interface ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources = (
  serialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSourcesOrig
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface ApplicationForView_application_RyanWhiteApplication_householdMembers_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_deductions = (
  serialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_deductionsOrig
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_deductions => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_deductions = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_deductions
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_deductionsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_householdMembers_person_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_documentFiles = (
  serialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_documentFilesOrig
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_documentFiles = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_documentFiles
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_RyanWhiteApplication_householdMembers_person_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_assets = (
  serialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_assetsOrig
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_assets => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_assets = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_assets
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person_assetsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_householdMembers_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes[]
  incomeSources: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources[]
  deductions: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  documentFiles: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_documentFiles[]
  updatedAt: Date
  gender: string | null
  emailComment: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  assets: ApplicationForView_application_RyanWhiteApplication_householdMembers_person_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person = (
  serialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_personOrig
): ApplicationForView_application_RyanWhiteApplication_householdMembers_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_deductions
  ),
  documentFiles: serialized.documentFiles.map(
    deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_documentFiles
  ),
  updatedAt: parseDate(serialized.updatedAt),
  assets: serialized.assets.map(
    deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
})
export const serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_householdMembers_person
): ApplicationForView_application_RyanWhiteApplication_householdMembers_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_deductions
  ),
  documentFiles: deserialized.documentFiles.map(
    serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_documentFiles
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  assets: deserialized.assets.map(
    serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
})
export interface ApplicationForView_application_RyanWhiteApplication_householdMembers {
  __typename: 'ApplicationHouseholdMember'
  id: string
  wantsCoverage: boolean | null
  person: ApplicationForView_application_RyanWhiteApplication_householdMembers_person
  createdAt: Date
}
export const deserializeApplicationForView_application_RyanWhiteApplication_householdMembers = (
  serialized: ApplicationForView_application_RyanWhiteApplication_householdMembersOrig
): ApplicationForView_application_RyanWhiteApplication_householdMembers => ({
  ...serialized,
  person: deserializeApplicationForView_application_RyanWhiteApplication_householdMembers_person(
    serialized.person
  ),
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_RyanWhiteApplication_householdMembers = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_householdMembers
): ApplicationForView_application_RyanWhiteApplication_householdMembersOrig => ({
  ...deserialized,
  person: serializeApplicationForView_application_RyanWhiteApplication_householdMembers_person(
    deserialized.person
  ),
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_RyanWhiteApplication_documents_files {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeApplicationForView_application_RyanWhiteApplication_documents_files = (
  serialized: ApplicationForView_application_RyanWhiteApplication_documents_filesOrig
): ApplicationForView_application_RyanWhiteApplication_documents_files => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeApplicationForView_application_RyanWhiteApplication_documents_files = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_documents_files
): ApplicationForView_application_RyanWhiteApplication_documents_filesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface ApplicationForView_application_RyanWhiteApplication_documents_person {
  __typename: 'Person'
  id: string
}
export const deserializeApplicationForView_application_RyanWhiteApplication_documents_person = (
  serialized: ApplicationForView_application_RyanWhiteApplication_documents_personOrig
): ApplicationForView_application_RyanWhiteApplication_documents_person => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_documents_person = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_documents_person
): ApplicationForView_application_RyanWhiteApplication_documents_personOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_documents_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeApplicationForView_application_RyanWhiteApplication_documents_application = (
  serialized: ApplicationForView_application_RyanWhiteApplication_documents_applicationOrig
): ApplicationForView_application_RyanWhiteApplication_documents_application => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_documents_application = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_documents_application
): ApplicationForView_application_RyanWhiteApplication_documents_applicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_documents {
  __typename: 'Document'
  id: string
  documentType: string
  complete: boolean | null
  files: ApplicationForView_application_RyanWhiteApplication_documents_files[]
  updatedAt: Date
  notes: string | null
  person: ApplicationForView_application_RyanWhiteApplication_documents_person | null
  application: ApplicationForView_application_RyanWhiteApplication_documents_application
}
export const deserializeApplicationForView_application_RyanWhiteApplication_documents = (
  serialized: ApplicationForView_application_RyanWhiteApplication_documentsOrig
): ApplicationForView_application_RyanWhiteApplication_documents => ({
  ...serialized,
  files: serialized.files.map(
    deserializeApplicationForView_application_RyanWhiteApplication_documents_files
  ),
  updatedAt: parseDate(serialized.updatedAt),
  person:
    serialized.person != null
      ? deserializeApplicationForView_application_RyanWhiteApplication_documents_person(
          serialized.person
        )
      : serialized.person,
  application: deserializeApplicationForView_application_RyanWhiteApplication_documents_application(
    serialized.application
  ),
})
export const serializeApplicationForView_application_RyanWhiteApplication_documents = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_documents
): ApplicationForView_application_RyanWhiteApplication_documentsOrig => ({
  ...deserialized,
  files: deserialized.files.map(
    serializeApplicationForView_application_RyanWhiteApplication_documents_files
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  person:
    deserialized.person != null
      ? serializeApplicationForView_application_RyanWhiteApplication_documents_person(
          deserialized.person
        )
      : deserialized.person,
  application: serializeApplicationForView_application_RyanWhiteApplication_documents_application(
    deserialized.application
  ),
})
export interface ApplicationForView_application_RyanWhiteApplication_documentFiles_documents {
  __typename: 'Document'
  id: string
}
export const deserializeApplicationForView_application_RyanWhiteApplication_documentFiles_documents = (
  serialized: ApplicationForView_application_RyanWhiteApplication_documentFiles_documentsOrig
): ApplicationForView_application_RyanWhiteApplication_documentFiles_documents => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_documentFiles_documents = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_documentFiles_documents
): ApplicationForView_application_RyanWhiteApplication_documentFiles_documentsOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
  documents: ApplicationForView_application_RyanWhiteApplication_documentFiles_documents[]
}
export const deserializeApplicationForView_application_RyanWhiteApplication_documentFiles = (
  serialized: ApplicationForView_application_RyanWhiteApplication_documentFilesOrig
): ApplicationForView_application_RyanWhiteApplication_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
  documents: serialized.documents.map(
    deserializeApplicationForView_application_RyanWhiteApplication_documentFiles_documents
  ),
})
export const serializeApplicationForView_application_RyanWhiteApplication_documentFiles = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_documentFiles
): ApplicationForView_application_RyanWhiteApplication_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
  documents: deserialized.documents.map(
    serializeApplicationForView_application_RyanWhiteApplication_documentFiles_documents
  ),
})
export interface ApplicationForView_application_RyanWhiteApplication_benefitOutcomes_person {
  __typename: 'Person'
  id: string
}
export const deserializeApplicationForView_application_RyanWhiteApplication_benefitOutcomes_person = (
  serialized: ApplicationForView_application_RyanWhiteApplication_benefitOutcomes_personOrig
): ApplicationForView_application_RyanWhiteApplication_benefitOutcomes_person => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_benefitOutcomes_person = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_benefitOutcomes_person
): ApplicationForView_application_RyanWhiteApplication_benefitOutcomes_personOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_benefitOutcomes_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeApplicationForView_application_RyanWhiteApplication_benefitOutcomes_application = (
  serialized: ApplicationForView_application_RyanWhiteApplication_benefitOutcomes_applicationOrig
): ApplicationForView_application_RyanWhiteApplication_benefitOutcomes_application => ({
  ...serialized,
})
export const serializeApplicationForView_application_RyanWhiteApplication_benefitOutcomes_application = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_benefitOutcomes_application
): ApplicationForView_application_RyanWhiteApplication_benefitOutcomes_applicationOrig => ({
  ...deserialized,
})
export interface ApplicationForView_application_RyanWhiteApplication_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  person: ApplicationForView_application_RyanWhiteApplication_benefitOutcomes_person
  application: ApplicationForView_application_RyanWhiteApplication_benefitOutcomes_application | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication_benefitOutcomes = (
  serialized: ApplicationForView_application_RyanWhiteApplication_benefitOutcomesOrig
): ApplicationForView_application_RyanWhiteApplication_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  person: deserializeApplicationForView_application_RyanWhiteApplication_benefitOutcomes_person(
    serialized.person
  ),
  application:
    serialized.application != null
      ? deserializeApplicationForView_application_RyanWhiteApplication_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplicationForView_application_RyanWhiteApplication_benefitOutcomes = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_benefitOutcomes
): ApplicationForView_application_RyanWhiteApplication_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  person: serializeApplicationForView_application_RyanWhiteApplication_benefitOutcomes_person(
    deserialized.person
  ),
  application:
    deserialized.application != null
      ? serializeApplicationForView_application_RyanWhiteApplication_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface ApplicationForView_application_RyanWhiteApplication_accountOnDateOfService {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
  timeOfService: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location: string | null
  insuranceType: string
  status: string
  createdAt: Date
  updatedAt: Date
  notes: string | null
  accountAmount: number | null
  njmmisCheck: string | null
  previousInsurance: string | null
  invoiceMonth: string | null
  invoiceYear: string | null
  dischargeDate: Date | null
  isScheduled: boolean
}
export const deserializeApplicationForView_application_RyanWhiteApplication_accountOnDateOfService = (
  serialized: ApplicationForView_application_RyanWhiteApplication_accountOnDateOfServiceOrig
): ApplicationForView_application_RyanWhiteApplication_accountOnDateOfService => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
})
export const serializeApplicationForView_application_RyanWhiteApplication_accountOnDateOfService = (
  deserialized: ApplicationForView_application_RyanWhiteApplication_accountOnDateOfService
): ApplicationForView_application_RyanWhiteApplication_accountOnDateOfServiceOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
})
export interface ApplicationForView_application_RyanWhiteApplication {
  __typename: 'RyanWhiteApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  readyForAudit: boolean
  person: ApplicationForView_application_RyanWhiteApplication_person
  assignedTo: ApplicationForView_application_RyanWhiteApplication_assignedTo | null
  householdMembers: ApplicationForView_application_RyanWhiteApplication_householdMembers[]
  documents: ApplicationForView_application_RyanWhiteApplication_documents[]
  documentFiles: ApplicationForView_application_RyanWhiteApplication_documentFiles[]
  benefitOutcomes: ApplicationForView_application_RyanWhiteApplication_benefitOutcomes[]
  accountOnDateOfService: ApplicationForView_application_RyanWhiteApplication_accountOnDateOfService | null
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  facility: string
  dateOfApplication: Date | null
  coveragePeriod: number | null
}
export const deserializeApplicationForView_application_RyanWhiteApplication = (
  serialized: ApplicationForView_application_RyanWhiteApplicationOrig
): ApplicationForView_application_RyanWhiteApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  person: deserializeApplicationForView_application_RyanWhiteApplication_person(
    serialized.person
  ),
  assignedTo:
    serialized.assignedTo != null
      ? deserializeApplicationForView_application_RyanWhiteApplication_assignedTo(
          serialized.assignedTo
        )
      : serialized.assignedTo,
  householdMembers: serialized.householdMembers.map(
    deserializeApplicationForView_application_RyanWhiteApplication_householdMembers
  ),
  documents: serialized.documents.map(
    deserializeApplicationForView_application_RyanWhiteApplication_documents
  ),
  documentFiles: serialized.documentFiles.map(
    deserializeApplicationForView_application_RyanWhiteApplication_documentFiles
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplicationForView_application_RyanWhiteApplication_benefitOutcomes
  ),
  accountOnDateOfService:
    serialized.accountOnDateOfService != null
      ? deserializeApplicationForView_application_RyanWhiteApplication_accountOnDateOfService(
          serialized.accountOnDateOfService
        )
      : serialized.accountOnDateOfService,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
})
export const serializeApplicationForView_application_RyanWhiteApplication = (
  deserialized: ApplicationForView_application_RyanWhiteApplication
): ApplicationForView_application_RyanWhiteApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  person: serializeApplicationForView_application_RyanWhiteApplication_person(
    deserialized.person
  ),
  assignedTo:
    deserialized.assignedTo != null
      ? serializeApplicationForView_application_RyanWhiteApplication_assignedTo(
          deserialized.assignedTo
        )
      : deserialized.assignedTo,
  householdMembers: deserialized.householdMembers.map(
    serializeApplicationForView_application_RyanWhiteApplication_householdMembers
  ),
  documents: deserialized.documents.map(
    serializeApplicationForView_application_RyanWhiteApplication_documents
  ),
  documentFiles: deserialized.documentFiles.map(
    serializeApplicationForView_application_RyanWhiteApplication_documentFiles
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplicationForView_application_RyanWhiteApplication_benefitOutcomes
  ),
  accountOnDateOfService:
    deserialized.accountOnDateOfService != null
      ? serializeApplicationForView_application_RyanWhiteApplication_accountOnDateOfService(
          deserialized.accountOnDateOfService
        )
      : deserialized.accountOnDateOfService,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
})
export type ApplicationForView_application =
  | ApplicationForView_application_MedicaidApplication
  | ApplicationForView_application_MonitorApplication
  | ApplicationForView_application_CharityCareApplication
  | ApplicationForView_application_SlideApplication
  | ApplicationForView_application_RyanWhiteApplication
export const deserializeApplicationForView_application = (
  serialized: ApplicationForView_applicationOrig
): ApplicationForView_application => {
  if (false || serialized.__typename === 'MedicaidApplication')
    return deserializeApplicationForView_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'MonitorApplication')
    return deserializeApplicationForView_application_MonitorApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplicationForView_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplicationForView_application_SlideApplication(
      serialized
    )
  if (false || serialized.__typename === 'RyanWhiteApplication')
    return deserializeApplicationForView_application_RyanWhiteApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` + 'ApplicationForView_application'
  )
}
export const serializeApplicationForView_application = (
  deserialized: ApplicationForView_application
): ApplicationForView_applicationOrig => {
  if (false || deserialized.__typename === 'MedicaidApplication')
    return serializeApplicationForView_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'MonitorApplication')
    return serializeApplicationForView_application_MonitorApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplicationForView_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplicationForView_application_SlideApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'RyanWhiteApplication')
    return serializeApplicationForView_application_RyanWhiteApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` + 'ApplicationForView_application'
  )
}
export interface ApplicationForView {
  /**
   * Application
   */
  application: ApplicationForView_application
}
export const deserializeApplicationForView = (
  serialized: ApplicationForViewOrig
): ApplicationForView => ({
  ...serialized,
  application: deserializeApplicationForView_application(
    serialized.application
  ),
})
export const serializeApplicationForView = (
  deserialized: ApplicationForView
): ApplicationForViewOrig => ({
  ...deserialized,
  application: serializeApplicationForView_application(
    deserialized.application
  ),
})
export interface ApplicationForViewVariables {
  id: string
}
export const deserializeApplicationForViewVariables = (
  serialized: ApplicationForViewVariablesOrig
): ApplicationForViewVariables => ({...serialized})
export const serializeApplicationForViewVariables = (
  deserialized: ApplicationForViewVariables
): ApplicationForViewVariablesOrig => ({...deserialized})
