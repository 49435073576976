import React, {ComponentProps, ReactElement} from 'react'
import {Form as BaseForm, Formik, FormikContextType} from 'formik'
import {MutationFunction} from '@apollo/react-common'
import {
  flowMax,
  addDisplayName,
  addStateHandlers,
  addHandlers,
  addProps,
  addState,
  addWrapper,
  addEffect,
  addRef,
  addDefaultProps,
} from 'ad-hok'
import {ApolloError} from 'apollo-boost'
import {isEqual, set, get} from 'lodash/fp'
import {removeProps, addPropIdentityStabilization} from 'ad-hok-utils'
import sub from 'date-fns/sub'

import {addTranslationHelpers} from 'utils/i18n'
import Snackbar from 'components/Snackbar'
import Alert from 'components/Alert'
import NavigationPrompt from 'components/NavigationPrompt'
import {
  FormValues,
  FormSchema,
  FormCanonicalValues,
  FormSchemaFields,
  ExtractFormSchemaFields,
  FormInitialValues,
} from 'utils/form/schema'
import {
  addFormName,
  FormFieldStatuses,
  addFormContextProvider,
  addFormCanonicalValuesContextProvider,
} from 'utils/form/context'
import {addFormik} from 'utils/form/formik'
import {
  getCanonicalValues,
  getInitialValues,
  getCanonicalValuesMemoized,
  getFormValueChanges,
  FormValueChange,
} from 'utils/form/getValues'
import {getValidationSchema} from 'utils/form/validation'
import {
  Determiner,
  DeterminedEligibilitiesForBenefit,
  getFormStatuses,
} from 'utils/form/determiners'
import {
  getInitialFieldsUpdatedAt,
  getFieldsFreshnessConfirmed,
  getInitialFieldsFreshness,
  FieldsUpdatedAt,
  getFieldsFreshness,
} from 'utils/form/getFieldsUpdatedAt'
import addRouteParams from 'utils/addRouteParams'

export const formColumnWidth = 420

const DEFAULT_STALE_FIELD_THRESHOLD = 12 * 7 * 24 * 60 * 60 // 12 weeks as seconds

declare global {
  interface Window {
    __formDirtyNavigationAlertDisabled: boolean | null
  }
}

export interface OnSubmitSuccessOptions<
  TFields extends FormSchemaFields,
  TData = never
> {
  canonicalValues: FormCanonicalValues<TFields>
  formValues: FormValues<TFields>
  fieldsFreshness: any
  data?: TData
}

interface Props<TFields extends FormSchemaFields, TData>
  extends Omit<ComponentProps<typeof BaseForm>, 'onSubmit'> {
  name: string
  mutate?: MutationFunction<TData, FormCanonicalValues<TFields>>
  onSubmitSuccess?: (options: OnSubmitSuccessOptions<TFields, TData>) => void
  shouldResetFormOnInitialValuesChange?: boolean
  schema: FormSchema<TFields>
  initialValues?: FormInitialValues<TFields>
  initialTouched?: any
  determiners?: Determiner[]
  onEligibilityCheck?: (
    determinedEligibilities: DeterminedEligibilitiesForBenefit[]
  ) => void
  resetOnSaveSuccess?: boolean
  shouldTrackFieldsUpdatedAt?: boolean
  mapFieldsUpdatedAt?: (
    canonicalValues: FormCanonicalValues<TFields>,
    fieldsFreshness: any
  ) => FormCanonicalValues<TFields>
  initialFieldsFreshness?: any
}

type FormType = <TFields extends FormSchemaFields, TData>(
  props: Props<TFields, TData>
) => ReactElement<any, any> | null

const Form: FormType = flowMax(
  addDisplayName('Form'),
  addFormName(({name}) => name),
  addState(
    'formFieldStatuses',
    'setFormFieldStatuses',
    {} as FormFieldStatuses
  ),
  addTranslationHelpers,
  addDefaultProps(() => ({
    shouldTrackFieldsUpdatedAt: false,
  })),
  addStateHandlers(
    {
      fieldsUpdatedAt: {} as any,
    },
    {
      setFieldsUpdatedAt: () => (fieldsUpdatedAt: any) => ({fieldsUpdatedAt}),
      setCollectionItemFieldsUpdatedAt: ({fieldsUpdatedAt}) => (
        itemName: string,
        itemFieldsUpdatedAt: any
      ) => ({
        fieldsUpdatedAt: set(itemName, itemFieldsUpdatedAt, fieldsUpdatedAt),
      }),
      removeCollectionItemFieldsUpdatedAt: ({fieldsUpdatedAt}) => (
        collectionName: string,
        index: number
      ) => {
        const filteredCollection = (
          (get(collectionName, fieldsUpdatedAt) as any[] | undefined) || []
        ).filter((_collectionItem, itemIndex) => itemIndex !== index)

        return {
          fieldsUpdatedAt: set(
            collectionName,
            filteredCollection,
            fieldsUpdatedAt
          ),
        }
      },
    }
  ),
  addStateHandlers(
    ({initialFieldsFreshness}) => ({
      fieldsFreshness: initialFieldsFreshness ?? {},
    }),
    {
      setFieldsFreshness: () => (fieldsFreshness: any) => ({
        fieldsFreshness,
      }),
      updateFieldsFreshness: ({fieldsFreshness}) => ({
        changes,
        fieldsUpdatedAt,
      }: {
        changes: FormValueChange[]
        fieldsUpdatedAt: FieldsUpdatedAt
      }) => ({
        fieldsFreshness: getFieldsFreshness({
          fieldsFreshness,
          changes,
          fieldsUpdatedAt,
        }),
      }),
      setCollectionItemFieldsFreshness: ({fieldsFreshness}) => (
        itemName: string,
        itemFieldsFreshness: any
      ) => ({
        fieldsFreshness: set(itemName, itemFieldsFreshness, fieldsFreshness),
      }),
      removeCollectionItemFieldsFreshness: ({fieldsFreshness}) => (
        collectionName: string,
        index: number
      ) => {
        const filteredCollection = (
          (get(collectionName, fieldsFreshness) as any[] | undefined) || []
        ).filter((_collectionItem, itemIndex) => itemIndex !== index)

        return {
          fieldsFreshness: set(
            collectionName,
            filteredCollection,
            fieldsFreshness
          ),
        }
      },
      toggleFreshnessConfirmed: ({fieldsFreshness}) => (name: string) => ({
        fieldsFreshness: set(
          `${name}.confirmed`,
          !getFieldsFreshnessConfirmed(fieldsFreshness, name),
          fieldsFreshness
        ),
      }),
    }
  ),
  addProps(
    ({schema, t, initialValues}) => ({
      initialValues: getInitialValues(schema, initialValues),
      validationSchema: getValidationSchema({schema, t}),
      initialFieldsUpdatedAt: getInitialFieldsUpdatedAt(schema, initialValues),
    }),
    ['schema', 't', 'initialValues']
  ),
  addPropIdentityStabilization('initialFieldsUpdatedAt'),
  addPropIdentityStabilization('initialValues'),
  addEffect(
    ({
      setFieldsUpdatedAt,
      setFieldsFreshness,
      schema,
      initialValues,
      shouldTrackFieldsUpdatedAt,
      shouldResetFormOnInitialValuesChange,
      initialFieldsUpdatedAt,
    }) => () => {
      if (!(shouldTrackFieldsUpdatedAt && shouldResetFormOnInitialValuesChange))
        return

      setFieldsUpdatedAt(initialFieldsUpdatedAt)

      const changes = getFormValueChanges(schema, initialValues, initialValues)
      setFieldsFreshness(
        getInitialFieldsFreshness({
          changes,
          fieldsUpdatedAt: initialFieldsUpdatedAt,
        })
      )
    },
    ['initialFieldsUpdatedAt', 'initialValues']
  ),
  addStateHandlers(
    ({schema, initialValues}) => ({
      formCanonicalValues: getCanonicalValues(initialValues, schema),
    }),
    {
      setFormCanonicalValues: (_, {schema}) => (
        formCanonicalValues: FormCanonicalValues<
          ExtractFormSchemaFields<typeof schema>
        >
      ) => ({
        formCanonicalValues,
      }),
    }
  ),
  addFormContextProvider,
  addFormCanonicalValuesContextProvider,
  addStateHandlers(
    {
      showingErrorMessage: null as string | null,
    },
    {
      showErrorMessage: () => (message: string) => ({
        showingErrorMessage: message,
      }),
      dismissErrorMessage: () => () => ({showingErrorMessage: null}),
    }
  ),
  addHandlers({
    onError: ({showErrorMessage, t}) => ({
      setSubmitting,
    }: {
      setSubmitting: (isSubmitting: boolean) => void
    }) => (error: ApolloError) => {
      console.error({error})
      showErrorMessage(t('form.failedToSave'))
      setSubmitting(false)
    },
  }),
  addRouteParams<{staleThresholdSeconds: string | undefined}>(),
  addProps(
    ({
      shouldTrackFieldsUpdatedAt,
      staleThresholdSeconds: staleThresholdSecondsOverride,
    }) => {
      if (!shouldTrackFieldsUpdatedAt) return {}
      const staleThresholdSeconds = staleThresholdSecondsOverride
        ? parseInt(staleThresholdSecondsOverride)
        : DEFAULT_STALE_FIELD_THRESHOLD
      return {
        staleThresholdDate: sub(new Date(), {seconds: staleThresholdSeconds}),
      }
    },
    ['shouldTrackFieldsUpdatedAt', 'staleThresholdSeconds']
  ),
  addState(
    'submitSuccessOptions',
    'setSubmitSuccessOptions',
    null as null | OnSubmitSuccessOptions<any, any>
  ),
  addWrapper(
    (
      render,
      {
        schema,
        mutate,
        onError,
        initialValues,
        initialTouched,
        validationSchema,
        setSubmitSuccessOptions,
        resetOnSaveSuccess,
        fieldsFreshness,
        mapFieldsUpdatedAt,
      }
    ) => (
      <Formik
        initialValues={initialValues}
        initialTouched={initialTouched}
        validationSchema={validationSchema}
        onSubmit={(values, {setSubmitting, resetForm}) => {
          const canonicalValues = getCanonicalValues(values, schema)

          if (!mutate) {
            if (resetOnSaveSuccess) resetForm()
            setSubmitting(false)
            setSubmitSuccessOptions({
              canonicalValues,
              formValues: values,
              fieldsFreshness,
            })
            return
          }

          mutate({
            variables: mapFieldsUpdatedAt
              ? mapFieldsUpdatedAt(canonicalValues, fieldsFreshness)
              : canonicalValues,
          })
            .then((maybeData: any) => {
              if (!maybeData?.data) {
                onError({setSubmitting})(maybeData?.error)
              } else {
                if (resetOnSaveSuccess) resetForm()
                setSubmitting(false)
                setSubmitSuccessOptions({
                  canonicalValues,
                  formValues: values,
                  fieldsFreshness,
                  data: maybeData.data,
                })
              }
            })
            .catch((error: ApolloError) => {
              onError({setSubmitting})(error)
            })
        }}
      >
        {render()}
      </Formik>
    )
  ),
  addFormik,
  addProps(({formik, schema}) => ({
    formik: formik as FormikContextType<
      FormValues<ExtractFormSchemaFields<typeof schema>>
    >,
  })),
  addRef('previousInitialValues', ({initialValues}) => initialValues),
  addEffect(
    ({
      shouldResetFormOnInitialValuesChange,
      initialValues,
      previousInitialValues,
      formik: {resetForm},
    }) => () => {
      if (!shouldResetFormOnInitialValuesChange) return
      if (!isEqual(initialValues, previousInitialValues.current)) {
        resetForm({values: initialValues})
      }
      previousInitialValues.current = initialValues
    },
    [
      'initialValues',
      'previousInitialValues',
      'formik.resetForm',
      'shouldResetFormOnInitialValuesChange',
    ]
  ),
  // eslint-disable-next-line ad-hok/dependencies
  addEffect(
    ({
      setSubmitSuccessOptions,
      submitSuccessOptions,
      onSubmitSuccess,
    }) => () => {
      if (submitSuccessOptions) {
        onSubmitSuccess?.(submitSuccessOptions)
        setSubmitSuccessOptions(null)
      }
    },
    ['submitSuccessOptions']
  ),
  // eslint-disable-next-line ad-hok/dependencies
  addEffect(
    ({
      formik: {values, errors},
      setFormFieldStatuses,
      determiners,
      formName,
      schema,
      onEligibilityCheck,
      fieldsFreshness,
      staleThresholdDate,
      formFieldStatuses,
      t,
    }) => () => {
      if (!determiners) return

      const {statuses, determinedEligibilities} = getFormStatuses({
        values,
        fieldsFreshness,
        staleThresholdDate,
        determiners,
        formName,
        schema,
        errors,
        t,
      })
      if (!isEqual(statuses, formFieldStatuses)) {
        setFormFieldStatuses(statuses)
      }
      onEligibilityCheck?.(determinedEligibilities)
    },
    ['formik.values', 'formik.errors', 'determiners.length', 'fieldsFreshness']
  ),
  removeProps(['formFieldStatuses']),
  addRef('previousFormValuesRef', ({formik: {values}}) => values),
  // eslint-disable-next-line ad-hok/dependencies
  addEffect(
    ({
      formik: {values},
      setFormCanonicalValues,
      schema,
      previousFormValuesRef,
      formCanonicalValues,
    }) => () => {
      setFormCanonicalValues(
        getCanonicalValuesMemoized(
          values,
          schema,
          previousFormValuesRef.current,
          formCanonicalValues
        )
      )
    },
    ['formik.values', 'schema']
  ),
  // eslint-disable-next-line ad-hok/dependencies
  addEffect(
    ({
      schema,
      previousFormValuesRef,
      formik: {values},
      fieldsUpdatedAt,
      updateFieldsFreshness,
      shouldTrackFieldsUpdatedAt,
    }) => () => {
      if (!shouldTrackFieldsUpdatedAt) return

      const changes = getFormValueChanges(
        schema,
        previousFormValuesRef.current,
        values
      )

      updateFieldsFreshness({
        changes,
        fieldsUpdatedAt,
      })
    },
    ['formik.values', 'previousFormValuesRef', 'fieldsUpdatedAt']
  ),
  // eslint-disable-next-line ad-hok/dependencies
  addEffect(
    ({previousFormValuesRef, formik: {values}}) => () => {
      previousFormValuesRef.current = values
    },
    ['formik.values']
  ),
  addWrapper((render, {formik: {dirty}, t}) => (
    <>
      <NavigationPrompt
        when={dirty && !window.__formDirtyNavigationAlertDisabled}
        message={t('form.navigationConfirmation')}
      />
      {render()}
    </>
  )),
  removeProps([
    'initialValues',
    'onError',
    'mutate',
    'schema',
    'validationSchema',
    'showErrorMessage',
    't',
    'setFormFieldStatuses',
    'formik',
    'formName',
    'determiners',
    'onSubmitSuccess',
    'onEligibilityCheck',
    'initialTouched',
    'previousInitialValues',
    'submitSuccessOptions',
    'setSubmitSuccessOptions',
    'resetOnSaveSuccess',
    'shouldResetFormOnInitialValuesChange',
    'formCanonicalValues',
    'setFormCanonicalValues',
    'previousFormValuesRef',
    'fieldsFreshness',
    'setFieldsFreshness',
    'updateFieldsFreshness',
    'shouldTrackFieldsUpdatedAt',
    'staleThresholdSeconds',
    'staleThresholdDate',
    'fieldsUpdatedAt',
    'mapFieldsUpdatedAt',
    'toggleFreshnessConfirmed',
    'setCollectionItemFieldsFreshness',
    'removeCollectionItemFieldsFreshness',
    'initialFieldsFreshness',
    'setFieldsUpdatedAt',
    'setCollectionItemFieldsUpdatedAt',
    'removeCollectionItemFieldsUpdatedAt',
    'initialFieldsUpdatedAt',
  ]),
  ({showingErrorMessage, dismissErrorMessage, ...props}) => (
    <>
      <BaseForm {...props} />
      <Snackbar open={!!showingErrorMessage} onClose={dismissErrorMessage}>
        <Alert onClose={dismissErrorMessage} severity="error">
          {showingErrorMessage}
        </Alert>
      </Snackbar>
    </>
  )
)

export default Form
