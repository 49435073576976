import React, {FC} from 'react'
import {identity} from 'lodash/fp'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import {generatePath} from 'react-router'
import {format} from 'date-fns/fp'
import {zonedTimeToUtc} from 'date-fns-tz'
import {TFunction} from 'i18next'

import {makeClasses, addClasses} from 'theme'
import {addTranslationHelpers} from 'utils/i18n'
import Paper from 'components/Paper'
import Grid from 'components/Grid'
import Heading from 'components/Heading'
import Body1 from 'components/Body1'
import Caption from 'components/Caption'
import Button from 'components/Button'
import Link from 'components/Link'
import {
  personDetailDefaultPath,
  editAccountPath,
  editPersonPath,
} from 'components/TopLevelRoutes'
import TriageStatusUpdateSelect from 'components/TriageStatusUpdateSelect'
import {formatTimeAgo, formatShortDateTimeEastern} from 'utils/date'
import {isoDateFormat} from 'utils/form/fieldTypes'
import {getExtendedName} from 'utils/name'
import {TRIAGE_STATUS_CLOSED} from 'utils/triageStatuses'
import DisplayLocation from 'components/DisplayLocation'
import DisplayItem from 'components/DisplayItem'
import LabeledDisplayItem from 'components/LabeledDisplayItem'
import {
  Triages_triages_account_person,
  Triages_triages,
} from 'graphql/deserializedTypes/Triages'
import TriageAssignedToSelect from 'components/TriageAssignedToSelect'
import {getGenderAbbreviation} from 'utils/gender'

const classes = makeClasses((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  actionContainer: {
    alignSelf: 'flex-end',
  },
  nameHeader: {
    marginBottom: theme.spacing(2),
  },
  leftColumnsContainer: {
    flex: 1,
  },
  leftColumnContainer: {
    flex: 1,
    maxWidth: 350,
  },
  rightColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  rightColumnInnerContainer: {
    flex: 1,
  },
  statusContainer: {
    marginBottom: theme.spacing(2),
  },
  closeDate: {
    marginTop: theme.spacing(1),
  },
  closeComment: {
    maxWidth: 450,
  },
  assignedToSelect: {
    marginRight: theme.spacing(2),
  },
}))

const getPersonDescription = (
  person: Triages_triages_account_person
): string => {
  const parensContents = [
    person.dob && format('M/d/yyyy')(person.dob),
    person.gender && getGenderAbbreviation(person.gender),
  ]
    .filter(identity)
    .join(', ')

  return parensContents ? `(${parensContents})` : ''
}

interface GetDateOfServiceFormattedOptions {
  dateOfService: Date
  timeOfService?: string | null
  serviceTimeZone: string
  isScheduled: boolean
  t: TFunction
}

const getDateOfServiceFormatted = ({
  dateOfService,
  timeOfService,
  serviceTimeZone,
  isScheduled,
  t,
}: GetDateOfServiceFormattedOptions) => {
  const dateAndMaybeTimeOfService = timeOfService
    ? zonedTimeToUtc(
        `${format(isoDateFormat)(dateOfService)} ${timeOfService}`,
        serviceTimeZone
      )
    : dateOfService
  return `${format('M/d/yy')(dateOfService)}${
    timeOfService ? `, ${timeOfService}` : ''
  } (${formatTimeAgo(dateAndMaybeTimeOfService, {
    includeSeconds: !!timeOfService,
  })})${isScheduled ? ` - ${t('triageItem.scheduled')}` : ''}`
}

interface Props {
  triage: Triages_triages
}

const TriageItem: FC<Props> = flowMax(
  addDisplayName('TriageItem'),
  addTranslationHelpers,
  addClasses(classes),
  addProps(
    ({
      triage: {
        account: {
          person,
          person: {id},
          dischargeDate,
        },
        account,
      },
      t,
    }) => ({
      actionUrl: generatePath(editPersonPath, {id}),
      personName: getExtendedName({...person, t}),
      personDescription: getPersonDescription(person),
      personId: id,
      dateOfServiceFormatted: getDateOfServiceFormatted({...account, t}),
      dischargeDateFormatted: dischargeDate
        ? format('M/d/yy')(dischargeDate)
        : '',
    })
  ),
  ({
    triage: {account, id},
    triage,
    actionUrl,
    personName,
    personDescription,
    personId,
    t,
    classes,
    dateOfServiceFormatted,
    dischargeDateFormatted,
  }) => (
    <>
      <Paper className={classes.paper} data-testid={`triage-card-${id}`}>
        <Grid container direction="row" justify="space-between">
          <div className={classes.leftColumnsContainer}>
            <Grid container direction="column">
              <Heading
                variant="h5"
                component="h2"
                className={classes.nameHeader}
              >
                <Link
                  to={generatePath(personDetailDefaultPath, {id: personId})}
                  highlight
                >
                  {personName}
                </Link>{' '}
                {personDescription}
              </Heading>
              <Body1>
                <Link
                  to={generatePath(editAccountPath, {id: account.id})}
                  highlight
                >
                  {t('triageItem.accountNumber', {
                    accountNumber: account.hospitalAccountId,
                  })}
                </Link>
              </Body1>
              <Grid container direction="row">
                <div className={classes.leftColumnContainer}>
                  <DisplayLocation account={account} />
                  <DisplayItem
                    i18nKey="triageItem.dateOfService"
                    translations={{
                      dateOfService: dateOfServiceFormatted,
                    }}
                  />
                  {account.dischargeDate && (
                    <DisplayItem
                      i18nKey="triageItem.dischargeDate"
                      translations={{
                        dischargeDate: dischargeDateFormatted,
                      }}
                    />
                  )}
                </div>
                <div>
                  <DisplayItem
                    i18nKey="triageItem.insuranceOnDateOfService"
                    translations={{
                      insuranceType: account.insuranceType,
                    }}
                  />
                  {account.njmmisCheck && (
                    <LabeledDisplayItem
                      label={t('triageItem.njmmisCheck')}
                      value={account.njmmisCheck}
                    />
                  )}
                  {account.previousInsurance && (
                    <LabeledDisplayItem
                      label={t('triageItem.previousInsurance')}
                      value={account.previousInsurance}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={classes.rightColumnContainer}>
            <Grid
              container
              direction="column"
              justify="space-between"
              className={classes.rightColumnInnerContainer}
            >
              <Grid container direction="row">
                <TriageAssignedToSelect
                  triage={triage}
                  className={classes.assignedToSelect}
                />
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    alignItems="flex-end"
                    className={classes.statusContainer}
                  >
                    <TriageStatusUpdateSelect triage={triage} />
                    {triage.status === TRIAGE_STATUS_CLOSED && (
                      <>
                        <Caption className={classes.closeDate}>
                          {t('triageItem.closeDate', {
                            date: formatShortDateTimeEastern(triage.updatedAt),
                          })}
                        </Caption>
                        <Caption>
                          {t('triageItem.closeReason', {
                            reason: triage.closeReason,
                          })}
                        </Caption>
                        {triage.closeComment && (
                          <Caption className={classes.closeComment}>
                            {t('triageItem.closeComment', {
                              comment: triage.closeComment,
                            })}
                          </Caption>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {triage.status !== TRIAGE_STATUS_CLOSED && (
                <Grid item className={classes.actionContainer}>
                  <Button
                    color="primary"
                    component={Link}
                    variant="contained"
                    to={actionUrl}
                  >
                    {t('triageItem.actionButtonLabel')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </Grid>
      </Paper>
    </>
  )
)

export default TriageItem
