import React from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addHandlers,
  addWrapper,
  branch,
  renderNothing,
  addStateHandlers,
} from 'ad-hok'
import {FC} from 'react'
import {generatePath} from 'react-router'
import {TFunction} from 'i18next'
import {format} from 'date-fns/fp'
import UndoIcon from '@material-ui/icons/Undo'
import {branchIfNullish, branchIfEmpty} from 'ad-hok-utils'
import RefreshIcon from '@material-ui/icons/Refresh'

import {Inbounds_inbounds} from 'graphql/deserializedTypes/Inbounds'
import {addClasses, makeClasses} from 'theme'
import Paper from 'components/Paper'
import {getExtendedName} from 'utils/name'
import {addTranslationHelpers} from 'utils/i18n'
import Heading from 'components/Heading'
import Link from 'components/Link'
import {
  getShortDate,
  getAge,
  toEasternTime,
  formatTimeAgo,
  getFormattedDateOfService,
} from 'utils/date'
import DisplayItem from 'components/DisplayItem'
import {
  addStartInboundMutation,
  addCompleteInboundMutation,
  addUncompleteInboundMutation,
  addUnstartInboundMutation,
  addRetryInboundNjmmisCheckMutation,
} from 'graphql/generated'
import Button from 'components/Button'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import {editPersonPath} from 'components/TopLevelRoutes'
import IconButton from 'components/IconButton'
import {
  InboundType,
  InboundInsuranceOnDateOfService,
  NjmmisCheckStatus,
} from 'graphql/deserializedTypes/globalTypes'
import Grid from 'components/Grid'
import {NjmmisCheckFields} from 'graphql/deserializedTypes/NjmmisCheckFields'
import InfoTooltip from 'components/InfoTooltip'
import Body1 from 'components/Body1'
import EligibilityDeterminationStatus from 'components/EligibilityDeterminationStatus'
import InputWithClearButton from 'components/InputWithClearButton'
import {StartInbound} from 'graphql/deserializedTypes/StartInbound'
import {addNavigate} from 'utils/routing'
import typedAs from 'utils/typedAs'
import {getFormattedPhoneNumber} from 'utils/phone'

const classes = makeClasses((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
    position: 'relative',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },
  status: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  startedBy: {
    marginBottom: theme.spacing(2),
  },
  nameHeader: {
    marginBottom: theme.spacing(2),
  },
  leftColumnsContainer: {
    flex: 1,
  },
  leftColumnContainer: {
    flex: 1,
    maxWidth: 350,
  },
  serviceType: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  medicaidEligibilityDeterminationStatus: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(0.5),
  },
  mrnSearchInput: {
    marginBottom: theme.spacing(1),
  },
}))

type StartedInbound = Inbounds_inbounds & {
  startedBy: NonNullable<Inbounds_inbounds['startedBy']>
  startedAt: NonNullable<Inbounds_inbounds['startedAt']>
}

const isStartedInbound = (
  inbound: Inbounds_inbounds
): inbound is StartedInbound => !!inbound.startedBy && !!inbound.startedAt

interface StartedByProps {
  inbound: StartedInbound
  onUndo: () => void
}

const StartedBy: FC<StartedByProps> = flowMax(
  addDisplayName('StartedBy'),
  addTranslationHelpers,
  addProps(({inbound: {startedBy}, t}) => ({
    startedByName: t('inboundItem.startedBy', {name: startedBy.name}),
  })),
  addClasses(classes),
  ({startedByName, onUndo, classes}) => (
    <div className={classes.startedBy}>
      {startedByName}
      <IconButton onClick={onUndo}>
        <UndoIcon />
      </IconButton>
    </div>
  )
)

type CompletedInbound = StartedInbound & {
  completedBy: NonNullable<Inbounds_inbounds['completedBy']>
  completedAt: NonNullable<Inbounds_inbounds['completedAt']>
}

const isCompletedInbound = (
  inbound: Inbounds_inbounds
): inbound is CompletedInbound =>
  isStartedInbound(inbound) && !!inbound.completedBy && !!inbound.completedAt

interface CompletedByProps {
  inbound: CompletedInbound
}

const CompletedBy: FC<CompletedByProps> = flowMax(
  addDisplayName('CompletedBy'),
  addTranslationHelpers,
  addProps(({inbound: {completedBy}, t}) => ({
    completedByName: t('inboundItem.completedBy', {name: completedBy.name}),
  })),
  addClasses(classes),
  ({completedByName, classes}) => (
    <div className={classes.startedBy}>{completedByName}</div>
  )
)

const getActionLabel = (
  {startedBy, completedBy}: Inbounds_inbounds,
  t: TFunction
) =>
  completedBy
    ? t('inboundItem.uncomplete')
    : startedBy
    ? t('inboundItem.complete')
    : t('inboundItem.start')

interface StatusProps {
  inbound: Inbounds_inbounds
  refetchInbounds: () => void
}

const Status: FC<StatusProps> = flowMax(
  addDisplayName('Status'),
  addProps(({inbound: {startedBy, completedBy}}) => ({
    startedBy,
    completedBy,
  })),
  addStateHandlers(
    ({inbound}) => ({personMrn: '', startButtonDisabled: !inbound.person}),
    {
      personMrnChange: () => (personMrn: string) => ({
        personMrn,
        startButtonDisabled: personMrn.length === 0,
      }),
      clearPersonMrn: () => () => ({personMrn: '', startButtonDisabled: true}),
    }
  ),
  addStartInboundMutation({
    variables: ({inbound: {id}, personMrn}) => ({
      id,
      associateWithPersonHavingMrn: personMrn.length > 0 ? personMrn : null,
    }),
  }),
  addUnstartInboundMutation({
    variables: ({inbound: {id}}) => ({id}),
  }),
  addCompleteInboundMutation({
    variables: ({inbound: {id}}) => ({id}),
  }),
  addUncompleteInboundMutation({
    variables: ({inbound: {id}}) => ({id}),
  }),
  addTranslationHelpers,
  addProps(({inbound, t}) => ({
    actionLabel: getActionLabel(inbound, t),
  })),
  addAppSnackbarContext,
  addProps(({inbound}) => ({
    showMrnSearchInput: !(
      inbound.person ||
      isStartedInbound(inbound) ||
      isCompletedInbound(inbound)
    ),
  })),
  addNavigate,
  addHandlers({
    onChangeStatus: ({
      startedBy,
      completedBy,
      mutateStartInbound,
      mutateCompleteInbound,
      mutateUncompleteInbound,
      showSnackbarMessage,
      refetchInbounds,
      navigate,
      t,
    }) => () => {
      const mutatePromise: Promise<unknown> = completedBy
        ? mutateUncompleteInbound()
        : startedBy
        ? mutateCompleteInbound()
        : mutateStartInbound()
      mutatePromise
        .catch(() => {
          showSnackbarMessage(
            completedBy
              ? t('inboundItem.uncompleteError')
              : startedBy
              ? t('inboundItem.completeError')
              : t('inboundItem.startError')
          )
        })
        .then((result: any) => {
          if (result && !startedBy && !completedBy) {
            const {data, errors} = typedAs<{
              data: StartInbound | null
              errors: [] | null
            }>(result)
            if (!errors && data?.startInbound?.person) {
              navigate(
                generatePath(editPersonPath, {id: data.startInbound.person.id})
              )
            }
          } else {
            refetchInbounds()
          }
        })
    },
    onUndo: ({
      mutateUnstartInbound,
      refetchInbounds,
      showSnackbarMessage,
      t,
    }) => () => {
      mutateUnstartInbound()
        .catch(() => {
          showSnackbarMessage(t('inboundItem.unstartError'))
        })
        .then(() => {
          refetchInbounds()
        })
    },
  }),
  addHandlers({
    onMrnSearchEnter: ({startButtonDisabled, onChangeStatus}) => () => {
      if (!startButtonDisabled) onChangeStatus()
    },
  }),
  addClasses(classes),
  addTranslationHelpers,
  ({
    actionLabel,
    onChangeStatus,
    onUndo,
    inbound,
    showMrnSearchInput,
    personMrn,
    personMrnChange,
    clearPersonMrn,
    onMrnSearchEnter,
    startButtonDisabled,
    classes,
    t,
  }) => (
    <div className={classes.status}>
      {isCompletedInbound(inbound) ? (
        <CompletedBy inbound={inbound} />
      ) : isStartedInbound(inbound) ? (
        <StartedBy inbound={inbound} onUndo={onUndo} />
      ) : (
        <div />
      )}
      {showMrnSearchInput && (
        <InputWithClearButton
          label={t('inboundItem.mrnMatchLabel')}
          inputClassName={classes.mrnSearchInput}
          value={personMrn}
          onChange={personMrnChange}
          onEnter={onMrnSearchEnter}
          onClear={clearPersonMrn}
        />
      )}
      <Button
        color="primary"
        variant="contained"
        disabled={startButtonDisabled}
        onClick={onChangeStatus}
      >
        {actionLabel}
      </Button>
    </div>
  )
)

const getFormattedDateOfBirth = ({
  dob,
  t,
}: {
  dob: Date | null | undefined
  t: TFunction
}) =>
  `(${
    dob
      ? `${getShortDate(dob, {showFullYear: true})}, ${getAge(dob)} yrs`
      : t('general.unknown')
  })`

const getFormattedCreateDate = (createdAt: Date) => {
  const createdAtEasternTime = toEasternTime(createdAt)
  return `${format('M/d/yy hh:mm a')(createdAtEasternTime)} (${formatTimeAgo(
    createdAtEasternTime,
    {
      includeSeconds: true,
    }
  )})`
}

const getFormattedLastIntakeWebformReceivedAt = (
  lastIntakeWebformReceivedAt: Date | null
) =>
  lastIntakeWebformReceivedAt
    ? format('M/d/yyyy hh:mm a')(toEasternTime(lastIntakeWebformReceivedAt))
    : null

const getFormattedInsuranceOnDateOfService = (
  insuranceOnDateOfService: InboundInsuranceOnDateOfService | null,
  t: TFunction
): string | null => {
  if (!insuranceOnDateOfService) return null
  return t(
    `inboundItem.insuranceOnDateOfServiceLabels.${insuranceOnDateOfService}`
  )
}

const getNjmmisCheckFormatted = (
  njmmisCheck: NjmmisCheckFields,
  t: TFunction
): string =>
  njmmisCheck.status === NjmmisCheckStatus.hasCurrentCoverage
    ? njmmisCheck.medicaidId!
    : t(`inboundItem.njmmisCheck.${njmmisCheck.status}`)

interface NjmmisCheckRetryButtonProps {
  inbound: Inbounds_inbounds
}

const EligibleServicesTooltip: FC<EligibleServicesTooltipProps> = flowMax(
  addDisplayName('EligibleServicesTooltip'),
  addProps(({inbound: {mostRecentNjmmisCheck: njmmisCheck}}) => ({
    njmmisCheck,
  })),
  branchIfNullish('njmmisCheck'),
  addProps(({njmmisCheck: {eligibleServices}}) => ({
    eligibleServices,
  })),
  branchIfEmpty('eligibleServices'),
  ({eligibleServices}) => (
    <InfoTooltip
      info={
        <>
          <ul>
            {eligibleServices.map((eligibleService, index) => (
              <li key={index}>{eligibleService}</li>
            ))}
          </ul>
        </>
      }
    />
  )
)

interface EligibleServicesTooltipProps {
  inbound: Inbounds_inbounds
}

const NjmmisCheckRetryButton: FC<NjmmisCheckRetryButtonProps> = flowMax(
  addDisplayName('NjmmisCheckRetryButton'),
  addProps(({inbound: {mostRecentNjmmisCheck: njmmisCheck}}) => ({
    njmmisCheck,
  })),
  branchIfNullish('njmmisCheck'),
  branch(
    ({njmmisCheck: {status}}) => status !== NjmmisCheckStatus.checkFailed,
    renderNothing()
  ),
  addRetryInboundNjmmisCheckMutation({
    variables: ({inbound: {id}}) => ({
      id,
    }),
  }),
  addHandlers({
    onClick: ({mutateRetryInboundNjmmisCheck}) => () => {
      mutateRetryInboundNjmmisCheck()
    },
  }),
  ({onClick}) => (
    <IconButton onClick={onClick}>
      <RefreshIcon />
    </IconButton>
  )
)

interface NjmmisCheckProps {
  inbound: Inbounds_inbounds
}

const NjmmisCheck: FC<NjmmisCheckProps> = flowMax(
  addDisplayName('NjmmisCheck'),
  addProps(({inbound: {mostRecentNjmmisCheck: njmmisCheck}}) => ({
    njmmisCheck,
  })),
  branchIfNullish('njmmisCheck'),
  addTranslationHelpers,
  addProps(({njmmisCheck, t}) => ({
    njmmisCheckFormatted: getNjmmisCheckFormatted(njmmisCheck, t),
  })),
  ({inbound, njmmisCheckFormatted}) => (
    <Grid container direction="row" alignItems="center">
      <DisplayItem
        i18nKey="inboundItem.njmmisCheckItem"
        translations={{
          status: njmmisCheckFormatted,
        }}
      />
      <NjmmisCheckRetryButton inbound={inbound} />
      <EligibleServicesTooltip inbound={inbound} />
    </Grid>
  )
)

interface Props {
  inbound: Inbounds_inbounds
  refetchInbounds: () => void
  inboundType: InboundType
}

const InboundItem: FC<Props> = flowMax(
  addDisplayName('InboundItem'),
  addTranslationHelpers,
  addProps(
    ({
      inbound: {
        person,
        personFormData,
        createdAt,
        dateOfService,
        lastIntakeWebformReceivedAt,
        insuranceOnDateOfService,
        inboundType,
        serviceType,
      },
      t,
    }) => ({
      personName: person
        ? getExtendedName({
            firstName: person.firstName,
            lastName: person.lastName,
            preferredName: person.preferredName,
            middleName: person.middleName,
            suffix: person.suffix,
            t,
          })
        : personFormData
        ? getExtendedName({
            firstName: personFormData.firstName,
            lastName: personFormData.lastName,
            preferredName: null,
            middleName: null,
            suffix: null,
            t,
          })
        : '',
      dobFormatted: getFormattedDateOfBirth({
        dob: person?.dob || personFormData?.dob,
        t,
      }),

      createdAtFormatted: getFormattedCreateDate(createdAt),
      dateOfServiceFormatted: getFormattedDateOfService(dateOfService),
      lastIntakeWebformReceivedAtFormatted: getFormattedLastIntakeWebformReceivedAt(
        lastIntakeWebformReceivedAt
      ),
      insuranceOnDateOfServiceFormatted: getFormattedInsuranceOnDateOfService(
        insuranceOnDateOfService,
        t
      ),
      serviceTypeFormatted:
        inboundType === InboundType.hospitalVisit ? serviceType : null,
      openApplicationsFormatted: person?.openApplications
        ? person.openApplications
            .map(({benefit}) => t(`applications.benefitLabels.${benefit}`))
            .join(', ')
        : '',
      phoneNumber: personFormData?.phoneNumber
        ? getFormattedPhoneNumber(personFormData.phoneNumber)
        : '',
    })
  ),
  addClasses(classes),
  addWrapper(
    (
      render,
      {
        inbound,
        inbound: {person},
        personName,
        dobFormatted,
        refetchInbounds,
        openApplicationsFormatted,
        classes,
      }
    ) => (
      <Paper className={classes.paper}>
        <div className={classes.leftColumnsContainer}>
          <Heading variant="h5" component="h2" className={classes.nameHeader}>
            {person ? (
              <Link
                to={generatePath(editPersonPath, {id: person.id})}
                highlight
              >
                {personName}
              </Link>
            ) : (
              <span>{personName}</span>
            )}{' '}
            {dobFormatted}
          </Heading>
          <Grid container direction="row">
            <div className={classes.leftColumnContainer}>{render()}</div>
            <Grid item>
              <NjmmisCheck inbound={inbound} />
              {person && (
                <EligibilityDeterminationStatus
                  labelKey="medicaid"
                  benefits={['medicaid', 'medicaid-lastMonth']}
                  mostRecentEligibilityDeterminations={
                    person.mostRecentEligibilityDeterminations
                  }
                  className={classes.medicaidEligibilityDeterminationStatus}
                />
              )}
              {!!openApplicationsFormatted && (
                <DisplayItem
                  i18nKey="inboundItem.openApplications"
                  translations={{
                    openApplications: openApplicationsFormatted,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </div>
        <Status inbound={inbound} refetchInbounds={refetchInbounds} />
      </Paper>
    )
  ),
  ({
    dateOfServiceFormatted,
    phoneNumber,
    lastIntakeWebformReceivedAtFormatted,
    inboundType,
    createdAtFormatted,
    inbound: {reason, account},
    insuranceOnDateOfServiceFormatted,
    serviceTypeFormatted,
    classes,
  }) => (
    <>
      {inboundType === InboundType.office ? (
        <>
          <DisplayItem
            i18nKey="inboundItem.createdAt"
            translations={{
              createdAt: createdAtFormatted,
            }}
          />
          <DisplayItem
            i18nKey="inboundItem.reason"
            translations={{
              reason,
            }}
          />
          <DisplayItem
            i18nKey="inboundItem.phoneNumber"
            translations={{phoneNumber}}
          />
        </>
      ) : (
        <>
          {!!serviceTypeFormatted && (
            <Body1 className={classes.serviceType}>
              {serviceTypeFormatted}
            </Body1>
          )}
          {!!dateOfServiceFormatted && (
            <DisplayItem
              i18nKey="inboundItem.dateOfService"
              translations={{
                dateOfService: dateOfServiceFormatted,
              }}
            />
          )}
          {!!account?.department && (
            <DisplayItem
              i18nKey="inboundItem.department"
              translations={{
                department: account.department,
              }}
            />
          )}
          {!!insuranceOnDateOfServiceFormatted && (
            <DisplayItem
              i18nKey="inboundItem.insuranceOnDateOfService"
              translations={{
                insuranceOnDateOfService: insuranceOnDateOfServiceFormatted,
              }}
            />
          )}
        </>
      )}
      {!!lastIntakeWebformReceivedAtFormatted && (
        <DisplayItem
          i18nKey="inboundItem.lastIntakeWebformReceivedAt"
          translations={{
            lastIntakeWebformReceivedAt: lastIntakeWebformReceivedAtFormatted,
          }}
        />
      )}
    </>
  )
)

export default InboundItem
