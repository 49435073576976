/* eslint-disable */
import {DeleteEditableFileApplicationAssociationVariables as DeleteEditableFileApplicationAssociationVariablesOrig} from '../types/DeleteEditableFileApplicationAssociation'
import {DeleteEditableFileApplicationAssociation as DeleteEditableFileApplicationAssociationOrig} from '../types/DeleteEditableFileApplicationAssociation'
import {DeleteEditableFileApplicationAssociation_deleteEditableFileApplicationAssociation as DeleteEditableFileApplicationAssociation_deleteEditableFileApplicationAssociationOrig} from '../types/DeleteEditableFileApplicationAssociation'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL mutation operation: DeleteEditableFileApplicationAssociation
// ====================================================
export interface DeleteEditableFileApplicationAssociation_deleteEditableFileApplicationAssociation {
  __typename: 'EditableFile'
  id: string
}
export const deserializeDeleteEditableFileApplicationAssociation_deleteEditableFileApplicationAssociation = (
  serialized: DeleteEditableFileApplicationAssociation_deleteEditableFileApplicationAssociationOrig
): DeleteEditableFileApplicationAssociation_deleteEditableFileApplicationAssociation => ({
  ...serialized,
})
export const serializeDeleteEditableFileApplicationAssociation_deleteEditableFileApplicationAssociation = (
  deserialized: DeleteEditableFileApplicationAssociation_deleteEditableFileApplicationAssociation
): DeleteEditableFileApplicationAssociation_deleteEditableFileApplicationAssociationOrig => ({
  ...deserialized,
})
export interface DeleteEditableFileApplicationAssociation {
  /**
   * Delete editable file application association and editable file if no more associations exist
   */
  deleteEditableFileApplicationAssociation: DeleteEditableFileApplicationAssociation_deleteEditableFileApplicationAssociation
}
export const deserializeDeleteEditableFileApplicationAssociation = (
  serialized: DeleteEditableFileApplicationAssociationOrig
): DeleteEditableFileApplicationAssociation => ({
  ...serialized,
  deleteEditableFileApplicationAssociation: deserializeDeleteEditableFileApplicationAssociation_deleteEditableFileApplicationAssociation(
    serialized.deleteEditableFileApplicationAssociation
  ),
})
export const serializeDeleteEditableFileApplicationAssociation = (
  deserialized: DeleteEditableFileApplicationAssociation
): DeleteEditableFileApplicationAssociationOrig => ({
  ...deserialized,
  deleteEditableFileApplicationAssociation: serializeDeleteEditableFileApplicationAssociation_deleteEditableFileApplicationAssociation(
    deserialized.deleteEditableFileApplicationAssociation
  ),
})
export interface DeleteEditableFileApplicationAssociationVariables {
  editableFileId: string
  applicationId: string
}
export const deserializeDeleteEditableFileApplicationAssociationVariables = (
  serialized: DeleteEditableFileApplicationAssociationVariablesOrig
): DeleteEditableFileApplicationAssociationVariables => ({...serialized})
export const serializeDeleteEditableFileApplicationAssociationVariables = (
  deserialized: DeleteEditableFileApplicationAssociationVariables
): DeleteEditableFileApplicationAssociationVariablesOrig => ({...deserialized})
