/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {Benefit, McdType, McdSubtype, SlideType} from './globalTypes' // ====================================================
// GraphQL query operation: Applications
// ====================================================

import {ApplicationsVariables as ApplicationsVariablesOrig} from '../types/Applications'
import {Applications as ApplicationsOrig} from '../types/Applications'
import {Applications_applications as Applications_applicationsOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication as Applications_applications_RyanWhiteApplicationOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_assignedTo as Applications_applications_RyanWhiteApplication_assignedToOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person as Applications_applications_RyanWhiteApplication_personOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_openHouseholdMemberApplications as Applications_applications_RyanWhiteApplication_person_openHouseholdMemberApplicationsOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_openApplications as Applications_applications_RyanWhiteApplication_person_openApplicationsOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_openApplications_RyanWhiteApplication as Applications_applications_RyanWhiteApplication_person_openApplications_RyanWhiteApplicationOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_openApplications_SlideApplication as Applications_applications_RyanWhiteApplication_person_openApplications_SlideApplicationOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_openApplications_CharityCareApplication as Applications_applications_RyanWhiteApplication_person_openApplications_CharityCareApplicationOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_openApplications_MedicaidApplication as Applications_applications_RyanWhiteApplication_person_openApplications_MedicaidApplicationOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_deductions as Applications_applications_RyanWhiteApplication_person_deductionsOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_incomeSources as Applications_applications_RyanWhiteApplication_person_incomeSourcesOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_incomeSources_esignSession as Applications_applications_RyanWhiteApplication_person_incomeSources_esignSessionOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards as Applications_applications_RyanWhiteApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_benefitOutcomes as Applications_applications_RyanWhiteApplication_person_benefitOutcomesOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application as Applications_applications_RyanWhiteApplication_person_benefitOutcomes_applicationOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication as Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication as Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication as Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_phoneNumbers as Applications_applications_RyanWhiteApplication_person_phoneNumbersOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession as Applications_applications_RyanWhiteApplication_person_phoneNumbers_esignSessionOrig} from '../types/Applications'
import {Applications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards as Applications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/Applications'
import {Applications_applications_SlideApplication as Applications_applications_SlideApplicationOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_assignedTo as Applications_applications_SlideApplication_assignedToOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person as Applications_applications_SlideApplication_personOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_openHouseholdMemberApplications as Applications_applications_SlideApplication_person_openHouseholdMemberApplicationsOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_openApplications as Applications_applications_SlideApplication_person_openApplicationsOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_openApplications_RyanWhiteApplication as Applications_applications_SlideApplication_person_openApplications_RyanWhiteApplicationOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_openApplications_SlideApplication as Applications_applications_SlideApplication_person_openApplications_SlideApplicationOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_openApplications_CharityCareApplication as Applications_applications_SlideApplication_person_openApplications_CharityCareApplicationOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_openApplications_MedicaidApplication as Applications_applications_SlideApplication_person_openApplications_MedicaidApplicationOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_deductions as Applications_applications_SlideApplication_person_deductionsOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_incomeSources as Applications_applications_SlideApplication_person_incomeSourcesOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_incomeSources_esignSession as Applications_applications_SlideApplication_person_incomeSources_esignSessionOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_incomeSources_esignSession_esignWizards as Applications_applications_SlideApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_benefitOutcomes as Applications_applications_SlideApplication_person_benefitOutcomesOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_benefitOutcomes_application as Applications_applications_SlideApplication_person_benefitOutcomes_applicationOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_benefitOutcomes_application_SlideApplication as Applications_applications_SlideApplication_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_benefitOutcomes_application_CharityCareApplication as Applications_applications_SlideApplication_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_benefitOutcomes_application_MedicaidApplication as Applications_applications_SlideApplication_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_phoneNumbers as Applications_applications_SlideApplication_person_phoneNumbersOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_phoneNumbers_esignSession as Applications_applications_SlideApplication_person_phoneNumbers_esignSessionOrig} from '../types/Applications'
import {Applications_applications_SlideApplication_person_phoneNumbers_esignSession_esignWizards as Applications_applications_SlideApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication as Applications_applications_CharityCareApplicationOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_assignedTo as Applications_applications_CharityCareApplication_assignedToOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person as Applications_applications_CharityCareApplication_personOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_openHouseholdMemberApplications as Applications_applications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_openApplications as Applications_applications_CharityCareApplication_person_openApplicationsOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_openApplications_RyanWhiteApplication as Applications_applications_CharityCareApplication_person_openApplications_RyanWhiteApplicationOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_openApplications_SlideApplication as Applications_applications_CharityCareApplication_person_openApplications_SlideApplicationOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_openApplications_CharityCareApplication as Applications_applications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_openApplications_MedicaidApplication as Applications_applications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_deductions as Applications_applications_CharityCareApplication_person_deductionsOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_incomeSources as Applications_applications_CharityCareApplication_person_incomeSourcesOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_incomeSources_esignSession as Applications_applications_CharityCareApplication_person_incomeSources_esignSessionOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_incomeSources_esignSession_esignWizards as Applications_applications_CharityCareApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_benefitOutcomes as Applications_applications_CharityCareApplication_person_benefitOutcomesOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_benefitOutcomes_application as Applications_applications_CharityCareApplication_person_benefitOutcomes_applicationOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_benefitOutcomes_application_SlideApplication as Applications_applications_CharityCareApplication_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication as Applications_applications_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication as Applications_applications_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_phoneNumbers as Applications_applications_CharityCareApplication_person_phoneNumbersOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_phoneNumbers_esignSession as Applications_applications_CharityCareApplication_person_phoneNumbers_esignSessionOrig} from '../types/Applications'
import {Applications_applications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards as Applications_applications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication as Applications_applications_MonitorApplicationOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_assignedTo as Applications_applications_MonitorApplication_assignedToOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person as Applications_applications_MonitorApplication_personOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_openHouseholdMemberApplications as Applications_applications_MonitorApplication_person_openHouseholdMemberApplicationsOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_openApplications as Applications_applications_MonitorApplication_person_openApplicationsOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_openApplications_RyanWhiteApplication as Applications_applications_MonitorApplication_person_openApplications_RyanWhiteApplicationOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_openApplications_SlideApplication as Applications_applications_MonitorApplication_person_openApplications_SlideApplicationOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_openApplications_CharityCareApplication as Applications_applications_MonitorApplication_person_openApplications_CharityCareApplicationOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_openApplications_MedicaidApplication as Applications_applications_MonitorApplication_person_openApplications_MedicaidApplicationOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_deductions as Applications_applications_MonitorApplication_person_deductionsOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_incomeSources as Applications_applications_MonitorApplication_person_incomeSourcesOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_incomeSources_esignSession as Applications_applications_MonitorApplication_person_incomeSources_esignSessionOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_incomeSources_esignSession_esignWizards as Applications_applications_MonitorApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_benefitOutcomes as Applications_applications_MonitorApplication_person_benefitOutcomesOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_benefitOutcomes_application as Applications_applications_MonitorApplication_person_benefitOutcomes_applicationOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_benefitOutcomes_application_SlideApplication as Applications_applications_MonitorApplication_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication as Applications_applications_MonitorApplication_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication as Applications_applications_MonitorApplication_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_phoneNumbers as Applications_applications_MonitorApplication_person_phoneNumbersOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_phoneNumbers_esignSession as Applications_applications_MonitorApplication_person_phoneNumbers_esignSessionOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards as Applications_applications_MonitorApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/Applications'
import {Applications_applications_MonitorApplication_primaryPointOfContact as Applications_applications_MonitorApplication_primaryPointOfContactOrig} from '../types/Applications'
import {deserializeMcdSubtype} from 'graphql/converters'
import {serializeMcdSubtype} from 'graphql/converters'
import {deserializeMcdType} from 'graphql/converters'
import {serializeMcdType} from 'graphql/converters'
import {Applications_applications_MedicaidApplication as Applications_applications_MedicaidApplicationOrig} from '../types/Applications'
import {Applications_applications_MedicaidApplication_assignedTo as Applications_applications_MedicaidApplication_assignedToOrig} from '../types/Applications'
import {Applications_applications_MedicaidApplication_person as Applications_applications_MedicaidApplication_personOrig} from '../types/Applications'
import {Applications_applications_MedicaidApplication_person_openHouseholdMemberApplications as Applications_applications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig} from '../types/Applications'
import {Applications_applications_MedicaidApplication_person_openApplications as Applications_applications_MedicaidApplication_person_openApplicationsOrig} from '../types/Applications'
import {Applications_applications_MedicaidApplication_person_openApplications_RyanWhiteApplication as Applications_applications_MedicaidApplication_person_openApplications_RyanWhiteApplicationOrig} from '../types/Applications'
import {Applications_applications_MedicaidApplication_person_openApplications_SlideApplication as Applications_applications_MedicaidApplication_person_openApplications_SlideApplicationOrig} from '../types/Applications'
import {Applications_applications_MedicaidApplication_person_openApplications_CharityCareApplication as Applications_applications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig} from '../types/Applications'
import {BenefitType} from 'utils/benefits'
import {deserializeBenefit} from 'graphql/converters'
import {serializeBenefit} from 'graphql/converters'
import {Applications_applications_MedicaidApplication_person_openApplications_MedicaidApplication as Applications_applications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig} from '../types/Applications'
import {Applications_applications_MedicaidApplication_person_deductions as Applications_applications_MedicaidApplication_person_deductionsOrig} from '../types/Applications'
import {Applications_applications_MedicaidApplication_person_incomeSources as Applications_applications_MedicaidApplication_person_incomeSourcesOrig} from '../types/Applications'
import {Applications_applications_MedicaidApplication_person_incomeSources_esignSession as Applications_applications_MedicaidApplication_person_incomeSources_esignSessionOrig} from '../types/Applications'
import {Applications_applications_MedicaidApplication_person_incomeSources_esignSession_esignWizards as Applications_applications_MedicaidApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/Applications'
import {formatISODate} from 'utils/date'
import {Applications_applications_MedicaidApplication_person_benefitOutcomes as Applications_applications_MedicaidApplication_person_benefitOutcomesOrig} from '../types/Applications'
import {Applications_applications_MedicaidApplication_person_benefitOutcomes_application as Applications_applications_MedicaidApplication_person_benefitOutcomes_applicationOrig} from '../types/Applications'
import {deserializeSlideType} from 'graphql/converters'
import {serializeSlideType} from 'graphql/converters'
import {Applications_applications_MedicaidApplication_person_benefitOutcomes_application_SlideApplication as Applications_applications_MedicaidApplication_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/Applications'
import {Applications_applications_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication as Applications_applications_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/Applications'
import {Applications_applications_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication as Applications_applications_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/Applications'
import {Applications_applications_MedicaidApplication_person_phoneNumbers as Applications_applications_MedicaidApplication_person_phoneNumbersOrig} from '../types/Applications'
import {Applications_applications_MedicaidApplication_person_phoneNumbers_esignSession as Applications_applications_MedicaidApplication_person_phoneNumbers_esignSessionOrig} from '../types/Applications'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {Applications_applications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards as Applications_applications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/Applications'
export interface Applications_applications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplications_applications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: Applications_applications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): Applications_applications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplications_applications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: Applications_applications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards
): Applications_applications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface Applications_applications_MedicaidApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: Applications_applications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplications_applications_MedicaidApplication_person_phoneNumbers_esignSession = (
  serialized: Applications_applications_MedicaidApplication_person_phoneNumbers_esignSessionOrig
): Applications_applications_MedicaidApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplications_applications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplications_applications_MedicaidApplication_person_phoneNumbers_esignSession = (
  deserialized: Applications_applications_MedicaidApplication_person_phoneNumbers_esignSession
): Applications_applications_MedicaidApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplications_applications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface Applications_applications_MedicaidApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: Applications_applications_MedicaidApplication_person_phoneNumbers_esignSession | null
}
export const deserializeApplications_applications_MedicaidApplication_person_phoneNumbers = (
  serialized: Applications_applications_MedicaidApplication_person_phoneNumbersOrig
): Applications_applications_MedicaidApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplications_applications_MedicaidApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplications_applications_MedicaidApplication_person_phoneNumbers = (
  deserialized: Applications_applications_MedicaidApplication_person_phoneNumbers
): Applications_applications_MedicaidApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplications_applications_MedicaidApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface Applications_applications_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplications_applications_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: Applications_applications_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplicationOrig
): Applications_applications_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplications_applications_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: Applications_applications_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication
): Applications_applications_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface Applications_applications_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplications_applications_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: Applications_applications_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplicationOrig
): Applications_applications_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplications_applications_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: Applications_applications_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication
): Applications_applications_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface Applications_applications_MedicaidApplication_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplications_applications_MedicaidApplication_person_benefitOutcomes_application_SlideApplication = (
  serialized: Applications_applications_MedicaidApplication_person_benefitOutcomes_application_SlideApplicationOrig
): Applications_applications_MedicaidApplication_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplications_applications_MedicaidApplication_person_benefitOutcomes_application_SlideApplication = (
  deserialized: Applications_applications_MedicaidApplication_person_benefitOutcomes_application_SlideApplication
): Applications_applications_MedicaidApplication_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type Applications_applications_MedicaidApplication_person_benefitOutcomes_application =
  | Applications_applications_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication
  | Applications_applications_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication
  | Applications_applications_MedicaidApplication_person_benefitOutcomes_application_SlideApplication
export const deserializeApplications_applications_MedicaidApplication_person_benefitOutcomes_application = (
  serialized: Applications_applications_MedicaidApplication_person_benefitOutcomes_applicationOrig
): Applications_applications_MedicaidApplication_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplications_applications_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplications_applications_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplications_applications_MedicaidApplication_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_MedicaidApplication_person_benefitOutcomes_application'
  )
}
export const serializeApplications_applications_MedicaidApplication_person_benefitOutcomes_application = (
  deserialized: Applications_applications_MedicaidApplication_person_benefitOutcomes_application
): Applications_applications_MedicaidApplication_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplications_applications_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplications_applications_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplications_applications_MedicaidApplication_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_MedicaidApplication_person_benefitOutcomes_application'
  )
}
export interface Applications_applications_MedicaidApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: Applications_applications_MedicaidApplication_person_benefitOutcomes_application | null
}
export const deserializeApplications_applications_MedicaidApplication_person_benefitOutcomes = (
  serialized: Applications_applications_MedicaidApplication_person_benefitOutcomesOrig
): Applications_applications_MedicaidApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplications_applications_MedicaidApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplications_applications_MedicaidApplication_person_benefitOutcomes = (
  deserialized: Applications_applications_MedicaidApplication_person_benefitOutcomes
): Applications_applications_MedicaidApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplications_applications_MedicaidApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface Applications_applications_MedicaidApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplications_applications_MedicaidApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: Applications_applications_MedicaidApplication_person_incomeSources_esignSession_esignWizardsOrig
): Applications_applications_MedicaidApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplications_applications_MedicaidApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: Applications_applications_MedicaidApplication_person_incomeSources_esignSession_esignWizards
): Applications_applications_MedicaidApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface Applications_applications_MedicaidApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: Applications_applications_MedicaidApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplications_applications_MedicaidApplication_person_incomeSources_esignSession = (
  serialized: Applications_applications_MedicaidApplication_person_incomeSources_esignSessionOrig
): Applications_applications_MedicaidApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplications_applications_MedicaidApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplications_applications_MedicaidApplication_person_incomeSources_esignSession = (
  deserialized: Applications_applications_MedicaidApplication_person_incomeSources_esignSession
): Applications_applications_MedicaidApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplications_applications_MedicaidApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface Applications_applications_MedicaidApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: Applications_applications_MedicaidApplication_person_incomeSources_esignSession | null
}
export const deserializeApplications_applications_MedicaidApplication_person_incomeSources = (
  serialized: Applications_applications_MedicaidApplication_person_incomeSourcesOrig
): Applications_applications_MedicaidApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplications_applications_MedicaidApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplications_applications_MedicaidApplication_person_incomeSources = (
  deserialized: Applications_applications_MedicaidApplication_person_incomeSources
): Applications_applications_MedicaidApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplications_applications_MedicaidApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface Applications_applications_MedicaidApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplications_applications_MedicaidApplication_person_deductions = (
  serialized: Applications_applications_MedicaidApplication_person_deductionsOrig
): Applications_applications_MedicaidApplication_person_deductions => ({
  ...serialized,
})
export const serializeApplications_applications_MedicaidApplication_person_deductions = (
  deserialized: Applications_applications_MedicaidApplication_person_deductions
): Applications_applications_MedicaidApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface Applications_applications_MedicaidApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplications_applications_MedicaidApplication_person_openApplications_MedicaidApplication = (
  serialized: Applications_applications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig
): Applications_applications_MedicaidApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_MedicaidApplication_person_openApplications_MedicaidApplication = (
  deserialized: Applications_applications_MedicaidApplication_person_openApplications_MedicaidApplication
): Applications_applications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_MedicaidApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplications_applications_MedicaidApplication_person_openApplications_CharityCareApplication = (
  serialized: Applications_applications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig
): Applications_applications_MedicaidApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_MedicaidApplication_person_openApplications_CharityCareApplication = (
  deserialized: Applications_applications_MedicaidApplication_person_openApplications_CharityCareApplication
): Applications_applications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_MedicaidApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplications_applications_MedicaidApplication_person_openApplications_SlideApplication = (
  serialized: Applications_applications_MedicaidApplication_person_openApplications_SlideApplicationOrig
): Applications_applications_MedicaidApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_MedicaidApplication_person_openApplications_SlideApplication = (
  deserialized: Applications_applications_MedicaidApplication_person_openApplications_SlideApplication
): Applications_applications_MedicaidApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_MedicaidApplication_person_openApplications_RyanWhiteApplication {
  __typename: 'RyanWhiteApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplications_applications_MedicaidApplication_person_openApplications_RyanWhiteApplication = (
  serialized: Applications_applications_MedicaidApplication_person_openApplications_RyanWhiteApplicationOrig
): Applications_applications_MedicaidApplication_person_openApplications_RyanWhiteApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_MedicaidApplication_person_openApplications_RyanWhiteApplication = (
  deserialized: Applications_applications_MedicaidApplication_person_openApplications_RyanWhiteApplication
): Applications_applications_MedicaidApplication_person_openApplications_RyanWhiteApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type Applications_applications_MedicaidApplication_person_openApplications =
  | Applications_applications_MedicaidApplication_person_openApplications_MedicaidApplication
  | Applications_applications_MedicaidApplication_person_openApplications_CharityCareApplication
  | Applications_applications_MedicaidApplication_person_openApplications_SlideApplication
  | Applications_applications_MedicaidApplication_person_openApplications_RyanWhiteApplication
export const deserializeApplications_applications_MedicaidApplication_person_openApplications = (
  serialized: Applications_applications_MedicaidApplication_person_openApplicationsOrig
): Applications_applications_MedicaidApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeApplications_applications_MedicaidApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplications_applications_MedicaidApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplications_applications_MedicaidApplication_person_openApplications_SlideApplication(
      serialized
    )
  if (false || serialized.__typename === 'RyanWhiteApplication')
    return deserializeApplications_applications_MedicaidApplication_person_openApplications_RyanWhiteApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_MedicaidApplication_person_openApplications'
  )
}
export const serializeApplications_applications_MedicaidApplication_person_openApplications = (
  deserialized: Applications_applications_MedicaidApplication_person_openApplications
): Applications_applications_MedicaidApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeApplications_applications_MedicaidApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplications_applications_MedicaidApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplications_applications_MedicaidApplication_person_openApplications_SlideApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'RyanWhiteApplication')
    return serializeApplications_applications_MedicaidApplication_person_openApplications_RyanWhiteApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_MedicaidApplication_person_openApplications'
  )
}
export interface Applications_applications_MedicaidApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplications_applications_MedicaidApplication_person_openHouseholdMemberApplications = (
  serialized: Applications_applications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig
): Applications_applications_MedicaidApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_MedicaidApplication_person_openHouseholdMemberApplications = (
  deserialized: Applications_applications_MedicaidApplication_person_openHouseholdMemberApplications
): Applications_applications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_MedicaidApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: Applications_applications_MedicaidApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: Applications_applications_MedicaidApplication_person_benefitOutcomes[]
  incomeSources: Applications_applications_MedicaidApplication_person_incomeSources[]
  deductions: Applications_applications_MedicaidApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  openApplications: Applications_applications_MedicaidApplication_person_openApplications[]
  openHouseholdMemberApplications: Applications_applications_MedicaidApplication_person_openHouseholdMemberApplications[]
}
export const deserializeApplications_applications_MedicaidApplication_person = (
  serialized: Applications_applications_MedicaidApplication_personOrig
): Applications_applications_MedicaidApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplications_applications_MedicaidApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplications_applications_MedicaidApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplications_applications_MedicaidApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplications_applications_MedicaidApplication_person_deductions
  ),
  openApplications: serialized.openApplications.map(
    deserializeApplications_applications_MedicaidApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeApplications_applications_MedicaidApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeApplications_applications_MedicaidApplication_person = (
  deserialized: Applications_applications_MedicaidApplication_person
): Applications_applications_MedicaidApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplications_applications_MedicaidApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplications_applications_MedicaidApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplications_applications_MedicaidApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplications_applications_MedicaidApplication_person_deductions
  ),
  openApplications: deserialized.openApplications.map(
    serializeApplications_applications_MedicaidApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeApplications_applications_MedicaidApplication_person_openHouseholdMemberApplications
  ),
})
export interface Applications_applications_MedicaidApplication_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeApplications_applications_MedicaidApplication_assignedTo = (
  serialized: Applications_applications_MedicaidApplication_assignedToOrig
): Applications_applications_MedicaidApplication_assignedTo => ({...serialized})
export const serializeApplications_applications_MedicaidApplication_assignedTo = (
  deserialized: Applications_applications_MedicaidApplication_assignedTo
): Applications_applications_MedicaidApplication_assignedToOrig => ({
  ...deserialized,
})
export interface Applications_applications_MedicaidApplication {
  __typename: 'MedicaidApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  readyForAudit: boolean
  submitDate: Date | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  initialDateOfService: Date | null
  mcdType: string
  mcdSubtype: string | null
  person: Applications_applications_MedicaidApplication_person
  assignedTo: Applications_applications_MedicaidApplication_assignedTo | null
}
export const deserializeApplications_applications_MedicaidApplication = (
  serialized: Applications_applications_MedicaidApplicationOrig
): Applications_applications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  mcdType: deserializeMcdType(serialized.mcdType),
  mcdSubtype:
    serialized.mcdSubtype != null
      ? deserializeMcdSubtype(serialized.mcdSubtype)
      : serialized.mcdSubtype,
  person: deserializeApplications_applications_MedicaidApplication_person(
    serialized.person
  ),
  assignedTo:
    serialized.assignedTo != null
      ? deserializeApplications_applications_MedicaidApplication_assignedTo(
          serialized.assignedTo
        )
      : serialized.assignedTo,
})
export const serializeApplications_applications_MedicaidApplication = (
  deserialized: Applications_applications_MedicaidApplication
): Applications_applications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  mcdType: serializeMcdType(deserialized.mcdType),
  mcdSubtype:
    deserialized.mcdSubtype != null
      ? serializeMcdSubtype(deserialized.mcdSubtype)
      : deserialized.mcdSubtype,
  person: serializeApplications_applications_MedicaidApplication_person(
    deserialized.person
  ),
  assignedTo:
    deserialized.assignedTo != null
      ? serializeApplications_applications_MedicaidApplication_assignedTo(
          deserialized.assignedTo
        )
      : deserialized.assignedTo,
})
export interface Applications_applications_MonitorApplication_primaryPointOfContact {
  __typename: 'Person'
  id: string
}
export const deserializeApplications_applications_MonitorApplication_primaryPointOfContact = (
  serialized: Applications_applications_MonitorApplication_primaryPointOfContactOrig
): Applications_applications_MonitorApplication_primaryPointOfContact => ({
  ...serialized,
})
export const serializeApplications_applications_MonitorApplication_primaryPointOfContact = (
  deserialized: Applications_applications_MonitorApplication_primaryPointOfContact
): Applications_applications_MonitorApplication_primaryPointOfContactOrig => ({
  ...deserialized,
})
export interface Applications_applications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplications_applications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: Applications_applications_MonitorApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): Applications_applications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplications_applications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: Applications_applications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards
): Applications_applications_MonitorApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface Applications_applications_MonitorApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: Applications_applications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplications_applications_MonitorApplication_person_phoneNumbers_esignSession = (
  serialized: Applications_applications_MonitorApplication_person_phoneNumbers_esignSessionOrig
): Applications_applications_MonitorApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplications_applications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplications_applications_MonitorApplication_person_phoneNumbers_esignSession = (
  deserialized: Applications_applications_MonitorApplication_person_phoneNumbers_esignSession
): Applications_applications_MonitorApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplications_applications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface Applications_applications_MonitorApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: Applications_applications_MonitorApplication_person_phoneNumbers_esignSession | null
}
export const deserializeApplications_applications_MonitorApplication_person_phoneNumbers = (
  serialized: Applications_applications_MonitorApplication_person_phoneNumbersOrig
): Applications_applications_MonitorApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplications_applications_MonitorApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplications_applications_MonitorApplication_person_phoneNumbers = (
  deserialized: Applications_applications_MonitorApplication_person_phoneNumbers
): Applications_applications_MonitorApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplications_applications_MonitorApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface Applications_applications_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplications_applications_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: Applications_applications_MonitorApplication_person_benefitOutcomes_application_MedicaidApplicationOrig
): Applications_applications_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplications_applications_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: Applications_applications_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication
): Applications_applications_MonitorApplication_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface Applications_applications_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplications_applications_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: Applications_applications_MonitorApplication_person_benefitOutcomes_application_CharityCareApplicationOrig
): Applications_applications_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplications_applications_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: Applications_applications_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication
): Applications_applications_MonitorApplication_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface Applications_applications_MonitorApplication_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplications_applications_MonitorApplication_person_benefitOutcomes_application_SlideApplication = (
  serialized: Applications_applications_MonitorApplication_person_benefitOutcomes_application_SlideApplicationOrig
): Applications_applications_MonitorApplication_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplications_applications_MonitorApplication_person_benefitOutcomes_application_SlideApplication = (
  deserialized: Applications_applications_MonitorApplication_person_benefitOutcomes_application_SlideApplication
): Applications_applications_MonitorApplication_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type Applications_applications_MonitorApplication_person_benefitOutcomes_application =
  | Applications_applications_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication
  | Applications_applications_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication
  | Applications_applications_MonitorApplication_person_benefitOutcomes_application_SlideApplication
export const deserializeApplications_applications_MonitorApplication_person_benefitOutcomes_application = (
  serialized: Applications_applications_MonitorApplication_person_benefitOutcomes_applicationOrig
): Applications_applications_MonitorApplication_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplications_applications_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplications_applications_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplications_applications_MonitorApplication_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_MonitorApplication_person_benefitOutcomes_application'
  )
}
export const serializeApplications_applications_MonitorApplication_person_benefitOutcomes_application = (
  deserialized: Applications_applications_MonitorApplication_person_benefitOutcomes_application
): Applications_applications_MonitorApplication_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplications_applications_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplications_applications_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplications_applications_MonitorApplication_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_MonitorApplication_person_benefitOutcomes_application'
  )
}
export interface Applications_applications_MonitorApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: Applications_applications_MonitorApplication_person_benefitOutcomes_application | null
}
export const deserializeApplications_applications_MonitorApplication_person_benefitOutcomes = (
  serialized: Applications_applications_MonitorApplication_person_benefitOutcomesOrig
): Applications_applications_MonitorApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplications_applications_MonitorApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplications_applications_MonitorApplication_person_benefitOutcomes = (
  deserialized: Applications_applications_MonitorApplication_person_benefitOutcomes
): Applications_applications_MonitorApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplications_applications_MonitorApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface Applications_applications_MonitorApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplications_applications_MonitorApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: Applications_applications_MonitorApplication_person_incomeSources_esignSession_esignWizardsOrig
): Applications_applications_MonitorApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplications_applications_MonitorApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: Applications_applications_MonitorApplication_person_incomeSources_esignSession_esignWizards
): Applications_applications_MonitorApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface Applications_applications_MonitorApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: Applications_applications_MonitorApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplications_applications_MonitorApplication_person_incomeSources_esignSession = (
  serialized: Applications_applications_MonitorApplication_person_incomeSources_esignSessionOrig
): Applications_applications_MonitorApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplications_applications_MonitorApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplications_applications_MonitorApplication_person_incomeSources_esignSession = (
  deserialized: Applications_applications_MonitorApplication_person_incomeSources_esignSession
): Applications_applications_MonitorApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplications_applications_MonitorApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface Applications_applications_MonitorApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: Applications_applications_MonitorApplication_person_incomeSources_esignSession | null
}
export const deserializeApplications_applications_MonitorApplication_person_incomeSources = (
  serialized: Applications_applications_MonitorApplication_person_incomeSourcesOrig
): Applications_applications_MonitorApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplications_applications_MonitorApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplications_applications_MonitorApplication_person_incomeSources = (
  deserialized: Applications_applications_MonitorApplication_person_incomeSources
): Applications_applications_MonitorApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplications_applications_MonitorApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface Applications_applications_MonitorApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplications_applications_MonitorApplication_person_deductions = (
  serialized: Applications_applications_MonitorApplication_person_deductionsOrig
): Applications_applications_MonitorApplication_person_deductions => ({
  ...serialized,
})
export const serializeApplications_applications_MonitorApplication_person_deductions = (
  deserialized: Applications_applications_MonitorApplication_person_deductions
): Applications_applications_MonitorApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface Applications_applications_MonitorApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplications_applications_MonitorApplication_person_openApplications_MedicaidApplication = (
  serialized: Applications_applications_MonitorApplication_person_openApplications_MedicaidApplicationOrig
): Applications_applications_MonitorApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_MonitorApplication_person_openApplications_MedicaidApplication = (
  deserialized: Applications_applications_MonitorApplication_person_openApplications_MedicaidApplication
): Applications_applications_MonitorApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_MonitorApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplications_applications_MonitorApplication_person_openApplications_CharityCareApplication = (
  serialized: Applications_applications_MonitorApplication_person_openApplications_CharityCareApplicationOrig
): Applications_applications_MonitorApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_MonitorApplication_person_openApplications_CharityCareApplication = (
  deserialized: Applications_applications_MonitorApplication_person_openApplications_CharityCareApplication
): Applications_applications_MonitorApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_MonitorApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplications_applications_MonitorApplication_person_openApplications_SlideApplication = (
  serialized: Applications_applications_MonitorApplication_person_openApplications_SlideApplicationOrig
): Applications_applications_MonitorApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_MonitorApplication_person_openApplications_SlideApplication = (
  deserialized: Applications_applications_MonitorApplication_person_openApplications_SlideApplication
): Applications_applications_MonitorApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_MonitorApplication_person_openApplications_RyanWhiteApplication {
  __typename: 'RyanWhiteApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplications_applications_MonitorApplication_person_openApplications_RyanWhiteApplication = (
  serialized: Applications_applications_MonitorApplication_person_openApplications_RyanWhiteApplicationOrig
): Applications_applications_MonitorApplication_person_openApplications_RyanWhiteApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_MonitorApplication_person_openApplications_RyanWhiteApplication = (
  deserialized: Applications_applications_MonitorApplication_person_openApplications_RyanWhiteApplication
): Applications_applications_MonitorApplication_person_openApplications_RyanWhiteApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type Applications_applications_MonitorApplication_person_openApplications =
  | Applications_applications_MonitorApplication_person_openApplications_MedicaidApplication
  | Applications_applications_MonitorApplication_person_openApplications_CharityCareApplication
  | Applications_applications_MonitorApplication_person_openApplications_SlideApplication
  | Applications_applications_MonitorApplication_person_openApplications_RyanWhiteApplication
export const deserializeApplications_applications_MonitorApplication_person_openApplications = (
  serialized: Applications_applications_MonitorApplication_person_openApplicationsOrig
): Applications_applications_MonitorApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeApplications_applications_MonitorApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplications_applications_MonitorApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplications_applications_MonitorApplication_person_openApplications_SlideApplication(
      serialized
    )
  if (false || serialized.__typename === 'RyanWhiteApplication')
    return deserializeApplications_applications_MonitorApplication_person_openApplications_RyanWhiteApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_MonitorApplication_person_openApplications'
  )
}
export const serializeApplications_applications_MonitorApplication_person_openApplications = (
  deserialized: Applications_applications_MonitorApplication_person_openApplications
): Applications_applications_MonitorApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeApplications_applications_MonitorApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplications_applications_MonitorApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplications_applications_MonitorApplication_person_openApplications_SlideApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'RyanWhiteApplication')
    return serializeApplications_applications_MonitorApplication_person_openApplications_RyanWhiteApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_MonitorApplication_person_openApplications'
  )
}
export interface Applications_applications_MonitorApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplications_applications_MonitorApplication_person_openHouseholdMemberApplications = (
  serialized: Applications_applications_MonitorApplication_person_openHouseholdMemberApplicationsOrig
): Applications_applications_MonitorApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_MonitorApplication_person_openHouseholdMemberApplications = (
  deserialized: Applications_applications_MonitorApplication_person_openHouseholdMemberApplications
): Applications_applications_MonitorApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_MonitorApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: Applications_applications_MonitorApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: Applications_applications_MonitorApplication_person_benefitOutcomes[]
  incomeSources: Applications_applications_MonitorApplication_person_incomeSources[]
  deductions: Applications_applications_MonitorApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  openApplications: Applications_applications_MonitorApplication_person_openApplications[]
  openHouseholdMemberApplications: Applications_applications_MonitorApplication_person_openHouseholdMemberApplications[]
}
export const deserializeApplications_applications_MonitorApplication_person = (
  serialized: Applications_applications_MonitorApplication_personOrig
): Applications_applications_MonitorApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplications_applications_MonitorApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplications_applications_MonitorApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplications_applications_MonitorApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplications_applications_MonitorApplication_person_deductions
  ),
  openApplications: serialized.openApplications.map(
    deserializeApplications_applications_MonitorApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeApplications_applications_MonitorApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeApplications_applications_MonitorApplication_person = (
  deserialized: Applications_applications_MonitorApplication_person
): Applications_applications_MonitorApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplications_applications_MonitorApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplications_applications_MonitorApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplications_applications_MonitorApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplications_applications_MonitorApplication_person_deductions
  ),
  openApplications: deserialized.openApplications.map(
    serializeApplications_applications_MonitorApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeApplications_applications_MonitorApplication_person_openHouseholdMemberApplications
  ),
})
export interface Applications_applications_MonitorApplication_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeApplications_applications_MonitorApplication_assignedTo = (
  serialized: Applications_applications_MonitorApplication_assignedToOrig
): Applications_applications_MonitorApplication_assignedTo => ({...serialized})
export const serializeApplications_applications_MonitorApplication_assignedTo = (
  deserialized: Applications_applications_MonitorApplication_assignedTo
): Applications_applications_MonitorApplication_assignedToOrig => ({
  ...deserialized,
})
export interface Applications_applications_MonitorApplication {
  __typename: 'MonitorApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  readyForAudit: boolean
  submitDate: Date | null
  applicationType: string | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  darSubmitDate: Date | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  primaryPointOfContact: Applications_applications_MonitorApplication_primaryPointOfContact | null
  initialDateOfService: Date | null
  person: Applications_applications_MonitorApplication_person
  assignedTo: Applications_applications_MonitorApplication_assignedTo | null
}
export const deserializeApplications_applications_MonitorApplication = (
  serialized: Applications_applications_MonitorApplicationOrig
): Applications_applications_MonitorApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  darSubmitDate:
    serialized.darSubmitDate != null
      ? parseDate(serialized.darSubmitDate)
      : serialized.darSubmitDate,
  primaryPointOfContact:
    serialized.primaryPointOfContact != null
      ? deserializeApplications_applications_MonitorApplication_primaryPointOfContact(
          serialized.primaryPointOfContact
        )
      : serialized.primaryPointOfContact,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  person: deserializeApplications_applications_MonitorApplication_person(
    serialized.person
  ),
  assignedTo:
    serialized.assignedTo != null
      ? deserializeApplications_applications_MonitorApplication_assignedTo(
          serialized.assignedTo
        )
      : serialized.assignedTo,
})
export const serializeApplications_applications_MonitorApplication = (
  deserialized: Applications_applications_MonitorApplication
): Applications_applications_MonitorApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  darSubmitDate:
    deserialized.darSubmitDate != null
      ? formatISODate(deserialized.darSubmitDate)
      : deserialized.darSubmitDate,
  primaryPointOfContact:
    deserialized.primaryPointOfContact != null
      ? serializeApplications_applications_MonitorApplication_primaryPointOfContact(
          deserialized.primaryPointOfContact
        )
      : deserialized.primaryPointOfContact,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  person: serializeApplications_applications_MonitorApplication_person(
    deserialized.person
  ),
  assignedTo:
    deserialized.assignedTo != null
      ? serializeApplications_applications_MonitorApplication_assignedTo(
          deserialized.assignedTo
        )
      : deserialized.assignedTo,
})
export interface Applications_applications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplications_applications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: Applications_applications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): Applications_applications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplications_applications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: Applications_applications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards
): Applications_applications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface Applications_applications_CharityCareApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: Applications_applications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplications_applications_CharityCareApplication_person_phoneNumbers_esignSession = (
  serialized: Applications_applications_CharityCareApplication_person_phoneNumbers_esignSessionOrig
): Applications_applications_CharityCareApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplications_applications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplications_applications_CharityCareApplication_person_phoneNumbers_esignSession = (
  deserialized: Applications_applications_CharityCareApplication_person_phoneNumbers_esignSession
): Applications_applications_CharityCareApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplications_applications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface Applications_applications_CharityCareApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: Applications_applications_CharityCareApplication_person_phoneNumbers_esignSession | null
}
export const deserializeApplications_applications_CharityCareApplication_person_phoneNumbers = (
  serialized: Applications_applications_CharityCareApplication_person_phoneNumbersOrig
): Applications_applications_CharityCareApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplications_applications_CharityCareApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplications_applications_CharityCareApplication_person_phoneNumbers = (
  deserialized: Applications_applications_CharityCareApplication_person_phoneNumbers
): Applications_applications_CharityCareApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplications_applications_CharityCareApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface Applications_applications_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplications_applications_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: Applications_applications_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplicationOrig
): Applications_applications_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplications_applications_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: Applications_applications_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication
): Applications_applications_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface Applications_applications_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplications_applications_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: Applications_applications_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplicationOrig
): Applications_applications_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplications_applications_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: Applications_applications_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication
): Applications_applications_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface Applications_applications_CharityCareApplication_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplications_applications_CharityCareApplication_person_benefitOutcomes_application_SlideApplication = (
  serialized: Applications_applications_CharityCareApplication_person_benefitOutcomes_application_SlideApplicationOrig
): Applications_applications_CharityCareApplication_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplications_applications_CharityCareApplication_person_benefitOutcomes_application_SlideApplication = (
  deserialized: Applications_applications_CharityCareApplication_person_benefitOutcomes_application_SlideApplication
): Applications_applications_CharityCareApplication_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type Applications_applications_CharityCareApplication_person_benefitOutcomes_application =
  | Applications_applications_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication
  | Applications_applications_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication
  | Applications_applications_CharityCareApplication_person_benefitOutcomes_application_SlideApplication
export const deserializeApplications_applications_CharityCareApplication_person_benefitOutcomes_application = (
  serialized: Applications_applications_CharityCareApplication_person_benefitOutcomes_applicationOrig
): Applications_applications_CharityCareApplication_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplications_applications_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplications_applications_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplications_applications_CharityCareApplication_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_CharityCareApplication_person_benefitOutcomes_application'
  )
}
export const serializeApplications_applications_CharityCareApplication_person_benefitOutcomes_application = (
  deserialized: Applications_applications_CharityCareApplication_person_benefitOutcomes_application
): Applications_applications_CharityCareApplication_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplications_applications_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplications_applications_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplications_applications_CharityCareApplication_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_CharityCareApplication_person_benefitOutcomes_application'
  )
}
export interface Applications_applications_CharityCareApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: Applications_applications_CharityCareApplication_person_benefitOutcomes_application | null
}
export const deserializeApplications_applications_CharityCareApplication_person_benefitOutcomes = (
  serialized: Applications_applications_CharityCareApplication_person_benefitOutcomesOrig
): Applications_applications_CharityCareApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplications_applications_CharityCareApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplications_applications_CharityCareApplication_person_benefitOutcomes = (
  deserialized: Applications_applications_CharityCareApplication_person_benefitOutcomes
): Applications_applications_CharityCareApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplications_applications_CharityCareApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface Applications_applications_CharityCareApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplications_applications_CharityCareApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: Applications_applications_CharityCareApplication_person_incomeSources_esignSession_esignWizardsOrig
): Applications_applications_CharityCareApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplications_applications_CharityCareApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: Applications_applications_CharityCareApplication_person_incomeSources_esignSession_esignWizards
): Applications_applications_CharityCareApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface Applications_applications_CharityCareApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: Applications_applications_CharityCareApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplications_applications_CharityCareApplication_person_incomeSources_esignSession = (
  serialized: Applications_applications_CharityCareApplication_person_incomeSources_esignSessionOrig
): Applications_applications_CharityCareApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplications_applications_CharityCareApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplications_applications_CharityCareApplication_person_incomeSources_esignSession = (
  deserialized: Applications_applications_CharityCareApplication_person_incomeSources_esignSession
): Applications_applications_CharityCareApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplications_applications_CharityCareApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface Applications_applications_CharityCareApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: Applications_applications_CharityCareApplication_person_incomeSources_esignSession | null
}
export const deserializeApplications_applications_CharityCareApplication_person_incomeSources = (
  serialized: Applications_applications_CharityCareApplication_person_incomeSourcesOrig
): Applications_applications_CharityCareApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplications_applications_CharityCareApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplications_applications_CharityCareApplication_person_incomeSources = (
  deserialized: Applications_applications_CharityCareApplication_person_incomeSources
): Applications_applications_CharityCareApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplications_applications_CharityCareApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface Applications_applications_CharityCareApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplications_applications_CharityCareApplication_person_deductions = (
  serialized: Applications_applications_CharityCareApplication_person_deductionsOrig
): Applications_applications_CharityCareApplication_person_deductions => ({
  ...serialized,
})
export const serializeApplications_applications_CharityCareApplication_person_deductions = (
  deserialized: Applications_applications_CharityCareApplication_person_deductions
): Applications_applications_CharityCareApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface Applications_applications_CharityCareApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplications_applications_CharityCareApplication_person_openApplications_MedicaidApplication = (
  serialized: Applications_applications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig
): Applications_applications_CharityCareApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_CharityCareApplication_person_openApplications_MedicaidApplication = (
  deserialized: Applications_applications_CharityCareApplication_person_openApplications_MedicaidApplication
): Applications_applications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_CharityCareApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplications_applications_CharityCareApplication_person_openApplications_CharityCareApplication = (
  serialized: Applications_applications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig
): Applications_applications_CharityCareApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_CharityCareApplication_person_openApplications_CharityCareApplication = (
  deserialized: Applications_applications_CharityCareApplication_person_openApplications_CharityCareApplication
): Applications_applications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_CharityCareApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplications_applications_CharityCareApplication_person_openApplications_SlideApplication = (
  serialized: Applications_applications_CharityCareApplication_person_openApplications_SlideApplicationOrig
): Applications_applications_CharityCareApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_CharityCareApplication_person_openApplications_SlideApplication = (
  deserialized: Applications_applications_CharityCareApplication_person_openApplications_SlideApplication
): Applications_applications_CharityCareApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_CharityCareApplication_person_openApplications_RyanWhiteApplication {
  __typename: 'RyanWhiteApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplications_applications_CharityCareApplication_person_openApplications_RyanWhiteApplication = (
  serialized: Applications_applications_CharityCareApplication_person_openApplications_RyanWhiteApplicationOrig
): Applications_applications_CharityCareApplication_person_openApplications_RyanWhiteApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_CharityCareApplication_person_openApplications_RyanWhiteApplication = (
  deserialized: Applications_applications_CharityCareApplication_person_openApplications_RyanWhiteApplication
): Applications_applications_CharityCareApplication_person_openApplications_RyanWhiteApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type Applications_applications_CharityCareApplication_person_openApplications =
  | Applications_applications_CharityCareApplication_person_openApplications_MedicaidApplication
  | Applications_applications_CharityCareApplication_person_openApplications_CharityCareApplication
  | Applications_applications_CharityCareApplication_person_openApplications_SlideApplication
  | Applications_applications_CharityCareApplication_person_openApplications_RyanWhiteApplication
export const deserializeApplications_applications_CharityCareApplication_person_openApplications = (
  serialized: Applications_applications_CharityCareApplication_person_openApplicationsOrig
): Applications_applications_CharityCareApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeApplications_applications_CharityCareApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplications_applications_CharityCareApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplications_applications_CharityCareApplication_person_openApplications_SlideApplication(
      serialized
    )
  if (false || serialized.__typename === 'RyanWhiteApplication')
    return deserializeApplications_applications_CharityCareApplication_person_openApplications_RyanWhiteApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_CharityCareApplication_person_openApplications'
  )
}
export const serializeApplications_applications_CharityCareApplication_person_openApplications = (
  deserialized: Applications_applications_CharityCareApplication_person_openApplications
): Applications_applications_CharityCareApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeApplications_applications_CharityCareApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplications_applications_CharityCareApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplications_applications_CharityCareApplication_person_openApplications_SlideApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'RyanWhiteApplication')
    return serializeApplications_applications_CharityCareApplication_person_openApplications_RyanWhiteApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_CharityCareApplication_person_openApplications'
  )
}
export interface Applications_applications_CharityCareApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplications_applications_CharityCareApplication_person_openHouseholdMemberApplications = (
  serialized: Applications_applications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig
): Applications_applications_CharityCareApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_CharityCareApplication_person_openHouseholdMemberApplications = (
  deserialized: Applications_applications_CharityCareApplication_person_openHouseholdMemberApplications
): Applications_applications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_CharityCareApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: Applications_applications_CharityCareApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: Applications_applications_CharityCareApplication_person_benefitOutcomes[]
  incomeSources: Applications_applications_CharityCareApplication_person_incomeSources[]
  deductions: Applications_applications_CharityCareApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  openApplications: Applications_applications_CharityCareApplication_person_openApplications[]
  openHouseholdMemberApplications: Applications_applications_CharityCareApplication_person_openHouseholdMemberApplications[]
}
export const deserializeApplications_applications_CharityCareApplication_person = (
  serialized: Applications_applications_CharityCareApplication_personOrig
): Applications_applications_CharityCareApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplications_applications_CharityCareApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplications_applications_CharityCareApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplications_applications_CharityCareApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplications_applications_CharityCareApplication_person_deductions
  ),
  openApplications: serialized.openApplications.map(
    deserializeApplications_applications_CharityCareApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeApplications_applications_CharityCareApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeApplications_applications_CharityCareApplication_person = (
  deserialized: Applications_applications_CharityCareApplication_person
): Applications_applications_CharityCareApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplications_applications_CharityCareApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplications_applications_CharityCareApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplications_applications_CharityCareApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplications_applications_CharityCareApplication_person_deductions
  ),
  openApplications: deserialized.openApplications.map(
    serializeApplications_applications_CharityCareApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeApplications_applications_CharityCareApplication_person_openHouseholdMemberApplications
  ),
})
export interface Applications_applications_CharityCareApplication_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeApplications_applications_CharityCareApplication_assignedTo = (
  serialized: Applications_applications_CharityCareApplication_assignedToOrig
): Applications_applications_CharityCareApplication_assignedTo => ({
  ...serialized,
})
export const serializeApplications_applications_CharityCareApplication_assignedTo = (
  deserialized: Applications_applications_CharityCareApplication_assignedTo
): Applications_applications_CharityCareApplication_assignedToOrig => ({
  ...deserialized,
})
export interface Applications_applications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  readyForAudit: boolean
  initialDateOfService: Date | null
  dateOfApplication: Date | null
  requestedDateOfApplication: Date | null
  notes: string | null
  facility: string
  coveragePeriod: number | null
  charityCareType: string
  person: Applications_applications_CharityCareApplication_person
  assignedTo: Applications_applications_CharityCareApplication_assignedTo | null
}
export const deserializeApplications_applications_CharityCareApplication = (
  serialized: Applications_applications_CharityCareApplicationOrig
): Applications_applications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
  requestedDateOfApplication:
    serialized.requestedDateOfApplication != null
      ? parseDate(serialized.requestedDateOfApplication)
      : serialized.requestedDateOfApplication,
  person: deserializeApplications_applications_CharityCareApplication_person(
    serialized.person
  ),
  assignedTo:
    serialized.assignedTo != null
      ? deserializeApplications_applications_CharityCareApplication_assignedTo(
          serialized.assignedTo
        )
      : serialized.assignedTo,
})
export const serializeApplications_applications_CharityCareApplication = (
  deserialized: Applications_applications_CharityCareApplication
): Applications_applications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
  requestedDateOfApplication:
    deserialized.requestedDateOfApplication != null
      ? formatISODate(deserialized.requestedDateOfApplication)
      : deserialized.requestedDateOfApplication,
  person: serializeApplications_applications_CharityCareApplication_person(
    deserialized.person
  ),
  assignedTo:
    deserialized.assignedTo != null
      ? serializeApplications_applications_CharityCareApplication_assignedTo(
          deserialized.assignedTo
        )
      : deserialized.assignedTo,
})
export interface Applications_applications_SlideApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplications_applications_SlideApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: Applications_applications_SlideApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): Applications_applications_SlideApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplications_applications_SlideApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: Applications_applications_SlideApplication_person_phoneNumbers_esignSession_esignWizards
): Applications_applications_SlideApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface Applications_applications_SlideApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: Applications_applications_SlideApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplications_applications_SlideApplication_person_phoneNumbers_esignSession = (
  serialized: Applications_applications_SlideApplication_person_phoneNumbers_esignSessionOrig
): Applications_applications_SlideApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplications_applications_SlideApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplications_applications_SlideApplication_person_phoneNumbers_esignSession = (
  deserialized: Applications_applications_SlideApplication_person_phoneNumbers_esignSession
): Applications_applications_SlideApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplications_applications_SlideApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface Applications_applications_SlideApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: Applications_applications_SlideApplication_person_phoneNumbers_esignSession | null
}
export const deserializeApplications_applications_SlideApplication_person_phoneNumbers = (
  serialized: Applications_applications_SlideApplication_person_phoneNumbersOrig
): Applications_applications_SlideApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplications_applications_SlideApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplications_applications_SlideApplication_person_phoneNumbers = (
  deserialized: Applications_applications_SlideApplication_person_phoneNumbers
): Applications_applications_SlideApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplications_applications_SlideApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface Applications_applications_SlideApplication_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplications_applications_SlideApplication_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: Applications_applications_SlideApplication_person_benefitOutcomes_application_MedicaidApplicationOrig
): Applications_applications_SlideApplication_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplications_applications_SlideApplication_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: Applications_applications_SlideApplication_person_benefitOutcomes_application_MedicaidApplication
): Applications_applications_SlideApplication_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface Applications_applications_SlideApplication_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplications_applications_SlideApplication_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: Applications_applications_SlideApplication_person_benefitOutcomes_application_CharityCareApplicationOrig
): Applications_applications_SlideApplication_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplications_applications_SlideApplication_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: Applications_applications_SlideApplication_person_benefitOutcomes_application_CharityCareApplication
): Applications_applications_SlideApplication_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface Applications_applications_SlideApplication_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplications_applications_SlideApplication_person_benefitOutcomes_application_SlideApplication = (
  serialized: Applications_applications_SlideApplication_person_benefitOutcomes_application_SlideApplicationOrig
): Applications_applications_SlideApplication_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplications_applications_SlideApplication_person_benefitOutcomes_application_SlideApplication = (
  deserialized: Applications_applications_SlideApplication_person_benefitOutcomes_application_SlideApplication
): Applications_applications_SlideApplication_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type Applications_applications_SlideApplication_person_benefitOutcomes_application =
  | Applications_applications_SlideApplication_person_benefitOutcomes_application_MedicaidApplication
  | Applications_applications_SlideApplication_person_benefitOutcomes_application_CharityCareApplication
  | Applications_applications_SlideApplication_person_benefitOutcomes_application_SlideApplication
export const deserializeApplications_applications_SlideApplication_person_benefitOutcomes_application = (
  serialized: Applications_applications_SlideApplication_person_benefitOutcomes_applicationOrig
): Applications_applications_SlideApplication_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplications_applications_SlideApplication_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplications_applications_SlideApplication_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplications_applications_SlideApplication_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_SlideApplication_person_benefitOutcomes_application'
  )
}
export const serializeApplications_applications_SlideApplication_person_benefitOutcomes_application = (
  deserialized: Applications_applications_SlideApplication_person_benefitOutcomes_application
): Applications_applications_SlideApplication_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplications_applications_SlideApplication_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplications_applications_SlideApplication_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplications_applications_SlideApplication_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_SlideApplication_person_benefitOutcomes_application'
  )
}
export interface Applications_applications_SlideApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: Applications_applications_SlideApplication_person_benefitOutcomes_application | null
}
export const deserializeApplications_applications_SlideApplication_person_benefitOutcomes = (
  serialized: Applications_applications_SlideApplication_person_benefitOutcomesOrig
): Applications_applications_SlideApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplications_applications_SlideApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplications_applications_SlideApplication_person_benefitOutcomes = (
  deserialized: Applications_applications_SlideApplication_person_benefitOutcomes
): Applications_applications_SlideApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplications_applications_SlideApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface Applications_applications_SlideApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplications_applications_SlideApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: Applications_applications_SlideApplication_person_incomeSources_esignSession_esignWizardsOrig
): Applications_applications_SlideApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplications_applications_SlideApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: Applications_applications_SlideApplication_person_incomeSources_esignSession_esignWizards
): Applications_applications_SlideApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface Applications_applications_SlideApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: Applications_applications_SlideApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplications_applications_SlideApplication_person_incomeSources_esignSession = (
  serialized: Applications_applications_SlideApplication_person_incomeSources_esignSessionOrig
): Applications_applications_SlideApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplications_applications_SlideApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplications_applications_SlideApplication_person_incomeSources_esignSession = (
  deserialized: Applications_applications_SlideApplication_person_incomeSources_esignSession
): Applications_applications_SlideApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplications_applications_SlideApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface Applications_applications_SlideApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: Applications_applications_SlideApplication_person_incomeSources_esignSession | null
}
export const deserializeApplications_applications_SlideApplication_person_incomeSources = (
  serialized: Applications_applications_SlideApplication_person_incomeSourcesOrig
): Applications_applications_SlideApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplications_applications_SlideApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplications_applications_SlideApplication_person_incomeSources = (
  deserialized: Applications_applications_SlideApplication_person_incomeSources
): Applications_applications_SlideApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplications_applications_SlideApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface Applications_applications_SlideApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplications_applications_SlideApplication_person_deductions = (
  serialized: Applications_applications_SlideApplication_person_deductionsOrig
): Applications_applications_SlideApplication_person_deductions => ({
  ...serialized,
})
export const serializeApplications_applications_SlideApplication_person_deductions = (
  deserialized: Applications_applications_SlideApplication_person_deductions
): Applications_applications_SlideApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface Applications_applications_SlideApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplications_applications_SlideApplication_person_openApplications_MedicaidApplication = (
  serialized: Applications_applications_SlideApplication_person_openApplications_MedicaidApplicationOrig
): Applications_applications_SlideApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_SlideApplication_person_openApplications_MedicaidApplication = (
  deserialized: Applications_applications_SlideApplication_person_openApplications_MedicaidApplication
): Applications_applications_SlideApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_SlideApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplications_applications_SlideApplication_person_openApplications_CharityCareApplication = (
  serialized: Applications_applications_SlideApplication_person_openApplications_CharityCareApplicationOrig
): Applications_applications_SlideApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_SlideApplication_person_openApplications_CharityCareApplication = (
  deserialized: Applications_applications_SlideApplication_person_openApplications_CharityCareApplication
): Applications_applications_SlideApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_SlideApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplications_applications_SlideApplication_person_openApplications_SlideApplication = (
  serialized: Applications_applications_SlideApplication_person_openApplications_SlideApplicationOrig
): Applications_applications_SlideApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_SlideApplication_person_openApplications_SlideApplication = (
  deserialized: Applications_applications_SlideApplication_person_openApplications_SlideApplication
): Applications_applications_SlideApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_SlideApplication_person_openApplications_RyanWhiteApplication {
  __typename: 'RyanWhiteApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplications_applications_SlideApplication_person_openApplications_RyanWhiteApplication = (
  serialized: Applications_applications_SlideApplication_person_openApplications_RyanWhiteApplicationOrig
): Applications_applications_SlideApplication_person_openApplications_RyanWhiteApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_SlideApplication_person_openApplications_RyanWhiteApplication = (
  deserialized: Applications_applications_SlideApplication_person_openApplications_RyanWhiteApplication
): Applications_applications_SlideApplication_person_openApplications_RyanWhiteApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type Applications_applications_SlideApplication_person_openApplications =
  | Applications_applications_SlideApplication_person_openApplications_MedicaidApplication
  | Applications_applications_SlideApplication_person_openApplications_CharityCareApplication
  | Applications_applications_SlideApplication_person_openApplications_SlideApplication
  | Applications_applications_SlideApplication_person_openApplications_RyanWhiteApplication
export const deserializeApplications_applications_SlideApplication_person_openApplications = (
  serialized: Applications_applications_SlideApplication_person_openApplicationsOrig
): Applications_applications_SlideApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeApplications_applications_SlideApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplications_applications_SlideApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplications_applications_SlideApplication_person_openApplications_SlideApplication(
      serialized
    )
  if (false || serialized.__typename === 'RyanWhiteApplication')
    return deserializeApplications_applications_SlideApplication_person_openApplications_RyanWhiteApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_SlideApplication_person_openApplications'
  )
}
export const serializeApplications_applications_SlideApplication_person_openApplications = (
  deserialized: Applications_applications_SlideApplication_person_openApplications
): Applications_applications_SlideApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeApplications_applications_SlideApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplications_applications_SlideApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplications_applications_SlideApplication_person_openApplications_SlideApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'RyanWhiteApplication')
    return serializeApplications_applications_SlideApplication_person_openApplications_RyanWhiteApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_SlideApplication_person_openApplications'
  )
}
export interface Applications_applications_SlideApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplications_applications_SlideApplication_person_openHouseholdMemberApplications = (
  serialized: Applications_applications_SlideApplication_person_openHouseholdMemberApplicationsOrig
): Applications_applications_SlideApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_SlideApplication_person_openHouseholdMemberApplications = (
  deserialized: Applications_applications_SlideApplication_person_openHouseholdMemberApplications
): Applications_applications_SlideApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_SlideApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: Applications_applications_SlideApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: Applications_applications_SlideApplication_person_benefitOutcomes[]
  incomeSources: Applications_applications_SlideApplication_person_incomeSources[]
  deductions: Applications_applications_SlideApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  openApplications: Applications_applications_SlideApplication_person_openApplications[]
  openHouseholdMemberApplications: Applications_applications_SlideApplication_person_openHouseholdMemberApplications[]
}
export const deserializeApplications_applications_SlideApplication_person = (
  serialized: Applications_applications_SlideApplication_personOrig
): Applications_applications_SlideApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplications_applications_SlideApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplications_applications_SlideApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplications_applications_SlideApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplications_applications_SlideApplication_person_deductions
  ),
  openApplications: serialized.openApplications.map(
    deserializeApplications_applications_SlideApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeApplications_applications_SlideApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeApplications_applications_SlideApplication_person = (
  deserialized: Applications_applications_SlideApplication_person
): Applications_applications_SlideApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplications_applications_SlideApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplications_applications_SlideApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplications_applications_SlideApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplications_applications_SlideApplication_person_deductions
  ),
  openApplications: deserialized.openApplications.map(
    serializeApplications_applications_SlideApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeApplications_applications_SlideApplication_person_openHouseholdMemberApplications
  ),
})
export interface Applications_applications_SlideApplication_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeApplications_applications_SlideApplication_assignedTo = (
  serialized: Applications_applications_SlideApplication_assignedToOrig
): Applications_applications_SlideApplication_assignedTo => ({...serialized})
export const serializeApplications_applications_SlideApplication_assignedTo = (
  deserialized: Applications_applications_SlideApplication_assignedTo
): Applications_applications_SlideApplication_assignedToOrig => ({
  ...deserialized,
})
export interface Applications_applications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  readyForAudit: boolean
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  facility: string
  slideType: string
  dateOfApplication: Date | null
  coveragePeriod: number | null
  person: Applications_applications_SlideApplication_person
  assignedTo: Applications_applications_SlideApplication_assignedTo | null
}
export const deserializeApplications_applications_SlideApplication = (
  serialized: Applications_applications_SlideApplicationOrig
): Applications_applications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  slideType: deserializeSlideType(serialized.slideType),
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
  person: deserializeApplications_applications_SlideApplication_person(
    serialized.person
  ),
  assignedTo:
    serialized.assignedTo != null
      ? deserializeApplications_applications_SlideApplication_assignedTo(
          serialized.assignedTo
        )
      : serialized.assignedTo,
})
export const serializeApplications_applications_SlideApplication = (
  deserialized: Applications_applications_SlideApplication
): Applications_applications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  slideType: serializeSlideType(deserialized.slideType),
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
  person: serializeApplications_applications_SlideApplication_person(
    deserialized.person
  ),
  assignedTo:
    deserialized.assignedTo != null
      ? serializeApplications_applications_SlideApplication_assignedTo(
          deserialized.assignedTo
        )
      : deserialized.assignedTo,
})
export interface Applications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: Applications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): Applications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: Applications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards
): Applications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface Applications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: Applications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeApplications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession = (
  serialized: Applications_applications_RyanWhiteApplication_person_phoneNumbers_esignSessionOrig
): Applications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeApplications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession = (
  deserialized: Applications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession
): Applications_applications_RyanWhiteApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface Applications_applications_RyanWhiteApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: Applications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession | null
}
export const deserializeApplications_applications_RyanWhiteApplication_person_phoneNumbers = (
  serialized: Applications_applications_RyanWhiteApplication_person_phoneNumbersOrig
): Applications_applications_RyanWhiteApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplications_applications_RyanWhiteApplication_person_phoneNumbers = (
  deserialized: Applications_applications_RyanWhiteApplication_person_phoneNumbers
): Applications_applications_RyanWhiteApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplications_applications_RyanWhiteApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplicationOrig
): Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication
): Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplicationOrig
): Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication
): Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication = (
  serialized: Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplicationOrig
): Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication = (
  deserialized: Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication
): Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application =
  | Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication
  | Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication
  | Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication
export const deserializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes_application = (
  serialized: Applications_applications_RyanWhiteApplication_person_benefitOutcomes_applicationOrig
): Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application'
  )
}
export const serializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes_application = (
  deserialized: Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application
): Applications_applications_RyanWhiteApplication_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application'
  )
}
export interface Applications_applications_RyanWhiteApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: Applications_applications_RyanWhiteApplication_person_benefitOutcomes_application | null
}
export const deserializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes = (
  serialized: Applications_applications_RyanWhiteApplication_person_benefitOutcomesOrig
): Applications_applications_RyanWhiteApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes = (
  deserialized: Applications_applications_RyanWhiteApplication_person_benefitOutcomes
): Applications_applications_RyanWhiteApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface Applications_applications_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeApplications_applications_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: Applications_applications_RyanWhiteApplication_person_incomeSources_esignSession_esignWizardsOrig
): Applications_applications_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeApplications_applications_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: Applications_applications_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards
): Applications_applications_RyanWhiteApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface Applications_applications_RyanWhiteApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: Applications_applications_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeApplications_applications_RyanWhiteApplication_person_incomeSources_esignSession = (
  serialized: Applications_applications_RyanWhiteApplication_person_incomeSources_esignSessionOrig
): Applications_applications_RyanWhiteApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeApplications_applications_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeApplications_applications_RyanWhiteApplication_person_incomeSources_esignSession = (
  deserialized: Applications_applications_RyanWhiteApplication_person_incomeSources_esignSession
): Applications_applications_RyanWhiteApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeApplications_applications_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface Applications_applications_RyanWhiteApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: Applications_applications_RyanWhiteApplication_person_incomeSources_esignSession | null
}
export const deserializeApplications_applications_RyanWhiteApplication_person_incomeSources = (
  serialized: Applications_applications_RyanWhiteApplication_person_incomeSourcesOrig
): Applications_applications_RyanWhiteApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeApplications_applications_RyanWhiteApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeApplications_applications_RyanWhiteApplication_person_incomeSources = (
  deserialized: Applications_applications_RyanWhiteApplication_person_incomeSources
): Applications_applications_RyanWhiteApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeApplications_applications_RyanWhiteApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface Applications_applications_RyanWhiteApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeApplications_applications_RyanWhiteApplication_person_deductions = (
  serialized: Applications_applications_RyanWhiteApplication_person_deductionsOrig
): Applications_applications_RyanWhiteApplication_person_deductions => ({
  ...serialized,
})
export const serializeApplications_applications_RyanWhiteApplication_person_deductions = (
  deserialized: Applications_applications_RyanWhiteApplication_person_deductions
): Applications_applications_RyanWhiteApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface Applications_applications_RyanWhiteApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplications_applications_RyanWhiteApplication_person_openApplications_MedicaidApplication = (
  serialized: Applications_applications_RyanWhiteApplication_person_openApplications_MedicaidApplicationOrig
): Applications_applications_RyanWhiteApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_RyanWhiteApplication_person_openApplications_MedicaidApplication = (
  deserialized: Applications_applications_RyanWhiteApplication_person_openApplications_MedicaidApplication
): Applications_applications_RyanWhiteApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_RyanWhiteApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplications_applications_RyanWhiteApplication_person_openApplications_CharityCareApplication = (
  serialized: Applications_applications_RyanWhiteApplication_person_openApplications_CharityCareApplicationOrig
): Applications_applications_RyanWhiteApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_RyanWhiteApplication_person_openApplications_CharityCareApplication = (
  deserialized: Applications_applications_RyanWhiteApplication_person_openApplications_CharityCareApplication
): Applications_applications_RyanWhiteApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_RyanWhiteApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplications_applications_RyanWhiteApplication_person_openApplications_SlideApplication = (
  serialized: Applications_applications_RyanWhiteApplication_person_openApplications_SlideApplicationOrig
): Applications_applications_RyanWhiteApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_RyanWhiteApplication_person_openApplications_SlideApplication = (
  deserialized: Applications_applications_RyanWhiteApplication_person_openApplications_SlideApplication
): Applications_applications_RyanWhiteApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_RyanWhiteApplication_person_openApplications_RyanWhiteApplication {
  __typename: 'RyanWhiteApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeApplications_applications_RyanWhiteApplication_person_openApplications_RyanWhiteApplication = (
  serialized: Applications_applications_RyanWhiteApplication_person_openApplications_RyanWhiteApplicationOrig
): Applications_applications_RyanWhiteApplication_person_openApplications_RyanWhiteApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_RyanWhiteApplication_person_openApplications_RyanWhiteApplication = (
  deserialized: Applications_applications_RyanWhiteApplication_person_openApplications_RyanWhiteApplication
): Applications_applications_RyanWhiteApplication_person_openApplications_RyanWhiteApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type Applications_applications_RyanWhiteApplication_person_openApplications =
  | Applications_applications_RyanWhiteApplication_person_openApplications_MedicaidApplication
  | Applications_applications_RyanWhiteApplication_person_openApplications_CharityCareApplication
  | Applications_applications_RyanWhiteApplication_person_openApplications_SlideApplication
  | Applications_applications_RyanWhiteApplication_person_openApplications_RyanWhiteApplication
export const deserializeApplications_applications_RyanWhiteApplication_person_openApplications = (
  serialized: Applications_applications_RyanWhiteApplication_person_openApplicationsOrig
): Applications_applications_RyanWhiteApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeApplications_applications_RyanWhiteApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplications_applications_RyanWhiteApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplications_applications_RyanWhiteApplication_person_openApplications_SlideApplication(
      serialized
    )
  if (false || serialized.__typename === 'RyanWhiteApplication')
    return deserializeApplications_applications_RyanWhiteApplication_person_openApplications_RyanWhiteApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_RyanWhiteApplication_person_openApplications'
  )
}
export const serializeApplications_applications_RyanWhiteApplication_person_openApplications = (
  deserialized: Applications_applications_RyanWhiteApplication_person_openApplications
): Applications_applications_RyanWhiteApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeApplications_applications_RyanWhiteApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplications_applications_RyanWhiteApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplications_applications_RyanWhiteApplication_person_openApplications_SlideApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'RyanWhiteApplication')
    return serializeApplications_applications_RyanWhiteApplication_person_openApplications_RyanWhiteApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'Applications_applications_RyanWhiteApplication_person_openApplications'
  )
}
export interface Applications_applications_RyanWhiteApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeApplications_applications_RyanWhiteApplication_person_openHouseholdMemberApplications = (
  serialized: Applications_applications_RyanWhiteApplication_person_openHouseholdMemberApplicationsOrig
): Applications_applications_RyanWhiteApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeApplications_applications_RyanWhiteApplication_person_openHouseholdMemberApplications = (
  deserialized: Applications_applications_RyanWhiteApplication_person_openHouseholdMemberApplications
): Applications_applications_RyanWhiteApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Applications_applications_RyanWhiteApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: Applications_applications_RyanWhiteApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: Applications_applications_RyanWhiteApplication_person_benefitOutcomes[]
  incomeSources: Applications_applications_RyanWhiteApplication_person_incomeSources[]
  deductions: Applications_applications_RyanWhiteApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  openApplications: Applications_applications_RyanWhiteApplication_person_openApplications[]
  openHouseholdMemberApplications: Applications_applications_RyanWhiteApplication_person_openHouseholdMemberApplications[]
}
export const deserializeApplications_applications_RyanWhiteApplication_person = (
  serialized: Applications_applications_RyanWhiteApplication_personOrig
): Applications_applications_RyanWhiteApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeApplications_applications_RyanWhiteApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeApplications_applications_RyanWhiteApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeApplications_applications_RyanWhiteApplication_person_deductions
  ),
  openApplications: serialized.openApplications.map(
    deserializeApplications_applications_RyanWhiteApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeApplications_applications_RyanWhiteApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeApplications_applications_RyanWhiteApplication_person = (
  deserialized: Applications_applications_RyanWhiteApplication_person
): Applications_applications_RyanWhiteApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeApplications_applications_RyanWhiteApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeApplications_applications_RyanWhiteApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeApplications_applications_RyanWhiteApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeApplications_applications_RyanWhiteApplication_person_deductions
  ),
  openApplications: deserialized.openApplications.map(
    serializeApplications_applications_RyanWhiteApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeApplications_applications_RyanWhiteApplication_person_openHouseholdMemberApplications
  ),
})
export interface Applications_applications_RyanWhiteApplication_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeApplications_applications_RyanWhiteApplication_assignedTo = (
  serialized: Applications_applications_RyanWhiteApplication_assignedToOrig
): Applications_applications_RyanWhiteApplication_assignedTo => ({
  ...serialized,
})
export const serializeApplications_applications_RyanWhiteApplication_assignedTo = (
  deserialized: Applications_applications_RyanWhiteApplication_assignedTo
): Applications_applications_RyanWhiteApplication_assignedToOrig => ({
  ...deserialized,
})
export interface Applications_applications_RyanWhiteApplication {
  __typename: 'RyanWhiteApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  readyForAudit: boolean
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  facility: string
  dateOfApplication: Date | null
  coveragePeriod: number | null
  person: Applications_applications_RyanWhiteApplication_person
  assignedTo: Applications_applications_RyanWhiteApplication_assignedTo | null
}
export const deserializeApplications_applications_RyanWhiteApplication = (
  serialized: Applications_applications_RyanWhiteApplicationOrig
): Applications_applications_RyanWhiteApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
  person: deserializeApplications_applications_RyanWhiteApplication_person(
    serialized.person
  ),
  assignedTo:
    serialized.assignedTo != null
      ? deserializeApplications_applications_RyanWhiteApplication_assignedTo(
          serialized.assignedTo
        )
      : serialized.assignedTo,
})
export const serializeApplications_applications_RyanWhiteApplication = (
  deserialized: Applications_applications_RyanWhiteApplication
): Applications_applications_RyanWhiteApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
  person: serializeApplications_applications_RyanWhiteApplication_person(
    deserialized.person
  ),
  assignedTo:
    deserialized.assignedTo != null
      ? serializeApplications_applications_RyanWhiteApplication_assignedTo(
          deserialized.assignedTo
        )
      : deserialized.assignedTo,
})
export type Applications_applications =
  | Applications_applications_MedicaidApplication
  | Applications_applications_MonitorApplication
  | Applications_applications_CharityCareApplication
  | Applications_applications_SlideApplication
  | Applications_applications_RyanWhiteApplication
export const deserializeApplications_applications = (
  serialized: Applications_applicationsOrig
): Applications_applications => {
  if (false || serialized.__typename === 'MedicaidApplication')
    return deserializeApplications_applications_MedicaidApplication(serialized)
  if (false || serialized.__typename === 'MonitorApplication')
    return deserializeApplications_applications_MonitorApplication(serialized)
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeApplications_applications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeApplications_applications_SlideApplication(serialized)
  if (false || serialized.__typename === 'RyanWhiteApplication')
    return deserializeApplications_applications_RyanWhiteApplication(serialized)
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` + 'Applications_applications'
  )
}
export const serializeApplications_applications = (
  deserialized: Applications_applications
): Applications_applicationsOrig => {
  if (false || deserialized.__typename === 'MedicaidApplication')
    return serializeApplications_applications_MedicaidApplication(deserialized)
  if (false || deserialized.__typename === 'MonitorApplication')
    return serializeApplications_applications_MonitorApplication(deserialized)
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeApplications_applications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeApplications_applications_SlideApplication(deserialized)
  if (false || deserialized.__typename === 'RyanWhiteApplication')
    return serializeApplications_applications_RyanWhiteApplication(deserialized)
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` + 'Applications_applications'
  )
}
export interface Applications {
  /**
   * List of applications
   */
  applications: Applications_applications[]
}
export const deserializeApplications = (
  serialized: ApplicationsOrig
): Applications => ({
  ...serialized,
  applications: serialized.applications.map(
    deserializeApplications_applications
  ),
})
export const serializeApplications = (
  deserialized: Applications
): ApplicationsOrig => ({
  ...deserialized,
  applications: deserialized.applications.map(
    serializeApplications_applications
  ),
})
export interface ApplicationsVariables {
  statuses: string[]
  benefits: string[]
  search?: string | null
  facilities?: string[] | null
  locations?: string[] | null
  initialDateOfService?: string[] | null
  hasPolicyId?: string[] | null
  submitDate?: string[] | null
  esignNeedsReview?: string[] | null
  completedEsignSessions?: string[] | null
  mcdType?: string[] | null
  age?: string[] | null
  createdByIds?: string[] | null
  assignedToIds?: string[] | null
  unreviewedEditableFiles?: string[] | null
  charityCareTypes?: string[] | null
  hasUnreadTextMessages?: string[] | null
  pastDateOfService?: string[] | null
  slideType?: string[] | null
  mcdSubtype?: string | null
}
export const deserializeApplicationsVariables = (
  serialized: ApplicationsVariablesOrig
): ApplicationsVariables => ({...serialized})
export const serializeApplicationsVariables = (
  deserialized: ApplicationsVariables
): ApplicationsVariablesOrig => ({...deserialized})
