/* eslint-disable */
import {EventLogsVariables as EventLogsVariablesOrig} from '../types/EventLogs'
import {EventLogs as EventLogsOrig} from '../types/EventLogs'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {EventLogs_eventLogs as EventLogs_eventLogsOrig} from '../types/EventLogs'
import {EventLogs_eventLogs_eventInitiatedBy as EventLogs_eventLogs_eventInitiatedByOrig} from '../types/EventLogs'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: EventLogs
// ====================================================
export interface EventLogs_eventLogs_eventInitiatedBy {
  __typename: 'User'
  id: string
  name: string | null
}
export const deserializeEventLogs_eventLogs_eventInitiatedBy = (
  serialized: EventLogs_eventLogs_eventInitiatedByOrig
): EventLogs_eventLogs_eventInitiatedBy => ({...serialized})
export const serializeEventLogs_eventLogs_eventInitiatedBy = (
  deserialized: EventLogs_eventLogs_eventInitiatedBy
): EventLogs_eventLogs_eventInitiatedByOrig => ({...deserialized})
export interface EventLogs_eventLogs {
  __typename: 'EventLog'
  id: string
  eventName: string | null
  eventMessage: string
  sentFlag: boolean | null
  sentAt: Date | null
  eventInitiatedBy: EventLogs_eventLogs_eventInitiatedBy
  personId: number
  applicationId: number | null
  createdAt: Date
  updatedAt: Date
}
export const deserializeEventLogs_eventLogs = (
  serialized: EventLogs_eventLogsOrig
): EventLogs_eventLogs => ({
  ...serialized,
  sentAt:
    serialized.sentAt != null
      ? parseDate(serialized.sentAt)
      : serialized.sentAt,
  eventInitiatedBy: deserializeEventLogs_eventLogs_eventInitiatedBy(
    serialized.eventInitiatedBy
  ),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
})
export const serializeEventLogs_eventLogs = (
  deserialized: EventLogs_eventLogs
): EventLogs_eventLogsOrig => ({
  ...deserialized,
  sentAt:
    deserialized.sentAt != null
      ? formatISO(deserialized.sentAt)
      : deserialized.sentAt,
  eventInitiatedBy: serializeEventLogs_eventLogs_eventInitiatedBy(
    deserialized.eventInitiatedBy
  ),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
})
export interface EventLogs {
  /**
   * List of Event Logs for this patient either at person or application level
   */
  eventLogs: EventLogs_eventLogs[]
}
export const deserializeEventLogs = (serialized: EventLogsOrig): EventLogs => ({
  ...serialized,
  eventLogs: serialized.eventLogs.map(deserializeEventLogs_eventLogs),
})
export const serializeEventLogs = (deserialized: EventLogs): EventLogsOrig => ({
  ...deserialized,
  eventLogs: deserialized.eventLogs.map(serializeEventLogs_eventLogs),
})
export interface EventLogsVariables {
  personId: string
  applicationId?: string | null
}
export const deserializeEventLogsVariables = (
  serialized: EventLogsVariablesOrig
): EventLogsVariables => ({...serialized})
export const serializeEventLogsVariables = (
  deserialized: EventLogsVariables
): EventLogsVariablesOrig => ({...deserialized})
