import {TFunction} from 'i18next'

export const getFormattedPhoneNumber = (phoneNumber: string) =>
  `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
    6,
    10
  )}`

export const getSinglePhoneSummary = ({number}: {number: string}) =>
  getFormattedPhoneNumber(number)

export const getSinglePhoneSummaryWithLanguage = ({
  number,
  language,
}: {
  number: string
  language: string | null
}) => `${getFormattedPhoneNumber(number)}${language ? ` (${language})` : ''}`

export const getPhoneSummary = ({
  phoneNumbers,
  t,
}: {
  phoneNumbers: {
    usedInApp: boolean | null
    number: string
    language: string | null
  }[]
  t: TFunction
}) => {
  const usedInAppPhoneNumber = phoneNumbers.find((number) => number.usedInApp)
  if (!usedInAppPhoneNumber) return t('general.unknown')
  return getSinglePhoneSummary(usedInAppPhoneNumber)
}
