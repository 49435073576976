import {
  makeDeterminerRule,
  START_TOKEN,
  makeDeterminer,
} from 'utils/form/determiners'
import {
  INCOME_SOURCE_TYPE_SALARY_WAGES,
  INCOME_SOURCE_TYPE_FREELANCE,
  INCOME_SOURCE_TYPE_SELF_EMPLOYED,
} from 'utils/form/fieldTypes'

const incomeSourceType = makeDeterminerRule({
  name: 'incomeSource.incomeType',
  necessaryIf: [START_TOKEN],
})

const incomeSourceEmployerName = makeDeterminerRule({
  name: 'incomeSource.employerName',
  necessaryIf: [START_TOKEN],
})

const incomeSourceEmployerNameMedicaid = makeDeterminerRule({
  name: 'incomeSource.employerName',
  necessaryIf: {
    'incomeSource.incomeType': [
      INCOME_SOURCE_TYPE_SALARY_WAGES,
      INCOME_SOURCE_TYPE_FREELANCE,
      INCOME_SOURCE_TYPE_SELF_EMPLOYED,
    ],
  },
})

const incomeSourceEmployerAddressStreet = makeDeterminerRule({
  name: 'incomeSource.employerAddressStreet',
  necessaryIf: {
    'incomeSource.employerInsurance': true,
  },
})

const incomeSourceEmployerAddressCity = makeDeterminerRule({
  name: 'incomeSource.employerAddressCity',
  necessaryIf: {
    'incomeSource.employerInsurance': true,
  },
})

const incomeSourceEmployerAddressState = makeDeterminerRule({
  name: 'incomeSource.employerAddressState',
  necessaryIf: {
    'incomeSource.employerInsurance': true,
  },
})

const incomeSourceEmployerAddressZip = makeDeterminerRule({
  name: 'incomeSource.employerAddressZip',
  necessaryIf: {
    'incomeSource.employerInsurance': true,
  },
})

const incomeSourceEmployerAddressStreetUsedForEsign = makeDeterminerRule({
  name: 'incomeSource.employerAddressStreet',
  necessaryIf: {
    'incomeSource.usedForEsign': true,
  },
})

const incomeSourceEmployerAddressCityUsedForEsign = makeDeterminerRule({
  name: 'incomeSource.employerAddressCity',
  necessaryIf: {
    'incomeSource.usedForEsign': true,
  },
})

const incomeSourceEmployerAddressStateUsedForEsign = makeDeterminerRule({
  name: 'incomeSource.employerAddressState',
  necessaryIf: {
    'incomeSource.usedForEsign': true,
  },
})

const incomeSourceEmployerAddressZipUsedForEsign = makeDeterminerRule({
  name: 'incomeSource.employerAddressZip',
  necessaryIf: {
    'incomeSource.usedForEsign': true,
  },
})

const incomeSourceEmployerInsurance = makeDeterminerRule({
  name: 'incomeSource.employerInsurance',
  necessaryIf: {
    'incomeSource.incomeType': [
      INCOME_SOURCE_TYPE_SALARY_WAGES,
      INCOME_SOURCE_TYPE_FREELANCE,
      INCOME_SOURCE_TYPE_SELF_EMPLOYED,
    ],
  },
})

const incomeSourceFullPartTime = makeDeterminerRule({
  name: 'incomeSource.fullPartTime',
  necessaryIf: {
    'incomeSource.incomeType': [
      INCOME_SOURCE_TYPE_SALARY_WAGES,
      INCOME_SOURCE_TYPE_FREELANCE,
      INCOME_SOURCE_TYPE_SELF_EMPLOYED,
    ],
  },
})

const incomeSourceStartMonth = makeDeterminerRule({
  name: 'incomeSource.startMonth',
  necessaryIf: [START_TOKEN],
})

const incomeSourceStartYear = makeDeterminerRule({
  name: 'incomeSource.startYear',
  necessaryIf: [START_TOKEN],
})

const incomeSourceEndMonth = makeDeterminerRule({
  name: 'incomeSource.endMonth',
  necessaryIf: [START_TOKEN],
})

const incomeSourceEndYear = makeDeterminerRule({
  name: 'incomeSource.endYear',
  necessaryIf: [START_TOKEN],
})

const incomeSourceAmount = makeDeterminerRule({
  name: 'incomeSource.amount',
  necessaryIf: [START_TOKEN],
})

const incomeSourcePayFrequency = makeDeterminerRule({
  name: 'incomeSource.payFrequency',
  necessaryIf: [START_TOKEN],
})

const incomeSourceUsedForEsign = makeDeterminerRule({
  name: 'incomeSource.usedForEsign',
  necessaryIf: [START_TOKEN],
})

export const determinerIncomeSourceForm = makeDeterminer({
  name: 'incomeSource',
  rules: [
    incomeSourceType,
    incomeSourceEmployerNameMedicaid,
    incomeSourceEmployerAddressStreet,
    incomeSourceEmployerAddressCity,
    incomeSourceEmployerAddressState,
    incomeSourceEmployerAddressZip,
    incomeSourceEmployerAddressStreetUsedForEsign,
    incomeSourceEmployerAddressCityUsedForEsign,
    incomeSourceEmployerAddressStateUsedForEsign,
    incomeSourceEmployerAddressZipUsedForEsign,
    incomeSourceEmployerInsurance,
    incomeSourceFullPartTime,
    incomeSourceStartMonth,
    incomeSourceStartYear,
    incomeSourceEndMonth,
    incomeSourceEndYear,
    incomeSourceAmount,
    incomeSourcePayFrequency,
    incomeSourceUsedForEsign,
  ],
})

export const determinerIncomeSourceFormCharityCare = makeDeterminer({
  name: 'incomeSource',
  rules: [
    incomeSourceType,
    incomeSourceEmployerName,
    incomeSourceStartMonth,
    incomeSourceStartYear,
    incomeSourceEndMonth,
    incomeSourceEndYear,
    incomeSourceAmount,
    incomeSourcePayFrequency,
    incomeSourceUsedForEsign,
    incomeSourceEmployerAddressStreetUsedForEsign,
    incomeSourceEmployerAddressCityUsedForEsign,
    incomeSourceEmployerAddressStateUsedForEsign,
    incomeSourceEmployerAddressZipUsedForEsign,
  ],
})
