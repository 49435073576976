/* eslint-disable */
import {CreateSendDocumentRequestsVariables as CreateSendDocumentRequestsVariablesOrig} from '../types/CreateSendDocumentRequests'
import {CreateSendDocumentRequests as CreateSendDocumentRequestsOrig} from '../types/CreateSendDocumentRequests'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL mutation operation: CreateSendDocumentRequests
// ====================================================
export interface CreateSendDocumentRequests {
  createSendDocumentRequests: boolean
}
export const deserializeCreateSendDocumentRequests = (
  serialized: CreateSendDocumentRequestsOrig
): CreateSendDocumentRequests => ({...serialized})
export const serializeCreateSendDocumentRequests = (
  deserialized: CreateSendDocumentRequests
): CreateSendDocumentRequestsOrig => ({...deserialized})
export interface CreateSendDocumentRequestsVariables {
  phoneNumber?: string | null
  language?: string | null
  applicationId: string
  personId: string
  requestorIds: string[]
  documentIds: string[]
  smsMessage?: string | null
}
export const deserializeCreateSendDocumentRequestsVariables = (
  serialized: CreateSendDocumentRequestsVariablesOrig
): CreateSendDocumentRequestsVariables => ({...serialized})
export const serializeCreateSendDocumentRequestsVariables = (
  deserialized: CreateSendDocumentRequestsVariables
): CreateSendDocumentRequestsVariablesOrig => ({...deserialized})
