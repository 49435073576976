/* eslint-disable */
import {Webforms as WebformsOrig} from '../types/Webforms'
import {Webforms_webforms as Webforms_webformsOrig} from '../types/Webforms'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: Webforms
// ====================================================
export interface Webforms_webforms {
  __typename: 'Webform'
  id: string
  name: string
  slug: string
}
export const deserializeWebforms_webforms = (
  serialized: Webforms_webformsOrig
): Webforms_webforms => ({...serialized})
export const serializeWebforms_webforms = (
  deserialized: Webforms_webforms
): Webforms_webformsOrig => ({...deserialized})
export interface Webforms {
  /**
   * Webforms
   */
  webforms: Webforms_webforms[]
}
export const deserializeWebforms = (serialized: WebformsOrig): Webforms => ({
  ...serialized,
  webforms: serialized.webforms.map(deserializeWebforms_webforms),
})
export const serializeWebforms = (deserialized: Webforms): WebformsOrig => ({
  ...deserialized,
  webforms: deserialized.webforms.map(serializeWebforms_webforms),
})
