/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {UpdateReadyForAuditInput} from './globalTypes' // ====================================================
// GraphQL mutation operation: UpdateReadyForAudit
// ====================================================

import {deserializeUpdateReadyForAuditInput} from './globalTypes'
import {serializeUpdateReadyForAuditInput} from './globalTypes'
import {UpdateReadyForAuditVariables as UpdateReadyForAuditVariablesOrig} from '../types/UpdateReadyForAudit'
import {UpdateReadyForAudit as UpdateReadyForAuditOrig} from '../types/UpdateReadyForAudit'
export interface UpdateReadyForAudit {
  /**
   * Update existing account's ready for audit boolean
   */
  updateReadyForAudit: boolean
}
export const deserializeUpdateReadyForAudit = (
  serialized: UpdateReadyForAuditOrig
): UpdateReadyForAudit => ({...serialized})
export const serializeUpdateReadyForAudit = (
  deserialized: UpdateReadyForAudit
): UpdateReadyForAuditOrig => ({...deserialized})
export interface UpdateReadyForAuditVariables {
  auditFlag: UpdateReadyForAuditInput
}
export const deserializeUpdateReadyForAuditVariables = (
  serialized: UpdateReadyForAuditVariablesOrig
): UpdateReadyForAuditVariables => ({
  ...serialized,
  auditFlag: deserializeUpdateReadyForAuditInput(serialized.auditFlag),
})
export const serializeUpdateReadyForAuditVariables = (
  deserialized: UpdateReadyForAuditVariables
): UpdateReadyForAuditVariablesOrig => ({
  ...deserialized,
  auditFlag: serializeUpdateReadyForAuditInput(deserialized.auditFlag),
})
