import {Person_person_openApplications_CharityCareApplication} from 'graphql/deserializedTypes/Person'
import {isUnder18} from 'utils/age'

export type CharityCareApplication = Omit<
  Person_person_openApplications_CharityCareApplication,
  'eligibilityDeterminations' | 'person'
>

export type CharityCareApplicationWithInitialDateOfService = CharityCareApplication & {
  initialDateOfService: Date
}

export const isMinorCharityCare = (
  {
    dob,
    isStudent,
  }: {
    dob: Date | null
    isStudent: boolean | null
  },
  {
    livesWithParents,
  }: {
    livesWithParents: boolean
  }
) => dob && (isUnder18(dob) || (isStudent && livesWithParents))

export const isCharityCareApplicationWithInitialDateOfService = (
  application: CharityCareApplication
): application is CharityCareApplicationWithInitialDateOfService =>
  !!application.initialDateOfService
