import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addHandlers,
  addProps,
  addMemoBoundary,
} from 'ad-hok'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import cx from 'classnames'

import {addClasses, makeClasses} from 'theme'
import {
  addFormContext,
  addFieldsFreshnessContext,
  addFormFieldStatusesContext,
} from 'utils/form/context'
import {
  getFieldsFreshnessConfirmed,
  getFieldsFreshnessUpdatedAt,
} from 'utils/form/getFieldsUpdatedAt'
import Caption from 'components/Caption'
import {toEasternTime} from 'utils/date'
import {getFormFeedbackIconStatus} from 'components/FormFeedbackIcon'
import CheckmarkToggle from 'components/CheckmarkToggle'

const classes = makeClasses((_theme) => ({
  button: {
    padding: 0,
    backgroundColor: '#fafafa',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  },
  buttonAnchored: {
    position: 'absolute',
    top: 42,
    right: -15,
  },
  buttonAnchoredWithText: {
    position: 'absolute',
    top: 42,
    right: -32,
  },
  buttonAnchoredNoIconWithText: {
    display: 'flex',
    flexDirection: 'column',
    right: -11,
    top: 42,
  },
  text: {
    color: '#bdbdbd',
    fontWeight: 'bold',
    marginLeft: 4,
  },
}))

const getLastConfirmedWeeks = (lastConfirmed: Date | undefined) => {
  if (!lastConfirmed) return ''
  const now = toEasternTime(new Date())
  const lastConfirmedEasternTime = toEasternTime(lastConfirmed)
  return `${Math.ceil(
    differenceInCalendarDays(now, lastConfirmedEasternTime) / 7
  )}w`
}

interface Props {
  name: string
  className?: string
  anchored?: boolean
  lazyIcon?: boolean
}

export const FormFieldConfirmFreshnessButton: FC<Props> = flowMax(
  addDisplayName('FormFieldConfirmFreshnessButton'),
  addFormContext,
  addFieldsFreshnessContext,
  addFormFieldStatusesContext,
  addProps(
    ({fieldsFreshness, name}) => {
      const confirmed = getFieldsFreshnessConfirmed(fieldsFreshness, name)
      return {
        confirmed,
        text:
          !confirmed &&
          getLastConfirmedWeeks(
            getFieldsFreshnessUpdatedAt(fieldsFreshness, name)
          ),
      }
    },
    ['fieldsFreshness', 'name']
  ),
  addProps(
    ({formFieldStatuses, name, lazyIcon}) => ({
      status: lazyIcon
        ? getFormFeedbackIconStatus({formFieldStatuses, name})
        : undefined,
    }),
    ['formFieldStatuses', 'name', 'lazyIcon']
  ),
  addProps(
    ({lazyIcon, status}) => ({
      noIcon: lazyIcon && !status,
    }),
    ['lazyIcon', 'status']
  ),
  addMemoBoundary(['confirmed', 'text', 'noIcon', 'name']),
  addClasses(classes),
  addHandlers({
    onToggle: ({toggleFreshnessConfirmed, name}) => () => {
      toggleFreshnessConfirmed(name)
    },
  }),
  ({onToggle, text, anchored, noIcon, classes, className, confirmed}) => (
    <div
      className={cx(
        className,
        anchored && classes.buttonAnchored,
        anchored && text && classes.buttonAnchoredWithText,
        anchored && noIcon && text && classes.buttonAnchoredNoIconWithText
      )}
    >
      <CheckmarkToggle
        onToggle={onToggle}
        isSelected={confirmed}
        className={classes.button}
      />
      <Caption className={classes.text}>{text}</Caption>
    </div>
  )
)
