import {makeFormSchema} from 'utils/form/schema'
import {
  makeTextField,
  makeDateField,
  makeTimeField,
  makeServiceTimeZoneField,
  makeFacilityField,
  makeDepartmentField,
  makeInsuranceTypeField,
  makeCurrencyField,
  makeNjmmisCheckField,
  makeMonthSelectField,
  makeYearSelectField,
  makeBooleanField,
} from 'utils/form/fieldTypes'
import {FacilityFields} from 'graphql/deserializedTypes/FacilityFields'
import {DepartmentFields} from 'graphql/deserializedTypes/DepartmentFields'

export const getEditAccountFormSchema = ({
  facilities,
  departments,
}: {
  facilities: FacilityFields[]
  departments: DepartmentFields[]
}) =>
  makeFormSchema({
    fields: {
      account: {
        id: makeTextField({
          isRequired: true,
        }),
        hospitalAccountId: makeTextField({
          isRequired: true,
        }),
        dateOfService: makeDateField({
          isRequired: true,
          defaultToToday: true,
        }),
        timeOfService: makeTimeField(),
        serviceTimeZone: makeServiceTimeZoneField(),
        facility: makeFacilityField({
          facilities,
          isRequired: true,
        }),
        department: makeDepartmentField({departments}),
        location: makeTextField(),
        insuranceType: makeInsuranceTypeField(),
        notes: makeTextField(),
        accountAmount: makeCurrencyField(),
        njmmisCheck: makeNjmmisCheckField(),
        previousInsurance: makeTextField(),
        invoiceMonth: makeMonthSelectField(),
        invoiceYear: makeYearSelectField(),
        dischargeDate: makeDateField(),
        isScheduled: makeBooleanField({
          isRequired: true,
        }),
      },
    },
  })

export const staticEditAccountFormSchema = getEditAccountFormSchema({
  facilities: [],
  departments: [],
})
