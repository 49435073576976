import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addStateHandlers,
  branch,
  returns,
  addWrapper,
} from 'ad-hok'

import {addAccountsQuery} from 'graphql/generated'
import {addLoadingIndicator} from 'utils/dataLoading'
import AccountItem from 'components/AccountItem'
import {WorklistNoResults} from 'components/ApplicationWorklist'
import {addTranslationHelpers} from 'utils/i18n'
import Heading from 'components/Heading'
import SearchInput from 'components/SearchInput'
import {makeClasses, addClasses} from 'theme'

const classes = makeClasses((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  topSectionContainer: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#f2f2f2',
  },
  header: {
    fontSize: 19,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),
  },
  searchInput: {
    width: 250,
  },
}))

const AccountSearch: FC = flowMax(
  addDisplayName('AccountSearch'),
  addStateHandlers(
    {
      search: '',
      searchExposed: '',
      hasTriggeredSearch: false,
    },
    {
      onSearchChange: () => (search: string) => ({
        search,
      }),
      onSearch: ({search}) => () =>
        search
          ? {
              hasTriggeredSearch: true,
              searchExposed: search,
            }
          : {},
      onClear: () => () => ({
        search: '',
        searchExposed: '',
        hasTriggeredSearch: false,
      }),
    }
  ),
  addTranslationHelpers,
  addClasses(classes),
  addWrapper(
    (render, {search, onSearch, onSearchChange, onClear, classes, t}) => (
      <section className={classes.container}>
        <div className={classes.topSectionContainer}>
          <Heading component="h3" className={classes.header}>
            {t('worklist.accountSearch.header')}
          </Heading>
          <SearchInput
            id="account-search"
            inputClassName={classes.searchInput}
            label={t('worklist.accountSearch.searchFieldLabel')}
            onChange={onSearchChange}
            onSearch={onSearch}
            onClear={onClear}
            value={search}
          />
        </div>
        {render()}
      </section>
    )
  ),
  addAccountsQuery({
    variables: ({searchExposed: search}) => ({search}),
    skip: ({hasTriggeredSearch}) => !hasTriggeredSearch,
  }),
  addLoadingIndicator({}),
  branch(
    ({accounts}) => !accounts.length,
    returns(() => <WorklistNoResults />)
  ),
  ({accounts}) => (
    <>
      {accounts.map((account) => (
        <AccountItem account={account} key={account.id} />
      ))}
    </>
  )
)

export default AccountSearch
