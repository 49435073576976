import * as Sentry from '@sentry/browser'

import {sentryEnabled, sentryDsn} from 'config/sentry'
import {sentryEnvironment} from 'config/environment'

if (sentryEnabled) {
  Sentry.init({
    dsn: sentryDsn,
    environment: sentryEnvironment,
  })
}
