/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {
  ServiceType,
  InboundType,
  InboundInsuranceOnDateOfService,
  Benefit,
  NjmmisCheckStatus,
} from './globalTypes' // ====================================================
// GraphQL mutation operation: CompleteInbound
// ====================================================

import {CompleteInboundVariables as CompleteInboundVariablesOrig} from '../types/CompleteInbound'
import {CompleteInbound as CompleteInboundOrig} from '../types/CompleteInbound'
import {CompleteInbound_completeInbound as CompleteInbound_completeInboundOrig} from '../types/CompleteInbound'
import {CompleteInbound_completeInbound_account as CompleteInbound_completeInbound_accountOrig} from '../types/CompleteInbound'
import {CompleteInbound_completeInbound_mostRecentNjmmisCheck as CompleteInbound_completeInbound_mostRecentNjmmisCheckOrig} from '../types/CompleteInbound'
import {CompleteInbound_completeInbound_personFormData as CompleteInbound_completeInbound_personFormDataOrig} from '../types/CompleteInbound'
import {CompleteInbound_completeInbound_person as CompleteInbound_completeInbound_personOrig} from '../types/CompleteInbound'
import {AdditionalData} from 'utils/getAdditionalData'
import {parseAdditionalData} from 'utils/getAdditionalData'
import {serializeAdditionalData} from 'utils/getAdditionalData'
import formatISO from 'date-fns/formatISO'
import {parseDate} from 'utils/form/fieldTypes'
import {formatISODate} from 'utils/date'
import {CompleteInbound_completeInbound_person_mostRecentEligibilityDeterminations as CompleteInbound_completeInbound_person_mostRecentEligibilityDeterminationsOrig} from '../types/CompleteInbound'
import {BenefitType} from 'utils/benefits'
import {deserializeBenefit} from 'graphql/converters'
import {serializeBenefit} from 'graphql/converters'
import {CompleteInbound_completeInbound_person_openApplications as CompleteInbound_completeInbound_person_openApplicationsOrig} from '../types/CompleteInbound'
import {CompleteInbound_completeInbound_completedBy as CompleteInbound_completeInbound_completedByOrig} from '../types/CompleteInbound'
import {CompleteInbound_completeInbound_startedBy as CompleteInbound_completeInbound_startedByOrig} from '../types/CompleteInbound'
export interface CompleteInbound_completeInbound_startedBy {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeCompleteInbound_completeInbound_startedBy = (
  serialized: CompleteInbound_completeInbound_startedByOrig
): CompleteInbound_completeInbound_startedBy => ({...serialized})
export const serializeCompleteInbound_completeInbound_startedBy = (
  deserialized: CompleteInbound_completeInbound_startedBy
): CompleteInbound_completeInbound_startedByOrig => ({...deserialized})
export interface CompleteInbound_completeInbound_completedBy {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeCompleteInbound_completeInbound_completedBy = (
  serialized: CompleteInbound_completeInbound_completedByOrig
): CompleteInbound_completeInbound_completedBy => ({...serialized})
export const serializeCompleteInbound_completeInbound_completedBy = (
  deserialized: CompleteInbound_completeInbound_completedBy
): CompleteInbound_completeInbound_completedByOrig => ({...deserialized})
export interface CompleteInbound_completeInbound_person_openApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeCompleteInbound_completeInbound_person_openApplications = (
  serialized: CompleteInbound_completeInbound_person_openApplicationsOrig
): CompleteInbound_completeInbound_person_openApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeCompleteInbound_completeInbound_person_openApplications = (
  deserialized: CompleteInbound_completeInbound_person_openApplications
): CompleteInbound_completeInbound_person_openApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface CompleteInbound_completeInbound_person_mostRecentEligibilityDeterminations {
  __typename: 'EligibilityDetermination'
  id: string
  benefit: string
  status: string
  reasonOrInfo: string | null
  secondaryInfo: string | null
  householdSizeDescription: string | null
  month: Date | null
  numMonths: number | null
  createdAt: Date
  additionalData: AdditionalData | null
}
export const deserializeCompleteInbound_completeInbound_person_mostRecentEligibilityDeterminations = (
  serialized: CompleteInbound_completeInbound_person_mostRecentEligibilityDeterminationsOrig
): CompleteInbound_completeInbound_person_mostRecentEligibilityDeterminations => ({
  ...serialized,
  month:
    serialized.month != null ? parseDate(serialized.month) : serialized.month,
  createdAt: parseDate(serialized.createdAt),
  additionalData:
    serialized.additionalData != null
      ? parseAdditionalData(serialized.additionalData)
      : serialized.additionalData,
})
export const serializeCompleteInbound_completeInbound_person_mostRecentEligibilityDeterminations = (
  deserialized: CompleteInbound_completeInbound_person_mostRecentEligibilityDeterminations
): CompleteInbound_completeInbound_person_mostRecentEligibilityDeterminationsOrig => ({
  ...deserialized,
  month:
    deserialized.month != null
      ? formatISODate(deserialized.month)
      : deserialized.month,
  createdAt: formatISO(deserialized.createdAt),
  additionalData:
    deserialized.additionalData != null
      ? serializeAdditionalData(deserialized.additionalData)
      : deserialized.additionalData,
})
export interface CompleteInbound_completeInbound_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  middleName: string | null
  lastName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  openApplications: CompleteInbound_completeInbound_person_openApplications[]
  mostRecentEligibilityDeterminations: CompleteInbound_completeInbound_person_mostRecentEligibilityDeterminations[]
}
export const deserializeCompleteInbound_completeInbound_person = (
  serialized: CompleteInbound_completeInbound_personOrig
): CompleteInbound_completeInbound_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  openApplications: serialized.openApplications.map(
    deserializeCompleteInbound_completeInbound_person_openApplications
  ),
  mostRecentEligibilityDeterminations: serialized.mostRecentEligibilityDeterminations.map(
    deserializeCompleteInbound_completeInbound_person_mostRecentEligibilityDeterminations
  ),
})
export const serializeCompleteInbound_completeInbound_person = (
  deserialized: CompleteInbound_completeInbound_person
): CompleteInbound_completeInbound_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  openApplications: deserialized.openApplications.map(
    serializeCompleteInbound_completeInbound_person_openApplications
  ),
  mostRecentEligibilityDeterminations: deserialized.mostRecentEligibilityDeterminations.map(
    serializeCompleteInbound_completeInbound_person_mostRecentEligibilityDeterminations
  ),
})
export interface CompleteInbound_completeInbound_personFormData {
  __typename: 'InboundPersonFormData'
  firstName: string | null
  lastName: string | null
  dob: Date | null
  phoneNumber: string | null
}
export const deserializeCompleteInbound_completeInbound_personFormData = (
  serialized: CompleteInbound_completeInbound_personFormDataOrig
): CompleteInbound_completeInbound_personFormData => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
})
export const serializeCompleteInbound_completeInbound_personFormData = (
  deserialized: CompleteInbound_completeInbound_personFormData
): CompleteInbound_completeInbound_personFormDataOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
})
export interface CompleteInbound_completeInbound_mostRecentNjmmisCheck {
  __typename: 'NjmmisCheck'
  id: string
  status: NjmmisCheckStatus
  medicaidId: string | null
  eligibleServices: string[] | null
}
export const deserializeCompleteInbound_completeInbound_mostRecentNjmmisCheck = (
  serialized: CompleteInbound_completeInbound_mostRecentNjmmisCheckOrig
): CompleteInbound_completeInbound_mostRecentNjmmisCheck => ({...serialized})
export const serializeCompleteInbound_completeInbound_mostRecentNjmmisCheck = (
  deserialized: CompleteInbound_completeInbound_mostRecentNjmmisCheck
): CompleteInbound_completeInbound_mostRecentNjmmisCheckOrig => ({
  ...deserialized,
})
export interface CompleteInbound_completeInbound_account {
  __typename: 'Account'
  id: string
  department: string
  insuranceType: string
}
export const deserializeCompleteInbound_completeInbound_account = (
  serialized: CompleteInbound_completeInbound_accountOrig
): CompleteInbound_completeInbound_account => ({...serialized})
export const serializeCompleteInbound_completeInbound_account = (
  deserialized: CompleteInbound_completeInbound_account
): CompleteInbound_completeInbound_accountOrig => ({...deserialized})
export interface CompleteInbound_completeInbound {
  __typename: 'Inbound'
  id: string
  reason: string
  dateOfService: Date | null
  serviceType: ServiceType | null
  createdAt: Date
  lastIntakeWebformReceivedAt: Date | null
  inboundType: InboundType
  insuranceOnDateOfService: InboundInsuranceOnDateOfService | null
  startedBy: CompleteInbound_completeInbound_startedBy | null
  startedAt: Date | null
  completedBy: CompleteInbound_completeInbound_completedBy | null
  completedAt: Date | null
  person: CompleteInbound_completeInbound_person | null
  personFormData: CompleteInbound_completeInbound_personFormData | null
  mostRecentNjmmisCheck: CompleteInbound_completeInbound_mostRecentNjmmisCheck | null
  account: CompleteInbound_completeInbound_account | null
}
export const deserializeCompleteInbound_completeInbound = (
  serialized: CompleteInbound_completeInboundOrig
): CompleteInbound_completeInbound => ({
  ...serialized,
  dateOfService:
    serialized.dateOfService != null
      ? parseDate(serialized.dateOfService)
      : serialized.dateOfService,
  createdAt: parseDate(serialized.createdAt),
  lastIntakeWebformReceivedAt:
    serialized.lastIntakeWebformReceivedAt != null
      ? parseDate(serialized.lastIntakeWebformReceivedAt)
      : serialized.lastIntakeWebformReceivedAt,
  startedBy:
    serialized.startedBy != null
      ? deserializeCompleteInbound_completeInbound_startedBy(
          serialized.startedBy
        )
      : serialized.startedBy,
  startedAt:
    serialized.startedAt != null
      ? parseDate(serialized.startedAt)
      : serialized.startedAt,
  completedBy:
    serialized.completedBy != null
      ? deserializeCompleteInbound_completeInbound_completedBy(
          serialized.completedBy
        )
      : serialized.completedBy,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
  person:
    serialized.person != null
      ? deserializeCompleteInbound_completeInbound_person(serialized.person)
      : serialized.person,
  personFormData:
    serialized.personFormData != null
      ? deserializeCompleteInbound_completeInbound_personFormData(
          serialized.personFormData
        )
      : serialized.personFormData,
  mostRecentNjmmisCheck:
    serialized.mostRecentNjmmisCheck != null
      ? deserializeCompleteInbound_completeInbound_mostRecentNjmmisCheck(
          serialized.mostRecentNjmmisCheck
        )
      : serialized.mostRecentNjmmisCheck,
  account:
    serialized.account != null
      ? deserializeCompleteInbound_completeInbound_account(serialized.account)
      : serialized.account,
})
export const serializeCompleteInbound_completeInbound = (
  deserialized: CompleteInbound_completeInbound
): CompleteInbound_completeInboundOrig => ({
  ...deserialized,
  dateOfService:
    deserialized.dateOfService != null
      ? formatISODate(deserialized.dateOfService)
      : deserialized.dateOfService,
  createdAt: formatISO(deserialized.createdAt),
  lastIntakeWebformReceivedAt:
    deserialized.lastIntakeWebformReceivedAt != null
      ? formatISO(deserialized.lastIntakeWebformReceivedAt)
      : deserialized.lastIntakeWebformReceivedAt,
  startedBy:
    deserialized.startedBy != null
      ? serializeCompleteInbound_completeInbound_startedBy(
          deserialized.startedBy
        )
      : deserialized.startedBy,
  startedAt:
    deserialized.startedAt != null
      ? formatISO(deserialized.startedAt)
      : deserialized.startedAt,
  completedBy:
    deserialized.completedBy != null
      ? serializeCompleteInbound_completeInbound_completedBy(
          deserialized.completedBy
        )
      : deserialized.completedBy,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
  person:
    deserialized.person != null
      ? serializeCompleteInbound_completeInbound_person(deserialized.person)
      : deserialized.person,
  personFormData:
    deserialized.personFormData != null
      ? serializeCompleteInbound_completeInbound_personFormData(
          deserialized.personFormData
        )
      : deserialized.personFormData,
  mostRecentNjmmisCheck:
    deserialized.mostRecentNjmmisCheck != null
      ? serializeCompleteInbound_completeInbound_mostRecentNjmmisCheck(
          deserialized.mostRecentNjmmisCheck
        )
      : deserialized.mostRecentNjmmisCheck,
  account:
    deserialized.account != null
      ? serializeCompleteInbound_completeInbound_account(deserialized.account)
      : deserialized.account,
})
export interface CompleteInbound {
  /**
   * Complete inbound
   */
  completeInbound: CompleteInbound_completeInbound
}
export const deserializeCompleteInbound = (
  serialized: CompleteInboundOrig
): CompleteInbound => ({
  ...serialized,
  completeInbound: deserializeCompleteInbound_completeInbound(
    serialized.completeInbound
  ),
})
export const serializeCompleteInbound = (
  deserialized: CompleteInbound
): CompleteInboundOrig => ({
  ...deserialized,
  completeInbound: serializeCompleteInbound_completeInbound(
    deserialized.completeInbound
  ),
})
export interface CompleteInboundVariables {
  id: string
}
export const deserializeCompleteInboundVariables = (
  serialized: CompleteInboundVariablesOrig
): CompleteInboundVariables => ({...serialized})
export const serializeCompleteInboundVariables = (
  deserialized: CompleteInboundVariables
): CompleteInboundVariablesOrig => ({...deserialized})
