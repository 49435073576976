import {editMonitorApplicationFormSchema} from 'components/EditMonitorApplicationForm/schema'
import {makeFormSchema} from 'utils/form/schema'
import {
  makeTextField,
  makeDateField,
  makeSelectField,
  makeNumberField,
} from 'utils/form/fieldTypes'

const {notes} = editMonitorApplicationFormSchema.fields.application

export const editRyanWhiteApplicationFormSchema = makeFormSchema({
  fields: {
    application: {
      id: makeTextField({
        isRequired: true,
      }),
      initialDateOfService: makeDateField(),
      accountNumber: makeTextField(),
      receivedDate: makeDateField(),
      assignedToId: makeSelectField({}),
      notes,
      dateOfApplication: makeDateField(),
      coveragePeriod: makeNumberField(),
    },
  },
})
