/* eslint-disable */
import {FileTemplates as FileTemplatesOrig} from '../types/FileTemplates'
import {FileTemplates_fileTemplates as FileTemplates_fileTemplatesOrig} from '../types/FileTemplates'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: FileTemplates
// ====================================================
export interface FileTemplates_fileTemplates {
  __typename: 'FileTemplate'
  id: string
  name: string
  fileKey: string
  allowSigning: boolean | null
  documentSendType: string | null
}
export const deserializeFileTemplates_fileTemplates = (
  serialized: FileTemplates_fileTemplatesOrig
): FileTemplates_fileTemplates => ({...serialized})
export const serializeFileTemplates_fileTemplates = (
  deserialized: FileTemplates_fileTemplates
): FileTemplates_fileTemplatesOrig => ({...deserialized})
export interface FileTemplates {
  /**
   * List of file templates
   */
  fileTemplates: FileTemplates_fileTemplates[]
}
export const deserializeFileTemplates = (
  serialized: FileTemplatesOrig
): FileTemplates => ({
  ...serialized,
  fileTemplates: serialized.fileTemplates.map(
    deserializeFileTemplates_fileTemplates
  ),
})
export const serializeFileTemplates = (
  deserialized: FileTemplates
): FileTemplatesOrig => ({
  ...deserialized,
  fileTemplates: deserialized.fileTemplates.map(
    serializeFileTemplates_fileTemplates
  ),
})
