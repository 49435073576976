import React, {FC} from 'react'
import {flowMax, addDisplayName, addMemoBoundary, addProps} from 'ad-hok'
import {generatePath} from 'react-router'
import {branchIfFalsy} from 'ad-hok-utils'

import {addRightColumnContext} from 'components/EditPersonForm/rightColumnContext'
import {addClasses, makeClasses} from 'theme'
import Grid from 'components/Grid'
import {addTranslationHelpers} from 'utils/i18n'
import {
  getLastChecked,
  LastChecked,
} from 'components/ApplicationEligibilityCheckList'
import Paper from 'components/Paper'
import Body1 from 'components/Body1'
import TriageStatusUpdateSelect from 'components/TriageStatusUpdateSelect'
import {TRIAGE_STATUS_CLOSED} from 'utils/triageStatuses'
import {PERSON_QUERY} from 'graphql/queries'
import Subtitle2 from 'components/Subtitle2'
import EligibilityDeterminationPreliminary from 'components/EditPersonForm/EligibilityDeterminationPreliminary'
import Trans from 'components/Trans'
import Link from 'components/Link'
import {editAccountPath} from 'components/TopLevelRoutes'
import {Person_person_openTriages} from 'graphql/deserializedTypes/Person'
import {addShowTriage} from 'utils/configContext'

const classes = makeClasses((theme) => ({
  triageContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  triagesHeader: {
    marginBottom: theme.spacing(2),
  },
  checkEligibilityButton: {
    maxWidth: 175,
    lineHeight: 1.2,
    padding: '3px 8px',
    marginTop: theme.spacing(1),
  },
  triageTopRowContainer: {
    marginBottom: theme.spacing(2),
  },
  triageTitle: {
    fontWeight: 500,
  },
  triageEligibilityCheckContainer: {
    paddingLeft: theme.spacing(2),
  },
}))

const TriageEligibilityDeterminations: FC = flowMax(
  addDisplayName('TriageEligibilityDeterminations'),
  addRightColumnContext,
  addMemoBoundary(['determinedEligibilitiesPreliminary']),
  addClasses(classes),
  ({determinedEligibilitiesPreliminary, classes}) => (
    <Grid item className={classes.triageEligibilityCheckContainer}>
      {determinedEligibilitiesPreliminary?.map((determinedEligibility) => (
        <EligibilityDeterminationPreliminary
          determinedEligibility={determinedEligibility}
          key={determinedEligibility.name}
        />
      ))}
    </Grid>
  )
)

type TriageType = Person_person_openTriages

interface OpenTriageProps {
  triage: TriageType
}

const OpenTriage: FC<OpenTriageProps> = flowMax(
  addDisplayName('OpenTriage'),
  addRightColumnContext,
  addMemoBoundary(['triage', 'person.id']),
  addTranslationHelpers,
  addProps(
    ({triage: {eligibilityDeterminations}, t}) => ({
      lastChecked: getLastChecked({eligibilityDeterminations, t}),
    }),
    ['triage', 't']
  ),
  addClasses(classes),
  ({
    triage: {
      id,
      account: {hospitalAccountId},
      account,
    },
    triage,
    person,
    lastChecked,
    classes,
  }) => (
    <Grid item data-testid={`personForm-eligibilityCheck-triage-${id}`}>
      <Paper className={classes.triageContainer}>
        <Grid container direction="column">
          <Grid item className={classes.triageTopRowContainer}>
            <Grid container direction="row">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Body1 className={classes.triageTitle}>
                  <Trans i18nKey="personForm.eligibilityCheck.triage">
                    <Link
                      highlight
                      to={generatePath(editAccountPath, {id: account.id})}
                    />
                    {{id: hospitalAccountId}}
                  </Trans>
                </Body1>
                <TriageStatusUpdateSelect
                  triage={triage}
                  refetchQueries={(status) =>
                    status === TRIAGE_STATUS_CLOSED
                      ? [
                          {
                            query: PERSON_QUERY,
                            variables: {id: person.id},
                          },
                        ]
                      : []
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <TriageEligibilityDeterminations />
          <Grid container direction="row" justify="space-between">
            <LastChecked text={lastChecked} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
)

const OpenTriages: FC = flowMax(
  addDisplayName('OpenTriages'),
  addShowTriage,
  branchIfFalsy('showTriage'),
  addRightColumnContext,
  addMemoBoundary(['person.openTriages']),
  addTranslationHelpers,
  addClasses(classes),
  ({person: {openTriages}, t, classes}) => (
    <>
      <Grid item>
        <Subtitle2 className={classes.triagesHeader}>
          {t('personForm.eligibilityCheck.triages')}
        </Subtitle2>
      </Grid>
      {openTriages.map((triage) => (
        <OpenTriage triage={triage} key={triage.id} />
      ))}
    </>
  )
)

export default OpenTriages
