/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {EditableFileStatus} from './globalTypes' // ====================================================
// GraphQL mutation operation: MergeEditableFiles
// ====================================================

import {MergeEditableFilesVariables as MergeEditableFilesVariablesOrig} from '../types/MergeEditableFiles'
import {MergeEditableFiles as MergeEditableFilesOrig} from '../types/MergeEditableFiles'
import {MergeEditableFiles_mergeEditableFiles as MergeEditableFiles_mergeEditableFilesOrig} from '../types/MergeEditableFiles'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {MergeEditableFiles_mergeEditableFiles_sentFax as MergeEditableFiles_mergeEditableFiles_sentFaxOrig} from '../types/MergeEditableFiles'
import {MergeEditableFiles_mergeEditableFiles_sentFax_countyFax as MergeEditableFiles_mergeEditableFiles_sentFax_countyFaxOrig} from '../types/MergeEditableFiles'
import {MergeEditableFiles_mergeEditableFiles_sentFax_countyFax_state as MergeEditableFiles_mergeEditableFiles_sentFax_countyFax_stateOrig} from '../types/MergeEditableFiles'
export interface MergeEditableFiles_mergeEditableFiles_sentFax_countyFax_state {
  __typename: 'State'
  stateCode: string
}
export const deserializeMergeEditableFiles_mergeEditableFiles_sentFax_countyFax_state = (
  serialized: MergeEditableFiles_mergeEditableFiles_sentFax_countyFax_stateOrig
): MergeEditableFiles_mergeEditableFiles_sentFax_countyFax_state => ({
  ...serialized,
})
export const serializeMergeEditableFiles_mergeEditableFiles_sentFax_countyFax_state = (
  deserialized: MergeEditableFiles_mergeEditableFiles_sentFax_countyFax_state
): MergeEditableFiles_mergeEditableFiles_sentFax_countyFax_stateOrig => ({
  ...deserialized,
})
export interface MergeEditableFiles_mergeEditableFiles_sentFax_countyFax {
  __typename: 'CountyFax'
  countyName: string
  state: MergeEditableFiles_mergeEditableFiles_sentFax_countyFax_state
}
export const deserializeMergeEditableFiles_mergeEditableFiles_sentFax_countyFax = (
  serialized: MergeEditableFiles_mergeEditableFiles_sentFax_countyFaxOrig
): MergeEditableFiles_mergeEditableFiles_sentFax_countyFax => ({
  ...serialized,
  state: deserializeMergeEditableFiles_mergeEditableFiles_sentFax_countyFax_state(
    serialized.state
  ),
})
export const serializeMergeEditableFiles_mergeEditableFiles_sentFax_countyFax = (
  deserialized: MergeEditableFiles_mergeEditableFiles_sentFax_countyFax
): MergeEditableFiles_mergeEditableFiles_sentFax_countyFaxOrig => ({
  ...deserialized,
  state: serializeMergeEditableFiles_mergeEditableFiles_sentFax_countyFax_state(
    deserialized.state
  ),
})
export interface MergeEditableFiles_mergeEditableFiles_sentFax {
  __typename: 'SentFax'
  toNumber: string | null
  faxStatus: string | null
  message: string | null
  updatedAt: Date
  countyFax: MergeEditableFiles_mergeEditableFiles_sentFax_countyFax
}
export const deserializeMergeEditableFiles_mergeEditableFiles_sentFax = (
  serialized: MergeEditableFiles_mergeEditableFiles_sentFaxOrig
): MergeEditableFiles_mergeEditableFiles_sentFax => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  countyFax: deserializeMergeEditableFiles_mergeEditableFiles_sentFax_countyFax(
    serialized.countyFax
  ),
})
export const serializeMergeEditableFiles_mergeEditableFiles_sentFax = (
  deserialized: MergeEditableFiles_mergeEditableFiles_sentFax
): MergeEditableFiles_mergeEditableFiles_sentFaxOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  countyFax: serializeMergeEditableFiles_mergeEditableFiles_sentFax_countyFax(
    deserialized.countyFax
  ),
})
export interface MergeEditableFiles_mergeEditableFiles {
  __typename: 'EditableFile'
  id: string
  fileKey: string
  filename: string
  updatedAt: Date
  status: EditableFileStatus
  allowSigning: boolean | null
  documentSendType: string | null
  faxEnabled: boolean | null
  sentFax: MergeEditableFiles_mergeEditableFiles_sentFax | null
}
export const deserializeMergeEditableFiles_mergeEditableFiles = (
  serialized: MergeEditableFiles_mergeEditableFilesOrig
): MergeEditableFiles_mergeEditableFiles => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  sentFax:
    serialized.sentFax != null
      ? deserializeMergeEditableFiles_mergeEditableFiles_sentFax(
          serialized.sentFax
        )
      : serialized.sentFax,
})
export const serializeMergeEditableFiles_mergeEditableFiles = (
  deserialized: MergeEditableFiles_mergeEditableFiles
): MergeEditableFiles_mergeEditableFilesOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  sentFax:
    deserialized.sentFax != null
      ? serializeMergeEditableFiles_mergeEditableFiles_sentFax(
          deserialized.sentFax
        )
      : deserialized.sentFax,
})
export interface MergeEditableFiles {
  mergeEditableFiles: MergeEditableFiles_mergeEditableFiles
}
export const deserializeMergeEditableFiles = (
  serialized: MergeEditableFilesOrig
): MergeEditableFiles => ({
  ...serialized,
  mergeEditableFiles: deserializeMergeEditableFiles_mergeEditableFiles(
    serialized.mergeEditableFiles
  ),
})
export const serializeMergeEditableFiles = (
  deserialized: MergeEditableFiles
): MergeEditableFilesOrig => ({
  ...deserialized,
  mergeEditableFiles: serializeMergeEditableFiles_mergeEditableFiles(
    deserialized.mergeEditableFiles
  ),
})
export interface MergeEditableFilesVariables {
  editableFileIds: string[]
  applicationId: string
}
export const deserializeMergeEditableFilesVariables = (
  serialized: MergeEditableFilesVariablesOrig
): MergeEditableFilesVariables => ({...serialized})
export const serializeMergeEditableFilesVariables = (
  deserialized: MergeEditableFilesVariables
): MergeEditableFilesVariablesOrig => ({...deserialized})
