import React, {FC, ChangeEvent} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addWrapper,
  addStateHandlers,
  addHandlers,
  addEffect,
} from 'ad-hok'
import {addExtendedHandlers, addInterval, branchIfFalsy} from 'ad-hok-utils'
import {format} from 'date-fns/fp'
import {truncate, without} from 'lodash/fp'
import {uniq} from 'lodash'
import cx from 'classnames'
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined'
import {useQuery} from '@apollo/react-hooks'

import FormSection from 'components/FormSection'
import {addApplicationFormContext} from 'components/EditApplicationForm/applicationFormContext'
import TableContainer from 'components/TableContainer'
import Table from 'components/Table'
import TableHead from 'components/TableHead'
import TableRow from 'components/TableRow'
import TableCell from 'components/TableCell'
import TableBody from 'components/TableBody'
import {addTranslationHelpers} from 'utils/i18n'
import {EditableFileFields} from 'graphql/deserializedTypes/EditableFileFields'
import typedAs from 'utils/typedAs'
import Checkbox from 'components/Checkbox'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import {
  addSendEditableFileToEmrMutation,
  addMergeEditableFilesMutation,
} from 'graphql/generated'
import EditPdfDialog, {
  EditableFileWithSignedUrl,
} from 'components/EditPdfDialog'
import {addFetchDocumentFileUrl} from 'components/DocumentFileLink'
import {
  addEditableFilesQuery,
  addMarkEditableFileReviewedMutation,
  addCreateNjmmisCaptureResultMutation,
} from 'graphql/generated'
import {addLoadingIndicator} from 'utils/dataLoading'
import LinkButton from 'components/LinkButton'
import {EditableFiles_editableFiles} from 'graphql/deserializedTypes/EditableFiles'
import {makeClasses, addClasses} from 'theme'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import {
  EDITABLE_FILES_QUERY,
  NJMMIS_CAPTURE_RESULT_QUERY,
} from 'graphql/queries'
import addPrevious from 'utils/addPrevious'
import {getShouldShowEditableFilesSections} from 'utils/editableFiles'
import AddButton from 'components/AddButton'
import addDialogState from 'utils/addDialogState'
import UploadEditableFileDialog from 'components/UploadEditableFileDialog'
import Body1 from './Body1'

const classes = makeClasses((theme) => ({
  listContainer: {
    marginBottom: theme.spacing(2),
    overflowX: 'initial',
  },
  buttonsRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  unreviewed: {
    fontWeight: 700,
  },
  reviewed: {
    fontWeight: 400,
  },
  button: {
    marginRight: theme.spacing(2),
  },
  merging: {
    opacity: 0.5,
    pointerEvents: 'none',
    cursor: 'auto',
  },
  mergingMessage: {
    position: 'absolute',
    bottom: 3,
    left: 24,
    color: '#333333',
    fontSize: 14,
    fontWeight: 700,
  },
  checkboxCell: {
    paddingLeft: 0,
  },
  filenameCell: {
    position: 'relative',
    paddingLeft: 0,
  },
  njmmisCaptureButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  njmmisCaptureFailed: {
    color: 'red',
    marginLeft: theme.spacing(2),
  },
}))

const capitalizeFirstLowercaseRest = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

const MergingMessage: FC = flowMax(
  addDisplayName('MergingMessage'),
  addClasses(classes),
  addTranslationHelpers,
  ({classes, t}) => (
    <span className={classes.mergingMessage}>{t('editableFiles.merging')}</span>
  )
)

interface CheckboxCellProps {
  selected: boolean
  onSelect: () => void
  onUnselect: () => void
}

const CheckboxCell: FC<CheckboxCellProps> = flowMax(
  addDisplayName('CheckboxCell'),
  addHandlers({
    onChange: ({onSelect, onUnselect}) => ({
      target: {checked},
    }: ChangeEvent<HTMLInputElement>) => {
      if (checked) {
        onSelect()
      } else {
        onUnselect()
      }
    },
  }),
  addClasses(classes),
  ({selected, onChange, classes}) => (
    <TableCell className={classes.checkboxCell}>
      <Checkbox checked={selected} onChange={onChange} />
    </TableCell>
  )
)

interface FilenameLinkProps {
  editableFile: EditableFiles_editableFiles
  onClick: () => void
}

const FilenameLink: FC<FilenameLinkProps> = flowMax(
  addDisplayName('FilenameLink'),
  addProps(({editableFile: {filename}}) => ({
    filenameFormatted: truncate({length: 35}, filename),
  })),
  addClasses(classes),
  ({
    onClick,
    editableFile: {isUnreviewed, filename, sentFax},
    filenameFormatted,
    classes,
  }) => (
    <Tooltip
      title={
        sentFax
          ? `Fax (${filename}) sent to: ${sentFax.countyFax.countyName} (${
              sentFax.countyFax.state.stateCode
            }) / Status: ${
              sentFax.faxStatus
                ? capitalizeFirstLowercaseRest(sentFax.faxStatus)
                : 'Sending'
            } ${
              sentFax.faxStatus !== null &&
              sentFax.faxStatus !== '' &&
              sentFax.faxStatus !== 'sending'
                ? format('M/d/yy HH:mm')(sentFax.updatedAt)
                : ''
            }`
          : filename
      }
    >
      <span>
        <LinkButton
          onClick={onClick}
          className={isUnreviewed ? classes.unreviewed : classes.reviewed}
        >
          {filenameFormatted}
        </LinkButton>
      </span>
    </Tooltip>
  )
)

interface EditableFileProps {
  editableFile: EditableFiles_editableFiles
  onFilenameClick: () => void
  selected: boolean
  onSelect: () => void
  onUnselect: () => void
}

const EditableFile: FC<EditableFileProps> = flowMax(
  addDisplayName('EditableFile'),
  addProps(({editableFile: {updatedAt, status}}) => ({
    updatedAtFormatted: format('M/d/yy HH:mm')(updatedAt),
    isMerging: status === 'merging',
  })),
  addApplicationFormContext,
  addMarkEditableFileReviewedMutation({}),
  addExtendedHandlers({
    onFilenameClick: ({
      mutateMarkEditableFileReviewed,
      editableFile: {id: editableFileId, isUnreviewed},
      application: {id: applicationId},
    }) => () => {
      if (!isUnreviewed) return

      mutateMarkEditableFileReviewed({
        variables: {
          editableFileId,
          applicationId,
        },
      })
    },
  }),
  addClasses(classes),
  ({
    updatedAtFormatted,
    onFilenameClick,
    selected,
    onSelect,
    onUnselect,
    editableFile: {isUnreviewed},
    editableFile,
    isMerging,
    classes,
  }) => (
    <TableRow className={cx(isMerging && classes.merging)}>
      <CheckboxCell
        selected={selected}
        onSelect={onSelect}
        onUnselect={onUnselect}
      />
      <TableCell className={classes.filenameCell}>
        <FilenameLink editableFile={editableFile} onClick={onFilenameClick} />
        {isMerging && <MergingMessage />}
      </TableCell>
      <TableCell
        className={isUnreviewed ? classes.unreviewed : classes.reviewed}
      >
        {updatedAtFormatted}
      </TableCell>
    </TableRow>
  )
)

interface PollWhileMergingProps {
  refetch: () => void
  interval?: number
}

const Polling: FC<PollWhileMergingProps> = flowMax(
  addDisplayName('Polling'),
  addInterval(
    ({refetch}) => () => {
      refetch()
    },
    ({interval = 3 * 1000}) => interval
  ),
  () => null
)

type AddPollingWhileMerging = <
  TProps extends {
    editableFiles: EditableFileFields[]
    refetch: () => void
  }
>(
  props: TProps
) => TProps

const addPollingWhileMerging: AddPollingWhileMerging = flowMax(
  addProps(
    ({editableFiles}) => ({
      mergingEditableFileId: editableFiles.find(
        ({status}) => status === 'merging'
      )?.id,
    }),
    ['editableFiles']
  ),
  addPrevious('mergingEditableFileId', 'mergingEditableFileIdPrevious'),
  addTranslationHelpers,
  addEffect(
    ({editableFiles, mergingEditableFileIdPrevious, t}) => () => {
      if (!mergingEditableFileIdPrevious) return
      if (editableFiles.some(({id}) => id === mergingEditableFileIdPrevious))
        return
      window.alert(t('editableFiles.mergeFailed'))
    },
    ['editableFiles']
  ),
  addWrapper((render, {mergingEditableFileId, refetch}) => (
    <>
      {render()}
      {!!mergingEditableFileId && <Polling refetch={refetch} />}
    </>
  ))
)

interface EditableFilesListProps {
  selectedEditableFileIds: string[]
  selectEditableFileId: (editableFileId: string) => void
  unselectEditableFileId: (editableFileId: string) => void
  renderControls: (a: any) => void
}

const EditableFilesList: FC<EditableFilesListProps> = flowMax(
  addDisplayName('EditableFilesList'),
  addClasses(classes),
  addTranslationHelpers,
  addApplicationFormContext,
  addProps(({application: {id: applicationId}}) => ({
    variables: {applicationId},
  })),
  addEditableFilesQuery({
    variables: ({variables}) => variables,
  }),
  addLoadingIndicator({}),
  addHandlers({
    refetch: ({variables, refetchEditableFiles}) => () => {
      refetchEditableFiles(variables)
    },
  }),
  addInterval(
    ({refetch}) => () => {
      refetch()
    },
    30 * 1000,
    ['variables']
  ),
  addPollingWhileMerging,
  addStateHandlers(
    {
      selectedFile: typedAs<EditableFileWithSignedUrl | null>(null),
    },
    {
      setSelectedFile: () => (selectedFile: EditableFileWithSignedUrl) => ({
        selectedFile,
      }),
      onEditPdfDialogClose: () => () => ({
        selectedFile: null,
      }),
    }
  ),
  addFetchDocumentFileUrl,
  addHandlers({
    onSelect: ({fetchDocumentFileUrl, setSelectedFile}) => async (
      editableFile: EditableFileFields
    ) => {
      const signedUrl = await fetchDocumentFileUrl({
        fileKey: editableFile.fileKey,
      })
      setSelectedFile({...editableFile, signedUrl})
    },
  }),
  ({
    application: {
      id: applicationId,
      person: {id: personId},
    },
    classes,
    t,
    application,
    editableFiles,
    selectedFile,
    onSelect,
    onEditPdfDialogClose,
    selectedEditableFileIds,
    selectEditableFileId,
    unselectEditableFileId,
    refetch,
    renderControls,
  }) => (
    <>
      {editableFiles.length > 0 && (
        <>
          <TableContainer className={classes.listContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.checkboxCell} />
                  <TableCell className={classes.filenameCell}>
                    {t('editableFiles.filename')}
                  </TableCell>
                  <TableCell>{t('editableFiles.updatedAt')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {editableFiles.map((editableFile) => (
                  <EditableFile
                    editableFile={editableFile}
                    onFilenameClick={() => {
                      onSelect(editableFile)
                    }}
                    selected={selectedEditableFileIds.includes(editableFile.id)}
                    onSelect={() => selectEditableFileId(editableFile.id)}
                    onUnselect={() => unselectEditableFileId(editableFile.id)}
                    key={editableFile.id}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <EditPdfDialog
            personId={personId}
            benefit={application.benefit}
            applicationId={applicationId}
            faxEnabled={selectedFile?.faxEnabled}
            file={selectedFile}
            onClose={onEditPdfDialogClose}
            onDelete={
              selectedFile
                ? () => unselectEditableFileId(selectedFile.id)
                : undefined
            }
          />
        </>
      )}

      {renderControls({refetch})}
    </>
  )
)

interface MergeButtonProps {
  disabled: boolean
  onClick: () => void
}

const MergeButton: FC<MergeButtonProps> = flowMax(
  addDisplayName('MergeButton'),
  addTranslationHelpers,
  addWrapper((render, {disabled, t}) =>
    disabled ? (
      <Tooltip title={t('editableFiles.mergeDisabled')}>
        <div>{render()}</div>
      </Tooltip>
    ) : (
      <>{render()}</>
    )
  ),
  addClasses(classes),
  ({disabled, onClick, classes, t}) => (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      className={classes.button}
    >
      {t('editableFiles.merge')}
    </Button>
  )
)

interface SendToEmrButtonProps {
  disabled: boolean
  onClick: () => void
}

const SendToEmrButton: FC<SendToEmrButtonProps> = flowMax(
  addDisplayName('SendToEmrButton'),
  addTranslationHelpers,
  addWrapper((render, {disabled, t}) =>
    disabled ? (
      <Tooltip title={t('editableFiles.sendToEmrDisabled')}>
        <div>{render()}</div>
      </Tooltip>
    ) : (
      <>{render()}</>
    )
  ),
  addClasses(classes),
  ({disabled, onClick, classes, t}) => (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      className={classes.button}
    >
      {t('editableFiles.sendToEmr')}
    </Button>
  )
)

interface CaptureNjmmisResultsButtonProps {
  applicationId: string
  refetchEditableFiles: () => void
}

const CaptureNjmmisResultsButton: FC<CaptureNjmmisResultsButtonProps> = flowMax(
  addDisplayName('CaptureNjmmisResultsButton'),
  addTranslationHelpers,
  addClasses(classes),
  addCreateNjmmisCaptureResultMutation({}),
  ({
    t,
    classes,
    applicationId,
    mutateCreateNjmmisCaptureResult,
    refetchEditableFiles,
  }) => {
    const [
      {njmmisCaptureResultId, njmmisCaptureFailed},
      setState,
    ] = React.useState({
      njmmisCaptureResultId: null as string | null,
      njmmisCaptureFailed: false,
    })

    const {refetch: refetchQuery} = useQuery(NJMMIS_CAPTURE_RESULT_QUERY, {
      skip: true,
    })

    const onClick = () => {
      mutateCreateNjmmisCaptureResult({variables: {applicationId}}).then(
        ({data}) => {
          if (data) {
            setState((state) => ({
              ...state,
              njmmisCaptureResultId: data.createNjmmisCaptureResult.id,
              njmmisCaptureFailed: false,
            }))
          }
        }
      )
    }

    const refetch = React.useCallback(() => {
      if (njmmisCaptureResultId) {
        refetchQuery({id: njmmisCaptureResultId}).then(({data}) => {
          if (data?.njmmisCaptureResult?.status) {
            const {status} = data.njmmisCaptureResult
            if (status === 'pending') return

            if (status === 'success') {
              refetchEditableFiles()
            }

            setState((state) => ({
              ...state,
              njmmisCaptureResultId: null,
              njmmisCaptureFailed: status === 'failed',
            }))
          }
        })
      }
    }, [njmmisCaptureResultId, refetchQuery, refetchEditableFiles])

    React.useEffect(() => {
      if (!njmmisCaptureResultId) {
        return
      }

      const intervalId = setInterval(() => {
        refetch()
      }, 3 * 1000)

      return () => {
        clearInterval(intervalId)
      }
    }, [njmmisCaptureResultId, refetch])

    return (
      <div className={classes.njmmisCaptureButton}>
        <Button
          onClick={onClick}
          color="primary"
          startIcon={<DesktopWindowsOutlinedIcon />}
          disabled={!!njmmisCaptureResultId}
        >
          {njmmisCaptureResultId
            ? t('editableFiles.capturingNjmmisResults')
            : t('editableFiles.captureNjmmisResults')}
        </Button>
        {njmmisCaptureFailed && (
          <Body1 className={classes.njmmisCaptureFailed}>
            {t('editableFiles.captureNjmmisFailed')}
          </Body1>
        )}
      </div>
    )
  }
)

const EditableFilesSection: FC = flowMax(
  addDisplayName('EditableFilesSection'),
  addApplicationFormContext,
  addProps(
    ({application}) => ({
      shouldShow: getShouldShowEditableFilesSections(application),
    }),
    ['application']
  ),
  branchIfFalsy('shouldShow'),
  addStateHandlers(
    {
      selectedEditableFileIds: typedAs<string[]>([]),
    },
    {
      selectEditableFileId: ({selectedEditableFileIds}) => (
        editableFileId: string
      ) => ({
        selectedEditableFileIds: uniq([
          ...selectedEditableFileIds,
          editableFileId,
        ]),
      }),
      unselectEditableFileId: ({selectedEditableFileIds}) => (
        editableFileId: string
      ) => ({
        selectedEditableFileIds: without(
          [editableFileId],
          selectedEditableFileIds
        ),
      }),
      clearSelectedEditableFileIds: () => () => ({
        selectedEditableFileIds: [],
      }),
    }
  ),
  addProps(({selectedEditableFileIds}) => ({
    isSendToEmrButtonDisabled: selectedEditableFileIds.length !== 1,
    isMergeButtonDisabled: selectedEditableFileIds.length < 2,
  })),
  addSendEditableFileToEmrMutation({}),
  addMergeEditableFilesMutation({
    refetchQueries: ({application: {id: applicationId}}) => [
      {
        query: EDITABLE_FILES_QUERY,
        variables: {
          applicationId,
        },
      },
    ],
  }),
  addAppSnackbarContext,
  addTranslationHelpers,
  addHandlers({
    onSendToEmrClick: ({
      mutateSendEditableFileToEmr,
      selectedEditableFileIds,
      clearSelectedEditableFileIds,
      showSnackbarMessage,
      t,
    }) => () => {
      mutateSendEditableFileToEmr({
        variables: {
          id: selectedEditableFileIds[0],
        },
      }).then(() => {
        clearSelectedEditableFileIds()
        showSnackbarMessage(t('editableFiles.sentToEmr'))
      })
    },
    onMergeClick: ({
      mutateMergeEditableFiles,
      selectedEditableFileIds,
      clearSelectedEditableFileIds,
      application: {id: applicationId},
    }) => () => {
      mutateMergeEditableFiles({
        variables: {
          editableFileIds: selectedEditableFileIds,
          applicationId,
        },
      }).then(() => {
        clearSelectedEditableFileIds()
      })
    },
  }),
  addDialogState,
  addClasses(classes),
  ({
    selectedEditableFileIds,
    selectEditableFileId,
    unselectEditableFileId,
    isSendToEmrButtonDisabled,
    onSendToEmrClick,
    onMergeClick,
    isMergeButtonDisabled,
    application: {
      id: applicationId,
      person: {id: personId},
    },
    isShowingDialog,
    showDialog,
    hideDialog,
    classes,
    t,
  }) => (
    <>
      <FormSection labelTranslationKey="editableFiles" shouldMemoize={false}>
        <EditableFilesList
          selectedEditableFileIds={selectedEditableFileIds}
          selectEditableFileId={selectEditableFileId}
          unselectEditableFileId={unselectEditableFileId}
          renderControls={({refetch}) => (
            <>
              <div className={classes.buttonsRowContainer}>
                <MergeButton
                  onClick={onMergeClick}
                  disabled={isMergeButtonDisabled}
                />
                <SendToEmrButton
                  onClick={onSendToEmrClick}
                  disabled={isSendToEmrButtonDisabled}
                />
                <AddButton onClick={showDialog}>
                  {t('editableFiles.addButton')}
                </AddButton>
              </div>
              <CaptureNjmmisResultsButton
                applicationId={applicationId}
                refetchEditableFiles={refetch}
              />
            </>
          )}
        />
      </FormSection>
      <UploadEditableFileDialog
        personId={personId}
        applicationId={applicationId}
        open={isShowingDialog}
        onClose={hideDialog}
        uploadInstructionsText={t('documentUpload.instructions')}
        refetchQueriesOnCreateEditableFileSuccess={[
          {
            query: EDITABLE_FILES_QUERY,
            variables: {applicationId},
          },
        ]}
      />
    </>
  )
)

export default EditableFilesSection
