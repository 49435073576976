import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addWrapper,
  addMemoBoundary,
  branch,
  renderNothing,
  addEffect,
  addRef,
} from 'ad-hok'
import {generatePath} from 'react-router'
import {sortBy} from 'lodash/fp'
import cx from 'classnames'

import {FormValues, ExtractFormSchemaFields} from 'utils/form/schema'
import {editPersonFormSchema} from 'components/EditPersonForm/schema'
import {addTranslationHelpers} from 'utils/i18n'
import {addClasses, makeClasses} from 'theme'
import FormSection from 'components/FormSection'
import {addDocumentsSectionContext} from 'components/EditPersonForm/documentsSectionContext'
import {RequiredDocument} from 'components/EditPersonForm/documents'
import Grid from 'components/Grid'
import Body1 from 'components/Body1'
import {addFormikTyped} from 'utils/form/formik'
import Link from 'components/Link'
import {personDetailPath} from 'components/PersonDetail/index'
import {getExtendedName} from 'utils/name'
import {editApplicationPath} from 'components/TopLevelRoutes'
import {getBenefitName, getApplicationShortName} from 'utils/application'
import typedAs from 'utils/typedAs'
import {
  PersistedDocument,
  ReconciledDocument,
} from 'utils/persistedDocumentTypes'
import PersistedDocumentCheckMarkOrNecessaryIcon from 'components/PersistedDocumentCheckMarkOrNecessaryIcon'
import {
  Person_person_openApplications,
  Person_person,
} from 'graphql/deserializedTypes/Person'
import {addShowDocumentFiles} from 'utils/configContext'

const classes = makeClasses((theme) => ({
  documentType: {
    flex: 1,
    marginRight: theme.spacing(1),
    lineHeight: 1.3,
  },
  applicationName: {
    width: 130,
    lineHeight: 1.3,
  },
  documentContainer: {
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  personContainer: {
    marginBottom: theme.spacing(1),
  },
  personHeader: {
    display: 'block',
    marginBottom: theme.spacing(0.5),
  },
  hidden: {
    display: 'none',
  },
  personHeaderNotLink: {
    fontWeight: 500,
  },
}))

interface DocumentWithResolvedNames {
  documentType: string
  isNecessary: boolean
  personName: string
  applicationName: string
}

type PersonFormValues = FormValues<
  ExtractFormSchemaFields<typeof editPersonFormSchema>
>
type PersonType =
  | PersonFormValues['person']
  | PersonFormValues['person']['relationships'][number]['otherPerson']

export const getReconciledDocuments = ({
  persistedDocuments,
  requiredDocuments,
}: {
  persistedDocuments: PersistedDocument[]
  requiredDocuments: RequiredDocument[]
}): ReconciledDocument[] => {
  const reconciledRequiredDocuments = requiredDocuments.map(
    (requiredDocument) => ({
      documentType: requiredDocument.documentType,
      requiredDocument,
      persistedDocument: persistedDocuments.find(
        ({documentType, application: {id: applicationId}, person}) =>
          documentType === requiredDocument.documentType &&
          applicationId === requiredDocument.applicationId &&
          ((!person?.id && !requiredDocument.personId) ||
            person?.id === requiredDocument.personId)
      ),
    })
  )
  const nonrequiredPersistedDocuments = persistedDocuments
    .filter(
      ({id}) =>
        !reconciledRequiredDocuments.some(
          (reconciledRequiredDocument) =>
            reconciledRequiredDocument.persistedDocument?.id === id
        )
    )
    .map((persistedDocument) => ({
      documentType: persistedDocument.documentType,
      persistedDocument,
    }))
  return [...reconciledRequiredDocuments, ...nonrequiredPersistedDocuments]
}

interface PersonDocumentProps {
  document: ReconciledDocument
}

const PersonDocument: FC<PersonDocumentProps> = flowMax(
  addDisplayName('PersonDocument'),
  addDocumentsSectionContext,
  addProps(
    ({
      person: {openApplications},
      document: {requiredDocument, persistedDocument},
    }) => ({
      application: openApplications.find(
        ({id}) =>
          (requiredDocument?.applicationId ||
            persistedDocument?.application.id)! === id
      ),
    }),
    ['person.openApplications', 'document']
  ),
  addProps(
    ({application}) => ({
      applicationName: application ? getApplicationShortName(application) : '',
    }),
    ['application']
  ),
  addClasses(classes),
  ({document: {documentType, persistedDocument}, applicationName, classes}) => (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      className={classes.documentContainer}
    >
      <Body1 className={classes.documentType}>{documentType}</Body1>
      <Body1 className={classes.applicationName}>{applicationName}</Body1>
      <PersistedDocumentCheckMarkOrNecessaryIcon
        persistedDocument={persistedDocument}
      />
    </Grid>
  )
)

interface ApplicationDocumentProps {
  document: ReconciledDocument
}

const ApplicationDocument: FC<ApplicationDocumentProps> = flowMax(
  addDisplayName('ApplicationDocument'),
  addClasses(classes),
  ({document: {documentType, persistedDocument}, classes}) => (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      className={classes.documentContainer}
    >
      <Body1 className={classes.documentType}>{documentType}</Body1>
      <PersistedDocumentCheckMarkOrNecessaryIcon
        persistedDocument={persistedDocument}
      />
    </Grid>
  )
)

interface PersonHeaderProps {
  person: PersonType
}

const PersonHeader: FC<PersonHeaderProps> = flowMax(
  addDisplayName('PersonHeader'),
  addShowDocumentFiles,
  addClasses(classes),
  addWrapper((render, {person, showDocumentFiles, classes}) =>
    person.id && showDocumentFiles ? (
      <Body1>
        <Link
          highlight
          to={`${generatePath(personDetailPath, {id: person.id})}/files`}
          target="_self"
          className={classes.personHeader}
        >
          {render()}
        </Link>
      </Body1>
    ) : (
      <Body1 className={classes.personHeaderNotLink}>{render()}</Body1>
    )
  ),
  addTranslationHelpers,
  ({person, t}) => <>{getExtendedName({...person, t})}</>
)

type ApplicationType = Person_person_openApplications

interface ApplicationHeaderProps {
  application: ApplicationType
}

const ApplicationHeader: FC<ApplicationHeaderProps> = flowMax(
  addDisplayName('ApplicationHeader'),
  addTranslationHelpers,
  addClasses(classes),
  ({application: {id}, application, classes, t}) => (
    <Link
      highlight
      to={generatePath(editApplicationPath, {id})}
      target="_self"
      className={classes.personHeader}
    >
      {getBenefitName({...application, t})} ({id})
    </Link>
  )
)

interface PersonDocumentsProps {
  person: PersonType
  relationshipIndex?: number
}

const PersonDocuments: FC<PersonDocumentsProps> = flowMax(
  addDisplayName('PersonDocuments'),
  addProps(({person: personForDocuments}) => ({
    personForDocuments,
  })),
  addDocumentsSectionContext,
  addProps(
    ({person: {openApplications: applications}}) => ({
      applications,
    }),
    ['person']
  ),
  addProps(
    ({applications, personForDocuments: {id: personId}}) => ({
      persistedDocuments: personId
        ? applications.flatMap(({documents}) =>
            documents.filter((document) => document.person?.id === personId)
          )
        : [],
    }),
    ['applications', 'personForDocuments']
  ),
  addProps(
    ({documents, personForDocuments: {id: personId}, relationshipIndex}) => ({
      requiredDocuments: documents.filter(
        (requiredDocument) =>
          (personId && requiredDocument.personId === personId) ||
          (relationshipIndex != null &&
            relationshipIndex === requiredDocument.relationshipIndex)
      ),
    }),
    ['documents', 'personForDocuments', 'relationshipIndex']
  ),
  addProps(
    ({persistedDocuments, requiredDocuments}) => ({
      reconciledDocuments: getReconciledDocuments({
        persistedDocuments,
        requiredDocuments,
      }),
    }),
    ['persistedDocuments', 'requiredDocuments']
  ),
  addProps(
    ({reconciledDocuments}) => ({
      reconciledDocuments: sortBy('documentType')(reconciledDocuments),
    }),
    ['reconciledDocuments']
  ),
  // eslint-disable-next-line ad-hok/dependencies
  addEffect(
    ({
      reconciledDocuments,
      setHasNecessaryDocuments,
      personForDocuments: {id: personId},
    }) => () => {
      setHasNecessaryDocuments(
        `person-${personId}`,
        reconciledDocuments.some(
          ({persistedDocument}) => !persistedDocument?.complete
        )
      )
    },
    ['reconciledDocuments']
  ),
  branch(
    ({reconciledDocuments}) => !reconciledDocuments.length,
    renderNothing()
  ),
  addClasses(classes),
  ({reconciledDocuments, personForDocuments, classes}) => (
    <div className={classes.personContainer}>
      <PersonHeader person={personForDocuments} />
      {reconciledDocuments.map((reconciledDocument, index) => (
        <PersonDocument
          document={reconciledDocument}
          key={`${reconciledDocument.documentType}-${index}`}
        />
      ))}
    </div>
  )
)

interface ApplicationDocumentsProps {
  application: ApplicationType
}

const ApplicationDocuments: FC<ApplicationDocumentsProps> = flowMax(
  addDisplayName('ApplicationDocuments'),
  addProps(
    ({application: {documents: persistedDocuments}}) => ({
      persistedDocuments: persistedDocuments.filter(({person}) => !person),
    }),
    ['application.documents']
  ),
  addDocumentsSectionContext,
  addProps(
    ({documents, application: {id}}) => ({
      requiredDocuments: documents.filter(
        ({personId, applicationId}) => !personId && applicationId === id
      ),
    }),
    ['documents', 'application.id']
  ),
  addProps(
    ({persistedDocuments, requiredDocuments}) => ({
      reconciledDocuments: sortBy(
        'documentType',
        getReconciledDocuments({
          persistedDocuments,
          requiredDocuments,
        })
      ),
    }),
    ['persistedDocuments', 'requiredDocuments']
  ),
  // eslint-disable-next-line ad-hok/dependencies
  addEffect(
    ({
      reconciledDocuments,
      setHasNecessaryDocuments,
      application: {id: applicationId},
    }) => () => {
      setHasNecessaryDocuments(
        `application-${applicationId}`,
        reconciledDocuments.some(
          ({persistedDocument}) => !persistedDocument?.complete
        )
      )
    },
    ['reconciledDocuments']
  ),
  branch(
    ({reconciledDocuments}) => !reconciledDocuments.length,
    renderNothing()
  ),
  addClasses(classes),
  ({reconciledDocuments, application, classes}) => (
    <div className={classes.personContainer}>
      <ApplicationHeader application={application} />
      {reconciledDocuments.map((reconciledDocument, index) => (
        <ApplicationDocument
          document={reconciledDocument}
          key={`${reconciledDocument.documentType}-${index}`}
        />
      ))}
    </div>
  )
)

interface Props {
  show: boolean
}

const DocumentsSection: FC<Props> = flowMax(
  addDisplayName('DocumentsSection'),
  addMemoBoundary(['show']),
  addTranslationHelpers,
  addClasses(classes),
  addWrapper((render, {show, classes}) => (
    <FormSection
      labelTranslationKey="documents"
      data-testid="documents-section"
      className={cx(!show && classes.hidden)}
    >
      {render()}
    </FormSection>
  )),
  addFormikTyped(editPersonFormSchema),
  addProps(({formik: {values: {person}}}) => ({
    personValues: person,
  })),
  addDocumentsSectionContext,
  addRef('personPreviousRef', typedAs<Person_person | null>(null)),
  // eslint-disable-next-line ad-hok/dependencies
  addEffect(
    ({person, personPreviousRef, setHasNecessaryDocuments}) => () => {
      if (!personPreviousRef.current) {
        personPreviousRef.current = person
        return
      }

      const personPrevious = personPreviousRef.current
      if (
        !(
          person.openApplications.length <
          personPrevious.openApplications.length
        )
      )
        return
      const closedApplications = personPrevious.openApplications.filter(
        (application) =>
          !person.openApplications.some(({id}) => id === application.id)
      )
      closedApplications.forEach((closedApplication) => {
        setHasNecessaryDocuments(`application-${closedApplication.id}`, false)
      })
      personPreviousRef.current = person
    },
    ['person']
  ),
  ({
    personValues: {relationships},
    personValues,
    person: {openApplications},
  }) => (
    <>
      <PersonDocuments person={personValues} />
      {relationships.map(({otherPerson}, relationshipIndex) => (
        <PersonDocuments
          person={otherPerson}
          relationshipIndex={relationshipIndex}
          key={otherPerson.id ?? `tmp-${relationshipIndex}`}
        />
      ))}
      {openApplications.map((application) => (
        <ApplicationDocuments application={application} key={application.id} />
      ))}
    </>
  )
)

export default DocumentsSection
