import React from 'react'
import {identity} from 'lodash/fp'
import {ArrayHelpers} from 'formik'
import {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addWrapper,
  AddWrapperRenderCallback,
  addMemoBoundary,
  addStateHandlers,
  addHandlers,
  addProps,
} from 'ad-hok'
import AddIcon from '@material-ui/icons/Add'
import {addComponentBoundary} from 'ad-hok-utils'

import {makeClasses, addClasses} from 'theme'
import {formatCurrency} from 'utils/number'
import {addFormPrefixContext} from 'components/FormPrefix'
import FieldArray, {FieldArrayItem} from 'components/FieldArray'
import {addFormContext} from 'utils/form/context'
import {addTranslationHelpers} from 'utils/i18n'
import Grid from 'components/Grid'
import Body1 from 'components/Body1'
import Button from 'components/Button'
import {addFormik} from 'utils/form/formik'
import NestedFormListItem from 'components/NestedFormListItem'
import ConfirmationDialog from 'components/ConfirmationDialog'
import AssetDialog from 'components/AssetDialog'
import {addRightColumnContext} from 'components/EditPersonForm/rightColumnContext'
import {OnSubmitSuccessOptions} from 'components/Form'
import {ExtractFormSchemaFields} from 'utils/form/schema'
import {assetFormSchema} from 'components/AssetDialog/index'
import addDialogState from 'utils/addDialogState'
import {PersonFields_assets} from 'graphql/deserializedTypes/PersonFields'

const classes = makeClasses((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
}))

const formatAssetTitle = ({
  assetType,
  amount,
}: {
  assetType: string | null
  amount: number | null
}) =>
  [assetType, amount ? formatCurrency(amount) : null]
    .filter(identity)
    .join(', ')

interface AssetItemProps {
  asset: PersonFields_assets
  index: number
  arrayHelpers: ArrayHelpers
  formPrefix: string
  shouldFlagNecessaryFields: boolean | undefined
}

const AssetItem: FC<AssetItemProps> = flowMax(
  addDisplayName('AssetItem'),
  addFormik,
  addStateHandlers(
    {
      isShowingEditDialog: false,
      isShowingDeleteDialog: false,
    },
    {
      showEditDialog: () => () => ({isShowingEditDialog: true}),
      hideEditDialog: () => () => ({isShowingEditDialog: false}),
      showDeleteDialog: () => () => ({isShowingDeleteDialog: true}),
      hideDeleteDialog: () => () => ({isShowingDeleteDialog: false}),
    }
  ),
  addProps(({formPrefix, index}) => ({
    itemName: `${formPrefix}assets[${index}]`,
  })),
  addFormContext,
  addHandlers({
    onDialogSave: ({
      hideEditDialog,
      formik: {setFieldValue},
      itemName,
      setCollectionItemFieldsFreshness,
    }) => ({
      formValues: {asset},
      fieldsFreshness,
    }: OnSubmitSuccessOptions<
      ExtractFormSchemaFields<typeof assetFormSchema>
    >) => {
      setCollectionItemFieldsFreshness(itemName, fieldsFreshness.asset)
      setFieldValue(itemName, asset)
      hideEditDialog()
    },
    deleteAsset: ({
      index,
      arrayHelpers,
      formPrefix,
      removeCollectionItemFieldsFreshness,
      removeCollectionItemFieldsUpdatedAt,
      hideDeleteDialog,
    }) => () => {
      removeCollectionItemFieldsFreshness(`${formPrefix}assets`, index)
      removeCollectionItemFieldsUpdatedAt(`${formPrefix}assets`, index)
      arrayHelpers.remove(index)
      hideDeleteDialog()
    },
  }),
  addTranslationHelpers,
  addClasses(classes),
  ({
    t,
    asset,
    showEditDialog,
    hideEditDialog,
    isShowingEditDialog,
    onDialogSave,
    showDeleteDialog,
    hideDeleteDialog,
    isShowingDeleteDialog,
    deleteAsset,
    itemName,
    shouldFlagNecessaryFields,
  }) => (
    <div data-testid={`asset-${asset.id ?? 'new'}`}>
      <NestedFormListItem
        title={formatAssetTitle(asset)}
        subtitle=""
        onEditClick={showEditDialog}
        onDeleteClick={showDeleteDialog}
        itemName={itemName}
      />
      <AssetDialog
        asset={asset}
        itemName={itemName}
        open={isShowingEditDialog}
        onClose={hideEditDialog}
        onSave={onDialogSave}
        shouldFlagNecessaryFields={shouldFlagNecessaryFields}
      />
      <ConfirmationDialog
        title={t('deleteAssetDialog.title', {
          title: formatAssetTitle(asset),
        })}
        cancelText={t('deleteAssetDialog.cancel')}
        confirmText={t('deleteAssetDialog.confirm')}
        open={isShowingDeleteDialog}
        onCancel={hideDeleteDialog}
        onConfirm={deleteAsset}
      />
    </div>
  )
)

interface Props {
  name: string
  shouldFlagNecessaryFields?: boolean
}

const AssetFieldArray: FC<Props> = flowMax(
  addDisplayName('AssetFieldArray'),
  addFormPrefixContext,
  addWrapper(
    (
      render: AddWrapperRenderCallback<{
        items: FieldArrayItem<PersonFields_assets>[]
        arrayHelpers: ArrayHelpers
        formPrefix: string
      }>,
      props
    ) => (
      <FieldArray<PersonFields_assets>
        name={props.name}
        render={(arrayHelpers, items) =>
          render({arrayHelpers, items, formPrefix: props.formPrefix})
        }
      />
    )
  ),
  addComponentBoundary,
  addRightColumnContext,
  addProps(({hasCharityCareApplication}) => ({
    shouldFlagNecessaryFields: hasCharityCareApplication,
  })),
  addFormContext,
  addMemoBoundary([
    'name',
    'items',
    'formPrefix',
    'shouldFlagNecessaryFields',
    'formName',
  ]),
  addDialogState,
  addHandlers({
    onDialogSave: ({
      formPrefix,
      items,
      arrayHelpers,
      hideDialog,
      setCollectionItemFieldsFreshness,
    }) => ({
      formValues: {asset},
      fieldsFreshness,
    }: OnSubmitSuccessOptions<
      ExtractFormSchemaFields<typeof assetFormSchema>
    >) => {
      const itemName = `${formPrefix}assets[${items.length}]`
      setCollectionItemFieldsFreshness(itemName, fieldsFreshness.asset)
      arrayHelpers.push(asset)
      hideDialog()
    },
  }),
  addTranslationHelpers,
  addProps(({formName, name, t}) => ({
    addLabel: t(`${formName}.collectionControls.${name}.add`),
    noItemsLabel: t(`${formName}.collectionControls.${name}.noItems`),
  })),
  addClasses(classes),
  ({
    classes,
    items,
    arrayHelpers,
    addLabel,
    noItemsLabel,
    showDialog,
    hideDialog,
    isShowingDialog,
    onDialogSave,
    formPrefix,
    shouldFlagNecessaryFields,
  }) => (
    <Grid className={classes.container}>
      {items.length === 0 && <Body1>{noItemsLabel}</Body1>}
      {items.map(({key, record: asset}, index) => (
        <AssetItem
          formPrefix={formPrefix}
          asset={asset}
          index={index}
          arrayHelpers={arrayHelpers}
          shouldFlagNecessaryFields={shouldFlagNecessaryFields}
          key={key}
        />
      ))}
      <Button startIcon={<AddIcon />} color="primary" onClick={showDialog}>
        {addLabel}
      </Button>
      <AssetDialog
        open={isShowingDialog}
        onClose={hideDialog}
        onSave={onDialogSave}
        shouldFlagNecessaryFields={shouldFlagNecessaryFields}
      />
    </Grid>
  )
)

export default AssetFieldArray
