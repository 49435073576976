export type WebformRecordMergeResult = 'updated' | 'ignored' | 'created'

export type WebformFieldMergeResult =
  | 'identical'
  | 'conflict'
  | 'added'
  | 'blank'

export interface MergeRecordResultFields {
  [fieldName: string]: {
    webformValue: any
    result: WebformFieldMergeResult
  }
}

interface MergeRecordResult {
  result: WebformRecordMergeResult
  fields: MergeRecordResultFields
}

export type WebformMergeResult = {
  [recordName: string]: MergeRecordResult
}

export const parseWebformMergeResult = (
  webformMergeResultSerialized: string
): WebformMergeResult => JSON.parse(webformMergeResultSerialized)

export const serializeWebformMergeResult = (
  webformMergeResult: WebformMergeResult
): string => JSON.stringify(webformMergeResult)
