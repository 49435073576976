/* eslint-disable */
import {CreateNjmmisCaptureResultVariables as CreateNjmmisCaptureResultVariablesOrig} from '../types/CreateNjmmisCaptureResult'
import {CreateNjmmisCaptureResult as CreateNjmmisCaptureResultOrig} from '../types/CreateNjmmisCaptureResult'
import {CreateNjmmisCaptureResult_createNjmmisCaptureResult as CreateNjmmisCaptureResult_createNjmmisCaptureResultOrig} from '../types/CreateNjmmisCaptureResult'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL mutation operation: CreateNjmmisCaptureResult
// ====================================================
export interface CreateNjmmisCaptureResult_createNjmmisCaptureResult {
  __typename: 'NjmmisCaptureResult'
  id: string
  status: string
}
export const deserializeCreateNjmmisCaptureResult_createNjmmisCaptureResult = (
  serialized: CreateNjmmisCaptureResult_createNjmmisCaptureResultOrig
): CreateNjmmisCaptureResult_createNjmmisCaptureResult => ({...serialized})
export const serializeCreateNjmmisCaptureResult_createNjmmisCaptureResult = (
  deserialized: CreateNjmmisCaptureResult_createNjmmisCaptureResult
): CreateNjmmisCaptureResult_createNjmmisCaptureResultOrig => ({
  ...deserialized,
})
export interface CreateNjmmisCaptureResult {
  createNjmmisCaptureResult: CreateNjmmisCaptureResult_createNjmmisCaptureResult
}
export const deserializeCreateNjmmisCaptureResult = (
  serialized: CreateNjmmisCaptureResultOrig
): CreateNjmmisCaptureResult => ({
  ...serialized,
  createNjmmisCaptureResult: deserializeCreateNjmmisCaptureResult_createNjmmisCaptureResult(
    serialized.createNjmmisCaptureResult
  ),
})
export const serializeCreateNjmmisCaptureResult = (
  deserialized: CreateNjmmisCaptureResult
): CreateNjmmisCaptureResultOrig => ({
  ...deserialized,
  createNjmmisCaptureResult: serializeCreateNjmmisCaptureResult_createNjmmisCaptureResult(
    deserialized.createNjmmisCaptureResult
  ),
})
export interface CreateNjmmisCaptureResultVariables {
  applicationId: string
}
export const deserializeCreateNjmmisCaptureResultVariables = (
  serialized: CreateNjmmisCaptureResultVariablesOrig
): CreateNjmmisCaptureResultVariables => ({...serialized})
export const serializeCreateNjmmisCaptureResultVariables = (
  deserialized: CreateNjmmisCaptureResultVariables
): CreateNjmmisCaptureResultVariablesOrig => ({...deserialized})
