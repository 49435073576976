import React from 'react'
import {CurriedUnchangedProps, flowMax, addWrapper} from 'ad-hok'
import {Fragment} from 'react'
import {isArray} from 'lodash/fp'

type AddRemountingWhenPropsChangeType = <
  TProps,
  TPropName extends keyof TProps
>(
  propName: TPropName | TPropName[]
) => CurriedUnchangedProps<TProps>

const addRemountingWhenPropsChange: AddRemountingWhenPropsChangeType = (
  propNames
) =>
  flowMax(
    addWrapper((render, props) => (
      <Fragment
        key={
          isArray(propNames)
            ? propNames.map((propName) => props[propName]).join('--')
            : `${props[propNames]}`
        }
      >
        {render()}
      </Fragment>
    ))
  )

export default addRemountingWhenPropsChange
