import React, {FC, ReactNode} from 'react'
import {
  flowMax,
  addDisplayName,
  SimpleUnchangedProps,
  addStateHandlers,
  addWrapper,
} from 'ad-hok'
import CloseIcon from '@material-ui/icons/Close'
import {getContextHelpersFromInitialValues} from 'ad-hok-utils'

import {addTranslationHelpers} from 'utils/i18n'
import Snackbar from 'components/Snackbar'
import IconButton from 'components/IconButton'

const {
  addProvider: addAppSnackbarContextProvider,
  addConsumer: addAppSnackbarContext,
} = getContextHelpersFromInitialValues({
  showSnackbarMessage: (_message: string) => {},
  showSnackbarMessageAndAction: (_message: string, _action: ReactNode) => {},
  hideSnackbar: () => {},
})

export {addAppSnackbarContext}

interface AppSnackbarProps {
  message: string | null
  onClose: () => void
  action?: ReactNode
}

const AppSnackbar: FC<AppSnackbarProps> = flowMax(
  addDisplayName('AppSnackbar'),
  addTranslationHelpers,
  ({message, onClose, action, t}) => (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={!!message}
      autoHideDuration={6000}
      onClose={onClose}
      message={message}
      action={
        action ?? (
          <IconButton
            size="small"
            aria-label={t('snackbar.close')}
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )
      }
    />
  )
)

const addAppSnackbar: SimpleUnchangedProps = flowMax(
  addStateHandlers(
    {
      snackbarMessage: null as string | null,
      snackbarAction: null as ReactNode | null,
    },
    {
      showSnackbarMessage: () => (message: string) => ({
        snackbarMessage: message,
      }),
      showSnackbarMessageAndAction: () => (
        message: string,
        action: ReactNode
      ) => ({
        snackbarMessage: message,
        snackbarAction: action,
      }),
      hideSnackbar: () => () => ({
        snackbarMessage: null,
        snackbarAction: null,
      }),
    }
  ),
  addAppSnackbarContextProvider,
  addWrapper((render, {snackbarMessage, snackbarAction, hideSnackbar}) => (
    <>
      {render()}
      <AppSnackbar
        message={snackbarMessage}
        onClose={hideSnackbar}
        action={snackbarAction}
      />
    </>
  ))
)

export default addAppSnackbar
