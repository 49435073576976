/* eslint-disable */
import {ApplyStateMedicaidVariables as ApplyStateMedicaidVariablesOrig} from '../types/ApplyStateMedicaid'
import {ApplyStateMedicaid as ApplyStateMedicaidOrig} from '../types/ApplyStateMedicaid'
import {ApplyStateMedicaid_applyStateMedicaid as ApplyStateMedicaid_applyStateMedicaidOrig} from '../types/ApplyStateMedicaid'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL mutation operation: ApplyStateMedicaid
// ====================================================
export interface ApplyStateMedicaid_applyStateMedicaid {
  __typename: 'ApplyStateMedicaid'
  id: string
  status: string
  errorMessage: string | null
}
export const deserializeApplyStateMedicaid_applyStateMedicaid = (
  serialized: ApplyStateMedicaid_applyStateMedicaidOrig
): ApplyStateMedicaid_applyStateMedicaid => ({...serialized})
export const serializeApplyStateMedicaid_applyStateMedicaid = (
  deserialized: ApplyStateMedicaid_applyStateMedicaid
): ApplyStateMedicaid_applyStateMedicaidOrig => ({...deserialized})
export interface ApplyStateMedicaid {
  applyStateMedicaid: ApplyStateMedicaid_applyStateMedicaid
}
export const deserializeApplyStateMedicaid = (
  serialized: ApplyStateMedicaidOrig
): ApplyStateMedicaid => ({
  ...serialized,
  applyStateMedicaid: deserializeApplyStateMedicaid_applyStateMedicaid(
    serialized.applyStateMedicaid
  ),
})
export const serializeApplyStateMedicaid = (
  deserialized: ApplyStateMedicaid
): ApplyStateMedicaidOrig => ({
  ...deserialized,
  applyStateMedicaid: serializeApplyStateMedicaid_applyStateMedicaid(
    deserialized.applyStateMedicaid
  ),
})
export interface ApplyStateMedicaidVariables {
  applicationId: string
  personId: string
  stateCode?: string | null
}
export const deserializeApplyStateMedicaidVariables = (
  serialized: ApplyStateMedicaidVariablesOrig
): ApplyStateMedicaidVariables => ({...serialized})
export const serializeApplyStateMedicaidVariables = (
  deserialized: ApplyStateMedicaidVariables
): ApplyStateMedicaidVariablesOrig => ({...deserialized})
