import React, {FC} from 'react'
import {flowMax, addDisplayName} from 'ad-hok'
import {generatePath} from 'react-router'

import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import {addClasses, makeClasses} from 'theme'
import Link from 'components/Link'
import {editPersonPath} from 'components/TopLevelRoutes'
import {addTranslationHelpers} from 'utils/i18n'

const classes = makeClasses((theme) => ({
  link: {
    color: '#90caf9', // blue 200
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
    textTransform: 'uppercase',
  },
}))

interface Props {
  personId: string
}

export const SnackbarProfileLink: FC<Props> = flowMax(
  addDisplayName('SnackbarProfileLink'),
  addAppSnackbarContext,
  addClasses(classes),
  addTranslationHelpers,
  ({personId, hideSnackbar, classes, t}) => (
    <Link
      to={generatePath(editPersonPath, {id: personId})}
      onClick={hideSnackbar}
      className={classes.link}
    >
      {t('worklist.unmatchedWebforms.goToProfile')}
    </Link>
  )
)
