/* eslint-disable */
import {DeleteDocumentFileVariables as DeleteDocumentFileVariablesOrig} from '../types/DeleteDocumentFile'
import {DeleteDocumentFile as DeleteDocumentFileOrig} from '../types/DeleteDocumentFile'
import {DeleteDocumentFile_deleteDocumentFile as DeleteDocumentFile_deleteDocumentFileOrig} from '../types/DeleteDocumentFile'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL mutation operation: DeleteDocumentFile
// ====================================================
export interface DeleteDocumentFile_deleteDocumentFile {
  __typename: 'DocumentFile'
  id: string
}
export const deserializeDeleteDocumentFile_deleteDocumentFile = (
  serialized: DeleteDocumentFile_deleteDocumentFileOrig
): DeleteDocumentFile_deleteDocumentFile => ({...serialized})
export const serializeDeleteDocumentFile_deleteDocumentFile = (
  deserialized: DeleteDocumentFile_deleteDocumentFile
): DeleteDocumentFile_deleteDocumentFileOrig => ({...deserialized})
export interface DeleteDocumentFile {
  /**
   * Delete document file
   */
  deleteDocumentFile: DeleteDocumentFile_deleteDocumentFile
}
export const deserializeDeleteDocumentFile = (
  serialized: DeleteDocumentFileOrig
): DeleteDocumentFile => ({
  ...serialized,
  deleteDocumentFile: deserializeDeleteDocumentFile_deleteDocumentFile(
    serialized.deleteDocumentFile
  ),
})
export const serializeDeleteDocumentFile = (
  deserialized: DeleteDocumentFile
): DeleteDocumentFileOrig => ({
  ...deserialized,
  deleteDocumentFile: serializeDeleteDocumentFile_deleteDocumentFile(
    deserialized.deleteDocumentFile
  ),
})
export interface DeleteDocumentFileVariables {
  id: string
}
export const deserializeDeleteDocumentFileVariables = (
  serialized: DeleteDocumentFileVariablesOrig
): DeleteDocumentFileVariables => ({...serialized})
export const serializeDeleteDocumentFileVariables = (
  deserialized: DeleteDocumentFileVariables
): DeleteDocumentFileVariablesOrig => ({...deserialized})
