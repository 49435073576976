export type ReceivedWebformResponse = {
  [key: string]: any
}

export const parseReceivedWebformResponse = (
  receivedWebformResponseSerialized: string
): ReceivedWebformResponse => JSON.parse(receivedWebformResponseSerialized)

export const serializeReceivedWebformResponse = (
  receivedWebformResponse: ReceivedWebformResponse
): string => JSON.stringify(receivedWebformResponse)
