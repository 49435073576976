import {isArray} from 'lodash/fp'

import {FieldType} from 'utils/form/fieldTypes'

export type FormSchema<TFields extends FormSchemaFields> = {
  fields: TFields
}

type MakeFormSchemaType = <TFields extends FormSchemaFields>(
  schema: FormSchema<TFields>
) => FormSchema<TFields>

export const makeFormSchema: MakeFormSchemaType = (schema) => schema

export type FormSchemaFields = {
  [objectName: string]: FormSchemaObjectFields
}

export type FormSchemaObjectFields = {
  [fieldName: string]: FormSchemaValue
}

export type FormSchemaValue =
  | FieldType<any>
  | FormSchemaCollection<any>
  | FormSchemaObjectFields

const COLLECTION_MARKER = '_collection'

export type FormSchemaCollection<TFields extends FormSchemaObjectFields> = [
  '_collection',
  TFields
]

type MakeFormCollectionType = <TFields extends FormSchemaObjectFields>(
  fields: TFields
) => FormSchemaCollection<TFields>

export const makeFormCollection: MakeFormCollectionType = (fields) => [
  COLLECTION_MARKER,
  fields,
]

export type ExtractFormCollectionFields<
  TCollection extends FormSchemaCollection<any>
> = TCollection[1]

export const extractFormCollectionFields = <
  TFields extends FormSchemaObjectFields
>(
  formCollection: FormSchemaCollection<TFields>
): TFields => formCollection[1]

export function isField(
  maybeField: FormSchemaValue
): maybeField is FieldType<any> {
  return (maybeField as FieldType<any>).type !== undefined
}

export function isFormCollection(
  maybeCollection: FormSchemaValue
): maybeCollection is FormSchemaCollection<any> {
  return isArray(maybeCollection)
}

type MakeFormObjectType = <TFields extends FormSchemaObjectFields>(
  objects: TFields
) => TFields

export const makeFormObject: MakeFormObjectType = (object) => object

export type FormValues<TFields extends FormSchemaFields> = {
  [ObjectName in keyof TFields]: FormObjectValues<TFields[ObjectName]>
}

export type FormObjectValues<TFields extends FormSchemaObjectFields> = {
  [FieldName in keyof TFields]: TFields[FieldName] extends FieldType<any>
    ? TFields[FieldName]['initialValue']
    : TFields[FieldName] extends FormSchemaCollection<any>
    ? Array<FormObjectValues<ExtractFormCollectionFields<TFields[FieldName]>>>
    : TFields[FieldName] extends FormSchemaObjectFields
    ? FormObjectValues<TFields[FieldName]>
    : never
}

export type FormCanonicalValues<TFields extends FormSchemaFields> = {
  [ObjectName in keyof TFields]: FormObjectCanonicalValues<TFields[ObjectName]>
}

export type FormCanonicalValuesFromSchema<
  TSchema extends FormSchema<any>
> = FormCanonicalValues<ExtractFormSchemaFields<TSchema>>

export type FormObjectCanonicalValues<
  TFields extends FormSchemaObjectFields
> = {
  [FieldName in keyof TFields]: TFields[FieldName] extends FieldType<any>
    ? ReturnType<TFields[FieldName]['mapToCanonical']>
    : TFields[FieldName] extends FormSchemaCollection<any>
    ? Array<
        FormObjectCanonicalValues<
          ExtractFormCollectionFields<TFields[FieldName]>
        >
      >
    : TFields[FieldName] extends FormSchemaObjectFields
    ? FormObjectCanonicalValues<TFields[FieldName]>
    : never
}

export type ExtractFormSchemaFields<
  TFormSchema
> = TFormSchema extends FormSchema<infer TFields> ? TFields : never

interface ExtractFieldSchemaOptions {
  name: string
  schema: FormSchema<any>
}

export const extractFieldSchema = <TFieldData,>({
  name,
  schema,
}: ExtractFieldSchemaOptions): FieldType<TFieldData> => {
  let field = schema.fields
  name.split('.').forEach((pathSegment) => {
    field = field[pathSegment]
    if (isFormCollection(field)) field = extractFormCollectionFields(field)
  })
  if (!field)
    throw new Error(`Didn't find expected form field at path "${name}"`)
  return field
}

export type FormInitialValues<TFields extends FormSchemaFields> = {
  [ObjectName in keyof TFields]: FormObjectInitialValues<TFields[ObjectName]>
}

export type FormObjectInitialValues<TFields extends FormSchemaObjectFields> = {
  [FieldName in keyof TFields]: TFields[FieldName] extends FieldType<any>
    ? ReturnType<TFields[FieldName]['mapToCanonical']> | null
    : TFields[FieldName] extends FormSchemaCollection<any>
    ? Array<
        FormObjectInitialValues<ExtractFormCollectionFields<TFields[FieldName]>>
      >
    : TFields[FieldName] extends FormSchemaObjectFields
    ? FormObjectInitialValues<TFields[FieldName]>
    : never
}
