import {TFunction} from 'i18next'

import {BenefitType, getIsFacilitySpecificBenefitType} from 'utils/benefits'

export const getBenefitName = ({benefit, t}: {benefit: string; t: TFunction}) =>
  t(`benefitTypeLabels.${benefit}`)

export const getApplicationName = ({
  id,
  benefit,
  facility,
  mcdType,
  slideType,
  mcdSubtype,
  t,
}: {
  id: string
  benefit: BenefitType
  facility?: string
  mcdType?: string
  slideType?: string | null
  mcdSubtype?: string | null
  t: TFunction
}) => {
  const benefitName = getBenefitName({benefit, t})
  // if (getIsFacilitySpecificBenefitType(benefit))
  if (benefit === 'charityCare')
    return t('applications.nameWithSubtype', {
      benefit: benefitName,
      subtype: facility,
      id,
    })
  if (benefit === 'medicaid' && mcdSubtype === null)
    return t('applications.nameWithSubtype', {
      benefit: benefitName,
      subtype: mcdType,
      id,
    })
  if (benefit === 'medicaid' && mcdSubtype !== null) {
    const mcdAppName = mcdType + ':' + mcdSubtype
    return t('applications.nameWithSubtype', {
      benefit: benefitName,
      subtype: mcdAppName,
      id,
    })
  }
  if (benefit === 'slide')
    return t('applications.nameWithSubtype', {
      benefit: benefitName,
      subtype: slideType,
      id,
    })
  if (benefit === 'ryanWhite')
    return t('applications.nameWithSubtype', {
      benefit: benefitName,
      subtype: facility,
      id,
    })
  return t('applications.name', {
    benefit: benefitName,
    id,
  })
}

const applicationNames: Record<BenefitType, string> = {
  medicaid: 'Medicaid',
  monitor: 'Monitor',
  charityCare: 'CC',
  slide: 'Slide',
  ryanWhite: 'Ryan White',
}

export const getApplicationShortName = ({
  id,
  benefit,
  facility,
}: {
  id: string
  benefit: BenefitType
  facility?: string | null
}) =>
  `${applicationNames[benefit]}${
    getIsFacilitySpecificBenefitType(benefit) && facility
      ? ` - ${facility}`
      : ''
  } (${id})`
