import React, {FC} from 'react'
import {flowMax, addDisplayName} from 'ad-hok'
import {generatePath} from 'react-router'
import {format} from 'date-fns/fp'

import Paper from 'components/Paper'
import {makeClasses, addClasses} from 'theme'
import Body1 from 'components/Body1'
import {addTranslationHelpers} from 'utils/i18n'
import Grid from 'components/Grid'
import Link from 'components/Link'
import {editAccountPath} from 'components/TopLevelRoutes'
import AccountStatusUpdateSelect from 'components/AccountStatusUpdateSelect'
import DisplayDob from 'components/DisplayDob'
import DisplayItem from 'components/DisplayItem'
import DisplayLocation from 'components/DisplayLocation'
import Trans from 'components/Trans'
import {getExtendedName} from 'utils/name'
import {Accounts_accounts} from 'graphql/deserializedTypes/Accounts'
import {generatePersonDetailTabPath} from 'components/PersonDetail'
import {orUnknown} from 'utils/string'

const classes = makeClasses((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  leftColumnContainer: {
    width: 280,
  },
  middleColumnContainer: {
    alignSelf: 'flex-end',
    marginRight: 'auto',
  },
  accountId: {
    fontSize: 24,
    marginBottom: theme.spacing(1),
  },
}))

interface Props {
  account: Accounts_accounts
}

const AccountItem: FC<Props> = flowMax(
  addDisplayName('AccountItem'),
  addClasses(classes),
  addTranslationHelpers,
  ({
    account: {
      id,
      hospitalAccountId,
      dateOfService,
      timeOfService,
      isScheduled,
      insuranceType,
      person: {id: personId, dob},
      person,
    },
    account,
    classes,
    t,
  }) => (
    <Paper className={classes.paper}>
      <Grid container direction="row">
        <Grid item className={classes.leftColumnContainer}>
          <Grid container direction="column">
            <Body1>{t('accountItem.account')}</Body1>
            <Link
              highlight
              to={generatePath(editAccountPath, {id})}
              className={classes.accountId}
            >
              {orUnknown(t, 'capitalizedAngleBrackets')(hospitalAccountId)}
            </Link>
            <Body1>
              <Trans i18nKey="accountItem.personDisplay">
                <Link
                  highlight
                  to={generatePersonDetailTabPath({
                    id: personId,
                    tabPath: 'accounts',
                  })}
                />
                {{
                  person: `${getExtendedName({...person, t})} (${personId})`,
                }}
              </Trans>
            </Body1>
            <DisplayDob dob={dob} />
          </Grid>
        </Grid>
        <Grid item className={classes.middleColumnContainer}>
          <Grid container direction="column">
            <DisplayItem
              i18nKey={
                isScheduled
                  ? 'accountItem.dateOfServiceDisplayScheduled'
                  : 'accountItem.dateOfServiceDisplay'
              }
              translations={{
                date: `${format('M/d/yy')(dateOfService)}${
                  timeOfService ? ` ${timeOfService}ET` : ''
                }`,
              }}
            />
            <DisplayLocation account={account} />
            <DisplayItem
              i18nKey="accountItem.insuranceTypeDisplay"
              translations={{
                insuranceType,
              }}
            />
          </Grid>
        </Grid>
        <AccountStatusUpdateSelect account={account} />
      </Grid>
    </Paper>
  )
)

export default AccountItem
