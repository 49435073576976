import React, {FC} from 'react'
import {flowMax, addDisplayName, branch, renderNothing} from 'ad-hok'

interface TabPanelProps {
  index: number
  activeIndex: number
}

const TabPanel: FC<TabPanelProps> = flowMax(
  addDisplayName('TabPanel'),
  branch(({index, activeIndex}) => index !== activeIndex, renderNothing()),
  ({children}) => <>{children}</>
)

export default TabPanel
