/* eslint-disable */
import {Me as MeOrig} from '../types/Me'
import {Me_me as Me_meOrig} from '../types/Me'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: Me
// ====================================================
export interface Me_me {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
  openApplicationsWithUnreviewedRemoteRequestEditableFileCount: number
  openApplicationsWithUnreadMessagesCount: number
  roles: string[]
}
export const deserializeMe_me = (serialized: Me_meOrig): Me_me => ({
  ...serialized,
})
export const serializeMe_me = (deserialized: Me_me): Me_meOrig => ({
  ...deserialized,
})
export interface Me {
  /**
   * Currently authenticated user
   */
  me: Me_me
}
export const deserializeMe = (serialized: MeOrig): Me => ({
  ...serialized,
  me: deserializeMe_me(serialized.me),
})
export const serializeMe = (deserialized: Me): MeOrig => ({
  ...deserialized,
  me: serializeMe_me(deserialized.me),
})
