import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps, addDefaultProps} from 'ad-hok'
import {generatePath} from 'react-router'

import {addTranslationHelpers} from 'utils/i18n'
import {getExtendedName} from 'utils/name'
import Trans from 'components/Trans'
import Link from 'components/Link'
import {personDetailDefaultPath} from 'components/TopLevelRoutes'
// import {getIsFacilitySpecificBenefitType, BenefitType} from 'utils/benefits'
import {BenefitType} from 'utils/benefits'

interface Props {
  application: {
    benefit: BenefitType
    person: {
      id: string
      firstName: string | null
      lastName: string | null
      preferredName: string | null
      middleName: string | null
      suffix: string | null
    }
    facility?: string
    mcdType?: string
    charityCareType?: string
    slideType?: string
    mcdSubtype?: string | null
  }
  shouldShowMcdType?: boolean
  shouldLinkToPerson?: boolean
  shouldShowSlideType?: boolean
}

const ApplicationTitle: FC<Props> = flowMax(
  addDisplayName('ApplicationTitle'),
  addDefaultProps({
    shouldLinkToPerson: true,
  }),
  addTranslationHelpers,
  addProps(
    ({application: {benefit, person}, t}) => ({
      benefit: t(`benefitTypeLabels.${benefit}`),
      personName: getExtendedName({...person, t}),
    }),
    ['application', 't']
  ),
  addProps(
    ({
      benefit: benefitLabel,
      application: {
        benefit,
        facility,
        mcdType,
        mcdSubtype,
        charityCareType,
        slideType,
      },
      shouldShowMcdType,
      shouldShowSlideType,
    }) => ({
      benefit:
        benefit === 'slide' && shouldShowSlideType
          ? `${benefitLabel} (${facility} - ${slideType})`
          : benefit === 'charityCare'
          ? `${benefitLabel} (${facility} - ${charityCareType})`
          : benefit === 'medicaid' && shouldShowMcdType && mcdSubtype !== null
          ? `${benefitLabel} (${mcdType}-${mcdSubtype})`
          : benefit === 'medicaid' && shouldShowMcdType && mcdSubtype === null
          ? `${benefitLabel} (${mcdType})`
          : benefitLabel,
    }),
    ['benefit', 'application', 'shouldShowMcdType', 'shouldShowSlideType']
  ),
  ({
    application: {
      person: {id: personId},
    },
    benefit,
    personName,
    shouldLinkToPerson,
  }) => (
    <>
      {shouldLinkToPerson ? (
        <Trans i18nKey="applicationItem.title">
          <Link
            to={generatePath(personDetailDefaultPath, {id: personId})}
            highlight
          />
          {{benefit}}
          {{personName}}
        </Trans>
      ) : (
        <Trans i18nKey="applicationItem.title">
          <span />
          {{benefit}}
          {{personName}}
        </Trans>
      )}
    </>
  )
)

export default ApplicationTitle
