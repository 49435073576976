/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {BenefitOutcomeInput} from './globalTypes' // ====================================================
// GraphQL mutation operation: CreateOrUpdateBenefitOutcome
// ====================================================

import {deserializeBenefitOutcomeInput} from './globalTypes'
import {serializeBenefitOutcomeInput} from './globalTypes'
import {CreateOrUpdateBenefitOutcomeVariables as CreateOrUpdateBenefitOutcomeVariablesOrig} from '../types/CreateOrUpdateBenefitOutcome'
import {CreateOrUpdateBenefitOutcome as CreateOrUpdateBenefitOutcomeOrig} from '../types/CreateOrUpdateBenefitOutcome'
import formatISO from 'date-fns/formatISO'
import {parseDate} from 'utils/form/fieldTypes'
import {formatISODate} from 'utils/date'
import {CreateOrUpdateBenefitOutcome_createOrUpdateBenefitOutcome as CreateOrUpdateBenefitOutcome_createOrUpdateBenefitOutcomeOrig} from '../types/CreateOrUpdateBenefitOutcome'
export interface CreateOrUpdateBenefitOutcome_createOrUpdateBenefitOutcome {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
}
export const deserializeCreateOrUpdateBenefitOutcome_createOrUpdateBenefitOutcome = (
  serialized: CreateOrUpdateBenefitOutcome_createOrUpdateBenefitOutcomeOrig
): CreateOrUpdateBenefitOutcome_createOrUpdateBenefitOutcome => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
})
export const serializeCreateOrUpdateBenefitOutcome_createOrUpdateBenefitOutcome = (
  deserialized: CreateOrUpdateBenefitOutcome_createOrUpdateBenefitOutcome
): CreateOrUpdateBenefitOutcome_createOrUpdateBenefitOutcomeOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
})
export interface CreateOrUpdateBenefitOutcome {
  /**
   * Create a new benefit outcome or update existing
   */
  createOrUpdateBenefitOutcome: CreateOrUpdateBenefitOutcome_createOrUpdateBenefitOutcome
}
export const deserializeCreateOrUpdateBenefitOutcome = (
  serialized: CreateOrUpdateBenefitOutcomeOrig
): CreateOrUpdateBenefitOutcome => ({
  ...serialized,
  createOrUpdateBenefitOutcome: deserializeCreateOrUpdateBenefitOutcome_createOrUpdateBenefitOutcome(
    serialized.createOrUpdateBenefitOutcome
  ),
})
export const serializeCreateOrUpdateBenefitOutcome = (
  deserialized: CreateOrUpdateBenefitOutcome
): CreateOrUpdateBenefitOutcomeOrig => ({
  ...deserialized,
  createOrUpdateBenefitOutcome: serializeCreateOrUpdateBenefitOutcome_createOrUpdateBenefitOutcome(
    deserialized.createOrUpdateBenefitOutcome
  ),
})
export interface CreateOrUpdateBenefitOutcomeVariables {
  benefitOutcome: BenefitOutcomeInput
}
export const deserializeCreateOrUpdateBenefitOutcomeVariables = (
  serialized: CreateOrUpdateBenefitOutcomeVariablesOrig
): CreateOrUpdateBenefitOutcomeVariables => ({
  ...serialized,
  benefitOutcome: deserializeBenefitOutcomeInput(serialized.benefitOutcome),
})
export const serializeCreateOrUpdateBenefitOutcomeVariables = (
  deserialized: CreateOrUpdateBenefitOutcomeVariables
): CreateOrUpdateBenefitOutcomeVariablesOrig => ({
  ...deserialized,
  benefitOutcome: serializeBenefitOutcomeInput(deserialized.benefitOutcome),
})
