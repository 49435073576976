/* eslint-disable */
import {CountyFaxesVariables as CountyFaxesVariablesOrig} from '../types/CountyFaxes'
import {CountyFaxes as CountyFaxesOrig} from '../types/CountyFaxes'
import {CountyFaxes_countyFaxes as CountyFaxes_countyFaxesOrig} from '../types/CountyFaxes'
import {CountyFaxes_countyFaxes_state as CountyFaxes_countyFaxes_stateOrig} from '../types/CountyFaxes'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: CountyFaxes
// ====================================================
export interface CountyFaxes_countyFaxes_state {
  __typename: 'State'
  id: string
  stateCode: string
  stateName: string
}
export const deserializeCountyFaxes_countyFaxes_state = (
  serialized: CountyFaxes_countyFaxes_stateOrig
): CountyFaxes_countyFaxes_state => ({...serialized})
export const serializeCountyFaxes_countyFaxes_state = (
  deserialized: CountyFaxes_countyFaxes_state
): CountyFaxes_countyFaxes_stateOrig => ({...deserialized})
export interface CountyFaxes_countyFaxes {
  __typename: 'CountyFax'
  id: string
  state: CountyFaxes_countyFaxes_state
  countyName: string
  faxNumber: string | null
  emailAddress: string | null
  contactName: string | null
  contactEmail: string | null
  faxNumberEnabled: boolean | null
  emailAddressEnabled: boolean | null
}
export const deserializeCountyFaxes_countyFaxes = (
  serialized: CountyFaxes_countyFaxesOrig
): CountyFaxes_countyFaxes => ({
  ...serialized,
  state: deserializeCountyFaxes_countyFaxes_state(serialized.state),
})
export const serializeCountyFaxes_countyFaxes = (
  deserialized: CountyFaxes_countyFaxes
): CountyFaxes_countyFaxesOrig => ({
  ...deserialized,
  state: serializeCountyFaxes_countyFaxes_state(deserialized.state),
})
export interface CountyFaxes {
  /**
   * List of county faxes
   */
  countyFaxes: CountyFaxes_countyFaxes[]
}
export const deserializeCountyFaxes = (
  serialized: CountyFaxesOrig
): CountyFaxes => ({
  ...serialized,
  countyFaxes: serialized.countyFaxes.map(deserializeCountyFaxes_countyFaxes),
})
export const serializeCountyFaxes = (
  deserialized: CountyFaxes
): CountyFaxesOrig => ({
  ...deserialized,
  countyFaxes: deserialized.countyFaxes.map(serializeCountyFaxes_countyFaxes),
})
export interface CountyFaxesVariables {
  stateId?: string | null
}
export const deserializeCountyFaxesVariables = (
  serialized: CountyFaxesVariablesOrig
): CountyFaxesVariables => ({...serialized})
export const serializeCountyFaxesVariables = (
  deserialized: CountyFaxesVariables
): CountyFaxesVariablesOrig => ({...deserialized})
