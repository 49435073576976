/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {EditableFileInput, EditableFileStatus} from './globalTypes' // ====================================================
// GraphQL mutation operation: CreateEditableFile
// ====================================================

import {deserializeEditableFileInput} from './globalTypes'
import {serializeEditableFileInput} from './globalTypes'
import {CreateEditableFileVariables as CreateEditableFileVariablesOrig} from '../types/CreateEditableFile'
import {CreateEditableFile as CreateEditableFileOrig} from '../types/CreateEditableFile'
import {CreateEditableFile_createEditableFile as CreateEditableFile_createEditableFileOrig} from '../types/CreateEditableFile'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {CreateEditableFile_createEditableFile_sentFax as CreateEditableFile_createEditableFile_sentFaxOrig} from '../types/CreateEditableFile'
import {CreateEditableFile_createEditableFile_sentFax_countyFax as CreateEditableFile_createEditableFile_sentFax_countyFaxOrig} from '../types/CreateEditableFile'
import {CreateEditableFile_createEditableFile_sentFax_countyFax_state as CreateEditableFile_createEditableFile_sentFax_countyFax_stateOrig} from '../types/CreateEditableFile'
export interface CreateEditableFile_createEditableFile_sentFax_countyFax_state {
  __typename: 'State'
  stateCode: string
}
export const deserializeCreateEditableFile_createEditableFile_sentFax_countyFax_state = (
  serialized: CreateEditableFile_createEditableFile_sentFax_countyFax_stateOrig
): CreateEditableFile_createEditableFile_sentFax_countyFax_state => ({
  ...serialized,
})
export const serializeCreateEditableFile_createEditableFile_sentFax_countyFax_state = (
  deserialized: CreateEditableFile_createEditableFile_sentFax_countyFax_state
): CreateEditableFile_createEditableFile_sentFax_countyFax_stateOrig => ({
  ...deserialized,
})
export interface CreateEditableFile_createEditableFile_sentFax_countyFax {
  __typename: 'CountyFax'
  countyName: string
  state: CreateEditableFile_createEditableFile_sentFax_countyFax_state
}
export const deserializeCreateEditableFile_createEditableFile_sentFax_countyFax = (
  serialized: CreateEditableFile_createEditableFile_sentFax_countyFaxOrig
): CreateEditableFile_createEditableFile_sentFax_countyFax => ({
  ...serialized,
  state: deserializeCreateEditableFile_createEditableFile_sentFax_countyFax_state(
    serialized.state
  ),
})
export const serializeCreateEditableFile_createEditableFile_sentFax_countyFax = (
  deserialized: CreateEditableFile_createEditableFile_sentFax_countyFax
): CreateEditableFile_createEditableFile_sentFax_countyFaxOrig => ({
  ...deserialized,
  state: serializeCreateEditableFile_createEditableFile_sentFax_countyFax_state(
    deserialized.state
  ),
})
export interface CreateEditableFile_createEditableFile_sentFax {
  __typename: 'SentFax'
  toNumber: string | null
  faxStatus: string | null
  message: string | null
  updatedAt: Date
  countyFax: CreateEditableFile_createEditableFile_sentFax_countyFax
}
export const deserializeCreateEditableFile_createEditableFile_sentFax = (
  serialized: CreateEditableFile_createEditableFile_sentFaxOrig
): CreateEditableFile_createEditableFile_sentFax => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  countyFax: deserializeCreateEditableFile_createEditableFile_sentFax_countyFax(
    serialized.countyFax
  ),
})
export const serializeCreateEditableFile_createEditableFile_sentFax = (
  deserialized: CreateEditableFile_createEditableFile_sentFax
): CreateEditableFile_createEditableFile_sentFaxOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  countyFax: serializeCreateEditableFile_createEditableFile_sentFax_countyFax(
    deserialized.countyFax
  ),
})
export interface CreateEditableFile_createEditableFile {
  __typename: 'EditableFile'
  id: string
  fileKey: string
  filename: string
  updatedAt: Date
  status: EditableFileStatus
  allowSigning: boolean | null
  documentSendType: string | null
  faxEnabled: boolean | null
  sentFax: CreateEditableFile_createEditableFile_sentFax | null
}
export const deserializeCreateEditableFile_createEditableFile = (
  serialized: CreateEditableFile_createEditableFileOrig
): CreateEditableFile_createEditableFile => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  sentFax:
    serialized.sentFax != null
      ? deserializeCreateEditableFile_createEditableFile_sentFax(
          serialized.sentFax
        )
      : serialized.sentFax,
})
export const serializeCreateEditableFile_createEditableFile = (
  deserialized: CreateEditableFile_createEditableFile
): CreateEditableFile_createEditableFileOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  sentFax:
    deserialized.sentFax != null
      ? serializeCreateEditableFile_createEditableFile_sentFax(
          deserialized.sentFax
        )
      : deserialized.sentFax,
})
export interface CreateEditableFile {
  /**
   * Create editable file
   */
  createEditableFile: CreateEditableFile_createEditableFile
}
export const deserializeCreateEditableFile = (
  serialized: CreateEditableFileOrig
): CreateEditableFile => ({
  ...serialized,
  createEditableFile: deserializeCreateEditableFile_createEditableFile(
    serialized.createEditableFile
  ),
})
export const serializeCreateEditableFile = (
  deserialized: CreateEditableFile
): CreateEditableFileOrig => ({
  ...deserialized,
  createEditableFile: serializeCreateEditableFile_createEditableFile(
    deserialized.createEditableFile
  ),
})
export interface CreateEditableFileVariables {
  editableFile: EditableFileInput
  sendForSigning?: boolean | null
  sendForRemoteSigningSessionId?: string | null
  setUnreviewed?: boolean | null
  applicationId?: string | null
  sendDocumentType?: string | null
  isDocumentSendOnly?: boolean | null
  isDocumentSendAndSave?: boolean | null
  isEsignDocument?: boolean | null
}
export const deserializeCreateEditableFileVariables = (
  serialized: CreateEditableFileVariablesOrig
): CreateEditableFileVariables => ({
  ...serialized,
  editableFile: deserializeEditableFileInput(serialized.editableFile),
})
export const serializeCreateEditableFileVariables = (
  deserialized: CreateEditableFileVariables
): CreateEditableFileVariablesOrig => ({
  ...deserialized,
  editableFile: serializeEditableFileInput(deserialized.editableFile),
})
