import {format} from 'date-fns/fp'
import differenceInYears from 'date-fns/differenceInYears'
import baseGetMonth from 'date-fns/getMonth'
import isBefore from 'date-fns/isBefore'
import isAfter from 'date-fns/isAfter'
import isEqual from 'date-fns/isEqual'
import getDate from 'date-fns/getDate'
import {TFunction} from 'i18next'
import {utcToZonedTime} from 'date-fns-tz'
import isValidDate from 'date-fns/isValid'
import setHours from 'date-fns/setHours'
import getHours from 'date-fns/getHours'
import setMinutes from 'date-fns/setMinutes'
import getMinutes from 'date-fns/getMinutes'
import setSeconds from 'date-fns/setSeconds'
import getSeconds from 'date-fns/getSeconds'
import setMilliseconds from 'date-fns/setMilliseconds'
import getMilliseconds from 'date-fns/getMilliseconds'
import formatDistance from 'date-fns/formatDistance'

export const getShortDate = (
  date: Date,
  opts?: {
    showFullYear?: boolean
  }
) => format(`M/d/${opts?.showFullYear ? 'yyyy' : 'yy'}`)(date)
export const getLongDate = (date: Date) => format('MM/dd/yyyy')(date)

export const getAge = (date: Date) => differenceInYears(new Date(), date)

interface FormatTimeAgoOptions {
  includeSeconds?: boolean
  dayGranularity?: boolean
}

export const formatTimeAgo = (
  date: Date | null,
  {includeSeconds = true, dayGranularity = false}: FormatTimeAgoOptions = {}
): string | null => {
  if (!date) return null
  const now = new Date()
  return formatDistance(
    dayGranularity ? withSameTimeOfDayAs(now)(date) : date,
    now,
    {
      addSuffix: true,
      includeSeconds,
    }
  )
}

export const formatISODate = (date: Date): string => format('yyyy-MM-dd')(date)

export const getMonth = (date: Date): number => baseGetMonth(date) + 1

export const getFormattedDateOfBirth = ({
  dob,
  t,
  showFullYear,
}: {
  dob: Date | null
  t: TFunction
  showFullYear?: boolean
}) =>
  dob
    ? `${getShortDate(dob, {showFullYear})} (${getAge(dob)} yrs)`
    : t('general.unknown')

export const toEasternTime = (utcDate: Date): Date =>
  utcToZonedTime(utcDate, 'America/New_York')

export const getDayOfMonth = getDate

export const isBeforeOrEqual = (date: Date, otherDate: Date) =>
  isBefore(date, otherDate) || isEqual(date, otherDate)
export const isAfterOrEqual = (date: Date, otherDate: Date) =>
  isAfter(date, otherDate) || isEqual(date, otherDate)

export const formatShortDateTimeEastern = (date: Date) =>
  `${format('M/d/yy HH:mm')(toEasternTime(date))} ET`

export const validDateOrNull = (date: Date): Date | null =>
  isValidDate(date) ? date : null

export const withSameTimeOfDayAs = (otherDate: Date) => (date: Date) => {
  let adjustedDate = date
  adjustedDate = setHours(adjustedDate, getHours(otherDate))
  adjustedDate = setMinutes(adjustedDate, getMinutes(otherDate))
  adjustedDate = setSeconds(adjustedDate, getSeconds(otherDate))
  return setMilliseconds(adjustedDate, getMilliseconds(otherDate))
}

export const getFormattedDateOfService = (dateOfService: Date | null) => {
  if (!dateOfService) return null
  let timeAgo = formatTimeAgo(dateOfService, {dayGranularity: true})!
  if (/seconds/i.test(timeAgo)) {
    timeAgo = 'today'
  }
  return `${getShortDate(dateOfService, {showFullYear: true})} (${timeAgo})`
}
