import React, {FC} from 'react'
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
} from '@material-ui/core'
import {
  flowMax,
  addDisplayName,
  addWrapper,
  addState,
  addProps,
  addHandlers,
  addStateHandlers,
} from 'ad-hok'
import {branchIfEmpty} from 'ad-hok-utils'
import {addInterval} from 'ad-hok-utils'
import {Grid} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'

import FormSection from 'components/FormSection'
import {addApplicationFormContext} from 'components/EditApplicationForm/applicationFormContext'
import {addTranslationHelpers} from 'utils/i18n'
import {
  addRemoteDocumentRequestsQuery,
  addTextMessagesQuery,
} from 'graphql/generated'
import {addLoadingIndicator} from 'utils/dataLoading'
import {makeClasses, addClasses} from 'theme'
import {TEXT_MESSAGES_QUERY} from 'graphql/queries'
import {getFormattedPhoneNumber} from 'utils/phone'
import {formatShortDateTimeEastern} from 'utils/date'
import {getLongDate} from 'utils/date'
import {addConfigContext} from 'utils/configContext'
import Box from 'components/Box'
import Checkbox from 'components/Checkbox'
import Tooltip from 'components/Tooltip'
import IconButton from 'components/IconButton'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import {
  addCancelDocumentRequestsMutation,
  addUpdatePersonMessageReadMutation,
} from 'graphql/generated'

const classes = makeClasses((theme) => ({
  boxBorder: {
    color: '#000000',
    width: 375,
    border: '1px solid lightgrey',
    borderRadius: 8,
    padding: 7,
    margin: 1,
  },
  tableHeader: {
    backgroundColor: '#3f51b5',
    color: 'white',
  },
  tableCellHeader: {
    padding: '0px 5px 0px 0px',
    color: 'white',
  },
  tableCellHeaderIcon: {
    color: 'white',
  },
  tableCell: {
    padding: '0px 5px 0px 0px',
    fontSize: '11px',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#dfe3e8',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
}))

export const MESSAGE_POLL_INTERVAL = 30 * 1000
export const DOCUMENT_REQUEST_POLL_INTERVAL = 15 * 1000

const CommunicationHistorySection: FC = flowMax(
  addDisplayName('CommunicationHistorySection'),
  addConfigContext,
  addProps(({config}) => ({
    documentTypes: config.remoteDocumentTypes,
  })),
  branchIfEmpty('documentTypes'),
  addWrapper((render) => (
    <FormSection labelTranslationKey="communicationHistory">
      {render()}
    </FormSection>
  )),
  addAppSnackbarContext,
  addApplicationFormContext,
  addProps(({application: {person: {id: personId}}}) => ({personId})),
  addState('cancelTip', 'setCancelTip', 'Cancel'),
  addState('open', 'setOpen', false),
  addCancelDocumentRequestsMutation({}),
  addUpdatePersonMessageReadMutation({}),
  addRemoteDocumentRequestsQuery({
    variables: ({personId}) => ({personId}),
  }),
  addTextMessagesQuery({
    variables: ({personId}) => ({personId}),
  }),
  addLoadingIndicator({}),
  addInterval(
    ({refetchTextMessages}) => () => {
      refetchTextMessages()
    },
    MESSAGE_POLL_INTERVAL,
    ['personId']
  ),
  addInterval(
    ({refetchRemoteDocumentRequests}) => () => {
      refetchRemoteDocumentRequests()
    },
    DOCUMENT_REQUEST_POLL_INTERVAL,
    ['personId']
  ),
  // addProps(({people: peopleWithUnreadTextMessages}) => ({
  //   numberOfPeopleWithUnreadTextMessages: peopleWithUnreadTextMessages.length,
  // })),
  addProps(
    ({personId}) => ({
      refetchQueries: [
        {
          query: TEXT_MESSAGES_QUERY,
          variables: {personId},
        },
      ],
    }),
    ['personId']
  ),
  addStateHandlers(
    {
      requestIds: new Set<string>(),
    },
    {
      toggleRequestId: ({requestIds}) => (requestId: string) => {
        const newRequestIds = new Set(requestIds)
        if (requestIds.has(requestId)) {
          newRequestIds.delete(requestId)
        } else {
          newRequestIds.add(requestId)
        }
        // console.log(newRequestIds)
        return {
          requestIds: newRequestIds,
        }
      },
      clearRequestIds: () => () => ({
        requestIds: new Set(),
      }),
    }
  ),
  addClasses(classes),
  addHandlers({
    onCancel: ({
      requestIds,
      clearRequestIds,
      mutateCancelDocumentRequests,
      showSnackbarMessage,
      refetchRemoteDocumentRequests,
    }) => () => {
      console.log(requestIds)
      mutateCancelDocumentRequests({
        variables: {
          requestIds: Array.from(requestIds),
        },
      })
        .then(() => {
          showSnackbarMessage(
            //           t('applicationForm.createLighticoWorkflowDialog.saved')
            'Document Request Cancelled: ' + requestIds.size
          )
        })
        .catch(() => {
          window.alert(
            //            t('applicationForm.createLighticoWorkflowDialog.failedToSave')
            'Failed to Cancel Request'
          )
        })

      clearRequestIds()
      refetchRemoteDocumentRequests()
    },
    updateStatus: ({
      personId,
      mutateUpdatePersonMessageRead,
      setOpen,
      open,
    }) => () => {
      console.log(personId)
      mutateUpdatePersonMessageRead({
        variables: {
          personId,
        },
      }).catch(() => {
        window.alert('Failed to Update Read Status')
      })
      setOpen(!open)
    },
  }),
  addProps(({remoteDocumentRequests}) => ({
    filteredRemoteDocumentRequests: remoteDocumentRequests.filter(
      (remoteRequest) =>
        !remoteRequest.requestReceivedAt && !remoteRequest.requestCancelled
    ),
  })),
  addTranslationHelpers,
  ({
    classes,
    filteredRemoteDocumentRequests,
    textMessages,
    requestIds,
    toggleRequestId,
    setCancelTip,
    cancelTip,
    onCancel,
    open,
    updateStatus,
    application,
  }) => (
    <div>
      <Box className={classes.boxBorder}>
        <Grid container>
          <Typography style={{fontStyle: 'Bold'}}>Pending Request</Typography>
          {filteredRemoteDocumentRequests.length > 0 && (
            <TableContainer component={Paper} style={{maxHeight: 300}}>
              <Table aria-label="Document Request Table">
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell
                      className={classes.tableCellHeader}
                      style={{maxWidth: 35}}
                    >
                      {setCancelTip('Cancel(' + requestIds.size + ')')}
                      {requestIds.size > 0 ? (
                        <Tooltip title={cancelTip}>
                          <IconButton onClick={onCancel}>
                            <DeleteIcon
                              className={classes.tableCellHeaderIcon}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Select to Cancel">
                          <IconButton>
                            <FilterListIcon
                              className={classes.tableCellHeaderIcon}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell
                      className={classes.tableCellHeader}
                      align="center"
                      style={{minWidth: 45}}
                    >
                      App ID
                    </TableCell>

                    <TableCell
                      className={classes.tableCellHeader}
                      align="center"
                    >
                      Document&nbsp;
                    </TableCell>
                    <TableCell
                      className={classes.tableCellHeader}
                      align="center"
                      style={{minWidth: 75}}
                    >
                      Phone
                    </TableCell>
                    <TableCell
                      className={classes.tableCellHeader}
                      align="center"
                    >
                      Sent&nbsp;
                    </TableCell>
                    <TableCell
                      className={classes.tableCellHeader}
                      align="center"
                    >
                      Request By
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRemoteDocumentRequests.map((remoteRequest) => (
                    <TableRow
                      className={classes.tableRow}
                      key={remoteRequest.id}
                    >
                      <TableCell
                        className={classes.tableCell}
                        padding="checkbox"
                        style={{maxWidth: 10}}
                      >
                        <Checkbox
                          style={{minWidth: 10}}
                          checked={requestIds.has(remoteRequest.id)}
                          onChange={() => {
                            toggleRequestId(remoteRequest.id)
                          }}
                          key={remoteRequest.id}
                        ></Checkbox>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {remoteRequest.applicationId}
                      </TableCell>
                      {remoteRequest.requestorType ? (
                        <TableCell className={classes.tableCell}>
                          {remoteRequest.requestorType}-
                          {remoteRequest.documentType}
                        </TableCell>
                      ) : (
                        <TableCell className={classes.tableCell}>
                          (E)-{remoteRequest.documentName}
                        </TableCell>
                      )}

                      <TableCell
                        className={classes.tableCell}
                        style={{width: 75}}
                      >
                        {getFormattedPhoneNumber(remoteRequest.phoneNumber)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getLongDate(remoteRequest.createdAt)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        [{remoteRequest.requestInitiatedBy.name}]
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {filteredRemoteDocumentRequests.length === 0 && (
            <Typography style={{fontStyle: 'italic'}}>
              &nbsp;&nbsp;- None
            </Typography>
          )}
        </Grid>
      </Box>
      <Box className={classes.boxBorder}>
        <Grid container style={{maxHeight: 400}}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={updateStatus}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Typography
            variant={
              application.person.unreadMessageStatus === true ? 'h6' : 'body1'
            }
          >
            Text Message(s){' '}
            {application.person.unreadMessageStatus === true ? (
              <MessageOutlinedIcon />
            ) : (
              ''
            )}
          </Typography>
          {textMessages.length === 0 && (
            <Typography style={{fontStyle: 'italic'}}>
              &nbsp;&nbsp;- None
            </Typography>
          )}
          <TableContainer component={Paper} style={{maxHeight: 300}}>
            <Table aria-label="Document Request Table">
              <TableBody>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  {textMessages.map((message) => (
                    <Card
                      variant="outlined"
                      key={message.id}
                      style={{width: 360}}
                    >
                      <CardContent
                        style={{
                          alignContent:
                            message.messageType === 'Send' ? 'right' : 'left',
                          padding: 0,
                          margin: 4,
                          width: 335,
                        }}
                      >
                        <Typography
                          color="secondary"
                          gutterBottom
                          style={{
                            textAlign:
                              message.messageType === 'Send' ? 'right' : 'left',
                            color:
                              message.messageType === 'Send'
                                ? '#8d6949'
                                : '#6a4b85',
                            fontSize: '12px',
                          }}
                        >
                          {message.applicationId
                            ? 'App ID:' + message.applicationId
                            : ''}
                          {message.messageInitiatedBy?.name
                            ? ' [' + message.messageInitiatedBy.name + ']  '
                            : ''}
                          {message.phoneNumber
                            ? getFormattedPhoneNumber(message.phoneNumber) +
                              '  '
                            : ''}
                          {formatShortDateTimeEastern(message.createdAt)}
                        </Typography>
                        <Typography
                          color="secondary"
                          style={{
                            textAlign:
                              message.messageType === 'Send' ? 'right' : 'left',
                            color:
                              message.messageType === 'Send'
                                ? '#8d6949'
                                : '#6a4b85',
                            fontSize: '12px',
                            alignContent: 'left',
                          }}
                        >
                          <b color="#F8FD99">{message.message}</b>
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                </Collapse>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
    </div>
  )
)
export default CommunicationHistorySection
