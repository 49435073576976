import React from 'react'
import {
  flowMax,
  addDisplayName,
  branch,
  renderNothing,
  addProps,
  addHandlers,
  addStateHandlers,
} from 'ad-hok'
import {FC} from 'react'
import ESignIcon from '@material-ui/icons/ExplicitOutlined'
import {branchIfNullish, addExtendedHandlers} from 'ad-hok-utils'

import IconButton from 'components/IconButton'
import Tooltip from 'components/Tooltip'
import {addTranslationHelpers} from 'utils/i18n'
import Dialog from 'components/Dialog'
import {addClasses, makeClasses} from 'theme'
import DialogTitle from 'components/DialogTitle'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import {addCreateApplicationDialog} from 'components/CreateApplicationDialog'

const classes = makeClasses((theme) => ({
  paper: {
    minWidth: 400,
  },
}))

interface ApplicationPromptProps {
  isShowing: boolean
  onClose: () => void
  onCreateApplicationClick: () => void
  onProceedClick: () => void
}

const ApplicationPrompt: FC<ApplicationPromptProps> = flowMax(
  addDisplayName('ApplicationPrompt'),
  addExtendedHandlers({
    onCreateApplicationClick: ({onClose}) => () => {
      onClose()
    },
    onProceedClick: ({onClose}) => () => {
      onClose()
    },
  }),
  addClasses(classes),
  addTranslationHelpers,
  ({
    isShowing,
    onClose,
    onCreateApplicationClick,
    onProceedClick,
    classes,
    t,
  }) => (
    <Dialog open={isShowing} onClose={onClose} classes={{paper: classes.paper}}>
      <DialogTitle>
        {t('esignSessionIcon.applicationPrompt.message')}
      </DialogTitle>
      <DialogActions>
        <Button onClick={onProceedClick} color="primary" variant="contained">
          {t('esignSessionIcon.applicationPrompt.proceed')}
        </Button>
        <Button
          onClick={onCreateApplicationClick}
          color="primary"
          variant="contained"
        >
          {t('esignSessionIcon.applicationPrompt.createApplication')}
        </Button>
      </DialogActions>
    </Dialog>
  )
)

interface Props {
  phoneNumberOrIncomeSource: {
    usedForEsign: boolean | null
    esignSessionUrl: string | null
  }
  className?: string
  shouldShowApplicationPrompt?: boolean
}

const EsignSessionIcon: FC<Props> = flowMax(
  addDisplayName('EsignSessionIcon'),
  branch(
    ({phoneNumberOrIncomeSource: {usedForEsign}}) => !usedForEsign,
    renderNothing()
  ),
  addProps(({phoneNumberOrIncomeSource: {esignSessionUrl}}) => ({
    esignSessionUrl,
  })),
  addTranslationHelpers,
  branchIfNullish('esignSessionUrl', {
    returns: ({t, className}) => (
      <Tooltip
        title={t('deletePhoneNumberDialog.esignLinkDisabled')}
        className={className}
      >
        <span>
          <IconButton onClick={() => {}} disabled>
            <ESignIcon />
          </IconButton>
        </span>
      </Tooltip>
    ),
  }),
  addHandlers({
    openEsignSession: ({esignSessionUrl}) => () => {
      window.open(esignSessionUrl, '_blank')
    },
  }),
  addStateHandlers(
    {
      isShowingApplicationPrompt: false,
    },
    {
      showApplicationPrompt: () => () => ({
        isShowingApplicationPrompt: true,
      }),
      hideApplicationPrompt: () => () => ({
        isShowingApplicationPrompt: false,
      }),
    }
  ),
  addHandlers({
    onClick: ({
      shouldShowApplicationPrompt,
      openEsignSession,
      showApplicationPrompt,
    }) => () => {
      if (!shouldShowApplicationPrompt) {
        openEsignSession()
        return
      }
      showApplicationPrompt()
    },
  }),
  branch(
    ({shouldShowApplicationPrompt}) => !!shouldShowApplicationPrompt,
    addCreateApplicationDialog,
    addProps({
      showCreateApplicationDialog: () => {},
    })
  ),
  ({
    esignSessionUrl,
    onClick,
    isShowingApplicationPrompt,
    hideApplicationPrompt,
    openEsignSession,
    showCreateApplicationDialog,
    className,
  }) => (
    <>
      <IconButton
        aria-label={esignSessionUrl}
        onClick={onClick}
        className={className}
      >
        <ESignIcon />
      </IconButton>
      <ApplicationPrompt
        isShowing={isShowingApplicationPrompt}
        onClose={hideApplicationPrompt}
        onCreateApplicationClick={showCreateApplicationDialog}
        onProceedClick={openEsignSession}
      />
    </>
  )
)

export default EsignSessionIcon
