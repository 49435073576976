import flatten from 'flat'
import {flow} from 'lodash/fp'

import {mapKeysWithKey} from 'utils/fp'

const flattenObjectKeys: typeof flatten = flow(
  flatten,
  mapKeysWithKey(((_value: any, key: string) =>
    key.replace(/\.(\d+)\./g, '[$1].')) as any)
) as any

export default flattenObjectKeys
