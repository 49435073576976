import React, {FC, ComponentProps} from 'react'
import {flowMax, addDisplayName} from 'ad-hok'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import cx from 'classnames'

import {addClasses, makeClasses} from 'theme'
import Tooltip from 'components/Tooltip'

const classes = makeClasses((theme) => ({
  info: {
    color: theme.palette.grey[500],
  },
}))

interface Props {
  info: ComponentProps<typeof Tooltip>['title']
  className?: string
}

const InfoTooltip: FC<Props> = flowMax(
  addDisplayName('InfoTooltip'),
  addClasses(classes),
  ({info, classes, className}) => (
    <Tooltip title={info}>
      <InfoOutlinedIcon className={cx(classes.info, className)} />
    </Tooltip>
  )
)

export default InfoTooltip
