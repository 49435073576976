/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {EditableFileStatus} from './globalTypes' // ====================================================
// GraphQL query operation: EditableFiles
// ====================================================

import {EditableFilesVariables as EditableFilesVariablesOrig} from '../types/EditableFiles'
import {EditableFiles as EditableFilesOrig} from '../types/EditableFiles'
import {EditableFiles_editableFiles as EditableFiles_editableFilesOrig} from '../types/EditableFiles'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {EditableFiles_editableFiles_sentFax as EditableFiles_editableFiles_sentFaxOrig} from '../types/EditableFiles'
import {EditableFiles_editableFiles_sentFax_countyFax as EditableFiles_editableFiles_sentFax_countyFaxOrig} from '../types/EditableFiles'
import {EditableFiles_editableFiles_sentFax_countyFax_state as EditableFiles_editableFiles_sentFax_countyFax_stateOrig} from '../types/EditableFiles'
export interface EditableFiles_editableFiles_sentFax_countyFax_state {
  __typename: 'State'
  stateCode: string
}
export const deserializeEditableFiles_editableFiles_sentFax_countyFax_state = (
  serialized: EditableFiles_editableFiles_sentFax_countyFax_stateOrig
): EditableFiles_editableFiles_sentFax_countyFax_state => ({...serialized})
export const serializeEditableFiles_editableFiles_sentFax_countyFax_state = (
  deserialized: EditableFiles_editableFiles_sentFax_countyFax_state
): EditableFiles_editableFiles_sentFax_countyFax_stateOrig => ({
  ...deserialized,
})
export interface EditableFiles_editableFiles_sentFax_countyFax {
  __typename: 'CountyFax'
  countyName: string
  state: EditableFiles_editableFiles_sentFax_countyFax_state
}
export const deserializeEditableFiles_editableFiles_sentFax_countyFax = (
  serialized: EditableFiles_editableFiles_sentFax_countyFaxOrig
): EditableFiles_editableFiles_sentFax_countyFax => ({
  ...serialized,
  state: deserializeEditableFiles_editableFiles_sentFax_countyFax_state(
    serialized.state
  ),
})
export const serializeEditableFiles_editableFiles_sentFax_countyFax = (
  deserialized: EditableFiles_editableFiles_sentFax_countyFax
): EditableFiles_editableFiles_sentFax_countyFaxOrig => ({
  ...deserialized,
  state: serializeEditableFiles_editableFiles_sentFax_countyFax_state(
    deserialized.state
  ),
})
export interface EditableFiles_editableFiles_sentFax {
  __typename: 'SentFax'
  toNumber: string | null
  faxStatus: string | null
  message: string | null
  updatedAt: Date
  countyFax: EditableFiles_editableFiles_sentFax_countyFax
}
export const deserializeEditableFiles_editableFiles_sentFax = (
  serialized: EditableFiles_editableFiles_sentFaxOrig
): EditableFiles_editableFiles_sentFax => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  countyFax: deserializeEditableFiles_editableFiles_sentFax_countyFax(
    serialized.countyFax
  ),
})
export const serializeEditableFiles_editableFiles_sentFax = (
  deserialized: EditableFiles_editableFiles_sentFax
): EditableFiles_editableFiles_sentFaxOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  countyFax: serializeEditableFiles_editableFiles_sentFax_countyFax(
    deserialized.countyFax
  ),
})
export interface EditableFiles_editableFiles {
  __typename: 'EditableFile'
  id: string
  fileKey: string
  filename: string
  updatedAt: Date
  status: EditableFileStatus
  allowSigning: boolean | null
  documentSendType: string | null
  faxEnabled: boolean | null
  sentFax: EditableFiles_editableFiles_sentFax | null
  isUnreviewed: boolean
}
export const deserializeEditableFiles_editableFiles = (
  serialized: EditableFiles_editableFilesOrig
): EditableFiles_editableFiles => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  sentFax:
    serialized.sentFax != null
      ? deserializeEditableFiles_editableFiles_sentFax(serialized.sentFax)
      : serialized.sentFax,
})
export const serializeEditableFiles_editableFiles = (
  deserialized: EditableFiles_editableFiles
): EditableFiles_editableFilesOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  sentFax:
    deserialized.sentFax != null
      ? serializeEditableFiles_editableFiles_sentFax(deserialized.sentFax)
      : deserialized.sentFax,
})
export interface EditableFiles {
  /**
   * List of editable files for an application
   */
  editableFiles: EditableFiles_editableFiles[]
}
export const deserializeEditableFiles = (
  serialized: EditableFilesOrig
): EditableFiles => ({
  ...serialized,
  editableFiles: serialized.editableFiles.map(
    deserializeEditableFiles_editableFiles
  ),
})
export const serializeEditableFiles = (
  deserialized: EditableFiles
): EditableFilesOrig => ({
  ...deserialized,
  editableFiles: deserialized.editableFiles.map(
    serializeEditableFiles_editableFiles
  ),
})
export interface EditableFilesVariables {
  applicationId: string
}
export const deserializeEditableFilesVariables = (
  serialized: EditableFilesVariablesOrig
): EditableFilesVariables => ({...serialized})
export const serializeEditableFilesVariables = (
  deserialized: EditableFilesVariables
): EditableFilesVariablesOrig => ({...deserialized})
