import {SimplePropsAdder, flowMax, addHandlers} from 'ad-hok'

import {SIGNED_DOCUMENT_UPLOAD_URL_QUERY} from 'graphql/queries'
import addApolloClient from 'utils/addApolloClient'
import {SignedDocumentUploadUrl} from 'graphql/types/SignedDocumentUploadUrl'

type AddSignedDocumentUploadUrlType = SimplePropsAdder<{
  fetchSignedDocumentUploadUrl: (fileKey: string) => Promise<string>
}>

const addFetchSignedDocumentUploadUrl: AddSignedDocumentUploadUrlType = flowMax(
  addApolloClient,
  addHandlers({
    fetchSignedDocumentUploadUrl: ({apolloClient}) => async (
      fileKey: string
    ) => {
      const result = await apolloClient.query<SignedDocumentUploadUrl>({
        query: SIGNED_DOCUMENT_UPLOAD_URL_QUERY,
        variables: {
          fileKey,
        },
        fetchPolicy: 'network-only',
      })

      return result.data.signedDocumentUploadUrl.url
    },
  })
)

export default addFetchSignedDocumentUploadUrl
