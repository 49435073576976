import React, {FC} from 'react'
import Typography from '@material-ui/core/Typography'

import {TextProps} from 'components/Heading'

const Caption: FC<TextProps> = ({variant = 'caption', ...props}) => (
  <Typography variant={variant} {...props} />
)

export default Caption
