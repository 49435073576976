import {InboundType} from 'graphql/deserializedTypes/globalTypes'

const WORKLIST_TAB_NAME_KEY = 'sageWorklistTab'

export const getLastWorklistTabName = (): string | null =>
  localStorage.getItem(WORKLIST_TAB_NAME_KEY)

export const setLastWorklistTabName = (tabName: string) => {
  localStorage.setItem(WORKLIST_TAB_NAME_KEY, tabName)
}

const getWorklistTabFiltersKey = (tabName: string) =>
  `sageWorklistTabFilters_${tabName}`

export const setWorklistTabFilters = (tabName: string, filterValues: any) => {
  localStorage.setItem(
    getWorklistTabFiltersKey(tabName),
    JSON.stringify(filterValues)
  )
}

export const getWorklistTabFilters = (tabName: string) => {
  const persistedFilterValues = localStorage.getItem(
    getWorklistTabFiltersKey(tabName)
  )
  if (!persistedFilterValues) return null
  return JSON.parse(persistedFilterValues)
}

const INBOUND_TYPE_KEY = 'sageWorklistInboundType'

export const getLastInboundType = (): InboundType | null =>
  localStorage.getItem(INBOUND_TYPE_KEY) as InboundType | null

export const setLastInboundType = (inboundType: InboundType) => {
  localStorage.setItem(INBOUND_TYPE_KEY, inboundType)
}
