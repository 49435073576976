import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addWrapper,
  addProps,
  addDefaultProps,
  addHandlers,
  addStateHandlers,
  addEffect,
} from 'ad-hok'
import LaunchIcon from '@material-ui/icons/Launch'
import {first} from 'lodash/fp'

import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogContent from 'components/DialogContent'
import Form from 'components/Form'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import {makeClasses, addClasses} from 'theme'
import {addTranslationHelpers} from 'utils/i18n'
import {makeFormSchema, FormCanonicalValuesFromSchema} from 'utils/form/schema'
import {
  makeBooleanCheckboxField,
  makeTextField,
  ValidatorTest,
  makeMultiSelectField,
} from 'utils/form/fieldTypes'
import CheckboxField from 'components/CheckboxField'
import MultilineTextField from 'components/MultilineTextField'
import TextField from 'components/TextField'
import MultiSelectField from 'components/MultiSelectField'
import {getCanonicalValues, getInitialValues} from 'utils/form/getValues'
import {addApplicationFormContext} from 'components/EditApplicationForm/applicationFormContext'
import IconButton from 'components/IconButton'
import {addOpenDocumentFileInNewTab} from 'components/DocumentFileLink'
import {addDocumentSubsectionContext} from 'components/EditApplicationForm/DataAndDocumentsSection/context'
import {
  addSubmitForm,
  addFormikSubmitFormCallback,
} from 'utils/addFormikHoistedState'
import {APPLICATION_QUERY} from 'graphql/queries'
import {addDocumentFilesContextProvider} from 'components/DocumentFiles/context'
import {CreateDocumentFileButton} from 'components/DocumentFiles/index'
import {addFormikTyped} from 'utils/form/formik'
import {
  getPersonFileDialogTitle,
  getPersonFileKeyPrefix,
} from 'components/PersonDetailFiles'
import {
  getApplicationFileDialogTitle,
  getApplicationFileKeyPrefix,
  getIsApplicationFileEditingFrozen,
} from 'components/EditApplicationForm/DocumentFilesSection'
import {DocumentFields} from 'graphql/deserializedTypes/DocumentFields'

const classes = makeClasses(() => ({
  contentContainer: {
    width: 600,
  },
  openInNewTabIcon: {
    color: '#2f80ed',
  },
  addFileButton: {
    position: 'relative',
    top: -32,
    marginBottom: -12,
  },
}))

const mustHaveAtLeastOneFileBeforeMarkingComplete: ValidatorTest = [
  'applicationDocumentDialog.mustHaveAtLeastOneFileBeforeMarkingComplete',
  function (value) {
    if (!value) return true
    const {fileIds} = this.parent
    if (!fileIds.length) return false
    return true
  },
]

export const applicationDocumentFormSchema = makeFormSchema({
  fields: {
    document: {
      documentType: makeTextField({isRequired: true}),
      complete: makeBooleanCheckboxField({
        validatorTest: mustHaveAtLeastOneFileBeforeMarkingComplete,
      }),
      fileIds: makeMultiSelectField({
        isNeverNull: true,
      }),
      notes: makeTextField(),
    },
  },
})

type AddAutoselectingNewlyAddedFile = <
  TProps extends {
    open: boolean
    fileIdOptions: {
      value: string
    }[]
  }
>(
  props: TProps
) => TProps & {
  onFileDialogOpened: () => void
}

const addAutoselectingNewlyAddedFile: AddAutoselectingNewlyAddedFile = flowMax(
  addStateHandlers(
    {
      shouldAutoselectNewlyAddedFile: false,
    },
    {
      onDocumentDialogClosed: () => () => ({
        shouldAutoselectNewlyAddedFile: false,
      }),
      onFileDialogOpened: () => () => ({
        shouldAutoselectNewlyAddedFile: true,
      }),
    }
  ),
  addEffect(
    ({open, onDocumentDialogClosed}) => () => {
      if (open) return
      onDocumentDialogClosed()
    },
    ['open']
  ),
  addFormikTyped(applicationDocumentFormSchema),
  addEffect(
    ({
      fileIdOptions,
      shouldAutoselectNewlyAddedFile,
      formik: {
        setFieldValue,
        values: {
          document: {fileIds},
        },
      },
    }) => () => {
      if (!shouldAutoselectNewlyAddedFile) return
      if (!fileIdOptions.length) return
      const newlyAddedFileId = first(fileIdOptions)!.value
      if (fileIds.includes(newlyAddedFileId)) return
      setFieldValue('document.fileIds', [newlyAddedFileId, ...fileIds])
    },
    ['fileIdOptions.length']
  )
)

interface Props {
  open: boolean
  onClose: () => void
  document?: DocumentFields
  accept?: string
  onSubmit: (opts: {
    canonicalValues: FormCanonicalValuesFromSchema<
      typeof applicationDocumentFormSchema
    >
  }) => void
  canEditDocumentType?: boolean
  documentType?: string
  isSubmitting: boolean
}

const ApplicationDocumentDialog: FC<Props> = flowMax(
  addDisplayName('ApplicationDocumentDialog'),
  addDefaultProps({
    canEditDocumentType: true,
  }),
  addSubmitForm,
  addProps(
    ({document, documentType}) => ({
      initialValues: documentType
        ? {
            document: {
              documentType,
              complete: false,
              fileIds: [],
              notes: '',
            },
          }
        : document
        ? {
            document: {
              ...document,
              fileIds: document.files.map(({id}) => id),
            },
          }
        : {
            document: {
              ...getCanonicalValues(
                getInitialValues(applicationDocumentFormSchema),
                applicationDocumentFormSchema
              ).document,
              fileIds: [],
            },
          },
    }),
    ['document', 'documentType']
  ),
  addDocumentSubsectionContext,
  addClasses(classes),
  addTranslationHelpers,
  addWrapper(
    (
      render,
      {
        classes,
        open,
        onClose,
        document,
        initialValues,
        submitForm,
        onSubmit,
        createDialogTitle,
        isSubmitting,
        t,
      }
    ) => (
      <Dialog
        open={open}
        onClose={onClose}
        data-testid={`application-document-dialog-${document?.id ?? 'new'}`}
        scroll="paper"
      >
        <DialogTitle>{createDialogTitle}</DialogTitle>
        <DialogContent className={classes.contentContainer} dividers>
          <Form
            name="applicationDocumentForm"
            schema={applicationDocumentFormSchema}
            onSubmitSuccess={onSubmit}
            initialValues={initialValues}
          >
            {render()}
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t('applicationDocumentDialog.cancel')}
          </Button>
          <Button
            onClick={submitForm}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {t('applicationDocumentDialog.save')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  ),
  addFormikSubmitFormCallback,
  addApplicationFormContext,
  addProps(
    ({personId, application: {householdMembers}, application}) => ({
      person: personId
        ? householdMembers.find(({person}) => person.id === personId)?.person ??
          (personId === application.person.id ? application.person : null)
        : null,
    }),
    ['personId', 'application']
  ),
  addProps(
    ({personId, person, application}) => ({
      documentFiles: !personId
        ? application.documentFiles
        : person?.documentFiles ?? [],
    }),
    ['personId', 'application', 'person']
  ),
  addProps(
    ({documentFiles}) => ({
      fileIdOptions: documentFiles.map(({id, name}) => ({
        value: id,
        label: name,
      })),
    }),
    ['documentFiles']
  ),
  addAutoselectingNewlyAddedFile,
  addOpenDocumentFileInNewTab,
  addHandlers({
    renderOptionIcon: ({documentFiles, openDocumentFileInNewTab, classes}) => ({
      value: fileId,
    }: {
      value: string
    }) => (
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          const documentFile = documentFiles.find(({id}) => id === fileId)
          if (!documentFile) return
          openDocumentFileInNewTab(documentFile)
        }}
        className={classes.openInNewTabIcon}
      >
        <LaunchIcon />
      </IconButton>
    ),
  }),
  addProps(({application: {id}, application, personId, person, t}) => ({
    isFileEditingFrozen:
      !personId && getIsApplicationFileEditingFrozen(application),
    refetchQueries: [
      {
        query: APPLICATION_QUERY,
        variables: {id},
      },
    ],
    additionalCreateDocumentFileMutateVariables: personId
      ? {personId}
      : {applicationId: id},
    fileKeyPrefix: personId
      ? getPersonFileKeyPrefix(personId)
      : getApplicationFileKeyPrefix(id),
    documentFileDialogTitle: personId
      ? getPersonFileDialogTitle({person, t})
      : getApplicationFileDialogTitle({t}),
    documentFiles: [],
  })),
  addDocumentFilesContextProvider,
  ({
    canEditDocumentType,
    fileIdOptions,
    renderOptionIcon,
    onFileDialogOpened,
    classes,
    accept,
  }) => (
    <>
      <TextField name="document.documentType" disabled={!canEditDocumentType} />
      <MultiSelectField
        name="document.fileIds"
        options={fileIdOptions}
        renderOptionIcon={renderOptionIcon}
      />
      <CreateDocumentFileButton
        className={classes.addFileButton}
        onDialogOpened={onFileDialogOpened}
        accept={accept}
      />
      <MultilineTextField name="document.notes" rows={5} rowsMax={20} />
      <CheckboxField name="document.complete" />
    </>
  )
)

export default ApplicationDocumentDialog
