import React, {FC, ComponentProps} from 'react'
import Link from '@material-ui/core/Link'
import {flowMax, addDisplayName} from 'ad-hok'
import cx from 'classnames'

import {makeClasses, addClasses} from 'theme'
import {highlightStyle} from 'components/Link'

const classes = makeClasses((/*theme*/) => ({
  link: highlightStyle,
  disabled: {
    color: '#999999',
    cursor: 'default',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

interface Props {
  variant?: ComponentProps<typeof Link>['variant']
  onClick: ComponentProps<typeof Link>['onClick']
  className?: string
  disabled?: boolean
}

const LinkButton: FC<Props> = flowMax(
  addDisplayName('LinkButton'),
  addClasses(classes),
  ({classes, variant = 'body1' as const, disabled, className, ...props}) => (
    <Link
      className={cx(classes.link, disabled && classes.disabled, className)}
      component="button"
      type="button"
      variant={variant}
      disabled={disabled}
      {...props}
    />
  )
)

export default LinkButton
