import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'

import {makeClasses, addClasses} from 'theme'
import {addPersonContext} from 'components/PersonDetail/personContext'
import AccountItem from 'components/AccountItem'

const classes = makeClasses((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
}))

const PersonAccounts: FC = flowMax(
  addDisplayName('PersonAccounts'),
  addPersonContext,
  addProps(({person: {accounts}, person}) => ({
    accounts: accounts.map((account) => ({
      ...account,
      person,
    })),
  })),
  addClasses(classes),
  ({accounts, classes}) => (
    <div className={classes.container}>
      {accounts.map((account) => (
        <AccountItem account={account} key={account.id} />
      ))}
    </div>
  )
)

export default PersonAccounts
