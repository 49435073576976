import React from 'react'
import {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addStateHandlers,
  addHandlers,
  addWrapper,
  AddWrapperRenderCallback,
  addMemoBoundary,
} from 'ad-hok'
import AddIcon from '@material-ui/icons/Add'
import {identity} from 'lodash'
import {ArrayHelpers} from 'formik'
import {addComponentBoundary} from 'ad-hok-utils'

import {addFormContext} from 'utils/form/context'
import {addFormik} from 'utils/form/formik'
import {addTranslationHelpers} from 'utils/i18n'
import {makeClasses, addClasses} from 'theme'
import FieldArray, {FieldArrayItem} from 'components/FieldArray'
import Button from 'components/Button'
import Grid from 'components/Grid'
import Body1 from 'components/Body1'
import NestedFormListItem from 'components/NestedFormListItem'
import ConfirmationDialog from 'components/ConfirmationDialog'
import DeductionDialog from 'components/DeductionDialog'
import {formatDateRange} from './IncomeSourceFieldArray'
import {formatCurrency} from 'utils/number'
import {addFormPrefixContext} from './FormPrefix'
import {addRightColumnContext} from 'components/EditPersonForm/rightColumnContext'
import {OnSubmitSuccessOptions} from 'components/Form'
import {ExtractFormSchemaFields} from 'utils/form/schema'
import {deductionFormSchema} from 'components/DeductionDialog/index'
import addDialogState from 'utils/addDialogState'
import {PersonFields_deductions} from 'graphql/deserializedTypes/PersonFields'

const classes = makeClasses((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
}))

type FormatDeductionTitleOptions = Pick<
  PersonFields_deductions,
  'deductionType' | 'amount' | 'deductionFrequency'
>
const formatDeductionTitle = ({
  deductionType,
  amount,
  deductionFrequency,
}: FormatDeductionTitleOptions) =>
  [
    deductionType,
    amount && deductionFrequency
      ? `${formatCurrency(amount)}/${deductionFrequency}`
      : null,
  ]
    .filter(identity)
    .join(', ')

interface DeductionItemProps {
  deduction: PersonFields_deductions
  index: number
  arrayHelpers: ArrayHelpers
  formPrefix: string
}

const DeductionItem: FC<DeductionItemProps> = flowMax(
  addDisplayName('DeductionItem'),
  addFormik,
  addStateHandlers(
    {
      isShowingEditDialog: false,
      isShowingDeleteDialog: false,
    },
    {
      showEditDialog: () => () => ({isShowingEditDialog: true}),
      hideEditDialog: () => () => ({isShowingEditDialog: false}),
      showDeleteDialog: () => () => ({isShowingDeleteDialog: true}),
      hideDeleteDialog: () => () => ({isShowingDeleteDialog: false}),
    }
  ),
  addProps(({formPrefix, index}) => ({
    itemName: `${formPrefix}deductions[${index}]`,
  })),
  addFormContext,
  addHandlers({
    onDialogSave: ({
      hideEditDialog,
      formik: {setFieldValue},
      itemName,
      setCollectionItemFieldsFreshness,
    }) => ({
      formValues: {deduction},
      fieldsFreshness,
    }: OnSubmitSuccessOptions<
      ExtractFormSchemaFields<typeof deductionFormSchema>
    >) => {
      setCollectionItemFieldsFreshness(itemName, fieldsFreshness.deduction)
      setFieldValue(itemName, deduction)
      hideEditDialog()
    },
    deleteDeduction: ({
      index,
      arrayHelpers,
      formPrefix,
      removeCollectionItemFieldsFreshness,
      removeCollectionItemFieldsUpdatedAt,
      hideDeleteDialog,
    }) => () => {
      removeCollectionItemFieldsFreshness(`${formPrefix}deductions`, index)
      removeCollectionItemFieldsUpdatedAt(`${formPrefix}deductions`, index)
      arrayHelpers.remove(index)
      hideDeleteDialog()
    },
  }),
  addTranslationHelpers,
  addClasses(classes),
  ({
    t,
    deduction,
    showEditDialog,
    hideEditDialog,
    isShowingEditDialog,
    onDialogSave,
    showDeleteDialog,
    hideDeleteDialog,
    isShowingDeleteDialog,
    deleteDeduction,
    itemName,
  }) => (
    <div data-testid={`deduction-${deduction.id ?? 'new'}`}>
      <NestedFormListItem
        title={formatDeductionTitle(deduction)}
        subtitle={formatDateRange(deduction)}
        onEditClick={showEditDialog}
        onDeleteClick={showDeleteDialog}
        itemName={itemName}
      />
      <DeductionDialog
        deduction={deduction}
        itemName={itemName}
        open={isShowingEditDialog}
        onClose={hideEditDialog}
        onSave={onDialogSave}
      />
      <ConfirmationDialog
        title={t('deleteDeductionDialog.title', {
          title: formatDeductionTitle(deduction),
        })}
        cancelText={t('deleteDeductionDialog.cancel')}
        confirmText={t('deleteDeductionDialog.confirm')}
        open={isShowingDeleteDialog}
        onCancel={hideDeleteDialog}
        onConfirm={deleteDeduction}
      />
    </div>
  )
)

interface Props {
  name: string
}

const DeductionFieldArray: FC<Props> = flowMax(
  addDisplayName('DeductionFieldArray'),
  addFormPrefixContext,
  addWrapper(
    (
      render: AddWrapperRenderCallback<{
        items: FieldArrayItem<PersonFields_deductions>[]
        arrayHelpers: ArrayHelpers
        formPrefix: string
      }>,
      props
    ) => (
      <FieldArray<PersonFields_deductions>
        name={props.name}
        render={(arrayHelpers, items) =>
          render({arrayHelpers, items, formPrefix: props.formPrefix})
        }
      />
    )
  ),
  addComponentBoundary,
  addRightColumnContext,
  addFormContext,
  addMemoBoundary(['name', 'items', 'formPrefix', 'formName']),
  addDialogState,
  addHandlers({
    onDialogSave: ({
      formPrefix,
      items,
      arrayHelpers,
      hideDialog,
      setCollectionItemFieldsFreshness,
    }) => ({
      formValues: {deduction},
      fieldsFreshness,
    }: OnSubmitSuccessOptions<
      ExtractFormSchemaFields<typeof deductionFormSchema>
    >) => {
      const itemName = `${formPrefix}deductions[${items.length}]`
      setCollectionItemFieldsFreshness(itemName, fieldsFreshness.incomeSource)
      arrayHelpers.push(deduction)
      hideDialog()
    },
  }),
  addTranslationHelpers,
  addProps(({formName, name, t}) => ({
    addLabel: t(`${formName}.collectionControls.${name}.add`),
    noItemsLabel: t(`${formName}.collectionControls.${name}.noItems`),
  })),
  addClasses(classes),
  ({
    classes,
    items,
    arrayHelpers,
    addLabel,
    noItemsLabel,
    showDialog,
    hideDialog,
    isShowingDialog,
    onDialogSave,
    formPrefix,
  }) => (
    <Grid className={classes.container}>
      {items.length === 0 && <Body1>{noItemsLabel}</Body1>}
      {items.map(({key, record}, index) => (
        <DeductionItem
          formPrefix={formPrefix}
          deduction={record}
          index={index}
          arrayHelpers={arrayHelpers}
          key={key}
        />
      ))}
      <Button startIcon={<AddIcon />} color="primary" onClick={showDialog}>
        {addLabel}
      </Button>
      <DeductionDialog
        open={isShowingDialog}
        onClose={hideDialog}
        onSave={onDialogSave}
      />
    </Grid>
  )
)

export default DeductionFieldArray
