/* eslint-disable */
import {UsersVariables as UsersVariablesOrig} from '../types/Users'
import {Users as UsersOrig} from '../types/Users'
import {Users_users as Users_usersOrig} from '../types/Users'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: Users
// ====================================================
export interface Users_users {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeUsers_users = (
  serialized: Users_usersOrig
): Users_users => ({...serialized})
export const serializeUsers_users = (
  deserialized: Users_users
): Users_usersOrig => ({...deserialized})
export interface Users {
  /**
   * List of users
   */
  users: Users_users[]
}
export const deserializeUsers = (serialized: UsersOrig): Users => ({
  ...serialized,
  users: serialized.users.map(deserializeUsers_users),
})
export const serializeUsers = (deserialized: Users): UsersOrig => ({
  ...deserialized,
  users: deserialized.users.map(serializeUsers_users),
})
export interface UsersVariables {
  includeBlockedUsersWithAssignedTasks?: boolean | null
  includeBlockedUsersWithAssignedTriages?: boolean | null
  includeBlockedUsersWithAssignedApplications?: boolean | null
  includeBlockedUsersWithAssignedInbounds?: boolean | null
}
export const deserializeUsersVariables = (
  serialized: UsersVariablesOrig
): UsersVariables => ({...serialized})
export const serializeUsersVariables = (
  deserialized: UsersVariables
): UsersVariablesOrig => ({...deserialized})
