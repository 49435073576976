import parseISO from 'date-fns/parseISO'
import {getYear} from 'date-fns/fp'
import parse from 'date-fns/parse'
import {isNumber, isFunction, isArray} from 'lodash/fp'
import {round, range} from 'lodash'
import {TFunction} from 'i18next'
import {StringSchema, DateSchema, NumberSchema, TestContext} from 'yup'

import {makeFormCollection, FormValues} from 'utils/form/schema'
import {formatISODate} from 'utils/date'
import {snakeCaseToSentenceCase} from 'utils/string'
import {
  NjmmisCheckResult,
  McdType,
  SlideType,
  McdSubtype,
} from 'graphql/deserializedTypes/globalTypes'
import {FacilityFields} from 'graphql/deserializedTypes/FacilityFields'
import {DepartmentFields} from 'graphql/deserializedTypes/DepartmentFields'
import {
  Config_config_applicationBenefits,
  Config_config_charityCareTypes,
} from 'graphql/deserializedTypes/Config'
import {
  makeOptionsFromRawEnums,
  makeOptionsFromNamedEnums,
} from 'utils/enumOptions'

export type ValidatorTest = [
  string,
  (this: TestContext, value: string) => boolean | Promise<boolean>
]
export type FieldType<TFormData, TCanonicalData = TFormData> = {
  initialValue: TFormData
  isRequired: boolean
  mapToCanonical: (data: TFormData) => TCanonicalData
  mapFromCanonical: (data: TCanonicalData | null) => TFormData
  type: 'text' | 'datetime' | 'date' | 'boolean' | 'email'
  options?: SelectFieldOption[] | SelectFieldOptionsCallback
  validRegex?: RegExp
  validatorTest?: ValidatorTest
  validatorTests?: ValidatorTest[]
  validator?: ({
    t,
  }: {
    t: TFunction
  }) => StringSchema<string> | DateSchema<Date> | NumberSchema<number>
}

interface MakeFieldOptions<TRequired extends boolean> {
  isRequired?: TRequired
  initialValue?: string
  validator?: FieldType<any>['validator']
  validatorTest?: ValidatorTest
}

type MakeFieldOptionsNonGeneric = MakeFieldOptions<boolean>

type GetTMutateData<TRequired extends boolean, TBase> = TRequired extends true
  ? TBase
  : TBase | null

export const makeTextField = <TRequired extends boolean>(
  opts: MakeFieldOptions<TRequired> = {}
): FieldType<string, GetTMutateData<TRequired, string>> => ({
  initialValue: '',
  isRequired: opts.isRequired ?? false,
  mapToCanonical: (data) => (data || null) as GetTMutateData<TRequired, string>,
  mapFromCanonical: (data) => data ?? '',
  type: 'text',
  validatorTest: opts.validatorTest,
})

export const mapToCanonicalSsn = (data: string | null) =>
  data ? data.replace(/[-\s]/g, '') : null

// per https://dev.to/stephencweiss/validating-social-security-numbers-47mo
const SSN_REGEX = /^(?!(000|666|9))(\d{3}-?(?!(00))\d{2}-?(?!(0000))\d{4})$/
export const makeSsnField = ({
  isRequired = false,
}: MakeFieldOptionsNonGeneric = {}): FieldType<string, string | null> => ({
  initialValue: '',
  isRequired,
  mapToCanonical: mapToCanonicalSsn,
  mapFromCanonical: (data) => data ?? '',
  type: 'text',
  validRegex: SSN_REGEX,
})

const ZIP_CODE_REGEX = /^\d{5}(-\d{4})?$/
export const makeZipCodeField = ({
  isRequired = false,
}: MakeFieldOptionsNonGeneric = {}): FieldType<string, string | null> => ({
  initialValue: '',
  isRequired,
  mapToCanonical: (data) => data || null,
  mapFromCanonical: (data) => data ?? '',
  type: 'text',
  validRegex: ZIP_CODE_REGEX,
})

const YEAR_REGEX = /^\d{4}$/
export const makeYearField = ({
  isRequired = false,
}: MakeFieldOptionsNonGeneric = {}): FieldType<string, string | null> => ({
  initialValue: '',
  isRequired,
  mapToCanonical: (data) => data || null,
  mapFromCanonical: (data) => data ?? '',
  type: 'text',
  validRegex: YEAR_REGEX,
})

export const mapToCanonicalPhone = <
  TMutateData extends string | null = string | null
>(
  data: string | null
) => (data ? data.replace(/[-\s()]/g, '') : null) as TMutateData

const PHONE_REGEX = /^\(?\s*\d{3}\s*\)?\s*-?\s*\d{3}\s*-?\s*\d{4}\s*$/
export const makePhoneField = <TRequired extends boolean>(
  opts: MakeFieldOptions<TRequired> = {}
): FieldType<string, GetTMutateData<TRequired, string>> => ({
  initialValue: '',
  isRequired: opts.isRequired ?? false,
  mapToCanonical: mapToCanonicalPhone,
  mapFromCanonical: (data) => data ?? '',
  type: 'text',
  validRegex: PHONE_REGEX,
  validatorTest: opts.validatorTest,
})

export interface SelectFieldOption {
  label: string
  value: string
}

interface SelectFieldPassedOption {
  value: string
  label?: string
}

export type SelectFieldOptionsCallback = (opts: {
  values: FormValues<any>
  t: TFunction
}) => SelectFieldOption[]

type SelectFieldOptionsOption =
  | SelectFieldPassedOption[]
  | SelectFieldOptionsCallback

type WithPopulatedDefaultLabelsType = (
  passedOptions: SelectFieldOptionsOption
) => SelectFieldOption[] | SelectFieldOptionsCallback

const withPopulatedDefaultLabels: WithPopulatedDefaultLabelsType = (
  passedOptions
) =>
  isFunction(passedOptions)
    ? passedOptions
    : passedOptions.map(({value, label = value}) => ({label, value}))

interface MakeSelectFieldOptions<TRequired extends boolean>
  extends MakeFieldOptions<TRequired> {
  initialValue?: string
  options?: SelectFieldOptionsOption
  defaultsToSingularOption?: boolean
  validatorTest?: ValidatorTest
  validatorTests?: ValidatorTest[]
}

const getInitialValue = <TRequired extends boolean>({
  initialValue,
  options,
  defaultsToSingularOption,
}: MakeSelectFieldOptions<TRequired>) => {
  if (initialValue) {
    return initialValue
  }

  if (defaultsToSingularOption && isArray(options) && options.length === 1) {
    return options[0].value
  }

  return ''
}

export const makeSelectField = <TRequired extends boolean>(
  opts: MakeSelectFieldOptions<TRequired>
): FieldType<string, GetTMutateData<TRequired, string>> => ({
  initialValue: getInitialValue(opts),
  isRequired: opts.isRequired ?? false,
  validatorTest: opts.validatorTest,
  validatorTests: opts.validatorTests,
  mapToCanonical: (data) => (data || null) as GetTMutateData<TRequired, string>,
  mapFromCanonical: (data) => data ?? '',
  type: 'text',
  options: opts.options
    ? withPopulatedDefaultLabels(opts.options)
    : opts.options,
})

export const makeNumericSelectField = <TRequired extends boolean>(
  opts: MakeSelectFieldOptions<TRequired>
): FieldType<string, GetTMutateData<TRequired, number>> => ({
  initialValue: getInitialValue(opts),
  isRequired: opts.isRequired ?? false,
  validatorTest: opts.validatorTest,
  validatorTests: opts.validatorTests,
  mapToCanonical: (data) =>
    (data?.length > 0 ? parseInt(data) : null) as GetTMutateData<
      TRequired,
      number
    >,
  mapFromCanonical: (data) =>
    data !== null && data !== undefined ? `${data}` : '',
  type: 'text',
  options: opts.options
    ? withPopulatedDefaultLabels(opts.options)
    : opts.options,
})

export const getStringValuesFromEnumType = <
  TEnum extends {
    [key: string]: string
  }
>(
  enumValue: TEnum,
  opts?: {
    shouldConvertCase?: boolean
  }
): string[] =>
  Object.values(enumValue).map(
    opts?.shouldConvertCase ? snakeCaseToSentenceCase : (x) => x
  )

export const getSelectFieldOptionsFromEnumType = <
  TEnum extends {
    [key: string]: string
  }
>(
  enumValue: TEnum,
  opts?: {
    shouldConvertCase?: boolean
  }
): SelectFieldOption[] =>
  getStringValuesFromEnumType(enumValue, opts).map((value) => ({
    value,
    label: value,
  }))

interface MakeMultiSelectFieldOptions<TNeverNull extends boolean> {
  initialValue?: string[]
  options?: SelectFieldPassedOption[]
  isNeverNull?: TNeverNull
}

export const makeMultiSelectField = <TNeverNull extends boolean>(
  opts: MakeMultiSelectFieldOptions<TNeverNull>
): FieldType<string[], GetTMutateData<TNeverNull, string[]>> => {
  const {initialValue = [], isNeverNull = false, options} = opts
  return {
    initialValue,
    isRequired: false,
    mapToCanonical: (data) =>
      (data.length === 0 ? (isNeverNull ? [] : null) : data) as GetTMutateData<
        TNeverNull,
        string[]
      >,
    mapFromCanonical: (data) => data ?? [],
    type: 'text',
    options: options ? withPopulatedDefaultLabels(options) : options,
  }
}

const stateAbbreviations = [
  'NJ',
  'NY',
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NM',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]

export const makeStateField = (opts: MakeFieldOptionsNonGeneric = {}) =>
  makeSelectField({
    ...opts,
    options: stateAbbreviations.map((stateAbbreviation) => ({
      value: stateAbbreviation,
    })),
  })

export const parseDate = parseISO
export const isoDateFormat = 'yyyy-MM-dd'

const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/

interface MakeDateFieldOptions<TRequired extends boolean>
  extends MakeFieldOptions<TRequired> {
  defaultToToday?: boolean
  validatorTest?: ValidatorTest
  validatorTests?: ValidatorTest[]
}

export const makeDateField = <TRequired extends boolean>(
  opts: MakeDateFieldOptions<TRequired> = {}
): FieldType<string, GetTMutateData<TRequired, Date>> => {
  const {
    isRequired = false,
    defaultToToday = false,
    initialValue = defaultToToday ? formatISODate(new Date()) : '',
    validator,
    validatorTest = [
      'form.invalidDate',
      (value) => {
        try {
          if (!value) return true
          const parsed = parseDate(value)
          return !/Invalid/.test(parsed.toString())
        } catch (e) {
          return false
        }
      },
    ],
    validatorTests,
  } = opts
  return {
    initialValue,
    isRequired,
    mapToCanonical: (data) =>
      (data ? parseDate(data) : null) as GetTMutateData<TRequired, Date>,
    mapFromCanonical: (data) => (data ? formatISODate(data!) : ''),
    type: 'text',
    validRegex: DATE_REGEX,
    validatorTest,
    validatorTests,
    validator,
  }
}

const TIME_REGEX = /^\d{1,2}:\d{1,2}$/
const timeFormat = 'HH:mm'
const parseTime = (value: string) => parse(value, timeFormat, new Date())

export const makeTimeField = ({
  isRequired = false,
}: MakeFieldOptionsNonGeneric = {}): FieldType<string, string | null> => ({
  initialValue: '',
  isRequired,
  mapToCanonical: (data) => data || null,
  mapFromCanonical: (data) => data ?? '',
  type: 'text',
  validRegex: TIME_REGEX,
  validatorTest: [
    'form.invalidTime',
    (value) => {
      try {
        if (!value) return true
        const parsed = parseTime(value)
        return !/Invalid/.test(parsed.toString())
      } catch (e) {
        return false
      }
    },
  ],
})

export const makeBooleanField = <TRequired extends boolean>(
  opts: MakeFieldOptions<TRequired> = {}
): FieldType<'true' | 'false' | null, GetTMutateData<TRequired, boolean>> => ({
  initialValue: null,
  isRequired: opts.isRequired ?? false,
  mapToCanonical: (data) =>
    (data === 'true'
      ? true
      : data === 'false'
      ? false
      : data) as GetTMutateData<TRequired, boolean>,
  mapFromCanonical: (data) =>
    (data === true ? 'true' : data === false ? 'false' : data) as
      | 'true'
      | 'false'
      | null,
  type: 'boolean',
})

interface MakeBooleanCheckboxFieldOptions extends MakeFieldOptionsNonGeneric {
  validatorTest?: ValidatorTest
}

export const makeBooleanCheckboxField = ({
  isRequired = false,
  validatorTest,
}: MakeBooleanCheckboxFieldOptions = {}): FieldType<
  boolean,
  boolean | null
> => ({
  initialValue: false,
  isRequired,
  mapToCanonical: (data) => data,
  mapFromCanonical: (data) => !!data,
  type: 'boolean',
  validatorTest,
})

export const makeNumberField = ({
  isRequired = false,
  validator,
}: MakeFieldOptionsNonGeneric = {}): FieldType<string, number | null> => ({
  initialValue: '',
  isRequired,
  mapToCanonical: (data) =>
    isNumber(data) ? data : data ? parseInt(data) : null,
  mapFromCanonical: (data) => (data == null ? '' : `${data}`),
  type: 'text',
  validator,
})

const CURRENCY_REGEX = /^\d+(?:\.\d{2})?$/
export const makeCurrencyField = ({
  isRequired = false,
}: MakeFieldOptionsNonGeneric = {}): FieldType<string, number | null> => ({
  initialValue: '',
  isRequired,
  mapToCanonical: (data) => (data ? parseFloat(data) : null),
  mapFromCanonical: (data) =>
    data == null ? '' : data === round(data) ? `${data}` : data.toFixed(2),
  type: 'text',
  validRegex: CURRENCY_REGEX,
})

export const makeEmailField = ({
  isRequired = false,
}: MakeFieldOptionsNonGeneric = {}): FieldType<string, string | null> => ({
  initialValue: '',
  isRequired,
  mapToCanonical: (data) => data || null,
  mapFromCanonical: (data) => data ?? '',
  type: 'email',
})

export const makeGenderField = () =>
  makeSelectField({
    options: [
      {
        value: 'Male',
      },
      {
        value: 'Female',
      },
      {
        value: 'Unknown/other',
      },
    ],
  })

export const makeLanguageField = (opts?: {validatorTest?: ValidatorTest}) =>
  makeSelectField({
    options: [
      {
        value: 'English',
      },
      {
        value: 'Spanish',
      },
    ],
    validatorTest: opts?.validatorTest,
  })

export const makeServiceTimeZoneField = () =>
  makeSelectField({
    isRequired: true,
    initialValue: 'America/New_York',
    options: [
      {
        label: 'Eastern',
        value: 'America/New_York',
      },
    ],
  })

export const makeCharityCareTypeField = <TRequired extends boolean>({
  charityCareTypes,
  ...opts
}: Pick<
  MakeSelectFieldOptions<TRequired>,
  'defaultsToSingularOption' | 'validatorTest' | 'isRequired'
> & {
  charityCareTypes: Config_config_charityCareTypes[]
}) =>
  makeSelectField({
    ...opts,
    options: makeOptionsFromNamedEnums(charityCareTypes),
  })

export const makeFacilityField = <TRequired extends boolean>({
  facilities,
  ...opts
}: Pick<
  MakeSelectFieldOptions<TRequired>,
  'defaultsToSingularOption' | 'validatorTest' | 'isRequired'
> & {
  facilities: FacilityFields[]
}) =>
  makeSelectField({
    ...opts,
    options: makeOptionsFromNamedEnums(facilities),
  })

export const makeDepartmentField = ({
  departments,
  defaultsToSingularOption,
}: {
  departments: DepartmentFields[]
  defaultsToSingularOption?: boolean
}) =>
  makeSelectField({
    options: makeOptionsFromNamedEnums(departments),
    isRequired: true,
    defaultsToSingularOption,
  })

export const makeBenefitField = <TRequired extends boolean>({
  benefits,
  t,
  ...opts
}: Pick<
  MakeSelectFieldOptions<TRequired>,
  'defaultsToSingularOption' | 'validatorTest' | 'isRequired'
> & {
  benefits: Config_config_applicationBenefits[]
  t: TFunction
}) =>
  makeSelectField({
    ...opts,
    options: makeOptionsFromRawEnums(
      benefits.map(({benefit}) => benefit),
      (benefit) => t(`applications.benefitLabels.${benefit}`)
    ),
  })

export const INSURANCE_TYPES = [
  'Self pay',
  'Charity care',
  'Charity care - pend',
  'Commercial',
  'Medicare',
  'Medicaid',
  'Medicaid - pend',
  'Other',
  'Unknown',
]

export const makeInsuranceTypeField = () =>
  makeSelectField({
    options: INSURANCE_TYPES.map((value) => ({value})),
    isRequired: true,
  })

export const makeReferralSourceField = () =>
  makeSelectField({
    options: [
      {
        value: 'Live',
      },
      {
        value: 'Report',
      },
      {
        value: 'Retro',
      },
    ],
  })

export const INCOME_SOURCE_TYPE_UNEMPLOYMENT = 'Unemployment Compensation'
export const INCOME_SOURCE_TYPE_SSI = 'Supplemental Security Income (SSI)'
export const INCOME_SOURCE_TYPE_VETERANS_BENEFITS = "Veteran's Benefits"
export const INCOME_SOURCE_TYPE_PUBLIC_ASSISTANCE = 'Public Assistance'
export const INCOME_SOURCE_TYPE_CASH_SUPPORT =
  '(MCD only) Cash support (if >$140 and given by tax filer claiming the client)'
export const INCOME_SOURCE_TYPE_CASH_SUPPORT_CHARITY_CARE =
  '(CC only) Cash support'
export const INCOME_SOURCE_TYPE_SOCIAL_SECURITY_DISABILITY =
  'Social Security Disability (SSDI)'
export const INCOME_SOURCE_TYPE_SOCIAL_SECURITY_RETIREMENT =
  'Social Security Retirement'
export const INCOME_SOURCE_TYPE_ALIMONY = 'Alimony/Child support'
export const INCOME_SOURCE_TYPE_NET_FARMING = 'Net Farming/Fishing'
export const INCOME_SOURCE_TYPE_WORKERS_COMPENSATION = 'Workers Compensation'
export const INCOME_SOURCE_TYPE_INSURANCE_PAYMENTS = 'Insurance payments'
export const INCOME_SOURCE_TYPE_ANNUITY_PAYMENTS = 'Annuity payments'
export const INCOME_SOURCE_TYPE_INTEREST = 'Interest/Dividends'
export const INCOME_SOURCE_TYPE_LOTTERY = 'Lottery or Gambling Lump Sums >$80k'
export const INCOME_SOURCE_TYPE_PENSION = 'Pension/retirement account/annuity'
export const INCOME_SOURCE_TYPE_RENTAL_INCOME = 'Rental income'
export const INCOME_SOURCE_TYPE_STATE_DISABILITY = 'State Disability'
export const INCOME_SOURCE_TYPE_OTHER = 'Other'
export const INCOME_SOURCE_TYPE_SALARY_WAGES = 'Salary/Wages (has paystub)'
export const INCOME_SOURCE_TYPE_FREELANCE = 'Freelance/Paid Cash'
export const INCOME_SOURCE_TYPE_SELF_EMPLOYED = 'Self employed/Business owner'
export const makeIncomeTypeField = () =>
  makeSelectField({
    options: [
      {value: INCOME_SOURCE_TYPE_SALARY_WAGES},
      {value: INCOME_SOURCE_TYPE_FREELANCE},
      {value: INCOME_SOURCE_TYPE_SELF_EMPLOYED},
      {value: INCOME_SOURCE_TYPE_CASH_SUPPORT},
      {value: INCOME_SOURCE_TYPE_CASH_SUPPORT_CHARITY_CARE},
      {value: INCOME_SOURCE_TYPE_SOCIAL_SECURITY_DISABILITY},
      {value: INCOME_SOURCE_TYPE_SOCIAL_SECURITY_RETIREMENT},
      {value: INCOME_SOURCE_TYPE_SSI},
      {value: INCOME_SOURCE_TYPE_UNEMPLOYMENT},
      {value: INCOME_SOURCE_TYPE_WORKERS_COMPENSATION},
      {value: INCOME_SOURCE_TYPE_PUBLIC_ASSISTANCE},
      {value: INCOME_SOURCE_TYPE_VETERANS_BENEFITS},
      {value: INCOME_SOURCE_TYPE_ALIMONY},
      {value: INCOME_SOURCE_TYPE_NET_FARMING},
      {value: INCOME_SOURCE_TYPE_INSURANCE_PAYMENTS},
      {value: INCOME_SOURCE_TYPE_ANNUITY_PAYMENTS},
      {value: INCOME_SOURCE_TYPE_INTEREST},
      {value: INCOME_SOURCE_TYPE_LOTTERY},
      {value: INCOME_SOURCE_TYPE_PENSION},
      {value: INCOME_SOURCE_TYPE_RENTAL_INCOME},
      {value: INCOME_SOURCE_TYPE_STATE_DISABILITY},
      {value: INCOME_SOURCE_TYPE_OTHER},
    ],
  })

type IncomeSourceType = string
type CharityCareIncomeSourceCategory = string
export const charityCareIncomeSourceCategoryMap: {
  [incomeSourceCategory: string]: IncomeSourceType[]
} = {
  'Salary/Wages before deductions': [INCOME_SOURCE_TYPE_SALARY_WAGES],
  'Public Assistance': [INCOME_SOURCE_TYPE_PUBLIC_ASSISTANCE],
  'Social Security Benefits': [
    INCOME_SOURCE_TYPE_SOCIAL_SECURITY_DISABILITY,
    INCOME_SOURCE_TYPE_SOCIAL_SECURITY_RETIREMENT,
    INCOME_SOURCE_TYPE_SSI,
  ],
  "Unemployment & Workmen's Comp": [
    INCOME_SOURCE_TYPE_UNEMPLOYMENT,
    INCOME_SOURCE_TYPE_WORKERS_COMPENSATION,
  ],
  "Veteran's Benefits": [INCOME_SOURCE_TYPE_VETERANS_BENEFITS],
  'Alimony/Child support': [INCOME_SOURCE_TYPE_ALIMONY],
  'Other Monetary Support': [INCOME_SOURCE_TYPE_CASH_SUPPORT_CHARITY_CARE],
  'Pension Payments': [INCOME_SOURCE_TYPE_PENSION],
  'Insurance or Annuity Payments': [
    INCOME_SOURCE_TYPE_INSURANCE_PAYMENTS,
    INCOME_SOURCE_TYPE_ANNUITY_PAYMENTS,
  ],
  'Interest/Dividends': [INCOME_SOURCE_TYPE_INTEREST],
  'Rental income': [INCOME_SOURCE_TYPE_RENTAL_INCOME],
  'Net Business income (self employed/verified by independent source)': [
    INCOME_SOURCE_TYPE_SELF_EMPLOYED,
  ],
  Other: [
    INCOME_SOURCE_TYPE_FREELANCE,
    INCOME_SOURCE_TYPE_NET_FARMING,
    INCOME_SOURCE_TYPE_LOTTERY,
    INCOME_SOURCE_TYPE_STATE_DISABILITY,
    INCOME_SOURCE_TYPE_OTHER,
  ],
}

export const INCOME_FREQUENCY_LUMP_SUM = 'Lump sum'
export const INCOME_FREQUENCY_MONTHLY = 'Monthly'
export const INCOME_FREQUENCY_WEEKLY = 'Weekly'
export const INCOME_FREQUENCY_BIWEEKLY = 'Biweekly'
export const INCOME_FREQUENCY_TWICE_A_MONTH = 'Twice a month'
export const makeIncomeFrequencyField = () =>
  makeSelectField({
    options: [
      {value: INCOME_FREQUENCY_MONTHLY},
      {value: INCOME_FREQUENCY_WEEKLY},
      {value: INCOME_FREQUENCY_BIWEEKLY},
      {value: INCOME_FREQUENCY_TWICE_A_MONTH},
      {value: INCOME_FREQUENCY_LUMP_SUM},
    ],
  })

export const makeIncomeWorkTypeField = () =>
  makeSelectField({
    options: [{value: 'Full time'}, {value: 'Part time'}],
  })

export const DEDUCTION_TYPE_SELF_EMPLOYMENT_EXPENSES =
  'Self-employment business expenses'
export const DEDUCTION_TYPE_ALIMONY =
  'Alimony Paid (agreement finalized prior to 2019)'
export const DEDUCTION_TYPE_STUDENT_LOAN_INTEREST = 'Student loan interest'
export const DEDUCTION_TYPE_HEALTH_SAVINGS_ACCOUNT = 'Health savings account'
export const DEDUCTION_TYPE_IRA_DEDUCTION = 'IRA deduction'
export const DEDUCTION_TYPE_MOVING_EXPENSES =
  'Moving expenses (Armed Forces Members only)'
export const DEDUCTION_TYPE_EDUCATOR_EXPENSES = 'Educator expenses'
export const DEDUCTION_TYPE_SELF_EMPLOYMENT_TAX =
  'Deductible part of Self-Employment tax'
export const DEDUCTION_TYPE_SELF_EMPLOYED_IRA =
  'Self-employed SEP, SIMPLE, or other qualified retirement plans'
export const DEDUCTION_TYPE_SELF_EMPLOYED_HEALTH_INSURANCE =
  'Self-employed health insurance'
export const DEDUCTION_TYPE_OTHER = 'Other deductions'
export const makeDeductionTypeField = () =>
  makeSelectField({
    options: [
      {value: DEDUCTION_TYPE_SELF_EMPLOYMENT_EXPENSES},
      {value: DEDUCTION_TYPE_ALIMONY},
      {value: DEDUCTION_TYPE_STUDENT_LOAN_INTEREST},
      {value: DEDUCTION_TYPE_HEALTH_SAVINGS_ACCOUNT},
      {value: DEDUCTION_TYPE_IRA_DEDUCTION},
      {value: DEDUCTION_TYPE_MOVING_EXPENSES},
      {value: DEDUCTION_TYPE_EDUCATOR_EXPENSES},
      {value: DEDUCTION_TYPE_SELF_EMPLOYMENT_TAX},
      {value: DEDUCTION_TYPE_SELF_EMPLOYED_IRA},
      {value: DEDUCTION_TYPE_SELF_EMPLOYED_HEALTH_INSURANCE},
      {value: DEDUCTION_TYPE_OTHER},
    ],
  })

export const ASSET_TYPE_CASH = 'Cash'
export const ASSET_TYPE_SAVINGS_ACCOUNTS = 'Savings accounts'
export const ASSET_TYPE_CHECKING_ACCOUNTS = 'Checking accounts'
export const ASSET_TYPE_CERTIFICATES_OF_DEPOSIT_IRA =
  'Certificates of deposit/IRA'
export const ASSET_TYPE_OTHER =
  'Other assets (treasury bills, negotiable paper, corporate stocks and bonds)'
export const ASSET_TYPE_EQUITY_IN_REAL_ESTATE =
  'Equity in real estate (other than primary residence)'
export const allAssetTypes = [
  ASSET_TYPE_CASH,
  ASSET_TYPE_SAVINGS_ACCOUNTS,
  ASSET_TYPE_CHECKING_ACCOUNTS,
  ASSET_TYPE_CERTIFICATES_OF_DEPOSIT_IRA,
  ASSET_TYPE_EQUITY_IN_REAL_ESTATE,
  ASSET_TYPE_OTHER,
]
export const makeAssetTypeField = () =>
  makeSelectField({
    options: allAssetTypes.map((value) => ({value})),
  })

export const getMonthSelectFieldOptions = (): SelectFieldOption[] => [
  {
    value: '01',
    label: 'Jan - 01',
  },
  {
    value: '02',
    label: 'Feb - 02',
  },
  {
    value: '03',
    label: 'Mar - 03',
  },
  {
    value: '04',
    label: 'Apr - 04',
  },
  {
    value: '05',
    label: 'May - 05',
  },
  {
    value: '06',
    label: 'Jun - 06',
  },
  {
    value: '07',
    label: 'Jul - 07',
  },
  {
    value: '08',
    label: 'Aug - 08',
  },
  {
    value: '09',
    label: 'Sep - 09',
  },
  {
    value: '10',
    label: 'Oct - 10',
  },
  {
    value: '11',
    label: 'Nov - 11',
  },
  {
    value: '12',
    label: 'Dec - 12',
  },
]

export const makeMonthSelectField = ({
  validatorTest,
}: {
  validatorTest?: ValidatorTest
} = {}) =>
  makeSelectField({
    validatorTest,
    options: getMonthSelectFieldOptions(),
  })

const getCurrentYear = () => getYear(new Date())

export const getYearSelectFieldOptions = (): SelectFieldOption[] =>
  range(getCurrentYear(), getCurrentYear() - 30, -1).map((value) => ({
    value: value.toString(),
    label: value.toString(),
  }))

export const makeYearSelectField = (validatorTest?: ValidatorTest) =>
  makeSelectField({
    validatorTest,
    options: getYearSelectFieldOptions(),
  })

export const makeNjmmisCheckField = () =>
  makeSelectField({
    options: getSelectFieldOptionsFromEnumType(NjmmisCheckResult, {
      shouldConvertCase: true,
    }),
  })

export const makeMcdTypeField = (opts?: {
  validatorTest?: ValidatorTest
  isRequired?: boolean
}) =>
  makeSelectField({
    options: getSelectFieldOptionsFromEnumType(McdType),
    validatorTest: opts?.validatorTest,
    isRequired: opts?.isRequired,
  })

export const makeMcdSubtypeField = (opts?: {
  validatorTest?: ValidatorTest
  isRequired?: boolean
}) =>
  makeSelectField({
    options: getSelectFieldOptionsFromEnumType(McdSubtype),
    validatorTest: opts?.validatorTest,
    isRequired: opts?.isRequired,
  })

export const makeSlideTypeField = (opts?: {
  validatorTest?: ValidatorTest
  isRequired?: boolean
}) =>
  makeSelectField({
    options: getSelectFieldOptionsFromEnumType(SlideType),
    validatorTest: opts?.validatorTest,
    isRequired: opts?.isRequired,
  })

export const makeEsignSessionStatusField = () =>
  makeSelectField({
    options: ['In progress', 'Linked', 'Failed'].map((value) => ({
      label: value,
      value,
    })),
  })

export const makePhoneNumbersCollection = () =>
  makeFormCollection({
    id: makeTextField(),
    number: makePhoneField({isRequired: true}),
    language: makeLanguageField(),
    comment: makeTextField(),
    usedInApp: makeBooleanField(),
    usedForEsign: makeBooleanField(),
    esignSessionId: makeTextField(),
    esignSessionStatus: makeEsignSessionStatusField(),
  })
