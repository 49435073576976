import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import {getContextHelpersFromInitialValues} from 'ad-hok-utils'

const {
  addProvider: addFormPrefixContextProvider,
  addConsumer: addFormPrefixContext,
} = getContextHelpersFromInitialValues({
  formPrefix: '',
})

export {addFormPrefixContext}

type FormPrefixProps = {
  prefix: string
}

const FormPrefix: FC<FormPrefixProps> = flowMax(
  addDisplayName('FormPrefix'),
  addProps(({prefix: formPrefix}) => ({
    formPrefix,
  })),
  addFormPrefixContextProvider,
  ({children}) => <>{children}</>
)

export default FormPrefix
