import React from 'react'
import {flowMax, addDisplayName} from 'ad-hok'
import {FC} from 'react'
import cx from 'classnames'

import {addClasses, makeClasses} from 'theme'
import Body1 from 'components/Body1'
import Trans from 'components/Trans'

const classes = makeClasses(() => ({
  itemValue: {
    fontWeight: 'bold',
  },
  itemValueBoldLabel: {
    fontWeight: 400,
  },
  boldLabel: {
    fontWeight: 'bold',
  },
}))

interface Props {
  i18nKey: string
  translations: {[key: string]: string | number}
  className?: string
  boldLabel?: boolean
}

const DisplayItem: FC<Props> = flowMax(
  addDisplayName('DisplayItem'),
  addClasses(classes),
  ({classes, className, i18nKey, translations, boldLabel}) => (
    <Body1 className={cx(boldLabel && classes.boldLabel, className)}>
      <Trans i18nKey={i18nKey}>
        <span
          className={boldLabel ? classes.itemValueBoldLabel : classes.itemValue}
        />
        {translations}
      </Trans>
    </Body1>
  )
)
export default DisplayItem
