import React, {FC} from 'react'
import {identity} from 'lodash/fp'
import {format} from 'date-fns/fp'
import {flowMax, addDisplayName, addProps} from 'ad-hok'

import {ReceivedWebforms_receivedWebforms} from 'graphql/deserializedTypes/ReceivedWebforms'
import {parseDate} from 'utils/form/fieldTypes'
import {getFormattedPhoneNumber} from 'utils/phone'
import {addClasses, makeClasses} from 'theme'
import Paper from 'components/Paper'
import Grid from 'components/Grid'
import Body1 from 'components/Body1'
import DisplayItem from 'components/DisplayItem'
import {PersonMatchButton} from 'components/UnmatchedWebformsWorklist/PersonMatchButton'
import {toEasternTime} from 'utils/date'
import {addTranslationHelpers} from 'utils/i18n'

const classes = makeClasses((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  bold: {
    fontWeight: 700,
  },
  webformName: {
    fontWeight: 700,
    color: 'grey',
  },
  createdAt: {
    fontStyle: 'italic',
  },
}))

const getFormattedAddress = ({
  homeAddressStreet: street,
  homeAddressCity: city,
  homeAddressState: state,
  homeAddressZip: zip,
}: {
  homeAddressStreet?: string
  homeAddressCity?: string
  homeAddressState?: string
  homeAddressZip?: string
}): string | null =>
  street || city || state || zip
    ? [street, city, [state, zip].filter(identity).join(' ')]
        .filter(identity)
        .join(', ')
    : null

interface Props {
  receivedWebform: ReceivedWebforms_receivedWebforms
}

export const UnmatchedWebform: FC<Props> = flowMax(
  addDisplayName('UnmatchedWebform'),
  addTranslationHelpers,
  addProps(
    ({
      receivedWebform: {
        response: {
          person: {firstName, lastName, dob, appointmentDate},
          person,
          phone,
        },
        createdAt,
      },
      t,
    }) => ({
      name: `${firstName} ${lastName}`,
      dobFormatted: dob ? format('M/d/yyyy')(parseDate(dob)) : null,
      appointmentDateFormatted: appointmentDate
        ? format('M/d/yyyy')(parseDate(appointmentDate))
        : null,
      addressFormatted: getFormattedAddress(person),
      phoneFormatted: phone?.number
        ? getFormattedPhoneNumber(phone.number)
        : null,
      createdAtFormatted: t('worklist.unmatchedWebforms.createdAt', {
        time: format('M/d/yyyy HH:mm')(toEasternTime(createdAt)),
      }),
    })
  ),
  addClasses(classes),
  ({
    receivedWebform: {webform},
    receivedWebform,
    name,
    dobFormatted,
    appointmentDateFormatted,
    addressFormatted,
    phoneFormatted,
    createdAtFormatted,
    classes,
  }) => (
    <Paper className={classes.container}>
      <Grid container direction="row" justify="space-between">
        <Grid item>
          <Body1 className={classes.webformName}>
            {webform.name}{' '}
            <span className={classes.createdAt}>{createdAtFormatted}</span>
          </Body1>
          <Body1 className={classes.bold}>
            {name} {dobFormatted && `(${dobFormatted})`}
          </Body1>
          {phoneFormatted && (
            <DisplayItem
              i18nKey="worklist.unmatchedWebforms.phoneDisplay"
              translations={{
                phone: phoneFormatted,
              }}
            />
          )}
          {addressFormatted && (
            <DisplayItem
              i18nKey="worklist.unmatchedWebforms.addressDisplay"
              translations={{
                address: addressFormatted,
              }}
            />
          )}
          {appointmentDateFormatted && (
            <DisplayItem
              i18nKey="worklist.unmatchedWebforms.appointmentDateDisplay"
              translations={{
                date: appointmentDateFormatted,
              }}
            />
          )}
        </Grid>
        <Grid item>
          <PersonMatchButton receivedWebform={receivedWebform} />
        </Grid>
      </Grid>
    </Paper>
  )
)
