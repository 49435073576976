/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {EditableFileStatus} from './globalTypes' // ====================================================
// GraphQL mutation operation: SendEditableFileToEmr
// ====================================================

import {SendEditableFileToEmrVariables as SendEditableFileToEmrVariablesOrig} from '../types/SendEditableFileToEmr'
import {SendEditableFileToEmr as SendEditableFileToEmrOrig} from '../types/SendEditableFileToEmr'
import {SendEditableFileToEmr_sendEditableFileToEmr as SendEditableFileToEmr_sendEditableFileToEmrOrig} from '../types/SendEditableFileToEmr'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {SendEditableFileToEmr_sendEditableFileToEmr_sentFax as SendEditableFileToEmr_sendEditableFileToEmr_sentFaxOrig} from '../types/SendEditableFileToEmr'
import {SendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax as SendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFaxOrig} from '../types/SendEditableFileToEmr'
import {SendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax_state as SendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax_stateOrig} from '../types/SendEditableFileToEmr'
export interface SendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax_state {
  __typename: 'State'
  stateCode: string
}
export const deserializeSendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax_state = (
  serialized: SendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax_stateOrig
): SendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax_state => ({
  ...serialized,
})
export const serializeSendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax_state = (
  deserialized: SendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax_state
): SendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax_stateOrig => ({
  ...deserialized,
})
export interface SendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax {
  __typename: 'CountyFax'
  countyName: string
  state: SendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax_state
}
export const deserializeSendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax = (
  serialized: SendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFaxOrig
): SendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax => ({
  ...serialized,
  state: deserializeSendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax_state(
    serialized.state
  ),
})
export const serializeSendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax = (
  deserialized: SendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax
): SendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFaxOrig => ({
  ...deserialized,
  state: serializeSendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax_state(
    deserialized.state
  ),
})
export interface SendEditableFileToEmr_sendEditableFileToEmr_sentFax {
  __typename: 'SentFax'
  toNumber: string | null
  faxStatus: string | null
  message: string | null
  updatedAt: Date
  countyFax: SendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax
}
export const deserializeSendEditableFileToEmr_sendEditableFileToEmr_sentFax = (
  serialized: SendEditableFileToEmr_sendEditableFileToEmr_sentFaxOrig
): SendEditableFileToEmr_sendEditableFileToEmr_sentFax => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  countyFax: deserializeSendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax(
    serialized.countyFax
  ),
})
export const serializeSendEditableFileToEmr_sendEditableFileToEmr_sentFax = (
  deserialized: SendEditableFileToEmr_sendEditableFileToEmr_sentFax
): SendEditableFileToEmr_sendEditableFileToEmr_sentFaxOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  countyFax: serializeSendEditableFileToEmr_sendEditableFileToEmr_sentFax_countyFax(
    deserialized.countyFax
  ),
})
export interface SendEditableFileToEmr_sendEditableFileToEmr {
  __typename: 'EditableFile'
  id: string
  fileKey: string
  filename: string
  updatedAt: Date
  status: EditableFileStatus
  allowSigning: boolean | null
  documentSendType: string | null
  faxEnabled: boolean | null
  sentFax: SendEditableFileToEmr_sendEditableFileToEmr_sentFax | null
}
export const deserializeSendEditableFileToEmr_sendEditableFileToEmr = (
  serialized: SendEditableFileToEmr_sendEditableFileToEmrOrig
): SendEditableFileToEmr_sendEditableFileToEmr => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  sentFax:
    serialized.sentFax != null
      ? deserializeSendEditableFileToEmr_sendEditableFileToEmr_sentFax(
          serialized.sentFax
        )
      : serialized.sentFax,
})
export const serializeSendEditableFileToEmr_sendEditableFileToEmr = (
  deserialized: SendEditableFileToEmr_sendEditableFileToEmr
): SendEditableFileToEmr_sendEditableFileToEmrOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  sentFax:
    deserialized.sentFax != null
      ? serializeSendEditableFileToEmr_sendEditableFileToEmr_sentFax(
          deserialized.sentFax
        )
      : deserialized.sentFax,
})
export interface SendEditableFileToEmr {
  sendEditableFileToEmr: SendEditableFileToEmr_sendEditableFileToEmr
}
export const deserializeSendEditableFileToEmr = (
  serialized: SendEditableFileToEmrOrig
): SendEditableFileToEmr => ({
  ...serialized,
  sendEditableFileToEmr: deserializeSendEditableFileToEmr_sendEditableFileToEmr(
    serialized.sendEditableFileToEmr
  ),
})
export const serializeSendEditableFileToEmr = (
  deserialized: SendEditableFileToEmr
): SendEditableFileToEmrOrig => ({
  ...deserialized,
  sendEditableFileToEmr: serializeSendEditableFileToEmr_sendEditableFileToEmr(
    deserialized.sendEditableFileToEmr
  ),
})
export interface SendEditableFileToEmrVariables {
  id: string
}
export const deserializeSendEditableFileToEmrVariables = (
  serialized: SendEditableFileToEmrVariablesOrig
): SendEditableFileToEmrVariables => ({...serialized})
export const serializeSendEditableFileToEmrVariables = (
  deserialized: SendEditableFileToEmrVariables
): SendEditableFileToEmrVariablesOrig => ({...deserialized})
