import React from 'react'
import {
  flowMax,
  addDisplayName,
  addWrapper,
  addHandlers,
  addProps,
} from 'ad-hok'
import {FC} from 'react'

import Form, {formColumnWidth, OnSubmitSuccessOptions} from 'components/Form'
import {addTranslationHelpers} from 'utils/i18n'
import {addClasses, makeClasses} from 'theme'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogContent from 'components/DialogContent'
import SelectField from 'components/SelectField'
import MultilineTextField from 'components/MultilineTextField'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import {makeFormSchema, ExtractFormSchemaFields} from 'utils/form/schema'
import {
  makeSelectField,
  makeTextField,
  ValidatorTest,
} from 'utils/form/fieldTypes'
import {addFormik} from 'utils/form/formik'
import addHasSaved from 'utils/addHasSaved'

const classes = makeClasses(() => ({
  contentContainer: {
    width: formColumnWidth,
  },
}))

const buildCloseReasonFormSchema = ({
  closeReasonOptions,
  closeReasonValidator,
}: {
  closeReasonOptions: string[]
  closeReasonValidator?: ValidatorTest
}) =>
  makeFormSchema({
    fields: {
      record: {
        closeReason: makeSelectField({
          isRequired: true,
          options: closeReasonOptions.map((value) => ({value})),
          validatorTest: closeReasonValidator,
        }),
        closeComment: makeTextField(),
      },
    },
  })

const staticSchema = buildCloseReasonFormSchema({closeReasonOptions: []})
export {staticSchema as closeReasonDialogSchema}

interface Props {
  closeReasonOptions: string[]
  closeReasonValidator?: ValidatorTest
  onSave: (
    options: OnSubmitSuccessOptions<
      ExtractFormSchemaFields<typeof staticSchema>
    >
  ) => void
  open: boolean
  onClose: () => void
}

const CloseReasonDialog: FC<Props> = flowMax(
  addDisplayName('CloseReasonDialog'),
  addProps(
    ({closeReasonOptions, closeReasonValidator}) => ({
      schema: buildCloseReasonFormSchema({
        closeReasonOptions,
        closeReasonValidator,
      }),
    }),
    ['closeReasonOptions', 'closeReasonValidator']
  ),
  addHasSaved('onSave'),
  addWrapper((render, {open, onClose, onSave, schema}) => (
    <Dialog open={open} onClose={onClose}>
      <Form name="closeReasonForm" schema={schema} onSubmitSuccess={onSave}>
        {render()}
      </Form>
    </Dialog>
  )),
  addFormik,
  addHandlers({
    onSaveClick: ({formik: {submitForm}}) => () => {
      submitForm()
    },
  }),
  addTranslationHelpers,
  addClasses(classes),
  ({onClose, onSaveClick, hasSaved, classes, t}) => (
    <>
      <DialogTitle>{t('closeReasonDialog.title')}</DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <SelectField name="record.closeReason" />
        <MultilineTextField name="record.closeComment" />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('closeReasonDialog.cancel')}
        </Button>
        <Button onClick={onSaveClick} color="primary" disabled={hasSaved}>
          {t('closeReasonDialog.save')}
        </Button>
      </DialogActions>
    </>
  )
)

export default CloseReasonDialog
