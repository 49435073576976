/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {NjmmisCheckResult, Benefit} from './globalTypes' // ====================================================
// GraphQL query operation: AccountsForCsv
// ====================================================

import {AccountsForCsvVariables as AccountsForCsvVariablesOrig} from '../types/AccountsForCsv'
import {AccountsForCsv as AccountsForCsvOrig} from '../types/AccountsForCsv'
import {deserializeNjmmisCheckResult} from 'graphql/converters'
import {serializeNjmmisCheckResult} from 'graphql/converters'
import {AccountsForCsv_accounts as AccountsForCsv_accountsOrig} from '../types/AccountsForCsv'
import {AccountsForCsv_accounts_openApplications as AccountsForCsv_accounts_openApplicationsOrig} from '../types/AccountsForCsv'
import {AccountsForCsv_accounts_openApplications_RyanWhiteApplication as AccountsForCsv_accounts_openApplications_RyanWhiteApplicationOrig} from '../types/AccountsForCsv'
import {AccountsForCsv_accounts_openApplications_SlideApplication as AccountsForCsv_accounts_openApplications_SlideApplicationOrig} from '../types/AccountsForCsv'
import {AccountsForCsv_accounts_openApplications_CharityCareApplication as AccountsForCsv_accounts_openApplications_CharityCareApplicationOrig} from '../types/AccountsForCsv'
import {AccountsForCsv_accounts_openApplications_MonitorApplication as AccountsForCsv_accounts_openApplications_MonitorApplicationOrig} from '../types/AccountsForCsv'
import {AccountsForCsv_accounts_openApplications_MonitorApplication_primaryPointOfContact as AccountsForCsv_accounts_openApplications_MonitorApplication_primaryPointOfContactOrig} from '../types/AccountsForCsv'
import {BenefitType} from 'utils/benefits'
import {deserializeBenefit} from 'graphql/converters'
import {serializeBenefit} from 'graphql/converters'
import {AccountsForCsv_accounts_openApplications_MedicaidApplication as AccountsForCsv_accounts_openApplications_MedicaidApplicationOrig} from '../types/AccountsForCsv'
import {AccountsForCsv_accounts_openApplications_MedicaidApplication_primaryPointOfContact as AccountsForCsv_accounts_openApplications_MedicaidApplication_primaryPointOfContactOrig} from '../types/AccountsForCsv'
import {AccountsForCsv_accounts_triage as AccountsForCsv_accounts_triageOrig} from '../types/AccountsForCsv'
import {AdditionalData} from 'utils/getAdditionalData'
import {parseAdditionalData} from 'utils/getAdditionalData'
import {serializeAdditionalData} from 'utils/getAdditionalData'
import {AccountsForCsv_accounts_triage_eligibilityDeterminations as AccountsForCsv_accounts_triage_eligibilityDeterminationsOrig} from '../types/AccountsForCsv'
import formatISO from 'date-fns/formatISO'
import {AccountsForCsv_accounts_potentiallyCoveringBenefitOutcome as AccountsForCsv_accounts_potentiallyCoveringBenefitOutcomeOrig} from '../types/AccountsForCsv'
import {AccountsForCsv_accounts_potentiallyCoveringBenefitOutcome_application as AccountsForCsv_accounts_potentiallyCoveringBenefitOutcome_applicationOrig} from '../types/AccountsForCsv'
import {parseDate} from 'utils/form/fieldTypes'
import {formatISODate} from 'utils/date'
import {AccountsForCsv_accounts_person as AccountsForCsv_accounts_personOrig} from '../types/AccountsForCsv'
import {AccountsForCsv_accounts_person_phoneNumbers as AccountsForCsv_accounts_person_phoneNumbersOrig} from '../types/AccountsForCsv'
export interface AccountsForCsv_accounts_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializeAccountsForCsv_accounts_person_phoneNumbers = (
  serialized: AccountsForCsv_accounts_person_phoneNumbersOrig
): AccountsForCsv_accounts_person_phoneNumbers => ({...serialized})
export const serializeAccountsForCsv_accounts_person_phoneNumbers = (
  deserialized: AccountsForCsv_accounts_person_phoneNumbers
): AccountsForCsv_accounts_person_phoneNumbersOrig => ({...deserialized})
export interface AccountsForCsv_accounts_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  dob: Date | null
  hospitalPatientId: string | null
  suffix: string | null
  gender: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: AccountsForCsv_accounts_person_phoneNumbers[]
  email: string | null
  emailComment: string | null
  preferredLanguage: string | null
}
export const deserializeAccountsForCsv_accounts_person = (
  serialized: AccountsForCsv_accounts_personOrig
): AccountsForCsv_accounts_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeAccountsForCsv_accounts_person_phoneNumbers
  ),
})
export const serializeAccountsForCsv_accounts_person = (
  deserialized: AccountsForCsv_accounts_person
): AccountsForCsv_accounts_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeAccountsForCsv_accounts_person_phoneNumbers
  ),
})
export interface AccountsForCsv_accounts_potentiallyCoveringBenefitOutcome_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeAccountsForCsv_accounts_potentiallyCoveringBenefitOutcome_application = (
  serialized: AccountsForCsv_accounts_potentiallyCoveringBenefitOutcome_applicationOrig
): AccountsForCsv_accounts_potentiallyCoveringBenefitOutcome_application => ({
  ...serialized,
})
export const serializeAccountsForCsv_accounts_potentiallyCoveringBenefitOutcome_application = (
  deserialized: AccountsForCsv_accounts_potentiallyCoveringBenefitOutcome_application
): AccountsForCsv_accounts_potentiallyCoveringBenefitOutcome_applicationOrig => ({
  ...deserialized,
})
export interface AccountsForCsv_accounts_potentiallyCoveringBenefitOutcome {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: AccountsForCsv_accounts_potentiallyCoveringBenefitOutcome_application | null
}
export const deserializeAccountsForCsv_accounts_potentiallyCoveringBenefitOutcome = (
  serialized: AccountsForCsv_accounts_potentiallyCoveringBenefitOutcomeOrig
): AccountsForCsv_accounts_potentiallyCoveringBenefitOutcome => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeAccountsForCsv_accounts_potentiallyCoveringBenefitOutcome_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeAccountsForCsv_accounts_potentiallyCoveringBenefitOutcome = (
  deserialized: AccountsForCsv_accounts_potentiallyCoveringBenefitOutcome
): AccountsForCsv_accounts_potentiallyCoveringBenefitOutcomeOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeAccountsForCsv_accounts_potentiallyCoveringBenefitOutcome_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface AccountsForCsv_accounts_triage_eligibilityDeterminations {
  __typename: 'EligibilityDetermination'
  id: string
  benefit: string
  status: string
  reasonOrInfo: string | null
  secondaryInfo: string | null
  householdSizeDescription: string | null
  month: Date | null
  numMonths: number | null
  createdAt: Date
  additionalData: AdditionalData | null
}
export const deserializeAccountsForCsv_accounts_triage_eligibilityDeterminations = (
  serialized: AccountsForCsv_accounts_triage_eligibilityDeterminationsOrig
): AccountsForCsv_accounts_triage_eligibilityDeterminations => ({
  ...serialized,
  month:
    serialized.month != null ? parseDate(serialized.month) : serialized.month,
  createdAt: parseDate(serialized.createdAt),
  additionalData:
    serialized.additionalData != null
      ? parseAdditionalData(serialized.additionalData)
      : serialized.additionalData,
})
export const serializeAccountsForCsv_accounts_triage_eligibilityDeterminations = (
  deserialized: AccountsForCsv_accounts_triage_eligibilityDeterminations
): AccountsForCsv_accounts_triage_eligibilityDeterminationsOrig => ({
  ...deserialized,
  month:
    deserialized.month != null
      ? formatISODate(deserialized.month)
      : deserialized.month,
  createdAt: formatISO(deserialized.createdAt),
  additionalData:
    deserialized.additionalData != null
      ? serializeAdditionalData(deserialized.additionalData)
      : deserialized.additionalData,
})
export interface AccountsForCsv_accounts_triage {
  __typename: 'Triage'
  id: string
  status: string
  closeReason: string | null
  closeComment: string | null
  createdAt: Date
  updatedAt: Date
  eligibilityDeterminations: AccountsForCsv_accounts_triage_eligibilityDeterminations[]
}
export const deserializeAccountsForCsv_accounts_triage = (
  serialized: AccountsForCsv_accounts_triageOrig
): AccountsForCsv_accounts_triage => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  eligibilityDeterminations: serialized.eligibilityDeterminations.map(
    deserializeAccountsForCsv_accounts_triage_eligibilityDeterminations
  ),
})
export const serializeAccountsForCsv_accounts_triage = (
  deserialized: AccountsForCsv_accounts_triage
): AccountsForCsv_accounts_triageOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  eligibilityDeterminations: deserialized.eligibilityDeterminations.map(
    serializeAccountsForCsv_accounts_triage_eligibilityDeterminations
  ),
})
export interface AccountsForCsv_accounts_openApplications_MedicaidApplication_primaryPointOfContact {
  __typename: 'Person'
  id: string
  firstName: string | null
  middleName: string | null
  lastName: string | null
  preferredName: string | null
  suffix: string | null
}
export const deserializeAccountsForCsv_accounts_openApplications_MedicaidApplication_primaryPointOfContact = (
  serialized: AccountsForCsv_accounts_openApplications_MedicaidApplication_primaryPointOfContactOrig
): AccountsForCsv_accounts_openApplications_MedicaidApplication_primaryPointOfContact => ({
  ...serialized,
})
export const serializeAccountsForCsv_accounts_openApplications_MedicaidApplication_primaryPointOfContact = (
  deserialized: AccountsForCsv_accounts_openApplications_MedicaidApplication_primaryPointOfContact
): AccountsForCsv_accounts_openApplications_MedicaidApplication_primaryPointOfContactOrig => ({
  ...deserialized,
})
export interface AccountsForCsv_accounts_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  primaryPointOfContact: AccountsForCsv_accounts_openApplications_MedicaidApplication_primaryPointOfContact | null
  submitDate: Date | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  detailsUpdatedAt: Date
}
export const deserializeAccountsForCsv_accounts_openApplications_MedicaidApplication = (
  serialized: AccountsForCsv_accounts_openApplications_MedicaidApplicationOrig
): AccountsForCsv_accounts_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  primaryPointOfContact:
    serialized.primaryPointOfContact != null
      ? deserializeAccountsForCsv_accounts_openApplications_MedicaidApplication_primaryPointOfContact(
          serialized.primaryPointOfContact
        )
      : serialized.primaryPointOfContact,
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
})
export const serializeAccountsForCsv_accounts_openApplications_MedicaidApplication = (
  deserialized: AccountsForCsv_accounts_openApplications_MedicaidApplication
): AccountsForCsv_accounts_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  primaryPointOfContact:
    deserialized.primaryPointOfContact != null
      ? serializeAccountsForCsv_accounts_openApplications_MedicaidApplication_primaryPointOfContact(
          deserialized.primaryPointOfContact
        )
      : deserialized.primaryPointOfContact,
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
})
export interface AccountsForCsv_accounts_openApplications_MonitorApplication_primaryPointOfContact {
  __typename: 'Person'
  id: string
  firstName: string | null
  middleName: string | null
  lastName: string | null
  preferredName: string | null
  suffix: string | null
}
export const deserializeAccountsForCsv_accounts_openApplications_MonitorApplication_primaryPointOfContact = (
  serialized: AccountsForCsv_accounts_openApplications_MonitorApplication_primaryPointOfContactOrig
): AccountsForCsv_accounts_openApplications_MonitorApplication_primaryPointOfContact => ({
  ...serialized,
})
export const serializeAccountsForCsv_accounts_openApplications_MonitorApplication_primaryPointOfContact = (
  deserialized: AccountsForCsv_accounts_openApplications_MonitorApplication_primaryPointOfContact
): AccountsForCsv_accounts_openApplications_MonitorApplication_primaryPointOfContactOrig => ({
  ...deserialized,
})
export interface AccountsForCsv_accounts_openApplications_MonitorApplication {
  __typename: 'MonitorApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  primaryPointOfContact: AccountsForCsv_accounts_openApplications_MonitorApplication_primaryPointOfContact | null
  submitDate: Date | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  applicationType: string | null
  darSubmitDate: Date | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  detailsUpdatedAt: Date
}
export const deserializeAccountsForCsv_accounts_openApplications_MonitorApplication = (
  serialized: AccountsForCsv_accounts_openApplications_MonitorApplicationOrig
): AccountsForCsv_accounts_openApplications_MonitorApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  primaryPointOfContact:
    serialized.primaryPointOfContact != null
      ? deserializeAccountsForCsv_accounts_openApplications_MonitorApplication_primaryPointOfContact(
          serialized.primaryPointOfContact
        )
      : serialized.primaryPointOfContact,
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  darSubmitDate:
    serialized.darSubmitDate != null
      ? parseDate(serialized.darSubmitDate)
      : serialized.darSubmitDate,
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
})
export const serializeAccountsForCsv_accounts_openApplications_MonitorApplication = (
  deserialized: AccountsForCsv_accounts_openApplications_MonitorApplication
): AccountsForCsv_accounts_openApplications_MonitorApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  primaryPointOfContact:
    deserialized.primaryPointOfContact != null
      ? serializeAccountsForCsv_accounts_openApplications_MonitorApplication_primaryPointOfContact(
          deserialized.primaryPointOfContact
        )
      : deserialized.primaryPointOfContact,
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  darSubmitDate:
    deserialized.darSubmitDate != null
      ? formatISODate(deserialized.darSubmitDate)
      : deserialized.darSubmitDate,
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
})
export interface AccountsForCsv_accounts_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  facility: string
  initialDateOfService: Date | null
  dateOfApplication: Date | null
  requestedDateOfApplication: Date | null
  notes: string | null
  detailsUpdatedAt: Date
}
export const deserializeAccountsForCsv_accounts_openApplications_CharityCareApplication = (
  serialized: AccountsForCsv_accounts_openApplications_CharityCareApplicationOrig
): AccountsForCsv_accounts_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
  requestedDateOfApplication:
    serialized.requestedDateOfApplication != null
      ? parseDate(serialized.requestedDateOfApplication)
      : serialized.requestedDateOfApplication,
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
})
export const serializeAccountsForCsv_accounts_openApplications_CharityCareApplication = (
  deserialized: AccountsForCsv_accounts_openApplications_CharityCareApplication
): AccountsForCsv_accounts_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
  requestedDateOfApplication:
    deserialized.requestedDateOfApplication != null
      ? formatISODate(deserialized.requestedDateOfApplication)
      : deserialized.requestedDateOfApplication,
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
})
export interface AccountsForCsv_accounts_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  facility: string
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  detailsUpdatedAt: Date
}
export const deserializeAccountsForCsv_accounts_openApplications_SlideApplication = (
  serialized: AccountsForCsv_accounts_openApplications_SlideApplicationOrig
): AccountsForCsv_accounts_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
})
export const serializeAccountsForCsv_accounts_openApplications_SlideApplication = (
  deserialized: AccountsForCsv_accounts_openApplications_SlideApplication
): AccountsForCsv_accounts_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
})
export interface AccountsForCsv_accounts_openApplications_RyanWhiteApplication {
  __typename: 'RyanWhiteApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  facility: string
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  detailsUpdatedAt: Date
}
export const deserializeAccountsForCsv_accounts_openApplications_RyanWhiteApplication = (
  serialized: AccountsForCsv_accounts_openApplications_RyanWhiteApplicationOrig
): AccountsForCsv_accounts_openApplications_RyanWhiteApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
})
export const serializeAccountsForCsv_accounts_openApplications_RyanWhiteApplication = (
  deserialized: AccountsForCsv_accounts_openApplications_RyanWhiteApplication
): AccountsForCsv_accounts_openApplications_RyanWhiteApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
})
export type AccountsForCsv_accounts_openApplications =
  | AccountsForCsv_accounts_openApplications_MedicaidApplication
  | AccountsForCsv_accounts_openApplications_MonitorApplication
  | AccountsForCsv_accounts_openApplications_CharityCareApplication
  | AccountsForCsv_accounts_openApplications_SlideApplication
  | AccountsForCsv_accounts_openApplications_RyanWhiteApplication
export const deserializeAccountsForCsv_accounts_openApplications = (
  serialized: AccountsForCsv_accounts_openApplicationsOrig
): AccountsForCsv_accounts_openApplications => {
  if (false || serialized.__typename === 'MedicaidApplication')
    return deserializeAccountsForCsv_accounts_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'MonitorApplication')
    return deserializeAccountsForCsv_accounts_openApplications_MonitorApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeAccountsForCsv_accounts_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeAccountsForCsv_accounts_openApplications_SlideApplication(
      serialized
    )
  if (false || serialized.__typename === 'RyanWhiteApplication')
    return deserializeAccountsForCsv_accounts_openApplications_RyanWhiteApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` + 'AccountsForCsv_accounts_openApplications'
  )
}
export const serializeAccountsForCsv_accounts_openApplications = (
  deserialized: AccountsForCsv_accounts_openApplications
): AccountsForCsv_accounts_openApplicationsOrig => {
  if (false || deserialized.__typename === 'MedicaidApplication')
    return serializeAccountsForCsv_accounts_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'MonitorApplication')
    return serializeAccountsForCsv_accounts_openApplications_MonitorApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeAccountsForCsv_accounts_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeAccountsForCsv_accounts_openApplications_SlideApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'RyanWhiteApplication')
    return serializeAccountsForCsv_accounts_openApplications_RyanWhiteApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` + 'AccountsForCsv_accounts_openApplications'
  )
}
export interface AccountsForCsv_accounts {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
  timeOfService: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location: string | null
  insuranceType: string
  status: string
  createdAt: Date
  updatedAt: Date
  notes: string | null
  accountAmount: number | null
  njmmisCheck: string | null
  previousInsurance: string | null
  invoiceMonth: string | null
  invoiceYear: string | null
  dischargeDate: Date | null
  isScheduled: boolean
  person: AccountsForCsv_accounts_person
  potentiallyCoveringBenefitOutcome: AccountsForCsv_accounts_potentiallyCoveringBenefitOutcome | null
  triage: AccountsForCsv_accounts_triage
  openApplications: AccountsForCsv_accounts_openApplications[]
}
export const deserializeAccountsForCsv_accounts = (
  serialized: AccountsForCsv_accountsOrig
): AccountsForCsv_accounts => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
  person: deserializeAccountsForCsv_accounts_person(serialized.person),
  potentiallyCoveringBenefitOutcome:
    serialized.potentiallyCoveringBenefitOutcome != null
      ? deserializeAccountsForCsv_accounts_potentiallyCoveringBenefitOutcome(
          serialized.potentiallyCoveringBenefitOutcome
        )
      : serialized.potentiallyCoveringBenefitOutcome,
  triage: deserializeAccountsForCsv_accounts_triage(serialized.triage),
  openApplications: serialized.openApplications.map(
    deserializeAccountsForCsv_accounts_openApplications
  ),
})
export const serializeAccountsForCsv_accounts = (
  deserialized: AccountsForCsv_accounts
): AccountsForCsv_accountsOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
  person: serializeAccountsForCsv_accounts_person(deserialized.person),
  potentiallyCoveringBenefitOutcome:
    deserialized.potentiallyCoveringBenefitOutcome != null
      ? serializeAccountsForCsv_accounts_potentiallyCoveringBenefitOutcome(
          deserialized.potentiallyCoveringBenefitOutcome
        )
      : deserialized.potentiallyCoveringBenefitOutcome,
  triage: serializeAccountsForCsv_accounts_triage(deserialized.triage),
  openApplications: deserialized.openApplications.map(
    serializeAccountsForCsv_accounts_openApplications
  ),
})
export interface AccountsForCsv {
  /**
   * List of accounts
   */
  accounts: AccountsForCsv_accounts[]
}
export const deserializeAccountsForCsv = (
  serialized: AccountsForCsvOrig
): AccountsForCsv => ({
  ...serialized,
  accounts: serialized.accounts.map(deserializeAccountsForCsv_accounts),
})
export const serializeAccountsForCsv = (
  deserialized: AccountsForCsv
): AccountsForCsvOrig => ({
  ...deserialized,
  accounts: deserialized.accounts.map(serializeAccountsForCsv_accounts),
})
export interface AccountsForCsvVariables {
  statuses?: string[] | null
  insuranceTypes?: string[] | null
  dateOfServiceAfter?: Date | null
  dateOfServiceBefore?: Date | null
  facility?: string[] | null
  hasExternalVendorCode?: boolean | null
  coveredByBenefitType?: string[] | null
  coveredByBenefitOutcome?: string[] | null
  coveredByOpenApplicationStatus?: string[] | null
  search?: string | null
  password?: string | null
  invoiceMonth?: string[] | null
  invoiceYear?: string[] | null
}
export const deserializeAccountsForCsvVariables = (
  serialized: AccountsForCsvVariablesOrig
): AccountsForCsvVariables => ({
  ...serialized,
  dateOfServiceAfter:
    serialized.dateOfServiceAfter != null
      ? parseDate(serialized.dateOfServiceAfter)
      : serialized.dateOfServiceAfter,
  dateOfServiceBefore:
    serialized.dateOfServiceBefore != null
      ? parseDate(serialized.dateOfServiceBefore)
      : serialized.dateOfServiceBefore,
})
export const serializeAccountsForCsvVariables = (
  deserialized: AccountsForCsvVariables
): AccountsForCsvVariablesOrig => ({
  ...deserialized,
  dateOfServiceAfter:
    deserialized.dateOfServiceAfter != null
      ? formatISODate(deserialized.dateOfServiceAfter)
      : deserialized.dateOfServiceAfter,
  dateOfServiceBefore:
    deserialized.dateOfServiceBefore != null
      ? formatISODate(deserialized.dateOfServiceBefore)
      : deserialized.dateOfServiceBefore,
})
