/* eslint-disable */
import {SentWebFormsVariables as SentWebFormsVariablesOrig} from '../types/SentWebForms'
import {SentWebForms as SentWebFormsOrig} from '../types/SentWebForms'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {SentWebForms_sentWebForms as SentWebForms_sentWebFormsOrig} from '../types/SentWebForms'
import {SentWebForms_sentWebForms_requestInitiatedBy as SentWebForms_sentWebForms_requestInitiatedByOrig} from '../types/SentWebForms'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: SentWebForms
// ====================================================
export interface SentWebForms_sentWebForms_requestInitiatedBy {
  __typename: 'User'
  id: string
  name: string | null
}
export const deserializeSentWebForms_sentWebForms_requestInitiatedBy = (
  serialized: SentWebForms_sentWebForms_requestInitiatedByOrig
): SentWebForms_sentWebForms_requestInitiatedBy => ({...serialized})
export const serializeSentWebForms_sentWebForms_requestInitiatedBy = (
  deserialized: SentWebForms_sentWebForms_requestInitiatedBy
): SentWebForms_sentWebForms_requestInitiatedByOrig => ({...deserialized})
export interface SentWebForms_sentWebForms {
  __typename: 'SentWebForm'
  id: string
  personId: number
  phoneNumber: string | null
  webFormName: string
  requestInitiatedBy: SentWebForms_sentWebForms_requestInitiatedBy | null
  createdAt: Date
  updatedAt: Date
}
export const deserializeSentWebForms_sentWebForms = (
  serialized: SentWebForms_sentWebFormsOrig
): SentWebForms_sentWebForms => ({
  ...serialized,
  requestInitiatedBy:
    serialized.requestInitiatedBy != null
      ? deserializeSentWebForms_sentWebForms_requestInitiatedBy(
          serialized.requestInitiatedBy
        )
      : serialized.requestInitiatedBy,
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
})
export const serializeSentWebForms_sentWebForms = (
  deserialized: SentWebForms_sentWebForms
): SentWebForms_sentWebFormsOrig => ({
  ...deserialized,
  requestInitiatedBy:
    deserialized.requestInitiatedBy != null
      ? serializeSentWebForms_sentWebForms_requestInitiatedBy(
          deserialized.requestInitiatedBy
        )
      : deserialized.requestInitiatedBy,
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
})
export interface SentWebForms {
  /**
   * List of messages send/received for this patient
   */
  sentWebForms: SentWebForms_sentWebForms[]
}
export const deserializeSentWebForms = (
  serialized: SentWebFormsOrig
): SentWebForms => ({
  ...serialized,
  sentWebForms: serialized.sentWebForms.map(
    deserializeSentWebForms_sentWebForms
  ),
})
export const serializeSentWebForms = (
  deserialized: SentWebForms
): SentWebFormsOrig => ({
  ...deserialized,
  sentWebForms: deserialized.sentWebForms.map(
    serializeSentWebForms_sentWebForms
  ),
})
export interface SentWebFormsVariables {
  personId: string
}
export const deserializeSentWebFormsVariables = (
  serialized: SentWebFormsVariablesOrig
): SentWebFormsVariables => ({...serialized})
export const serializeSentWebFormsVariables = (
  deserialized: SentWebFormsVariables
): SentWebFormsVariablesOrig => ({...deserialized})
