import {SimplePropsAdder, addProps} from 'ad-hok'
import {useNavigate, useLocation} from 'react-router-dom'

type AddNavigateType = SimplePropsAdder<{
  navigate: ReturnType<typeof useNavigate>
}>

export const addNavigate: AddNavigateType = addProps(() => ({
  navigate: useNavigate(),
}))

type AddLocationType = SimplePropsAdder<{
  location: ReturnType<typeof useLocation>
}>

export const addLocation: AddLocationType = addProps(() => ({
  location: useLocation(),
}))
