import {getContextHelpers, toObjectKeys} from 'ad-hok-utils'

import {Account_account} from 'graphql/deserializedTypes/Account'

const [
  addAccountFormLeftColumnContextProvider,
  addAccountFormLeftColumnContext,
] = getContextHelpers<{
  account: Account_account
}>(toObjectKeys(['account']))

export {
  addAccountFormLeftColumnContextProvider,
  addAccountFormLeftColumnContext,
}
