/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {
  ApplicationInput,
  Benefit,
  McdType,
  McdSubtype,
  SlideType,
} from './globalTypes' // ====================================================
// GraphQL mutation operation: CreateApplication
// ====================================================

import {deserializeApplicationInput} from './globalTypes'
import {serializeApplicationInput} from './globalTypes'
import {CreateApplicationVariables as CreateApplicationVariablesOrig} from '../types/CreateApplication'
import {CreateApplication as CreateApplicationOrig} from '../types/CreateApplication'
import {CreateApplication_createApplication as CreateApplication_createApplicationOrig} from '../types/CreateApplication'
import {CreateApplication_createApplication_RyanWhiteApplication as CreateApplication_createApplication_RyanWhiteApplicationOrig} from '../types/CreateApplication'
import {deserializeSlideType} from 'graphql/converters'
import {serializeSlideType} from 'graphql/converters'
import {CreateApplication_createApplication_SlideApplication as CreateApplication_createApplication_SlideApplicationOrig} from '../types/CreateApplication'
import {CreateApplication_createApplication_CharityCareApplication as CreateApplication_createApplication_CharityCareApplicationOrig} from '../types/CreateApplication'
import {CreateApplication_createApplication_MonitorApplication as CreateApplication_createApplication_MonitorApplicationOrig} from '../types/CreateApplication'
import {CreateApplication_createApplication_MonitorApplication_primaryPointOfContact as CreateApplication_createApplication_MonitorApplication_primaryPointOfContactOrig} from '../types/CreateApplication'
import {BenefitType} from 'utils/benefits'
import {deserializeMcdSubtype} from 'graphql/converters'
import {serializeMcdSubtype} from 'graphql/converters'
import {deserializeMcdType} from 'graphql/converters'
import {serializeMcdType} from 'graphql/converters'
import {formatISODate} from 'utils/date'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {deserializeBenefit} from 'graphql/converters'
import {serializeBenefit} from 'graphql/converters'
import {CreateApplication_createApplication_MedicaidApplication as CreateApplication_createApplication_MedicaidApplicationOrig} from '../types/CreateApplication'
export interface CreateApplication_createApplication_MedicaidApplication {
  __typename: 'MedicaidApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  readyForAudit: boolean
  submitDate: Date | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  initialDateOfService: Date | null
  mcdType: string
  mcdSubtype: string | null
}
export const deserializeCreateApplication_createApplication_MedicaidApplication = (
  serialized: CreateApplication_createApplication_MedicaidApplicationOrig
): CreateApplication_createApplication_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  mcdType: deserializeMcdType(serialized.mcdType),
  mcdSubtype:
    serialized.mcdSubtype != null
      ? deserializeMcdSubtype(serialized.mcdSubtype)
      : serialized.mcdSubtype,
})
export const serializeCreateApplication_createApplication_MedicaidApplication = (
  deserialized: CreateApplication_createApplication_MedicaidApplication
): CreateApplication_createApplication_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  mcdType: serializeMcdType(deserialized.mcdType),
  mcdSubtype:
    deserialized.mcdSubtype != null
      ? serializeMcdSubtype(deserialized.mcdSubtype)
      : deserialized.mcdSubtype,
})
export interface CreateApplication_createApplication_MonitorApplication_primaryPointOfContact {
  __typename: 'Person'
  id: string
}
export const deserializeCreateApplication_createApplication_MonitorApplication_primaryPointOfContact = (
  serialized: CreateApplication_createApplication_MonitorApplication_primaryPointOfContactOrig
): CreateApplication_createApplication_MonitorApplication_primaryPointOfContact => ({
  ...serialized,
})
export const serializeCreateApplication_createApplication_MonitorApplication_primaryPointOfContact = (
  deserialized: CreateApplication_createApplication_MonitorApplication_primaryPointOfContact
): CreateApplication_createApplication_MonitorApplication_primaryPointOfContactOrig => ({
  ...deserialized,
})
export interface CreateApplication_createApplication_MonitorApplication {
  __typename: 'MonitorApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  readyForAudit: boolean
  submitDate: Date | null
  applicationType: string | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  darSubmitDate: Date | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  primaryPointOfContact: CreateApplication_createApplication_MonitorApplication_primaryPointOfContact | null
  initialDateOfService: Date | null
}
export const deserializeCreateApplication_createApplication_MonitorApplication = (
  serialized: CreateApplication_createApplication_MonitorApplicationOrig
): CreateApplication_createApplication_MonitorApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  darSubmitDate:
    serialized.darSubmitDate != null
      ? parseDate(serialized.darSubmitDate)
      : serialized.darSubmitDate,
  primaryPointOfContact:
    serialized.primaryPointOfContact != null
      ? deserializeCreateApplication_createApplication_MonitorApplication_primaryPointOfContact(
          serialized.primaryPointOfContact
        )
      : serialized.primaryPointOfContact,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
})
export const serializeCreateApplication_createApplication_MonitorApplication = (
  deserialized: CreateApplication_createApplication_MonitorApplication
): CreateApplication_createApplication_MonitorApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  darSubmitDate:
    deserialized.darSubmitDate != null
      ? formatISODate(deserialized.darSubmitDate)
      : deserialized.darSubmitDate,
  primaryPointOfContact:
    deserialized.primaryPointOfContact != null
      ? serializeCreateApplication_createApplication_MonitorApplication_primaryPointOfContact(
          deserialized.primaryPointOfContact
        )
      : deserialized.primaryPointOfContact,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
})
export interface CreateApplication_createApplication_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  readyForAudit: boolean
  initialDateOfService: Date | null
  dateOfApplication: Date | null
  requestedDateOfApplication: Date | null
  notes: string | null
  facility: string
  coveragePeriod: number | null
  charityCareType: string
}
export const deserializeCreateApplication_createApplication_CharityCareApplication = (
  serialized: CreateApplication_createApplication_CharityCareApplicationOrig
): CreateApplication_createApplication_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
  requestedDateOfApplication:
    serialized.requestedDateOfApplication != null
      ? parseDate(serialized.requestedDateOfApplication)
      : serialized.requestedDateOfApplication,
})
export const serializeCreateApplication_createApplication_CharityCareApplication = (
  deserialized: CreateApplication_createApplication_CharityCareApplication
): CreateApplication_createApplication_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
  requestedDateOfApplication:
    deserialized.requestedDateOfApplication != null
      ? formatISODate(deserialized.requestedDateOfApplication)
      : deserialized.requestedDateOfApplication,
})
export interface CreateApplication_createApplication_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  readyForAudit: boolean
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  facility: string
  slideType: string
  dateOfApplication: Date | null
  coveragePeriod: number | null
}
export const deserializeCreateApplication_createApplication_SlideApplication = (
  serialized: CreateApplication_createApplication_SlideApplicationOrig
): CreateApplication_createApplication_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  slideType: deserializeSlideType(serialized.slideType),
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
})
export const serializeCreateApplication_createApplication_SlideApplication = (
  deserialized: CreateApplication_createApplication_SlideApplication
): CreateApplication_createApplication_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  slideType: serializeSlideType(deserialized.slideType),
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
})
export interface CreateApplication_createApplication_RyanWhiteApplication {
  __typename: 'RyanWhiteApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  readyForAudit: boolean
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  facility: string
  dateOfApplication: Date | null
  coveragePeriod: number | null
}
export const deserializeCreateApplication_createApplication_RyanWhiteApplication = (
  serialized: CreateApplication_createApplication_RyanWhiteApplicationOrig
): CreateApplication_createApplication_RyanWhiteApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
})
export const serializeCreateApplication_createApplication_RyanWhiteApplication = (
  deserialized: CreateApplication_createApplication_RyanWhiteApplication
): CreateApplication_createApplication_RyanWhiteApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
})
export type CreateApplication_createApplication =
  | CreateApplication_createApplication_MedicaidApplication
  | CreateApplication_createApplication_MonitorApplication
  | CreateApplication_createApplication_CharityCareApplication
  | CreateApplication_createApplication_SlideApplication
  | CreateApplication_createApplication_RyanWhiteApplication
export const deserializeCreateApplication_createApplication = (
  serialized: CreateApplication_createApplicationOrig
): CreateApplication_createApplication => {
  if (false || serialized.__typename === 'MedicaidApplication')
    return deserializeCreateApplication_createApplication_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'MonitorApplication')
    return deserializeCreateApplication_createApplication_MonitorApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeCreateApplication_createApplication_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeCreateApplication_createApplication_SlideApplication(
      serialized
    )
  if (false || serialized.__typename === 'RyanWhiteApplication')
    return deserializeCreateApplication_createApplication_RyanWhiteApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` + 'CreateApplication_createApplication'
  )
}
export const serializeCreateApplication_createApplication = (
  deserialized: CreateApplication_createApplication
): CreateApplication_createApplicationOrig => {
  if (false || deserialized.__typename === 'MedicaidApplication')
    return serializeCreateApplication_createApplication_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'MonitorApplication')
    return serializeCreateApplication_createApplication_MonitorApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeCreateApplication_createApplication_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeCreateApplication_createApplication_SlideApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'RyanWhiteApplication')
    return serializeCreateApplication_createApplication_RyanWhiteApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` + 'CreateApplication_createApplication'
  )
}
export interface CreateApplication {
  /**
   * Create a new application
   */
  createApplication: CreateApplication_createApplication
}
export const deserializeCreateApplication = (
  serialized: CreateApplicationOrig
): CreateApplication => ({
  ...serialized,
  createApplication: deserializeCreateApplication_createApplication(
    serialized.createApplication
  ),
})
export const serializeCreateApplication = (
  deserialized: CreateApplication
): CreateApplicationOrig => ({
  ...deserialized,
  createApplication: serializeCreateApplication_createApplication(
    deserialized.createApplication
  ),
})
export interface CreateApplicationVariables {
  application: ApplicationInput
}
export const deserializeCreateApplicationVariables = (
  serialized: CreateApplicationVariablesOrig
): CreateApplicationVariables => ({
  ...serialized,
  application: deserializeApplicationInput(serialized.application),
})
export const serializeCreateApplicationVariables = (
  deserialized: CreateApplicationVariables
): CreateApplicationVariablesOrig => ({
  ...deserialized,
  application: serializeApplicationInput(deserialized.application),
})
