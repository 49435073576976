import {sortBy} from 'lodash/fp'

import {
  Application_application_MedicaidApplication_householdMembers,
  Application_application,
} from 'graphql/deserializedTypes/Application'
import {Person_person} from 'graphql/deserializedTypes/Person'

type HouseholdMember = Application_application_MedicaidApplication_householdMembers

export type HouseholdMemberWithRelationshipType = HouseholdMember & {
  relationshipType: string | null | undefined
}

export const getHouseholdMembersWithRelationshipTypes = ({
  application: {householdMembers},
  person: {relationships},
  person,
}: {
  application: Application_application
  person: Person_person
}): HouseholdMemberWithRelationshipType[] =>
  sortBy(
    ({person: {firstName, id: personId}}) => [
      personId !== person.id,
      firstName,
    ],
    householdMembers.map((householdMember) => ({
      ...householdMember,
      relationshipType:
        householdMember.person.id === person.id
          ? 'Client'
          : relationships.find(
              ({otherPerson}) => otherPerson.id === householdMember.person.id
            )?.relationshipType,
    }))
  )
