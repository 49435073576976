import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addHandlers,
  addProps,
  addStateHandlers,
} from 'ad-hok'
import {PureQueryOptions} from 'apollo-boost'

import addRouteParams from 'utils/addRouteParams'
import {addLoadingIndicator} from 'utils/dataLoading'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import {addTranslationHelpers} from 'utils/i18n'
import {
  addApplicationQuery,
  addUpdateApplicationMutation,
} from 'graphql/generated'
import EditMedicaidApplicationForm from 'components/EditMedicaidApplicationForm'
import EditMonitorApplicationForm from 'components/EditMonitorApplicationForm'
import EditCharityCareApplicationForm from 'components/EditCharityCareApplicationForm'
import EditSlideApplicationForm from 'components/EditSlideApplicationForm'
import EditRyanWhiteApplicationForm from 'components/EditRyanWhiteApplicationForm'
import {addApplicationFormContextProvider} from 'components/EditApplicationForm/applicationFormContext'
import {PERSON_QUERY} from 'graphql/queries'
import {
  Application_application_MonitorApplication,
  Application_application,
  Application_application_MedicaidApplication,
  Application_application_CharityCareApplication,
  Application_application_SlideApplication,
  Application_application_RyanWhiteApplication,
} from 'graphql/deserializedTypes/Application'

const isMonitorApplication = (
  application: Application_application
): application is Application_application_MonitorApplication =>
  application.__typename === 'MonitorApplication'

export const isMedicaidApplication = (
  application: Application_application
): application is Application_application_MedicaidApplication =>
  application.__typename === 'MedicaidApplication'

export const isCharityCareApplication = (
  application: Application_application
): application is Application_application_CharityCareApplication =>
  application.__typename === 'CharityCareApplication'

export const isSlideApplication = (
  application: Application_application
): application is Application_application_SlideApplication =>
  application.__typename === 'SlideApplication'

export const isRyanWhiteApplication = (
  application: Application_application
): application is Application_application_RyanWhiteApplication =>
  application.__typename === 'RyanWhiteApplication'

const EditApplicationForm: FC = flowMax(
  addDisplayName('EditApplicationForm'),
  addRouteParams<{id: string}>(),
  addApplicationQuery({
    variables: ({id}) => ({id}),
  }),
  addLoadingIndicator({}),
  addAppSnackbarContext,
  addTranslationHelpers,
  addHandlers({
    onUpdateSuccess: ({showSnackbarMessage, t}) => () => {
      showSnackbarMessage(t('applicationForm.updatedApplication'))
    },
  }),
  addUpdateApplicationMutation({}),
  addProps(
    ({
      application: {
        person: {id: personId},
      },
    }) => ({
      refetchQueriesOnStatusChange: (): PureQueryOptions[] => [
        {
          query: PERSON_QUERY,
          variables: {
            id: personId,
          },
        },
      ],
    }),
    ['application.person.id']
  ),
  addStateHandlers(
    {
      hasNecessaryDocumentsByPersonId: {} as {
        [personId: string]: boolean
      },
    },
    {
      setHasNecessaryDocumentsByPersonId: ({
        hasNecessaryDocumentsByPersonId,
      }) => (personId: string | null, hasNecessaryDocuments: boolean) => ({
        hasNecessaryDocumentsByPersonId: {
          ...hasNecessaryDocumentsByPersonId,
          [personId ?? 'application']: hasNecessaryDocuments,
        },
      }),
    }
  ),
  addProps(
    ({hasNecessaryDocumentsByPersonId}) => ({
      hasNecessaryDocuments: Object.values(
        hasNecessaryDocumentsByPersonId
      ).some((hasNecessaryDocuments) => hasNecessaryDocuments),
    }),
    ['hasNecessaryDocumentsByPersonId']
  ),
  addApplicationFormContextProvider,
  ({application}) =>
    isMonitorApplication(application) ? (
      <EditMonitorApplicationForm />
    ) : isMedicaidApplication(application) ? (
      <EditMedicaidApplicationForm />
    ) : isCharityCareApplication(application) ? (
      <EditCharityCareApplicationForm />
    ) : isSlideApplication(application) ? (
      <EditSlideApplicationForm />
    ) : (
      <EditRyanWhiteApplicationForm />
    )
)

export default EditApplicationForm
