import React, {ReactElement} from 'react'
import {FC} from 'react'
import {flowMax, addDisplayName} from 'ad-hok'
import StarIcon from '@material-ui/icons/Star'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import {addTranslationHelpers} from 'utils/i18n'
import {makeClasses, addClasses} from 'theme'
import Grid from 'components/Grid'
import Body1 from 'components/Body1'
import Caption from 'components/Caption'
import Divider from 'components/Divider'
import IconButton from 'components/IconButton'
import {FormFieldWithFeedbackIconContainer} from 'components/FormFieldWithFeedbackIconContainer'
import InfoTooltip from 'components/InfoTooltip'

const classes = makeClasses((theme) => ({
  contents: {
    flex: 1,
  },
  controls: {
    flex: 0,
  },
  info: {
    marginLeft: theme.spacing(3),
  },
  star: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.grey[600],
  },
  divider: {
    marginRight: theme.spacing(6),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

interface Props {
  title: string
  subtitle?: string
  info?: string | null
  starred?: boolean | null
  onEditClick: () => void
  onDeleteClick?: () => void
  renderDelete?: () => ReactElement | null
  itemName?: string
  renderAdditionalIcons?: () => ReactElement | null
  titleClassName?: string
}

const NestedFormListItem: FC<Props> = flowMax(
  addDisplayName('NestedFormListItem'),
  addTranslationHelpers,
  addClasses(classes),
  ({
    classes,
    title,
    subtitle,
    info,
    starred,
    onEditClick,
    onDeleteClick,
    renderDelete,
    itemName,
    renderAdditionalIcons,
    titleClassName,
    t,
  }) => (
    <>
      <FormFieldWithFeedbackIconContainer
        prefix={itemName}
        shouldRegisterToFormSection
      >
        <Grid container direction="row" alignItems="center">
          <Grid
            className={classes.contents}
            container
            direction="row"
            alignItems="center"
          >
            <div>
              <Body1 className={titleClassName}>{title}</Body1>
              <Grid container direction="row" alignItems="center">
                {starred && (
                  <StarIcon fontSize="small" className={classes.star} />
                )}
                <Caption> {subtitle}</Caption>
              </Grid>
            </div>
            <InfoTooltip info={info} />
            {!!renderAdditionalIcons && renderAdditionalIcons()}
          </Grid>
          <Grid
            className={classes.controls}
            container
            direction="row"
            wrap="nowrap"
          >
            <IconButton
              aria-label={t('formListItem.edit', {title})}
              onClick={onEditClick}
            >
              <EditIcon />
            </IconButton>
            {renderDelete ? (
              renderDelete()
            ) : (
              <IconButton
                aria-label={t('formListItem.delete', {title})}
                onClick={onDeleteClick}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </FormFieldWithFeedbackIconContainer>
      <Divider className={classes.divider} />
    </>
  )
)

export default NestedFormListItem
