import React, {FC} from 'react'
import Typography from '@material-ui/core/Typography'

import {TextProps} from 'components/Heading'

const Body2: FC<TextProps> = ({variant = 'body2', ...props}) => (
  <Typography variant={variant} {...props} />
)

export default Body2
