import React, {FC, ChangeEvent} from 'react'
import {flowMax, addDisplayName, addHandlers} from 'ad-hok'
import ClearIcon from '@material-ui/icons/Clear'

import FormControl from 'components/FormControl'
import InputLabel from 'components/InputLabel'
import InputAdornment from 'components/InputAdornment'
import IconButton from 'components/IconButton'
import OutlinedInput from 'components/OutlinedInput'
import {addClasses, makeClasses} from 'theme'

const classes = makeClasses((theme) => ({
  clearButtonPlaceholder: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    marginRight: -12,
  },
}))

export interface InputWithClearButtonProps {
  id?: string
  inputClassName?: string
  label?: string
  value: string
  onChange: (value: string) => void
  onEnter: () => void
  onClear: () => void
}

const InputWithClearButton: FC<InputWithClearButtonProps> = flowMax(
  addDisplayName('InputWithClearButton'),
  addHandlers({
    onValueChange: ({onChange}) => (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value)
    },
  }),
  addClasses(classes),
  ({
    value,
    onValueChange,
    onEnter,
    onClear,
    id,
    label,
    inputClassName,
    classes,
  }) => (
    <FormControl className={inputClassName} variant="outlined">
      {!!label && <InputLabel htmlFor={id}>{label}</InputLabel>}
      <OutlinedInput
        id={id}
        label={label}
        type="text"
        value={value}
        onChange={onValueChange}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault()
            onEnter()
          }
        }}
        endAdornment={
          <InputAdornment position="end">
            {value ? (
              <IconButton onClick={onClear} edge="end">
                <ClearIcon />
              </IconButton>
            ) : (
              <div className={classes.clearButtonPlaceholder} />
            )}
          </InputAdornment>
        }
      />
    </FormControl>
  )
)

export default InputWithClearButton
