/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {UpdateEsignSessionInput} from './globalTypes' // ====================================================
// GraphQL mutation operation: UpdateEsignSession
// ====================================================

import {deserializeUpdateEsignSessionInput} from './globalTypes'
import {serializeUpdateEsignSessionInput} from './globalTypes'
import {UpdateEsignSessionVariables as UpdateEsignSessionVariablesOrig} from '../types/UpdateEsignSession'
import {UpdateEsignSession as UpdateEsignSessionOrig} from '../types/UpdateEsignSession'
import {UpdateEsignSession_updateEsignSession as UpdateEsignSession_updateEsignSessionOrig} from '../types/UpdateEsignSession'
export interface UpdateEsignSession_updateEsignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
}
export const deserializeUpdateEsignSession_updateEsignSession = (
  serialized: UpdateEsignSession_updateEsignSessionOrig
): UpdateEsignSession_updateEsignSession => ({...serialized})
export const serializeUpdateEsignSession_updateEsignSession = (
  deserialized: UpdateEsignSession_updateEsignSession
): UpdateEsignSession_updateEsignSessionOrig => ({...deserialized})
export interface UpdateEsignSession {
  updateEsignSession: UpdateEsignSession_updateEsignSession
}
export const deserializeUpdateEsignSession = (
  serialized: UpdateEsignSessionOrig
): UpdateEsignSession => ({
  ...serialized,
  updateEsignSession: deserializeUpdateEsignSession_updateEsignSession(
    serialized.updateEsignSession
  ),
})
export const serializeUpdateEsignSession = (
  deserialized: UpdateEsignSession
): UpdateEsignSessionOrig => ({
  ...deserialized,
  updateEsignSession: serializeUpdateEsignSession_updateEsignSession(
    deserialized.updateEsignSession
  ),
})
export interface UpdateEsignSessionVariables {
  esignSession: UpdateEsignSessionInput
}
export const deserializeUpdateEsignSessionVariables = (
  serialized: UpdateEsignSessionVariablesOrig
): UpdateEsignSessionVariables => ({
  ...serialized,
  esignSession: deserializeUpdateEsignSessionInput(serialized.esignSession),
})
export const serializeUpdateEsignSessionVariables = (
  deserialized: UpdateEsignSessionVariables
): UpdateEsignSessionVariablesOrig => ({
  ...deserialized,
  esignSession: serializeUpdateEsignSessionInput(deserialized.esignSession),
})
