/* eslint-disable */
import {DeleteDocumentVariables as DeleteDocumentVariablesOrig} from '../types/DeleteDocument'
import {DeleteDocument as DeleteDocumentOrig} from '../types/DeleteDocument'
import {DeleteDocument_deleteDocument as DeleteDocument_deleteDocumentOrig} from '../types/DeleteDocument'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL mutation operation: DeleteDocument
// ====================================================
export interface DeleteDocument_deleteDocument {
  __typename: 'Document'
  id: string
}
export const deserializeDeleteDocument_deleteDocument = (
  serialized: DeleteDocument_deleteDocumentOrig
): DeleteDocument_deleteDocument => ({...serialized})
export const serializeDeleteDocument_deleteDocument = (
  deserialized: DeleteDocument_deleteDocument
): DeleteDocument_deleteDocumentOrig => ({...deserialized})
export interface DeleteDocument {
  /**
   * Delete document
   */
  deleteDocument: DeleteDocument_deleteDocument
}
export const deserializeDeleteDocument = (
  serialized: DeleteDocumentOrig
): DeleteDocument => ({
  ...serialized,
  deleteDocument: deserializeDeleteDocument_deleteDocument(
    serialized.deleteDocument
  ),
})
export const serializeDeleteDocument = (
  deserialized: DeleteDocument
): DeleteDocumentOrig => ({
  ...deserialized,
  deleteDocument: serializeDeleteDocument_deleteDocument(
    deserialized.deleteDocument
  ),
})
export interface DeleteDocumentVariables {
  id: string
}
export const deserializeDeleteDocumentVariables = (
  serialized: DeleteDocumentVariablesOrig
): DeleteDocumentVariables => ({...serialized})
export const serializeDeleteDocumentVariables = (
  deserialized: DeleteDocumentVariables
): DeleteDocumentVariablesOrig => ({...deserialized})
