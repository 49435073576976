import {CurriedUnchangedProps} from 'ad-hok'
import {mapValues, flow, fromPairs, map, isArray, mapKeys, get} from 'lodash/fp'

type TapType = <TProps>(
  callback: (props: TProps) => void
) => CurriedUnchangedProps<TProps>

export const tap: TapType = (callback) => (props) => {
  callback(props)

  return props
}

type LogType = <TProps>(key: string) => CurriedUnchangedProps<TProps>

export const log: LogType = (key) => tap((props) => console.log({[key]: props}))

type LogPropType = <TProps>(propsPath: string) => CurriedUnchangedProps<TProps>

export const logProp: LogPropType = (propsPath) =>
  tap((props) => console.log({[propsPath]: get(propsPath, props)}))

type ObjectMapperType<TObject> = (
  values: TObject[keyof TObject],
  key: keyof TObject
) => any

type MapValuesWithKeyType = <
  TMapper extends ObjectMapperType<TObject>,
  TObject
>(
  mapper: TMapper
) => (object: TObject) => {[key in keyof TObject]: ReturnType<TMapper>}

export const mapValuesWithKey: MapValuesWithKeyType = (mapValues as any).convert(
  {
    cap: false,
  }
)

type MapValuesWithKeyUncurried = <
  TMapper extends ObjectMapperType<TObject>,
  TObject
>(
  mapper: TMapper,
  object: TObject
) => {[key in keyof TObject]: ReturnType<TMapper>}

export const mapValuesWithKeyUncurried: MapValuesWithKeyUncurried = (mapValues as any).convert(
  {
    cap: false,
  }
)

export const mapWithKey: typeof map = (map as any).convert({cap: false})

type MapKeysWithKeyType = <TMapper extends ObjectMapperType<TObject>, TObject>(
  mapper: TMapper
) => (object: TObject) => {[key: string]: TObject[keyof TObject]}

export const mapKeysWithKey: MapKeysWithKeyType = (mapKeys as any).convert({
  cap: false,
})

type MapKeysWithKeyUncurriedType = <
  TMapper extends ObjectMapperType<TObject>,
  TObject
>(
  mapper: TMapper,
  object: TObject
) => {[key: string]: TObject[keyof TObject]}

export const mapKeysWithKeyUncurried: MapKeysWithKeyUncurriedType = (mapKeys as any).convert(
  {
    cap: false,
  }
)

type MapToObjectType = (
  toPair: (itemValue: any, itemKey: string) => any
) => (collection: {} | any[]) => {}

export const mapToObject: MapToObjectType = (toPair) =>
  flow(mapWithKey(toPair), fromPairs)

type EnsureArrayType = <TMaybeArray>(
  maybeArray: TMaybeArray
) => TMaybeArray extends Array<any> ? TMaybeArray : Array<TMaybeArray>

export const ensureArray: EnsureArrayType = (maybeArray) =>
  (isArray(maybeArray) ? maybeArray : [maybeArray]) as any

type CallWithType = <TArgument, TReturn>(
  argument: TArgument,
  func: (argument: TArgument) => TReturn
) => TReturn

export const callWith: CallWithType = (argument, func) => func(argument)

export const invoke = <TFunction extends () => any>(
  func: TFunction
): ReturnType<TFunction> => func()

export const invertToMany = (object: {
  [key: string]: string
}): {
  [key: string]: string[]
} => {
  const ret: {
    [key: string]: string[]
  } = {}
  Object.entries(object).forEach(([key, value]) => {
    if (!ret[value]) ret[value] = []
    ret[value].push(key)
  })
  return ret
}
