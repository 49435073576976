import {flowMax, addProps} from 'ad-hok'
import {get} from 'lodash/fp'

import {addFormik} from 'utils/form/formik'

type AddFieldErrorType = <
  TProps extends {
    name: string
    shouldIgnoreTouchedForFieldError?: boolean
  }
>(
  props: TProps
) => TProps & {
  fieldError: string | null
}

export const addFieldError: AddFieldErrorType = flowMax(
  addFormik,
  addProps(
    ({formik: {errors, touched}, name, shouldIgnoreTouchedForFieldError}) => ({
      fieldError:
        shouldIgnoreTouchedForFieldError || get(name)(touched)
          ? get(name)(errors)
          : null,
    }),
    [
      'formik.errors',
      'formik.touched',
      'name',
      'shouldIgnoreTouchedForFieldError',
    ]
  )
)
