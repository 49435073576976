import {SimplePropsAdder, addProps} from 'ad-hok'
import {useFormikContext, FormikContextType} from 'formik'

import {
  FormSchemaFields,
  FormSchema,
  FormValues,
  ExtractFormSchemaFields,
} from 'utils/form/schema'

type AddFormikType = SimplePropsAdder<{
  formik: ReturnType<typeof useFormikContext>
}>

export const addFormik: AddFormikType = addProps(() => ({
  formik: useFormikContext(),
}))

type AddFormikTypedType = <TFields extends FormSchemaFields, TProps extends {}>(
  formSchema: FormSchema<TFields>
) => (
  props: TProps
) => TProps & {
  formik: FormikContextType<FormValues<TFields>>
}

export const addFormikTyped: AddFormikTypedType = (formSchema) =>
  addProps(() => ({
    formik: useFormikContext<
      FormValues<ExtractFormSchemaFields<typeof formSchema>>
    >(),
  }))
