import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import {generatePath} from 'react-router'

import {addTranslationHelpers} from 'utils/i18n'
import {
  editPersonPath,
  personDetailDefaultPath,
} from 'components/TopLevelRoutes'
import Paper from 'components/Paper'
import Heading from 'components/Heading'
import Body1 from 'components/Body1'
import {makeClasses, addClasses} from 'theme'
import Button from 'components/Button'
import Link from 'components/Link'
import {getFormattedDateOfBirth, getFormattedDateOfService} from 'utils/date'
import {getExtendedName} from 'utils/name'
import {getSinglePhoneSummaryWithLanguage} from 'utils/phone'
import {getFormattedHomeAddress} from 'utils/address'
import {orUnknown} from 'utils/string'
import {getMaskedSsn} from 'utils/ssn'
import EligibilityDeterminationStatus from 'components/EligibilityDeterminationStatus'
import {Person_person_mostRecentEligibilityDeterminations} from 'graphql/deserializedTypes/Person'

const labelWidth = 165

const classes = makeClasses((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  personInfoContainer: {
    display: 'grid',
    gridTemplateColumns: `${labelWidth}px 1fr`,
    color: '#333333', // Gray 1
    marginBottom: 1,
  },
  personInfoLabel: {
    textAlign: 'right',
    marginRight: theme.spacing(2),
  },
  personInfoValue: {
    fontWeight: 700,
  },
  topCardHeaderRow: {
    display: 'grid',
    gridTemplateColumns: '2fr 1.5fr 1fr 124px',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  personInfo: {
    display: 'grid',
    gridTemplateColumns: '2fr 1.5fr 1fr 124px',
  },
  accountHeader: {
    paddingLeft: labelWidth,
    fontWeight: 500,
  },
  applicationsHeader: {
    fontWeight: 500,
  },
  applicationsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  eligibilityDeterminationStatus: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
  },
}))

type PersonInfoValue = string | null | undefined
interface PersonInfoProps {
  label: string
  value?: PersonInfoValue
  values?: PersonInfoValue[]
}

const PersonInfo: FC<PersonInfoProps> = flowMax(
  addDisplayName('PersonInfo'),
  addProps(({values, value}) => ({
    values: values && values.length > 0 ? values : [value],
  })),
  addTranslationHelpers,
  addClasses(classes),
  ({label, values, t, classes}) => (
    <div className={classes.personInfoContainer}>
      <div className={classes.personInfoLabel}>
        <Body1>{label}:</Body1>
      </div>
      <div>
        {values.map((value, index) => (
          <Body1 className={classes.personInfoValue} key={index}>
            {orUnknown(t)(value)}
          </Body1>
        ))}
      </div>
    </div>
  )
)

const getHospitalPatientId = ({
  hospitalPatientId,
}: {
  hospitalPatientId: string | null
}) => hospitalPatientId ?? ''

interface PersonItemProps {
  person: {
    id: string
    firstName: string | null
    middleName: string | null
    lastName: string | null
    preferredName: string | null
    suffix: string | null
    dob: Date | null
    hospitalPatientId: string | null
    gender: string | null
    ssn: string | null
    preferredLanguage: string | null
    homeAddressStreet: string | null
    homeAddressCity: string | null
    homeAddressState: string | null
    homeAddressZip: string | null
    phoneNumbers: {
      number: string
      language: string | null
    }[]
    mostRecentAccount: {
      id: string
      dateOfService: Date
      department: string
      location: string | null
      insuranceType: string
    } | null
    openApplications: {
      id: string
      benefit: string
    }[]
    mostRecentEligibilityDeterminations: Person_person_mostRecentEligibilityDeterminations[]
  }
  noLink?: boolean
  detailView?: boolean
}

const PersonItem: FC<PersonItemProps> = flowMax(
  addDisplayName('PersonItem'),
  addClasses(classes),
  addTranslationHelpers,
  addProps(
    ({person: {dob, ssn, mostRecentAccount, openApplications}, person, t}) => ({
      personName: getExtendedName({...person, t}),
      dobFormatted: getFormattedDateOfBirth({dob, t}),
      ssnFormatted: ssn && getMaskedSsn(ssn),
      homeAddressFormatted:
        person.homeAddressStreet &&
        person.homeAddressCity &&
        person.homeAddressState &&
        person.homeAddressZip &&
        getFormattedHomeAddress({
          ...person,
          t,
        }),
      hasMostRecentAccount: !!mostRecentAccount,
      dateOfServiceFormatted:
        mostRecentAccount &&
        getFormattedDateOfService(mostRecentAccount.dateOfService),
      departmentFormatted: mostRecentAccount && mostRecentAccount.department,
      locationFormatted: mostRecentAccount && mostRecentAccount.location,
      insuranceTypeFormatted:
        mostRecentAccount && mostRecentAccount.insuranceType,
      openApplicationsFormatted: openApplications
        .map(({benefit}) => t(`applications.benefitLabels.${benefit}`))
        .join(', '),
    })
  ),
  ({
    person,
    personName,
    dobFormatted,
    ssnFormatted,
    homeAddressFormatted,
    hasMostRecentAccount,
    dateOfServiceFormatted,
    departmentFormatted,
    locationFormatted,
    insuranceTypeFormatted,
    openApplicationsFormatted,
    noLink,
    detailView,
    classes,
    t,
  }) => (
    <Paper className={classes.container}>
      <div className={classes.topCardHeaderRow}>
        <Heading variant="h5" component="h2">
          {noLink ? (
            `${personName} ${dobFormatted}`
          ) : (
            <>
              <Link
                to={generatePath(personDetailDefaultPath, {id: person.id})}
                highlight
              >
                {personName}
              </Link>{' '}
              {dobFormatted}
            </>
          )}
        </Heading>
        <Heading
          variant="body1"
          component="h3"
          className={classes.accountHeader}
        >
          {hasMostRecentAccount
            ? t('personItem.accountTitle')
            : t('personItem.noAccountTitle')}
        </Heading>
        <Heading
          variant="body1"
          component="h3"
          className={classes.applicationsHeader}
        >
          {openApplicationsFormatted
            ? t('personItem.openApplicationsTitle')
            : t('personItem.noOpenApplicationsTitle')}
        </Heading>
        <Button
          color="primary"
          component={Link}
          variant="contained"
          to={generatePath(editPersonPath, {id: person.id})}
        >
          {t('personItem.edit')}
        </Button>
      </div>
      <div className={classes.personInfo}>
        <div>
          {detailView && (
            <PersonInfo label={t('personItem.personId')} value={person.id} />
          )}
          {(detailView || person.hospitalPatientId) && (
            <PersonInfo
              label={t('personItem.hospitalId')}
              value={getHospitalPatientId(person)}
            />
          )}
          {(detailView || person.gender) && (
            <PersonInfo label={t('personItem.gender')} value={person.gender} />
          )}
          {(detailView || ssnFormatted) && (
            <PersonInfo label={t('personItem.ssn')} value={ssnFormatted} />
          )}
          {(detailView || person.preferredLanguage) && (
            <PersonInfo
              label={t('personItem.preferredLanguage')}
              value={person.preferredLanguage}
            />
          )}
          {(detailView || homeAddressFormatted) && (
            <PersonInfo
              label={t('personItem.homeAddress')}
              value={homeAddressFormatted}
            />
          )}
          {detailView && (
            <PersonInfo
              label={t('personItem.contactPhone')}
              values={person.phoneNumbers.map((phoneNumber) =>
                getSinglePhoneSummaryWithLanguage(phoneNumber)
              )}
            />
          )}
        </div>
        <div>
          {hasMostRecentAccount && (
            <>
              <PersonInfo
                label={t('personItem.dateOfService')}
                value={dateOfServiceFormatted}
              />
              <PersonInfo
                label={t('personItem.department')}
                value={departmentFormatted}
              />
              <PersonInfo
                label={t('personItem.location')}
                value={locationFormatted}
              />
              <PersonInfo
                label={t('personItem.insuranceType')}
                value={insuranceTypeFormatted}
              />
            </>
          )}
        </div>
        <div className={classes.applicationsContainer}>
          {openApplicationsFormatted && (
            <Body1 className={classes.personInfoValue}>
              {openApplicationsFormatted}
            </Body1>
          )}
          <EligibilityDeterminationStatus
            labelKey="medicaid"
            benefits={['medicaid', 'medicaid-lastMonth']}
            mostRecentEligibilityDeterminations={
              person.mostRecentEligibilityDeterminations
            }
            className={classes.eligibilityDeterminationStatus}
          />
        </div>
      </div>
    </Paper>
  )
)

export default PersonItem
