/* eslint-disable */
import {MarkReceivedWebformFileTemplateClearedVariables as MarkReceivedWebformFileTemplateClearedVariablesOrig} from '../types/MarkReceivedWebformFileTemplateCleared'
import {MarkReceivedWebformFileTemplateCleared as MarkReceivedWebformFileTemplateClearedOrig} from '../types/MarkReceivedWebformFileTemplateCleared'
import {MarkReceivedWebformFileTemplateCleared_markReceivedWebformFileTemplateCleared as MarkReceivedWebformFileTemplateCleared_markReceivedWebformFileTemplateClearedOrig} from '../types/MarkReceivedWebformFileTemplateCleared'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL mutation operation: MarkReceivedWebformFileTemplateCleared
// ====================================================
export interface MarkReceivedWebformFileTemplateCleared_markReceivedWebformFileTemplateCleared {
  __typename: 'ReceivedWebformFileTemplate'
  id: string
  cleared: boolean
}
export const deserializeMarkReceivedWebformFileTemplateCleared_markReceivedWebformFileTemplateCleared = (
  serialized: MarkReceivedWebformFileTemplateCleared_markReceivedWebformFileTemplateClearedOrig
): MarkReceivedWebformFileTemplateCleared_markReceivedWebformFileTemplateCleared => ({
  ...serialized,
})
export const serializeMarkReceivedWebformFileTemplateCleared_markReceivedWebformFileTemplateCleared = (
  deserialized: MarkReceivedWebformFileTemplateCleared_markReceivedWebformFileTemplateCleared
): MarkReceivedWebformFileTemplateCleared_markReceivedWebformFileTemplateClearedOrig => ({
  ...deserialized,
})
export interface MarkReceivedWebformFileTemplateCleared {
  markReceivedWebformFileTemplateCleared: MarkReceivedWebformFileTemplateCleared_markReceivedWebformFileTemplateCleared
}
export const deserializeMarkReceivedWebformFileTemplateCleared = (
  serialized: MarkReceivedWebformFileTemplateClearedOrig
): MarkReceivedWebformFileTemplateCleared => ({
  ...serialized,
  markReceivedWebformFileTemplateCleared: deserializeMarkReceivedWebformFileTemplateCleared_markReceivedWebformFileTemplateCleared(
    serialized.markReceivedWebformFileTemplateCleared
  ),
})
export const serializeMarkReceivedWebformFileTemplateCleared = (
  deserialized: MarkReceivedWebformFileTemplateCleared
): MarkReceivedWebformFileTemplateClearedOrig => ({
  ...deserialized,
  markReceivedWebformFileTemplateCleared: serializeMarkReceivedWebformFileTemplateCleared_markReceivedWebformFileTemplateCleared(
    deserialized.markReceivedWebformFileTemplateCleared
  ),
})
export interface MarkReceivedWebformFileTemplateClearedVariables {
  id: string
}
export const deserializeMarkReceivedWebformFileTemplateClearedVariables = (
  serialized: MarkReceivedWebformFileTemplateClearedVariablesOrig
): MarkReceivedWebformFileTemplateClearedVariables => ({...serialized})
export const serializeMarkReceivedWebformFileTemplateClearedVariables = (
  deserialized: MarkReceivedWebformFileTemplateClearedVariables
): MarkReceivedWebformFileTemplateClearedVariablesOrig => ({...deserialized})
