/* eslint-disable */
import {CreateLighticoWorkflowsVariables as CreateLighticoWorkflowsVariablesOrig} from '../types/CreateLighticoWorkflows'
import {CreateLighticoWorkflows as CreateLighticoWorkflowsOrig} from '../types/CreateLighticoWorkflows'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL mutation operation: CreateLighticoWorkflows
// ====================================================
export interface CreateLighticoWorkflows {
  createLighticoWorkflows: boolean
}
export const deserializeCreateLighticoWorkflows = (
  serialized: CreateLighticoWorkflowsOrig
): CreateLighticoWorkflows => ({...serialized})
export const serializeCreateLighticoWorkflows = (
  deserialized: CreateLighticoWorkflows
): CreateLighticoWorkflowsOrig => ({...deserialized})
export interface CreateLighticoWorkflowsVariables {
  esignSessionId: string
  workflowIds: string[]
}
export const deserializeCreateLighticoWorkflowsVariables = (
  serialized: CreateLighticoWorkflowsVariablesOrig
): CreateLighticoWorkflowsVariables => ({...serialized})
export const serializeCreateLighticoWorkflowsVariables = (
  deserialized: CreateLighticoWorkflowsVariables
): CreateLighticoWorkflowsVariablesOrig => ({...deserialized})
