import React from 'react'
import {FC} from 'react'

import TextField, {FieldComponentProps} from 'components/TextField'
import InputAdornment from 'components/InputAdornment'

const CurrencyField: FC<FieldComponentProps> = (props) => (
  <TextField
    InputProps={{
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
    }}
    {...props}
  />
)

export default CurrencyField
