import React from 'react'
import {flowMax, addDisplayName} from 'ad-hok'
import {FC} from 'react'
import cx from 'classnames'
import CheckIcon from '@material-ui/icons/Check'

import {PersistedDocument} from 'utils/persistedDocumentTypes'
import {addClasses, makeClasses} from 'theme'
import FormFeedbackIcon from 'components/FormFeedbackIcon'

const classes = makeClasses((theme) => ({
  check: {
    color: '#219653', // Green 1
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    flexShrink: 0,
  },
}))

interface Props {
  persistedDocument: PersistedDocument | undefined
  className?: string
}

const PersistedDocumentCheckMarkOrNecessaryIcon: FC<Props> = flowMax(
  addDisplayName('PersistedDocumentCheckMarkOrNecessaryIcon'),
  addClasses(classes),
  ({persistedDocument, classes, className}) => (
    <div className={cx(classes.container, className)}>
      {persistedDocument?.complete ? (
        <CheckIcon className={classes.check} />
      ) : (
        <FormFeedbackIcon status="necessary" />
      )}
    </div>
  )
)

export default PersistedDocumentCheckMarkOrNecessaryIcon
