import {useTranslation} from 'react-i18next'
import {SimplePropsAdder, addProps} from 'ad-hok'
import {TFunction} from 'i18next'

type AddTranslationHelpersType = SimplePropsAdder<{
  t: TFunction
}>

export const addTranslationHelpers: AddTranslationHelpersType = addProps(() => {
  const {t} = useTranslation()

  return {t}
})
