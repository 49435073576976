import React from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import {FC} from 'react'
import {matchPath} from 'react-router'
import cx from 'classnames'

import {makeClasses, addClasses} from 'theme'
import AppBar from 'components/AppBar'
import Container from 'components/Container'
import {addLocation} from 'utils/routing'
import {
  editPersonPath,
  editApplicationPath,
  editAccountPath,
} from 'components/TopLevelRoutes'

const classes = makeClasses((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  containerMaxHeightWindow: {
    maxHeight: '100vh',
    overflowY: 'hidden',
  },
}))

const Page: FC = flowMax(
  addDisplayName('Page'),
  addLocation,
  addProps(
    ({location: {pathname}}) => ({
      shouldCapMaxHeight:
        !!matchPath({path: editPersonPath}, pathname) ||
        !!matchPath({path: editApplicationPath}, pathname) ||
        !!matchPath({path: editAccountPath}, pathname),
    }),
    ['location.pathname']
  ),
  addClasses(classes),
  ({classes, children, shouldCapMaxHeight}) => (
    <div
      className={cx(
        classes.container,
        shouldCapMaxHeight && classes.containerMaxHeightWindow
      )}
    >
      <AppBar />
      <Container>{children}</Container>
    </div>
  )
)

export default Page
