/* eslint-disable */
import {PersonForAwaitingPhoneNumberEsignSessionIdVariables as PersonForAwaitingPhoneNumberEsignSessionIdVariablesOrig} from '../types/PersonForAwaitingPhoneNumberEsignSessionId'
import {PersonForAwaitingPhoneNumberEsignSessionId as PersonForAwaitingPhoneNumberEsignSessionIdOrig} from '../types/PersonForAwaitingPhoneNumberEsignSessionId'
import {PersonForAwaitingPhoneNumberEsignSessionId_person as PersonForAwaitingPhoneNumberEsignSessionId_personOrig} from '../types/PersonForAwaitingPhoneNumberEsignSessionId'
import {PersonForAwaitingPhoneNumberEsignSessionId_person_relationships as PersonForAwaitingPhoneNumberEsignSessionId_person_relationshipsOrig} from '../types/PersonForAwaitingPhoneNumberEsignSessionId'
import {PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson as PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPersonOrig} from '../types/PersonForAwaitingPhoneNumberEsignSessionId'
import {PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_incomeSources as PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_incomeSourcesOrig} from '../types/PersonForAwaitingPhoneNumberEsignSessionId'
import {PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_phoneNumbers as PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_phoneNumbersOrig} from '../types/PersonForAwaitingPhoneNumberEsignSessionId'
import {PersonForAwaitingPhoneNumberEsignSessionId_person_incomeSources as PersonForAwaitingPhoneNumberEsignSessionId_person_incomeSourcesOrig} from '../types/PersonForAwaitingPhoneNumberEsignSessionId'
import {PersonForAwaitingPhoneNumberEsignSessionId_person_phoneNumbers as PersonForAwaitingPhoneNumberEsignSessionId_person_phoneNumbersOrig} from '../types/PersonForAwaitingPhoneNumberEsignSessionId'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: PersonForAwaitingPhoneNumberEsignSessionId
// ====================================================
export interface PersonForAwaitingPhoneNumberEsignSessionId_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializePersonForAwaitingPhoneNumberEsignSessionId_person_phoneNumbers = (
  serialized: PersonForAwaitingPhoneNumberEsignSessionId_person_phoneNumbersOrig
): PersonForAwaitingPhoneNumberEsignSessionId_person_phoneNumbers => ({
  ...serialized,
})
export const serializePersonForAwaitingPhoneNumberEsignSessionId_person_phoneNumbers = (
  deserialized: PersonForAwaitingPhoneNumberEsignSessionId_person_phoneNumbers
): PersonForAwaitingPhoneNumberEsignSessionId_person_phoneNumbersOrig => ({
  ...deserialized,
})
export interface PersonForAwaitingPhoneNumberEsignSessionId_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
}
export const deserializePersonForAwaitingPhoneNumberEsignSessionId_person_incomeSources = (
  serialized: PersonForAwaitingPhoneNumberEsignSessionId_person_incomeSourcesOrig
): PersonForAwaitingPhoneNumberEsignSessionId_person_incomeSources => ({
  ...serialized,
})
export const serializePersonForAwaitingPhoneNumberEsignSessionId_person_incomeSources = (
  deserialized: PersonForAwaitingPhoneNumberEsignSessionId_person_incomeSources
): PersonForAwaitingPhoneNumberEsignSessionId_person_incomeSourcesOrig => ({
  ...deserialized,
})
export interface PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializePersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_phoneNumbers = (
  serialized: PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_phoneNumbersOrig
): PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_phoneNumbers => ({
  ...serialized,
})
export const serializePersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_phoneNumbers = (
  deserialized: PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_phoneNumbers
): PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_phoneNumbersOrig => ({
  ...deserialized,
})
export interface PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
}
export const deserializePersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_incomeSources = (
  serialized: PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_incomeSourcesOrig
): PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_incomeSources => ({
  ...serialized,
})
export const serializePersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_incomeSources = (
  deserialized: PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_incomeSources
): PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_incomeSourcesOrig => ({
  ...deserialized,
})
export interface PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson {
  __typename: 'Person'
  id: string
  phoneNumbers: PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_phoneNumbers[]
  incomeSources: PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_incomeSources[]
}
export const deserializePersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson = (
  serialized: PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPersonOrig
): PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson => ({
  ...serialized,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializePersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_phoneNumbers
  ),
  incomeSources: serialized.incomeSources.map(
    deserializePersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_incomeSources
  ),
})
export const serializePersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson = (
  deserialized: PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson
): PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPersonOrig => ({
  ...deserialized,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializePersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_phoneNumbers
  ),
  incomeSources: deserialized.incomeSources.map(
    serializePersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson_incomeSources
  ),
})
export interface PersonForAwaitingPhoneNumberEsignSessionId_person_relationships {
  __typename: 'Relationship'
  otherPerson: PersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson
}
export const deserializePersonForAwaitingPhoneNumberEsignSessionId_person_relationships = (
  serialized: PersonForAwaitingPhoneNumberEsignSessionId_person_relationshipsOrig
): PersonForAwaitingPhoneNumberEsignSessionId_person_relationships => ({
  ...serialized,
  otherPerson: deserializePersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson(
    serialized.otherPerson
  ),
})
export const serializePersonForAwaitingPhoneNumberEsignSessionId_person_relationships = (
  deserialized: PersonForAwaitingPhoneNumberEsignSessionId_person_relationships
): PersonForAwaitingPhoneNumberEsignSessionId_person_relationshipsOrig => ({
  ...deserialized,
  otherPerson: serializePersonForAwaitingPhoneNumberEsignSessionId_person_relationships_otherPerson(
    deserialized.otherPerson
  ),
})
export interface PersonForAwaitingPhoneNumberEsignSessionId_person {
  __typename: 'Person'
  id: string
  phoneNumbers: PersonForAwaitingPhoneNumberEsignSessionId_person_phoneNumbers[]
  incomeSources: PersonForAwaitingPhoneNumberEsignSessionId_person_incomeSources[]
  relationships: PersonForAwaitingPhoneNumberEsignSessionId_person_relationships[]
}
export const deserializePersonForAwaitingPhoneNumberEsignSessionId_person = (
  serialized: PersonForAwaitingPhoneNumberEsignSessionId_personOrig
): PersonForAwaitingPhoneNumberEsignSessionId_person => ({
  ...serialized,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializePersonForAwaitingPhoneNumberEsignSessionId_person_phoneNumbers
  ),
  incomeSources: serialized.incomeSources.map(
    deserializePersonForAwaitingPhoneNumberEsignSessionId_person_incomeSources
  ),
  relationships: serialized.relationships.map(
    deserializePersonForAwaitingPhoneNumberEsignSessionId_person_relationships
  ),
})
export const serializePersonForAwaitingPhoneNumberEsignSessionId_person = (
  deserialized: PersonForAwaitingPhoneNumberEsignSessionId_person
): PersonForAwaitingPhoneNumberEsignSessionId_personOrig => ({
  ...deserialized,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializePersonForAwaitingPhoneNumberEsignSessionId_person_phoneNumbers
  ),
  incomeSources: deserialized.incomeSources.map(
    serializePersonForAwaitingPhoneNumberEsignSessionId_person_incomeSources
  ),
  relationships: deserialized.relationships.map(
    serializePersonForAwaitingPhoneNumberEsignSessionId_person_relationships
  ),
})
export interface PersonForAwaitingPhoneNumberEsignSessionId {
  /**
   * Person
   */
  person: PersonForAwaitingPhoneNumberEsignSessionId_person
}
export const deserializePersonForAwaitingPhoneNumberEsignSessionId = (
  serialized: PersonForAwaitingPhoneNumberEsignSessionIdOrig
): PersonForAwaitingPhoneNumberEsignSessionId => ({
  ...serialized,
  person: deserializePersonForAwaitingPhoneNumberEsignSessionId_person(
    serialized.person
  ),
})
export const serializePersonForAwaitingPhoneNumberEsignSessionId = (
  deserialized: PersonForAwaitingPhoneNumberEsignSessionId
): PersonForAwaitingPhoneNumberEsignSessionIdOrig => ({
  ...deserialized,
  person: serializePersonForAwaitingPhoneNumberEsignSessionId_person(
    deserialized.person
  ),
})
export interface PersonForAwaitingPhoneNumberEsignSessionIdVariables {
  id: string
}
export const deserializePersonForAwaitingPhoneNumberEsignSessionIdVariables = (
  serialized: PersonForAwaitingPhoneNumberEsignSessionIdVariablesOrig
): PersonForAwaitingPhoneNumberEsignSessionIdVariables => ({...serialized})
export const serializePersonForAwaitingPhoneNumberEsignSessionIdVariables = (
  deserialized: PersonForAwaitingPhoneNumberEsignSessionIdVariables
): PersonForAwaitingPhoneNumberEsignSessionIdVariablesOrig => ({
  ...deserialized,
})
