import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addHandlers,
  addProps,
  SimplePropsAdder,
} from 'ad-hok'
import {cleanupProps} from 'ad-hok-utils'
import {truncate} from 'lodash/fp'

import addApolloClient from 'utils/addApolloClient'
import {SIGNED_DOCUMENT_DOWNLOAD_URL_QUERY} from 'graphql/queries'
import LinkButton from 'components/LinkButton'
import {DocumentFileFields} from 'graphql/deserializedTypes/DocumentFileFields'

export const getBasename = (
  path: string,
  {
    truncateToLength,
  }: {
    truncateToLength?: number
  } = {}
): string => {
  const basename = path.substring(path.lastIndexOf('/') + 1)
  if (!truncateToLength) return basename
  const extensionDotIndex = basename.lastIndexOf('.')
  if (extensionDotIndex <= 0)
    return truncate({length: truncateToLength}, basename)
  const basenameWithoutExtension = basename.substring(0, extensionDotIndex)
  const extensionWithDot = basename.substring(extensionDotIndex)
  if (extensionWithDot.length >= truncateToLength)
    return truncate({length: truncateToLength}, basename)
  return `${truncate(
    {length: truncateToLength - extensionWithDot.length},
    basenameWithoutExtension
  )}${extensionWithDot}`
}

type DocumentFileType = DocumentFileFields

type AddFetchDocumentFileUrlType = SimplePropsAdder<{
  fetchDocumentFileUrl: (documentFile: {fileKey: string}) => Promise<string>
}>

export const addFetchDocumentFileUrl: AddFetchDocumentFileUrlType = flowMax(
  addApolloClient,
  addHandlers({
    fetchDocumentFileUrl: ({apolloClient}) => async ({
      fileKey,
    }: {
      fileKey: string
    }) => {
      const {
        data: {
          signedDocumentDownloadUrl: {url},
        },
      } = await apolloClient.query({
        query: SIGNED_DOCUMENT_DOWNLOAD_URL_QUERY,
        variables: {
          fileKey,
        },
        fetchPolicy: 'network-only',
      })
      return url
    },
  })
)

type AddOpenDocumentFileInNewTabType = SimplePropsAdder<{
  openDocumentFileInNewTab: (documentFile: DocumentFileType) => Promise<void>
}>

export const addOpenDocumentFileInNewTab: AddOpenDocumentFileInNewTabType = flowMax(
  addFetchDocumentFileUrl,
  addHandlers({
    openDocumentFileInNewTab: ({fetchDocumentFileUrl}) => async (
      documentFile: DocumentFileType
    ) => {
      const url = await fetchDocumentFileUrl(documentFile)
      window.open(url)
    },
  }),
  cleanupProps('fetchDocumentFileUrl')
)

interface Props {
  documentFile: DocumentFileType
  className?: string
}

const DocumentFileLink: FC<Props> = flowMax(
  addDisplayName('DocumentFileLink'),
  addOpenDocumentFileInNewTab,
  addHandlers({
    downloadFile: ({openDocumentFileInNewTab, documentFile}) => () => {
      openDocumentFileInNewTab(documentFile)
    },
  }),
  addProps(({documentFile: {fileKey}}) => ({
    text: getBasename(fileKey, {truncateToLength: 25}),
  })),
  ({downloadFile, text, className}) => (
    <LinkButton onClick={downloadFile} className={className}>
      {text}
    </LinkButton>
  )
)

export default DocumentFileLink
