import React, {FC} from 'react'
import {flowMax, addDisplayName, addMemoBoundary} from 'ad-hok'

import TextField from 'components/TextField'
import DateField from 'components/DateField'
import SelectField from 'components/SelectField'
import FieldSet from 'components/FieldSet'
import PhoneFieldArray from 'components/PhoneFieldArray'
import FormRow from 'components/FormRow'
import MultiSelectField from 'components/MultiSelectField'
import FormSection from 'components/FormSection'
import OptionalBooleanField from 'components/OptionalBooleanField'
import CurrencyField from 'components/CurrencyField'
import IncomeSourceFieldArray from 'components/IncomeSourceFieldArray'
import DeductionFieldArray from 'components/DeductionFieldArray'
import AssetFieldArray from 'components/AssetFieldArray'
import {showSelectedOptionForNonexistentDependentOfTaxFilerId} from 'components/EditPersonForm/schema'

export const SharedPersonDataFields: FC = () => (
  <>
    <OptionalBooleanField name="wantsCoverage" />
    <TextField name="firstName" />
    <TextField name="middleName" />
    <TextField name="lastName" />
    <TextField name="suffix" />
    <TextField name="preferredName" />
    <FieldSet labelTranslationKey="phoneNumbers">
      <PhoneFieldArray name="phoneNumbers" />
    </FieldSet>
    <FieldSet labelTranslationKey="email">
      <TextField name="email" />
      <TextField name="emailComment" />
    </FieldSet>
    <SelectField name="preferredLanguage" />
    <DateField name="dob" />
    <SelectField name="gender" />
    <SelectField name="race" />
    <TextField name="ssn" />
    <FieldSet labelTranslationKey="homeAddress">
      <TextField name="homeAddressStreet" />
      <FormRow variant="twoColumn_2_1">
        <TextField name="homeAddressCity" lazyIcon />
        <SelectField name="homeAddressState" />
      </FormRow>
      <TextField name="homeAddressZip" />
      <TextField name="homeAddressComment" />
      <FormRow variant="twoColumn_1_1">
        <SelectField name="addressSinceMonth" lazyIcon />
        <TextField name="addressSinceYear" />
      </FormRow>
    </FieldSet>
    <FieldSet labelTranslationKey="mailingAddress">
      <TextField name="mailingAddressStreet" />
      <FormRow variant="twoColumn_2_1">
        <TextField name="mailingAddressCity" lazyIcon />
        <SelectField name="mailingAddressState" />
      </FormRow>
      <TextField name="mailingAddressZip" />
      <TextField name="mailingAddressComment" />
    </FieldSet>
  </>
)

export const SharedPersonSections: FC = flowMax(
  addDisplayName('SharedPersonSections'),
  addMemoBoundary([]),
  () => (
    <>
      <FormSection labelTranslationKey="insuranceCoverage">
        <OptionalBooleanField name="hasInsurance" />
        <SelectField name="currentInsuranceType" />
        <OptionalBooleanField name="currentInsuranceIncludesDental" />
        <TextField name="currentInsuranceName" />
        <TextField name="currentInsurancePolicyNo" />
        <DateField name="insuranceStartdate" />
      </FormSection>
      <FormSection labelTranslationKey="ci">
        <OptionalBooleanField name="livesInNj" />
        <FormRow variant="twoColumn_1_1">
          <SelectField name="livedInNjSinceMonth" lazyIcon />
          <TextField name="livedInNjSinceYear" />
        </FormRow>
        <SelectField name="njResidencyDoc" />
        <FormRow variant="twoColumn_1_1">
          <SelectField name="homelessSinceMonth" lazyIcon />
          <TextField name="homelessSinceYear" />
        </FormRow>
        <FieldSet labelTranslationKey="lastKnownAddress">
          <TextField name="lastKnownAddressStreet" />
          <FormRow variant="twoColumn_2_1">
            <TextField name="lastKnownAddressCity" lazyIcon />
            <SelectField name="lastKnownAddressState" />
          </FormRow>
          <TextField name="lastKnownAddressZip" />
        </FieldSet>
        <TextField name="othersAttestationWho" />
        <TextField name="othersAttestationRelationship" />
        <TextField type="tel" name="othersAttestationPhone" />
        <SelectField name="ciStatus" />
        <OptionalBooleanField name="pendingUsCitizenship" />
        <OptionalBooleanField name="fiveYearsInUs" />
        <OptionalBooleanField name="specialCategory" />
        <SelectField name="ciDocumentType" />
        <TextField name="ciDocumentName" />
        <DateField name="dateOfEntry" />
        <TextField name="uscisNo" />
        <TextField name="prCardNo" />
        <SelectField name="maritalStatus" />
        <FormRow variant="twoColumn_1_1">
          <SelectField name="separatedSinceMonth" lazyIcon />
          <TextField name="separatedSinceYear" />
        </FormRow>
        <OptionalBooleanField name="dontLiveWithSpouse" />
        <OptionalBooleanField name="noJointAssetsWithSpouse" />
        <OptionalBooleanField name="noFinancialSupportFromSpouse" />
        <OptionalBooleanField name="pregnant" />
        <DateField name="dueDate" />
        <TextField type="number" name="expectedChild" />
        <OptionalBooleanField name="spouseLiveWithMe" />
        <OptionalBooleanField name="spousePregnant" />
        <OptionalBooleanField name="isStudent" />
      </FormSection>
      <FormSection labelTranslationKey="tax">
        <SelectField name="taxFilingStatus" />
        <TextField name="notFiledJointlySince" />
        <SelectField
          name="dependentOfTaxFilerId"
          shouldIgnoreTouchedForFieldError
          showSelectedOptionForNonexistentValue={
            showSelectedOptionForNonexistentDependentOfTaxFilerId
          }
        />
        <OptionalBooleanField name="claimedBySomeoneOtherThanParents" />
        <OptionalBooleanField name="livingWithParentsWhoDoNotFileJointly" />
        <TextField name="reasonNotFiling" />
        <FieldSet labelTranslationKey="preliminaryOnly">
          <TextField type="number" name="estTaxDependentCount" />
          <OptionalBooleanField name="taxDependentInclSpouseLiveWithClient" />
          <TextField type="number" name="estParentLiveWithClient" />
          <TextField type="number" name="estChildren19LiveWithClient" />
          <TextField type="number" name="children18LiveWithClient" />
          <TextField type="number" name="estMinor19SiblingLiveWithClient" />
          <TextField type="number" name="minor18SiblingLiveWithClient" />
          <CurrencyField name="estHouseholdIncome" />
        </FieldSet>
      </FormSection>
      <FormSection labelTranslationKey="incomeAndDeductions">
        <OptionalBooleanField name="income" />
        <IncomeSourceFieldArray name="incomeSources" />
        <SelectField name="changeJobInLast6Mon" />
        <FormRow variant="twoColumn_1_1">
          <SelectField name="noIncomeSinceMonth" lazyIcon />
          <TextField name="noIncomeSinceYear" />
        </FormRow>
        <SelectField name="whoSupportWithoutIncome" />
        <TextField name="selfSupportWithoutIncome" />
        <TextField name="othersSupportWithoutIncome" />
        <TextField name="supporterRelationship" />
        <FormRow variant="twoColumn_1_1">
          <SelectField name="supportSinceMonth" lazyIcon />
          <TextField name="supportSinceYear" />
        </FormRow>
        <MultiSelectField name="supportType" />
        <FieldSet labelTranslationKey="supporterAddress">
          <TextField name="supporterAddressStreet" />
          <FormRow variant="twoColumn_2_1">
            <TextField name="supporterAddressCity" lazyIcon />
            <SelectField name="supporterAddressState" />
          </FormRow>
          <TextField name="supporterAddressZip" />
        </FieldSet>
        <TextField type="tel" name="supporterPhone" />
        <FieldSet labelTranslationKey="deductions">
          <OptionalBooleanField name="hasDeduction" />
          <DeductionFieldArray name="deductions" />
        </FieldSet>
        <FieldSet labelTranslationKey="assets">
          <OptionalBooleanField name="hasAsset" />
          <AssetFieldArray name="assets" />
          <FormRow variant="twoColumn_1_1">
            <SelectField name="noAssetSinceMonth" lazyIcon />
            <TextField name="noAssetSinceYear" />
          </FormRow>
          <OptionalBooleanField name="ownNonresidentProperty" />
          <FieldSet labelTranslationKey="nonresidentPropertyMailingAddress">
            <TextField name="nonresidentPropertyMailingAddressStreet" />
            <FormRow variant="twoColumn_2_1">
              <TextField
                name="nonresidentPropertyMailingAddressCity"
                lazyIcon
              />
              <SelectField name="nonresidentPropertyMailingAddressState" />
            </FormRow>
            <TextField name="nonresidentPropertyMailingAddressZip" />
          </FieldSet>
        </FieldSet>
        <FieldSet labelTranslationKey="additionalMedicaidQuestions">
          <OptionalBooleanField name="insuranceLast3Months" />
          <OptionalBooleanField name="alaskanNativeamerican" />
          <OptionalBooleanField name="fosterCareAfter18" />
          <SelectField name="identityDocument" />
          <TextField name="noIdentityDocReason" />
          <SelectField name="desiredMco" />
          <TextField name="pcp" />
          <TextField name="childrenPcp" />
        </FieldSet>
      </FormSection>
    </>
  )
)
