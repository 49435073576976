import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import {addWrapper, SimpleUnchangedProps, flowMax} from 'ad-hok'
import {ThemeProvider} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'

import theme from 'theme'

const addCssReset: SimpleUnchangedProps = addWrapper((render) => (
  <>
    <CssBaseline />
    {render()}
  </>
))

const addThemeProvider: SimpleUnchangedProps = addWrapper((render) => (
  <ThemeProvider theme={theme}>{render()}</ThemeProvider>
))

const addRobotoFont: SimpleUnchangedProps = addWrapper((render) => (
  <>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
    />
    {render()}
  </>
))

const addPickersUtilsProvider: SimpleUnchangedProps = addWrapper((render) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    {render()}
  </MuiPickersUtilsProvider>
))

const addMaterialUI: SimpleUnchangedProps = flowMax(
  addCssReset,
  addThemeProvider,
  addRobotoFont,
  addPickersUtilsProvider
)

export default addMaterialUI
