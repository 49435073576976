import parseISO from 'date-fns/parseISO'
import {mapValues, get, isPlainObject, isArray, set} from 'lodash'

import {
  FormSchemaFields,
  FormSchema,
  FormSchemaObjectFields,
  isField,
  isFormCollection,
  FormSchemaCollection,
  extractFormCollectionFields,
  FormInitialValues,
  FormObjectInitialValues,
} from 'utils/form/schema'
import {FormValueChange} from 'utils/form/getValues'

export interface DeserializedFieldsUpdatedAt {
  [key: string]:
    | Date
    | DeserializedFieldsUpdatedAt
    | DeserializedFieldsUpdatedAt[]
}

export interface FieldsUpdatedAt {
  [key: string]: string | FieldsUpdatedAt | FieldsUpdatedAt[]
}

export interface FieldsFreshnessValue {
  updatedAt: Date
  confirmed: boolean
}

export interface FieldsFreshness {
  [key: string]: FieldsFreshnessValue | FieldsFreshness[]
}

export interface FieldsFreshnessObject {
  [key: string]: FieldsFreshness
}

export const parseFieldsUpdatedAt = (
  fieldsUpdatedAt: string
): DeserializedFieldsUpdatedAt =>
  parseFieldsUpdatedObject(JSON.parse(fieldsUpdatedAt))

const parseFieldsUpdatedObject = (
  fieldsUpdatedAtObject: FieldsUpdatedAt
): DeserializedFieldsUpdatedAt =>
  mapValues(fieldsUpdatedAtObject, (field) => {
    if (isPlainObject(field)) {
      return parseFieldsUpdatedObject(field as FieldsUpdatedAt)
    }

    if (isArray(field)) {
      return field.map(parseFieldsUpdatedObject)
    }

    return parseISO(field as string)
  })

export const serializeFieldsUpdatedAt = (
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt
): string => JSON.stringify(fieldsUpdatedAt)

type GetInitialFieldsUpdatedAtType = <TFields extends FormSchemaFields>(
  schema: FormSchema<TFields>,
  initialValues?: FormInitialValues<TFields>
) => any

export const getInitialFieldsUpdatedAt: GetInitialFieldsUpdatedAtType = (
  schema,
  initialValues
) =>
  mapValues(schema.fields, (fields, objectName) =>
    getObjectInitialFieldsUpdatedAt(
      fields,
      initialValues && initialValues[objectName],
      initialValues && initialValues[objectName].fieldsUpdatedAt
    )
  )

type GetObjectInitialFieldsUpdatedAtType = <
  TFields extends FormSchemaObjectFields
>(
  fields: TFields,
  initialValues?: FormObjectInitialValues<TFields>,
  fieldsUpdatedAt?: any
) => any

export const getObjectInitialFieldsUpdatedAt: GetObjectInitialFieldsUpdatedAtType = (
  fields,
  initialValues,
  fieldsUpdatedAt = {}
) =>
  mapValues(fields, (fieldOrCollection, name) => {
    if (isField(fieldOrCollection)) {
      return fieldsUpdatedAt ? fieldsUpdatedAt[name] : undefined
    }

    if (isFormCollection(fieldOrCollection)) {
      return getCollectionInitialFieldsUpdatedAt(
        fieldOrCollection,
        initialValues && (initialValues[name] as any[]),
        fieldsUpdatedAt && fieldsUpdatedAt[name]
      )
    }

    return getObjectInitialFieldsUpdatedAt(
      fieldOrCollection as FormSchemaObjectFields,
      initialValues && (initialValues[name] as any),
      initialValues && initialValues[name].fieldsUpdatedAt
    )
  })

type GetCollectionInitialFieldsUpdatedAtType = (
  collectionSchema: FormSchemaCollection<any>,
  initialValues?: any[],
  fieldsUpdatedAt?: any[]
) => any

const getCollectionInitialFieldsUpdatedAt: GetCollectionInitialFieldsUpdatedAtType = (
  collectionSchema,
  initialValues,
  fieldsUpdatedAt = []
) => {
  if (!initialValues) return []

  return initialValues.map((item: any, index) =>
    getObjectInitialFieldsUpdatedAt(
      extractFormCollectionFields(collectionSchema),
      item,
      (fieldsUpdatedAt && fieldsUpdatedAt[index]) || item.fieldsUpdatedAt
    )
  )
}

export const getFieldsFreshnessConfirmed = (
  fieldsFreshness: FieldsFreshnessObject,
  name: string
): boolean => get(fieldsFreshness, `${name}.confirmed`, false) as boolean

export const getFieldsFreshnessUpdatedAt = (
  fieldsFreshness: FieldsFreshnessObject,
  name: string
): Date | undefined => get(fieldsFreshness, `${name}.updatedAt`) as any

export const getInitialFieldsFreshness = ({
  changes,
  fieldsUpdatedAt,
}: {
  changes: FormValueChange[]
  fieldsUpdatedAt: FieldsUpdatedAt
}): any =>
  changes.reduce(
    (fieldsFreshness, {path}) =>
      set(fieldsFreshness, path, {
        updatedAt: get(fieldsUpdatedAt, path),
        confirmed: false,
      }),
    {}
  )

export const getFieldsFreshness = ({
  changes,
  fieldsFreshness,
  fieldsUpdatedAt,
}: {
  fieldsFreshness: any
  changes: FormValueChange[]
  fieldsUpdatedAt: FieldsUpdatedAt
}): any =>
  changes
    .filter(({type}) => type === 'changed' || type === 'added')
    .reduce(
      (fieldsFreshness, {path, type}) => {
        const currentFieldFreshness = get(fieldsFreshness, path) ?? {}
        const newFieldFreshness = {
          updatedAt: get(fieldsUpdatedAt, path),
          confirmed: currentFieldFreshness.confirmed || type !== 'added',
        }
        return set(fieldsFreshness, path, newFieldFreshness)
      },
      {...fieldsFreshness}
    )
