import React from 'react'
import {flowMax, addDisplayName, addState, addHandlers} from 'ad-hok'
import {FC} from 'react'

import {makeClasses, addClasses} from 'theme'
import addApolloClient from 'utils/addApolloClient'
import {addFormikTyped} from 'utils/form/formik'
import {staticEditAccountFormSchema} from 'components/EditAccountForm/schema'
import {addTranslationHelpers} from 'utils/i18n'
import {UPDATE_ACCOUNT_MUTATION} from 'graphql/mutations'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogContent from 'components/DialogContent'
import {StandaloneTextField} from 'components/TextField'
import Button from 'components/Button'
import {getCanonicalValues} from 'utils/form/getValues'
import {ACCOUNT_QUERY} from 'graphql/queries'
import {serializeUpdateAccountVariables} from 'graphql/deserializedTypes/UpdateAccount'

const classes = makeClasses((theme) => ({
  contentContainer: {
    width: 600,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  submitButton: {
    display: 'block',
    marginTop: theme.spacing(3),
  },
}))

interface Props {
  open: boolean
  onClose: () => void
}

const ReparentAccountDialog: FC<Props> = flowMax(
  addDisplayName('ReparentAccountDialog'),
  addApolloClient,
  addState('personId', 'setPersonId', ''),
  addFormikTyped(staticEditAccountFormSchema),
  addTranslationHelpers,
  addHandlers({
    onSubmit: ({
      apolloClient,
      personId,
      onClose,
      formik: {
        values: {account},
      },
      t,
    }) => async () => {
      try {
        await apolloClient.mutate({
          mutation: UPDATE_ACCOUNT_MUTATION,
          variables: serializeUpdateAccountVariables({
            account: {
              ...getCanonicalValues({account}, staticEditAccountFormSchema)
                .account,
              personId,
            },
          }),
          refetchQueries: [
            {
              query: ACCOUNT_QUERY,
              variables: {id: account.id},
            },
          ],
        })

        onClose()
      } catch (e) {
        alert(t('reparentAccountDialog.notFound'))
      }
    },
  }),
  addClasses(classes),
  ({open, onClose, personId, setPersonId, onSubmit, classes, t}) => (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('reparentAccountDialog.title')}</DialogTitle>
      <DialogContent className={classes.contentContainer} dividers>
        <StandaloneTextField
          label={t('reparentAccountDialog.personId')}
          value={personId}
          onChange={({target: {value}}) => setPersonId(value)}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={!personId}
          className={classes.submitButton}
        >
          {t('reparentAccountDialog.submit')}
        </Button>
      </DialogContent>
    </Dialog>
  )
)

export default ReparentAccountDialog
