import React from 'react'
import ApolloClient, {ApolloError, InMemoryCache} from 'apollo-boost'
import jwtDecode, {JwtPayload} from 'jwt-decode'
import {
  addState,
  addWrapper,
  branch,
  flowMax,
  renderNothing,
  SimpleUnchangedProps,
} from 'ad-hok'
import {addPropTrackingRef} from 'ad-hok-utils'
import {ApolloProvider} from '@apollo/react-hooks'
import {Auth0ContextInterface} from '@auth0/auth0-react'
import {IntrospectionFragmentMatcher} from 'apollo-cache-inmemory'

import introspectionQueryResultData from 'graphql/types/fragmentTypes.json'
import {addAuth0Context} from 'utils/auth0'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})
const cache = new InMemoryCache({fragmentMatcher})
let token = ''
let tokenRenew = false

const getClient = (
  auth0ContextRef: React.MutableRefObject<Auth0ContextInterface>
) =>
  new ApolloClient({
    uri: '/graphql',
    cache,
    request: async (operation) => {
      if (token !== '') {
        var decoded = jwtDecode<JwtPayload>(token)
        const currentTimePlusMin = Date.parse(Date()) / 1000 + 60
        tokenRenew = decoded.exp ? currentTimePlusMin > decoded.exp : true
        console.log(
          'Token Issued At: ' +
            decoded.iat +
            ' Current time Plus Min: ' +
            currentTimePlusMin +
            ' Token Expires At: ' +
            decoded.exp +
            ' Token about to expire or already expired: ' +
            tokenRenew
        )
      }
      if (!token || tokenRenew) {
        token = await auth0ContextRef.current.getAccessTokenSilently()
        console.log('Got New Token: ' + token)
      }

      operation.setContext({
        headers: {
          authorization: token,
        },
      })
    },
  })

export const addApolloProvider: SimpleUnchangedProps = flowMax(
  addAuth0Context,
  addPropTrackingRef('auth0Context', 'auth0ContextRef'),
  addState('apolloClient', 'setApolloClient', ({auth0ContextRef}) =>
    getClient(auth0ContextRef)
  ),
  branch(({auth0Context: {isLoading}}) => isLoading, renderNothing()),
  addWrapper((render, {apolloClient}) => (
    <ApolloProvider client={apolloClient}>{render()}</ApolloProvider>
  ))
)

export const isAuth0TokenInvalid = ({graphQLErrors}: ApolloError) =>
  graphQLErrors?.some(({extensions}) => extensions?.code === 'UNAUTHORIZED')

export const getAuthToken = () => token
