/* eslint-disable */
import {PersonMatchVariables as PersonMatchVariablesOrig} from '../types/PersonMatch'
import {PersonMatch as PersonMatchOrig} from '../types/PersonMatch'
import {formatISODate} from 'utils/date'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {PersonMatch_personMatch as PersonMatch_personMatchOrig} from '../types/PersonMatch'
import {PersonMatch_personMatch_assets as PersonMatch_personMatch_assetsOrig} from '../types/PersonMatch'
import {PersonMatch_personMatch_deductions as PersonMatch_personMatch_deductionsOrig} from '../types/PersonMatch'
import {PersonMatch_personMatch_incomeSources as PersonMatch_personMatch_incomeSourcesOrig} from '../types/PersonMatch'
import {PersonMatch_personMatch_phoneNumbers as PersonMatch_personMatch_phoneNumbersOrig} from '../types/PersonMatch'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: PersonMatch
// ====================================================
export interface PersonMatch_personMatch_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializePersonMatch_personMatch_phoneNumbers = (
  serialized: PersonMatch_personMatch_phoneNumbersOrig
): PersonMatch_personMatch_phoneNumbers => ({...serialized})
export const serializePersonMatch_personMatch_phoneNumbers = (
  deserialized: PersonMatch_personMatch_phoneNumbers
): PersonMatch_personMatch_phoneNumbersOrig => ({...deserialized})
export interface PersonMatch_personMatch_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
}
export const deserializePersonMatch_personMatch_incomeSources = (
  serialized: PersonMatch_personMatch_incomeSourcesOrig
): PersonMatch_personMatch_incomeSources => ({...serialized})
export const serializePersonMatch_personMatch_incomeSources = (
  deserialized: PersonMatch_personMatch_incomeSources
): PersonMatch_personMatch_incomeSourcesOrig => ({...deserialized})
export interface PersonMatch_personMatch_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializePersonMatch_personMatch_deductions = (
  serialized: PersonMatch_personMatch_deductionsOrig
): PersonMatch_personMatch_deductions => ({...serialized})
export const serializePersonMatch_personMatch_deductions = (
  deserialized: PersonMatch_personMatch_deductions
): PersonMatch_personMatch_deductionsOrig => ({...deserialized})
export interface PersonMatch_personMatch_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializePersonMatch_personMatch_assets = (
  serialized: PersonMatch_personMatch_assetsOrig
): PersonMatch_personMatch_assets => ({...serialized})
export const serializePersonMatch_personMatch_assets = (
  deserialized: PersonMatch_personMatch_assets
): PersonMatch_personMatch_assetsOrig => ({...deserialized})
export interface PersonMatch_personMatch {
  __typename: 'Person'
  id: string
  updatedAt: Date
  firstName: string | null
  middleName: string | null
  lastName: string | null
  suffix: string | null
  preferredName: string | null
  dob: Date | null
  gender: string | null
  ssn: string | null
  phoneNumbers: PersonMatch_personMatch_phoneNumbers[]
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  email: string | null
  emailComment: string | null
  preferredLanguage: string | null
  hospitalPatientId: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  incomeSources: PersonMatch_personMatch_incomeSources[]
  deductions: PersonMatch_personMatch_deductions[]
  assets: PersonMatch_personMatch_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  unreadMessageStatus: boolean | null
}
export const deserializePersonMatch_personMatch = (
  serialized: PersonMatch_personMatchOrig
): PersonMatch_personMatch => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializePersonMatch_personMatch_phoneNumbers
  ),
  incomeSources: serialized.incomeSources.map(
    deserializePersonMatch_personMatch_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializePersonMatch_personMatch_deductions
  ),
  assets: serialized.assets.map(deserializePersonMatch_personMatch_assets),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
})
export const serializePersonMatch_personMatch = (
  deserialized: PersonMatch_personMatch
): PersonMatch_personMatchOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializePersonMatch_personMatch_phoneNumbers
  ),
  incomeSources: deserialized.incomeSources.map(
    serializePersonMatch_personMatch_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializePersonMatch_personMatch_deductions
  ),
  assets: deserialized.assets.map(serializePersonMatch_personMatch_assets),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
})
export interface PersonMatch {
  /**
   * Matches existing people
   */
  personMatch: PersonMatch_personMatch[]
}
export const deserializePersonMatch = (
  serialized: PersonMatchOrig
): PersonMatch => ({
  ...serialized,
  personMatch: serialized.personMatch.map(deserializePersonMatch_personMatch),
})
export const serializePersonMatch = (
  deserialized: PersonMatch
): PersonMatchOrig => ({
  ...deserialized,
  personMatch: deserialized.personMatch.map(serializePersonMatch_personMatch),
})
export interface PersonMatchVariables {
  ssn?: string | null
  hospitalPatientId?: string | null
  firstName?: string | null
  lastName?: string | null
  dob?: Date | null
}
export const deserializePersonMatchVariables = (
  serialized: PersonMatchVariablesOrig
): PersonMatchVariables => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
})
export const serializePersonMatchVariables = (
  deserialized: PersonMatchVariables
): PersonMatchVariablesOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
})
