import React from 'react'
import {flowMax, addDisplayName, addHandlers} from 'ad-hok'
import {FC} from 'react'

import {ReceivedWebforms_receivedWebforms} from 'graphql/deserializedTypes/ReceivedWebforms'
import {addUpdateReceivedWebformMutation} from 'graphql/generated'
import {addTranslationHelpers} from 'utils/i18n'
import {addClasses, makeClasses} from 'theme'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import {WebformResponseDetails} from 'utils/webforms'
import Button from 'components/Button'
import DialogActions from 'components/DialogActions'
import {unmatchedWebformsRefetchOptions} from 'components/UnmatchedWebformsWorklist/shared'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'

const classes = makeClasses((theme) => ({
  modalContainer: {
    minWidth: 400,
  },
  modalInfoSection: {
    marginBottom: theme.spacing(4),
  },
  buttonsRowContainer: {
    flexDirection: 'row',
  },
  actionButton: {
    width: 160,
    height: 40,
    lineHeight: 1.3,
    marginRight: theme.spacing(1),
  },
}))

interface Props {
  receivedWebform: ReceivedWebforms_receivedWebforms
  open: boolean
  onClose: () => void
  onMatchToExistingPersonClick: () => void
  onCreateNewPersonClick: () => void
}

export const InfoModal: FC<Props> = flowMax(
  addDisplayName('InfoModal'),
  addUpdateReceivedWebformMutation(unmatchedWebformsRefetchOptions),
  addTranslationHelpers,
  addAppSnackbarContext,
  addHandlers({
    onNotAMatchClick: ({
      onClose,
      mutateUpdateReceivedWebform,
      receivedWebform,
      showSnackbarMessage,
      t,
    }) => () => {
      mutateUpdateReceivedWebform({
        variables: {
          receivedWebform: {
            id: receivedWebform.id,
            noPersonMatch: true,
          },
        },
      })
        .then(() => {
          onClose()
          showSnackbarMessage(t('worklist.unmatchedWebforms.recordedNoMatch'))
        })
        .catch(() => {
          window.alert(t('worklist.unmatchedWebforms.failedToSave'))
        })
    },
  }),
  addClasses(classes),
  ({
    open,
    onClose,
    onNotAMatchClick,
    receivedWebform,
    onMatchToExistingPersonClick,
    onCreateNewPersonClick,
    classes,
    t,
  }) => (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{paper: classes.modalContainer}}
    >
      <DialogContent>
        <div className={classes.modalInfoSection}>
          <WebformResponseDetails receivedWebform={receivedWebform} />
        </div>
        <div className={classes.buttonsRowContainer}>
          <Button
            onClick={onCreateNewPersonClick}
            variant="contained"
            className={classes.actionButton}
          >
            {t('worklist.unmatchedWebforms.createNewPerson')}
          </Button>
          <Button
            onClick={onMatchToExistingPersonClick}
            variant="contained"
            className={classes.actionButton}
          >
            {t('worklist.unmatchedWebforms.matchToExistingPerson')}
          </Button>
          <Button
            onClick={onNotAMatchClick}
            variant="contained"
            className={classes.actionButton}
          >
            {t('worklist.unmatchedWebforms.notAMatch')}
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('buttons.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
)
