import React, {FC} from 'react'
import {flowMax, addDisplayName} from 'ad-hok'
import {Routes, Route} from 'react-router-dom'
import {matchPath} from 'react-router'

import Worklist from 'components/Worklist'
import Login from 'components/Login'
import {addRedirectUnlessSignedIn} from 'utils/auth0'
import {addSentrySetUser} from 'utils/sentry'
import addAuthenticationErrorBoundary from 'utils/addAuthenticationErrorBoundary'
import Page from 'components/Page'
import CreateAccountAndPatientForm from 'components/CreateAccountAndPatientForm'
import EditPersonForm from 'components/EditPersonForm'
import PersonDetail, {
  getPersonDetailTabSubpath,
  personDetailPath,
} from 'components/PersonDetail'
import PersonDetailAccounts from 'components/PersonDetailAccounts'
import PersonDetailBenefitOutcomes from 'components/PersonDetailBenefitOutcomes'
import PersonDetailApplications from 'components/PersonDetailApplications'
import PersonDetailTriages from 'components/PersonDetailTriages'
import PersonDetailFiles from 'components/PersonDetailFiles'
import EditAccountForm from 'components/EditAccountForm'
import EditApplicationForm from 'components/EditApplicationForm'
import PersonDetailTasks from 'components/PersonDetailTasks'
import MedicaidApplicationView from 'components/MedicaidApplicationView'
import MedicaidApplicationSubmission from 'components/MedicaidApplicationSubmission'
import {addConfigContextProvider} from 'utils/configContext'
import PatientPortal from 'components/PatientPortal'
import {addRedirectToAndFromPatientPortal} from 'utils/patientPortal'

export const createAccountPath = '/create-account'
export const personDetailDefaultPath = `${personDetailPath}/applications`
export const editPersonPath = '/person/:id/edit'
export const editAccountPath = '/account/:id/edit'
export const editApplicationPath = '/application/:id/edit'
export const medicaidApplicationViewPath = '/application/:id/medicaid-view'
export const medicaidApplicationSubmitPath =
  '/application/:id/submit-application'
export const patientPortalPath = '/patient-portal'

export const isApplicationViewPath = (path: string) =>
  !!matchPath({path: medicaidApplicationViewPath}, path)
export const isApplicationSubmitPath = (path: string) =>
  !!matchPath({path: medicaidApplicationSubmitPath}, path)

const AuthenticatedRoutes: FC = flowMax(
  addDisplayName('AuthenticatedRoutes'),
  addAuthenticationErrorBoundary,
  addRedirectUnlessSignedIn('/login'),
  addRedirectToAndFromPatientPortal,
  addSentrySetUser,
  addConfigContextProvider,
  () => (
    <Page>
      <Routes>
        <Route path="/" element={<Worklist />} />
        <Route
          path={createAccountPath}
          element={<CreateAccountAndPatientForm />}
        />
        <Route path={personDetailPath} element={<PersonDetail />}>
          <Route
            path={getPersonDetailTabSubpath('applications')}
            element={<PersonDetailApplications />}
          />
          <Route
            path={getPersonDetailTabSubpath('benefitOutcomes')}
            element={<PersonDetailBenefitOutcomes />}
          />
          <Route
            path={getPersonDetailTabSubpath('accounts')}
            element={<PersonDetailAccounts />}
          />
          <Route
            path={getPersonDetailTabSubpath('triages')}
            element={<PersonDetailTriages />}
          />
          <Route
            path={getPersonDetailTabSubpath('files')}
            element={<PersonDetailFiles />}
          />
          <Route
            path={getPersonDetailTabSubpath('tasks')}
            element={<PersonDetailTasks />}
          />
        </Route>
        <Route path={editPersonPath} element={<EditPersonForm />} />
        <Route path={editAccountPath} element={<EditAccountForm />} />
        <Route path={editApplicationPath} element={<EditApplicationForm />} />
        <Route
          path={medicaidApplicationViewPath}
          element={<MedicaidApplicationView />}
        />
        <Route
          path={medicaidApplicationSubmitPath}
          element={<MedicaidApplicationSubmission />}
        />
        <Route path={patientPortalPath} element={<PatientPortal />} />
        <Route path="/nj-portal" element={<NJPortal />} />
      </Routes>
    </Page>
  )
)
const NJPortal = () => {
  window.location.href = 'https://dmahs-nj.my.site.com/admintool/login'
  return <h2>Redirecting...</h2>
}
const TopLevelRoutes: FC = () => (
  <Routes>
    <Route path="//*" element={<AuthenticatedRoutes />} />
    <Route path="login" element={<Login />} />
  </Routes>
)

export default TopLevelRoutes
