declare global {
  interface Window {
    SageAppConfig: {
      REACT_APP_AUTH0_AUDIENCE: string
      REACT_APP_AUTH0_CLIENT_ID: string
      REACT_APP_AUTH0_DOMAIN: string
      SHOW_ENV_WARNING: string | null
      SENTRY_ENVIRONMENT: string | null
      isPatientPortal: boolean
      AUDIT_INGEST_SHARED_SECRET: string
    }
  }
}

export const AUTH0_AUDIENCE = window.SageAppConfig?.REACT_APP_AUTH0_AUDIENCE
export const AUTH0_CLIENT_ID = window.SageAppConfig?.REACT_APP_AUTH0_CLIENT_ID
export const AUTH0_DOMAIN = window.SageAppConfig?.REACT_APP_AUTH0_DOMAIN
export const showEnvWarning = window.SageAppConfig?.SHOW_ENV_WARNING
export const sentryEnvironment =
  window.SageAppConfig?.SENTRY_ENVIRONMENT ?? undefined
export const isPatientPortal = !!window.SageAppConfig?.isPatientPortal
export const AUDIT_INGEST_SHARED_SECRET =
  window.SageAppConfig?.AUDIT_INGEST_SHARED_SECRET
