import {isArray, isFunction, min, get, reduce, values} from 'lodash/fp'
import {TFunction} from 'i18next'
import {range} from 'lodash'
import {FormikErrors} from 'formik'
import {isAfter} from 'date-fns'

import {
  FormSchema,
  FormCanonicalValues,
  FormObjectCanonicalValues,
} from 'utils/form/schema'
import {FormFieldStatus, FormFieldStatuses} from 'utils/form/context'
import flattenObjectKeys from 'utils/flattenObjectKeys'
import {getCanonicalValues} from 'utils/form/getValues'
import {FieldsFreshnessObject} from 'utils/form/getFieldsUpdatedAt'
import {AdditionalData} from 'utils/getAdditionalData'

type NecessaryIf =
  | string[]
  | {
      [fieldName: string]: any
      // | Exclude<string | number | boolean | {}, Function>
      // | ((opts: {value: any, values: FormCanonicalValues<any>}) => boolean)
    }

export type DeterminerRule = {
  name: string
  necessaryIf: NecessaryIf
  ineligibleIf?: (opts: {
    value: any
    values: any
  }) =>
    | {
        reasonOrInfo?: string | null
        secondaryInfo?: string
        month?: Date
        numMonths?: number
        isEligible: boolean | null
        shouldPreventEligibleDetermination?: boolean
        additionalData?: AdditionalData
      }
    | boolean
  achieves?: (opts: {value: any; values: any}) => string | null
  clearNecessaryIfIneligible?: boolean
  useResolvedIndexes?: number[][]
  isEligibleIfPassesIneligibilityCheck?: boolean
  shouldCheckIneligibilityImmediately?: boolean
  necessaryIfResolved?: (opts: any) => boolean
  getIsBlank?: (value: any) => boolean
}

export const makeDeterminerRule = (rule: DeterminerRule) => rule

export type Determiner = {
  name: string
  isPreliminary?: boolean
  rules: DeterminerRule[]
}

export const makeDeterminer = (determiner: Determiner) => determiner

export const START_TOKEN = 'START'

export const isBlank = (
  value: any,
  {
    getIsBlank,
  }: {
    getIsBlank?: (value: any) => boolean
  } = {}
) =>
  value == null ||
  value === '' ||
  (isArray(value) && !value.length) ||
  getIsBlank?.(value)

const freshnessUnknown = (resolvedName: string) =>
  resolvedName.startsWith('application') ||
  resolvedName === 'person.phoneNumbers'

const isFreshOrConfirmed = (
  resolvedName: string,
  fieldsFreshness: any,
  staleThresholdDate: Date
) => {
  const {updatedAt, confirmed} = get(resolvedName, fieldsFreshness) ?? {
    updatedAt: null,
    confirmed: false,
  }
  return confirmed || (updatedAt && isAfter(updatedAt, staleThresholdDate))
}

interface GetFormStatusesOptions {
  determiners: Determiner[]
  values: any
  fieldsFreshness?: FieldsFreshnessObject
  staleThresholdDate?: Date
  formName: string
  t: TFunction
  schema: FormSchema<any>
  errors: FormikErrors<any>
}

export interface DeterminedEligibilitiesForBenefit {
  name: string
  isPreliminary: boolean
  reasonOrInfo: string | null
  isEligible: boolean | null
  secondaryInfo?: string | null
  numMonths?: number | null
  month?: Date | null
  additionalData?: AdditionalData | null
}

export interface DeterminedEligibilitiesForFullMedicaid
  extends DeterminedEligibilitiesForBenefit {
  secondaryInfo: string
  month: Date
}

export interface DeterminedEligibilitiesForFullCharityCare
  extends DeterminedEligibilitiesForBenefit {
  secondaryInfo?: string
  numMonths: number
  additionalData?: AdditionalData
}

export interface DeterminedEligibilitiesForFullSlide
  extends DeterminedEligibilitiesForBenefit {}

export interface DeterminedEligibilitiesForFullRyanWhite
  extends DeterminedEligibilitiesForBenefit {}

const getMergedFieldStatus = ({
  fieldName,
  allStatuses,
}: {
  fieldName: string
  allStatuses: Array<FormFieldStatuses>
}): FormFieldStatus =>
  allStatuses.find((statuses) => !!statuses[fieldName])?.[fieldName]

type IsNecessaryDetermination = 'name' | 'achievement' | false

const isNecessary = ({
  necessaryIf,
  name,
  achievement,
  value,
  canonicalValues,
  resolvedIndexes,
}: {
  necessaryIf: NecessaryIf
  name: string
  achievement: string | undefined | null
  value: any
  canonicalValues: any
  resolvedIndexes: number[]
}): IsNecessaryDetermination => {
  if (isArray(necessaryIf)) {
    if (necessaryIf.includes(name)) return 'name'
    if (achievement && necessaryIf.includes(achievement)) return 'achievement'
    return false
  }
  const keys = Object.keys(necessaryIf)
  if (achievement && keys.includes(achievement)) {
    const achievementValue = necessaryIf[achievement]
    if (!isFunction(achievementValue)) return 'achievement'
    if (achievementValue({value, values: canonicalValues})) return 'achievement'
  }
  if (!keys.includes(name)) return false
  const necessaryIfValue = necessaryIf[name]
  if (isFunction(necessaryIfValue))
    return necessaryIfValue({value, values: canonicalValues, resolvedIndexes})
      ? 'name'
      : false
  if (isArray(necessaryIfValue))
    return necessaryIfValue.includes(value) ? 'name' : false
  return necessaryIfValue === value ? 'name' : false
}

const extractBasename = (name: string) => name.slice(name.lastIndexOf('.') + 1)

interface ValueWithResolvedName {
  value: any
  resolvedName: string
  resolvedIndexes: number[]
}

const arrayPrefixMatches = (array1: number[], array2: number[]) =>
  !range(0, min([array1.length, array2.length])).some(
    (index) => array1[index] !== array2[index]
  )

export const getValuesWithResolvedNames = ({
  name,
  canonicalValues,
  useResolvedIndexes,
}: {
  name: string
  canonicalValues: FormCanonicalValues<any> | FormObjectCanonicalValues<any>
  useResolvedIndexes?: number[][] | undefined
}): ValueWithResolvedName[] => {
  if (!/\[/.test(name))
    return [
      {
        resolvedName: name,
        value: get(name)(canonicalValues),
        resolvedIndexes: [],
      },
    ]
  const nameChunks = name.split('[].')
  const traverseSubtree = ({
    namePrefix,
    resolvedIndexes,
    value,
  }: {
    namePrefix: string
    resolvedIndexes: number[]
    value: any
  }): ValueWithResolvedName[] => {
    if (resolvedIndexes.length === nameChunks.length - 1)
      return [
        {
          resolvedName: namePrefix,
          value,
          resolvedIndexes,
        },
      ]
    if (!isArray(value)) return []
    const nextNameChunk = nameChunks[resolvedIndexes.length + 1]
    return value.flatMap((itemValue, itemIndex) => {
      if (
        useResolvedIndexes?.length &&
        !useResolvedIndexes.find((useResolvedIndex) =>
          arrayPrefixMatches([...resolvedIndexes, itemIndex], useResolvedIndex)
        )
      )
        return []
      return traverseSubtree({
        namePrefix: `${namePrefix}[${itemIndex}].${nextNameChunk}`,
        resolvedIndexes: [...resolvedIndexes, itemIndex],
        value: get(nextNameChunk)(itemValue),
      })
    })
  }
  return traverseSubtree({
    namePrefix: nameChunks[0],
    resolvedIndexes: [],
    value: get(nameChunks[0])(canonicalValues),
  })
}

interface DeterminerRuleWithImmediateIneligibilityCheckFlag
  extends DeterminerRule {
  isImmediateIneligibilityCheck?: boolean
}

export const runSingleDeterminer = ({
  canonicalValues,
  fieldsFreshness,
  staleThresholdDate,
  formName,
  t,
  errors,
  allFieldNamesWithStatus,
}: Pick<
  GetFormStatusesOptions,
  'fieldsFreshness' | 'staleThresholdDate' | 'formName' | 't' | 'errors'
> & {
  canonicalValues: FormCanonicalValues<any>
  allFieldNamesWithStatus: {
    [fieldName: string]: true
  }
}) => ({rules, name: determinerName, isPreliminary}: Determiner) => {
  let reasonOrInfo: string | null = null
  let statuses: FormFieldStatuses = {}
  let isEligible: boolean | null = null
  let secondaryInfo: string | null = null
  let month: Date | null = null
  let numMonths: number | null = null
  let additionalData: AdditionalData | null = null
  for (const fieldName in flattenObjectKeys<
    FormikErrors<any>,
    {
      [fieldName: string]: any
    }
  >(errors)) {
    statuses[fieldName] = 'invalid'
    allFieldNamesWithStatus[fieldName] = true
  }
  let currentRules: DeterminerRuleWithImmediateIneligibilityCheckFlag[] = [
    ...rules.filter(
      ({necessaryIf}) =>
        isArray(necessaryIf) && necessaryIf.includes(START_TOKEN)
    ),
    ...rules
      .filter(
        ({shouldCheckIneligibilityImmediately}) =>
          shouldCheckIneligibilityImmediately
      )
      .map((rule) => ({
        ...rule,
        isImmediateIneligibilityCheck: true,
      })),
  ]
  let hasSeenBlankNecessary = false
  outer: while (currentRules.length) {
    const nextRules = []
    for (const rule of currentRules) {
      const {
        name,
        ineligibleIf,
        achieves,
        clearNecessaryIfIneligible = true,
        useResolvedIndexes,
        isEligibleIfPassesIneligibilityCheck,
        isImmediateIneligibilityCheck,
        necessaryIfResolved,
        getIsBlank,
      } = rule
      const valuesWithResolvedNames = getValuesWithResolvedNames({
        name,
        canonicalValues,
        useResolvedIndexes,
      })
      for (const {
        value,
        resolvedName,
        resolvedIndexes,
      } of valuesWithResolvedNames) {
        const ineligibleInfo = ineligibleIf?.({
          value,
          values: canonicalValues,
        })
        let isIneligible = !!ineligibleInfo
        let isUndecided = false
        let shouldPreventEligibleDetermination = false
        if (ineligibleInfo != null && typeof ineligibleInfo !== 'boolean') {
          reasonOrInfo = ineligibleInfo.reasonOrInfo ?? null
          secondaryInfo = ineligibleInfo.secondaryInfo ?? null
          month = ineligibleInfo.month ?? null
          numMonths = ineligibleInfo.numMonths ?? null
          additionalData = ineligibleInfo.additionalData ?? null
          isIneligible = ineligibleInfo.isEligible === false
          isUndecided = ineligibleInfo.isEligible == null
          shouldPreventEligibleDetermination = !!ineligibleInfo.shouldPreventEligibleDetermination
        }
        if (isIneligible) {
          if (!reasonOrInfo) {
            reasonOrInfo = t(
              `${formName}.ineligibilityReasons.${extractBasename(name)}`
            )
          }
          isEligible = false
          if (clearNecessaryIfIneligible) {
            for (const fieldName in statuses) {
              if (statuses[fieldName] === 'necessary') {
                delete statuses[fieldName]
              }
            }
            break outer
          }
        } else if (
          isEligibleIfPassesIneligibilityCheck &&
          !isImmediateIneligibilityCheck &&
          !isBlank(value, {getIsBlank}) &&
          !isUndecided
        ) {
          isEligible = true
        }
        if (shouldPreventEligibleDetermination) {
          hasSeenBlankNecessary = true
        }
        if (isImmediateIneligibilityCheck) continue
        if (
          freshnessUnknown(resolvedName) ||
          !fieldsFreshness ||
          !staleThresholdDate
            ? isBlank(value, {getIsBlank})
            : !isFreshOrConfirmed(
                resolvedName,
                fieldsFreshness,
                staleThresholdDate
              )
        ) {
          if (
            necessaryIfResolved &&
            !necessaryIfResolved({
              value,
              values: canonicalValues,
              resolvedIndexes,
            })
          )
            continue
          if (statuses[resolvedName] !== 'invalid') {
            statuses = {
              ...statuses,
              [resolvedName]: 'necessary',
            }
            allFieldNamesWithStatus[resolvedName] = true
          }
          const isFieldBlank = !!isBlank(value, {getIsBlank})
          if (!hasSeenBlankNecessary) {
            hasSeenBlankNecessary = isFieldBlank
          }
          if (isFieldBlank) continue
        }
        const achievement = achieves?.({value, values: canonicalValues})
        const dependentRules = rules
          .map((rule) => {
            const isNecessaryDetermination = isNecessary({
              necessaryIf: rule.necessaryIf,
              name,
              achievement,
              value,
              canonicalValues,
              resolvedIndexes,
            })
            if (!isNecessaryDetermination) return null
            if (isNecessaryDetermination === 'achievement') return rule
            return {
              ...rule,
              useResolvedIndexes: [resolvedIndexes],
            }
          })
          .filter((x) => x) as DeterminerRule[]
        nextRules.push(...dependentRules)
      }
    }
    currentRules = nextRules
  }

  return {
    name: determinerName,
    isPreliminary: isPreliminary ?? false,
    reasonOrInfo,
    secondaryInfo,
    month,
    numMonths,
    additionalData,
    isEligible: isEligible
      ? true
      : isEligible === false
      ? false
      : hasSeenBlankNecessary
      ? null
      : true,
    statuses,
  }
}

export const getFormStatuses = ({
  determiners,
  values,
  fieldsFreshness,
  staleThresholdDate,
  formName,
  t,
  schema,
  errors,
}: GetFormStatusesOptions): {
  statuses: FormFieldStatuses
  determinedEligibilities: DeterminedEligibilitiesForBenefit[]
} => {
  const allFieldNamesWithStatus: {
    [fieldName: string]: true
  } = {}
  const canonicalValues = getCanonicalValues(values, schema)
  const allDeterminations = determiners.map(
    runSingleDeterminer({
      canonicalValues,
      fieldsFreshness,
      staleThresholdDate,
      formName,
      t,
      errors,
      allFieldNamesWithStatus,
    })
  )
  const statuses: FormFieldStatuses = reduce(
    (statuses, fieldName: string) => ({
      ...statuses,
      [fieldName]: getMergedFieldStatus({
        fieldName,
        allStatuses: allDeterminations.map(({statuses}) => statuses),
      }),
    }),
    {}
  )(Object.keys(allFieldNamesWithStatus))
  return {
    statuses,
    determinedEligibilities: allDeterminations.map(
      ({statuses, ...determination}) => determination
    ),
  }
}
