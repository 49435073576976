import React, {FC} from 'react'
import {flowMax, addDisplayName, addHandlers, addProps} from 'ad-hok'

import {addUpdateAccountStatusMutation} from 'graphql/generated'
import StatusUpdateSelect, {
  StatusUpdateMutateUpdates,
} from 'components/StatusUpdateSelect'
import {ACCOUNT_STATUSES} from 'utils/accountStatuses'
import {addTranslationHelpers} from 'utils/i18n'
import {AccountFields} from 'graphql/deserializedTypes/AccountFields'

interface Props {
  account: AccountFields
  className?: string
}

const AccountStatusUpdateSelect: FC<Props> = flowMax(
  addDisplayName('AccountStatusUpdateSelect'),
  addUpdateAccountStatusMutation({}),
  addHandlers({
    mutate: ({mutateUpdateAccountStatus, account: {id}}) => (
      updates: StatusUpdateMutateUpdates
    ): Promise<any> =>
      mutateUpdateAccountStatus({
        variables: {
          account: {
            id,
            ...updates,
          },
        },
      }),
  }),
  addProps(
    () => ({
      statusOptions: ACCOUNT_STATUSES.map((value) => ({
        value,
        label: value.toUpperCase(),
      })),
    }),
    []
  ),
  addTranslationHelpers,
  ({account: {id, status}, statusOptions, mutate, className, t}) => (
    <StatusUpdateSelect
      id={`account-${id}-status-select`}
      updateFailedMessage={t('accountItem.statusUpdateFailed')}
      status={status}
      statusOptions={statusOptions}
      mutate={mutate}
      className={className}
    />
  )
)

export default AccountStatusUpdateSelect
