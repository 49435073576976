import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import cx from 'classnames'

import {Person_person_mostRecentEligibilityDeterminations} from 'graphql/deserializedTypes/Person'
import {addClasses, makeClasses} from 'theme'
import {addTranslationHelpers} from 'utils/i18n'

const classes = makeClasses((theme) => ({
  message: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  eligible: {
    color: 'red',
  },
  ineligible: {
    color: 'black',
  },
  undecided: {
    color: '#aaa',
  },
}))

interface Props {
  labelKey: string
  benefits: string[]
  mostRecentEligibilityDeterminations: Person_person_mostRecentEligibilityDeterminations[]
  className?: string
}

const EligibilityDeterminationStatus: FC<Props> = flowMax(
  addDisplayName('EligibilityDeterminationStatus'),
  addClasses(classes),
  addTranslationHelpers,
  addProps(({benefits, mostRecentEligibilityDeterminations}) => {
    const determinations = mostRecentEligibilityDeterminations.filter(
      ({benefit}) => benefits.includes(benefit)
    )
    if (determinations.length === 0) {
      return {status: 'undecided'} as const
    }

    const eligibleDeterminations = determinations.filter(
      ({status}) => status !== 'Ineligible'
    )

    return {
      status: eligibleDeterminations.length === 0 ? 'ineligible' : 'eligible',
    } as const
  }),
  ({status, labelKey, className, classes, t}) => (
    <div className={className}>
      <span className={cx(classes.message, classes[status])}>
        {t(`eligibilityDeterminationStatus.${labelKey}.${status}`)}
      </span>
    </div>
  )
)

export default EligibilityDeterminationStatus
