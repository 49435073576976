import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  branch,
  returns,
  addProps,
  addWrapper,
  CurriedUnchangedProps,
} from 'ad-hok'
import {Theme} from '@material-ui/core'
import {TFunction} from 'i18next'
import {addInterval} from 'ad-hok-utils'

import {addClasses, makeClasses} from 'theme'
import Body1 from './Body1'
import {addTranslationHelpers} from 'utils/i18n'
import {addLoadingIndicator} from 'utils/dataLoading'
import {
  addTriagesQuery,
  addWebformsQuery,
  addUsersQuery,
} from 'graphql/generated'
import TriageItem from 'components/TriageItem'
import {TRIAGE_STATUSES, TRIAGE_STATUS_CLOSED} from 'utils/triageStatuses'
import addFilterControls, {makeFilters} from 'utils/addFilterControls'
import {
  INSURANCE_TYPES,
  getStringValuesFromEnumType,
} from 'utils/form/fieldTypes'
import {NjmmisCheckResult} from 'graphql/deserializedTypes/globalTypes'
import {CI_STATUS_OPTIONS} from 'components/EditPersonForm/schema'
import {WebformFields} from 'graphql/deserializedTypes/WebformFields'
import {addFacilities, addDepartments} from 'utils/configContext'
import {FacilityFields} from 'graphql/deserializedTypes/FacilityFields'
import {DepartmentFields} from 'graphql/deserializedTypes/DepartmentFields'
import {UserFields} from 'graphql/deserializedTypes/UserFields'
import {usersToSelectOptions} from 'components/TaskDialog'

const classes = makeClasses((theme: Theme) => ({
  noResults: {
    marginTop: theme.spacing(2),
  },
  filterField: {
    maxWidth: 300,
    marginBottom: theme.spacing(2),
  },
  limitedResultsMessage: {
    position: 'relative',
    top: -4,
  },
}))

export const WORKLIST_POLL_INTERVAL = 30 * 1000
const WORKLIST_MAX_NUM_RESULTS = 100

const ResultsLimitedMessage: FC = flowMax(
  addDisplayName('ResultsLimitedMessage'),
  addTranslationHelpers,
  addClasses(classes),
  ({t, classes}) => (
    <Body1 className={classes.limitedResultsMessage}>
      {t('worklist.limitedResults', {max: WORKLIST_MAX_NUM_RESULTS})}
    </Body1>
  )
)

type AddResultsLimitedMessage = <
  TDataItem,
  TDataPropName extends string,
  TProps extends {
    [dataPropName in TDataPropName]: TDataItem[]
  }
>(
  dataPropName: TDataPropName
) => CurriedUnchangedProps<TProps>

export const addResultsLimitedMessage: AddResultsLimitedMessage = (
  dataPropName
) =>
  addWrapper((render, props) => {
    const data = props[dataPropName]
    return (
      <>
        {data.length >= WORKLIST_MAX_NUM_RESULTS && <ResultsLimitedMessage />}
        {render()}
      </>
    )
  })

const getTriageFilters = ({
  webforms,
  facilities,
  departments,
  users,
  t,
}: {
  webforms: WebformFields[]
  facilities: FacilityFields[]
  departments: DepartmentFields[]
  users: UserFields[]
  t: TFunction
}) =>
  makeFilters({
    statuses: {
      type: 'multiselect',
      label: t('worklist.triageFilters.status'),
      options: TRIAGE_STATUSES.map((status) => ({
        value: status,
        label:
          status === TRIAGE_STATUS_CLOSED
            ? t('worklist.triageFilters.closedStatusLabel')
            : status,
        isClosed: status === TRIAGE_STATUS_CLOSED,
      })),
    },
    facilities: {
      type: 'multiselect',
      label: t('worklist.triageFilters.facilities'),
      options: facilities.map(({name}) => ({
        value: name,
        label: name,
      })),
    },
    departments: {
      type: 'multiselect',
      label: t('worklist.triageFilters.departments'),
      options: departments.map(({name}) => ({
        value: name,
        label: name,
      })),
    },
    insuranceTypes: {
      type: 'multiselect',
      label: t('worklist.triageFilters.insuranceTypes'),
      options: INSURANCE_TYPES.map((insuranceType) => ({
        value: insuranceType,
        label: insuranceType,
      })),
    },
    njmmisCheck: {
      type: 'multiselect',
      label: t('worklist.triageFilters.njmmisCheck'),
      options: [
        {
          value: '',
          label: t('worklist.triageFilters.empty'),
        },
        ...getStringValuesFromEnumType(NjmmisCheckResult, {
          shouldConvertCase: true,
        }).map((value) => ({
          value,
          label: value,
        })),
      ],
    },
    dateOfService: {
      type: 'multiselect',
      label: t('worklist.triageFilters.dateOfService'),
      options: [
        'Today',
        'Within 5 days',
        'Current month',
        'Next 3 days',
        'In 4+ days',
      ].map((value) => ({
        value,
        label: value,
      })),
    },
    hasDischargeDate: {
      type: 'multiselect',
      label: t('worklist.triageFilters.hasDischargeDate'),
      options: ['Yes', 'No'].map((value) => ({
        value,
        label: value,
      })),
    },
    isScheduled: {
      type: 'multiselect',
      label: t('worklist.triageFilters.isScheduled'),
      options: ['Yes', 'No'].map((value) => ({
        value,
        label: value,
      })),
    },
    hasSsn: {
      type: 'multiselect',
      label: t('worklist.triageFilters.hasSsn'),
      options: ['Yes', 'No'].map((value) => ({
        value,
        label: value,
      })),
    },
    ciStatus: {
      type: 'multiselect',
      label: t('worklist.triageFilters.ciStatus'),
      options: CI_STATUS_OPTIONS.map((value) => ({
        value,
        label: value,
      })),
    },
    unreviewedWebformIds: {
      type: 'multiselect',
      label: t('worklist.triageFilters.unreviewedWebformIds'),
      options: webforms.map(({id, name}) => ({
        value: id,
        label: name,
      })),
    },
    assignedToIds: {
      type: 'multiselect',
      label: t('worklist.taskFilters.assignedToIds'),
      options: [
        {
          label: t('form.none'),
          value: 'None',
        },
        ...usersToSelectOptions(users),
      ],
    },
  })

const TriageWorklist: FC = flowMax(
  addDisplayName('TriageWorklist'),
  addTranslationHelpers,
  addClasses(classes),
  addWebformsQuery({}),
  addUsersQuery({
    variables: () => ({
      includeBlockedUsersWithAssignedTriages: true,
    }),
  }),
  addLoadingIndicator({}),
  addFacilities,
  addDepartments,
  addProps(
    ({webforms, facilities, departments, users, t}) => ({
      filters: getTriageFilters({webforms, facilities, departments, users, t}),
      initialFilterValues: {
        statuses: [],
        facilities: [],
        departments: [],
        insuranceTypes: [],
        njmmisCheck: [],
        dateOfService: [],
        hasDischargeDate: [],
        isScheduled: [],
        hasSsn: [],
        ciStatus: [],
        unreviewedWebformIds: [],
        assignedToIds: [],
      },
    }),
    ['webforms', 'facilities', 'departments', 'users', 't']
  ),
  addFilterControls<ReturnType<typeof getTriageFilters>>({
    filterFieldClassName: ({classes}) => classes.filterField,
    persistenceKey: 'triage',
    showClearButton: true,
  }),
  addTriagesQuery({
    variables: ({filterValues}) => filterValues,
  }),
  addInterval(
    ({filterValues, refetchTriages}) => () => {
      refetchTriages(filterValues)
    },
    WORKLIST_POLL_INTERVAL,
    ['filterValues']
  ),
  addLoadingIndicator({}),
  branch(
    ({triages}) => !triages.length,
    returns(({t, classes}) => (
      <Body1 className={classes.noResults}>{t('worklist.noResults')}</Body1>
    ))
  ),
  addResultsLimitedMessage('triages'),
  ({triages}) => (
    <>
      {triages.map((triage) => (
        <TriageItem triage={triage} key={triage.id} />
      ))}
    </>
  )
)

export default TriageWorklist
