import {
  makeDeterminerRule,
  START_TOKEN,
  makeDeterminer,
} from 'utils/form/determiners'
import {APPLICATION_LOCATION_COUNTY} from 'components/EditMonitorApplicationForm/schema'

const primaryPointOfContactId = makeDeterminerRule({
  name: 'application.primaryPointOfContactId',
  necessaryIf: [START_TOKEN],
})

const submitDate = makeDeterminerRule({
  name: 'application.submitDate',
  necessaryIf: [START_TOKEN],
})

const applicationType = makeDeterminerRule({
  name: 'application.applicationType',
  necessaryIf: [START_TOKEN],
})

const submitMethod = makeDeterminerRule({
  name: 'application.submitMethod',
  necessaryIf: [START_TOKEN],
})

const location = makeDeterminerRule({
  name: 'application.location',
  necessaryIf: [START_TOKEN],
})

const countyName = makeDeterminerRule({
  name: 'application.countyName',
  necessaryIf: {'application.location': APPLICATION_LOCATION_COUNTY},
})

export const determinerMonitor = makeDeterminer({
  name: 'monitor',
  isPreliminary: false,
  rules: [
    primaryPointOfContactId,
    submitDate,
    applicationType,
    submitMethod,
    location,
    countyName,
  ],
})
