import {getContextHelpers, toObjectKeys} from 'ad-hok-utils'

import {OutcomePerson} from 'components/BenefitOutcomeFormDialog'

interface ApplicationBenefitOutcomesContextValue {
  outcomesPeople: OutcomePerson[]
  submitDate?: Date | null
  initialDateOfService?: Date | null
}

const {
  addProvider: addApplicationBenefitOutcomesContextProvider,
  addConsumer: addApplicationBenefitOutcomesContext,
} = getContextHelpers<ApplicationBenefitOutcomesContextValue>(
  toObjectKeys(['outcomesPeople', 'submitDate', 'initialDateOfService'])
)

export {
  addApplicationBenefitOutcomesContextProvider,
  addApplicationBenefitOutcomesContext,
}
