import {CurriedPropsAdder} from 'ad-hok'

type TypePropType = <
  TProps extends {},
  TPropName extends keyof TProps,
  TType extends TProps[TPropName]
>(
  propName: TPropName,
  dummy: TType
) => CurriedPropsAdder<TProps, {[propName in TPropName]: TType}>

const typeProp: TypePropType = (propName, dummy) => (props) => ({
  ...props,
  [propName]: props[propName] as typeof dummy,
})

export default typeProp
