/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {AccountInput, NjmmisCheckResult} from './globalTypes' // ====================================================
// GraphQL mutation operation: CreateAccountAndPersonFromWebform
// ====================================================

import {deserializeAccountInput} from './globalTypes'
import {serializeAccountInput} from './globalTypes'
import {CreateAccountAndPersonFromWebformVariables as CreateAccountAndPersonFromWebformVariablesOrig} from '../types/CreateAccountAndPersonFromWebform'
import {CreateAccountAndPersonFromWebform as CreateAccountAndPersonFromWebformOrig} from '../types/CreateAccountAndPersonFromWebform'
import {deserializeNjmmisCheckResult} from 'graphql/converters'
import {serializeNjmmisCheckResult} from 'graphql/converters'
import {CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform as CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebformOrig} from '../types/CreateAccountAndPersonFromWebform'
import {formatISODate} from 'utils/date'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person as CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_personOrig} from '../types/CreateAccountAndPersonFromWebform'
import {CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_assets as CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_assetsOrig} from '../types/CreateAccountAndPersonFromWebform'
import {CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_deductions as CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_deductionsOrig} from '../types/CreateAccountAndPersonFromWebform'
import {CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_incomeSources as CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_incomeSourcesOrig} from '../types/CreateAccountAndPersonFromWebform'
import {CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_phoneNumbers as CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_phoneNumbersOrig} from '../types/CreateAccountAndPersonFromWebform'
export interface CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_phoneNumbers = (
  serialized: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_phoneNumbersOrig
): CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_phoneNumbers => ({
  ...serialized,
})
export const serializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_phoneNumbers = (
  deserialized: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_phoneNumbers
): CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_phoneNumbersOrig => ({
  ...deserialized,
})
export interface CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
}
export const deserializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_incomeSources = (
  serialized: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_incomeSourcesOrig
): CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_incomeSources => ({
  ...serialized,
})
export const serializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_incomeSources = (
  deserialized: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_incomeSources
): CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_incomeSourcesOrig => ({
  ...deserialized,
})
export interface CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_deductions = (
  serialized: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_deductionsOrig
): CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_deductions => ({
  ...serialized,
})
export const serializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_deductions = (
  deserialized: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_deductions
): CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_deductionsOrig => ({
  ...deserialized,
})
export interface CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_assets = (
  serialized: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_assetsOrig
): CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_assets => ({
  ...serialized,
})
export const serializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_assets = (
  deserialized: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_assets
): CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_assetsOrig => ({
  ...deserialized,
})
export interface CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person {
  __typename: 'Person'
  id: string
  updatedAt: Date
  firstName: string | null
  middleName: string | null
  lastName: string | null
  suffix: string | null
  preferredName: string | null
  dob: Date | null
  gender: string | null
  ssn: string | null
  phoneNumbers: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_phoneNumbers[]
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  email: string | null
  emailComment: string | null
  preferredLanguage: string | null
  hospitalPatientId: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  incomeSources: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_incomeSources[]
  deductions: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_deductions[]
  assets: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  unreadMessageStatus: boolean | null
}
export const deserializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person = (
  serialized: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_personOrig
): CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_phoneNumbers
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_deductions
  ),
  assets: serialized.assets.map(
    deserializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
})
export const serializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person = (
  deserialized: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person
): CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_personOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_phoneNumbers
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_deductions
  ),
  assets: deserialized.assets.map(
    serializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
})
export interface CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
  timeOfService: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location: string | null
  insuranceType: string
  status: string
  createdAt: Date
  updatedAt: Date
  notes: string | null
  accountAmount: number | null
  njmmisCheck: string | null
  previousInsurance: string | null
  invoiceMonth: string | null
  invoiceYear: string | null
  dischargeDate: Date | null
  isScheduled: boolean
  person: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person
}
export const deserializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform = (
  serialized: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebformOrig
): CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
  person: deserializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person(
    serialized.person
  ),
})
export const serializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform = (
  deserialized: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform
): CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebformOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
  person: serializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform_person(
    deserialized.person
  ),
})
export interface CreateAccountAndPersonFromWebform {
  /**
   * Create a new account and associated person from submitted webform responses
   */
  createAccountAndPersonFromWebform: CreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform
}
export const deserializeCreateAccountAndPersonFromWebform = (
  serialized: CreateAccountAndPersonFromWebformOrig
): CreateAccountAndPersonFromWebform => ({
  ...serialized,
  createAccountAndPersonFromWebform: deserializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform(
    serialized.createAccountAndPersonFromWebform
  ),
})
export const serializeCreateAccountAndPersonFromWebform = (
  deserialized: CreateAccountAndPersonFromWebform
): CreateAccountAndPersonFromWebformOrig => ({
  ...deserialized,
  createAccountAndPersonFromWebform: serializeCreateAccountAndPersonFromWebform_createAccountAndPersonFromWebform(
    deserialized.createAccountAndPersonFromWebform
  ),
})
export interface CreateAccountAndPersonFromWebformVariables {
  receivedWebformId: string
  account: AccountInput
}
export const deserializeCreateAccountAndPersonFromWebformVariables = (
  serialized: CreateAccountAndPersonFromWebformVariablesOrig
): CreateAccountAndPersonFromWebformVariables => ({
  ...serialized,
  account: deserializeAccountInput(serialized.account),
})
export const serializeCreateAccountAndPersonFromWebformVariables = (
  deserialized: CreateAccountAndPersonFromWebformVariables
): CreateAccountAndPersonFromWebformVariablesOrig => ({
  ...deserialized,
  account: serializeAccountInput(deserialized.account),
})
