import {SimplePropsAdder, addProps, flowMax} from 'ad-hok'
import {branchIfPassesPredicate} from 'ad-hok-utils'

import {addMeQuery} from 'graphql/generated'
import {isDataLoading} from 'utils/dataLoading'

type AddUserRoles = SimplePropsAdder<{
  userIsApprover: boolean
}>

const addUserRoles: AddUserRoles = flowMax(
  addMeQuery({}),
  branchIfPassesPredicate('me', isDataLoading),
  addProps(({me: {data: {roles}}}) => ({
    userIsApprover: roles.includes('approver'),
  }))
)

export default addUserRoles
