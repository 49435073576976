import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps, addHandlers, addState} from 'ad-hok'
import {TFunction} from 'i18next'

import addFilterControls, {makeFilters} from 'utils/addFilterControls'
import {ACCOUNT_STATUSES, DEFAULT_ACCOUNT_STATUS} from 'utils/accountStatuses'
import {
  INSURANCE_TYPES,
  getMonthSelectFieldOptions,
  getYearSelectFieldOptions,
} from 'utils/form/fieldTypes'
import {addTranslationHelpers} from 'utils/i18n'
import {makeClasses, addClasses} from 'theme'
import {
  BENEFIT_OUTCOME_BENEFIT_OPTIONS,
  BENEFIT_OUTCOME_OUTCOMES,
} from 'components/BenefitOutcomeFormDialog/index'
import {APPLICATION_STATUSES} from 'utils/applicationStatuses'
import AccountSearch from 'components/AccountSearch'
import addApolloClient from 'utils/addApolloClient'
import {ACCOUNTS_FOR_CSV_QUERY} from 'graphql/queries'
import Button from 'components/Button'
import {saveAccountsCsv} from 'components/AccountWorklist/csv'
import {StandaloneTextField} from 'components/TextField'
import Grid from 'components/Grid'
import {
  AccountsForCsv as AccountsForCsvOrig,
  AccountsForCsvVariables as AccountsForCsvVariablesOrig,
} from 'graphql/types/AccountsForCsv'
import {
  deserializeAccountsForCsv,
  serializeAccountsForCsvVariables,
} from 'graphql/deserializedTypes/AccountsForCsv'
import {FacilityFields} from 'graphql/deserializedTypes/FacilityFields'
import {addFacilities} from 'utils/configContext'
import {makeOptionsFromNamedEnums} from 'utils/enumOptions'

const classes = makeClasses((theme) => ({
  filterField: {
    maxWidth: 240,
    marginBottom: theme.spacing(2),
  },
  passwordInput: {
    marginRight: theme.spacing(2),
    width: 220,
  },
}))

const getAccountFilters = (facilities: FacilityFields[], t: TFunction) =>
  makeFilters({
    statuses: {
      type: 'multiselect',
      label: t('worklist.accountFilters.statuses'),
      options: ACCOUNT_STATUSES.map((status) => ({
        value: status,
        label: status,
      })),
    },
    insuranceTypes: {
      type: 'multiselect',
      label: t('worklist.accountFilters.insuranceTypes'),
      options: INSURANCE_TYPES.map((insuranceType) => ({
        value: insuranceType,
        label: insuranceType,
      })),
    },
    dateOfServiceAfter: {
      type: 'date',
      label: t('worklist.accountFilters.dateOfServiceAfter'),
    },
    dateOfServiceBefore: {
      type: 'date',
      label: t('worklist.accountFilters.dateOfServiceBefore'),
    },
    facility: {
      type: 'multiselect',
      label: t('worklist.accountFilters.facility'),
      options: makeOptionsFromNamedEnums(facilities),
    },
    coveredByBenefitType: {
      type: 'multiselect',
      label: t('worklist.accountFilters.coveredByBenefitType'),
      options: BENEFIT_OUTCOME_BENEFIT_OPTIONS,
    },
    coveredByBenefitOutcome: {
      type: 'multiselect',
      label: t('worklist.accountFilters.coveredByBenefitOutcome'),
      options: BENEFIT_OUTCOME_OUTCOMES.map((value) => ({
        label: value,
        value,
      })),
    },
    coveredByOpenApplicationStatus: {
      type: 'multiselect',
      label: t('worklist.accountFilters.coveredByOpenApplicationStatus'),
      options: APPLICATION_STATUSES.map(({status}) => ({
        label: status,
        value: status,
      })),
    },
    hasExternalVendorCode: {
      type: 'select',
      label: t('worklist.accountFilters.hasExternalVendorCode'),
      options: [
        {
          value: '',
          label: '',
        },
        {
          value: 'true',
          label: t('worklist.accountFilters.yes'),
        },
        {
          value: 'false',
          label: t('worklist.accountFilters.no'),
        },
      ],
    },
    invoiceMonth: {
      type: 'multiselect',
      label: t('worklist.accountFilters.invoiceMonth'),
      options: getMonthSelectFieldOptions(),
    },
    invoiceYear: {
      type: 'multiselect',
      label: t('worklist.accountFilters.invoiceYear'),
      options: getYearSelectFieldOptions(),
    },
  })

const AccountWorklist: FC = flowMax(addDisplayName('AccountWorklist'), () => (
  <AccountSearch />
))

export const AccountCsvExportUNUSED = flowMax(
  addDisplayName('AccountCsvExportUNUSED'),
  addTranslationHelpers,
  addFacilities,
  addProps(
    ({facilities, t}) => ({
      filters: getAccountFilters(facilities, t),
      initialFilterValues: {
        statuses: [DEFAULT_ACCOUNT_STATUS],
        insuranceTypes: [],
        dateOfServiceAfter: '',
        dateOfServiceBefore: '',
        facility: [],
        hasExternalVendorCode: '',
        coveredByBenefitType: [],
        coveredByBenefitOutcome: [],
        coveredByOpenApplicationStatus: [],
        invoiceMonth: [],
        invoiceYear: [],
      },
    }),
    ['facilities', 't']
  ),
  addClasses(classes),
  addFilterControls<ReturnType<typeof getAccountFilters>>({
    noFilterSelectionTranslationKey:
      'worklist.accountFilters.noFilterSelection',
    filterFieldClassName: ({classes}) => classes.filterField,
    noOpenClosedFilter: true,
    persistenceKey: 'accounts',
  }),
  addProps(
    ({filterValues: {hasExternalVendorCode}, filterValues}) => ({
      variables: {
        ...filterValues,
        hasExternalVendorCode:
          hasExternalVendorCode === 'true'
            ? true
            : hasExternalVendorCode === 'false'
            ? false
            : null,
      },
    }),
    ['filterValues']
  ),
  addApolloClient,
  addTranslationHelpers,
  addState('password', 'setPassword', ''),
  addHandlers({
    onExportClick: ({apolloClient, variables, password, t}) => async () => {
      try {
        const {data} = await apolloClient.query<
          AccountsForCsvOrig,
          AccountsForCsvVariablesOrig
        >({
          query: ACCOUNTS_FOR_CSV_QUERY,
          variables: serializeAccountsForCsvVariables({
            ...variables,
            password,
          }),
          fetchPolicy: 'network-only',
        })
        const {accounts} = deserializeAccountsForCsv(data)
        saveAccountsCsv({accounts, t})
      } catch (e) {
        if (e.message.match(/invalid.*password/))
          return window.alert(t('worklist.reports.invalidPassword'))
        throw e
      }
    },
  }),
  ({onExportClick, password, setPassword, classes, t}) => (
    <Grid container direction="row" alignItems="center">
      <StandaloneTextField
        type="password"
        label={t('worklist.reports.passwordInput')}
        placeholder={t('worklist.reports.passwordInputPlaceholder')}
        value={password}
        onChange={({target: {value}}) => setPassword(value)}
        className={classes.passwordInput}
      />
      <Button
        color="primary"
        variant="contained"
        disabled={!password}
        onClick={onExportClick}
      >
        {t('worklist.accountFilters.exportToCsv')}
      </Button>
    </Grid>
  )
)

export default AccountWorklist
