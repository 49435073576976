import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import {removeProps} from 'ad-hok-utils'

import {addUsersQuery} from 'graphql/generated'
import {getAssignedToOptions, Assignable} from 'components/TaskDialog'
import {FieldComponentProps} from 'components/TextField'
import SelectField from 'components/SelectField'
import {addLoadingIndicator} from 'utils/dataLoading'

interface Props extends FieldComponentProps {
  assignable?: Assignable
}

const AssignedToField: FC<Props> = flowMax(
  addDisplayName('AssignedToField'),
  addUsersQuery({}),
  addLoadingIndicator({}),
  addProps(
    ({users, assignable}) => ({
      options: getAssignedToOptions(users, assignable),
    }),
    ['users', 'assignable']
  ),
  removeProps(['users', 'assignable', 'refetchUsers']),
  ({options, ...props}) => <SelectField {...props} options={options} />
)

export default AssignedToField
