import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  branch,
  renderNothing,
  addProps,
  addWrapper,
} from 'ad-hok'
import {generatePath} from 'react-router'
import {branchIfFailsPredicate, branchIfFalsy} from 'ad-hok-utils'
import {format} from 'date-fns/fp'

import {Application_application} from 'graphql/deserializedTypes/Application'
import Body1 from 'components/Body1'
import Link from 'components/Link'
import {editAccountPath} from 'components/TopLevelRoutes'
import {addTranslationHelpers} from 'utils/i18n'
import Trans from 'components/Trans'
import {makeClasses, addClasses} from 'theme'
import DisplayItem from 'components/DisplayItem'
import {addShowAccounts} from 'utils/configContext'

const classes = makeClasses({
  notFound: {
    color: '#ff0000',
  },
  scheduled: {
    fontWeight: 'bold',
  },
  container: {
    position: 'relative',
    top: -20,
  },
})

const NoAccountFound: FC = flowMax(
  addDisplayName('NoAccountFound'),
  addClasses(classes),
  ({classes}) => (
    <Trans i18nKey="accountOnDateOfServiceDetails.noAccountFound">
      <span className={classes.notFound} />
    </Trans>
  )
)

const getHasAccountOnDateOfService = (
  application: Application_application
): application is Application_application & {
  accountOnDateOfService: {
    id: string
  }
} => !!application.accountOnDateOfService

interface Props {
  application: Application_application
}

const AccountOnDateOfServiceDetails: FC<Props> = flowMax(
  addDisplayName('AccountOnDateOfServiceDetails'),
  addShowAccounts,
  branchIfFalsy('showAccounts'),
  branch(
    ({application: {initialDateOfService}}) => !initialDateOfService,
    renderNothing()
  ),
  addClasses(classes),
  addWrapper((render, {classes}) => (
    <div className={classes.container}>{render()}</div>
  )),
  branchIfFailsPredicate('application', getHasAccountOnDateOfService, {
    returns: () => <NoAccountFound />,
  }),
  addProps(({application: {accountOnDateOfService: {dischargeDate}}}) => ({
    dischargeDateFormatted: dischargeDate
      ? format('M/d/yy')(dischargeDate)
      : '',
  })),
  addTranslationHelpers,
  ({
    application: {
      accountOnDateOfService: {
        id,
        isScheduled,
        hospitalAccountId,
        insuranceType,
        department,
      },
    },
    dischargeDateFormatted,
    classes,
    t,
  }) => (
    <>
      <Body1>
        {t('accountOnDateOfServiceDetails.account')}:{' '}
        <Link to={generatePath(editAccountPath, {id})} highlight>
          {hospitalAccountId}
        </Link>{' '}
        {isScheduled && (
          <span className={classes.scheduled}>
            {t('accountOnDateOfServiceDetails.scheduled')}
          </span>
        )}
      </Body1>
      <DisplayItem
        i18nKey="accountOnDateOfServiceDetails.insuranceType"
        translations={{
          insuranceType,
        }}
      />
      <DisplayItem
        i18nKey="accountOnDateOfServiceDetails.department"
        translations={{
          department,
        }}
      />
      {!!dischargeDateFormatted && (
        <DisplayItem
          i18nKey="accountOnDateOfServiceDetails.dischargeDate"
          translations={{
            dischargeDate: dischargeDateFormatted,
          }}
        />
      )}
    </>
  )
)

export default AccountOnDateOfServiceDetails
