import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addHandlers,
  addWrapper,
} from 'ad-hok'
import {PureQueryOptions} from 'apollo-boost'
import {first} from 'lodash/fp'
import {getContextHelpers, toObjectKeys} from 'ad-hok-utils'

import {makeClasses, addClasses} from 'theme'
import {
  addCreateTaskMutation,
  addUsersQuery,
  addUpdateTaskMutation,
} from 'graphql/generated'
import {getCanonicalValues, getInitialValues} from 'utils/form/getValues'
import {addTranslationHelpers} from 'utils/i18n'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogContent from 'components/DialogContent'
import Form from 'components/Form'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import SelectField from 'components/SelectField'
import DateField from 'components/DateField'
import TextField from 'components/TextField'
import MultilineTextField from 'components/MultilineTextField'
import {makeFormSchema} from 'utils/form/schema'
import {
  makeTextField,
  makeSelectField,
  makeDateField,
  makeNumberField,
  SelectFieldOption,
} from 'utils/form/fieldTypes'
import {addLoadingIndicator} from 'utils/dataLoading'
import DisplayItem from 'components/DisplayItem'
import {getExtendedName} from 'utils/name'
import {TASK_STATUSES} from 'components/TaskStatusUpdateSelect'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import addFormikHoistedState, {
  addFormikHoistedStateCallbacks,
} from 'utils/addFormikHoistedState'
import {TaskFields} from 'graphql/deserializedTypes/TaskFields'
import {Assert, SchemaDoesntHaveExtraFields} from 'utils/form/typeHelpers'
import {Users_users} from 'graphql/deserializedTypes/Users'
import {UserFields} from 'graphql/deserializedTypes/UserFields'

const classes = makeClasses((theme) => ({
  contentContainer: {
    width: 600,
  },
  personName: {
    marginBottom: theme.spacing(3),
  },
}))

export const TASK_TYPES = [
  'Clean up',
  'Client Outreach',
  'Application follow up',
  'General',
]

const sharedFields = {
  status: makeSelectField({
    isRequired: true,
    options: TASK_STATUSES.map((value) => ({value})),
  }),
  taskType: makeSelectField({
    isRequired: true,
    options: TASK_TYPES.map((value) => ({value})),
  }),
  dueDate: makeDateField({isRequired: true}),
  assignedToId: makeSelectField({isRequired: true}),
  durationMin: makeNumberField(),
  note: makeTextField(),
}

const createTaskFormSchema = makeFormSchema({
  fields: {
    task: {
      personId: makeTextField({isRequired: true}),
      ...sharedFields,
    },
  },
})

type CheckCreate = Assert<
  SchemaDoesntHaveExtraFields<
    typeof createTaskFormSchema,
    typeof addCreateTaskMutation
  >
>

const updateTaskFormSchema = makeFormSchema({
  fields: {
    task: {
      id: makeTextField({isRequired: true}),
      ...sharedFields,
    },
  },
})

type CheckUpdate = Assert<
  SchemaDoesntHaveExtraFields<
    typeof updateTaskFormSchema,
    typeof addUpdateTaskMutation
  >
>

// type UpdateDiff = SchemaMutationDiff<
//   typeof updateTaskFormSchema,
//   typeof addUpdateTaskMutation,
//   'task'
// >

interface PersonForTaskDialog {
  id: string
  firstName: string | null
  middleName: string | null
  lastName: string | null
  preferredName: string | null
  suffix: string | null
}

const [addTaskDialogContextProvider, addTaskDialogContext] = getContextHelpers<{
  person: PersonForTaskDialog
}>(toObjectKeys(['person']))

export {addTaskDialogContextProvider}

export interface Assignable {
  assignedTo: UserFields | null
}

export const usersToSelectOptions = (
  users: UserFields[]
): SelectFieldOption[] =>
  users
    .filter(({name}) => !!name)
    .map(({id, name}) => ({
      value: id,
      label: name!,
    }))

export const getAssignedToOptions = (
  users: Users_users[],
  object: Assignable | undefined,
  opts?: {
    includeBlank?: boolean
  }
): SelectFieldOption[] => {
  const unblockedUsers = usersToSelectOptions(users)
  const withoutCurrentlyAssignedUser = opts?.includeBlank
    ? [
        {
          value: '',
          label: '',
        },
        ...unblockedUsers,
      ]
    : unblockedUsers
  if (!object) return withoutCurrentlyAssignedUser
  const currentlyAssignedUser = object.assignedTo
  if (!currentlyAssignedUser) return withoutCurrentlyAssignedUser
  if (
    withoutCurrentlyAssignedUser.some(
      ({value}) => value === currentlyAssignedUser.id
    )
  )
    return withoutCurrentlyAssignedUser
  return [
    ...withoutCurrentlyAssignedUser,
    {
      value: currentlyAssignedUser.id,
      label: currentlyAssignedUser.name!,
    },
  ]
}

interface Props {
  open: boolean
  onClose: () => void
  refetchQueries?: PureQueryOptions[]
  task?: TaskFields
}

const TaskDialog: FC<Props> = flowMax(
  addDisplayName('TaskDialog'),
  addUsersQuery({}),
  addLoadingIndicator({}),
  addFormikHoistedState,
  addCreateTaskMutation({
    refetchQueries: ({refetchQueries}) => refetchQueries ?? [],
  }),
  addUpdateTaskMutation({
    refetchQueries: ({refetchQueries}) => refetchQueries ?? [],
  }),
  addTaskDialogContext,
  addProps(
    ({person: {id: personId}, task}) => ({
      initialValuesUpdate: task
        ? {
            task: {
              ...task,
              assignedToId: task.assignedTo.id,
            },
          }
        : null,
      initialValuesCreate: task
        ? null
        : {
            task: {
              ...getCanonicalValues(
                getInitialValues(createTaskFormSchema),
                createTaskFormSchema
              ).task,
              personId,
              status: first(TASK_STATUSES)!,
            },
          },
    }),
    ['person.id', 'task']
  ),
  addTranslationHelpers,
  addProps(
    ({task, t}) => ({
      dialogTitle: task
        ? t('taskDialog.titleUpdate')
        : t('taskDialog.titleCreate'),
    }),
    ['task', 't']
  ),
  addAppSnackbarContext,
  addHandlers({
    onSubmitSuccess: ({onClose, task, showSnackbarMessage, t}) => () => {
      onClose()
      showSnackbarMessage(
        task ? t('personDetail.taskUpdated') : t('personDetail.taskCreated')
      )
    },
  }),
  addClasses(classes),
  addTranslationHelpers,
  addWrapper(
    (
      render,
      {
        open,
        onClose,
        mutateCreateTask,
        mutateUpdateTask,
        task,
        initialValuesUpdate,
        initialValuesCreate,
        dialogTitle,
        onSubmitSuccess,
        submitFormRef,
        classes,
        isSubmitting,
        t,
      }
    ) => (
      <Dialog open={open} onClose={onClose} scroll="paper">
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent className={classes.contentContainer} dividers>
          {task ? (
            <Form
              name="taskForm"
              schema={updateTaskFormSchema}
              mutate={mutateUpdateTask}
              initialValues={initialValuesUpdate!}
              onSubmitSuccess={onSubmitSuccess}
            >
              {render()}
            </Form>
          ) : (
            <Form
              name="taskForm"
              schema={createTaskFormSchema}
              mutate={mutateCreateTask}
              initialValues={initialValuesCreate!}
              onSubmitSuccess={onSubmitSuccess}
            >
              {render()}
            </Form>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t('taskDialog.cancel')}
          </Button>
          <Button
            onClick={() => submitFormRef.current?.()}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {t('taskDialog.save')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  ),
  addFormikHoistedStateCallbacks,
  addProps(
    ({users, task}) => ({
      assignedToOptions: getAssignedToOptions(users, task),
    }),
    ['users', 'task']
  ),
  ({assignedToOptions, person, classes, t}) => (
    <>
      <DisplayItem
        i18nKey="taskDialog.personName"
        translations={{
          name: getExtendedName({...person, t}),
        }}
        className={classes.personName}
      />
      <SelectField name="task.status" />
      <SelectField name="task.taskType" />
      <DateField name="task.dueDate" />
      <SelectField name="task.assignedToId" options={assignedToOptions} />
      <TextField type="number" name="task.durationMin" />
      <MultilineTextField name="task.note" rows={5} rowsMax={20} />
    </>
  )
)

export default TaskDialog
