import React, {FC} from 'react'
import {flowMax, addDisplayName, addHandlers, addProps} from 'ad-hok'
import {PureQueryOptions} from 'apollo-boost'

import {addTranslationHelpers} from 'utils/i18n'
import {addUpdateTriageMutation} from 'graphql/generated'
import StatusUpdateSelect, {
  StatusUpdateMutateUpdates,
} from 'components/StatusUpdateSelect'
import {TRIAGE_STATUS_CLOSED, TRIAGE_STATUSES} from 'utils/triageStatuses'
import {ValidatorTest} from 'utils/form/fieldTypes'
import typedAs from 'utils/typedAs'

const CLOSE_REASON_INELIGIBLE_FOR_NEW_BENEFITS = 'Ineligible for new benefits'

const CLOSE_REASON_OPTIONS = [
  'Completed - proceed',
  'Insurance discovery',
  CLOSE_REASON_INELIGIBLE_FOR_NEW_BENEFITS,
  'Unable to communicate with client',
  'Client not interested - likely eligible',
  'Client not interested - eligibility unknown',
  'Transfer to 3rd party',
  'No phone or address',
  'Other',
]

const statusOptions = TRIAGE_STATUSES.map((status) => ({
  value: status,
  label: status.toUpperCase(),
  ...(status === TRIAGE_STATUS_CLOSED
    ? {closeReasonOptions: CLOSE_REASON_OPTIONS}
    : {}),
}))

interface Props {
  triage: {
    id: string
    status: string
    eligibilityDeterminations: {
      id: string
    }[]
  }
  refetchQueries?: (status: string) => PureQueryOptions[]
}

const TriageStatusUpdateSelect: FC<Props> = flowMax(
  addDisplayName('TriageStatusUpdateSelect'),
  addTranslationHelpers,
  addUpdateTriageMutation({}),
  addHandlers({
    mutate: ({mutateUpdateTriage, triage: {id}, refetchQueries}) => (
      updates: StatusUpdateMutateUpdates
    ): Promise<any> =>
      mutateUpdateTriage({
        variables: {
          triage: {
            id,
            ...updates,
          },
        },
        refetchQueries: refetchQueries?.(updates.status),
      }),
  }),
  addProps(
    ({triage: {eligibilityDeterminations}}) => ({
      closeReasonValidator: typedAs<ValidatorTest>([
        'triageItem.invalidCloseReasonWithoutEligibilityChecks',
        (value) => {
          if (value !== CLOSE_REASON_INELIGIBLE_FOR_NEW_BENEFITS) return true
          return eligibilityDeterminations.length > 0
        },
      ]),
    }),
    ['triage.eligibilityDeterminations']
  ),
  ({triage: {id, status}, t, mutate, closeReasonValidator}) => (
    <>
      <StatusUpdateSelect
        id={`triage-${id}-status-select`}
        updateFailedMessage={t('triageItem.statusUpdateFailed')}
        status={status}
        statusOptions={statusOptions}
        mutate={mutate}
        closeReasonValidator={closeReasonValidator}
      />
    </>
  )
)

export default TriageStatusUpdateSelect
