import {
  makeDeterminerRule,
  START_TOKEN,
  makeDeterminer,
} from 'utils/form/determiners'

const number = makeDeterminerRule({
  name: 'phoneNumber.number',
  necessaryIf: [START_TOKEN],
})

const language = makeDeterminerRule({
  name: 'phoneNumber.language',
  necessaryIf: [START_TOKEN],
})

const usedInApp = makeDeterminerRule({
  name: 'phoneNumber.usedInApp',
  necessaryIf: [START_TOKEN],
})

const usedForEsign = makeDeterminerRule({
  name: 'phoneNumber.usedForEsign',
  necessaryIf: [START_TOKEN],
})

export const determinerPhoneNumberForm = makeDeterminer({
  name: 'phoneNumber',
  rules: [number, language, usedInApp, usedForEsign],
})
