import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  branch,
  renderNothing,
  addHandlers,
  returns,
  addWrapper,
  addState,
  addEffect,
} from 'ad-hok'
import DeleteIcon from '@material-ui/icons/Delete'
import {format} from 'date-fns/fp'

import {
  addDocumentFilesContext,
  DocumentFileType,
} from 'components/DocumentFiles/context'
import addDialogState from 'utils/addDialogState'
import {
  addCreateDocumentFileMutation,
  addDeleteDocumentFileMutation,
} from 'graphql/generated'
import {addTranslationHelpers} from 'utils/i18n'
import {FormCanonicalValues, ExtractFormSchemaFields} from 'utils/form/schema'
import AddButton from 'components/AddButton'
import Tooltip from 'components/Tooltip'
import IconButton from 'components/IconButton'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import ConfirmationDialog from 'components/ConfirmationDialog'
import {addClasses, makeClasses} from 'theme'
import Body1 from 'components/Body1'
import DocumentFileLink from 'components/DocumentFileLink'
import DocumentFileDialog, {
  documentFileFormSchema,
} from 'components/DocumentFileDialog'
import TableRow from 'components/TableRow'
import TableCell from 'components/TableCell'
import TableContainer from 'components/TableContainer'
import Table from 'components/Table'
import TableHead from 'components/TableHead'
import TableBody from 'components/TableBody'
import {toEasternTime} from 'utils/date'
import DocumentFileDownloadLink from 'components/DocumentFileDownloadLink'
import {Assert, SchemaDoesntHaveExtraFields} from 'utils/form/typeHelpers'
import {FILE_TYPES} from 'utils/fileTypes'

const classes = makeClasses((theme) => ({
  deleteButton: {
    marginLeft: 'auto',
  },
  fileLink: {
    fontSize: 12,
  },
  tableHead: {
    '& tr th.MuiTableCell-head': {
      textTransform: 'uppercase',
    },
  },
  filesCell: {
    whiteSpace: 'nowrap',
  },
  tableContainer: {
    marginBottom: theme.spacing(1),
  },
}))

type Check = Assert<
  SchemaDoesntHaveExtraFields<
    typeof documentFileFormSchema,
    typeof addCreateDocumentFileMutation
  >
>

interface CreateDocumentFileButtonProps {
  className?: string
  onDialogOpened?: () => void
  accept?: string
}

export const CreateDocumentFileButton: FC<CreateDocumentFileButtonProps> = flowMax(
  addDisplayName('CreateDocumentFileButton'),
  addDocumentFilesContext,
  branch(({isFileEditingFrozen}) => isFileEditingFrozen, renderNothing()),
  addDialogState,
  addEffect(
    ({isShowingDialog, onDialogOpened}) => () => {
      if (!isShowingDialog) return
      onDialogOpened?.()
    },
    ['isShowingDialog']
  ),
  addCreateDocumentFileMutation({
    refetchQueries: ({refetchQueries}) => refetchQueries,
    awaitRefetchQueries: true,
  }),
  addTranslationHelpers,
  addState('isSubmitting', 'setIsSubmitting', false),
  addHandlers({
    onSubmit: ({
      mutateCreateDocumentFile,
      hideDialog,
      additionalCreateDocumentFileMutateVariables,
      setIsSubmitting,
      t,
    }) => ({
      canonicalValues,
    }: {
      canonicalValues: FormCanonicalValues<
        ExtractFormSchemaFields<typeof documentFileFormSchema>
      >
    }) => {
      mutateCreateDocumentFile({
        variables: {
          documentFile: {
            ...canonicalValues.documentFile,
            ...additionalCreateDocumentFileMutateVariables,
          },
        },
      })
        .then(() => {
          hideDialog()
          setIsSubmitting(false)
        })
        .catch(() => {
          window.alert(t('applicationForm.documentFiles.failedToSave'))
          setIsSubmitting(false)
        })
      setIsSubmitting(true)
    },
  }),
  ({
    isShowingDialog,
    showDialog,
    hideDialog,
    onSubmit,
    isSubmitting,
    className,
    accept,
    t,
  }) => (
    <>
      <AddButton onClick={showDialog} className={className}>
        {t('applicationForm.documentFiles.add')}
      </AddButton>
      <DocumentFileDialog
        accept={accept}
        open={isShowingDialog}
        onClose={hideDialog}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
      />
    </>
  )
)

interface DeleteDocumentFileButtonProps {
  documentFile: DocumentFileType
  className?: string
}

const DeleteDocumentFileButton: FC<DeleteDocumentFileButtonProps> = flowMax(
  addDisplayName('DeleteDocumentFileButton'),
  addDocumentFilesContext,
  branch(({isFileEditingFrozen}) => isFileEditingFrozen, renderNothing()),
  addTranslationHelpers,
  branch(
    ({documentFile: {documents}}) => !!documents.length,
    returns(({className, t}) => (
      <Tooltip title={t('applicationForm.documentFiles.deleteDisabledTooltip')}>
        <span className={className}>
          <IconButton disabled>
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    ))
  ),
  addDialogState,
  addAppSnackbarContext,
  addDeleteDocumentFileMutation({
    refetchQueries: ({refetchQueries}) => refetchQueries,
  }),
  addHandlers({
    onDelete: ({
      mutateDeleteDocumentFile,
      documentFile: {id},
      hideDialog,
      showSnackbarMessage,
      t,
    }) => () => {
      mutateDeleteDocumentFile({
        variables: {id},
      })
        .then(() => {
          hideDialog()
          showSnackbarMessage(t('applicationForm.documentFiles.deleted'))
        })
        .catch(() => {
          window.alert(t('applicationForm.documentFiles.failedToDelete'))
        })
    },
  }),
  ({
    documentFile: {name},
    showDialog,
    onDelete,
    t,
    hideDialog,
    isShowingDialog,
    className,
  }) => (
    <>
      <IconButton onClick={showDialog} className={className}>
        <DeleteIcon />
      </IconButton>
      <ConfirmationDialog
        open={isShowingDialog}
        onCancel={hideDialog}
        onConfirm={onDelete}
        title={t('deleteDocumentFileDialog.title', {name})}
        cancelText={t('deleteDocumentFileDialog.cancel')}
        confirmText={t('deleteDocumentFileDialog.confirm')}
      />
    </>
  )
)

interface DocumentFileProps {
  documentFile: DocumentFileType
}

const DocumentFile: FC<DocumentFileProps> = flowMax(
  addDisplayName('DocumentFile'),
  addClasses(classes),
  addDocumentFilesContext,
  ({
    documentFile: {name, createdAt},
    documentFile,
    shouldHideCreatedAtColumn,
    classes,
  }) => (
    <TableRow>
      <TableCell>
        <Body1>{name}</Body1>
      </TableCell>
      <TableCell className={classes.filesCell}>
        <DocumentFileLink
          documentFile={documentFile}
          className={classes.fileLink}
        />
        <DocumentFileDownloadLink documentFile={documentFile} />
      </TableCell>
      {!shouldHideCreatedAtColumn && (
        <TableCell>
          {format('M/d/yy HH:mm')(toEasternTime(createdAt))} ET
        </TableCell>
      )}
      <TableCell>
        <DeleteDocumentFileButton
          documentFile={documentFile}
          className={classes.deleteButton}
        />
      </TableCell>
    </TableRow>
  )
)

const DocumentFiles: FC = flowMax(
  addDisplayName('DocumentFiles'),
  addWrapper((render) => (
    <>
      {render()}
      <CreateDocumentFileButton accept={`${FILE_TYPES.PDF}`} />
    </>
  )),
  addDocumentFilesContext,
  addTranslationHelpers,
  addClasses(classes),
  ({documentFiles, shouldHideCreatedAtColumn, t, classes}) => (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>{t('documentFiles.name')}</TableCell>
            <TableCell>{t('documentFiles.file')}</TableCell>
            {!shouldHideCreatedAtColumn && (
              <TableCell>{t('documentFiles.createdAt')}</TableCell>
            )}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documentFiles.map((documentFile) => (
            <DocumentFile documentFile={documentFile} key={documentFile.id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
)

export default DocumentFiles
