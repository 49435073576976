import {TFunction} from 'i18next'

export const getFormattedSsn = ({
  ssn,
  t,
}: {
  ssn: string | null
  t: TFunction
}) => {
  if (!ssn) return t('general.unknown')
  return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`
}

export const getMaskedSsn = (ssn: string) => `***-**-${ssn.slice(5)}`
