import React, {FC, forwardRef} from 'react'
import {flowMax, addDisplayName, addDefaultProps} from 'ad-hok'

import {addClasses, makeClasses} from 'theme'

const classes = makeClasses((_theme) => ({
  container: {
    position: 'relative',
  },
}))

type RefValueType = HTMLDivElement
type RefType =
  | ((instance: RefValueType | null) => void)
  | React.RefObject<RefValueType>
  | null
  | undefined

interface FieldScrollTargetWithForwardedRefProps {
  forwardedRef: RefType
  offset?: number
}

const FieldScrollTargetWithForwardedRef: FC<FieldScrollTargetWithForwardedRefProps> = flowMax(
  addDisplayName('FieldScrollTargetWithForwardedRef'),
  addDefaultProps({
    offset: 50,
  }),
  addClasses(classes),
  ({classes, forwardedRef, offset}) => (
    <div
      ref={forwardedRef}
      className={classes.container}
      style={{top: offset * -1}}
    />
  )
)

interface Props {
  offset?: number
}

const FieldScrollTarget = forwardRef<RefValueType, Props>(({offset}, ref) => (
  <FieldScrollTargetWithForwardedRef forwardedRef={ref} offset={offset} />
))

export default FieldScrollTarget
