import {TFunction} from 'i18next'

export const getExtendedName = ({
  firstName,
  lastName,
  preferredName,
  middleName,
  suffix,
  t,
}: {
  firstName: string | null
  lastName: string | null
  preferredName: string | null
  middleName: string | null
  suffix: string | null
  t: TFunction
}) => {
  const unknown = t('general.unknown')
  if (!(firstName || preferredName || lastName || middleName || suffix))
    return unknown
  return `${firstName || unknown}${preferredName ? ` (${preferredName})` : ''}${
    middleName ? ` ${middleName}` : ''
  } ${lastName || unknown}${suffix ? ` ${suffix}` : ''}`
}
