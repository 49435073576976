import {getContextHelpers, toObjectKeys} from 'ad-hok-utils'

import {FormValues, ExtractFormSchemaFields} from 'utils/form/schema'
import {editPersonFormSchema} from 'components/EditPersonForm/schema'
import {Person_person} from 'graphql/deserializedTypes/Person'

const [addLeftColumnContextProvider, addLeftColumnContext] = getContextHelpers<{
  person: Person_person
  relationships: FormValues<
    ExtractFormSchemaFields<typeof editPersonFormSchema>
  >['person']['relationships']
  saveAndCheckEligibilities: () => void
  viewingRelationshipIndex: number
  setViewingRelationshipIndex: (relationshipIndex: number) => void
  setScrollToSectionName: (sectionName: string) => void
  setScrollToFieldName: (sectionName: string, fieldName: string) => void
}>(
  toObjectKeys([
    'person',
    'relationships',
    'saveAndCheckEligibilities',
    'viewingRelationshipIndex',
    'setViewingRelationshipIndex',
    'setScrollToSectionName',
    'setScrollToFieldName',
  ])
)

export {addLeftColumnContextProvider, addLeftColumnContext}
