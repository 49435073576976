import React from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import {FC} from 'react'

import {makeClasses, addClasses} from 'theme'
import {addPersonContext} from 'components/PersonDetail/personContext'
import TaskItem from 'components/TaskItem'
import {PERSON_QUERY} from 'graphql/queries'

const classes = makeClasses((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
}))

const PersonDetailTasks: FC = flowMax(
  addDisplayName('PersonDetailTasks'),
  addPersonContext,
  addProps(
    ({person: {id}}) => ({
      refetchQueriesOnDeleteOrUpdate: [
        {
          query: PERSON_QUERY,
          variables: {id},
        },
      ],
    }),
    ['person.id']
  ),
  addClasses(classes),
  ({person: {tasks}, refetchQueriesOnDeleteOrUpdate, classes}) => (
    <div className={classes.container}>
      {tasks.map((task) => (
        <TaskItem
          task={task}
          refetchQueriesOnDeleteOrUpdate={refetchQueriesOnDeleteOrUpdate}
          key={task.id}
        />
      ))}
    </div>
  )
)

export default PersonDetailTasks
