import * as Yup from 'yup'
import {TFunction} from 'i18next'

import {makeFormSchema} from 'utils/form/schema'
import {
  makeTextField,
  makeDateField,
  makeSelectField,
} from 'utils/form/fieldTypes'

const dateNotInFuture = ({t}: {t: TFunction}) =>
  Yup.date().max(new Date(), t('applicationForm.errors.dateNotInFuture'))

export const APPLICATION_LOCATION_COUNTY = 'County'
export const APPLICATION_LOCATIONS = [
  APPLICATION_LOCATION_COUNTY,
  'County - ABD',
  'County - AEMA',
  'County - Newborn',
  'State',
  'PE Unit',
]

export const editMonitorApplicationFormSchema = makeFormSchema({
  fields: {
    application: {
      id: makeTextField({
        isRequired: true,
      }),
      primaryPointOfContactId: makeTextField(),
      submitDate: makeDateField(),
      applicationType: makeSelectField({
        options: [{value: 'New'}, {value: 'Renewal'}, {value: 'PE'}],
      }),
      submitMethod: makeSelectField({
        options: [
          {value: 'Online - NJFC'},
          {value: 'Online - healthcare.gov'},
          {value: 'Phone - NJFC'},
          {value: 'Phone - county'},
          {value: 'Phone - other'},
          {value: 'Mail - NJFC'},
          {value: 'Mail - county'},
          {value: 'Mail - other'},
          {value: 'In person'},
          {value: 'Hospital PE'},
        ],
      }),
      location: makeSelectField({
        options: APPLICATION_LOCATIONS.map((value) => ({value})),
      }),
      countyName: makeSelectField({
        options: [
          {value: 'Atlantic'},
          {value: 'Bergen'},
          {value: 'Burlington'},
          {value: 'Camden'},
          {value: 'Cape May'},
          {value: 'Cumberland'},
          {value: 'Essex'},
          {value: 'Gloucester'},
          {value: 'Hudson'},
          {value: 'Hunterdon'},
          {value: 'Mercer'},
          {value: 'Middlesex'},
          {value: 'Monmouth'},
          {value: 'Morris'},
          {value: 'Ocean'},
          {value: 'Passaic'},
          {value: 'Salem'},
          {value: 'Somerset'},
          {value: 'Sussex'},
          {value: 'Union'},
          {value: 'Warren'},
        ],
      }),
      confirmationNumber: makeTextField(),
      policyId: makeTextField(),
      darSubmitDate: makeDateField({
        validator: dateNotInFuture,
      }),
      assignedToId: makeSelectField({}),
      notes: makeTextField(),
      initialDateOfService: makeDateField(),
      accountNumber: makeTextField(),
    },
  },
})
