/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {UpdateEditableFileInput, EditableFileStatus} from './globalTypes' // ====================================================
// GraphQL mutation operation: UpdateEditableFile
// ====================================================

import {deserializeUpdateEditableFileInput} from './globalTypes'
import {serializeUpdateEditableFileInput} from './globalTypes'
import {UpdateEditableFileVariables as UpdateEditableFileVariablesOrig} from '../types/UpdateEditableFile'
import {UpdateEditableFile as UpdateEditableFileOrig} from '../types/UpdateEditableFile'
import {UpdateEditableFile_updateEditableFile as UpdateEditableFile_updateEditableFileOrig} from '../types/UpdateEditableFile'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {UpdateEditableFile_updateEditableFile_sentFax as UpdateEditableFile_updateEditableFile_sentFaxOrig} from '../types/UpdateEditableFile'
import {UpdateEditableFile_updateEditableFile_sentFax_countyFax as UpdateEditableFile_updateEditableFile_sentFax_countyFaxOrig} from '../types/UpdateEditableFile'
import {UpdateEditableFile_updateEditableFile_sentFax_countyFax_state as UpdateEditableFile_updateEditableFile_sentFax_countyFax_stateOrig} from '../types/UpdateEditableFile'
export interface UpdateEditableFile_updateEditableFile_sentFax_countyFax_state {
  __typename: 'State'
  stateCode: string
}
export const deserializeUpdateEditableFile_updateEditableFile_sentFax_countyFax_state = (
  serialized: UpdateEditableFile_updateEditableFile_sentFax_countyFax_stateOrig
): UpdateEditableFile_updateEditableFile_sentFax_countyFax_state => ({
  ...serialized,
})
export const serializeUpdateEditableFile_updateEditableFile_sentFax_countyFax_state = (
  deserialized: UpdateEditableFile_updateEditableFile_sentFax_countyFax_state
): UpdateEditableFile_updateEditableFile_sentFax_countyFax_stateOrig => ({
  ...deserialized,
})
export interface UpdateEditableFile_updateEditableFile_sentFax_countyFax {
  __typename: 'CountyFax'
  countyName: string
  state: UpdateEditableFile_updateEditableFile_sentFax_countyFax_state
}
export const deserializeUpdateEditableFile_updateEditableFile_sentFax_countyFax = (
  serialized: UpdateEditableFile_updateEditableFile_sentFax_countyFaxOrig
): UpdateEditableFile_updateEditableFile_sentFax_countyFax => ({
  ...serialized,
  state: deserializeUpdateEditableFile_updateEditableFile_sentFax_countyFax_state(
    serialized.state
  ),
})
export const serializeUpdateEditableFile_updateEditableFile_sentFax_countyFax = (
  deserialized: UpdateEditableFile_updateEditableFile_sentFax_countyFax
): UpdateEditableFile_updateEditableFile_sentFax_countyFaxOrig => ({
  ...deserialized,
  state: serializeUpdateEditableFile_updateEditableFile_sentFax_countyFax_state(
    deserialized.state
  ),
})
export interface UpdateEditableFile_updateEditableFile_sentFax {
  __typename: 'SentFax'
  toNumber: string | null
  faxStatus: string | null
  message: string | null
  updatedAt: Date
  countyFax: UpdateEditableFile_updateEditableFile_sentFax_countyFax
}
export const deserializeUpdateEditableFile_updateEditableFile_sentFax = (
  serialized: UpdateEditableFile_updateEditableFile_sentFaxOrig
): UpdateEditableFile_updateEditableFile_sentFax => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  countyFax: deserializeUpdateEditableFile_updateEditableFile_sentFax_countyFax(
    serialized.countyFax
  ),
})
export const serializeUpdateEditableFile_updateEditableFile_sentFax = (
  deserialized: UpdateEditableFile_updateEditableFile_sentFax
): UpdateEditableFile_updateEditableFile_sentFaxOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  countyFax: serializeUpdateEditableFile_updateEditableFile_sentFax_countyFax(
    deserialized.countyFax
  ),
})
export interface UpdateEditableFile_updateEditableFile {
  __typename: 'EditableFile'
  id: string
  fileKey: string
  filename: string
  updatedAt: Date
  status: EditableFileStatus
  allowSigning: boolean | null
  documentSendType: string | null
  faxEnabled: boolean | null
  sentFax: UpdateEditableFile_updateEditableFile_sentFax | null
}
export const deserializeUpdateEditableFile_updateEditableFile = (
  serialized: UpdateEditableFile_updateEditableFileOrig
): UpdateEditableFile_updateEditableFile => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  sentFax:
    serialized.sentFax != null
      ? deserializeUpdateEditableFile_updateEditableFile_sentFax(
          serialized.sentFax
        )
      : serialized.sentFax,
})
export const serializeUpdateEditableFile_updateEditableFile = (
  deserialized: UpdateEditableFile_updateEditableFile
): UpdateEditableFile_updateEditableFileOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  sentFax:
    deserialized.sentFax != null
      ? serializeUpdateEditableFile_updateEditableFile_sentFax(
          deserialized.sentFax
        )
      : deserialized.sentFax,
})
export interface UpdateEditableFile {
  /**
   * Update editable file
   */
  updateEditableFile: UpdateEditableFile_updateEditableFile
}
export const deserializeUpdateEditableFile = (
  serialized: UpdateEditableFileOrig
): UpdateEditableFile => ({
  ...serialized,
  updateEditableFile: deserializeUpdateEditableFile_updateEditableFile(
    serialized.updateEditableFile
  ),
})
export const serializeUpdateEditableFile = (
  deserialized: UpdateEditableFile
): UpdateEditableFileOrig => ({
  ...deserialized,
  updateEditableFile: serializeUpdateEditableFile_updateEditableFile(
    deserialized.updateEditableFile
  ),
})
export interface UpdateEditableFileVariables {
  editableFile: UpdateEditableFileInput
  sendForSigning?: boolean | null
  sendForRemoteSigningSessionId?: string | null
  sendForFax?: string | null
  markUnreviewed?: boolean | null
  applicationId?: string | null
  sendDocumentType?: string | null
  isDocumentSendOnly?: boolean | null
  isDocumentSendAndSave?: boolean | null
  isEsignDocument?: boolean | null
}
export const deserializeUpdateEditableFileVariables = (
  serialized: UpdateEditableFileVariablesOrig
): UpdateEditableFileVariables => ({
  ...serialized,
  editableFile: deserializeUpdateEditableFileInput(serialized.editableFile),
})
export const serializeUpdateEditableFileVariables = (
  deserialized: UpdateEditableFileVariables
): UpdateEditableFileVariablesOrig => ({
  ...deserialized,
  editableFile: serializeUpdateEditableFileInput(deserialized.editableFile),
})
