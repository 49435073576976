import React, {FC} from 'react'
import {flowMax, addDisplayName, addHandlers} from 'ad-hok'
import DeleteIcon from '@material-ui/icons/Delete'
import {PureQueryOptions} from 'apollo-boost'

import {addDeleteTaskMutation} from 'graphql/generated'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import addDialogState from 'utils/addDialogState'
import {addTranslationHelpers} from 'utils/i18n'
import IconButton from 'components/IconButton'
import ConfirmationDialog from 'components/ConfirmationDialog'
import {TaskFields} from 'graphql/deserializedTypes/TaskFields'

interface Props {
  task: TaskFields
  refetchQueriesOnDelete: PureQueryOptions[]
  className?: string
}

const DeleteTaskButton: FC<Props> = flowMax(
  addDisplayName('DeleteTaskButton'),
  addDeleteTaskMutation({
    refetchQueries: ({refetchQueriesOnDelete}) => refetchQueriesOnDelete,
  }),
  addAppSnackbarContext,
  addDialogState,
  addTranslationHelpers,
  addHandlers({
    onDelete: ({
      task: {id},
      mutateDeleteTask,
      hideDialog,
      showSnackbarMessage,
      t,
    }) => () => {
      mutateDeleteTask({
        variables: {id},
      }).then(() => {
        hideDialog()
        showSnackbarMessage(t('taskItem.deletedMessage'))
      })
    },
  }),
  ({
    task: {taskType, assignedTo},
    showDialog,
    hideDialog,
    isShowingDialog,
    onDelete,
    className,
    t,
  }) => (
    <>
      <IconButton onClick={showDialog} className={className}>
        <DeleteIcon />
      </IconButton>
      <ConfirmationDialog
        title={t('deleteTaskDialog.title')}
        message={t('deleteTaskDialog.message', {
          taskType,
          assignedTo: assignedTo.name,
        })}
        cancelText={t('deleteTaskDialog.cancel')}
        confirmText={t('deleteTaskDialog.confirm')}
        open={isShowingDialog}
        onCancel={hideDialog}
        onConfirm={onDelete}
      />
    </>
  )
)

export default DeleteTaskButton
