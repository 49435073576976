import React, {FC} from 'react'
import {Select, MenuItem, FormControl} from '@material-ui/core'
import {
  flowMax,
  addDisplayName,
  addWrapper,
  branch,
  returns,
  addState,
  addProps,
  addHandlers,
  addStateHandlers,
} from 'ad-hok'
import {branchIfEmpty} from 'ad-hok-utils'
import TextField from '@material-ui/core/TextField'
import {Grid} from '@material-ui/core'
import SendSharp from '@material-ui/icons/SendSharp'

import FormSection from 'components/FormSection'
import {addApplicationFormContext} from 'components/EditApplicationForm/applicationFormContext'
import {addTranslationHelpers} from 'utils/i18n'
import Body1 from 'components/Body1'
import {addPersonQuery} from 'graphql/generated'
import {addLoadingIndicator} from 'utils/dataLoading'
import {documentRequestSectionForLink} from 'components/EditApplicationForm/ApplicationFormLeftColumn'
import Trans from 'components/Trans'
import {getExtendedName} from 'utils/name'
import {makeClasses, addClasses} from 'theme'
import {
  Application_application_MedicaidApplication_householdMembers,
  Application_application_MedicaidApplication_householdMembers_person_phoneNumbers,
} from 'graphql/deserializedTypes/Application'
import {getFormattedPhoneNumber} from 'utils/phone'
import {addConfigContext} from 'utils/configContext'
import addDialogState from 'utils/addDialogState'
import Button from 'components/Button'
import FormGroup from 'components/FormGroup'
import FormControlLabel from 'components/FormControlLabel'
import FormLabel from 'components/FormLabel'
import Box from 'components/Box'
import Checkbox from 'components/Checkbox'
import {addCreateSendDocumentRequestsMutation} from 'graphql/generated'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import {getHouseholdMembersWithRelationshipTypes} from 'utils/householdMembers'

const classes = makeClasses((theme) => ({
  phoneNumberRowContainer: {
    height: 38,
    whiteSpace: 'nowrap',
  },
  phoneNumbersContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(-1),
  },
  phoneNumberInfo: {
    position: 'relative',
    top: 6,
    left: 4,
  },
  phoneNumberEsignIcon: {
    position: 'relative',
    top: -2,
  },
  esignSessionReviewedUnreviewedIcon: {
    position: 'relative',
    top: -2,
  },
  esignSessionUnreviewedIcon: {
    color: '#bdbdbd',
  },
  esignSessionReviewedIcon: {
    color: '#219653',
  },
  esignSessionReviewedUnreviewed: {
    fontWeight: 500,
  },
  esignSessionUnreviewed: {
    color: '#ff0000',
  },
  boxBorder: {
    color: '#000000',
    width: 350,
    border: '1px solid lightgrey',
    borderRadius: 8,
    padding: 7,
    margin: 1,
  },
  textField: {
    rows: 1,
    variant: 'outlined',
    margin: 'normal',
  },
}))

interface DocumentRequestProps {
  phoneNumber: Application_application_MedicaidApplication_householdMembers_person_phoneNumbers
}

const DocumentRequest: FC<DocumentRequestProps> = flowMax(
  addDisplayName('PhoneNumberUsedForEsign'),
  addAppSnackbarContext,
  addDialogState,
  addConfigContext,
  addApplicationFormContext,
  addClasses(classes),
  addProps(({config}) => ({
    requestors: config.remoteRequestorTypes,
    documents: config.remoteDocumentTypes,
    workflowIds: config.lighticoWorkflows,
  })),
  addProps(({application}) => ({
    personId: application.person.id,
  })),
  addStateHandlers(
    {
      requestorIds: new Set<string>(),
      workflowIds: new Set<string>(),
      documentIds: new Set<string>(),
    },
    {
      toggleRequestorId: ({requestorIds}) => (requestorId: string) => {
        const newRequestorIds = new Set(requestorIds)
        if (requestorIds.has(requestorId)) {
          newRequestorIds.delete(requestorId)
        } else {
          newRequestorIds.add(requestorId)
        }
        return {
          requestorIds: newRequestorIds,
        }
      },
      clearRequestIds: () => () => ({
        requestorIds: new Set(),
        documentIds: new Set(),
      }),
      toggleDocumentId: ({documentIds}) => (documentId: string) => {
        const newDocumentIds = new Set(documentIds)
        if (documentIds.has(documentId)) {
          newDocumentIds.delete(documentId)
        } else {
          newDocumentIds.add(documentId)
        }
        return {
          documentIds: newDocumentIds,
        }
      },
    }
  ),
  addState('isSubmitting', 'setIsSubmitting', false),
  addState('textMessage', 'setTextMessage', ''),
  addCreateSendDocumentRequestsMutation({}),

  addHandlers({
    onSave: ({
      requestorIds,
      documentIds,
      mutateCreateSendDocumentRequests,
      showSnackbarMessage,
      setIsSubmitting,
      clearRequestIds,
      application: {id: applicationId},
      personId,
      phoneNumber,
      textMessage,
      setTextMessage,
      //      t,
    }) => () => {
      setIsSubmitting(true)
      setTextMessage(textMessage)
      mutateCreateSendDocumentRequests({
        variables: {
          phoneNumber: phoneNumber.number,
          language: phoneNumber.language,
          applicationId,
          personId,
          requestorIds: Array.from(requestorIds),
          documentIds: Array.from(documentIds),
          smsMessage: textMessage,
        },
      })
        .then(() => {
          showSnackbarMessage(
            //           t('applicationForm.createLighticoWorkflowDialog.saved')
            'Sent Document Request'
          )
        })
        .catch(() => {
          window.alert(
            //            t('applicationForm.createLighticoWorkflowDialog.failedToSave')
            'Failed to Send Request'
          )
        })
        .then(() => {
          setIsSubmitting(false)
        })
      textMessage = ''
      setTextMessage(textMessage)
      clearRequestIds()
    },
  }),
  addTranslationHelpers,
  ({
    classes,
    toggleRequestorId,
    toggleDocumentId,
    requestors,
    requestorIds,
    documents,
    documentIds,
    textMessage,
    setTextMessage,
    onSave,
  }) => (
    <div>
      <Box className={classes.boxBorder}>
        <Grid container>
          <FormControl
            component="fieldset"
            variant="standard"
            size="medium"
            margin="dense"
          >
            <FormLabel component="legend">From</FormLabel>
            <FormGroup>
              {requestors.map((requestor) => (
                <FormControlLabel
                  checked={requestorIds.has(requestor.id)}
                  onChange={() => toggleRequestorId(requestor.id)}
                  control={<Checkbox />}
                  label={requestor.requestorType}
                  key={requestor.id}
                />
              ))}
            </FormGroup>
          </FormControl>
          <FormControl
            component="fieldset"
            variant="standard"
            size="medium"
            margin="dense"
          >
            <FormLabel color="primary" component="legend">
              Document
            </FormLabel>
            <FormGroup>
              {documents.map((document) => (
                <FormControlLabel
                  checked={documentIds.has(document.id)}
                  onChange={() => toggleDocumentId(document.id)}
                  control={<Checkbox />}
                  label={document.documentType}
                  key={document.id}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid container>
          <FormControl component="fieldset" variant="standard">
            <TextField
              className={classes.textField}
              label="Text Message"
              id="TextMessage"
              variant="outlined"
              value={textMessage}
              multiline
              onChange={({target: {value}}) => setTextMessage(value)}
            />
          </FormControl>
          &nbsp;&nbsp;
          <FormControl component="fieldset" variant="standard">
            <Button
              onClick={onSave}
              variant="contained"
              color="primary"
              disabled={
                !(
                  textMessage ||
                  (documentIds.size > 0 && requestorIds.size > 0)
                ) ||
                (documentIds.size === 0 && requestorIds.size !== 0) ||
                (documentIds.size !== 0 && requestorIds.size === 0)
              }
            >
              <SendSharp />
            </Button>
          </FormControl>
        </Grid>
      </Box>
    </div>
  )
)

type HouseholdMember = Application_application_MedicaidApplication_householdMembers
type HouseholdMemberWithRelationshipType = HouseholdMember & {
  relationshipType: string | null | undefined
}

interface HouseholdMemberProps {
  householdMember: HouseholdMemberWithRelationshipType
}

const HouseholdMember: FC<HouseholdMemberProps> = flowMax(
  addDisplayName('HouseholdMember'),
  addApplicationFormContext,
  addProps(
    ({application}) => ({
      isMedicaidApplication: application.benefit === 'medicaid',
    }),
    ['application']
  ),
  addProps(
    ({
      householdMember: {
        person: {phoneNumbers, incomeSources},
      },
    }) => ({
      phoneNumbersUsedForEsign: phoneNumbers.filter(
        ({usedForEsign}) => usedForEsign
      ),
      incomeSourcesUsedForEsign: incomeSources.filter(
        ({usedForEsign}) => usedForEsign
      ),
    }),
    ['householdMember.person']
  ),
  addState('selected', 'setSelected', 0),
  addTranslationHelpers,
  addHandlers({
    selectChangeHandler: ({setSelected}) => (
      event: React.ChangeEvent<{value: unknown}>
    ) => {
      const status = event.target.value as number
      setSelected(status)
    },
  }),
  addClasses(classes),
  ({
    householdMember: {relationshipType, wantsCoverage, person},
    phoneNumbersUsedForEsign,
    isMedicaidApplication,
    classes,
    selected,
    t,
    selectChangeHandler,
  }) => (
    <div>
      <Body1>
        <Trans i18nKey="applicationForm.householdMember">
          {{name: getExtendedName({...person, t})}}
          {{
            relationshipType:
              relationshipType === undefined
                ? t(
                    'applicationForm.householdMemberRelationshipTypeRemovedFromProfile'
                  )
                : relationshipType ?? t('general.unknown'),
          }}
          {{
            wantsCoverage:
              isMedicaidApplication && wantsCoverage
                ? ` (${t('applicationForm.wantsCoverage')})`
                : '',
          }}
        </Trans>
      </Body1>
      {!!phoneNumbersUsedForEsign.length && (
        <div className={classes.phoneNumbersContainer}>
          <FormControl style={{minWidth: 150}} size="medium" margin="dense">
            <Select
              id="phone-select"
              value={selected}
              onChange={selectChangeHandler}
              variant="outlined"
            >
              {phoneNumbersUsedForEsign.map((esignPhone, index) => (
                <MenuItem value={index} selected={true} key={index}>
                  {getFormattedPhoneNumber(esignPhone.number)}
                  <b>
                    {esignPhone.comment?.length
                      ? ' - ' + esignPhone.comment?.substring(0, 15)
                      : ''}
                  </b>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {phoneNumbersUsedForEsign.map((phoneNo, index) => {
            if (index === selected) {
              return <DocumentRequest phoneNumber={phoneNo} key={index} />
            } else {
              return null
            }
          })}
        </div>
      )}
      {phoneNumbersUsedForEsign.length === 0 && (
        <b style={{color: 'red'}}>Please add/enable Client Phone for E-Sign</b>
      )}
    </div>
  )
)

const DocumentRequestSection: FC = flowMax(
  addDisplayName('DocumentRequestSection'),
  addConfigContext,
  addProps(({config}) => ({
    documentTypes: config.remoteDocumentTypes,
  })),
  branchIfEmpty('documentTypes'),
  addWrapper((render) => (
    <FormSection labelTranslationKey={documentRequestSectionForLink.name}>
      {render()}
    </FormSection>
  )),
  addApplicationFormContext,
  addTranslationHelpers,
  branch(
    ({application: {householdMembers}}) => !householdMembers.length,
    returns(({t}) => <Body1>{t('applicationForm.noHousehold')}</Body1>)
  ),
  addPersonQuery({
    variables: ({
      application: {
        person: {id},
      },
    }) => ({id}),
  }),
  addLoadingIndicator({}),
  addProps(
    ({application, person}) => ({
      householdMembersWithRelationshipTypes: getHouseholdMembersWithRelationshipTypes(
        {application, person}
      ),
    }),
    ['application', 'person']
  ),
  addClasses(classes),
  ({householdMembersWithRelationshipTypes}) => (
    <>
      {householdMembersWithRelationshipTypes.map(
        (householdMemberWithRelationshipType) =>
          householdMemberWithRelationshipType.relationshipType === 'Client' && (
            <HouseholdMember
              householdMember={householdMemberWithRelationshipType}
              key={householdMemberWithRelationshipType.id}
            />
          )
      )}
    </>
  )
)
export default DocumentRequestSection
