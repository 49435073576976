/* eslint-disable */
import {TextMessagesVariables as TextMessagesVariablesOrig} from '../types/TextMessages'
import {TextMessages as TextMessagesOrig} from '../types/TextMessages'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {TextMessages_textMessages as TextMessages_textMessagesOrig} from '../types/TextMessages'
import {TextMessages_textMessages_messageInitiatedBy as TextMessages_textMessages_messageInitiatedByOrig} from '../types/TextMessages'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: TextMessages
// ====================================================
export interface TextMessages_textMessages_messageInitiatedBy {
  __typename: 'User'
  id: string
  name: string | null
}
export const deserializeTextMessages_textMessages_messageInitiatedBy = (
  serialized: TextMessages_textMessages_messageInitiatedByOrig
): TextMessages_textMessages_messageInitiatedBy => ({...serialized})
export const serializeTextMessages_textMessages_messageInitiatedBy = (
  deserialized: TextMessages_textMessages_messageInitiatedBy
): TextMessages_textMessages_messageInitiatedByOrig => ({...deserialized})
export interface TextMessages_textMessages {
  __typename: 'TextMessage'
  id: string
  phoneNumber: string | null
  message: string
  sessionId: string
  messageInitiatedBy: TextMessages_textMessages_messageInitiatedBy | null
  messageType: string
  personId: number
  applicationId: number | null
  createdAt: Date
  updatedAt: Date
}
export const deserializeTextMessages_textMessages = (
  serialized: TextMessages_textMessagesOrig
): TextMessages_textMessages => ({
  ...serialized,
  messageInitiatedBy:
    serialized.messageInitiatedBy != null
      ? deserializeTextMessages_textMessages_messageInitiatedBy(
          serialized.messageInitiatedBy
        )
      : serialized.messageInitiatedBy,
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
})
export const serializeTextMessages_textMessages = (
  deserialized: TextMessages_textMessages
): TextMessages_textMessagesOrig => ({
  ...deserialized,
  messageInitiatedBy:
    deserialized.messageInitiatedBy != null
      ? serializeTextMessages_textMessages_messageInitiatedBy(
          deserialized.messageInitiatedBy
        )
      : deserialized.messageInitiatedBy,
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
})
export interface TextMessages {
  /**
   * List of messages send/received for this patient
   */
  textMessages: TextMessages_textMessages[]
}
export const deserializeTextMessages = (
  serialized: TextMessagesOrig
): TextMessages => ({
  ...serialized,
  textMessages: serialized.textMessages.map(
    deserializeTextMessages_textMessages
  ),
})
export const serializeTextMessages = (
  deserialized: TextMessages
): TextMessagesOrig => ({
  ...deserialized,
  textMessages: deserialized.textMessages.map(
    serializeTextMessages_textMessages
  ),
})
export interface TextMessagesVariables {
  personId: string
}
export const deserializeTextMessagesVariables = (
  serialized: TextMessagesVariablesOrig
): TextMessagesVariables => ({...serialized})
export const serializeTextMessagesVariables = (
  deserialized: TextMessagesVariables
): TextMessagesVariablesOrig => ({...deserialized})
