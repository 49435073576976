/* eslint-disable */
import {CancelDocumentRequestsVariables as CancelDocumentRequestsVariablesOrig} from '../types/CancelDocumentRequests'
import {CancelDocumentRequests as CancelDocumentRequestsOrig} from '../types/CancelDocumentRequests'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL mutation operation: CancelDocumentRequests
// ====================================================
export interface CancelDocumentRequests {
  cancelDocumentRequests: boolean
}
export const deserializeCancelDocumentRequests = (
  serialized: CancelDocumentRequestsOrig
): CancelDocumentRequests => ({...serialized})
export const serializeCancelDocumentRequests = (
  deserialized: CancelDocumentRequests
): CancelDocumentRequestsOrig => ({...deserialized})
export interface CancelDocumentRequestsVariables {
  requestIds: string[]
}
export const deserializeCancelDocumentRequestsVariables = (
  serialized: CancelDocumentRequestsVariablesOrig
): CancelDocumentRequestsVariables => ({...serialized})
export const serializeCancelDocumentRequestsVariables = (
  deserialized: CancelDocumentRequestsVariables
): CancelDocumentRequestsVariablesOrig => ({...deserialized})
