import React, {FC} from 'react'
import {flowMax, addDisplayName, addWrapper, addProps} from 'ad-hok'
import parseISO from 'date-fns/parseISO'

import {addClasses} from 'theme'
import Form from 'components/Form'
import FormSection from 'components/FormSection'
import ReadOnlyTextField from 'components/ReadOnlyTextField'
import {editMonitorApplicationFormSchema} from 'components/EditMonitorApplicationForm/schema'
import Grid from 'components/Grid'
import DateField from 'components/DateField'
import SelectField from 'components/SelectField'
import TextField from 'components/TextField'
import MultilineTextField from 'components/MultilineTextField'
import ApplicationStatusUpdateSelect from 'components/ApplicationStatusUpdateSelect'
import {determinerMonitor} from 'components/EditApplicationForm/determiners'
import {
  PrimaryPointOfContactDetails,
  addPrimaryPointOfContactProviderAndOptions,
} from 'components/EditApplicationForm/primaryPointOfContact'
import {addFormikTyped} from 'utils/form/formik'
import ApplicationFormLeftColumn from 'components/EditApplicationForm/ApplicationFormLeftColumn'
import OutcomesSection from 'components/EditApplicationForm/OutcomesSection'
import {addApplicationFormContext} from 'components/EditApplicationForm/applicationFormContext'
import {addApplicationBenefitOutcomesContextProvider} from 'components/EditApplicationForm/applicationBenefitOutcomesContext'
import {applicationFormClasses} from 'components/EditMedicaidApplicationForm/index'
import DataAndDocumentsSection from 'components/EditApplicationForm/DataAndDocumentsSection'
import {Application_application_MonitorApplication} from 'graphql/deserializedTypes/Application'
import {Assert, SchemaDoesntHaveExtraFields} from 'utils/form/typeHelpers'
import {addUpdateApplicationMutation} from 'graphql/generated'
import AccountOnDateOfServiceDetails from 'components/AccountOnDateOfServiceDetails'
import AssignedToField from 'components/AssignedToField'
import FileTemplateSection from 'components/FileTemplateSection'
import EditableFilesSection from 'components/EditableFilesSection'

const determiners = [determinerMonitor]

type Check = Assert<
  SchemaDoesntHaveExtraFields<
    typeof editMonitorApplicationFormSchema,
    typeof addUpdateApplicationMutation
  >
>

const EditMonitorApplicationForm: FC = flowMax(
  addDisplayName('EditMonitorApplicationForm'),
  addApplicationFormContext,
  addProps(({application}) => ({
    application: application as Application_application_MonitorApplication,
  })),
  addProps(
    ({application}) => ({
      initialValues: {
        application: {
          ...application,
          assignedToId: application.assignedTo?.id ?? null,
          primaryPointOfContactId: application.primaryPointOfContact?.id ?? '',
        },
      },
    }),
    ['application']
  ),
  addClasses(applicationFormClasses),
  addWrapper(
    (
      render,
      {classes, onUpdateSuccess, mutateUpdateApplication, initialValues}
    ) => (
      <Form
        name="applicationForm"
        className={classes.form}
        schema={editMonitorApplicationFormSchema}
        initialValues={initialValues}
        mutate={mutateUpdateApplication}
        onSubmitSuccess={onUpdateSuccess}
        determiners={determiners}
        shouldResetFormOnInitialValuesChange
        resetOnSaveSuccess
      >
        {render()}
      </Form>
    )
  ),
  addPrimaryPointOfContactProviderAndOptions(editMonitorApplicationFormSchema),
  addFormikTyped(editMonitorApplicationFormSchema),
  addProps(
    ({
      application: {person},
      formik: {
        values: {
          application: {submitDate, initialDateOfService},
        },
      },
    }) => ({
      submitDate: submitDate ? parseISO(submitDate) : null,
      initialDateOfService: initialDateOfService
        ? parseISO(initialDateOfService)
        : null,
      outcomesPeople: [person],
    })
  ),
  addApplicationBenefitOutcomesContextProvider,
  ({
    application,
    classes,
    primaryPointOfContactOptions,
    formik: {
      values: {
        application: {policyId},
      },
    },
    refetchQueriesOnStatusChange,
  }) => (
    <Grid container className={classes.container} direction="row">
      <ApplicationFormLeftColumn
        application={application}
        policyId={policyId}
        showNecessaryCount
      />
      <Grid item className={classes.formColumnContainer}>
        <FormSection
          labelTranslationKey="applicationProcessing"
          shouldMemoize={false}
        >
          <ReadOnlyTextField name="application.id" />
          <SelectField
            name="application.primaryPointOfContactId"
            options={primaryPointOfContactOptions}
          />
          <PrimaryPointOfContactDetails />
          <DateField name="application.initialDateOfService" />
          {application.accountOnDateOfService ? (
            <ReadOnlyTextField name="application.accountNumber" />
          ) : (
            <TextField name="application.accountNumber" />
          )}
          <AccountOnDateOfServiceDetails application={application} />
          <DateField name="application.submitDate" />
          <SelectField name="application.applicationType" />
          <SelectField name="application.submitMethod" />
          <SelectField name="application.location" />
          <SelectField name="application.countyName" />
          <DateField name="application.darSubmitDate" />
          <TextField name="application.confirmationNumber" />
          <TextField name="application.policyId" />
        </FormSection>
        <FileTemplateSection />
        <EditableFilesSection />
        <OutcomesSection />
        <DataAndDocumentsSection />
      </Grid>
      <Grid item className={classes.rightColumnContainer}>
        <ApplicationStatusUpdateSelect
          className={classes.statusSelect}
          application={application}
          refetchQueries={refetchQueriesOnStatusChange}
        />
        <AssignedToField
          name="application.assignedToId"
          assignable={application}
        />
        <MultilineTextField rows={2} rowsMax={20} name="application.notes" />
      </Grid>
    </Grid>
  )
)

export default EditMonitorApplicationForm
