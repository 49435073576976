import {TFunction} from 'i18next'

import {getBenefitName} from 'utils/application'
import {getExtendedName} from 'utils/name'
import {saveCsvFileAs, toCsvContent, getCsvFilename} from 'utils/csv'
import {getFormattedPhoneNumbers, toDatetime, toDate} from 'utils/reports'
import {EligibilityDeterminationFields} from 'graphql/deserializedTypes/EligibilityDeterminationFields'
import {
  AccountsForCsv_accounts_openApplications,
  AccountsForCsv_accounts_openApplications_MedicaidApplication,
  AccountsForCsv_accounts_openApplications_MonitorApplication,
  AccountsForCsv_accounts_openApplications_CharityCareApplication,
  AccountsForCsv_accounts,
} from 'graphql/deserializedTypes/AccountsForCsv'

const getFormattedTriageEligibilityDeterminations = ({
  eligibilityDeterminations,
  t,
}: {
  eligibilityDeterminations: EligibilityDeterminationFields[] | undefined
  t: TFunction
}): string => {
  if (!eligibilityDeterminations?.length) return ''

  return eligibilityDeterminations
    .map(
      ({benefit, status, reasonOrInfo, householdSizeDescription}) =>
        `${getBenefitName({benefit, t})}: ${status}${
          reasonOrInfo ? ` (${reasonOrInfo})` : ''
        }${householdSizeDescription ? ` - ${householdSizeDescription}` : ''}`
    )
    .join('/')
}

type OpenApplicationType = AccountsForCsv_accounts_openApplications
type OpenMedicaidApplicationType = AccountsForCsv_accounts_openApplications_MedicaidApplication
type OpenMonitorApplicationType = AccountsForCsv_accounts_openApplications_MonitorApplication
type OpenCharityCareApplicationType = AccountsForCsv_accounts_openApplications_CharityCareApplication
const isMedicaidApplication = (
  application: OpenApplicationType | null
): application is OpenMedicaidApplicationType =>
  application?.benefit === 'medicaid'
const isMonitorApplication = (
  application: OpenApplicationType | null
): application is OpenMonitorApplicationType =>
  application?.benefit === 'monitor'
const isCharityCareApplication = (
  application: OpenApplicationType | null
): application is OpenCharityCareApplicationType =>
  application?.benefit === 'charityCare'

const getOpenApplicationCsvFields = ({t}: {t: TFunction}) => (
  openApplication: OpenApplicationType | null
) => ({
  'Open application ID': openApplication?.id,
  'Open application benefit type': openApplication?.benefit,
  'Open application status': openApplication?.status,
  'Open application create date': toDatetime(openApplication?.createdAt),
  'Open application status update date': toDatetime(openApplication?.updatedAt),
  'Open MCD application point of contact':
    isMedicaidApplication(openApplication) ||
    isMonitorApplication(openApplication)
      ? openApplication.primaryPointOfContact
        ? getExtendedName({...openApplication.primaryPointOfContact, t})
        : ''
      : '',
  'Open MCD application submit date':
    isMedicaidApplication(openApplication) ||
    isMonitorApplication(openApplication)
      ? toDate(openApplication.submitDate)
      : '',
  'Open MCD application submit method':
    isMedicaidApplication(openApplication) ||
    isMonitorApplication(openApplication)
      ? openApplication.submitMethod
      : '',
  'Open MCD application location':
    isMedicaidApplication(openApplication) ||
    isMonitorApplication(openApplication)
      ? openApplication.location
      : '',
  'Open MCD application county name':
    isMedicaidApplication(openApplication) ||
    isMonitorApplication(openApplication)
      ? openApplication.countyName
      : '',
  'Open MCD application (monitor) application type': isMonitorApplication(
    openApplication
  )
    ? openApplication.applicationType
    : '',
  'Open MCD application (monitor) DAR submit date': isMonitorApplication(
    openApplication
  )
    ? toDate(openApplication.darSubmitDate)
    : '',
  'Open MCD application confirmation number':
    isMedicaidApplication(openApplication) ||
    isMonitorApplication(openApplication)
      ? openApplication.confirmationNumber
      : '',
  'Open MCD application policy ID':
    isMedicaidApplication(openApplication) ||
    isMonitorApplication(openApplication)
      ? openApplication.policyId
      : '',
  'Open CC application initial date of service': isCharityCareApplication(
    openApplication
  )
    ? toDate(openApplication.initialDateOfService)
    : '',
  'Open CC application date of application': isCharityCareApplication(
    openApplication
  )
    ? toDate(openApplication.dateOfApplication)
    : '',
  'Open CC application requested date of application': isCharityCareApplication(
    openApplication
  )
    ? toDate(openApplication.requestedDateOfApplication)
    : '',
  'Open application notes': openApplication?.notes,
  'Open application details update date': toDatetime(
    openApplication?.detailsUpdatedAt
  ),
})

type AccountType = AccountsForCsv_accounts
const transformAccountToCsvRow = ({t}: {t: TFunction}) => ({
  id,
  hospitalAccountId,
  status,
  facility,
  department,
  location,
  insuranceType,
  accountAmount,
  notes,
  createdAt,
  updatedAt,
  dateOfService,
  timeOfService,
  person: {
    hospitalPatientId,
    firstName,
    middleName,
    lastName,
    suffix,
    preferredName,
    dob,
    gender,
    homeAddressStreet,
    homeAddressCity,
    homeAddressState,
    homeAddressZip,
    homeAddressComment,
    mailingAddressStreet,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZip,
    mailingAddressComment,
    phoneNumbers,
    email,
    emailComment,
    preferredLanguage,
  },
  person,
  potentiallyCoveringBenefitOutcome,
  triage,
  openApplications,
  invoiceMonth,
  invoiceYear,
}: AccountType) => {
  const sharedFields = {
    'Internal account ID': id,
    'Hospital account ID': hospitalAccountId,
    'Account status': status,
    Facility: facility,
    Department: department,
    Location: location,
    'Insurance on DOS': insuranceType,
    'Invoice month': invoiceMonth,
    'Invoice year': invoiceYear,
    'Account amount': accountAmount,
    'Account notes': notes,
    'Account create date': toDatetime(createdAt),
    'Account updated date': toDatetime(updatedAt),
    'Date of service': toDate(dateOfService),
    'Time of service': timeOfService,
    'Internal person ID': person.id,
    'Person hospital ID': hospitalPatientId,
    'First name': firstName,
    'Middle name': middleName,
    'Last name': lastName,
    Suffix: suffix,
    'Preferred name': preferredName,
    Dob: toDate(dob),
    Gender: gender,
    'Home address - street': homeAddressStreet,
    'Home address - city': homeAddressCity,
    'Home address - state': homeAddressState,
    'Home address - zip': homeAddressZip,
    'Home address - comment': homeAddressComment,
    'Mailing address - street': mailingAddressStreet,
    'Mailing address - city': mailingAddressCity,
    'Mailing address - state': mailingAddressState,
    'Mailing address - zip': mailingAddressZip,
    'Mailing address - comment': mailingAddressComment,
    'Phone numbers': getFormattedPhoneNumbers(phoneNumbers),
    Email: email,
    'Email comment': emailComment,
    'Preferred language': preferredLanguage,
    'Covered benefit type': potentiallyCoveringBenefitOutcome?.benefit,
    'Covered facility': potentiallyCoveringBenefitOutcome?.coveredFacility,
    'Covered benefit outcome': potentiallyCoveringBenefitOutcome?.outcome,
    'Covered benefit outcome date': toDate(
      potentiallyCoveringBenefitOutcome?.outcomeDate
    ),
    'Covered benefit denied/ineligible reason':
      potentiallyCoveringBenefitOutcome?.deniedIneligibleReason,
    'Covered benefit start date': toDate(
      potentiallyCoveringBenefitOutcome?.effectiveStartDate
    ),
    'Covered benefit end date': toDate(
      potentiallyCoveringBenefitOutcome?.effectiveEndDate
    ),
    'Covered insurance name': potentiallyCoveringBenefitOutcome?.insuranceName,
    'Covered benefit policy ID': potentiallyCoveringBenefitOutcome?.policyId,
    'Covered benefit associated app ID':
      potentiallyCoveringBenefitOutcome?.application?.id,
    'Covered benefit notes': potentiallyCoveringBenefitOutcome?.notes,
    'Covered benefit outcome create date': toDatetime(
      potentiallyCoveringBenefitOutcome?.createdAt
    ),
    'Covered benefit outcome update date': toDatetime(
      potentiallyCoveringBenefitOutcome?.updatedAt
    ),
    'Linked triage ID': triage?.id,
    'Linked triage status': triage?.status,
    'Linked triage close reason': triage?.closeReason,
    'Linked triage close comment': triage?.closeComment,
    'Linked triage last prelim outcome': getFormattedTriageEligibilityDeterminations(
      {
        eligibilityDeterminations: triage?.eligibilityDeterminations,
        t,
      }
    ),
    'Linked triage create date': toDatetime(triage?.createdAt),
    'Linked triage update date': toDatetime(triage?.updatedAt),
  }

  return ((openApplications.length
    ? openApplications
    : [null]) as (OpenApplicationType | null)[]).map((openApplication) => ({
    ...sharedFields,
    ...getOpenApplicationCsvFields({t})(openApplication),
  }))
}

export const saveAccountsCsv = ({
  accounts,
  t,
}: {
  accounts: AccountType[]
  t: TFunction
}) => {
  saveCsvFileAs(
    toCsvContent(accounts.flatMap(transformAccountToCsvRow({t}))),
    getCsvFilename('AccountsReport')
  )
}
