import React, {FC} from 'react'

import Tab from 'components/Tab'
import Link from 'components/Link'

interface Props {
  label: string
  to: string
}

const LinkTab: FC<Props> = (props) => <Tab component={Link} {...props} />

export default LinkTab
