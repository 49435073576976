import React from 'react'
import {
  SimplePropsAdder,
  flowMax,
  addProps,
  SimpleUnchangedProps,
  branch,
  returns,
} from 'ad-hok'
import {matchPath} from 'react-router'
import {Navigate} from 'react-router-dom'

import {addLocation} from 'utils/routing'
import {patientPortalPath} from 'components/TopLevelRoutes'
import {isPatientPortal} from 'config/environment'

type AddIsPatientPortalRoute = SimplePropsAdder<{
  isPatientPortalRoute: boolean
}>

const addIsPatientPortalRoute: AddIsPatientPortalRoute = flowMax(
  addLocation,
  addProps(({location: {pathname}}) => ({
    isPatientPortalRoute: !!matchPath({path: patientPortalPath}, pathname),
  }))
)

type AddIsPatientPortal = SimplePropsAdder<{
  isPatientPortal: boolean
}>

export const addIsPatientPortal: AddIsPatientPortal = flowMax(
  addProps(() => ({
    isPatientPortal,
  }))
)

export const addRedirectToAndFromPatientPortal: SimpleUnchangedProps = flowMax(
  addIsPatientPortalRoute,
  addIsPatientPortal,
  branch(
    ({isPatientPortal, isPatientPortalRoute}) =>
      isPatientPortal && !isPatientPortalRoute,
    returns(() => <Navigate replace to={patientPortalPath} />)
  ),
  branch(
    ({isPatientPortal, isPatientPortalRoute}) =>
      !isPatientPortal && isPatientPortalRoute,
    returns(() => <Navigate replace to="/" />)
  )
)
