/* eslint-disable */
import {FileTemplateMappingVariables as FileTemplateMappingVariablesOrig} from '../types/FileTemplateMapping'
import {FileTemplateMapping as FileTemplateMappingOrig} from '../types/FileTemplateMapping'
import {DeserializedFileTemplateMappingScalar} from 'graphql/converters'
import {deserializeFileTemplateMappingScalar} from 'graphql/converters'
import {serializeFileTemplateMappingScalar} from 'graphql/converters'
import {FileTemplateMapping_fileTemplateMapping as FileTemplateMapping_fileTemplateMappingOrig} from '../types/FileTemplateMapping'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: FileTemplateMapping
// ====================================================
export interface FileTemplateMapping_fileTemplateMapping {
  __typename: 'FileTemplateMapping'
  mapping: DeserializedFileTemplateMappingScalar
}
export const deserializeFileTemplateMapping_fileTemplateMapping = (
  serialized: FileTemplateMapping_fileTemplateMappingOrig
): FileTemplateMapping_fileTemplateMapping => ({
  ...serialized,
  mapping: deserializeFileTemplateMappingScalar(serialized.mapping),
})
export const serializeFileTemplateMapping_fileTemplateMapping = (
  deserialized: FileTemplateMapping_fileTemplateMapping
): FileTemplateMapping_fileTemplateMappingOrig => ({
  ...deserialized,
  mapping: serializeFileTemplateMappingScalar(deserialized.mapping),
})
export interface FileTemplateMapping {
  /**
   * File template mapping
   */
  fileTemplateMapping: FileTemplateMapping_fileTemplateMapping
}
export const deserializeFileTemplateMapping = (
  serialized: FileTemplateMappingOrig
): FileTemplateMapping => ({
  ...serialized,
  fileTemplateMapping: deserializeFileTemplateMapping_fileTemplateMapping(
    serialized.fileTemplateMapping
  ),
})
export const serializeFileTemplateMapping = (
  deserialized: FileTemplateMapping
): FileTemplateMappingOrig => ({
  ...deserialized,
  fileTemplateMapping: serializeFileTemplateMapping_fileTemplateMapping(
    deserialized.fileTemplateMapping
  ),
})
export interface FileTemplateMappingVariables {
  applicationId: string
  receivedWebformFileTemplateId?: string | null
}
export const deserializeFileTemplateMappingVariables = (
  serialized: FileTemplateMappingVariablesOrig
): FileTemplateMappingVariables => ({...serialized})
export const serializeFileTemplateMappingVariables = (
  deserialized: FileTemplateMappingVariables
): FileTemplateMappingVariablesOrig => ({...deserialized})
