import React, {FC} from 'react'
import {flowMax, addDisplayName, addState, addHandlers} from 'ad-hok'

import addApolloClient from 'utils/addApolloClient'
import {PERSON_QUERY} from 'graphql/queries'
import {addTranslationHelpers} from 'utils/i18n'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogContent from 'components/DialogContent'
import {StandaloneTextField} from 'components/TextField'
import Button from 'components/Button'
import {makeClasses, addClasses} from 'theme'
import {addLeftColumnContext} from 'components/EditPersonForm/leftColumnContext'
import {getInitialValues} from 'utils/form/getValues'
import {editPersonFormSchema} from 'components/EditPersonForm/schema'
import {
  Person as PersonOrig,
  PersonVariables as PersonVariablesOrig,
} from 'graphql/types/Person'
import {addFormContext} from 'utils/form/context'
import {deserializePerson_person} from 'graphql/deserializedTypes/Person'

const classes = makeClasses((theme) => ({
  contentContainer: {
    width: 600,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  submitButton: {
    display: 'block',
    marginTop: theme.spacing(3),
  },
}))

interface Props {
  open: boolean
  onClose: () => void
  pushRelationship: (relationship: any) => void
}

const AddExistingPersonDialog: FC<Props> = flowMax(
  addDisplayName('AddExistingPersonDialog'),
  addApolloClient,
  addState('personId', 'setPersonId', ''),
  addLeftColumnContext,
  addFormContext,
  addTranslationHelpers,
  addHandlers({
    onSubmit: ({
      apolloClient,
      personId,
      person,
      relationships,
      pushRelationship,
      onClose,
      setCollectionItemFieldsUpdatedAt,
      t,
    }) => async () => {
      if (personId === person.id) {
        alert(t('addExistingPersonDialog.notClientId'))
        return
      }
      if (relationships.some(({otherPerson}) => personId === otherPerson.id)) {
        alert(t('addExistingPersonDialog.notExistingRelationship'))
        return
      }
      try {
        const {
          data: {person: returnedPerson},
        } = await apolloClient.query<PersonOrig, PersonVariablesOrig>({
          query: PERSON_QUERY,
          variables: {
            id: personId,
          },
        })

        const person = deserializePerson_person(returnedPerson)
        if (person.fieldsUpdatedAt) {
          setCollectionItemFieldsUpdatedAt(
            `person.relationships[${relationships.length}].otherPerson`,
            person.fieldsUpdatedAt
          )
        }
        pushRelationship({
          otherPerson: getInitialValues(editPersonFormSchema, {
            person,
          }).person,
          id: '',
          relationshipType: '',
          liveTogether: null,
        })

        onClose()
      } catch (e) {
        alert(t('addExistingPersonDialog.notFound'))
      }
    },
  }),
  addClasses(classes),
  ({open, onClose, personId, setPersonId, onSubmit, classes, t}) => (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('addExistingPersonDialog.title')}</DialogTitle>
      <DialogContent className={classes.contentContainer} dividers>
        <StandaloneTextField
          label={t('addExistingPersonDialog.personId')}
          value={personId}
          onChange={({target: {value}}) => setPersonId(value)}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={!personId}
          className={classes.submitButton}
        >
          {t('addExistingPersonDialog.submit')}
        </Button>
      </DialogContent>
    </Dialog>
  )
)

export default AddExistingPersonDialog
