import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps, addMemoBoundary} from 'ad-hok'

import {addTranslationHelpers} from 'utils/i18n'
import Button from 'components/Button'
import {addFormContext} from 'utils/form/context'
import {addFormik} from 'utils/form/formik'

interface Props {
  testId?: string
}

const SubmitButton: FC<Props> = flowMax(
  addDisplayName('SubmitButton'),
  addFormContext,
  addFormik,
  addMemoBoundary(['formName', 'formik.isSubmitting', 'testId']),
  addTranslationHelpers,
  addProps(({formName, t}) => ({
    label: t(`${formName}.submit`),
  })),
  ({label, formik: {submitForm, isSubmitting}, testId}) => (
    <Button
      variant="contained"
      color="primary"
      onClick={submitForm}
      disabled={isSubmitting}
      data-testid={testId}
    >
      {label}
    </Button>
  )
)

export default SubmitButton
