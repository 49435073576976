import React, {FC} from 'react'
import {flowMax, addDisplayName} from 'ad-hok'

import {addClasses, makeClasses} from 'theme'
import Grid from 'components/Grid'

interface Props {
  variant:
    | 'twoColumn_2_1'
    | 'twoColumn_2_1_true'
    | 'twoColumn_1.5_1_true'
    | 'twoColumn_1_1'
    | 'twoColumn_1_1_true'
    | 'threeColumn_1_1_1'
    | 'threeColumn_1_1_1_true'
    | 'threeColumn_2_1_1'
    | 'threeColumn_2_1_1_true'
    | 'fourColumn_1_1_1_1'
    | 'fourColumn_1_1_1_1_true'
}

const classes = makeClasses((theme) => ({
  twoColumn_2_1: {
    '& > :nth-child(1)': {
      flex: 1.7,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(2)': {
      flex: 1,
    },
  },
  twoColumn_2_1_true: {
    '& > :nth-child(1)': {
      flex: 2,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(2)': {
      flex: 1,
    },
  },
  'twoColumn_1.5_1_true': {
    '& > :nth-child(1)': {
      flex: 1.5,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(2)': {
      flex: 1,
    },
  },
  twoColumn_1_1: {
    '& > :nth-child(1)': {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(2)': {
      flex: 1.2,
    },
  },
  twoColumn_1_1_true: {
    '& > :nth-child(1)': {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(2)': {
      flex: 1,
    },
  },
  threeColumn_2_1_1: {
    '& > :nth-child(1)': {
      flex: 2.1,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(2)': {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(3)': {
      flex: 1.4,
    },
  },
  threeColumn_2_1_1_true: {
    '& > :nth-child(1)': {
      flex: 2,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(2)': {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(3)': {
      flex: 1,
    },
  },
  threeColumn_1_1_1: {
    '& > :nth-child(1)': {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(2)': {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(3)': {
      flex: 1.45,
    },
  },
  threeColumn_1_1_1_true: {
    '& > :nth-child(1)': {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(2)': {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(3)': {
      flex: 1,
    },
  },
  fourColumn_1_1_1_1: {
    '& > :nth-child(1)': {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(2)': {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(3)': {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(4)': {
      flex: 1.45,
    },
  },
  fourColumn_1_1_1_1_true: {
    '& > :nth-child(1)': {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(2)': {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(3)': {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    '& > :nth-child(4)': {
      flex: 1,
    },
  },
}))

const FormRow: FC<Props> = flowMax(
  addDisplayName('FormRow'),
  addClasses(classes),
  ({children, classes, variant}) => (
    <Grid className={classes[variant]} container direction="row">
      {children}
    </Grid>
  )
)

export default FormRow
