import {getContextHelpers, toObjectKeys} from 'ad-hok-utils'

const [
  addDocumentSubsectionContextProvider,
  addDocumentSubsectionContext,
] = getContextHelpers<{
  personId: string | null
  createDialogTitle: string
}>(toObjectKeys(['personId', 'createDialogTitle']))

export {addDocumentSubsectionContextProvider, addDocumentSubsectionContext}
