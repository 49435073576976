/* eslint-disable */
import {NumberOfPeopleWithUnreviewedReceivedWebformsVariables as NumberOfPeopleWithUnreviewedReceivedWebformsVariablesOrig} from '../types/NumberOfPeopleWithUnreviewedReceivedWebforms'
import {NumberOfPeopleWithUnreviewedReceivedWebforms as NumberOfPeopleWithUnreviewedReceivedWebformsOrig} from '../types/NumberOfPeopleWithUnreviewedReceivedWebforms'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: NumberOfPeopleWithUnreviewedReceivedWebforms
// ====================================================
export interface NumberOfPeopleWithUnreviewedReceivedWebforms {
  /**
   * Count of number of people with unreviewed received webforms
   */
  numberOfPeopleWithUnreviewedReceivedWebforms: number
}
export const deserializeNumberOfPeopleWithUnreviewedReceivedWebforms = (
  serialized: NumberOfPeopleWithUnreviewedReceivedWebformsOrig
): NumberOfPeopleWithUnreviewedReceivedWebforms => ({...serialized})
export const serializeNumberOfPeopleWithUnreviewedReceivedWebforms = (
  deserialized: NumberOfPeopleWithUnreviewedReceivedWebforms
): NumberOfPeopleWithUnreviewedReceivedWebformsOrig => ({...deserialized})
export interface NumberOfPeopleWithUnreviewedReceivedWebformsVariables {
  webformIds?: string[] | null
}
export const deserializeNumberOfPeopleWithUnreviewedReceivedWebformsVariables = (
  serialized: NumberOfPeopleWithUnreviewedReceivedWebformsVariablesOrig
): NumberOfPeopleWithUnreviewedReceivedWebformsVariables => ({...serialized})
export const serializeNumberOfPeopleWithUnreviewedReceivedWebformsVariables = (
  deserialized: NumberOfPeopleWithUnreviewedReceivedWebformsVariables
): NumberOfPeopleWithUnreviewedReceivedWebformsVariablesOrig => ({
  ...deserialized,
})
