import React, {FC} from 'react'
import ApolloClient from 'apollo-boost'
import {
  flowMax,
  addDisplayName,
  addHandlers,
  addProps,
  addWrapper,
} from 'ad-hok'

import {
  makeFormSchema,
  FormSchemaFields,
  FormSchema,
  FormCanonicalValues,
  ExtractFormSchemaFields,
} from 'utils/form/schema'
import {
  makeTextField,
  makeDateField,
  makeServiceTimeZoneField,
  makeFacilityField,
  makeDepartmentField,
  makeInsuranceTypeField,
  makeBooleanField,
  makeNjmmisCheckField,
} from 'utils/form/fieldTypes'
import {getExistingHospitalAccountIdValidatorTest} from 'components/CreateAccountAndPatientForm/schema'
import {getCanonicalValues, getInitialValues} from 'utils/form/getValues'
import {DEFAULT_ACCOUNT_STATUS} from 'utils/accountStatuses'
import {ReceivedWebforms_receivedWebforms} from 'graphql/deserializedTypes/ReceivedWebforms'
import {addCreateAccountAndPersonFromWebformMutation} from 'graphql/generated'
import {addTranslationHelpers} from 'utils/i18n'
import Form, {OnSubmitSuccessOptions} from 'components/Form'
import addHasSaved from 'utils/addHasSaved'
import addApolloClient from 'utils/addApolloClient'
import {addClasses, makeClasses} from 'theme'
import Dialog from 'components/Dialog'
import {addFormikTyped} from 'utils/form/formik'
import DialogContent from 'components/DialogContent'
import TextField from 'components/TextField'
import DateField from 'components/DateField'
import SelectField from 'components/SelectField'
import OptionalBooleanField from 'components/OptionalBooleanField'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import {unmatchedWebformsRefetchOptions} from 'components/UnmatchedWebformsWorklist/shared'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import {SnackbarProfileLink} from 'components/SnackbarProfileLink'
import {FacilityFields} from 'graphql/deserializedTypes/FacilityFields'
import {addFacilities, addDepartments} from 'utils/configContext'
import {DepartmentFields} from 'graphql/deserializedTypes/DepartmentFields'

const classes = makeClasses((theme) => ({
  modalContainer: {
    minWidth: 400,
  },
  modalFormContentContainer: {
    minHeight: 170,
  },
}))

const getCreatePersonFormSchema = ({
  facilities,
  departments,
  apolloClient,
}: {
  facilities: FacilityFields[]
  departments: DepartmentFields[]
  apolloClient: ApolloClient<object> | null
}) =>
  makeFormSchema({
    fields: {
      account: {
        hospitalAccountId: makeTextField({
          isRequired: true,
          validatorTest: getExistingHospitalAccountIdValidatorTest({
            apolloClient,
          }),
        }),
        dateOfService: makeDateField({
          isRequired: true,
          defaultToToday: true,
        }),
        serviceTimeZone: makeServiceTimeZoneField(),
        facility: makeFacilityField({
          facilities,
          defaultsToSingularOption: true,
          isRequired: true,
        }),
        department: makeDepartmentField({
          departments,
          defaultsToSingularOption: true,
        }),
        location: makeTextField(),
        insuranceType: makeInsuranceTypeField(),
        status: makeTextField({
          isRequired: true,
        }),
        njmmisCheck: makeNjmmisCheckField(),
        isScheduled: makeBooleanField({
          isRequired: true,
        }),
      },
    },
  })

const staticCreatePersonFormSchema = getCreatePersonFormSchema({
  facilities: [],
  departments: [],
  apolloClient: null,
})

const computeInitialValues = <TFields extends FormSchemaFields>(
  schema: FormSchema<TFields>
): FormCanonicalValues<TFields> => {
  const initialValues = getCanonicalValues(getInitialValues(schema), schema)
  return {
    ...initialValues,
    account: {
      ...initialValues.account,
      status: DEFAULT_ACCOUNT_STATUS,
      njmmisCheck: 'Not checked',
      isScheduled: false,
      insuranceType: 'Self pay',
    },
  }
}

interface Props {
  receivedWebform: ReceivedWebforms_receivedWebforms
  open: boolean
  onClose: () => void
}

export const CreatePersonModal: FC<Props> = flowMax(
  addDisplayName('CreatePersonModal'),
  addCreateAccountAndPersonFromWebformMutation(unmatchedWebformsRefetchOptions),
  addTranslationHelpers,
  addAppSnackbarContext,
  addHandlers({
    onConfirm: ({
      onClose,
      mutateCreateAccountAndPersonFromWebform,
      receivedWebform,
      showSnackbarMessageAndAction,
      t,
    }) => ({
      canonicalValues: {account},
    }: OnSubmitSuccessOptions<
      ExtractFormSchemaFields<typeof staticCreatePersonFormSchema>
    >) => {
      mutateCreateAccountAndPersonFromWebform({
        variables: {
          account,
          receivedWebformId: receivedWebform.id,
        },
      })
        .then(({data}) => {
          onClose()
          if (!data) return
          const {
            createAccountAndPersonFromWebform: {
              person: {id: personId},
            },
          } = data
          showSnackbarMessageAndAction(
            t('worklist.unmatchedWebforms.createdPerson', {
              personId,
            }),
            <SnackbarProfileLink personId={personId} />
          )
        })
        .catch(() => {
          window.alert(t('form.failedToSave'))
        })
    },
  }),
  addHasSaved('onConfirm'),
  addApolloClient,
  addFacilities,
  addDepartments,
  addProps(
    ({facilities, departments, apolloClient}) => ({
      schema: getCreatePersonFormSchema({
        facilities,
        departments,
        apolloClient,
      }),
    }),
    ['facilities', 'departments', 'apolloClient']
  ),
  addProps(
    ({schema}) => ({
      initialValues: computeInitialValues(schema),
    }),
    ['schema']
  ),
  addClasses(classes),
  addWrapper(
    (render, {open, onClose, onConfirm, schema, initialValues, classes}) => (
      <Dialog
        open={open}
        onClose={onClose}
        classes={{paper: classes.modalContainer}}
      >
        <Form
          name="createAccountAndPersonFromWebformForm"
          schema={schema}
          onSubmitSuccess={onConfirm}
          initialValues={initialValues}
        >
          {render()}
        </Form>
      </Dialog>
    )
  ),
  addFormikTyped(staticCreatePersonFormSchema),
  addHandlers({
    onSaveClick: ({formik: {submitForm}}) => () => {
      submitForm()
    },
  }),
  addProps(({hasSaved}) => ({
    disabled: hasSaved,
  })),
  ({onClose, onSaveClick, disabled, classes, t}) => (
    <>
      <DialogContent>
        <div className={classes.modalFormContentContainer}>
          <TextField name="account.hospitalAccountId" />
          <DateField name="account.dateOfService" />
          <SelectField name="account.serviceTimeZone" />
          <OptionalBooleanField name="account.isScheduled" />
          <SelectField name="account.facility" />
          <SelectField name="account.department" />
          <TextField name="account.location" />
          <SelectField name="account.insuranceType" />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('buttons.cancel')}
        </Button>
        <Button
          onClick={onSaveClick}
          color="primary"
          variant="contained"
          disabled={disabled}
        >
          {t('buttons.save')}
        </Button>
      </DialogActions>
    </>
  )
)
