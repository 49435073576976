/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {UpdateTaskInput} from './globalTypes' // ====================================================
// GraphQL mutation operation: UpdateTask
// ====================================================

import {deserializeUpdateTaskInput} from './globalTypes'
import {serializeUpdateTaskInput} from './globalTypes'
import {UpdateTaskVariables as UpdateTaskVariablesOrig} from '../types/UpdateTask'
import {UpdateTask as UpdateTaskOrig} from '../types/UpdateTask'
import formatISO from 'date-fns/formatISO'
import {parseDate} from 'utils/form/fieldTypes'
import {formatISODate} from 'utils/date'
import {UpdateTask_updateTask as UpdateTask_updateTaskOrig} from '../types/UpdateTask'
import {UpdateTask_updateTask_linkedTo as UpdateTask_updateTask_linkedToOrig} from '../types/UpdateTask'
import {UpdateTask_updateTask_createdBy as UpdateTask_updateTask_createdByOrig} from '../types/UpdateTask'
import {UpdateTask_updateTask_assignedTo as UpdateTask_updateTask_assignedToOrig} from '../types/UpdateTask'
export interface UpdateTask_updateTask_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeUpdateTask_updateTask_assignedTo = (
  serialized: UpdateTask_updateTask_assignedToOrig
): UpdateTask_updateTask_assignedTo => ({...serialized})
export const serializeUpdateTask_updateTask_assignedTo = (
  deserialized: UpdateTask_updateTask_assignedTo
): UpdateTask_updateTask_assignedToOrig => ({...deserialized})
export interface UpdateTask_updateTask_createdBy {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeUpdateTask_updateTask_createdBy = (
  serialized: UpdateTask_updateTask_createdByOrig
): UpdateTask_updateTask_createdBy => ({...serialized})
export const serializeUpdateTask_updateTask_createdBy = (
  deserialized: UpdateTask_updateTask_createdBy
): UpdateTask_updateTask_createdByOrig => ({...deserialized})
export interface UpdateTask_updateTask_linkedTo {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
}
export const deserializeUpdateTask_updateTask_linkedTo = (
  serialized: UpdateTask_updateTask_linkedToOrig
): UpdateTask_updateTask_linkedTo => ({...serialized})
export const serializeUpdateTask_updateTask_linkedTo = (
  deserialized: UpdateTask_updateTask_linkedTo
): UpdateTask_updateTask_linkedToOrig => ({...deserialized})
export interface UpdateTask_updateTask {
  __typename: 'Task'
  id: string
  taskType: string
  assignedTo: UpdateTask_updateTask_assignedTo
  createdBy: UpdateTask_updateTask_createdBy
  status: string
  note: string | null
  durationMin: number | null
  dueDate: Date
  linkedTo: UpdateTask_updateTask_linkedTo
  updatedAt: Date
}
export const deserializeUpdateTask_updateTask = (
  serialized: UpdateTask_updateTaskOrig
): UpdateTask_updateTask => ({
  ...serialized,
  assignedTo: deserializeUpdateTask_updateTask_assignedTo(
    serialized.assignedTo
  ),
  createdBy: deserializeUpdateTask_updateTask_createdBy(serialized.createdBy),
  dueDate: parseDate(serialized.dueDate),
  linkedTo: deserializeUpdateTask_updateTask_linkedTo(serialized.linkedTo),
  updatedAt: parseDate(serialized.updatedAt),
})
export const serializeUpdateTask_updateTask = (
  deserialized: UpdateTask_updateTask
): UpdateTask_updateTaskOrig => ({
  ...deserialized,
  assignedTo: serializeUpdateTask_updateTask_assignedTo(
    deserialized.assignedTo
  ),
  createdBy: serializeUpdateTask_updateTask_createdBy(deserialized.createdBy),
  dueDate: formatISODate(deserialized.dueDate),
  linkedTo: serializeUpdateTask_updateTask_linkedTo(deserialized.linkedTo),
  updatedAt: formatISO(deserialized.updatedAt),
})
export interface UpdateTask {
  /**
   * Update existing task
   */
  updateTask: UpdateTask_updateTask
}
export const deserializeUpdateTask = (
  serialized: UpdateTaskOrig
): UpdateTask => ({
  ...serialized,
  updateTask: deserializeUpdateTask_updateTask(serialized.updateTask),
})
export const serializeUpdateTask = (
  deserialized: UpdateTask
): UpdateTaskOrig => ({
  ...deserialized,
  updateTask: serializeUpdateTask_updateTask(deserialized.updateTask),
})
export interface UpdateTaskVariables {
  task: UpdateTaskInput
}
export const deserializeUpdateTaskVariables = (
  serialized: UpdateTaskVariablesOrig
): UpdateTaskVariables => ({
  ...serialized,
  task: deserializeUpdateTaskInput(serialized.task),
})
export const serializeUpdateTaskVariables = (
  deserialized: UpdateTaskVariables
): UpdateTaskVariablesOrig => ({
  ...deserialized,
  task: serializeUpdateTaskInput(deserialized.task),
})
