import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import {TFunction} from 'i18next'

import FormSection from 'components/FormSection'
import {APPLICATION_QUERY} from 'graphql/queries'
import {addApplicationFormContext} from 'components/EditApplicationForm/applicationFormContext'
import {APPLICATION_STATUS_CLOSED} from 'utils/applicationStatuses'
import {addDocumentFilesContextProvider} from 'components/DocumentFiles/context'
import DocumentFiles from 'components/DocumentFiles'
import {addTranslationHelpers} from 'utils/i18n'

export const documentFilesSectionName = 'documentFiles'

export const getApplicationFileKeyPrefix = (applicationId: string) =>
  `documents/applications/${applicationId}`
export const getApplicationFileDialogTitle = ({t}: {t: TFunction}) =>
  t('applicationDocumentFileDialog.title')
export const getIsApplicationFileEditingFrozen = ({status}: {status: string}) =>
  status === APPLICATION_STATUS_CLOSED

const ApplicationDocumentFiles: FC = flowMax(
  addDisplayName('ApplicationDocumentFiles'),
  addApplicationFormContext,
  addTranslationHelpers,
  addProps(
    ({application: {id, documentFiles}, application, t}) => ({
      isFileEditingFrozen: getIsApplicationFileEditingFrozen(application),
      refetchQueries: [
        {
          query: APPLICATION_QUERY,
          variables: {id},
        },
      ],
      additionalCreateDocumentFileMutateVariables: {
        applicationId: id,
      },
      fileKeyPrefix: getApplicationFileKeyPrefix(id),
      documentFiles,
      documentFileDialogTitle: getApplicationFileDialogTitle({t}),
      shouldHideCreatedAtColumn: true,
    }),
    ['application', 't']
  ),
  addDocumentFilesContextProvider,
  () => <DocumentFiles />
)

const DocumentFilesSection: FC = flowMax(
  addDisplayName('DocumentFilesSection'),
  () => (
    <FormSection labelTranslationKey={documentFilesSectionName}>
      <ApplicationDocumentFiles />
    </FormSection>
  )
)

export default DocumentFilesSection
