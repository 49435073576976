import React from 'react'
import {TextField} from 'formik-material-ui'
import {FC} from 'react'

import Field from 'components/Field'
import {FieldComponentProps} from 'components/TextField'

interface Props extends FieldComponentProps {
  rows?: number
  rowsMax?: number
}

const MultilineTextField: FC<Props> = ({rows = 2, rowsMax = 10, ...props}) => (
  <Field
    component={TextField}
    type="text"
    multiline
    rows={rows}
    rowsMax={rowsMax}
    exampleValue={'' as string}
    {...props}
  />
)

export default MultilineTextField
