import {SimplePropsAdder, addStateHandlers} from 'ad-hok'

type AddDialogStateType = SimplePropsAdder<{
  isShowingDialog: boolean
  showDialog: () => void
  hideDialog: () => void
}>

const addDialogState: AddDialogStateType = addStateHandlers(
  {
    isShowingDialog: false,
  },
  {
    showDialog: () => () => ({isShowingDialog: true}),
    hideDialog: () => () => ({isShowingDialog: false}),
  }
)

export default addDialogState
