import {addProps, CurriedPropsAdder} from 'ad-hok'
import {useLocation} from 'react-router-dom'

type AddActiveRouteIndexProps = <TProps>(
  paths: (props: TProps) => string[]
) => CurriedPropsAdder<
  TProps,
  {
    activeRouteIndex: number
  }
>

const addActiveRouteIndex: AddActiveRouteIndexProps = (paths) =>
  addProps((props) => {
    const {pathname} = useLocation()
    const activeRouteIndex = paths(props).indexOf(pathname)
    return {activeRouteIndex: activeRouteIndex >= 0 ? activeRouteIndex : 0}
  })

export default addActiveRouteIndex
