import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import {TFunction} from 'i18next'

import {makeClasses, addClasses} from 'theme'
import {addPersonContext} from 'components/PersonDetail/personContext'
import ApplicationItem from 'components/ApplicationItem'
import {addTranslationHelpers} from 'utils/i18n'
import {PERSON_QUERY} from 'graphql/queries'

const classes = makeClasses((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
}))

const getApplicationLabel = ({
  application,
  personId,
  t,
}: {
  application: {
    person: {
      id: string
    }
    primaryPointOfContact?: {
      id: string
    } | null
    householdMembers: {
      wantsCoverage: boolean | null
      person: {
        id: string
      }
    }[]
  }
  personId: string
  t: TFunction
}) => {
  if (personId === application.person.id) return
  if (application.primaryPointOfContact?.id === personId)
    return t('applicationItem.labels.primaryPointOfContact')
  const householdMember = application.householdMembers.find(
    (householdMember) => householdMember.person.id === personId
  )
  if (householdMember)
    return `${t('applicationItem.labels.householdMember')}${
      householdMember.wantsCoverage
        ? ` (${t('applicationItem.labels.wantsCoverage')})`
        : ''
    }`
}

const PersonDetailApplications: FC = flowMax(
  addDisplayName('PersonDetailApplications'),
  addPersonContext,
  addTranslationHelpers,
  addProps(({person: {id, allApplications}, t}) => ({
    applicationsWithLabel: allApplications.map((application) => ({
      label: getApplicationLabel({application, personId: id, t}),
      application,
    })),
  })),
  addClasses(classes),
  ({applicationsWithLabel, person, classes}) => (
    <div className={classes.container}>
      {applicationsWithLabel.map(({label, application}) => (
        <ApplicationItem
          application={application}
          label={label}
          refetchQueriesOnStatusUpdate={[
            {
              query: PERSON_QUERY,
              variables: {
                id: person.id,
              },
            },
          ]}
          key={application.id}
        />
      ))}
    </div>
  )
)

export default PersonDetailApplications
