import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import {TFunction} from 'i18next'
import {generatePath} from 'react-router'
import EditIcon from '@material-ui/icons/Edit'

import {makeClasses, addClasses} from 'theme'
import {addPersonContext} from 'components/PersonDetail/personContext'
import Paper from 'components/Paper'
import Heading from 'components/Heading'
import DisplayShortDate from 'components/DisplayShortDate'
import Grid from 'components/Grid'
import LabeledDisplayItem from 'components/LabeledDisplayItem'
import Body1 from 'components/Body1'
import Link from 'components/Link'
import {editApplicationPath} from 'components/TopLevelRoutes'
import DeleteBenefitOutcomeButton from 'components/DeleteBenefitOutcomeButton'
import EditBenefitOutcomeButton from 'components/EditBenefitOutcomeButton'
import AddButton from 'components/AddButton'
import InsuranceDiscoveryBenefitOutcomeFormDialog from 'components/InsuranceDiscoverBenefitOutcomeFormDialog'
import {BenefitOutcome} from 'components/BenefitOutcomeFormDialog'
import {addTranslationHelpers} from 'utils/i18n'
import {getBenefitName} from 'utils/application'
import {PERSON_QUERY} from 'graphql/queries'
import addDialogState from 'utils/addDialogState'
import IconButton from './IconButton'
import {Person_person_benefitOutcomes} from 'graphql/deserializedTypes/Person'
import addUserRoles from 'utils/addUserRoles'
import {isBenefitOutcomeEditable} from 'utils/benefitOutcome'

const classes = makeClasses((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  addButton: {
    marginBottom: theme.spacing(2),
  },
  itemContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  itemTitle: {
    marginBottom: theme.spacing(2),
  },
  itemColumn: {
    marginRight: theme.spacing(20),
    marginBottom: theme.spacing(2),
  },
  itemDeleteContainer: {
    flex: 1,
  },
}))

const getFormattedTitle = ({
  benefit,
  coveredFacility,
  outcome,
  deniedIneligibleReason,
  t,
}: {
  benefit: string
  coveredFacility: string
  outcome: string
  deniedIneligibleReason: string | null
  t: TFunction
}) =>
  `${getBenefitName({benefit, t})}-${coveredFacility}: ${outcome}${
    deniedIneligibleReason ? ` (${deniedIneligibleReason})` : ''
  }`

const AddInsuranceDiscoverBenefitOutcomeButton: FC = flowMax(
  addDisplayName('AddInsuranceDiscoverBenefitOutcomeButton'),
  addPersonContext,
  addDialogState,
  addClasses(classes),
  addTranslationHelpers,
  ({person, isShowingDialog, showDialog, hideDialog, classes, t}) => (
    <>
      <AddButton
        className={classes.addButton}
        onClick={showDialog}
        variant="contained"
      >
        {t('insuranceDiscoveryBenefitOutcomes.add')}
      </AddButton>
      <InsuranceDiscoveryBenefitOutcomeFormDialog
        person={person}
        open={isShowingDialog}
        onCancel={hideDialog}
        onSaveSuccess={hideDialog}
        refetchQueriesOnCreateOrUpdate={[
          {
            query: PERSON_QUERY,
            variables: {id: person.id},
          },
        ]}
      />
    </>
  )
)

interface EditInsuranceDiscoverBenefitOutcomeButtonProps {
  benefitOutcome: BenefitOutcome
}

const EditInsuranceDiscoverBenefitOutcomeButton: FC<EditInsuranceDiscoverBenefitOutcomeButtonProps> = flowMax(
  addDisplayName('EditInsuranceDiscoverBenefitOutcomeButton'),
  addPersonContext,
  addDialogState,
  ({person, benefitOutcome, isShowingDialog, showDialog, hideDialog}) => (
    <>
      <IconButton onClick={showDialog}>
        <EditIcon />
      </IconButton>
      <InsuranceDiscoveryBenefitOutcomeFormDialog
        benefitOutcome={benefitOutcome}
        person={person}
        open={isShowingDialog}
        onCancel={hideDialog}
        onSaveSuccess={hideDialog}
        refetchQueriesOnCreateOrUpdate={[
          {
            query: PERSON_QUERY,
            variables: {id: person.id},
          },
        ]}
      />
    </>
  )
)

interface BenefitOutcomeItemProps {
  benefitOutcome: Person_person_benefitOutcomes
  userIsApprover: boolean
}

const BenefitOutcomeItem: FC<BenefitOutcomeItemProps> = flowMax(
  addDisplayName('BenefitOutcomeItem'),
  addPersonContext,
  addProps(({person: {id}}) => ({
    refetchQueries: [
      {
        query: PERSON_QUERY,
        variables: {id},
      },
    ],
  })),
  addProps(({benefitOutcome, userIsApprover}) => ({
    showDelete: isBenefitOutcomeEditable({benefitOutcome, userIsApprover}),
  })),
  addClasses(classes),
  addTranslationHelpers,
  ({
    benefitOutcome,
    person,
    refetchQueries,
    classes,
    userIsApprover,
    showDelete,
    t,
  }) => (
    <Paper
      className={classes.itemContainer}
      data-testid={`benefit-outcome-card-${benefitOutcome.id}`}
    >
      <Grid container direction="row" justify="space-between">
        <Heading variant="h5" component="h2" className={classes.itemTitle}>
          {getFormattedTitle({...benefitOutcome, t})}
        </Heading>
        {benefitOutcome.application ? (
          <EditBenefitOutcomeButton
            benefitOutcome={benefitOutcome}
            person={person}
            application={benefitOutcome.application}
            refetchQueriesOnUpdate={refetchQueries}
            userIsApprover={userIsApprover}
          />
        ) : (
          <EditInsuranceDiscoverBenefitOutcomeButton
            benefitOutcome={benefitOutcome}
          />
        )}
      </Grid>
      <Grid container direction="row" wrap="nowrap">
        <Grid item className={classes.itemColumn}>
          {benefitOutcome.application && (
            <DisplayShortDate
              label={t('benefitOutcomeItem.outcomeDate')}
              date={benefitOutcome.outcomeDate}
            />
          )}
          <DisplayShortDate
            label={t('benefitOutcomeItem.effectiveStartDate')}
            date={benefitOutcome.effectiveStartDate}
          />
          <DisplayShortDate
            label={t('benefitOutcomeItem.effectiveEndDate')}
            date={benefitOutcome.effectiveEndDate}
          />
        </Grid>
        <Grid item>
          <LabeledDisplayItem
            label={t('benefitOutcomeItem.insuranceName')}
            value={benefitOutcome.insuranceName}
          />
          <LabeledDisplayItem
            label={t('benefitOutcomeItem.policyId')}
            value={benefitOutcome.policyId}
          />
          {benefitOutcome.application && (
            <Body1>
              {t('benefitOutcomeItem.associatedAppId')}{' '}
              <Link
                to={generatePath(editApplicationPath, {
                  id: benefitOutcome.application.id,
                })}
                highlight
              >
                {benefitOutcome.application.id}
              </Link>
            </Body1>
          )}
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
          className={classes.itemDeleteContainer}
        >
          {showDelete && (
            <DeleteBenefitOutcomeButton
              benefitOutcome={benefitOutcome}
              person={person}
              refetchQueriesOnDelete={refetchQueries}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  )
)

const PersonDetailBenefitOutcomes: FC = flowMax(
  addDisplayName('PersonDetailBenefitOutcomes'),
  addPersonContext,
  addUserRoles,
  addClasses(classes),
  ({person: {benefitOutcomes}, classes, userIsApprover}) => (
    <div className={classes.container}>
      <AddInsuranceDiscoverBenefitOutcomeButton />
      {benefitOutcomes.map((benefitOutcome) => (
        <BenefitOutcomeItem
          benefitOutcome={benefitOutcome}
          userIsApprover={userIsApprover}
          key={benefitOutcome.id}
        />
      ))}
    </div>
  )
)

export default PersonDetailBenefitOutcomes
