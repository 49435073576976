export type PerIncomeSource = {
  id: string | null
  incomeType: string | null
  amount: number
  payFrequency?: string | null
  amountPer?: number | null
}[]

export type AdditionalData = {
  perIncomeSource: PerIncomeSource
}

export const parseAdditionalData = (
  additionalDataSerialized: string
): AdditionalData => JSON.parse(additionalDataSerialized)

export const serializeAdditionalData = (
  additionalData: AdditionalData
): string => JSON.stringify(additionalData)
