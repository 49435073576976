import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addState,
  addRef,
  addHandlers,
  addProps,
  addStateHandlers,
} from 'ad-hok'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import ButtonGroup from 'components/ButtonGroup'
import Button from 'components/Button'
import Popper from 'components/Popper'
import Grow from 'components/Grow'
import Paper from 'components/Paper'
import ClickAwayListener from 'components/ClickAwayListener'
import MenuItem from 'components/MenuItem'
import MenuList from 'components/MenuList'

interface Props {
  options: {
    label: string
    onClick: () => void
  }[]
}

const SplitButton: FC<Props> = flowMax(
  addDisplayName('SplitButton'),
  addState('selectedIndex', 'setSelectedIndex', 0),
  addRef('buttonRef', null as HTMLDivElement | null),
  addStateHandlers(
    {
      isOpen: false,
    },
    {
      onToggleOpen: ({isOpen}) => () => ({
        isOpen: !isOpen,
      }),
    }
  ),
  addHandlers({
    onClick: ({options, selectedIndex}) => () => {
      options[selectedIndex]?.onClick()
    },
  }),
  addProps(({options, selectedIndex}) => ({
    buttonLabel: options[selectedIndex]?.label,
  })),
  ({
    options,
    onClick,
    buttonLabel,
    isOpen,
    onToggleOpen,
    buttonRef,
    selectedIndex,
    setSelectedIndex,
  }) => (
    <>
      <ButtonGroup variant="contained" color="primary" ref={buttonRef}>
        <Button onClick={onClick}>{buttonLabel}</Button>
        <Button color="primary" size="small" onClick={onToggleOpen}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={isOpen}
        anchorEl={buttonRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onToggleOpen}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      selected={index === selectedIndex}
                      onClick={() => {
                        setSelectedIndex(index)
                        onToggleOpen()
                      }}
                      key={option.label}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
)

export default SplitButton
