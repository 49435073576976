import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import {TFunction} from 'i18next'
import {branchIfEmpty, addInterval} from 'ad-hok-utils'

import {addReceivedWebformsQuery, addWebformsQuery} from 'graphql/generated'
import {addLoadingIndicator} from 'utils/dataLoading'
import {addClasses, makeClasses} from 'theme'
import Body1 from 'components/Body1'
import {addTranslationHelpers} from 'utils/i18n'
import {MATCHED_STATUSES} from 'components/UnmatchedWebformsWorklist/shared'
import {UnmatchedWebform} from 'components/UnmatchedWebformsWorklist/UnmatchedWebform'
import {WebformFields} from 'graphql/deserializedTypes/WebformFields'
import addFilterControls, {makeFilters} from 'utils/addFilterControls'
import {WORKLIST_POLL_INTERVAL} from 'components/TriageWorklist'

const classes = makeClasses((theme) => ({
  matchedStatusSelect: {
    minWidth: 200,
    marginBottom: theme.spacing(4),
  },
  emptyState: {
    marginLeft: theme.spacing(2),
  },
  filterField: {
    maxWidth: 300,
    marginBottom: theme.spacing(2),
  },
}))

const getFilters = ({webforms, t}: {webforms: WebformFields[]; t: TFunction}) =>
  makeFilters({
    matchedStatus: {
      type: 'select',
      label: t('worklist.unmatchedWebforms.matchedStatus'),
      options: MATCHED_STATUSES.map((matchedStatus) => ({
        value: matchedStatus,
        label: t(`worklist.unmatchedWebforms.${matchedStatus}`),
      })),
    },
    webformId: {
      type: 'multiselect',
      label: t('worklist.unmatchedWebforms.webformId'),
      options: webforms.map(({id, name}) => ({
        value: id,
        label: name,
      })),
    },
    appointmentDate: {
      type: 'multiselect',
      label: t('worklist.unmatchedWebforms.appointmentDate'),
      options: [
        'Prior to today',
        'Today',
        'Next 3 days',
        'In 4+ days',
        'Current month',
      ].map((value) => ({
        value,
        label: value,
      })),
    },
  })

const EmptyState: FC = flowMax(
  addDisplayName('EmptyState'),
  addClasses(classes),
  addTranslationHelpers,
  ({classes, t}) => (
    <Body1 className={classes.emptyState}>{t('worklist.noResults')}</Body1>
  )
)

const UnmatchedWebformsWorklist: FC = flowMax(
  addDisplayName('UnmatchedWebformsWorklist'),
  addWebformsQuery({}),
  addLoadingIndicator({}),
  addTranslationHelpers,
  addProps(
    ({webforms, t}) => ({
      filters: getFilters({webforms, t}),
      initialFilterValues: {
        matchedStatus: MATCHED_STATUSES[0],
        webformId: [],
        appointmentDate: [],
      },
    }),
    ['webforms', 't']
  ),
  addClasses(classes),
  addFilterControls<ReturnType<typeof getFilters>>({
    filterFieldClassName: ({classes}) => classes.filterField,
    persistenceKey: 'receivedWebform',
  }),
  addReceivedWebformsQuery({
    variables: ({filterValues}) => ({
      ...filterValues,
      isScreeningOutcomeEligible: true,
    }),
  }),
  addInterval(
    ({filterValues, refetchReceivedWebforms}) => () => {
      refetchReceivedWebforms(filterValues)
    },
    WORKLIST_POLL_INTERVAL,
    ['filterValues']
  ),
  addLoadingIndicator({}),
  branchIfEmpty('receivedWebforms', {returns: () => <EmptyState />}),
  ({receivedWebforms}) => (
    <>
      {receivedWebforms.map((receivedWebform) => (
        <UnmatchedWebform
          receivedWebform={receivedWebform}
          key={receivedWebform.id}
        />
      ))}
    </>
  )
)

export default UnmatchedWebformsWorklist
