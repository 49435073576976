import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  branch,
  returns,
  addProps,
  addMemoBoundary,
  addEffect,
} from 'ad-hok'
import {TFunction} from 'i18next'
import {addPropIdentityStabilization, addDebouncedCopy} from 'ad-hok-utils'
import {every} from 'lodash'

import {addClasses, makeClasses} from 'theme'
import {addTranslationHelpers} from 'utils/i18n'
import {addLoadingIndicator} from 'utils/dataLoading'
import {
  mapToCanonicalSsn,
  mapToCanonicalPhone,
  INSURANCE_TYPES,
} from 'utils/form/fieldTypes'
import {addPeopleQuery, addWebformsQuery} from 'graphql/generated'
import addFilterControls, {
  makeFilters,
  FilterValues,
} from 'utils/addFilterControls'
import {WorklistNoResults} from 'components/ApplicationWorklist'
import Body1 from 'components/Body1'
import PersonItem from 'components/PersonItem'
import {
  Config_config_applicationBenefits,
  Config_config_departments,
} from 'graphql/deserializedTypes/Config'
import {makeOptionsFromRawEnums} from 'utils/enumOptions'
import {addBenefits, addDepartments} from 'utils/configContext'
import {Webforms_webforms} from 'graphql/deserializedTypes/Webforms'
import addRouteParams from 'utils/addRouteParams'

const classes = makeClasses((theme) => ({
  searchNote: {
    marginBottom: theme.spacing(2),
  },
  filterField: {
    maxWidth: 240,
    marginBottom: theme.spacing(2),
  },
}))

const SEARCH_DEBOUNCE_INTERVAL = 500

const getSearchNote = ({
  peopleCount,
  t,
}: {
  peopleCount: number
  t: TFunction
}) => {
  const searchCount = peopleCount < 50 ? peopleCount : '>50'
  return `${t(
    'worklist.personFilters.searchResults'
  )} (${t('worklist.personFilters.searchCount', {searchCount})}${
    peopleCount < 50 ? '' : `; ${t('worklist.personFilters.addMoreFilters')}`
  })`
}

const getCanonicalFilterValues = (
  filterValues: FilterValues<ReturnType<typeof getPersonFilters>>
) => ({
  ...filterValues,
  ssn: mapToCanonicalSsn(filterValues.ssn),
  phoneNumber: mapToCanonicalPhone(filterValues.phoneNumber),
})

const getPersonFilters = ({
  benefits,
  departments,
  webforms,
  t,
}: {
  benefits: Config_config_applicationBenefits[]
  departments: Config_config_departments[]
  webforms: Webforms_webforms[]
  t: TFunction
}) =>
  makeFilters({
    name: {
      type: 'text',
      label: t('worklist.personFilters.name'),
    },
    dob: {
      type: 'date',
      label: t('worklist.personFilters.dob'),
    },
    phoneNumber: {
      type: 'text',
      label: t('worklist.personFilters.phoneNumber'),
    },
    hospitalPatientId: {
      type: 'text',
      label: t('worklist.personFilters.hospitalPatientId'),
    },
    ssn: {
      type: 'text',
      label: t('worklist.personFilters.ssn'),
    },
    personId: {
      type: 'text',
      label: t('worklist.personFilters.personId'),
    },
    hospitalAccountId: {
      type: 'text',
      label: t('worklist.personFilters.hospitalAccountId'),
    },
    mostRecentMedicaidEligibilityDetermination: {
      type: 'multiselect',
      label: t(
        'worklist.personFilters.mostRecentMedicaidEligibilityDetermination'
      ),
      options: [
        {
          label: t('eligibilityDeterminationStatus.medicaid.eligible'),
          value: 'Not enough data',
        },
        {
          label: t('eligibilityDeterminationStatus.medicaid.ineligible'),
          value: 'Ineligible',
        },
        {
          label: t('eligibilityDeterminationStatus.medicaid.undecided'),
          value: 'null',
        },
      ],
    },
    doesNotHaveApplicationOfType: {
      type: 'multiselect',
      label: t('worklist.personFilters.doesNotHaveApplicationOfType'),
      options: makeOptionsFromRawEnums(
        benefits.map(({benefit}) => benefit),
        (benefit) => t(`applications.benefitLabels.${benefit}`)
      ),
    },
    openApplications: {
      type: 'multiselect',
      label: t('worklist.personFilters.openApplications'),
      options: [
        ...makeOptionsFromRawEnums(
          benefits.map(({benefit}) => benefit),
          (benefit) => t(`applications.benefitLabels.${benefit}`)
        ),
        {
          value: 'noOpenApplications',
          label: t('worklist.personFilters.noOpenApplications'),
        },
      ],
    },
    insuranceTypeOnMostRecentAccount: {
      type: 'multiselect',
      label: t('worklist.personFilters.insuranceTypeOnMostRecentAccount'),
      options: INSURANCE_TYPES.map((insuranceType) => ({
        value: insuranceType,
        label: insuranceType,
      })),
    },
    hasMrn: {
      type: 'multiselect',
      label: t('worklist.personFilters.hasMrn'),
      options: [
        {
          label: t('form.yes'),
          value: 'Yes',
        },
        {
          label: t('form.no'),
          value: 'No',
        },
      ],
    },
    pastDateOfService: {
      type: 'multiselect',
      label: t('worklist.personFilters.pastDateOfService'),
      options: [
        'No DOS',
        'Past 5 days',
        '6 - 30 days ago',
        '31 - 90 days ago',
        '> 90 days ago',
      ].map((value) => ({
        value,
        label: value,
      })),
    },
    futureDateOfService: {
      type: 'multiselect',
      label: t('worklist.personFilters.futureDateOfService'),
      options: [
        'In next 7 days',
        'In 8 - 14 days',
        'In 15 - 30 days',
        'In > 30 days',
      ].map((value) => ({
        value,
        label: value,
      })),
    },
    departmentOfMostRecentAccount: {
      type: 'multiselect',
      label: t('worklist.personFilters.departmentOfMostRecentAccount'),
      options: departments.map(({name}) => ({
        value: name,
        label: name,
      })),
    },
    dischargeDate: {
      type: 'multiselect',
      label: t('worklist.personFilters.dischargeDate'),
      options: [
        'Not discharged',
        'Past day',
        '2 - 5 days ago',
        '> 5 days ago',
      ].map((value) => ({
        value,
        label: value,
      })),
    },
    missingBenefitOutcomeForDateOfService: {
      type: 'multiselect',
      label: t('worklist.personFilters.missingBenefitOutcomeForDateOfService'),
      options: [
        'No missing benefit outcomes',
        'Past 5 days',
        '6 - 30 days ago',
        '31 - 90 days ago',
        '> 90 days ago',
      ].map((value) => ({
        value,
        label: value,
      })),
    },
    unreviewedWebformIds: {
      type: 'multiselect',
      label: t('worklist.personFilters.unreviewedWebformIds'),
      options: webforms.map(({id, name}) => ({
        value: id,
        label: name,
      })),
    },
    hasUnreadTextMessages: {
      type: 'multiselect',
      label: t('worklist.personFilters.hasUnreadTextMessages'),
      options: [
        {
          label: t('form.yes'),
          value: 'Yes',
        },
        {
          label: t('form.no'),
          value: 'No',
        },
      ],
    },
  })

const PersonWorklist: FC = flowMax(
  addDisplayName('PersonWorklist'),
  addBenefits,
  addDepartments,
  addWebformsQuery({}),
  addLoadingIndicator({}),
  addTranslationHelpers,
  addClasses(classes),
  addRouteParams<{
    unreviewedWebformIds: string | undefined
    unreadMessages: string | undefined
    openApplications: string | undefined
  }>(),
  addProps(({unreviewedWebformIds, webforms}) => ({
    unreviewedWebformIds: !unreviewedWebformIds
      ? undefined
      : unreviewedWebformIds === 'all'
      ? webforms.map((webform) => webform.id)
      : unreviewedWebformIds.split(','),
  })),
  addProps(
    ({benefits, departments, webforms, t}) => ({
      filters: getPersonFilters({benefits, departments, webforms, t}),
      initialFilterValues: {
        name: null,
        dob: '',
        phoneNumber: null,
        personId: null,
        hospitalPatientId: null,
        ssn: null,
        hospitalAccountId: null,
        mostRecentMedicaidEligibilityDetermination: [],
        doesNotHaveApplicationOfType: [],
        insuranceTypeOnMostRecentAccount: [],
        hasMrn: [],
        pastDateOfService: [],
        futureDateOfService: [],
        departmentOfMostRecentAccount: [],
        openApplications: [],
        dischargeDate: [],
        missingBenefitOutcomeForDateOfService: [],
        unreviewedWebformIds: [],
        hasUnreadTextMessages: [],
      },
    }),
    ['benefits', 'departments', 'webforms', 't']
  ),
  addFilterControls<ReturnType<typeof getPersonFilters>>({
    filterFieldClassName: ({classes}) => classes.filterField,
    showClearButton: true,
    persistenceKey: 'people',
    forceFilterValues: ({
      unreviewedWebformIds,
      unreadMessages,
      openApplications,
    }) => ({
      ...(unreviewedWebformIds
        ? {
            unreviewedWebformIds,
          }
        : undefined),
      ...(unreadMessages
        ? {hasUnreadTextMessages: [unreadMessages]}
        : undefined),
      ...(openApplications
        ? {openApplications: [openApplications]}
        : undefined),
    }),
  }),
  addEffect(
    ({
      unreviewedWebformIds,
      unreadMessages,
      openApplications,
      applyForcedFilters,
    }) => () => {
      if (unreviewedWebformIds || unreadMessages || openApplications) {
        applyForcedFilters()
        window.history.replaceState(null, '', window.location.pathname)
      }
    }
  ),
  addDebouncedCopy(
    SEARCH_DEBOUNCE_INTERVAL,
    'filterValues',
    'filterValuesDebounced'
  ),
  addProps(
    ({filterValuesDebounced: filterValues}) => ({
      filterValues: getCanonicalFilterValues(filterValues),
      skipPeopleQuery: every(filterValues, (filterValue) => !filterValue),
    }),
    ['filterValuesDebounced']
  ),
  addPeopleQuery({
    variables: ({filterValues}) => filterValues,
    skip: ({skipPeopleQuery}) => skipPeopleQuery,
  }),
  branch(
    ({skipPeopleQuery}) => skipPeopleQuery,
    returns(({t}) => <Body1>{t('worklist.personFilters.noSearch')}</Body1>)
  ),
  addLoadingIndicator({}),
  branch(
    ({people}) => !people.length,
    returns(() => <WorklistNoResults />)
  ),
  addPropIdentityStabilization('people'),
  addMemoBoundary(['people']),
  addProps(({people, t}) => ({
    searchNote: getSearchNote({peopleCount: people.length, t}),
  })),
  ({people, searchNote, classes}) => (
    <>
      <Body1 className={classes.searchNote}>{searchNote}</Body1>
      {people.map((person) => (
        <PersonItem person={person} key={person.id} />
      ))}
    </>
  )
)

export default PersonWorklist
