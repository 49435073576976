import {
  CurriedPropsAdder,
  addProps,
  flowMax,
  addState,
  addHandlers,
  addStateHandlers,
} from 'ad-hok'
import {cleanupProps, getContextHelpersFromInitialValues} from 'ad-hok-utils'

import {
  makeFormSchema,
  FormSchemaFields,
  FormSchema,
  FormCanonicalValues,
} from 'utils/form/schema'
import {FieldsFreshnessObject} from 'utils/form/getFieldsUpdatedAt'
import typedAs from 'utils/typedAs'

interface FormContextValue {
  formName: string
  formSchema: FormSchema<any>
  registerFieldToFormSection: ({
    fieldName,
    sectionName,
    status,
  }: {
    fieldName: string
    sectionName: string
    status?: FormFieldStatus
  }) => void
  formSections: FormSections
  scrollToFormSection: (sectionName: string) => void
  addFormSectionRef: (sectionName: string, element: HTMLElement) => void
  scrollToFormField: (sectionName: string, fieldName: string) => void
  addFormFieldRef: (
    sectionName: string,
    fieldName: string,
    element: HTMLElement
  ) => void
  shouldTrackFieldsUpdatedAt?: boolean
  setCollectionItemFieldsFreshness: (
    itemName: string,
    fieldsFreshness: any
  ) => void
  removeCollectionItemFieldsFreshness: (
    collectionName: string,
    index: number
  ) => void
  toggleFreshnessConfirmed: (fieldName: string) => void
  setCollectionItemFieldsUpdatedAt: (
    itemName: string,
    itemFieldsUpdatedAt: any
  ) => void
  removeCollectionItemFieldsUpdatedAt: (
    collectionName: string,
    index: number
  ) => void
}

interface FormContextValueTyped<TFields extends FormSchemaFields>
  extends FormContextValue {
  formSchema: FormSchema<TFields>
}

const formContextInitialValue: FormContextValue = {
  formName: '',
  formSchema: makeFormSchema({fields: {}}),
  registerFieldToFormSection: () => {},
  formSections: {},
  scrollToFormSection: () => {},
  scrollToFormField: () => {},
  addFormSectionRef: () => {},
  addFormFieldRef: () => {},
  shouldTrackFieldsUpdatedAt: false,
  setCollectionItemFieldsFreshness: () => {},
  removeCollectionItemFieldsFreshness: () => {},
  toggleFreshnessConfirmed: () => {},
  setCollectionItemFieldsUpdatedAt: () => {},
  removeCollectionItemFieldsUpdatedAt: () => {},
}

const {
  addProvider: addFormContextValueProvider,
  addConsumer: addFormContext,
} = getContextHelpersFromInitialValues(formContextInitialValue)

const [
  addFormFieldStatusesContextProvider,
  addFormFieldStatusesContext,
] = getContextHelpersFromInitialValues({
  formFieldStatuses: {} as FormFieldStatuses,
})

const [
  addFieldsFreshnessContextProvider,
  addFieldsFreshnessContext,
] = getContextHelpersFromInitialValues({
  fieldsFreshness: {} as FieldsFreshnessObject,
})

export {addFormContext, addFormFieldStatusesContext, addFieldsFreshnessContext}

interface FormCanonicalValuesContextValue {
  formCanonicalValues: FormCanonicalValues<any>
}

interface FormCanonicalValuesContextValueTyped<
  TFields extends FormSchemaFields
> {
  formCanonicalValues: FormCanonicalValues<TFields>
}

const formCanonicalValuesInitialValue: FormCanonicalValuesContextValue = {
  formCanonicalValues: {},
}

const {
  addProvider: addFormCanonicalValuesContextProvider,
  addConsumer: addFormCanonicalValuesContext,
} = getContextHelpersFromInitialValues(formCanonicalValuesInitialValue)

export {addFormCanonicalValuesContextProvider, addFormCanonicalValuesContext}

type AddFormNameType = <TProps>(
  getName: (props: TProps) => string
) => CurriedPropsAdder<
  TProps,
  {
    formName: string
  }
>

export const addFormName: AddFormNameType = (getName) =>
  addProps((props) => ({
    formName: getName(props),
  }))

export type FormFieldStatusNonempty = 'invalid' | 'necessary'
export type FormFieldStatus = FormFieldStatusNonempty | undefined

export type FormFieldStatuses = {
  [fieldName: string]: FormFieldStatus
}

export type FormSection = {
  fieldNames: string[]
  predeterminedStatuses: FormFieldStatuses
}

export type FormSections = {
  [sectionName: string]: FormSection
}

const getUpdatedFormSection = ({
  formSections,
  fieldName,
  sectionName,
  ...maybeStatus
}: {
  formSections: FormSections
  fieldName: string
  sectionName: string
  status?: FormFieldStatus
}): FormSection => {
  const section: FormSection = formSections[sectionName] ?? {
    fieldNames: [],
    predeterminedStatuses: {},
  }
  const existingFieldNames = section.fieldNames
  const existingPredeterminedStatuses = section.predeterminedStatuses
  return {
    ...section,
    fieldNames: !existingFieldNames.includes(fieldName)
      ? [...existingFieldNames, fieldName]
      : existingFieldNames,
    predeterminedStatuses:
      'status' in maybeStatus
        ? {
            ...existingPredeterminedStatuses,
            [fieldName]: maybeStatus.status,
          }
        : existingPredeterminedStatuses,
  }
}

type AddFormContextProviderType = <
  TFields extends FormSchemaFields,
  TProps extends {
    formName: string
    schema: FormSchema<TFields>
    formFieldStatuses: FormFieldStatuses
    shouldTrackFieldsUpdatedAt: boolean
    toggleFreshnessConfirmed: (fieldName: string) => void
    setCollectionItemFieldsFreshness: (
      itemName: string,
      fieldsFreshness: any
    ) => void
    removeCollectionItemFieldsFreshness: (
      collectionName: string,
      index: number
    ) => void
    fieldsFreshness: FieldsFreshnessObject
    setCollectionItemFieldsUpdatedAt: (
      itemName: string,
      itemFieldsUpdatedAt: any
    ) => void
    removeCollectionItemFieldsUpdatedAt: (
      collectionName: string,
      index: number
    ) => void
  }
>(
  props: TProps
) => TProps

export const addFormContextProvider: AddFormContextProviderType = flowMax(
  addStateHandlers(
    {
      formSections: {} as FormSections,
    },
    {
      registerFieldToFormSection: ({formSections}) => (opts: {
        fieldName: string
        sectionName: string
        status?: FormFieldStatus
      }) => ({
        formSections: {
          ...formSections,
          [opts.sectionName]: getUpdatedFormSection({
            formSections,
            ...opts,
          }),
        },
      }),
    }
  ),
  addState(
    'formSectionRefs',
    'setFormSectionRefs',
    typedAs<{
      [sectionName: string]: HTMLElement
    }>({})
  ),
  addHandlers(
    {
      scrollToFormSection: ({formSectionRefs}) => (sectionName: string) => {
        formSectionRefs[sectionName]?.scrollIntoView({behavior: 'auto'})
      },
      addFormSectionRef: ({formSectionRefs}) => (
        sectionName: string,
        element: HTMLElement
      ) => {
        formSectionRefs[sectionName] = element
      },
    },
    ['formSectionRefs']
  ),
  addState(
    'formFieldRefs',
    'setFormFieldRefs',
    typedAs<{
      [sectionName: string]: {
        [fieldName: string]: HTMLElement
      }
    }>({})
  ),
  addHandlers(
    {
      scrollToFormField: ({formFieldRefs}) => (
        sectionName: string,
        fieldName: string
      ) => {
        formFieldRefs[sectionName]?.[fieldName]?.scrollIntoView({
          behavior: 'auto',
        })
      },
      addFormFieldRef: ({formFieldRefs}) => (
        sectionName: string,
        fieldName: string,
        element: HTMLElement
      ) => {
        if (!formFieldRefs[sectionName]) formFieldRefs[sectionName] = {}
        formFieldRefs[sectionName][fieldName] = element
      },
    },
    ['formFieldRefs']
  ),
  addProps(({schema: formSchema}) => ({
    formSchema,
  })),
  addFormContextValueProvider,
  addFormFieldStatusesContextProvider,
  addFieldsFreshnessContextProvider,
  cleanupProps([
    'formSections',
    'registerFieldToFormSection',
    'scrollToFormSection',
    'addFormSectionRef',
    'formSectionRefs',
    'setFormSectionRefs',
    'scrollToFormField',
    'addFormFieldRef',
    'formFieldRefs',
    'setFormFieldRefs',
    'formSchema',
  ])
)

type AddFormContextTypedType = <TFields extends FormSchemaFields, TProps>(
  schema: FormSchema<TFields>
) => (props: TProps) => TProps & FormContextValueTyped<TFields>

export const addFormContextTyped: AddFormContextTypedType = (_schema) =>
  addFormContext

type AddFormCanonicalValuesContextTypedType = <
  TFields extends FormSchemaFields,
  TProps
>(
  schema: FormSchema<TFields>
) => (props: TProps) => TProps & FormCanonicalValuesContextValueTyped<TFields>

export const addFormCanonicalValuesContextTyped: AddFormCanonicalValuesContextTypedType = (
  _schema
) => addFormCanonicalValuesContext
