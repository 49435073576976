import React, {ComponentProps, ComponentType, ReactElement} from 'react'
import {FastField as FormikField} from 'formik'
import {
  flowMax,
  addDisplayName,
  addProps,
  addWrapper,
  addMemoBoundary,
  addHandlers,
} from 'ad-hok'
import cx from 'classnames'
import i18n from 'i18next'
import {omit} from 'lodash/fp'
import {addEffectOnMount, removeProps, cleanupProps} from 'ad-hok-utils'

import {addTranslationHelpers} from 'utils/i18n'
import {makeClasses, addClasses} from 'theme'
import {addFormSectionContext} from 'components/FormSection'
import {FormFieldWithFeedbackIconContainer} from 'components/FormFieldWithFeedbackIconContainer'
import {FieldType} from 'utils/form/fieldTypes'
import {
  FormFieldStatus,
  addFormContext,
  addFormFieldStatusesContext,
} from 'utils/form/context'
import {extractFieldSchema} from 'utils/form/schema'
import {addFormPrefixContext} from 'components/FormPrefix'
import {FormFieldConfirmFreshnessButton} from 'components/FormFieldConfirmFreshnessButton'
import {getFormFeedbackIconStatus} from 'components/FormFeedbackIcon'
import FieldScrollTarget from 'components/FieldScrollTarget'

const classes = makeClasses((theme) => ({
  container: {
    marginBottom: theme.spacing(4),
    position: 'relative',
  },
  fieldContainer: {
    flex: 1,
  },
  iconContainer: {
    height: 56,
    width: 48,
  },
}))

type AddFieldNames = <
  TProps extends {
    name: string
  }
>(
  props: TProps
) => TProps & {
  name: string
  unprefixedUnindexedName: string
  unindexedName: string
}

const addFieldNames: AddFieldNames = flowMax(
  addFormPrefixContext,
  addProps(
    ({formPrefix, name}) => ({
      name: `${formPrefix}${name}`,
      unprefixedUnindexedName: name.replace(/\[\d+\]/g, ''),
      unindexedName: `${formPrefix}${name}`.replace(/\[\d+\]/g, ''),
    }),
    ['formPrefix', 'name']
  ),
  cleanupProps(['formPrefix'])
)

type AddFieldInfoType = <
  TProps extends {
    name: string
    labelTranslationKey?: string
    exampleValue: any
  }
>(
  props: TProps
) => Omit<
  Omit<TProps, 'formContextValue'>,
  | 'formName'
  | 'formSchema'
  | 't'
  | 'formFieldStatuses'
  | 'formSectionName'
  | 'registerFieldToFormSection'
  | 'formSections'
  | 'scrollToFormSection'
  | 'addFormSectionRef'
  | 'unindexedName'
  | 'unprefixedUnindexedName'
  | 'shouldTrackFieldsUpdatedAt'
  | 'toggleFreshnessConfirmed'
  | 'setCollectionItemFieldsFreshness'
  | 'removeCollectionItemFieldsFreshness'
  | 'setCollectionItemFieldsUpdatedAt'
  | 'removeCollectionItemFieldsUpdatedAt'
> & {
  label: string
  fieldSchema: FieldType<any>
  required: boolean
  'data-testid': string
  name: string
  helperText: string | undefined
  placeholder: string | undefined
  showConfirmButton: boolean | undefined
  status: FormFieldStatus
}

export const addFieldInfo: AddFieldInfoType = flowMax(
  addFormContext,
  addFormFieldStatusesContext,
  addTranslationHelpers,
  addFieldNames,
  addProps(
    ({
      unindexedName,
      name,
      unprefixedUnindexedName,
      formName,
      formSchema: schema,
      t,
      exampleValue,
    }) => ({
      label: t(`${formName}.fieldLabels.${unprefixedUnindexedName}`),
      placeholder: i18n.exists(
        `${formName}.fieldPlaceholders.${unprefixedUnindexedName}`
      )
        ? t(`${formName}.fieldPlaceholders.${unprefixedUnindexedName}`)
        : undefined,
      helperText: i18n.exists(
        `${formName}.fieldHelperTexts.${unprefixedUnindexedName}`
      )
        ? t(`${formName}.fieldHelperTexts.${unprefixedUnindexedName}`)
        : undefined,
      fieldSchema: extractFieldSchema<typeof exampleValue>({
        name: unindexedName,
        schema,
      }),
      id: `${formName}-${name}-field`,
    }),
    [
      'unindexedName',
      'name',
      'unprefixedUnindexedName',
      'formName',
      'formSchema',
      't',
      'exampleValue',
    ]
  ),
  addProps(
    ({fieldSchema: {isRequired: required}, id}) => ({
      required,
      'data-testid': id,
    }),
    ['fieldSchema', 'id']
  ),
  addProps(({shouldTrackFieldsUpdatedAt}) => ({
    showConfirmButton: shouldTrackFieldsUpdatedAt,
  })),
  addProps(
    ({formFieldStatuses, name}) => ({
      status: getFormFeedbackIconStatus({
        formFieldStatuses,
        name,
      }),
    }),
    ['formFieldStatuses', 'name']
  ),
  addFormSectionContext,
  addEffectOnMount(
    ({name, registerFieldToFormSection, formSectionName}) => () => {
      if (!formSectionName) return
      registerFieldToFormSection({
        fieldName: name,
        sectionName: formSectionName,
      })
    }
  ),
  removeProps([
    'formName',
    'formSchema',
    't',
    'formFieldStatuses',
    'formSectionName',
    'registerFieldToFormSection',
    'formSections',
    'scrollToFormSection',
    'addFormSectionRef',
    'unindexedName',
    'unprefixedUnindexedName',
    'shouldTrackFieldsUpdatedAt',
    'toggleFreshnessConfirmed',
    'setCollectionItemFieldsFreshness',
    'removeCollectionItemFieldsFreshness',
    'setCollectionItemFieldsUpdatedAt',
    'removeCollectionItemFieldsUpdatedAt',
  ])
)

type AddFieldInfoWithMemoBoundaryType = <
  TProps extends {
    name: string
    labelTranslationKey?: string
    exampleValue: any
  }
>(
  propNames: string[]
) => (
  props: TProps
) => Omit<
  Omit<TProps, 'formContextValue'>,
  | 'formName'
  | 'formSchema'
  | 't'
  | 'formSectionName'
  | 'registerFieldToFormSection'
  | 'formSections'
  | 'scrollToFormSection'
  | 'addFormSectionRef'
  | 'scrollToFormField'
  | 'addFormFieldRef'
  | 'unindexedName'
  | 'unprefixedUnindexedName'
  | 'shouldTrackFieldsUpdatedAt'
  | 'toggleFreshnessConfirmed'
  | 'setCollectionItemFieldsFreshness'
  | 'removeCollectionItemFieldsFreshness'
  | 'setCollectionItemFieldsUpdatedAt'
  | 'removeCollectionItemFieldsUpdatedAt'
> & {
  label: string
  fieldSchema: FieldType<any>
  required: boolean
  'data-testid': string
  name: string
  helperText: string | undefined
  placeholder: string | undefined
  showConfirmButton: boolean | undefined
  callbackRef: (element: HTMLElement | null) => void
}

export const addFieldInfoWithMemoBoundary: AddFieldInfoWithMemoBoundaryType = (
  propNames
) =>
  flowMax(
    addFormContext,
    addMemoBoundary([
      ...propNames,
      'formName',
      'formSchema',
      'shouldTrackFieldsUpdatedAt',
    ]),
    addTranslationHelpers,
    addFieldNames,
    addProps(
      ({
        unindexedName,
        name,
        unprefixedUnindexedName,
        formName,
        formSchema: schema,
        t,
        exampleValue,
      }) => ({
        label: t(`${formName}.fieldLabels.${unprefixedUnindexedName}`),
        placeholder: i18n.exists(
          `${formName}.fieldPlaceholders.${unprefixedUnindexedName}`
        )
          ? t(`${formName}.fieldPlaceholders.${unprefixedUnindexedName}`)
          : undefined,
        helperText: i18n.exists(
          `${formName}.fieldHelperTexts.${unprefixedUnindexedName}`
        )
          ? t(`${formName}.fieldHelperTexts.${unprefixedUnindexedName}`)
          : undefined,
        fieldSchema: extractFieldSchema<typeof exampleValue>({
          name: unindexedName,
          schema,
        }),
        id: `${formName}-${name}-field`,
      }),
      [
        'unindexedName',
        'name',
        'unprefixedUnindexedName',
        'formName',
        'formSchema',
        't',
        'exampleValue',
      ]
    ),
    addProps(
      ({fieldSchema: {isRequired: required}, id}) => ({
        required,
        'data-testid': id,
      }),
      ['fieldSchema', 'id']
    ),
    addProps(({shouldTrackFieldsUpdatedAt}) => ({
      showConfirmButton: shouldTrackFieldsUpdatedAt,
    })),
    addFormSectionContext,
    addEffectOnMount(
      ({name, registerFieldToFormSection, formSectionName}) => () => {
        if (!formSectionName) return
        registerFieldToFormSection({
          fieldName: name,
          sectionName: formSectionName,
        })
      }
    ),
    addHandlers({
      callbackRef: ({addFormFieldRef, formSectionName, name}) => (
        element: HTMLElement | null
      ) => {
        if (!(element && formSectionName && name)) return
        addFormFieldRef(formSectionName, name, element)
      },
    }),
    removeProps([
      'formName',
      'formSchema',
      't',
      'formSectionName',
      'registerFieldToFormSection',
      'formSections',
      'scrollToFormSection',
      'addFormSectionRef',
      'scrollToFormField',
      'addFormFieldRef',
      'unindexedName',
      'unprefixedUnindexedName',
      'shouldTrackFieldsUpdatedAt',
      'toggleFreshnessConfirmed',
      'setCollectionItemFieldsFreshness',
      'removeCollectionItemFieldsFreshness',
      'setCollectionItemFieldsUpdatedAt',
      'removeCollectionItemFieldsUpdatedAt',
    ])
  )

interface Props<TFieldData> extends ComponentProps<typeof FormikField> {
  component: ComponentType<any>
  labelTranslationKey?: string
  exampleValue: TFieldData
  name: string
  omitProps?: string[]
  noIcon?: boolean
  noConfirmButton?: boolean
  lazyIcon?: boolean
  noFieldScrollTarget?: boolean
}

type FieldComponentType = <TFieldData>(
  props: Props<TFieldData>
) => ReactElement<any, any> | null

const Field: FieldComponentType = flowMax(
  addDisplayName('Field'),
  addMemoBoundary([]),
  addFieldInfoWithMemoBoundary([]),
  addClasses(classes),
  removeProps(['fieldSchema', 'exampleValue']),
  addWrapper(
    (
      render,
      {
        classes,
        noIcon,
        name,
        className,
        lazyIcon,
        callbackRef,
        noFieldScrollTarget,
      }
    ) => (
      <div className={cx(classes.container, className)}>
        {!noFieldScrollTarget && <FieldScrollTarget ref={callbackRef} />}
        {noIcon ? (
          render()
        ) : (
          <FormFieldWithFeedbackIconContainer name={name} lazy={lazyIcon}>
            {render()}
          </FormFieldWithFeedbackIconContainer>
        )}
      </div>
    )
  ),
  addWrapper((render, {showConfirmButton, noConfirmButton, name, lazyIcon}) =>
    showConfirmButton && !noConfirmButton ? (
      <>
        {render()}
        <FormFieldConfirmFreshnessButton
          name={name}
          anchored
          lazyIcon={lazyIcon}
        />
      </>
    ) : (
      render()
    )
  ),
  addProps({
    fullWidth: true,
  }),
  removeProps([
    'lazyIcon',
    'noIcon',
    'showConfirmButton',
    'noConfirmButton',
    'classes',
    'className',
    'callbackRef',
    'noFieldScrollTarget',
  ]),
  ({component, omitProps = [], ...props}) => (
    <FormikField
      component={component}
      variant="outlined"
      {...omit(omitProps)(props)}
    />
  )
)

export default Field
