import React, {FC} from 'react'
import {flowMax, addDisplayName} from 'ad-hok'
import {generatePath} from 'react-router'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {makeClasses, addClasses, sharedStyles} from 'theme'
import Grid from 'components/Grid'
import Heading from 'components/Heading'
import SubmitButton from 'components/SubmitButton'
import Caption from 'components/Caption'
import {getLongDate, getFormattedDateOfBirth} from 'utils/date'
import AccountTitle from 'components/AccountTitle'
import {addTranslationHelpers} from 'utils/i18n'
import DisplayItem from 'components/DisplayItem'
import {orUnknown} from 'utils/string'
import {getFormattedSsn} from 'utils/ssn'
import {getPhoneSummary} from 'utils/phone'
import {
  getFormattedMailingAddress,
  getFormattedHomeAddress,
} from 'utils/address'
import Link from 'components/Link'
import {editPersonPath} from 'components/TopLevelRoutes'
import TimeAgo from 'components/TimeAgo'
import addDialogState from 'utils/addDialogState'
import Button from 'components/Button'
import {addAccountFormLeftColumnContextProvider} from 'components/EditAccountForm/accountFormLeftColumnContext'
import Accordion from 'components/Accordion'
import AccordionSummary from 'components/AccordionSummary'
import Body1 from 'components/Body1'
import AccordionDetails from 'components/AccordionDetails'
import ReparentAccountDialog from 'components/EditAccountForm/ReparentAccountDialog'
import {Account_account} from 'graphql/deserializedTypes/Account'

const classes = makeClasses((theme) => ({
  container: {
    ...sharedStyles.formLeftColumnContainer,
    width: 350,
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    paddingRight: theme.spacing(2),
    flexWrap: 'nowrap',
  },
  titleContainer: {
    marginBottom: theme.spacing(2),
  },
  title: {
    lineHeight: '20px',
  },
  firstDisplayItem: {
    marginTop: theme.spacing(2),
  },
  profileLinkIcon: {
    marginLeft: theme.spacing(1),
  },
  profileLink: {
    marginTop: theme.spacing(1),
  },
  adminContainer: {
    marginTop: theme.spacing(2),
  },
}))

const ReparentAccountButton: FC = flowMax(
  addDisplayName('ReparentAccountButton'),
  addDialogState,
  addTranslationHelpers,
  ({showDialog, isShowingDialog, hideDialog, t}) => (
    <>
      <Button color="primary" onClick={showDialog}>
        {t('accountForm.reparentButton')}
      </Button>
      <ReparentAccountDialog open={isShowingDialog} onClose={hideDialog} />
    </>
  )
)

interface Props {
  account: Account_account
}

const AccountFormLeftColumn: FC<Props> = flowMax(
  addDisplayName('AccountFormLeftColumn'),
  addAccountFormLeftColumnContextProvider,
  addClasses(classes),
  addTranslationHelpers,
  ({
    account: {
      person: {ssn, dob, phoneNumbers, preferredLanguage},
      person,
    },
    account,
    classes,
    t,
  }) => (
    <Grid container direction="column" className={classes.container}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        className={classes.titleContainer}
      >
        <Heading variant="h6" className={classes.title}>
          <AccountTitle account={account} />
        </Heading>
        <SubmitButton testId="submit-account-form" />
      </Grid>
      <Caption>
        {t('accountForm.created', {
          time: getLongDate(account.createdAt),
        })}
      </Caption>
      <TimeAgo time={account.updatedAt} />
      <DisplayItem
        className={classes.firstDisplayItem}
        i18nKey="applicationForm.personIdDisplay"
        translations={{
          id: orUnknown(t)(person.id),
        }}
      />
      <DisplayItem
        i18nKey="applicationForm.ssnDisplay"
        translations={{
          ssn: getFormattedSsn({ssn, t}),
        }}
      />
      <DisplayItem
        i18nKey="applicationForm.dobDisplay"
        translations={{
          dob: getFormattedDateOfBirth({dob, t}),
        }}
      />
      <DisplayItem
        i18nKey="applicationForm.phoneDisplay"
        translations={{
          phone: getPhoneSummary({
            phoneNumbers,
            t,
          }),
        }}
      />
      <DisplayItem
        i18nKey="applicationForm.mailingAddressDisplay"
        translations={{
          address: getFormattedMailingAddress({
            ...person,
            t,
          }),
        }}
      />
      <DisplayItem
        i18nKey="applicationForm.homeAddressDisplay"
        translations={{
          address: getFormattedHomeAddress({
            ...person,
            t,
          }),
        }}
      />
      <DisplayItem
        i18nKey="applicationForm.preferredLanguageDisplay"
        translations={{
          language: orUnknown(t)(preferredLanguage),
        }}
      />
      <Link
        highlight
        target="_blank"
        to={generatePath(editPersonPath, {id: person.id})}
        className={classes.profileLink}
      >
        <Grid container direction="row" alignItems="center">
          {t('applicationForm.editPersonLink')}
          <OpenInNewIcon className={classes.profileLinkIcon} fontSize="small" />
        </Grid>
      </Link>
      <Accordion className={classes.adminContainer}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Body1>{t('accountForm.admin')}</Body1>
        </AccordionSummary>
        <AccordionDetails>
          <ReparentAccountButton />
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
)

export default AccountFormLeftColumn
