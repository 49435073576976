import {
  makeStyles,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core/styles'
import {Styles, ClassNameMap} from '@material-ui/styles/withStyles'
import {Theme} from '@material-ui/core'
import {CurriedPropsAdder, addProps} from 'ad-hok'

import {APP_BAR_HEIGHT, CONTAINER_MARGIN_TOP} from 'config/layout'
import {formColumnWidth} from 'components/Form'

const themeObject = {}

const theme = createMuiTheme(themeObject)

export default theme

type AddClassesType = <TProps extends {}, ClassKey extends string>(
  classes: Styles<Theme, TProps, ClassKey>
) => CurriedPropsAdder<TProps, {classes: ClassNameMap<ClassKey>}>

export const addClasses: AddClassesType = (styles) => {
  const useStyles = makeStyles(styles)
  return addProps((props) => ({classes: useStyles(props)}))
}

type MakeClassesType = <TClasses extends Styles<Theme, any>>(
  classes: TClasses
) => TClasses

export const makeClasses: MakeClassesType = (x) => x

export const sharedStyles = makeClasses({
  formColumnsContainer: {
    alignSelf: 'center',
    width: 'auto',
    marginTop: CONTAINER_MARGIN_TOP,
    height: `calc(100vh - ${APP_BAR_HEIGHT}px - ${CONTAINER_MARGIN_TOP}px)`,
    overflowY: 'hidden',
  },
  formColumnContainer: {
    width: formColumnWidth,
    height: '100%',
    overflowY: 'auto',
    paddingRight: theme.spacing(3),
  },
  formRightColumnContainer: {
    width: 450,
    marginLeft: theme.spacing(1),
    height: '100%',
    overflowY: 'auto',
  },
  formLeftColumnContainer: {
    width: 300,
    marginRight: theme.spacing(1),
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingBottom: theme.spacing(5),
  },
})
