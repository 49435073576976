/* eslint-disable */
import {PeopleWithUnreadTextMessages as PeopleWithUnreadTextMessagesOrig} from '../types/PeopleWithUnreadTextMessages'
import {PeopleWithUnreadTextMessages_people as PeopleWithUnreadTextMessages_peopleOrig} from '../types/PeopleWithUnreadTextMessages'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: PeopleWithUnreadTextMessages
// ====================================================
export interface PeopleWithUnreadTextMessages_people {
  __typename: 'Person'
  id: string
}
export const deserializePeopleWithUnreadTextMessages_people = (
  serialized: PeopleWithUnreadTextMessages_peopleOrig
): PeopleWithUnreadTextMessages_people => ({...serialized})
export const serializePeopleWithUnreadTextMessages_people = (
  deserialized: PeopleWithUnreadTextMessages_people
): PeopleWithUnreadTextMessages_peopleOrig => ({...deserialized})
export interface PeopleWithUnreadTextMessages {
  /**
   * List of people
   */
  people: PeopleWithUnreadTextMessages_people[]
}
export const deserializePeopleWithUnreadTextMessages = (
  serialized: PeopleWithUnreadTextMessagesOrig
): PeopleWithUnreadTextMessages => ({
  ...serialized,
  people: serialized.people.map(deserializePeopleWithUnreadTextMessages_people),
})
export const serializePeopleWithUnreadTextMessages = (
  deserialized: PeopleWithUnreadTextMessages
): PeopleWithUnreadTextMessagesOrig => ({
  ...deserialized,
  people: deserialized.people.map(serializePeopleWithUnreadTextMessages_people),
})
