import * as Yup from 'yup'
import {TFunction} from 'i18next'
import {mapValues} from 'lodash'

import {FieldType} from 'utils/form/fieldTypes'
import {
  FormSchemaFields,
  FormSchema,
  FormSchemaObjectFields,
  FormSchemaValue,
  isField,
  isFormCollection,
  extractFormCollectionFields,
} from 'utils/form/schema'

const getFieldValidator = ({
  field,
  t,
}: {
  field: FieldType<any>
  t: TFunction
}) => {
  let validator: any
  switch (field.type) {
    case 'datetime':
    case 'date':
      validator = Yup.date()
      break
    case 'boolean':
      validator = Yup.boolean().nullable()
      if (field.validatorTest)
        validator = validator.test(
          'custom-test',
          t(field.validatorTest[0]),
          field.validatorTest[1]
        )
      break
    default: {
      validator = Yup.string()
      if (field.type === 'email') validator = validator.email()
      if (field.validRegex)
        validator = validator.matches(field.validRegex, t('form.invalidFormat'))
      if (field.validatorTest)
        validator = validator.test(
          'custom-test',
          t(field.validatorTest[0]),
          field.validatorTest[1]
        )
      if (field.validatorTests) {
        validator = field.validatorTests.reduce(
          (validator, validatorTest, index) =>
            validator.test(
              `custom-test-${index}`,
              t(validatorTest[0]),
              validatorTest[1]
            ),
          validator
        )
      }
      if (field.validator) validator = field.validator({t})
    }
  }
  if (field.isRequired) validator = validator.required(t('form.required'))
  return validator
}

type GetValidationSchemaType = <TFields extends FormSchemaFields>(options: {
  schema: FormSchema<TFields>
  t: TFunction
}) => Yup.ObjectSchema<object>

export const getValidationSchema: GetValidationSchemaType = ({schema, t}) =>
  Yup.object(
    mapValues(schema.fields, (objectSchema) =>
      getObjectValidationSchema({schema: objectSchema, t})
    ) as any
  )

type GetObjectValidationSchemaType = <
  TFields extends FormSchemaObjectFields
>(options: {
  schema: TFields
  t: TFunction
}) => Yup.ObjectSchema<object>

const getObjectValidationSchema: GetObjectValidationSchemaType = ({
  schema: objectSchema,
  t,
}) =>
  Yup.object(
    mapValues(objectSchema, (schema: FormSchemaValue) => {
      if (isField(schema)) {
        return getFieldValidator({field: schema, t})
      }
      if (isFormCollection(schema)) {
        return Yup.array().of(
          getObjectValidationSchema({
            schema: extractFormCollectionFields(schema),
            t,
          })
        )
      }
      return getObjectValidationSchema({schema, t})
    }) as any
  )
