import React from 'react'
import {
  flowMax,
  addDisplayName,
  branch,
  renderNothing,
  addStateHandlers,
  addHandlers,
  addRef,
} from 'ad-hok'
import PersonIcon from '@material-ui/icons/Person'

import {addTranslationHelpers} from 'utils/i18n'
import IconButton from 'components/IconButton'
import Menu from 'components/Menu'
import MenuItem from 'components/MenuItem'
import {addAuth0Context, addSignOut} from 'utils/auth0'

const accountMenuId = 'account-menu'

const AccountMenu = flowMax(
  addDisplayName('AccountMenu'),
  addAuth0Context,
  branch(
    ({auth0Context: {isAuthenticated}}) => !isAuthenticated,
    renderNothing()
  ),
  addStateHandlers(
    {
      isAccountMenuOpen: false,
    },
    {
      openAccountMenu: () => () => ({isAccountMenuOpen: true}),
      closeAccountMenu: () => () => ({isAccountMenuOpen: false}),
    }
  ),
  addSignOut,
  addHandlers({
    onLogoutClick: ({closeAccountMenu, signOut}) => () => {
      closeAccountMenu()
      signOut()
    },
  }),
  addRef('buttonRef', null as HTMLButtonElement | null),
  addTranslationHelpers,
  ({
    isAccountMenuOpen,
    openAccountMenu,
    closeAccountMenu,
    onLogoutClick,
    buttonRef,
    t,
  }) => (
    <>
      <IconButton
        ref={buttonRef}
        aria-controls={accountMenuId}
        aria-haspopup="true"
        onClick={openAccountMenu}
        color="inherit"
      >
        <PersonIcon />
      </IconButton>
      <Menu
        id={accountMenuId}
        anchorEl={buttonRef.current}
        keepMounted
        open={isAccountMenuOpen}
        onClose={closeAccountMenu}
      >
        <MenuItem onClick={onLogoutClick}>{t('logout.logout')}</MenuItem>
      </Menu>
    </>
  )
)

export default AccountMenu
