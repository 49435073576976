import React from 'react'
import * as Sentry from '@sentry/browser'

import {sentryEnabled} from 'config/sentry'

interface ErrorBoundaryProps {
  renderFallback: (
    error: Error | null,
    onResolvedError: () => void
  ) => JSX.Element
}

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {hasError: false, error: null}
  }

  static getDerivedStateFromError(error: Error) {
    return {hasError: true, error}
  }

  componentDidCatch(error: Error | null /*errorInfo: object*/) {
    if (sentryEnabled) Sentry.captureException(error)
  }

  onResolvedError = () => {
    this.setState({hasError: false, error: null})
  }

  render() {
    const {renderFallback, children} = this.props
    const {hasError, error} = this.state

    if (hasError) {
      return renderFallback(error, this.onResolvedError)
    }

    return children
  }
}

export default ErrorBoundary
