import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addStateHandlers,
  addHandlers,
  addProps,
  addDefaultProps,
} from 'ad-hok'
import {isFunction} from 'lodash/fp'

import {addTranslationHelpers} from 'utils/i18n'
import {StandaloneSelectField} from 'components/SelectField'
import CloseReasonDialog, {
  closeReasonDialogSchema,
} from 'components/CloseReasonDialog'
import ConfirmationDialog from 'components/ConfirmationDialog'
import {ValidatorTest} from 'utils/form/fieldTypes'
import {OnSubmitSuccessOptions} from 'components/Form'
import {ExtractFormSchemaFields} from 'utils/form/schema'

export interface StatusOption {
  label: string
  value: string
  closeReasonOptions?: string[]
  confirm?: boolean
}

const getStatusOption = ({
  status,
  statusOptions,
}: {
  status: string
  statusOptions: StatusOption[]
}): StatusOption | undefined =>
  statusOptions.find(({value}) => value === status)

const getCloseReasonOptions = ({
  status,
  statusOptions,
}: {
  status: string
  statusOptions: StatusOption[]
}): string[] => {
  const statusOption = getStatusOption({status, statusOptions})
  if (!statusOption) return []
  return statusOption.closeReasonOptions ?? []
}

const getConfirmStatus = ({
  status,
  statusOptions,
}: {
  status: string
  statusOptions: StatusOption[]
}): boolean => {
  const statusOption = getStatusOption({status, statusOptions})
  return !!statusOption && !!statusOption.confirm
}

export interface StatusUpdateMutateUpdates {
  status: string
  closeReason?: string
  closeComment?: string | null
}

interface Props {
  id: string
  updateFailedMessage: string
  status: string
  statusOptions: StatusOption[]
  mutate: (opts: StatusUpdateMutateUpdates) => Promise<any>
  className?: string
  rejectSelect?: (opts: {status: string}) => boolean
  confirmationDialogTitleTranslationKey?: string | ((status: string) => string)
  closeReasonValidator?: ValidatorTest
}

const StatusUpdateSelect: FC<Props> = flowMax(
  addDisplayName('StatusUpdateSelect'),
  addDefaultProps({
    confirmationDialogTitleTranslationKey: 'statusSelect.confirmDialog.title',
  }),
  addStateHandlers(
    ({status}) => ({
      status,
      lastStatus: status,
    }),
    {
      setStatus: ({status}) => (newStatus: string) => ({
        status: newStatus,
        lastStatus: status,
      }),
      revertToLastStatus: ({lastStatus}) => () => ({
        status: lastStatus!,
      }),
    }
  ),
  addProps(({status, statusOptions}) => ({
    closeReasonOptions: getCloseReasonOptions({status, statusOptions}),
  })),
  addTranslationHelpers,
  addHandlers({
    updateStatus: ({mutate, revertToLastStatus, updateFailedMessage}) => (
      updates: StatusUpdateMutateUpdates
    ) =>
      mutate(updates).catch(() => {
        revertToLastStatus()
        window.alert(updateFailedMessage)
      }),
  }),
  addStateHandlers(
    {
      isShowingCloseReasonDialog: false,
    },
    {
      showCloseReasonDialog: () => () => ({isShowingCloseReasonDialog: true}),
      hideCloseReasonDialog: () => () => ({isShowingCloseReasonDialog: false}),
    }
  ),
  addStateHandlers(
    {
      isShowingConfirmationDialog: false,
    },
    {
      showConfirmationDialog: () => () => ({isShowingConfirmationDialog: true}),
      hideConfirmationDialog: () => () => ({
        isShowingConfirmationDialog: false,
      }),
    }
  ),
  addHandlers({
    onStatusChange: ({
      setStatus,
      updateStatus,
      showCloseReasonDialog,
      showConfirmationDialog,
      statusOptions,
      revertToLastStatus,
      rejectSelect,
    }) => (event: React.ChangeEvent<{value: unknown}>) => {
      const status = event.target.value as string
      setStatus(status)

      if (rejectSelect?.({status})) {
        revertToLastStatus()
        return
      }

      const closeReasonOptions = getCloseReasonOptions({status, statusOptions})
      if (closeReasonOptions.length > 0) {
        showCloseReasonDialog()
        return
      }

      const confirm = getConfirmStatus({status, statusOptions})
      if (confirm) {
        showConfirmationDialog()
        return
      }
      updateStatus({status})
    },
    closeCloseReasonDialogWithoutSaving: ({
      hideCloseReasonDialog,
      revertToLastStatus,
    }) => () => {
      revertToLastStatus()
      hideCloseReasonDialog()
    },
    saveCloseReasonDialog: ({hideCloseReasonDialog, status, updateStatus}) => ({
      canonicalValues: {record},
    }: OnSubmitSuccessOptions<
      ExtractFormSchemaFields<typeof closeReasonDialogSchema>
    >) => {
      updateStatus({...record, status})
      hideCloseReasonDialog()
    },
    closeConfirmationDialogWithoutConfirming: ({
      revertToLastStatus,
      hideConfirmationDialog,
    }) => () => {
      revertToLastStatus()
      hideConfirmationDialog()
    },
    confirmStatusChange: ({
      hideConfirmationDialog,
      status,
      updateStatus,
    }) => () => {
      updateStatus({status})
      hideConfirmationDialog()
    },
  }),
  ({
    id,
    status,
    onStatusChange,
    statusOptions,
    isShowingCloseReasonDialog,
    closeCloseReasonDialogWithoutSaving,
    saveCloseReasonDialog,
    closeReasonOptions,
    closeReasonValidator,
    isShowingConfirmationDialog,
    closeConfirmationDialogWithoutConfirming,
    confirmStatusChange,
    confirmationDialogTitleTranslationKey,
    t,
    className,
  }) => (
    <>
      <StandaloneSelectField
        value={status}
        onChange={onStatusChange}
        id={id}
        label={t('statusSelect.label')}
        options={statusOptions}
        className={className}
      />
      <CloseReasonDialog
        closeReasonOptions={closeReasonOptions}
        closeReasonValidator={closeReasonValidator}
        open={isShowingCloseReasonDialog}
        onClose={closeCloseReasonDialogWithoutSaving}
        onSave={saveCloseReasonDialog}
      />
      <ConfirmationDialog
        title={t(
          isFunction(confirmationDialogTitleTranslationKey)
            ? confirmationDialogTitleTranslationKey(status)
            : confirmationDialogTitleTranslationKey,
          {status}
        )}
        cancelText={t('statusSelect.confirmDialog.cancel')}
        confirmText={t('statusSelect.confirmDialog.confirm')}
        open={isShowingConfirmationDialog}
        onCancel={closeConfirmationDialogWithoutConfirming}
        onConfirm={confirmStatusChange}
      />
    </>
  )
)

export default StatusUpdateSelect
