import React from 'react'
import {
  flowMax,
  addHandlers,
  addProps,
  addState,
  addDisplayName,
  addEffect,
  addStateHandlers,
} from 'ad-hok'
import {FC} from 'react'

import {addTranslationHelpers} from 'utils/i18n'
import {addClasses, makeClasses} from 'theme'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogContent from 'components/DialogContent'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import {addCreateLighticoWorkflowsMutation} from 'graphql/generated'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import {addConfigContext} from 'utils/configContext'
import Checkbox from 'components/Checkbox'
import FormControlLabel from 'components/FormControlLabel'

const classes = makeClasses(() => ({
  contentContainer: {
    width: 600,
  },
}))

interface Props {
  esignSessionId: string
  open: boolean
  onClose: () => void
}

const CreateLighticoWorkflowsDialog: FC<Props> = flowMax(
  addDisplayName('CreateLighticoWorkflowDialog'),
  addAppSnackbarContext,
  addTranslationHelpers,
  addConfigContext,
  addProps(({config}) => ({
    workflows: config.lighticoWorkflows,
  })),
  addStateHandlers(
    {
      workflowIds: new Set<string>(),
    },
    {
      toggleWorkflowId: ({workflowIds}) => (workflowId: string) => {
        const newWorkflowIds = new Set(workflowIds)
        if (workflowIds.has(workflowId)) {
          newWorkflowIds.delete(workflowId)
        } else {
          newWorkflowIds.add(workflowId)
        }

        return {
          workflowIds: newWorkflowIds,
        }
      },
      clearWorkflowIds: () => () => ({
        workflowIds: new Set(),
      }),
    }
  ),
  addEffect(
    ({clearWorkflowIds, open}) => () => {
      if (open) {
        clearWorkflowIds()
      }
    },
    ['open']
  ),
  addState('isSubmitting', 'setIsSubmitting', false),
  addCreateLighticoWorkflowsMutation({}),
  addHandlers({
    onSave: ({
      esignSessionId,
      workflowIds,
      mutateCreateLighticoWorkflows,
      showSnackbarMessage,
      setIsSubmitting,
      onClose,
      t,
    }) => () => {
      setIsSubmitting(true)
      mutateCreateLighticoWorkflows({
        variables: {esignSessionId, workflowIds: Array.from(workflowIds)},
      })
        .then(() => {
          showSnackbarMessage(
            t('applicationForm.createLighticoWorkflowDialog.saved')
          )
          onClose()
        })
        .catch(() => {
          window.alert(
            t('applicationForm.createLighticoWorkflowDialog.failedToSave')
          )
        })
        .then(() => {
          setIsSubmitting(false)
        })
    },
  }),
  addClasses(classes),
  ({
    open,
    onClose,
    onSave,
    isSubmitting,
    workflows,
    workflowIds,
    toggleWorkflowId,
    classes,
    t,
  }) => (
    <Dialog open={open} onClose={onClose} scroll="paper">
      <DialogTitle>
        {t('applicationForm.createLighticoWorkflowDialog.title')}
      </DialogTitle>
      <DialogContent className={classes.contentContainer} dividers>
        {workflows.map((workflow) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={workflowIds.has(workflow.id)}
                onChange={() => toggleWorkflowId(workflow.id)}
              />
            }
            label={workflow.name}
            key={workflow.id}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('applicationForm.createLighticoWorkflowDialog.cancel')}
        </Button>
        <Button
          onClick={onSave}
          variant="contained"
          color="primary"
          disabled={isSubmitting || workflowIds.size === 0}
        >
          {t('applicationForm.createLighticoWorkflowDialog.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
)

export default CreateLighticoWorkflowsDialog
