import React, {FC, Fragment} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addHandlers,
  addWrapper,
  AddWrapperRenderCallback,
  addMemoBoundary,
} from 'ad-hok'
import {addPropIdentityStabilization} from 'ad-hok-utils'
import {
  flow,
  omit,
  map,
  flatMap,
  filter,
  fromPairs,
  isEqual,
  first,
  keys,
} from 'lodash/fp'
import {range, forIn} from 'lodash'

import {FormValues, ExtractFormSchemaFields} from 'utils/form/schema'
import {editPersonFormSchema} from 'components/EditPersonForm/schema'
import {RequiredDocument} from 'components/EditPersonForm/documents'
import {addClasses, makeClasses, sharedStyles} from 'theme'
import {addTranslationHelpers} from 'utils/i18n'
import Grid from 'components/Grid'
import {
  addFormContext,
  FormFieldStatuses,
  FormSections,
  FormSection as FormSectionType,
  FormFieldStatus,
  addFormFieldStatusesContext,
  FormFieldStatusNonempty,
} from 'utils/form/context'
import ListItem from 'components/ListItem'
import ListItemText from 'components/ListItemText'
import FormFeedbackIcon, {
  getFormFeedbackIconStatus,
} from 'components/FormFeedbackIcon'
import {addFormikTyped} from 'utils/form/formik'
import {getNumOpenApplications} from 'components/EditPersonForm/relationships'
import {getExtendedName} from 'utils/name'
import Subtitle2 from 'components/Subtitle2'
import List from 'components/List'
import Divider from 'components/Divider'
import Heading from 'components/Heading'
import FieldArray from 'components/FieldArray'
import NecessaryItemsCount from 'components/NecessaryItemsCount'
import FormSectionLinkManual from 'components/FormSectionLinkManual'
import TimeAgo from 'components/TimeAgo'
import {addDocumentsSectionContext} from 'components/EditPersonForm/documentsSectionContext'
import SplitButton from 'components/SplitButton'
import addDialogState from 'utils/addDialogState'
import AddExistingPersonDialog from 'components/EditPersonForm/AddExistingPersonDialog'
import {
  addLeftColumnContextProvider,
  addLeftColumnContext,
} from 'components/EditPersonForm/leftColumnContext'
import {getInitialValues} from 'utils/form/getValues'
import Button from 'components/Button'
import {callWith, mapValuesWithKey, mapKeysWithKey} from 'utils/fp'
import typedAs from 'utils/typedAs'
import {
  VIEWING_RELATIONSHIP_INDEX_CLIENT,
  VIEWING_RELATIONSHIP_INDEX_DOCUMENTS,
} from 'components/EditPersonForm/viewingRelationshipIndex'
import {Person_person} from 'graphql/deserializedTypes/Person'
import IconButton from 'components/IconButton'
import addEffectInDevelopment from 'utils/addEffectInDevelopment'
import ProfileSummaryButton from 'components/EditPersonForm/ProfileSummaryButton'

const classes = makeClasses((theme) => ({
  container: sharedStyles.formLeftColumnContainer,
  submitButtonContainer: {
    marginBottom: theme.spacing(1),
  },
  topRowContainer: {
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  personContainer: {
    marginBottom: theme.spacing(2),
  },
  documentSectionLinkContainer: {
    paddingLeft: 0,
    marginBottom: theme.spacing(4),
  },
}))

export interface SectionForLink {
  name: string
  sectionStatus: FormSectionStatus
}

const stripSectionPrefix = (sectionName: string) =>
  sectionName
    .replace(/^person.relationships\[\d+\]\.(otherPerson\.)?/, '')
    .replace(/^person\./, '')

interface SectionStatusIconProps {
  sectionStatus: FormSectionStatusNonempty
  relationshipIndex: number
  name: string
}

const SectionStatusIcon: FC<SectionStatusIconProps> = flowMax(
  addDisplayName('SectionStatusIcon'),
  addFormContext,
  addLeftColumnContext,
  addHandlers({
    onClick: ({
      scrollToFormField,
      sectionStatus: {firstFlaggedFieldName},
      name,
      viewingRelationshipIndex,
      relationshipIndex,
      setViewingRelationshipIndex,
      setScrollToFieldName,
    }) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (relationshipIndex !== viewingRelationshipIndex) {
        setViewingRelationshipIndex(relationshipIndex)
        setScrollToFieldName(name, firstFlaggedFieldName)
      } else {
        scrollToFormField(name, firstFlaggedFieldName)
      }
      event.stopPropagation()
    },
  }),
  ({onClick, sectionStatus: {status}, name}) => (
    <IconButton onClick={onClick}>
      <FormFeedbackIcon
        status={status}
        nameForTestId={`sectionSummary-${name}`}
      />
    </IconButton>
  )
)

interface FormSectionLinkProps {
  section: SectionForLink
  relationshipIndex: number
}

const FormSectionLink: FC<FormSectionLinkProps> = flowMax(
  addDisplayName('FormSectionLink'),
  addTranslationHelpers,
  addFormContext,
  addProps(({section: {name}, formName, t}) => ({
    label: t(`${formName}.sectionLeftColumnLabels.${stripSectionPrefix(name)}`),
  })),
  addLeftColumnContext,
  addHandlers({
    onClick: ({
      scrollToFormSection,
      section: {name},
      viewingRelationshipIndex,
      relationshipIndex,
      setViewingRelationshipIndex,
      setScrollToSectionName,
    }) => () => {
      if (relationshipIndex !== viewingRelationshipIndex) {
        setViewingRelationshipIndex(relationshipIndex)
        setScrollToSectionName(name)
      } else {
        scrollToFormSection(name)
      }
    },
  }),
  ({section: {name, sectionStatus}, label, onClick, relationshipIndex}) => (
    <ListItem button onClick={onClick}>
      <ListItemText primary={label} />
      {!!sectionStatus && (
        <SectionStatusIcon
          sectionStatus={sectionStatus}
          relationshipIndex={relationshipIndex}
          name={name}
        />
      )}
    </ListItem>
  )
)

type FormSectionStatusNonempty = {
  status: FormFieldStatusNonempty
  firstFlaggedFieldName: string
}

type FormSectionStatus = FormSectionStatusNonempty | undefined

const getSectionStatus = ({
  fieldNames,
  formFieldStatuses,
  predeterminedStatuses,
}: {
  fieldNames: string[]
  formFieldStatuses: FormFieldStatuses
  predeterminedStatuses: FormFieldStatuses
}): FormSectionStatus => {
  const sectionFieldStatuses = fieldNames.map((fieldName) =>
    typedAs<[string, FormFieldStatus]>(
      fieldName in predeterminedStatuses
        ? [fieldName, predeterminedStatuses[fieldName]]
        : [fieldName, formFieldStatuses[fieldName]]
    )
  )
  const invalidField = sectionFieldStatuses.find(
    ([, status]) => status === 'invalid'
  )
  if (invalidField)
    return {
      firstFlaggedFieldName: invalidField[0],
      status: 'invalid',
    }
  const necessaryField = sectionFieldStatuses.find(
    ([, status]) => status === 'necessary'
  )
  if (necessaryField)
    return {
      firstFlaggedFieldName: necessaryField[0],
      status: 'necessary',
    }
  return undefined
}

export const getSections = ({
  formFieldStatuses,
  formSections,
}: {
  formFieldStatuses: FormFieldStatuses
  formSections: FormSections
}): SectionForLink[] =>
  Object.entries(formSections).map(
    ([sectionName, {fieldNames, predeterminedStatuses}]) => ({
      name: sectionName,
      sectionStatus: getSectionStatus({
        fieldNames,
        formFieldStatuses,
        predeterminedStatuses,
      }),
    })
  )

const SECTION_FIELDS: {
  [sectionName: string]: string[]
} = {
  relationshipData: ['relationshipType', 'liveTogether'],
  personData: [
    'id',
    'hospitalPatientId',
    'wantsCoverage',
    'firstName',
    'middleName',
    'lastName',
    'suffix',
    'preferredName',
    'phoneNumbers[]',
    'phoneNumbers',
    'email',
    'emailComment',
    'preferredLanguage',
    'dob',
    'gender',
    'race',
    'ssn',
    'homeAddressStreet',
    'homeAddressCity',
    'homeAddressState',
    'homeAddressZip',
    'homeAddressComment',
    'addressSinceMonth',
    'addressSinceYear',
    'mailingAddressStreet',
    'mailingAddressCity',
    'mailingAddressState',
    'mailingAddressZip',
    'mailingAddressComment',
  ],
  insuranceCoverage: [
    'hasInsurance',
    'currentInsuranceType',
    'currentInsuranceIncludesDental',
    'currentInsuranceName',
    'currentInsurancePolicyNo',
    'insuranceStartdate',
  ],
  ci: [
    'livesInNj',
    'livedInNjSinceMonth',
    'livedInNjSinceYear',
    'njResidencyDoc',
    'homelessSinceMonth',
    'homelessSinceYear',
    'lastKnownAddressStreet',
    'lastKnownAddressCity',
    'lastKnownAddressState',
    'lastKnownAddressZip',
    'othersAttestationWho',
    'othersAttestationRelationship',
    'othersAttestationPhone',
    'ciStatus',
    'pendingUsCitizenship',
    'fiveYearsInUs',
    'specialCategory',
    'ciDocumentType',
    'ciDocumentName',
    'dateOfEntry',
    'uscisNo',
    'prCardNo',
    'maritalStatus',
    'separatedSinceMonth',
    'separatedSinceYear',
    'dontLiveWithSpouse',
    'noJointAssetsWithSpouse',
    'noFinancialSupportFromSpouse',
    'pregnant',
    'dueDate',
    'expectedChild',
    'spouseLiveWithMe',
    'spousePregnant',
    'isStudent',
  ],
  tax: [
    'taxFilingStatus',
    'notFiledJointlySince',
    'dependentOfTaxFilerId',
    'claimedBySomeoneOtherThanParents',
    'livingWithParentsWhoDoNotFileJointly',
    'reasonNotFiling',
    'estTaxDependentCount',
    'taxDependentInclSpouseLiveWithClient',
    'estParentLiveWithClient',
    'estChildren19LiveWithClient',
    'children18LiveWithClient',
    'estMinor19SiblingLiveWithClient',
    'minor18SiblingLiveWithClient',
    'estHouseholdIncome',
  ],
  incomeAndDeductions: [
    'income',
    'incomeSources[]',
    'changeJobInLast6Mon',
    'noIncomeSinceMonth',
    'noIncomeSinceYear',
    'whoSupportWithoutIncome',
    'selfSupportWithoutIncome',
    'othersSupportWithoutIncome',
    'supporterRelationship',
    'supportSinceMonth',
    'supportSinceYear',
    'supportType',
    'supporterAddressStreet',
    'supporterAddressCity',
    'supporterAddressState',
    'supporterAddressZip',
    'supporterPhone',
    'hasDeduction',
    'deductions[]',
    'hasAsset',
    'assets[]',
    'noAssetSinceMonth',
    'noAssetSinceYear',
    'ownNonresidentProperty',
    'nonresidentPropertyMailingAddressStreet',
    'nonresidentPropertyMailingAddressCity',
    'nonresidentPropertyMailingAddressState',
    'nonresidentPropertyMailingAddressZip',
    'insuranceLast3Months',
    'alaskanNativeamerican',
    'fosterCareAfter18',
    'identityDocument',
    'noIdentityDocReason',
    'desiredMco',
    'pcp',
    'childrenPcp',
  ],
}

const COLLECTION_FIELD_REGEX = /^(.+)\[\]$/
const getSectionFieldNames = ({
  prefix,
  person,
}: {
  prefix: string
  person: unknown
}) => (fieldName: string): string[] => {
  const match = COLLECTION_FIELD_REGEX.exec(fieldName)
  if (!match) return [`${prefix}${fieldName}`]
  const collectionName = match[1]
  const collectionLength =
    ((person as any)[collectionName] as unknown[] | undefined)?.length ?? 0
  return range(collectionLength).map(
    (index) => `${prefix}${collectionName}[${index}]`
  )
}

const getPredeterminedStatuses = ({
  fieldNames,
  prefix,
  person,
  formFieldStatuses,
}: {
  fieldNames: string[]
  prefix: string
  person: unknown
  formFieldStatuses: FormFieldStatuses
}): FormFieldStatuses =>
  callWith(
    fieldNames,
    flow(
      filter((fieldName) => COLLECTION_FIELD_REGEX.test(fieldName)),
      flatMap(getSectionFieldNames({prefix, person})),
      map((collectionPrefix) => [
        collectionPrefix,
        getFormFeedbackIconStatus({
          formFieldStatuses,
          prefix: collectionPrefix,
        }),
      ]),
      fromPairs
    )
  )

const getIsRegisteredPerson = (
  formSections: FormSections,
  expectedOrder: FormSections
) => {
  const firstExpectedKey = first(keys(expectedOrder))!
  return firstExpectedKey in formSections
}

const validateFieldOrder = (
  formSections: FormSections,
  expectedOrder: FormSections
) => {
  if (!getIsRegisteredPerson(formSections, expectedOrder)) return
  forIn(expectedOrder, (expectedSection, sectionName) =>
    validateFieldOrderInSection(sectionName, formSections, expectedSection)
  )
}

// ignore fields ending with [0], [1], etc., because they could be registered out-of-order
const shouldConsiderFieldForValidation = (field: string) =>
  !field.match(/\[\d+\]$/)

const validateFieldOrderInSection = (
  sectionName: string,
  formSections: FormSections,
  expectedSection: FormSectionType
) => {
  const fields = formSections[sectionName]?.fieldNames?.filter(
    shouldConsiderFieldForValidation
  )
  if (!fields) return
  const expectedFields = expectedSection.fieldNames.filter(
    shouldConsiderFieldForValidation
  )

  fields.forEach((field) => {
    if (!expectedFields.includes(field))
      throw new Error(`Unexpected form field for ${sectionName}: ${field}`)
  })

  const intersectingExpectedFields = expectedFields.filter((expectedField) =>
    fields.includes(expectedField)
  )

  if (!isEqual(fields, intersectingExpectedFields)) {
    const firstOutOfOrderFieldIndex = intersectingExpectedFields.findIndex(
      (expectedField, index) => fields[index] !== expectedField
    )
    throw new Error(
      `Out-of-order field for ${sectionName}\n\nExpected:\n${intersectingExpectedFields[firstOutOfOrderFieldIndex]}\n\nGot:\n${fields[firstOutOfOrderFieldIndex]}`
    )
  }
}

interface FormSectionLinksProps {
  relationship?: FormValues<
    ExtractFormSchemaFields<typeof editPersonFormSchema>
  >['person']['relationships'][0]
  relationshipIndex?: number
}

const FormSectionLinks: FC<FormSectionLinksProps> = flowMax(
  addDisplayName('FormSectionLinks'),
  addFormContext,
  addFormFieldStatusesContext,
  addFormikTyped(editPersonFormSchema),
  addProps(({relationship, formik: {values: {person}}}) => ({
    person: relationship?.otherPerson ?? person,
  })),
  addProps(
    ({relationshipIndex, person, formFieldStatuses, formSections}) => ({
      registeredFormSections: formSections,
      formSections: typedAs<FormSections>(
        callWith(
          SECTION_FIELDS,
          relationshipIndex == null
            ? flow(
                omit(['relationshipData']),
                mapValuesWithKey((fieldNames) => ({
                  fieldNames: flatMap(
                    getSectionFieldNames({
                      prefix: 'person.',
                      person,
                    }),
                    fieldNames
                  ),
                  predeterminedStatuses: getPredeterminedStatuses({
                    fieldNames,
                    prefix: 'person.',
                    person,
                    formFieldStatuses,
                  }),
                })),
                mapKeysWithKey((_: any, sectionName) => `person.${sectionName}`)
              )
            : flow(
                mapValuesWithKey((fieldNames, sectionName) => ({
                  fieldNames: flatMap(
                    getSectionFieldNames({
                      prefix: `person.relationships[${relationshipIndex}]${
                        sectionName === 'relationshipData' ? '' : '.otherPerson'
                      }.`,
                      person,
                    }),
                    fieldNames
                  ),
                  predeterminedStatuses: getPredeterminedStatuses({
                    fieldNames,
                    prefix: `person.relationships[${relationshipIndex}]${
                      sectionName === 'relationshipData' ? '' : '.otherPerson'
                    }.`,
                    person,
                    formFieldStatuses,
                  }),
                })),
                mapKeysWithKey(
                  (_: any, sectionName) =>
                    `person.relationships[${relationshipIndex}]${
                      sectionName === 'relationshipData' ? '' : '.otherPerson'
                    }.${sectionName}`
                )
              )
        )
      ),
    }),
    ['relationshipIndex', 'person', 'formFieldStatuses', 'formSections']
  ),
  addEffectInDevelopment(
    ({registeredFormSections, formSections}) => () => {
      validateFieldOrder(registeredFormSections, formSections)
    },
    ['registeredFormSections', 'relationshipIndex']
  ),
  addProps(
    ({formFieldStatuses, formSections}) => ({
      sections: getSections({formFieldStatuses, formSections}),
    }),
    ['formFieldStatuses', 'formSections']
  ),
  addPropIdentityStabilization('sections'),
  addTranslationHelpers,
  addProps(
    ({person, t}) => ({
      name: getExtendedName({...person, t}),
    }),
    ['person', 't']
  ),
  addLeftColumnContext,
  addProps(
    ({relationshipIndex, person}) => ({
      numOpenApplications:
        relationshipIndex != null
          ? getNumOpenApplications({
              relationshipIndex,
              person,
            })
          : 0,
    }),
    ['relationshipIndex', 'person']
  ),
  addMemoBoundary([
    'sections',
    'name',
    'numOpenApplications',
    'relationshipIndex',
  ]),
  addClasses(classes),
  ({sections, name, numOpenApplications, relationshipIndex, classes, t}) => (
    <div className={classes.personContainer}>
      <Subtitle2>
        {name}
        {numOpenApplications > 0 &&
          ` ${t('personForm.relationships.openApplicationsLeftColumn', {
            count: numOpenApplications,
          })}`}
      </Subtitle2>
      <List>
        {sections.map((section, index) => (
          <Fragment key={section.name}>
            <FormSectionLink
              section={section}
              relationshipIndex={
                relationshipIndex ?? VIEWING_RELATIONSHIP_INDEX_CLIENT
              }
            />
            {index !== sections.length - 1 && <Divider variant="middle" />}
          </Fragment>
        ))}
      </List>
    </div>
  )
)

interface DocumentsSectionLinkProps {
  documents: RequiredDocument[]
}

const DocumentsSectionLink: FC<DocumentsSectionLinkProps> = flowMax(
  addDisplayName('DocumentsSectionLink'),
  addDocumentsSectionContext,
  addLeftColumnContext,
  addMemoBoundary(['hasNecessaryDocuments', 'setViewingRelationshipIndex']),
  addHandlers({
    onClick: ({setViewingRelationshipIndex}) => () => {
      setViewingRelationshipIndex(VIEWING_RELATIONSHIP_INDEX_DOCUMENTS)
    },
  }),
  addClasses(classes),
  ({hasNecessaryDocuments, onClick, classes}) => (
    <FormSectionLinkManual
      shouldFlagNecessary={hasNecessaryDocuments}
      name="documents"
      onClick={onClick}
      className={classes.documentSectionLinkContainer}
    />
  )
)

const AddRelatedPersonButton: FC = flowMax(
  addDisplayName('AddRelatedPersonButton'),
  addWrapper(
    (render: AddWrapperRenderCallback<{push: (object: any) => void}>) => (
      <FieldArray
        name="person.relationships"
        render={({push}) => render({push})}
      />
    )
  ),
  addMemoBoundary([]),
  addTranslationHelpers,
  addDialogState,
  addProps(
    ({push, showDialog, t}) => ({
      options: [
        {
          label: t('personForm.addRelationship'),
          onClick: () =>
            push({
              otherPerson: getInitialValues(editPersonFormSchema).person,
              id: '',
              relationshipType: '',
              liveTogether: null,
            }),
        },
        {
          label: t('personForm.addExistingPerson'),
          onClick: showDialog,
        },
      ],
    }),
    ['push', 'showDialog', 't']
  ),
  ({options, isShowingDialog, hideDialog, push}) => (
    <>
      <SplitButton options={options} />
      <AddExistingPersonDialog
        open={isShowingDialog}
        onClose={hideDialog}
        pushRelationship={push}
      />
    </>
  )
)

const SaveButton: FC = flowMax(
  addDisplayName('SaveButton'),
  addLeftColumnContext,
  addTranslationHelpers,
  ({saveAndCheckEligibilities, t}) => (
    <Button
      onClick={saveAndCheckEligibilities}
      color="primary"
      variant="contained"
      data-testid="submit-intake-form"
    >
      {t('personForm.eligibilityCheck.checkButton')}
    </Button>
  )
)

interface LeftColumnProps {
  person: Person_person
  relationships: FormValues<
    ExtractFormSchemaFields<typeof editPersonFormSchema>
  >['person']['relationships']
  documents: RequiredDocument[]
  saveAndCheckEligibilities: () => void
  viewingRelationshipIndex: number
  setViewingRelationshipIndex: (relationshipIndex: number) => void
  setScrollToSectionName: (sectionName: string) => void
  setScrollToFieldName: (sectionName: string, fieldName: string) => void
}

const LeftColumn: FC<LeftColumnProps> = flowMax(
  addDisplayName('LeftColumn'),
  addLeftColumnContextProvider,
  addMemoBoundary(['person', 'relationships', 'documents']),
  addClasses(classes),
  addTranslationHelpers,
  ({person, relationships, documents, classes, t}) => (
    <Grid item className={classes.container}>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.topRowContainer}
      >
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Heading variant="h6">{t('personForm.title')}</Heading>
            <ProfileSummaryButton />
          </Grid>
          <TimeAgo time={person.updatedAt} />
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Grid item className={classes.submitButtonContainer}>
              <SaveButton />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <NecessaryItemsCount
        documents={documents}
        testId="personForm-necessaryItemsCount"
      />
      <FormSectionLinks />
      {relationships.map((relationship, index) => (
        <FormSectionLinks
          relationship={relationship}
          relationshipIndex={index}
          key={relationship.id || `index-${index}`}
        />
      ))}
      <DocumentsSectionLink documents={documents} />
      <AddRelatedPersonButton />
    </Grid>
  )
)

export default LeftColumn
