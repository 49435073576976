import React, {FC} from 'react'
import {flowMax, addDisplayName, addStateHandlers, addHandlers} from 'ad-hok'

import {addTranslationHelpers} from 'utils/i18n'
import Heading from 'components/Heading'
import SearchInput from 'components/SearchInput'
import {makeClasses, addClasses} from 'theme'

const classes = makeClasses((theme) => ({
  container: {
    padding: theme.spacing(2),
    backgroundColor: '#f2f2f2',
  },
  header: {
    fontSize: 19,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),
  },
  searchInput: {
    width: 300,
  },
}))

export type ApplicationSearchStatus = 'active' | 'inactive'

interface ApplicationSearchProps {
  onSearch: (search: string) => void
  onSearchStatus: (status: ApplicationSearchStatus) => void
}

const ApplicationSearch: FC<ApplicationSearchProps> = flowMax(
  addDisplayName('ApplicationSearch'),
  addStateHandlers(
    {
      search: '',
    },
    {
      onSearchChange: () => (search: string) => ({
        search,
      }),
      clearSearch: () => () => ({
        search: '',
      }),
    }
  ),
  addHandlers({
    onSearch: ({search, onSearch}) => () => {
      onSearch(search)
    },
    onSearchChange: ({onSearchChange, onSearchStatus}) => (search: string) => {
      onSearchChange(search)
      onSearchStatus(search ? 'active' : 'inactive')
    },
    onClear: ({clearSearch, onSearchStatus}) => () => {
      clearSearch()
      onSearchStatus('inactive')
    },
  }),
  addTranslationHelpers,
  addClasses(classes),
  ({search, onSearch, onSearchChange, onClear, classes, t}) => (
    <section className={classes.container}>
      <Heading component="h3" className={classes.header}>
        {t('worklist.applicationSearch.header')}
      </Heading>
      <SearchInput
        id="application-search"
        inputClassName={classes.searchInput}
        label={t('worklist.applicationSearch.searchFieldLabel')}
        onChange={onSearchChange}
        onSearch={onSearch}
        onClear={onClear}
        value={search}
      />
    </section>
  )
)

export default ApplicationSearch
