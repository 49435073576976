import {v4 as uuidv4} from 'uuid'

import {ApplicationFields} from 'graphql/deserializedTypes/ApplicationFields'
import {Benefit} from 'graphql/deserializedTypes/globalTypes'

export const getShouldShowEditableFilesSections = (
  application: ApplicationFields
) =>
  [
    Benefit.medicaid,
    Benefit.charityCare,
    Benefit.slide,
    Benefit.ryanWhite,
  ].includes(application.benefit)

export const makeEditableFileKey = (personId: string) =>
  `editableDocuments/${personId}_${uuidv4()}`
