import {addProps} from 'ad-hok'
import {useParams} from 'react-router-dom'

type AddRouteParamsType = <TParams>() => <TProps>(
  props: TProps
) => TProps & TParams

const getUrlParams = (search: string) =>
  Object.fromEntries(new URLSearchParams(search))

const addRouteParams: AddRouteParamsType = () =>
  addProps(() => {
    const {'*': _wildcard, ...params} = {
      ...useParams(),
      ...getUrlParams(window.location.search),
    } as any
    return params
  })

export default addRouteParams
