import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import {TFunction} from 'i18next'

import {addPersonContext} from 'components/PersonDetail/personContext'
import {PERSON_QUERY} from 'graphql/queries'
import {addDocumentFilesContextProvider} from 'components/DocumentFiles/context'
import DocumentFiles from 'components/DocumentFiles'
import {makeClasses, addClasses} from 'theme'
import {addTranslationHelpers} from 'utils/i18n'
import {getExtendedName} from 'utils/name'
import PersonOpenApplicationDocuments from 'components/PersonOpenApplicationDocuments'

const classes = makeClasses((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
  },
  filesContainer: {
    width: 600,
    marginRight: theme.spacing(6),
  },
}))

export const getPersonFileKeyPrefix = (personId: string) =>
  `documents/people/${personId}`
export const getPersonFileDialogTitle = ({
  person,
  t,
}: {
  person: {
    firstName: string | null
    lastName: string | null
    preferredName: string | null
    middleName: string | null
    suffix: string | null
  } | null
  t: TFunction
}) =>
  t('personDocumentFileDialog.title', {
    name: person ? getExtendedName({...person, t}) : '',
  })

const PersonDetailFiles: FC = flowMax(
  addDisplayName('PersonDetailFiles'),
  addPersonContext,
  addTranslationHelpers,
  addProps(
    ({person: {id, documentFiles}, person, t}) => ({
      isFileEditingFrozen: false,
      refetchQueries: [
        {
          query: PERSON_QUERY,
          variables: {id},
        },
      ],
      additionalCreateDocumentFileMutateVariables: {
        personId: id,
      },
      fileKeyPrefix: getPersonFileKeyPrefix(id),
      documentFiles,
      documentFileDialogTitle: getPersonFileDialogTitle({person, t}),
    }),
    ['person', 't']
  ),
  addDocumentFilesContextProvider,
  addClasses(classes),
  ({classes}) => (
    <div className={classes.container}>
      <div className={classes.filesContainer}>
        <DocumentFiles />
      </div>
      <PersonOpenApplicationDocuments />
    </div>
  )
)

export default PersonDetailFiles
