import React from 'react'
import {FC} from 'react'
import {flowMax, addDisplayName, addHandlers} from 'ad-hok'
import {Typography} from '@material-ui/core'
import cx from 'classnames'
import {saveAs as saveFileAs} from 'file-saver'
import DeleteIcon from '@material-ui/icons/Delete'
import SaveAltIcon from '@material-ui/icons/SaveAlt'

import Button from 'components/Button'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogActions from 'components/DialogActions'
import DialogContent from 'components/DialogContent'
import IconButton from 'components/IconButton'
import {makeClasses, addClasses} from 'theme'
import Grid from './Grid'
import Divider from './Divider'
import Box from './Box'
import {addFetchDocumentFileUrl, getBasename} from './DocumentFileLink'

const classes = makeClasses((theme) => ({
  paper: {
    minWidth: 700,
  },
  zipFileList: {
    padding: '0 10px',
    maxHeight: '400px',
    overflowY: 'auto',
  },
  zipFileRow: {
    margin: '10px 0',
  },
  btnTrash: {
    color: '#f00',
  },
}))

interface Props {
  open: boolean
  onConfirm: () => void
  title: string
  className?: string
  fileList: Array<any>
}

const PreviewZipFileListDialog: FC<Props> = flowMax(
  addDisplayName('PreviewZipFileListDialog'),
  addClasses(classes),
  addFetchDocumentFileUrl,
  addHandlers({
    downloadZipFile: ({fetchDocumentFileUrl}) => async (fileKey: string) => {
      const signedUrl = await fetchDocumentFileUrl({
        fileKey,
      })
      const response = await fetch(signedUrl, {
        method: 'GET',
      })
      const fileBlob = await response.blob()
      saveFileAs(fileBlob, getBasename(fileKey))
    },
  }),
  ({open, onConfirm, title, classes, className, fileList, downloadZipFile}) => (
    <Dialog
      open={open}
      onClose={onConfirm}
      classes={{paper: cx(classes.paper, className)}}
      maxWidth="md"
      data-testid="confirmation-dialog"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box className={classes.zipFileList}>
          {fileList.map((zipFile, index: number, array: Array<any>) => (
            <>
              <Grid
                className={classes.zipFileRow}
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Grid item>
                  <Typography>{getBasename(zipFile.fileKey)}</Typography>
                </Grid>
                <Grid item direction="row" alignItems="center">
                  <IconButton onClick={() => downloadZipFile(zipFile.fileKey)}>
                    <SaveAltIcon />
                  </IconButton>
                  <IconButton onClick={() => {}}>
                    <DeleteIcon className={classes.btnTrash} />
                  </IconButton>
                </Grid>
              </Grid>
              {index < array.length - 1 && <Divider />}
            </>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
)

export default PreviewZipFileListDialog
