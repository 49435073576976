import React from 'react'
import {flowMax, addDisplayName, addStateHandlers} from 'ad-hok'
import {FC} from 'react'

import {ReceivedWebforms_receivedWebforms} from 'graphql/deserializedTypes/ReceivedWebforms'
import {addTranslationHelpers} from 'utils/i18n'
import Button from 'components/Button'
import {InfoModal} from 'components/UnmatchedWebformsWorklist/InfoModal'
import {PersonHospitalPatientIdModal} from 'components/UnmatchedWebformsWorklist/PersonHospitalPatientIdModal'
import {CreatePersonModal} from 'components/UnmatchedWebformsWorklist/CreatePersonModal'

interface PersonMatchButtonProps {
  receivedWebform: ReceivedWebforms_receivedWebforms
}

export const PersonMatchButton: FC<PersonMatchButtonProps> = flowMax(
  addDisplayName('PersonMatchButton'),
  addStateHandlers(
    {
      isInfoModalShowing: false,
      isPersonMrnModalShowing: false,
      isCreatePersonModalShowing: false,
    },
    {
      onClick: () => () => ({
        isInfoModalShowing: true,
      }),
      hideInfoModal: () => () => ({
        isInfoModalShowing: false,
      }),
      onMatchToExistingPersonClick: () => () => ({
        isInfoModalShowing: false,
        isPersonMrnModalShowing: true,
      }),
      hidePersonMrnModal: () => () => ({
        isPersonMrnModalShowing: false,
      }),
      onCreateNewPersonClick: () => () => ({
        isInfoModalShowing: false,
        isCreatePersonModalShowing: true,
      }),
      hideCreatePersonModal: () => () => ({
        isCreatePersonModalShowing: false,
      }),
    }
  ),
  addTranslationHelpers,
  ({
    receivedWebform,
    onClick,
    isInfoModalShowing,
    hideInfoModal,
    isPersonMrnModalShowing,
    onMatchToExistingPersonClick,
    hidePersonMrnModal,
    onCreateNewPersonClick,
    hideCreatePersonModal,
    isCreatePersonModalShowing,
    t,
  }) => (
    <>
      <Button onClick={onClick} variant="contained">
        {t('worklist.unmatchedWebforms.match')}
      </Button>
      <InfoModal
        open={isInfoModalShowing}
        receivedWebform={receivedWebform}
        onClose={hideInfoModal}
        onMatchToExistingPersonClick={onMatchToExistingPersonClick}
        onCreateNewPersonClick={onCreateNewPersonClick}
      />
      <PersonHospitalPatientIdModal
        open={isPersonMrnModalShowing}
        receivedWebform={receivedWebform}
        onClose={hidePersonMrnModal}
      />
      <CreatePersonModal
        open={isCreatePersonModalShowing}
        receivedWebform={receivedWebform}
        onClose={hideCreatePersonModal}
      />
    </>
  )
)
