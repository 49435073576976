import React, {FC} from 'react'
import {flowMax, addDisplayName, addHandlers} from 'ad-hok'
import {PureQueryOptions} from 'apollo-boost'
import DeleteIcon from '@material-ui/icons/Delete'

import {addTranslationHelpers} from 'utils/i18n'
import {getExtendedName} from 'utils/name'
import {
  OutcomePerson,
  BenefitOutcome,
} from 'components/BenefitOutcomeFormDialog'
import {addDeleteBenefitOutcomeMutation} from 'graphql/generated'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import addDialogState from 'utils/addDialogState'
import ConfirmationDialog from 'components/ConfirmationDialog'
import IconButton from 'components/IconButton'
import {
  getFormattedBenefitOutcomeTitle,
  getFormattedBenefitOutcomeSubtitle,
} from 'utils/benefitOutcome'

interface Props {
  benefitOutcome: BenefitOutcome
  person: OutcomePerson
  refetchQueriesOnDelete: PureQueryOptions[]
}

const DeleteBenefitOutcomeButton: FC<Props> = flowMax(
  addDisplayName('DeleteBenefitOutcomeButton'),
  addDeleteBenefitOutcomeMutation({
    refetchQueries: ({refetchQueriesOnDelete}) => refetchQueriesOnDelete,
  }),
  addAppSnackbarContext,
  addDialogState,
  addTranslationHelpers,
  addHandlers({
    onDelete: ({
      benefitOutcome: {id},
      mutateDeleteBenefitOutcome,
      hideDialog,
      showSnackbarMessage,
      t,
    }) => () => {
      mutateDeleteBenefitOutcome({
        variables: {id},
      }).then(() => {
        hideDialog()
        showSnackbarMessage(t('benefitOutcomes.deletedMessage'))
      })
    },
  }),
  ({
    benefitOutcome,
    person,
    onDelete,
    showDialog,
    hideDialog,
    isShowingDialog,
    t,
  }) => (
    <>
      <IconButton onClick={showDialog}>
        <DeleteIcon />
      </IconButton>
      <ConfirmationDialog
        title={t('deleteBenefitOutcomeDialog.title')}
        message={t('deleteBenefitOutcomeDialog.message', {
          benefitTitle: getFormattedBenefitOutcomeTitle({...benefitOutcome, t}),
          benefitSubtitle: getFormattedBenefitOutcomeSubtitle(benefitOutcome),
          personName: getExtendedName({...person, t}),
        })}
        cancelText={t('deleteBenefitOutcomeDialog.cancel')}
        confirmText={t('deleteBenefitOutcomeDialog.confirm')}
        open={isShowingDialog}
        onCancel={hideDialog}
        onConfirm={onDelete}
      />
    </>
  )
)

export default DeleteBenefitOutcomeButton
