import {RECEIVED_WEBFORMS_QUERY} from 'graphql/queries'

export const MATCHED_STATUSES = ['unmatched', 'noMatch'] as const

export type MatchedStatus = typeof MATCHED_STATUSES[number]

export const unmatchedWebformsRefetchOptions = {
  refetchQueries: () =>
    MATCHED_STATUSES.map((matchedStatus) => ({
      query: RECEIVED_WEBFORMS_QUERY,
      variables: {matchedStatus},
    })),
  awaitRefetchQueries: true,
}
