import React from 'react'
import {
  flowMax,
  addDisplayName,
  addWrapper,
  addProps,
  addMemoBoundary,
} from 'ad-hok'
import {FC} from 'react'
import {get} from 'lodash'
import {addPropIdentityStabilization} from 'ad-hok-utils'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {TestContext} from 'yup'

import {makeFormSchema, ExtractFormSchemaFields} from 'utils/form/schema'
import Form, {OnSubmitSuccessOptions} from 'components/Form'
import {addTranslationHelpers} from 'utils/i18n'
import theme, {addClasses, makeClasses} from 'theme'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogContent from 'components/DialogContent'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import {incomeSourceSchema} from 'components/EditPersonForm/schema'
import SelectField from 'components/SelectField'
import FormRow from 'components/FormRow'
import TextField from 'components/TextField'
import OptionalBooleanField from 'components/OptionalBooleanField'
import CurrencyField from 'components/CurrencyField'
import {addRightColumnContext} from 'components/EditPersonForm/rightColumnContext'
import {
  addSubmitForm,
  addFormikSubmitFormCallback,
} from 'utils/addFormikHoistedState'
import addHasSaved from 'utils/addHasSaved'
import {addFieldsFreshnessContext} from 'utils/form/context'
import {PersonFields_incomeSources} from 'graphql/deserializedTypes/PersonFields'
import Accordion from 'components/Accordion'
import AccordionSummary from 'components/AccordionSummary'
import Body1 from 'components/Body1'
import AccordionDetails from 'components/AccordionDetails'
import {ValidatorTest, makePhoneField} from 'utils/form/fieldTypes'

const classes = makeClasses(() => ({
  contentContainer: {
    width: 600,
  },
  esignSessionContainer: {
    marginBottom: theme.spacing(2),
  },
  esignSessionContentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const employerPhoneRequiredForEsignValidatorTest: ValidatorTest = [
  'form.incomeSourceEmployerPhoneRequiredForEsign',
  function (this: TestContext, value: string) {
    const {usedForEsign} = this.parent
    if (!usedForEsign) return true
    if (value) return true
    return false
  },
]

export const incomeSourceFormSchema = makeFormSchema({
  fields: {
    incomeSource: {
      ...incomeSourceSchema,
      employerPhone: makePhoneField({
        validatorTest: employerPhoneRequiredForEsignValidatorTest,
      }),
    },
  },
})

interface Props {
  incomeSource?: PersonFields_incomeSources
  itemName?: string
  onSave: (
    options: OnSubmitSuccessOptions<
      ExtractFormSchemaFields<typeof incomeSourceFormSchema>
    >
  ) => void
  open: boolean
  onClose: () => void
}

const IncomeSourceDialog: FC<Props> = flowMax(
  addDisplayName('IncomeSourceDialog'),
  addRightColumnContext,
  addProps(({incomeSourceFormDeterminers: determiners}) => ({
    determiners,
  })),
  addFieldsFreshnessContext,
  addProps(
    ({fieldsFreshness, itemName}) => ({
      initialFieldsFreshness: {
        incomeSource: itemName ? get(fieldsFreshness, itemName, {}) : {},
      },
    }),
    ['fieldsFreshness', 'itemName']
  ),
  addPropIdentityStabilization('initialFieldsFreshness'),
  addMemoBoundary([
    'incomeSource',
    'onSave',
    'open',
    'onClose',
    'determiners',
    'initialFieldsFreshness',
  ]),
  addSubmitForm,
  addHasSaved('onSave'),
  addClasses(classes),
  addTranslationHelpers,
  addWrapper(
    (
      render,
      {
        classes,
        open,
        onClose,
        incomeSource,
        onSave,
        submitForm,
        hasSaved,
        determiners,
        initialFieldsFreshness,
        t,
      }
    ) => (
      <Dialog
        open={open}
        onClose={onClose}
        data-testid={`income-source-dialog-${incomeSource?.id ?? 'new'}`}
        scroll="paper"
      >
        <DialogTitle>{t('incomeSourceDialog.title')}</DialogTitle>
        <DialogContent className={classes.contentContainer} dividers>
          <Form
            name="incomeSourceForm"
            schema={incomeSourceFormSchema}
            onSubmitSuccess={onSave}
            initialValues={incomeSource && {incomeSource}}
            initialFieldsFreshness={initialFieldsFreshness}
            initialTouched={{incomeSource: {startMonth: true}}}
            shouldTrackFieldsUpdatedAt
            determiners={determiners}
          >
            {render()}
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t('incomeSourceDialog.cancel')}
          </Button>
          <Button
            onClick={submitForm}
            variant="contained"
            color="primary"
            disabled={hasSaved}
          >
            {t('incomeSourceDialog.save')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  ),
  addFormikSubmitFormCallback,
  addProps(
    ({incomeSource}) => ({
      isEmployerPhoneFieldDisabled: !!incomeSource?.esignSessionId,
    }),
    ['incomeSource']
  ),
  ({isEmployerPhoneFieldDisabled, classes, t}) => (
    <>
      <SelectField name="incomeSource.incomeType" lazyIcon />
      <FormRow variant="twoColumn_1_1_true">
        <CurrencyField name="incomeSource.amount" lazyIcon />
        <SelectField name="incomeSource.payFrequency" lazyIcon />
      </FormRow>
      <FormRow variant="fourColumn_1_1_1_1_true">
        <SelectField name="incomeSource.startMonth" lazyIcon />
        <SelectField name="incomeSource.startYear" lazyIcon />
        <SelectField name="incomeSource.endMonth" lazyIcon />
        <SelectField name="incomeSource.endYear" lazyIcon />
      </FormRow>
      <FormRow variant="twoColumn_1_1_true">
        <TextField name="incomeSource.employerName" lazyIcon />
        <SelectField name="incomeSource.fullPartTime" lazyIcon />
      </FormRow>
      <FormRow variant="twoColumn_1_1_true">
        <OptionalBooleanField name="incomeSource.employerInsurance" lazyIcon />
        <OptionalBooleanField name="incomeSource.proofOfIncome" lazyIcon />
      </FormRow>
      <TextField name="incomeSource.noProofOfIncomeReason" lazyIcon />
      <TextField name="incomeSource.employerAddressStreet" lazyIcon />
      <FormRow variant="threeColumn_2_1_1_true">
        <TextField name="incomeSource.employerAddressCity" lazyIcon />
        <SelectField name="incomeSource.employerAddressState" lazyIcon />
        <TextField name="incomeSource.employerAddressZip" lazyIcon />
      </FormRow>
      <TextField
        type="tel"
        name="incomeSource.employerPhone"
        lazyIcon
        disabled={isEmployerPhoneFieldDisabled}
      />
      <OptionalBooleanField name={'incomeSource.usedForEsign'} />
      <Accordion className={classes.esignSessionContainer}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Body1>{t('incomeSourceDialog.esignSession')}</Body1>
        </AccordionSummary>
        <AccordionDetails className={classes.esignSessionContentContainer}>
          <TextField name={'incomeSource.esignSessionId'} />
          <SelectField name={'incomeSource.esignSessionStatus'} disabled />
        </AccordionDetails>
      </Accordion>
    </>
  )
)

export default IncomeSourceDialog
