import React from 'react'
import {
  flowMax,
  addDisplayName,
  addWrapper,
  addHandlers,
  addProps,
} from 'ad-hok'
import {FC} from 'react'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import grey from '@material-ui/core/colors/grey'
import parseISO from 'date-fns/parseISO'
import {PureQueryOptions} from 'apollo-boost'

import Form from 'components/Form'
import {addTranslationHelpers} from 'utils/i18n'
import {addClasses, makeClasses} from 'theme'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogContent from 'components/DialogContent'
import DateField from 'components/DateField'
import TextField from 'components/TextField'
import FormRow from 'components/FormRow'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import MultilineTextField from 'components/MultilineTextField'
import DisplayItem from 'components/DisplayItem'
import Grid from 'components/Grid'
import Caption from 'components/Caption'
import {
  getBenefitsNonOverlappingValidatorTest,
  effectiveDateEndBeforeStart,
  BenefitOutcome,
  OutcomePerson,
  BENEFIT_OUTCOME_BENEFIT_OPTION_OTHER,
  BENEFIT_OUTCOME_COVERED_FACILITY_ALL,
  BENEFIT_OUTCOME_OUTCOME_CLIENT_ACQUIRED_OTHER_INSURANCE,
} from 'components/BenefitOutcomeFormDialog'
import OverlappingBenefitOutcomes from 'components/BenefitOutcomeFormDialog/OverlappingBenefitOutcomes'
import {makeFormSchema} from 'utils/form/schema'
import {
  makeTextField,
  makeDateField,
  ValidatorTest,
} from 'utils/form/fieldTypes'
import {addFormikTyped} from 'utils/form/formik'
import {addCreateOrUpdateBenefitOutcomeMutation} from 'graphql/generated'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import {getExtendedName} from 'utils/name'
import {getOverlappingBenefitOutcomes} from 'utils/benefitOutcome'
import addFormikHoistedState, {
  addFormikHoistedStateCallbacks,
} from 'utils/addFormikHoistedState'
import {Assert, SchemaDoesntHaveExtraFields} from 'utils/form/typeHelpers'

const classes = makeClasses((theme) => ({
  contentContainer: {
    width: 600,
  },
  personDisplay: {
    marginBottom: theme.spacing(3),
  },
  saveInfo: {
    width: 'auto',
    marginRight: theme.spacing(2),
  },
  saveInfoIcon: {
    marginRight: theme.spacing(1),
    color: grey[500],
  },
}))

const getInsuranceDiscoverBenefitOutcomeFormSchema = ({
  benefitOutcomeId,
  benefitOutcomes,
}: {
  benefitOutcomeId?: string
  benefitOutcomes: BenefitOutcome[]
}) =>
  makeFormSchema({
    fields: {
      benefitOutcome: {
        id: makeTextField(),
        benefit: makeTextField({
          isRequired: true,
        }),
        coveredFacility: makeTextField({
          isRequired: true,
        }),
        outcome: makeTextField({
          isRequired: true,
        }),
        effectiveStartDate: makeDateField({
          isRequired: true,
          validatorTests: [
            effectiveDateEndBeforeStart,
            benefitOutcomes &&
              getBenefitsNonOverlappingValidatorTest({
                benefitOutcomeId,
                benefitOutcomes,
                applicationCharityCareType: '',
                applicationSlideType: '',
              }),
          ].filter((v) => v) as ValidatorTest[],
        }),
        effectiveEndDate: makeDateField({
          isRequired: true,
          validatorTests: [
            effectiveDateEndBeforeStart,
            benefitOutcomes &&
              getBenefitsNonOverlappingValidatorTest({
                benefitOutcomeId,
                benefitOutcomes,
                applicationCharityCareType: '',
                applicationSlideType: '',
              }),
          ].filter((v) => v) as ValidatorTest[],
        }),
        insuranceName: makeTextField({
          isRequired: true,
        }),
        personId: makeTextField({
          isRequired: true,
        }),
        policyId: makeTextField(),
        notes: makeTextField(),
      },
    },
  })

const benefitOutcomeFormSchemaStatic = getInsuranceDiscoverBenefitOutcomeFormSchema(
  {
    benefitOutcomes: [],
  }
)

type Check = Assert<
  SchemaDoesntHaveExtraFields<
    typeof benefitOutcomeFormSchemaStatic,
    typeof addCreateOrUpdateBenefitOutcomeMutation
  >
>

interface Props {
  benefitOutcome?: BenefitOutcome
  person: OutcomePerson
  refetchQueriesOnCreateOrUpdate: PureQueryOptions[]
  open: boolean
  onCancel: () => void
  onSaveSuccess: () => void
}

const InsuranceDiscoveryBenefitOutcomeFormDialog: FC<Props> = flowMax(
  addDisplayName('InsuranceDiscoveryBenefitOutcomeFormDialog'),
  addAppSnackbarContext,
  addTranslationHelpers,
  addHandlers({
    onSave: ({benefitOutcome, showSnackbarMessage, onSaveSuccess, t}) => () => {
      showSnackbarMessage(
        benefitOutcome
          ? t('insuranceDiscoveryBenefitOutcomeForm.updatedMessage')
          : t('insuranceDiscoveryBenefitOutcomeForm.createdMessage')
      )
      onSaveSuccess()
    },
  }),
  addProps(({benefitOutcome, person: {id: personId}}) => ({
    initialValues: {
      benefitOutcome: benefitOutcome
        ? {
            ...benefitOutcome,
            personId,
          }
        : {
            id: '',
            benefit: BENEFIT_OUTCOME_BENEFIT_OPTION_OTHER,
            coveredFacility: BENEFIT_OUTCOME_COVERED_FACILITY_ALL,
            outcome: BENEFIT_OUTCOME_OUTCOME_CLIENT_ACQUIRED_OTHER_INSURANCE,
            effectiveStartDate: null,
            effectiveEndDate: new Date(),
            insuranceName: '',
            policyId: '',
            notes: '',
            personId,
          },
    },
  })),
  addProps(
    ({benefitOutcome, person: {benefitOutcomes}}) => ({
      formSchema: getInsuranceDiscoverBenefitOutcomeFormSchema({
        benefitOutcomeId: benefitOutcome?.id,
        benefitOutcomes,
      }),
    }),
    ['benefitOutcome', 'person.benefitOutcomes']
  ),
  addCreateOrUpdateBenefitOutcomeMutation({
    refetchQueries: ({refetchQueriesOnCreateOrUpdate}) =>
      refetchQueriesOnCreateOrUpdate,
  }),
  addFormikHoistedState,
  addClasses(classes),
  addWrapper(
    (
      render,
      {
        formSchema,
        open,
        onCancel,
        benefitOutcome,
        initialValues,
        onSave,
        submitFormRef,
        isSubmitting,
        mutateCreateOrUpdateBenefitOutcome,
        classes,
        t,
      }
    ) => (
      <Dialog
        open={open}
        onClose={onCancel}
        data-testid={`benefit-outcome-dialog-${benefitOutcome?.id ?? 'new'}`}
        scroll="paper"
      >
        <DialogTitle>
          {t('insuranceDiscoveryBenefitOutcomeForm.title')}
        </DialogTitle>
        <DialogContent className={classes.contentContainer} dividers>
          <Form
            name="insuranceDiscoveryBenefitOutcomeForm"
            schema={formSchema}
            mutate={mutateCreateOrUpdateBenefitOutcome}
            onSubmitSuccess={onSave}
            initialValues={initialValues}
          >
            {render()}
          </Form>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.saveInfo}
          >
            <InfoOutlinedIcon className={classes.saveInfoIcon} />
            <Caption>
              {t('insuranceDiscoveryBenefitOutcomeForm.saveInfo')}
            </Caption>
          </Grid>
          <Button onClick={onCancel} color="primary">
            {t('benefitOutcomeDialog.cancel')}
          </Button>
          <Button
            onClick={() => submitFormRef.current?.()}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {t('benefitOutcomeDialog.save')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  ),
  addFormikHoistedStateCallbacks,
  addFormikTyped(benefitOutcomeFormSchemaStatic),
  // eslint-disable-next-line ad-hok/dependencies
  addProps(
    ({
      benefitOutcome,
      person: {benefitOutcomes},
      formik: {
        values: {
          benefitOutcome: {
            effectiveStartDate,
            effectiveEndDate,
            benefit,
            coveredFacility,
          },
        },
      },
    }) => ({
      overlappingBenefitOutcomes: getOverlappingBenefitOutcomes({
        benefitOutcomeId: benefitOutcome?.id,
        effectiveStartDate: parseISO(effectiveStartDate),
        effectiveEndDate: parseISO(effectiveEndDate),
        benefit,
        coveredFacility,
        benefitOutcomes,
        applicationCharityCareType: '',
        applicationSlideType: '',
      }),
    }),
    ['person.benefitOutcome', 'formik.values']
  ),
  ({person, overlappingBenefitOutcomes, classes, t}) => (
    <>
      <div className={classes.personDisplay}>
        <DisplayItem
          i18nKey="benefitOutcomeForm.personIdDisplay"
          translations={{
            id: person.id,
          }}
        />
        <DisplayItem
          i18nKey="benefitOutcomeForm.personNameDisplay"
          translations={{
            name: getExtendedName({...person, t}),
          }}
        />
      </div>
      <FormRow variant="twoColumn_1_1_true">
        <DateField name="benefitOutcome.effectiveStartDate" noIcon />
        <DateField name="benefitOutcome.effectiveEndDate" noIcon />
      </FormRow>
      <OverlappingBenefitOutcomes
        overlappingBenefitOutcomes={overlappingBenefitOutcomes}
      />
      <FormRow variant="twoColumn_1_1_true">
        <TextField name="benefitOutcome.insuranceName" noIcon />
        <TextField name="benefitOutcome.policyId" noIcon />
      </FormRow>
      <MultilineTextField name={'benefitOutcome.notes'} noIcon />
    </>
  )
)

export default InsuranceDiscoveryBenefitOutcomeFormDialog
