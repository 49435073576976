import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  branch,
  returns,
  addHandlers,
  addWrapper,
  addState,
} from 'ad-hok'
import {Typography} from '@material-ui/core'
import {addInterval} from 'ad-hok-utils'
import {TFunction} from 'i18next'
import {Theme} from '@material-ui/core'

import {
  WORKLIST_POLL_INTERVAL,
  addResultsLimitedMessage,
} from 'components/TriageWorklist'
import {addLoadingIndicator} from 'utils/dataLoading'
import {WorklistNoResults} from 'components/ApplicationWorklist'
import {addInboundsQuery} from 'graphql/generated'
import InboundItem from 'components/InboundItem'
import addFilterControls, {makeFilters} from 'utils/addFilterControls'
import {addTranslationHelpers} from 'utils/i18n'
import {InboundType, ServiceType} from 'graphql/deserializedTypes/globalTypes'
import {addClasses, makeClasses} from 'theme'
import ToggleButton from 'components/ToggleButton'
import ToggleButtonGroup from 'components/ToggleButtonGroup'
import addRemountingWhenPropsChange from 'utils/addRemountingWhenPropsChange'
import {setLastInboundType, getLastInboundType} from 'utils/worklistPersistence'
import {getSelectFieldOptionsFromEnumType} from 'utils/form/fieldTypes'
import {DepartmentFields} from 'graphql/deserializedTypes/DepartmentFields'
import {
  addBenefits,
  addDepartments,
  addFinancialOffices,
  addInboundReasons,
} from 'utils/configContext'
import {Benefit} from 'graphql/types/globalTypes'
import {FinancialOfficeFields} from 'graphql/types/FinancialOfficeFields'
import {InboundReasonFields} from 'graphql/types/InboundReasonFields'

const classes = makeClasses((theme: Theme) => ({
  filterField: {
    maxWidth: 250,
    marginBottom: theme.spacing(2),
  },
  inboundTypeSelect: {
    marginBottom: theme.spacing(2),
  },
  inboundTypeSelectButton: {
    '&.MuiToggleButton-root': {
      color: '#4050b5',
      border: '1px solid #4050b5',
    },
  },
  inboundTypeSelectButtonSelected: {
    '&.MuiToggleButton-root': {
      backgroundColor: '#4050b5',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#4050b5',
      },
    },
  },
}))

const STATUS_OPEN = 'Open'
const STATUS_CLOSED = 'Closed'

const getInboundFilters = ({
  departments,
  financialOffices,
  inboundReasons,
  benefits,
  inboundType,
  t,
}: {
  departments: DepartmentFields[]
  financialOffices: FinancialOfficeFields[]
  inboundReasons: InboundReasonFields[]
  benefits: Benefit[]
  inboundType: InboundType
  t: TFunction
}) =>
  makeFilters({
    openClosedStatus: {
      type: 'select',
      label: t('worklist.inboundFilters.openClosedStatus'),
      options: [
        {
          label: t('worklist.inboundFilters.open'),
          value: STATUS_OPEN,
        },
        {
          label: t('worklist.inboundFilters.closed'),
          value: STATUS_CLOSED,
        },
      ],
    },
    serviceType: {
      type: 'multiselect',
      label: t('worklist.inboundFilters.serviceType'),
      options: getSelectFieldOptionsFromEnumType(ServiceType),
      disabled: inboundType !== InboundType.hospitalVisit,
    },
    department: {
      type: 'multiselect',
      label: t('worklist.inboundFilters.department'),
      options: departments.map(({name}) => ({
        value: name,
        label: name,
      })),
      disabled: inboundType !== InboundType.hospitalVisit,
    },
    financialOffice: {
      type: 'multiselect',
      label: t('worklist.inboundFilters.financialOffice'),
      options: financialOffices.map(({id, name}) => ({
        value: id,
        label: name,
      })),
      disabled: inboundType !== InboundType.office,
    },
    inboundReason: {
      type: 'multiselect',
      label: t('worklist.inboundFilters.inboundReason'),
      options: inboundReasons.map(({id, name}) => ({
        value: id,
        label: name,
      })),
      disabled: inboundType !== InboundType.office,
    },
    dateOfService: {
      type: 'multiselect',
      label: t('worklist.inboundFilters.dateOfService'),
      options: ['Past 5 days'].map((value) => ({
        value,
        label: value,
      })),
      disabled: inboundType !== InboundType.hospitalVisit,
    },
    intakeWebformReceived: {
      type: 'multiselect',
      label: t('worklist.inboundFilters.intakeWebformReceived'),
      options: ['< 5 days', '< 15 days', 'Received', 'Not received'].map(
        (name) => ({
          value: name,
          label: name,
        })
      ),
      disabled: inboundType === InboundType.office,
    },
    openApplications: {
      type: 'multiselect',
      label: t('worklist.inboundFilters.openApplications'),
      options: [
        ...benefits.map((value) => ({
          value,
          label: t(`applications.benefitLabels.${value}`),
        })),
        {
          value: 'noOpenApplications',
          label: t('worklist.inboundFilters.noOpenApplications'),
        },
      ],
    },
  })

const ALL_INBOUND_TYPES = [
  InboundType.office,
  InboundType.scheduled,
  InboundType.hospitalVisit,
]

interface InboundTypeSelectProps {
  value: InboundType
  onChange: (inboundType: InboundType) => void
}

const InboundTypeSelect: FC<InboundTypeSelectProps> = flowMax(
  addDisplayName('InboundTypeSelect'),
  addHandlers({
    onChange: ({onChange}) => (_: unknown, value: InboundType | null) => {
      if (!value) return
      onChange(value)
      setLastInboundType(value)
    },
  }),
  addTranslationHelpers,
  addClasses(classes),
  ({value, onChange, classes, t}) => (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={onChange}
      className={classes.inboundTypeSelect}
    >
      {ALL_INBOUND_TYPES.map((inboundType) => (
        <ToggleButton
          value={inboundType}
          classes={{
            root: classes.inboundTypeSelectButton,
            selected: classes.inboundTypeSelectButtonSelected,
          }}
          key={inboundType}
        >
          {t(`worklist.inboundFilters.inboundTypeLabels.${inboundType}`)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
)

const InboundWorklist: FC = flowMax(
  addDisplayName('InboundWorklist'),
  addState(
    'inboundType',
    'setInboundType',
    () => getLastInboundType() ?? InboundType.office
  ),
  addWrapper((render, {inboundType, setInboundType}) => (
    <>
      <InboundTypeSelect value={inboundType} onChange={setInboundType} />
      {render()}
    </>
  )),
  addRemountingWhenPropsChange('inboundType'),
  addDepartments,
  addFinancialOffices,
  addInboundReasons,
  addBenefits,
  addTranslationHelpers,
  addProps(
    ({
      departments,
      financialOffices,
      inboundReasons,
      benefits,
      inboundType,
      t,
    }) => ({
      filters: getInboundFilters({
        departments,
        financialOffices,
        inboundReasons,
        benefits: benefits.map(({benefit}) => benefit),
        inboundType,
        t,
      }),
      initialFilterValues: {
        openClosedStatus: STATUS_OPEN,
        serviceType: [],
        department: [],
        financialOffice: [],
        dateOfService: [],
        intakeWebformReceived: [],
        openApplications: [],
        inboundReason: [],
      },
    }),
    [
      'departments',
      'financialOffices',
      'inboundReasons',
      'benefits',
      'inboundType',
      't',
    ]
  ),
  addClasses(classes),
  addFilterControls<ReturnType<typeof getInboundFilters>>({
    filterFieldClassName: ({classes}) => classes.filterField,
    persistenceKey: ({inboundType}) => `inbound-${inboundType}`,
    showClearButton: ({inboundType}) =>
      inboundType === InboundType.hospitalVisit,
  }),
  addProps(
    ({filterValues, inboundType}) => ({
      variables: {
        ...filterValues,
        inboundType,
      },
    }),
    ['filterValues', 'inboundType']
  ),
  addInboundsQuery({
    variables: ({variables}) => variables,
  }),
  addHandlers({
    refetch: ({variables, refetchInbounds}) => () => {
      refetchInbounds(variables)
    },
  }),
  addInterval(
    ({refetch}) => () => {
      refetch()
    },
    WORKLIST_POLL_INTERVAL,
    ['variables']
  ),
  addLoadingIndicator({}),
  branch(
    ({inbounds}) => !inbounds.length,
    returns(() => <WorklistNoResults />)
  ),
  addResultsLimitedMessage('inbounds'),
  ({inbounds, refetch, inboundType}) => (
    <>
      <Typography style={{fontStyle: 'normal', color: '#4050b5'}} variant="h6">
        &nbsp;&nbsp;Number of inbounds in queue: {inbounds.length}
      </Typography>

      {inbounds.map((inbound) => (
        <InboundItem
          inbound={inbound}
          refetchInbounds={refetch}
          inboundType={inboundType}
          key={inbound.id}
        />
      ))}
    </>
  )
)

export default InboundWorklist
