import {SelectFieldOption} from 'utils/form/fieldTypes'

export const makeOptionsFromNamedEnums = (
  values: {name: string}[]
): SelectFieldOption[] =>
  values.map(({name}) => ({
    label: name,
    value: name,
  }))

export const makeOptionsFromRawEnums = <T extends string>(
  values: T[],
  translate: (value: T) => string
): SelectFieldOption[] =>
  values.map((value) => ({value, label: translate(value)}))
