import React, {ReactElement, FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addStateHandlers,
  addWrapper,
  addProps,
  addEffect,
  addHandlers,
} from 'ad-hok'
import {Theme} from '@material-ui/core'
import cx from 'classnames'
import {isEmpty, mapValues} from 'lodash'
import {
  flow,
  replace,
  pick,
  keys,
  first,
  findKey,
  omit,
  isFunction,
} from 'lodash/fp'

import {addClasses, makeClasses} from 'theme'
import Grid from 'components/Grid'
import FilterSelectField from 'components/FilterSelectField'
import {addTranslationHelpers} from 'utils/i18n'
import {StandaloneDateField} from 'components/DateField'
import {validDateOrNull} from 'utils/date'
import {parseDate} from 'utils/form/fieldTypes'
import {StandaloneSelectField} from 'components/SelectField'
import {StandaloneTextField} from 'components/TextField'
import {
  setWorklistTabFilters,
  getWorklistTabFilters,
} from 'utils/worklistPersistence'
import {camelCaseToLowerCaseWords} from 'utils/string'
import {callWith, mapValuesWithKeyUncurried} from 'utils/fp'
import Button from 'components/Button'
import RadioButtons from 'components/RadioButtons'

const classes = makeClasses((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  filterSelect: {
    width: '30%',
    maxWidth: 500,
    marginRight: theme.spacing(2),
  },
  filterText: {
    marginRight: theme.spacing(2),
  },
  openClosedFilterSelect: {
    maxWidth: '120px !important',
  },
  clearButton: {
    alignSelf: 'flex-start',
    color: '#5b6aff',
    textTransform: 'none',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  sortRowContainer: {
    alignSelf: 'flex-end',
  },
}))

interface CommonFilterFields {
  label: string
  disabled?: boolean
}

interface MultiselectFilter extends CommonFilterFields {
  type: 'multiselect'
  options: {
    label: string
    value: string
    isClosed?: boolean
  }[]
}

interface SelectFilter extends CommonFilterFields {
  type: 'select'
  label: string
  options: {
    label: string
    value: string
  }[]
  isSort?: boolean
}

interface DateFilter extends CommonFilterFields {
  type: 'date'
}

interface TextFilter extends CommonFilterFields {
  type: 'text'
}

type FilterControlsFilter =
  | MultiselectFilter
  | SelectFilter
  | DateFilter
  | TextFilter

const getIsMultiselectFilter = (
  filter: FilterControlsFilter
): filter is MultiselectFilter => filter.type === 'multiselect'
const getIsSelectFilter = (
  filter: FilterControlsFilter
): filter is SelectFilter => filter.type === 'select'

type GetFilterControlsFilterValue<
  TFilter extends FilterControlsFilter
> = TFilter['type'] extends 'multiselect'
  ? string[]
  : TFilter['type'] extends 'select'
  ? string
  : TFilter['type'] extends 'date'
  ? string
  : TFilter['type'] extends 'text'
  ? string | null
  : never

type GetFilterControlsExposedFilterValue<
  TFilter extends FilterControlsFilter
> = TFilter['type'] extends 'multiselect'
  ? string[]
  : TFilter['type'] extends 'select'
  ? string
  : TFilter['type'] extends 'date'
  ? Date | null
  : TFilter['type'] extends 'text'
  ? string | null
  : never

export interface FilterControlsFilters {
  [filterName: string]: FilterControlsFilter
}

export const makeFilters = <TFilters extends FilterControlsFilters>(
  filters: TFilters
) => filters

export type FilterValues<TFilters extends FilterControlsFilters> = {
  [filterName in keyof TFilters]: GetFilterControlsExposedFilterValue<
    TFilters[filterName]
  >
}

type FilterInternalValues<TFilters extends FilterControlsFilters> = {
  [filterName in keyof TFilters]: GetFilterControlsFilterValue<
    TFilters[filterName]
  >
}

interface ClearButtonProps {
  onClick: () => void
}

const ClearButton: FC<ClearButtonProps> = flowMax(
  addDisplayName('ClearButton'),
  addClasses(classes),
  addTranslationHelpers,
  ({onClick, classes, t}) => (
    <Button onClick={onClick} className={classes.clearButton}>
      {t('filters.clear')}
    </Button>
  )
)

interface FilterControlsProps<TFilters extends FilterControlsFilters> {
  className?: string
  filters: TFilters
  filterValues: FilterInternalValues<TFilters>
  onChange: <TFilterName extends keyof TFilters>(opts: {
    name: TFilterName
    value: GetFilterControlsFilterValue<TFilters[TFilterName]>
  }) => void
  onFilterFocus: (opts: {name: keyof TFilters; value: string}) => void
  noFilterSelectionTranslationKey: string
  filterFieldClassName?: string
  extraControls?: ReactElement
  disableFilterControls?: boolean
  showClearButton: boolean
  resetFilters: () => void
  disableSortFilter: boolean
}

type FilterControlsType = <TFilters extends FilterControlsFilters>(
  props: FilterControlsProps<TFilters>
) => ReactElement<any, any> | null

const FilterControls: FilterControlsType = flowMax(
  addDisplayName('FilterControls'),
  addProps(
    ({filters}) => ({
      sortFilterKey: findKey((filter) => (filter as any).isSort, filters),
    }),
    ['filters']
  ),
  addProps(
    ({filters, sortFilterKey}) => ({
      filters: sortFilterKey ? omit([sortFilterKey], filters) : filters,
      sortFilter: sortFilterKey
        ? (filters[sortFilterKey] as SelectFilter)
        : null,
    }),
    ['filters', 'sortFilterKey']
  ),
  addClasses(classes),
  addTranslationHelpers,
  ({
    className,
    filters,
    filterValues,
    classes,
    onChange,
    onFilterFocus,
    noFilterSelectionTranslationKey,
    filterFieldClassName,
    extraControls,
    disableFilterControls,
    showClearButton,
    resetFilters,
    sortFilter,
    sortFilterKey,
    disableSortFilter,
    t,
  }) => (
    <div className={cx(classes.container, className)}>
      <Grid container>
        {Object.entries(filters)
          .filter(([_, {disabled}]) => !disabled)
          .map(([name, filter]) =>
            filter.type === 'multiselect' ? (
              <FilterSelectField
                className={cx(classes.filterSelect, filterFieldClassName)}
                value={
                  (filterValues[name] as GetFilterControlsFilterValue<
                    typeof filter
                  >) || []
                }
                onChange={({target: {value}}) =>
                  onChange({name, value: value as any})
                }
                onFilterFocus={(value) => {
                  onFilterFocus({name, value})
                }}
                id={`filter-${name}`}
                label={filter.label}
                options={filter.options}
                disabled={disableFilterControls}
                renderValue={(selected) =>
                  selected.length
                    ? filter.options
                        .filter(({value}) => selected.includes(value))
                        .map(({label}) => label)
                        .join(', ')
                    : t(noFilterSelectionTranslationKey, {
                        filter: callWith(
                          name,
                          flow(
                            camelCaseToLowerCaseWords,
                            replace(/njmmis/, 'NJMMIS')
                          )
                        ),
                      })
                }
                key={name}
              />
            ) : filter.type === 'date' ? (
              <StandaloneDateField
                className={cx(classes.filterText, filterFieldClassName)}
                value={
                  filterValues[name] as GetFilterControlsFilterValue<
                    typeof filter
                  >
                }
                onChange={({target: {value}}) =>
                  onChange({
                    name,
                    value: value as any,
                  })
                }
                label={filter.label}
                key={name}
                disabled={disableFilterControls}
              />
            ) : filter.type === 'text' ? (
              <StandaloneTextField
                className={cx(classes.filterText, filterFieldClassName)}
                value={(filterValues[name] as string) ?? ''}
                onChange={({target: {value}}) =>
                  onChange({name, value: (value ?? null) as any})
                }
                label={filter.label}
                key={name}
                disabled={disableFilterControls}
              />
            ) : (
              <StandaloneSelectField
                className={cx(
                  classes.filterSelect,
                  filterFieldClassName,
                  name === 'openClosed' && classes.openClosedFilterSelect
                )}
                value={
                  (filterValues[name] as GetFilterControlsFilterValue<
                    typeof filter
                  >) || ''
                }
                onChange={({target: {value}}) =>
                  onChange({name, value: value as any})
                }
                id={`filter-${name}`}
                label={filter.label}
                options={filter.options}
                shrink
                key={name}
                disabled={disableFilterControls}
              />
            )
          )}
        {showClearButton && <ClearButton onClick={resetFilters} />}
        {extraControls}
      </Grid>
      {!!sortFilter && (
        <div className={classes.sortRowContainer}>
          <RadioButtons
            label={sortFilter.label}
            name={sortFilterKey!}
            value={(filterValues[sortFilterKey!] as string) || ''}
            onChange={({target: {value}}) =>
              onChange({name: sortFilterKey!, value: value as any})
            }
            options={sortFilter.options}
            disabled={disableSortFilter}
          />
        </div>
      )}
    </div>
  )
)

const getExposedFilterValues = <TFilters extends FilterControlsFilters>({
  filterValues,
  filters,
  initialFilterValues,
}: {
  filterValues: FilterInternalValues<TFilters>
  filters: TFilters
  initialFilterValues: FilterInternalValues<TFilters>
}): FilterValues<TFilters> =>
  mapValues(filterValues, (filterValue, filterName) =>
    filters[filterName].disabled
      ? initialFilterValues[filterName]
      : filters[filterName].type === 'date'
      ? validDateOrNull(parseDate(filterValue as string))
      : filterName === 'statuses' &&
        (filterValue as string[]).length === 0 &&
        filterValues.openClosed === CLOSED
      ? (filters.statuses as MultiselectFilter).options
          .filter(({isClosed}) => isClosed)
          .map(({value}) => value)
      : filterValue
  ) as any

const restrictToValidValues = <TFilters extends FilterControlsFilters>(
  filterValues: FilterInternalValues<TFilters>,
  filters: TFilters
): FilterInternalValues<TFilters> =>
  mapValuesWithKeyUncurried((filterValue, filterName) => {
    const filter = filters[filterName]
    if (!filter) return filterValue
    if (!filterValue) return filterValue
    if (getIsMultiselectFilter(filter))
      return (filterValue as string[]).filter((singleValue) =>
        filter.options.some(({value}) => value === singleValue)
      )
    if (getIsSelectFilter(filter))
      return filter.options.some(({value}) => value === filterValue)
        ? filterValue
        : first(filter.options)
    return filterValue
  }, filterValues) as FilterInternalValues<TFilters>

const getInitialValues = <TFilters extends FilterControlsFilters>({
  initialFilterValues,
  shouldIncludeOpenClosedFilter,
  persistenceKey,
  forcedFilterValues,
}: {
  initialFilterValues: FilterInternalValues<TFilters>
  shouldIncludeOpenClosedFilter: boolean
  persistenceKey: string | undefined
  forcedFilterValues: object | undefined
}) => ({
  ...initialFilterValues,
  ...(shouldIncludeOpenClosedFilter
    ? {
        openClosed: OPEN,
      }
    : {}),
  ...(!isEmpty(forcedFilterValues)
    ? forcedFilterValues
    : pick(
        keys(initialFilterValues),
        (persistenceKey && getWorklistTabFilters(persistenceKey)) ?? {}
      )),
})

const OPEN = 'Open'
const CLOSED = 'Closed'
const OPEN_CLOSED_VALUES = [OPEN, CLOSED]

type AddFilterControlsType = <TFilters extends FilterControlsFilters>(opts?: {
  noFilterSelectionTranslationKey?: string
  className?: (props: any) => string
  filterFieldClassName?: (props: any) => string
  renderExtraControls?: (props: any) => ReactElement
  disableFilterControls?: (props: any) => boolean
  noOpenClosedFilter?: boolean
  persistenceKey?: string | ((props: any) => string)
  showClearButton?: boolean | ((props: any) => boolean)
  disableSortFilter?: (filterValues: FilterInternalValues<TFilters>) => boolean
  forceFilterValues?: (props: any) => object | undefined
}) => <
  TProps extends {
    filters: TFilters
    initialFilterValues: FilterInternalValues<TFilters>
  }
>(
  props: TProps
) => TProps & {
  filterValues: FilterValues<TFilters>
  applyForcedFilters: () => void
}

const addFilterControls: AddFilterControlsType = (opts) =>
  flowMax(
    addProps(
      ({filters}) => ({
        shouldIncludeOpenClosedFilter:
          !opts?.noOpenClosedFilter && 'statuses' in filters,
      }),
      ['filters']
    ),
    addProps((props) => ({
      persistenceKey: isFunction(opts?.persistenceKey)
        ? opts!.persistenceKey(props)
        : opts?.persistenceKey,
      showClearButton: !!(isFunction(opts?.showClearButton)
        ? opts!.showClearButton(props)
        : opts?.showClearButton),
    })),
    addProps((props) => ({
      forcedFilterValues: opts?.forceFilterValues
        ? opts?.forceFilterValues(props)
        : undefined,
    })),
    addStateHandlers(
      ({
        initialFilterValues,
        shouldIncludeOpenClosedFilter,
        persistenceKey,
        forcedFilterValues,
      }) => ({
        filterValues: getInitialValues({
          initialFilterValues,
          shouldIncludeOpenClosedFilter,
          persistenceKey,
          forcedFilterValues,
        }),
        lastOpenStatuses: [] as string[],
        lastClosedStatuses: [] as string[],
      }),
      {
        setFilterValues: () => ({filterValues}: {filterValues: any}) => ({
          filterValues,
        }),
        setFilter: ({filterValues, lastOpenStatuses, lastClosedStatuses}) => ({
          name,
          value,
        }: {
          name: string
          value: any
        }) => ({
          filterValues: {
            ...filterValues,
            [name]: value,
            ...(name === 'openClosed'
              ? {
                  statuses:
                    value === OPEN ? lastOpenStatuses : lastClosedStatuses,
                }
              : {}),
          },
          ...(name === 'openClosed'
            ? value === OPEN
              ? {
                  lastClosedStatuses: filterValues.statuses as string[],
                }
              : {
                  lastOpenStatuses: filterValues.statuses as string[],
                }
            : {}),
        }),
        focusFilter: ({filterValues}) => ({
          name,
          value,
        }: {
          name: string
          value: string
        }) => ({
          filterValues: {
            ...filterValues,
            [name]: [value],
          },
        }),
        resetFilters: ({filterValues}, {initialFilterValues}) => () => ({
          filterValues: {
            ...filterValues,
            ...initialFilterValues,
          },
          lastOpenStatuses: [],
          lastClosedStatuses: [],
        }),
      }
    ),
    addHandlers({
      applyForcedFilters: ({
        setFilterValues,
        initialFilterValues,
        shouldIncludeOpenClosedFilter,
        forcedFilterValues,
      }) => () => {
        setFilterValues({
          filterValues: getInitialValues({
            initialFilterValues,
            shouldIncludeOpenClosedFilter,
            persistenceKey: undefined,
            forcedFilterValues,
          }),
        })
      },
    }),
    addProps(
      ({filterValues, filters}) => ({
        filterValues: restrictToValidValues(filterValues, filters),
      }),
      ['filterValues', 'filters']
    ),
    addEffect(
      ({filterValues, persistenceKey}) => () => {
        if (!persistenceKey) return
        setWorklistTabFilters(persistenceKey, filterValues)
      },
      ['filterValues']
    ),
    addTranslationHelpers,
    addProps(
      ({filters, filterValues, shouldIncludeOpenClosedFilter, t}) => ({
        filters: shouldIncludeOpenClosedFilter
          ? {
              openClosed: {
                type: 'select',
                label: t('filters.openClosed'),
                options: OPEN_CLOSED_VALUES.map((value) => ({
                  label: value,
                  value,
                })),
              },
              ...mapValues(filters, (filterSpec, filterName) =>
                filterName === 'statuses'
                  ? {
                      ...filterSpec,
                      options:
                        filterValues.openClosed === CLOSED
                          ? (filterSpec as MultiselectFilter).options.filter(
                              ({isClosed}) => isClosed
                            )
                          : (filterSpec as MultiselectFilter).options.filter(
                              ({isClosed}) => !isClosed
                            ),
                    }
                  : filterSpec
              ),
            }
          : filters,
      }),
      ['filters', 'filterValues', 'shouldIncludeOpenClosedFilter', 't']
    ),
    addProps(
      ({filterValues, filters, initialFilterValues}) => ({
        filterValues: getExposedFilterValues({
          filterValues,
          filters,
          initialFilterValues,
        }),
        filterInternalValues: filterValues,
      }),
      ['filterValues', 'filters', 'initialFilterValues']
    ),
    addWrapper(
      (
        render,
        {
          filters,
          filterInternalValues,
          setFilter,
          focusFilter,
          resetFilters,
          showClearButton,
          ...props
        }
      ) => (
        <>
          <FilterControls
            filters={filters}
            filterValues={filterInternalValues}
            onChange={setFilter}
            onFilterFocus={focusFilter}
            noFilterSelectionTranslationKey={
              opts?.noFilterSelectionTranslationKey ??
              'worklist.noFilterSelection'
            }
            className={opts?.className?.(props)}
            filterFieldClassName={opts?.filterFieldClassName?.(props)}
            extraControls={opts?.renderExtraControls?.(props)}
            disableFilterControls={opts?.disableFilterControls?.(props)}
            resetFilters={resetFilters}
            showClearButton={showClearButton}
            disableSortFilter={
              opts?.disableSortFilter?.(filterInternalValues) ?? false
            }
          />
          {render()}
        </>
      )
    )
  )

export default addFilterControls
