import {AddEffectType} from 'ad-hok/lib/utils/createEffectAdder'
import {addEffect, flowMax} from 'ad-hok'

const addEffectInDevelopment: AddEffectType = (callback, dependencies) =>
  flowMax(
    addEffect(
      (props) => () => {
        if (process.env.NODE_ENV === 'development') {
          callback(props)()
        }
      },
      dependencies
    )
  )

export default addEffectInDevelopment
