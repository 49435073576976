import React from 'react'
import {FC} from 'react'
import {FastField as FormikField} from 'formik'

import {FieldComponentProps} from 'components/TextField'

const HiddenField: FC<FieldComponentProps> = (props) => (
  <FormikField component="input" type="hidden" {...props} />
)

export default HiddenField
