/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {UpdateAccountStatusInput, NjmmisCheckResult} from './globalTypes' // ====================================================
// GraphQL mutation operation: UpdateAccountStatus
// ====================================================

import {deserializeUpdateAccountStatusInput} from './globalTypes'
import {serializeUpdateAccountStatusInput} from './globalTypes'
import {UpdateAccountStatusVariables as UpdateAccountStatusVariablesOrig} from '../types/UpdateAccountStatus'
import {UpdateAccountStatus as UpdateAccountStatusOrig} from '../types/UpdateAccountStatus'
import {deserializeNjmmisCheckResult} from 'graphql/converters'
import {serializeNjmmisCheckResult} from 'graphql/converters'
import formatISO from 'date-fns/formatISO'
import {parseDate} from 'utils/form/fieldTypes'
import {formatISODate} from 'utils/date'
import {UpdateAccountStatus_updateAccountStatus as UpdateAccountStatus_updateAccountStatusOrig} from '../types/UpdateAccountStatus'
export interface UpdateAccountStatus_updateAccountStatus {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
  timeOfService: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location: string | null
  insuranceType: string
  status: string
  createdAt: Date
  updatedAt: Date
  notes: string | null
  accountAmount: number | null
  njmmisCheck: string | null
  previousInsurance: string | null
  invoiceMonth: string | null
  invoiceYear: string | null
  dischargeDate: Date | null
  isScheduled: boolean
}
export const deserializeUpdateAccountStatus_updateAccountStatus = (
  serialized: UpdateAccountStatus_updateAccountStatusOrig
): UpdateAccountStatus_updateAccountStatus => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
})
export const serializeUpdateAccountStatus_updateAccountStatus = (
  deserialized: UpdateAccountStatus_updateAccountStatus
): UpdateAccountStatus_updateAccountStatusOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
})
export interface UpdateAccountStatus {
  /**
   * Update existing account's status
   */
  updateAccountStatus: UpdateAccountStatus_updateAccountStatus
}
export const deserializeUpdateAccountStatus = (
  serialized: UpdateAccountStatusOrig
): UpdateAccountStatus => ({
  ...serialized,
  updateAccountStatus: deserializeUpdateAccountStatus_updateAccountStatus(
    serialized.updateAccountStatus
  ),
})
export const serializeUpdateAccountStatus = (
  deserialized: UpdateAccountStatus
): UpdateAccountStatusOrig => ({
  ...deserialized,
  updateAccountStatus: serializeUpdateAccountStatus_updateAccountStatus(
    deserialized.updateAccountStatus
  ),
})
export interface UpdateAccountStatusVariables {
  account: UpdateAccountStatusInput
}
export const deserializeUpdateAccountStatusVariables = (
  serialized: UpdateAccountStatusVariablesOrig
): UpdateAccountStatusVariables => ({
  ...serialized,
  account: deserializeUpdateAccountStatusInput(serialized.account),
})
export const serializeUpdateAccountStatusVariables = (
  deserialized: UpdateAccountStatusVariables
): UpdateAccountStatusVariablesOrig => ({
  ...deserialized,
  account: serializeUpdateAccountStatusInput(deserialized.account),
})
