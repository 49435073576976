import {TFunction} from 'i18next'
import {sum, max, last} from 'lodash/fp'
import {round} from 'lodash'
import subMonths from 'date-fns/subMonths'
import isDateBefore from 'date-fns/isBefore'
import setDayOfMonth from 'date-fns/setDate'
import addMonths from 'date-fns/addMonths'
import differenceInMonths from 'date-fns/differenceInMonths'
import subDays from 'date-fns/subDays'
import eachMonthOfInterval from 'date-fns/eachMonthOfInterval'

import {
  INCOME_FREQUENCY_MONTHLY,
  INCOME_FREQUENCY_WEEKLY,
  INCOME_FREQUENCY_BIWEEKLY,
  INCOME_FREQUENCY_TWICE_A_MONTH,
  INCOME_FREQUENCY_LUMP_SUM,
  INCOME_SOURCE_TYPE_CASH_SUPPORT_CHARITY_CARE,
  INCOME_SOURCE_TYPE_CASH_SUPPORT,
} from 'utils/form/fieldTypes'
import {Person_person} from 'graphql/deserializedTypes/Person'
import {FederalPovertyLevels_federalPovertyLevels} from 'graphql/deserializedTypes/FederalPovertyLevels'
import {SlideApplicationRules_slideApplicationRules} from 'graphql/deserializedTypes/SlideApplicationRules'
import {
  determineHouseholdSizeFactorsMedicaid,
  determineHouseholdSizePartsFullCharityCare,
  determineHouseholdSizePartsFullMedicaid,
  determineHouseholdSizePartsFullSlide,
  determineHouseholdSizePreliminaryCharityCare,
  determineHouseholdSizePreliminaryMedicaid,
  HOUSEHOLD_BOX_MEDICAID_ADULT_NOT_FILING,
  HOUSEHOLD_BOX_MEDICAID_DEPENDENT,
  HOUSEHOLD_BOX_MEDICAID_FILER,
  HOUSEHOLD_BOX_MEDICAID_JOINT_FILER,
  HOUSEHOLD_BOX_MEDICAID_MINOR_NOT_FILING,
} from 'utils/householdSize'
import {isMinorMedicaid} from 'utils/medicaid'
import {formatCurrency} from 'utils/number'
import {CharityCareApplicationWithInitialDateOfService} from 'utils/charityCare'
import {SlideApplicationWithInitialDateOfService} from 'utils/slide'
import {RyanWhiteApplicationWithInitialDateOfService} from 'utils/ryanWhite'
import {
  ClientType,
  PersonType,
  PersonWithRelationshipType,
  RELATIONSHIP_TYPE_PARENT,
  RELATIONSHIP_TYPE_SIBLING_STEPSIBLING,
  RELATIONSHIP_TYPE_SPOUSE,
  RELATIONSHIP_TYPE_STEPPARENT,
  TAX_FILING_STATUS_FILER,
  TAX_FILING_STATUS_FILING_JOINTLY,
} from 'components/EditPersonForm/schema'
import {PerIncomeSource} from 'utils/getAdditionalData'

type IncomeSource = PersonType['incomeSources'][0]
type Deduction = PersonType['deductions'][0]

export interface PersonWithoutIncome {
  _isPersonWithoutIncome: boolean
}

export const PERSON_WITHOUT_INCOME: PersonWithoutIncome = {
  _isPersonWithoutIncome: true,
}

export const isPersonWithoutIncome = (
  householdMember: PersonType | PersonWithoutIncome
): householdMember is PersonWithoutIncome =>
  '_isPersonWithoutIncome' in householdMember

const PREGNANT_MAX_PERCENT = 205
const ADULT_MAX_PERCENT = 138
const minorMaxPercents = [
  {
    percent: 150,
    copay: null,
    premium: null,
  },
  {
    percent: 200,
    copay: '$5 - $10',
    premium: null,
  },
  {
    percent: 250,
    copay: '$5 - $35',
    premium: '$44.50',
  },
  {
    percent: 300,
    copay: '$5 - $35',
    premium: '$90',
  },
  {
    percent: 355,
    copay: '$5 - $35',
    premium: '$151.50',
  },
]

const MINOR_MAX_PERCENT = last(minorMaxPercents)!.percent

export const isHouseholdIncomeIneligiblePreliminaryMedicaid = ({
  values: {
    person: {estHouseholdIncome: income, pregnant},
    person,
  },
  values,
  federalPovertyLevels,
  t,
}: {
  values: any
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  t: TFunction
}) => {
  const householdSize = determineHouseholdSizePreliminaryMedicaid(values)
  if (householdSize == null) return false

  const povertyLevelThreshold = getPovertyLevelThreshold({
    federalPovertyLevels,
    householdSize,
  })

  const maxPercent = isMinorMedicaid(person)
    ? MINOR_MAX_PERCENT
    : pregnant
    ? PREGNANT_MAX_PERCENT
    : ADULT_MAX_PERCENT
  const percentOfPovertyLevelThreshold = (income / povertyLevelThreshold) * 100
  const isEligible = percentOfPovertyLevelThreshold <= maxPercent
  return {
    isEligible,
    secondaryInfo: t('personForm.eligibilityCheck.federalPovertyLevelPercent', {
      percent: round(percentOfPovertyLevelThreshold),
    }),
  }
}

export const isHouseholdIncomeIneligibleFullMedicaid = ({
  values: {
    person: {pregnant},
    person,
  },
  values,
  federalPovertyLevels,
  lastMonth,
  t,
}: {
  values: {
    person: Person_person
  }
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  t: TFunction
  lastMonth?: boolean
}) => {
  const {month, householdSize, netIncome} = evaluateHouseholdIncomeFullMedicaid(
    {
      values,
      lastMonth,
    }
  )

  const povertyLevelThreshold = getPovertyLevelThreshold({
    federalPovertyLevels,
    householdSize,
  })

  const percentOfPovertyLevelThreshold =
    (netIncome / povertyLevelThreshold) * 100

  const isMinor = isMinorMedicaid(person)
  type Categorization = 'minor' | 'pregnant' | 'adult'
  const categorization: Categorization =
    isMinor && pregnant
      ? percentOfPovertyLevelThreshold <= PREGNANT_MAX_PERCENT
        ? 'pregnant'
        : 'minor'
      : isMinor
      ? 'minor'
      : pregnant
      ? 'pregnant'
      : 'adult'
  let copay = null
  let premium = null
  let maxPercent: number | null = null
  switch (categorization) {
    case 'adult':
      maxPercent = ADULT_MAX_PERCENT
      break
    case 'pregnant':
      maxPercent = PREGNANT_MAX_PERCENT
      break
    default:
      const minorMaxPercentIndex = minorMaxPercents.findIndex(
        ({percent}) => percent >= percentOfPovertyLevelThreshold
      )
      if (minorMaxPercentIndex === -1) {
        maxPercent = last(minorMaxPercents)!.percent
      } else {
        const minorMaxPercentInfo = minorMaxPercents[minorMaxPercentIndex]
        maxPercent = minorMaxPercentInfo.percent
        copay = minorMaxPercentInfo.copay
        premium = minorMaxPercentInfo.premium
      }
  }

  const isEligible = percentOfPovertyLevelThreshold <= maxPercent

  return {
    isEligible,
    month,
    reasonOrInfo: isEligible
      ? [
          premium
            ? t('personForm.applications.premium', {premium})
            : t('personForm.applications.noPremium'),
          copay
            ? t('personForm.applications.copay', {copay})
            : t('personForm.applications.noCopay'),
        ].join(', ')
      : null,
    secondaryInfo: t('personForm.applications.netIncome', {
      netIncome: formatCurrency(netIncome),
      federalPovertyLevelPercent: round(percentOfPovertyLevelThreshold),
    }),
  }
}

export const getDateFromMonthAndYear = (month: string, year: string): Date =>
  new Date(parseInt(year), parseInt(month) - 1)

export const includesMonth = (month: Date) => ({
  startMonth,
  startYear,
  endMonth,
  endYear,
}: {
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
}): boolean => {
  if (!(startMonth && startYear)) return false
  const isAfterStartMonth = isDateBefore(
    getDateFromMonthAndYear(startMonth, startYear),
    setDayOfMonth(month, 2)
  )
  if (!isAfterStartMonth) return false
  if (!(endMonth && endYear)) return true
  const isBeforeEndMonth = isDateBefore(
    month,
    addMonths(getDateFromMonthAndYear(endMonth, endYear), 1)
  )
  return isBeforeEndMonth
}

const getNumMonthsSpannedForMonth = (month: Date) => ({
  startMonth,
  startYear,
  endMonth,
  endYear,
}: {
  startMonth?: string | null
  startYear?: string | null
  endMonth?: string | null
  endYear?: string | null
}): number => {
  if (!(startMonth && startYear)) return 0
  const useEndMonth =
    endMonth && endYear ? getDateFromMonthAndYear(endMonth, endYear) : month
  return (
    differenceInMonths(
      useEndMonth,
      getDateFromMonthAndYear(startMonth, startYear)
    ) + 1
  )
}

export const getAmountForMonth = ({
  month,
  frequency,
  amount,
  startMonth,
  startYear,
  endMonth,
  endYear,
}: {
  month: Date
  frequency: string | null
  amount: number | null
  startMonth?: string | null
  startYear?: string | null
  endMonth?: string | null
  endYear?: string | null
}) => {
  if (!(amount && amount > 0)) return 0
  if (frequency === INCOME_FREQUENCY_MONTHLY) return amount
  if (frequency === INCOME_FREQUENCY_WEEKLY) return 4.333 * amount
  if (frequency === INCOME_FREQUENCY_BIWEEKLY) return 2.166 * amount
  if (frequency === INCOME_FREQUENCY_TWICE_A_MONTH) return 2 * amount
  if (frequency === INCOME_FREQUENCY_LUMP_SUM) {
    const numMonthsSpanned = getNumMonthsSpannedForMonth(month)({
      startMonth,
      startYear,
      endMonth,
      endYear,
    })
    if (numMonthsSpanned <= 0) return 0
    return amount / numMonthsSpanned
  }
  return 0
}

export const getAmountPerYear = ({
  frequency,
  amount,
}: {
  frequency: string | null
  amount: number | null
}): number => {
  if (!(amount && amount > 0)) return 0
  if (frequency === INCOME_FREQUENCY_MONTHLY) return 12 * amount
  if (frequency === INCOME_FREQUENCY_WEEKLY) return 52 * amount
  if (frequency === INCOME_FREQUENCY_BIWEEKLY) return 26 * amount
  if (frequency === INCOME_FREQUENCY_TWICE_A_MONTH) return 24 * amount
  if (frequency === INCOME_FREQUENCY_LUMP_SUM) return amount
  return 0
}

const getIncomeSourceAmountForMonth = (month: Date) => ({
  payFrequency: frequency,
  amount,
  startMonth,
  startYear,
  endMonth,
  endYear,
}: IncomeSource) =>
  getAmountForMonth({
    month,
    frequency,
    amount,
    startMonth,
    startYear,
    endMonth,
    endYear,
  })

const getDeductionAmountForMonth = (month: Date) => ({
  deductionFrequency: frequency,
  amount,
  startMonth,
  startYear,
  endMonth,
  endYear,
}: Deduction) =>
  getAmountForMonth({
    month,
    frequency,
    amount,
    startMonth,
    startYear,
    endMonth,
    endYear,
  })

export const MEDICAID_EXCLUDE_INCOME_SOURCE_TYPES = [
  INCOME_SOURCE_TYPE_CASH_SUPPORT_CHARITY_CARE,
]
export const CHARITY_CARE_EXCLUDE_INCOME_SOURCE_TYPES = [
  INCOME_SOURCE_TYPE_CASH_SUPPORT,
]

export const filterMedicaidIncomeSourceTypes = ({
  incomeType,
}: PersonType['incomeSources'][number]) =>
  !(incomeType && MEDICAID_EXCLUDE_INCOME_SOURCE_TYPES.includes(incomeType))

export const filterCharityCareIncomeSourceTypes = ({
  incomeType,
}: PersonType['incomeSources'][number]) =>
  !(incomeType && CHARITY_CARE_EXCLUDE_INCOME_SOURCE_TYPES.includes(incomeType))

const determineMonthIncomeFullMedicaid = ({
  person: {incomeSources, deductions},
  month,
}: {
  person: PersonType
  month: Date
}): number => {
  const incomeTotal = sum(
    incomeSources
      .filter((incomeSource) => includesMonth(month)(incomeSource))
      .filter(filterMedicaidIncomeSourceTypes)
      .map(getIncomeSourceAmountForMonth(month))
  )
  const deductionsTotal = sum(deductions.map(getDeductionAmountForMonth(month)))
  return incomeTotal - deductionsTotal
}

export const evaluateHouseholdIncomeFullMedicaid = ({
  values,
  lastMonth,
}: {
  values: {
    person: Person_person
  }
  lastMonth?: boolean
}): {
  month: Date
  householdSize: number
  netIncome: number
} => {
  const householdMembers = determineHouseholdMembersFullMedicaid(values)
  const householdMembersToCountTowardIncome = getHouseholdMembersToCountTowardIncomeMedicaid(
    values
  )
  const month = lastMonth ? subMonths(new Date(), 1) : new Date()
  const netIncome = max([
    sum(
      householdMembersToCountTowardIncome.map((householdMember) =>
        isPersonWithoutIncome(householdMember)
          ? 0
          : determineMonthIncomeFullMedicaid({
              person: householdMember,
              month,
            })
      )
    ),
    0,
  ])!
  const householdSize = householdMembers.length
  return {
    month,
    netIncome,
    householdSize,
  }
}

export const getPovertyLevelThreshold = ({
  federalPovertyLevels,
  householdSize,
}: {
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  householdSize: number
}) => {
  const getFederalPovertyLevelMonthlyIncome = (familySize: string) =>
    federalPovertyLevels.find(
      (federalPovertyLevel) => familySize === federalPovertyLevel.familySize
    )!.monthlyIncome
  return householdSize <= 6
    ? getFederalPovertyLevelMonthlyIncome(`${householdSize}`)
    : getFederalPovertyLevelMonthlyIncome('6') +
        (householdSize - 6) *
          getFederalPovertyLevelMonthlyIncome('Each additional')
}

export const determineHouseholdMembersFullMedicaid = (values: {
  person: ClientType
}): (PersonWithRelationshipType | PersonWithoutIncome)[] =>
  determineHouseholdSizePartsFullMedicaid(values).flatMap(
    ([_factor, people]) => people
  )

const getHouseholdMembersToCountTowardIncomeMedicaid = (values: {
  person: ClientType
}): PersonWithRelationshipType[] => {
  const householdMembers = determineHouseholdMembersFullMedicaid(values)
  const householdBox = determineHouseholdSizeFactorsMedicaid(values)
  const householdMembersFullPeople: PersonWithRelationshipType[] = householdMembers.filter(
    (householdMember) => !isPersonWithoutIncome(householdMember)
  ) as PersonWithRelationshipType[]
  return [
    HOUSEHOLD_BOX_MEDICAID_FILER,
    HOUSEHOLD_BOX_MEDICAID_JOINT_FILER,
    HOUSEHOLD_BOX_MEDICAID_DEPENDENT,
  ].includes(householdBox)
    ? householdMembersFullPeople.filter(
        ({personValues: {taxFilingStatus}}) =>
          taxFilingStatus &&
          [TAX_FILING_STATUS_FILER, TAX_FILING_STATUS_FILING_JOINTLY].includes(
            taxFilingStatus
          )
      )
    : householdBox === HOUSEHOLD_BOX_MEDICAID_ADULT_NOT_FILING
    ? householdMembersFullPeople.filter(
        ({relationshipType}) =>
          relationshipType &&
          ['client', RELATIONSHIP_TYPE_SPOUSE].includes(relationshipType)
      )
    : householdBox === HOUSEHOLD_BOX_MEDICAID_MINOR_NOT_FILING
    ? householdMembersFullPeople.some(
        ({relationshipType}) =>
          relationshipType &&
          [RELATIONSHIP_TYPE_PARENT, RELATIONSHIP_TYPE_STEPPARENT].includes(
            relationshipType
          )
      )
      ? householdMembersFullPeople.filter(
          ({relationshipType}) =>
            relationshipType &&
            [RELATIONSHIP_TYPE_PARENT, RELATIONSHIP_TYPE_STEPPARENT].includes(
              relationshipType
            )
        )
      : householdMembersFullPeople.filter(
          ({relationshipType}) =>
            relationshipType &&
            [
              'client',
              RELATIONSHIP_TYPE_SPOUSE,
              RELATIONSHIP_TYPE_SIBLING_STEPSIBLING,
            ].includes(relationshipType)
        )
    : householdMembersFullPeople
}

export const isHouseholdIncomeIneligiblePreliminaryCharityCare = ({
  values: {
    person: {estHouseholdIncome: income},
  },
  values,
  federalPovertyLevels,
  t,
}: {
  values: any
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  t: TFunction
}) => {
  const householdSize = determineHouseholdSizePreliminaryCharityCare(values)
  if (householdSize == null) return false

  const povertyLevelThreshold = getPovertyLevelThreshold({
    federalPovertyLevels,
    householdSize,
  })

  const maxPercent = 300

  const percentOfPovertyLevelThreshold = (income / povertyLevelThreshold) * 100
  const isEligible = percentOfPovertyLevelThreshold <= maxPercent

  return {
    isEligible,
    secondaryInfo: t('personForm.eligibilityCheck.federalPovertyLevelPercent', {
      percent: round(percentOfPovertyLevelThreshold),
    }),
  }
}

const determineNetIncomeCharityCareFull = ({
  householdMembers,
  initialDateOfService,
}: {
  householdMembers: PersonWithRelationshipType[]
  initialDateOfService: Date
}): {
  total: number
  perIncomeSource: PerIncomeSource
} => {
  const perIncomeSource = householdMembers
    .flatMap(({incomeSources}) => incomeSources)
    .filter(getIsIncludedIncomeSourceCharityCare(initialDateOfService))
    .map(({id, incomeType, amount, payFrequency}) => ({
      id,
      incomeType,
      amount: getAmountPerYear({amount, frequency: payFrequency}),
      payFrequency,
      amountPer: amount,
    }))
  return {
    total: sum(perIncomeSource.map(({amount}) => amount)),
    perIncomeSource,
  }
}

export const determineHouseholdMembersFullCharityCare = (values: {
  person: ClientType
}): (PersonWithRelationshipType | PersonWithoutIncome)[] =>
  determineHouseholdSizePartsFullCharityCare(values).flatMap(
    ([_factor, people]) => people
  )

export const getHouseholdMembersToCountTowardIncomeCharityCare = (values: {
  person: ClientType
}): PersonWithRelationshipType[] =>
  determineHouseholdMembersFullCharityCare(values).filter(
    (person) => !isPersonWithoutIncome(person)
  ) as PersonWithRelationshipType[]

export const evaluateHouseholdIncomeFullCharityCare = ({
  values,
  initialDateOfService,
}: {
  values: {
    person: ClientType
  }
  initialDateOfService: Date
}): {
  householdSize: number
  netIncome: number
  netAssets: number
  perIncomeSource: PerIncomeSource
} => {
  const householdMembers = determineHouseholdMembersFullCharityCare(values)
  const householdMembersToCountTowardIncome = getHouseholdMembersToCountTowardIncomeCharityCare(
    values
  )
  const {total: netIncome, perIncomeSource} = determineNetIncomeCharityCareFull(
    {
      householdMembers: householdMembersToCountTowardIncome,
      initialDateOfService,
    }
  )
  const netAssets = sum(
    householdMembersToCountTowardIncome.flatMap(({assets}) =>
      assets.map(({amount}) => amount ?? 0)
    )
  )
  const householdSize = householdMembers.length
  return {
    netIncome,
    householdSize,
    netAssets,
    perIncomeSource,
  }
}

// const determineNetAnnualIncome = (
//   householdMembers: PersonWithRelationshipType[],
//   filterIncomeSource: (
//     incomeSource: PersonType['incomeSources'][number]
//   ) => boolean = () => true
// ): number =>
//   sum(
//     householdMembers
//       .flatMap(({incomeSources}) => incomeSources)
//       .filter(filterIncomeSource)
//       .map(({amount, payFrequency}) =>
//         getAmountPerYear({amount, frequency: payFrequency})
//       )
//   )

export const determineHouseholdMembersFullSlide = (values: {
  person: ClientType
}): (PersonWithRelationshipType | PersonWithoutIncome)[] =>
  determineHouseholdSizePartsFullSlide(values).flatMap(
    ([_factor, people]) => people
  )

// const determineNetIncomeSlideFull = (
//   householdMembers: PersonWithRelationshipType[]
// ): number => determineNetAnnualIncome(householdMembers)

const determineNetIncomeSlideFullFPL = ({
  householdMembers,
  initialDateOfService,
}: {
  householdMembers: PersonWithRelationshipType[]
  initialDateOfService: Date
}): {
  total: number
  perIncomeSource: PerIncomeSource
} => {
  const perIncomeSource = householdMembers
    .flatMap(({incomeSources}) => incomeSources)
    .filter(getIsIncludedIncomeSourceCharityCare(initialDateOfService))
    .map(({id, incomeType, amount, payFrequency}) => ({
      id,
      incomeType,
      amount: getAmountPerYear({amount, frequency: payFrequency}),
      payFrequency,
      amountPer: amount,
    }))
  return {
    total: sum(perIncomeSource.map(({amount}) => amount)),
    perIncomeSource,
  }
}

export const evaluateHouseholdIncomeFullSlideFPL = ({
  values,
  initialDateOfService,
}: {
  values: {
    person: ClientType
  }
  initialDateOfService: Date
}): {
  householdSize: number
  netIncome: number
  perIncomeSource: PerIncomeSource
} => {
  const householdMembers = determineHouseholdMembersFullSlide(values)
  const householdMembersToCountTowardIncome = householdMembers.filter(
    (person) => !isPersonWithoutIncome(person)
  ) as PersonWithRelationshipType[]
  // const netIncome = determineNetIncomeSlideFull(
  //   householdMembersToCountTowardIncome
  // )
  const {total: netIncome, perIncomeSource} = determineNetIncomeSlideFullFPL({
    householdMembers: householdMembersToCountTowardIncome,
    initialDateOfService,
  })
  const householdSize = householdMembers.length
  console.log('Income Source:' + perIncomeSource.length)
  return {
    netIncome,
    householdSize,
    perIncomeSource,
  }
}

// export const evaluateHouseholdIncomeFullSlide = ({
//   values,
//   initialDateOfService,
// }: {
//   values: {
//     person: ClientType
//     initialDateOfService: Date
//   }
// }): {
//   householdSize: number
//   netIncome: number
//   perIncomeSource: PerIncomeSource
// } => {
//   const householdMembers = determineHouseholdMembersFullSlide(values)
//   const householdMembersToCountTowardIncome = householdMembers.filter(
//     (person) => !isPersonWithoutIncome(person)
//   ) as PersonWithRelationshipType[]
//   const {total: netIncome, perIncomeSource}  = determineNetIncomeSlideFullFPL(
//     householdMembersToCountTowardIncome,
//     initialDateOfService,
//   )

//   const householdSize = householdMembers.length
//   return {
//     netIncome,
//     householdSize,
//     perIncomeSource,
//   }
// }

const charityCareMaxPercents = [
  {
    percent: 200,
    percentPaidByPatient: 0,
  },
  {
    percent: 225,
    percentPaidByPatient: 20,
  },
  {
    percent: 250,
    percentPaidByPatient: 40,
  },
  {
    percent: 275,
    percentPaidByPatient: 60,
  },
  {
    percent: 300,
    percentPaidByPatient: 80,
  },
]

export const CHARITY_CARE_INCOME_ANNUAL_NUM_MONTHS = -1

export const isHouseholdIncomeIneligibleFullCharityCareWithFPL = ({
  values,
  federalPovertyLevels,
  initialDateOfService,
  t,
}: {
  values: {
    person: ClientType
  }
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  initialDateOfService: Date
  t: TFunction
}) => {
  const {
    householdSize,
    netIncome,
    netAssets,
    perIncomeSource,
  } = evaluateHouseholdIncomeFullCharityCare({
    values,
    initialDateOfService,
  })

  const isIneligibleDueToAssets =
    householdSize > 1 ? netAssets >= 15000 : netAssets >= 7500

  const povertyLevelThreshold = getPovertyLevelThreshold({
    federalPovertyLevels,
    householdSize,
  })

  const percentOfPovertyLevelThreshold =
    (netIncome / (povertyLevelThreshold * 12)) * 100

  let percentPaidByPatient: number | null = null
  let isIneligibleDueToIncome = false
  const maxPercentInfo = charityCareMaxPercents.find(
    ({percent}) => percent >= percentOfPovertyLevelThreshold
  )
  if (!maxPercentInfo) {
    isIneligibleDueToIncome = true
  } else {
    percentPaidByPatient = maxPercentInfo.percentPaidByPatient
  }

  const isEligible = !isIneligibleDueToAssets && !isIneligibleDueToIncome

  return {
    isEligible,
    reasonOrInfo: isEligible
      ? t('personForm.applications.paidByPatient', {
          percent: percentPaidByPatient!,
        })
      : isIneligibleDueToAssets
      ? t('personForm.applications.ineligibleReasonAssets')
      : t('personForm.applications.ineligibleReasonIncome'),
    secondaryInfo: [
      t('personForm.applications.annualIncome', {
        netIncome: formatCurrency(netIncome),
        federalPovertyLevelPercent: round(percentOfPovertyLevelThreshold),
      }),
      t('personForm.applications.assets', {
        assets: formatCurrency(netAssets),
      }),
    ].join(', '),
    numMonths: CHARITY_CARE_INCOME_ANNUAL_NUM_MONTHS,
    percentPaidByPatient,
    additionalData: {perIncomeSource},
  }
}

export const isHouseholdIncomeIneligibleFullCharityCare = ({
  values,
  federalPovertyLevels,
  application: {initialDateOfService},
  t,
}: {
  values: {
    person: ClientType
  }
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  application: CharityCareApplicationWithInitialDateOfService
  t: TFunction
}) => {
  const {
    householdSize,
    netIncome,
    netAssets,
    perIncomeSource,
  } = evaluateHouseholdIncomeFullCharityCare({
    values,
    initialDateOfService,
  })

  // const fpl = hello(initialDateOfService)
  // let fpl_hasvalue = false
  // if (fpl) fpl_hasvalue = true

  // console.log(fpl_hasvalue)
  const isIneligibleDueToAssets =
    householdSize > 1 ? netAssets >= 15000 : netAssets >= 7500

  const povertyLevelThreshold = getPovertyLevelThreshold({
    federalPovertyLevels,
    householdSize,
  })

  const percentOfPovertyLevelThreshold =
    (netIncome / (povertyLevelThreshold * 12)) * 100

  let percentPaidByPatient: number | null = null
  let isIneligibleDueToIncome = false
  const maxPercentInfo = charityCareMaxPercents.find(
    ({percent}) => percent >= percentOfPovertyLevelThreshold
  )
  if (!maxPercentInfo) {
    isIneligibleDueToIncome = true
  } else {
    percentPaidByPatient = maxPercentInfo.percentPaidByPatient
  }

  const isEligible = !isIneligibleDueToAssets && !isIneligibleDueToIncome

  return {
    isEligible,
    reasonOrInfo: isEligible
      ? t('personForm.applications.paidByPatient', {
          percent: percentPaidByPatient!,
        })
      : isIneligibleDueToAssets
      ? t('personForm.applications.ineligibleReasonAssets')
      : t('personForm.applications.ineligibleReasonIncome'),
    secondaryInfo: [
      t('personForm.applications.annualIncome', {
        netIncome: formatCurrency(netIncome),
        federalPovertyLevelPercent: round(percentOfPovertyLevelThreshold),
      }),
      t('personForm.applications.assets', {
        assets: formatCurrency(netAssets),
      }),
    ].join(', '),
    numMonths: CHARITY_CARE_INCOME_ANNUAL_NUM_MONTHS,
    percentPaidByPatient,
    additionalData: {perIncomeSource},
  }
}
/******************  SLIDE START NEW CODE ********************/

const getMatchingSlideScaleLevel = (
  slideMaxPercents: SlideApplicationRules_slideApplicationRules[],
  percentOfPovertyLevelThreshold: number
): {
  maxPercentInfo: SlideApplicationRules_slideApplicationRules | null
  isLastLevel: boolean
} => {
  const matchingPercentIndex = slideMaxPercents.findIndex(
    (
      item: SlideApplicationRules_slideApplicationRules,
      index: number,
      list: Array<SlideApplicationRules_slideApplicationRules>
    ) =>
      index < list.length - 1 &&
      percentOfPovertyLevelThreshold <= item.incomePercentage
  )
  if (matchingPercentIndex >= 0) {
    return {
      maxPercentInfo: slideMaxPercents[matchingPercentIndex],
      isLastLevel: matchingPercentIndex === slideMaxPercents.length - 1,
    }
  } else {
    return {
      maxPercentInfo: slideMaxPercents[slideMaxPercents.length - 1],
      isLastLevel: true,
    }
  }
}

export const isHouseholdIncomeIneligibleFullSlideWithFPL = ({
  values,
  federalPovertyLevels,
  slideApplicationRules,
  initialDateOfService,
  t,
}: {
  values: {
    person: ClientType
  }
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  slideApplicationRules: SlideApplicationRules_slideApplicationRules[]
  initialDateOfService: Date
  t: TFunction
}) => {
  const {
    householdSize,
    netIncome,
    perIncomeSource,
  } = evaluateHouseholdIncomeFullSlideFPL({
    values,
    initialDateOfService,
  })

  const povertyLevelThreshold = getPovertyLevelThreshold({
    federalPovertyLevels,
    householdSize,
  })
  const percentOfPovertyLevelThreshold =
    (netIncome / (povertyLevelThreshold * 12)) * 100

  let copayInsuredUninsured: string | undefined | null = ''
  let reducedFee: string | undefined | null = ''
  let plan: string | undefined = ''

  let isIneligibleDueToIncome = false
  const {maxPercentInfo, isLastLevel} = getMatchingSlideScaleLevel(
    slideApplicationRules,
    percentOfPovertyLevelThreshold
  )

  if (isLastLevel) {
    isIneligibleDueToIncome = true
  }
  copayInsuredUninsured = maxPercentInfo?.copayInsuredUninsured
  reducedFee = maxPercentInfo?.reducedFee
  plan = maxPercentInfo?.plan

  const isEligible = !isIneligibleDueToIncome

  return {
    isEligible,
    reasonOrInfo: isEligible
      ? t('personForm.applications.slidePlan', {
          copayInsuredUninsured: copayInsuredUninsured!,
          reducedFee: reducedFee!,
          plan: plan!,
        })
      : t('personForm.applications.ineligibleReasonIncome', {
          copayInsuredUninsured: copayInsuredUninsured!,
          reducedFee: reducedFee!,
          plan: plan!,
        }),
    secondaryInfo: [
      t('personForm.applications.annualIncome', {
        netIncome: formatCurrency(netIncome),
        federalPovertyLevelPercent: round(percentOfPovertyLevelThreshold),
      }),
    ].join(', '),
    numMonths: CHARITY_CARE_INCOME_ANNUAL_NUM_MONTHS,
    copayInsuredUninsured,
    reducedFee,
    plan,
    additionalData: {perIncomeSource},
  }
}

/******************  SLIDE NEW CODE END ********************/
export const isHouseholdIncomeIneligibleFullSlide = ({
  values,
  federalPovertyLevels,
  slideApplicationRules,
  application: {initialDateOfService},
  t,
}: {
  values: {
    person: ClientType
  }
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  slideApplicationRules: SlideApplicationRules_slideApplicationRules[]
  application: SlideApplicationWithInitialDateOfService
  t: TFunction
}) => {
  const {
    householdSize,
    netIncome,
    perIncomeSource,
  } = evaluateHouseholdIncomeFullSlideFPL({
    values,
    initialDateOfService,
  })

  const povertyLevelThreshold = getPovertyLevelThreshold({
    federalPovertyLevels,
    householdSize,
  })

  const percentOfPovertyLevelThreshold =
    (netIncome / (povertyLevelThreshold * 12)) * 100
  const {maxPercentInfo} = getMatchingSlideScaleLevel(
    slideApplicationRules,
    percentOfPovertyLevelThreshold
  )
  return {
    isEligible: true,
    reasonOrInfo: t('personForm.applications.scaleLevel', {
      level: maxPercentInfo?.plan,
    }),
    secondaryInfo: t('personForm.applications.annualIncome', {
      netIncome: formatCurrency(netIncome),
      federalPovertyLevelPercent: round(percentOfPovertyLevelThreshold),
    }),
    additionalData: {perIncomeSource},
  }
}

export const isHouseholdIncomeIneligibleFullRyanWhiteWithFPL = ({
  values,
  federalPovertyLevels,
  initialDateOfService,
  t,
}: {
  values: {
    person: ClientType
  }
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  initialDateOfService: Date
  t: TFunction
}) => {
  const {
    householdSize,
    netIncome,
    perIncomeSource,
  } = evaluateHouseholdIncomeFullSlideFPL({
    values,
    initialDateOfService,
  })

  const povertyLevelThreshold = getPovertyLevelThreshold({
    federalPovertyLevels,
    householdSize,
  })

  const percentOfPovertyLevelThreshold =
    (netIncome / (povertyLevelThreshold * 12)) * 100

  let isIneligibleDueToIncome = false
  if (percentOfPovertyLevelThreshold > 200) {
    isIneligibleDueToIncome = true
  }
  const isEligible = !isIneligibleDueToIncome

  return {
    isEligible,
    reasonOrInfo: isEligible
      ? 'Income'
      : t('personForm.applications.ineligibleReasonIncome', {}),
    secondaryInfo: t('personForm.applications.annualIncome', {
      netIncome: formatCurrency(netIncome),
      federalPovertyLevelPercent: round(percentOfPovertyLevelThreshold),
    }),
    additionalData: {perIncomeSource},
  }
}

export const isHouseholdIncomeIneligibleFullRyanWhite = ({
  values,
  federalPovertyLevels,
  application: {initialDateOfService},
  t,
}: {
  values: {
    person: ClientType
  }
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  application: RyanWhiteApplicationWithInitialDateOfService
  t: TFunction
}) => {
  const {
    householdSize,
    netIncome,
    perIncomeSource,
  } = evaluateHouseholdIncomeFullSlideFPL({
    values,
    initialDateOfService,
  })

  const povertyLevelThreshold = getPovertyLevelThreshold({
    federalPovertyLevels,
    householdSize,
  })

  const percentOfPovertyLevelThreshold =
    (netIncome / (povertyLevelThreshold * 12)) * 100

  return {
    isEligible: true,
    // reasonOrInfo: t('personForm.applications.scaleLevel', {
    //   level: getSlideScaleLevel(percentOfPovertyLevelThreshold),
    // }),
    secondaryInfo: t('personForm.applications.annualIncome', {
      netIncome: formatCurrency(netIncome),
      federalPovertyLevelPercent: round(percentOfPovertyLevelThreshold),
    }),
    additionalData: {perIncomeSource},
  }
}

export const getIsIncludedIncomeSourceCharityCare = (
  initialDateOfService: Date
) => (
  incomeSource: PersonWithRelationshipType['incomeSources'][number]
): boolean => {
  const monthsIncludingThisMonth = eachMonthOfInterval({
    start: subDays(initialDateOfService, 29),
    end: initialDateOfService,
  })

  return (
    filterCharityCareIncomeSourceTypes(incomeSource) &&
    monthsIncludingThisMonth.some((month) => includesMonth(month)(incomeSource))
  )
}
