import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps, branch, renderNothing} from 'ad-hok'
import {generatePath} from 'react-router'

import {addPersonContext} from 'components/PersonDetail/personContext'
import {
  determineDocuments,
  RequiredDocument,
} from 'components/EditPersonForm/documents'
import Grid from 'components/Grid'
import Body1 from 'components/Body1'
import Link from 'components/Link'
import {editApplicationPath} from 'components/TopLevelRoutes'
import {getReconciledDocuments} from 'components/EditPersonForm/DocumentsSection'
import {addTranslationHelpers} from 'utils/i18n'
import {getExtendedName} from 'utils/name'
import {personDetailPath} from 'components/PersonDetail/index'
import {makeClasses, addClasses} from 'theme'
import Paper from 'components/Paper'
import {getApplicationShortName} from 'utils/application'
import typedAs from 'utils/typedAs'
import {
  ReconciledDocument,
  PersistedDocument,
} from 'utils/persistedDocumentTypes'
import PersistedDocumentCheckMarkOrNecessaryIcon from 'components/PersistedDocumentCheckMarkOrNecessaryIcon'
import {
  Person_person,
  Person_person_allOpenHouseholdMemberApplications_MedicaidApplication_person,
  Person_person_relationships_otherPerson,
  Person_person_openApplications_MedicaidApplication,
  Person_person_openApplications_MonitorApplication,
  Person_person_openApplications_CharityCareApplication,
  Person_person_allOpenHouseholdMemberApplications_MedicaidApplication,
  Person_person_allOpenHouseholdMemberApplications_MonitorApplication,
  Person_person_allOpenHouseholdMemberApplications_CharityCareApplication,
  Person_person_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships,
  Person_person_openApplications_SlideApplication,
  Person_person_allOpenHouseholdMemberApplications_SlideApplication,
  Person_person_openApplications_RyanWhiteApplication,
  Person_person_allOpenHouseholdMemberApplications_RyanWhiteApplication,
} from 'graphql/deserializedTypes/Person'

const classes = makeClasses((theme) => ({
  applicationContainer: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    minWidth: 400,
  },
  applicationContents: {
    marginLeft: theme.spacing(1),
  },
  personContainer: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  checkMarkContainer: {
    marginLeft: theme.spacing(2),
  },
}))

interface ReconciledDocumentProps {
  reconciledDocument: ReconciledDocument
}

const ReconciledDocumentRow: FC<ReconciledDocumentProps> = flowMax(
  addDisplayName('ReconciledDocumentRow'),
  addClasses(classes),
  ({reconciledDocument: {documentType, persistedDocument}, classes}) => (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      justify="space-between"
    >
      <Body1>{documentType}</Body1>
      <PersistedDocumentCheckMarkOrNecessaryIcon
        persistedDocument={persistedDocument}
        className={classes.checkMarkContainer}
      />
    </Grid>
  )
)

interface ApplicationLevelDocumentsProps {
  requiredDocuments: RequiredDocument[]
  persistedDocuments: PersistedDocument[]
}

const ApplicationLevelDocuments: FC<ApplicationLevelDocumentsProps> = flowMax(
  addDisplayName('ApplicationLevelDocuments'),
  addProps(
    ({requiredDocuments, persistedDocuments}) => ({
      requiredDocuments: requiredDocuments.filter(({personId}) => !personId),
      persistedDocuments: persistedDocuments.filter(({person}) => !person),
    }),
    ['requiredDocuments', 'persistedDocuments']
  ),
  addProps(
    ({requiredDocuments, persistedDocuments}) => ({
      reconciledDocuments: getReconciledDocuments({
        persistedDocuments,
        requiredDocuments,
      }),
    }),
    ['requiredDocuments', 'persistedDocuments']
  ),
  ({reconciledDocuments}) => (
    <div>
      {reconciledDocuments.map((reconciledDocument, index) => (
        <ReconciledDocumentRow
          reconciledDocument={reconciledDocument}
          key={`${index}-${reconciledDocument.documentType}`}
        />
      ))}
    </div>
  )
)

type ClientType =
  | Person_person
  | Person_person_allOpenHouseholdMemberApplications_MedicaidApplication_person
type PersonType =
  | ClientType
  | Omit<
      Person_person_relationships_otherPerson,
      'openApplications' | 'openHouseholdMemberApplications'
    >
type ApplicationType =
  | Person_person_openApplications_MedicaidApplication
  | Person_person_openApplications_MonitorApplication
  | Person_person_openApplications_CharityCareApplication
  | Person_person_openApplications_SlideApplication
  | Person_person_openApplications_RyanWhiteApplication
  | Person_person_allOpenHouseholdMemberApplications_MedicaidApplication
  | Person_person_allOpenHouseholdMemberApplications_MonitorApplication
  | Person_person_allOpenHouseholdMemberApplications_CharityCareApplication
  | Person_person_allOpenHouseholdMemberApplications_SlideApplication
  | Person_person_allOpenHouseholdMemberApplications_RyanWhiteApplication

interface PersonDocumentsProps {
  person: PersonType
  requiredDocuments: RequiredDocument[]
  persistedDocuments: PersistedDocument[]
}

const PersonDocuments: FC<PersonDocumentsProps> = flowMax(
  addDisplayName('PersonDocuments'),
  addProps(
    ({requiredDocuments, persistedDocuments, person}) => ({
      requiredDocuments: requiredDocuments.filter(
        ({personId}) => personId === person.id
      ),
      persistedDocuments: persistedDocuments.filter(
        ({person: persistedDocumentPerson}) =>
          persistedDocumentPerson?.id === person.id
      ),
    }),
    ['requiredDocuments', 'persistedDocuments', 'person']
  ),
  addProps(
    ({requiredDocuments, persistedDocuments}) => ({
      reconciledDocuments: getReconciledDocuments({
        requiredDocuments,
        persistedDocuments,
      }),
    }),
    ['requiredDocuments', 'persistedDocuments']
  ),
  branch(
    ({reconciledDocuments}) => !reconciledDocuments.length,
    renderNothing()
  ),
  addTranslationHelpers,
  addClasses(classes),
  ({reconciledDocuments, person, classes, t}) => (
    <div className={classes.personContainer}>
      <Link highlight to={generatePath(personDetailPath, {id: person.id})}>
        {getExtendedName({
          ...person,
          t,
        })}
      </Link>
      {reconciledDocuments.map((reconciledDocument, index) => (
        <ReconciledDocumentRow
          reconciledDocument={reconciledDocument}
          key={`${index}-${reconciledDocument.documentType}`}
        />
      ))}
    </div>
  )
)

interface OpenApplicationDocumentsProps {
  application: ApplicationType
  requiredDocuments: RequiredDocument[]
  person: ClientType
}

const OpenApplicationDocuments: FC<OpenApplicationDocumentsProps> = flowMax(
  addDisplayName('OpenApplicationDocuments'),
  addProps(
    ({application, requiredDocuments}) => ({
      requiredDocuments: requiredDocuments.filter(
        ({applicationId}) => application.id === applicationId
      ),
    }),
    ['application', 'requiredDocuments']
  ),
  addClasses(classes),
  ({
    requiredDocuments,
    application: {documents: persistedDocuments},
    application,
    person,
    classes,
  }) => (
    <Paper className={classes.applicationContainer}>
      <Link
        highlight
        to={generatePath(editApplicationPath, {id: application.id})}
      >
        {getApplicationShortName(application)}
      </Link>
      <div className={classes.applicationContents}>
        <ApplicationLevelDocuments
          requiredDocuments={requiredDocuments}
          persistedDocuments={persistedDocuments}
        />
        <PersonDocuments
          person={person}
          requiredDocuments={requiredDocuments}
          persistedDocuments={persistedDocuments}
        />
        {typedAs<
          Person_person_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships[]
        >(person.relationships).map(({otherPerson}) => (
          <PersonDocuments
            person={otherPerson}
            requiredDocuments={requiredDocuments}
            persistedDocuments={persistedDocuments}
            key={otherPerson.id}
          />
        ))}
      </div>
    </Paper>
  )
)

const PersonOpenApplicationDocuments: FC = flowMax(
  addDisplayName('PersonOpenApplicationDocuments'),
  addPersonContext,
  addProps(
    ({
      person: {openApplications, allOpenHouseholdMemberApplications},
      person,
    }) => ({
      openApplicationsWithPerson: [
        ...openApplications.map((openApplication) => ({
          application: openApplication,
          person,
        })),
        ...allOpenHouseholdMemberApplications.map(
          (openHouseholdMemberApplication) => ({
            application: openHouseholdMemberApplication,
            person: openHouseholdMemberApplication.person,
          })
        ),
      ],
    })
  ),
  addProps(
    ({openApplicationsWithPerson}) => ({
      openApplicationsWithPersonAndRequiredDocuments: openApplicationsWithPerson.map(
        ({application, person}) => ({
          application,
          person,
          requiredDocuments: determineDocuments({person}, [application]),
        })
      ),
    }),
    ['openApplicationsWithPerson']
  ),
  ({openApplicationsWithPersonAndRequiredDocuments}) => (
    <div>
      {openApplicationsWithPersonAndRequiredDocuments.map(
        ({application, person, requiredDocuments}) => (
          <OpenApplicationDocuments
            application={application}
            person={person}
            requiredDocuments={requiredDocuments}
            key={application.id}
          />
        )
      )}
    </div>
  )
)

export default PersonOpenApplicationDocuments
