import React from 'react'
import {flowMax, addDisplayName} from 'ad-hok'
import {FC} from 'react'
import {generatePath} from 'react-router'
import {branchIfEmpty} from 'ad-hok-utils'

import {addClasses, makeClasses} from 'theme'
import {editApplicationPath} from 'components/TopLevelRoutes'
import Link from 'components/Link'
import Grid from 'components/Grid'
import Body1 from 'components/Body1'
import {BenefitOutcome} from 'components/BenefitOutcomeFormDialog'
import {addTranslationHelpers} from 'utils/i18n'
import {
  getFormattedBenefitOutcomeDateRange,
  getFormattedBenefitOutcomeTitle,
} from 'utils/benefitOutcome'

const classes = makeClasses((theme) => ({
  container: {
    marginTop: -theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(1),
    color: '#f44336',
  },
  outcomeTitle: {
    flex: 1.6,
  },
  outcomeDateRange: {
    flex: 1,
  },
  outcomeAppLink: {
    flex: 1,
  },
}))

interface Props {
  overlappingBenefitOutcomes: BenefitOutcome[]
}

const OverlappingBenefitOutcomes: FC<Props> = flowMax(
  addDisplayName('OverlappingBenefitOutcomes'),
  addTranslationHelpers,
  addClasses(classes),
  branchIfEmpty('overlappingBenefitOutcomes'),
  ({overlappingBenefitOutcomes, classes, t}) => (
    <div className={classes.container}>
      <Body1 className={classes.title}>
        {t('benefitOutcomeForm.benefitsOverlappingTitle')}
      </Body1>
      {overlappingBenefitOutcomes.map(({application, ...benefitOutcome}) => (
        <Grid container direction="row" key={benefitOutcome.id}>
          <Grid item className={classes.outcomeTitle}>
            <Body1>
              {getFormattedBenefitOutcomeTitle({...benefitOutcome, t})}
            </Body1>
          </Grid>
          <Grid item className={classes.outcomeDateRange}>
            <Body1>{getFormattedBenefitOutcomeDateRange(benefitOutcome)}</Body1>
          </Grid>
          {application && (
            <Grid item className={classes.outcomeAppLink}>
              <Link
                to={generatePath(editApplicationPath, {
                  id: application.id,
                })}
                highlight
                target="_self"
              >
                <Body1>
                  {t('benefitOutcomeForm.linkedApplication', {
                    id: application.id,
                  })}
                </Body1>
              </Link>
            </Grid>
          )}
        </Grid>
      ))}
    </div>
  )
)

export default OverlappingBenefitOutcomes
