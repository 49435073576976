import {TFunction} from 'i18next'
import subMonths from 'date-fns/subMonths'
import differenceInYears from 'date-fns/differenceInYears'

import {EligibilityDeterminationInput} from 'graphql/deserializedTypes/globalTypes'
import {FederalPovertyLevels_federalPovertyLevels} from 'graphql/deserializedTypes/FederalPovertyLevels'
import {
  eligibilityStatusToApiValue,
  getEligibilityStatus,
} from 'utils/eligibilityStatus'
import {
  CI_STATUS_LEGAL_RESIDENT,
  CI_STATUS_OTHER,
} from 'components/EditPersonForm/schema'
import {getHouseholdSizeDescriptionFullMedicaid} from 'utils/householdSize'
import {DeterminedEligibilitiesForFullMedicaid} from 'utils/form/determiners'
import {isHouseholdIncomeIneligibleFullMedicaid} from 'utils/income'
import {getAge} from 'utils/date'

interface CheckResult {
  input: EligibilityDeterminationInput
  determination: DeterminedEligibilitiesForFullMedicaid
}

const getEligibilityAndReason = ({
  lastMonth,
  person,
  federalPovertyLevels,
  t,
}: {
  lastMonth: boolean
  person: any
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  t: TFunction
}) => {
  if (person.livesInNj === false) {
    return {
      isEligible: false,
      reasonOrInfo: t('personForm.ineligibilityReasons.livesInNj'),
      secondaryInfo: '',
    }
  }

  if (
    person.pregnant !== true &&
    person.ciStatus === CI_STATUS_OTHER &&
    getAge(person.dob) >= 19
  ) {
    return {
      isEligible: false,
      reasonOrInfo: t('personForm.ineligibilityReasons.ciStatusOther'),
      secondaryInfo: '',
    }
  }

  if (
    differenceInYears(new Date(), person.dateOfEntry) < 5 &&
    person.pregnant !== true &&
    getAge(person.dob) >= 19 &&
    person.ciStatus === CI_STATUS_LEGAL_RESIDENT
  ) {
    return {
      isEligible: false,
      reasonOrInfo: t('personForm.ineligibilityReasons.dateOfEntry'),
      secondaryInfo: '',
    }
  }

  const householdIncomeEligibility = isHouseholdIncomeIneligibleFullMedicaid({
    values: {person},
    federalPovertyLevels,
    lastMonth,
    t,
  })

  if (!householdIncomeEligibility.isEligible) {
    return {
      isEligible: false,
      reasonOrInfo: t('personForm.ineligibilityReasons.income'),
      secondaryInfo: householdIncomeEligibility.secondaryInfo,
    }
  }

  return {isEligible: null, reasonOrInfo: '', secondaryInfo: ''}
}

const checkMedicaidEligibilityFor = ({
  benefit,
  month,
  person,
  application,
  federalPovertyLevels,
  t,
}: {
  benefit: 'medicaid' | 'medicaid-lastMonth'
  month: Date
  person: any
  application?: {id: string}
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  t: TFunction
}): CheckResult => {
  const eligibilityInfo = getEligibilityAndReason({
    lastMonth: benefit === 'medicaid-lastMonth',
    person,
    federalPovertyLevels,
    t,
  })

  const householdSizeDescription = getHouseholdSizeDescriptionFullMedicaid(
    {person},
    t
  )

  return {
    input: {
      benefit,
      personId: person.id,
      applicationId: application?.id,
      status: eligibilityStatusToApiValue(
        getEligibilityStatus({
          isEligible: eligibilityInfo.isEligible,
        })
      ),
      householdSizeDescription,
      reasonOrInfo: eligibilityInfo.reasonOrInfo,
      secondaryInfo: eligibilityInfo.secondaryInfo,
      month,
    },
    determination: {
      name: benefit,
      isEligible: eligibilityInfo.isEligible,
      isPreliminary: false,
      reasonOrInfo: eligibilityInfo.reasonOrInfo,
      secondaryInfo: eligibilityInfo.secondaryInfo,
      month,
    },
  }
}

export const checkMedicaidEligibility = ({
  person,
  application,
  federalPovertyLevels,
  t,
}: {
  person: any
  application?: {id: string}
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  t: TFunction
}): CheckResult[] => {
  const month = new Date()
  return [
    {benefit: 'medicaid' as const, month},
    {benefit: 'medicaid-lastMonth' as const, month: subMonths(month, 1)},
  ].map((benefitInfo) =>
    checkMedicaidEligibilityFor({
      ...benefitInfo,
      person,
      application,
      federalPovertyLevels,
      t,
    })
  )
}
