import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import {TFunction} from 'i18next'
import {getContextHelpers, toObjectKeys} from 'ad-hok-utils'

import {addTranslationHelpers} from 'utils/i18n'
import {addClasses, makeClasses} from 'theme'
import {getLongDate} from 'utils/date'
import {getExtendedName} from 'utils/name'
import {getFormattedSsn} from 'utils/ssn'
import {addFormikTyped} from 'utils/form/formik'
import {FormSchema} from 'utils/form/schema'
import {SelectFieldOption, FieldType} from 'utils/form/fieldTypes'
import DisplayItem from 'components/DisplayItem'
import {Application_application} from 'graphql/deserializedTypes/Application'

const classes = makeClasses((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
}))

interface PointOfContactPerson {
  id: string
  firstName: string | null
  lastName: string | null
  preferredName: string | null
  middleName: string | null
  suffix: string | null
  ssn: string | null
  dob: Date | null
}

interface PointOfContactPersonRelationship {
  relationshipType: string | null
  otherPerson: PointOfContactPerson
}

interface PointOfContactPersonWithRelationshipType
  extends PointOfContactPerson {
  relationshipType: string | null
}

const getPrimaryPointOfContactPeople = (
  person: PointOfContactPerson,
  relationships: PointOfContactPersonRelationship[]
): PointOfContactPersonWithRelationshipType[] => [
  {
    ...person,
    relationshipType: 'Client',
  },
  ...relationships.map(({relationshipType, otherPerson}) => ({
    ...otherPerson,
    relationshipType,
  })),
]

const buildPrimaryPointOfContactOptions = (
  primaryPointOfContactPeople: PointOfContactPersonWithRelationshipType[],
  t: TFunction
) =>
  primaryPointOfContactPeople.map((person) => ({
    label: `${getExtendedName({...person, t})} - ${
      person.relationshipType || t('general.unknown')
    }`,
    value: person.id,
  }))

interface PrimaryPointOfContactContextValue {
  primaryPointOfContact: PointOfContactPersonWithRelationshipType | null
}

const {
  addProvider: addPrimaryPointOfContactContextProvider,
  addConsumer: addPrimaryPointOfContactContext,
} = getContextHelpers<PrimaryPointOfContactContextValue>(
  toObjectKeys(['primaryPointOfContact'])
)

export const PrimaryPointOfContactDetails: FC = flowMax(
  addDisplayName('PrimaryPointOfContactDetails'),
  addPrimaryPointOfContactContext,
  addClasses(classes),
  addTranslationHelpers,
  ({primaryPointOfContact, classes, t}) => (
    <div className={classes.container}>
      {primaryPointOfContact && (
        <>
          <DisplayItem
            i18nKey="applicationForm.ssnDisplay"
            translations={{
              ssn: getFormattedSsn({ssn: primaryPointOfContact.ssn, t}),
            }}
          />
          <DisplayItem
            i18nKey="applicationForm.dobDisplay"
            translations={{
              dob: primaryPointOfContact.dob
                ? getLongDate(primaryPointOfContact.dob)
                : t('general.unknown')!,
            }}
          />
        </>
      )}
    </div>
  )
)

type AddPrimaryPointOfContactProviderAndOptionsType = (
  schema: FormSchema<{
    application: {
      primaryPointOfContactId: FieldType<string, string | null>
    }
  }>
) => <
  TProps extends {
    application: Application_application
  }
>(
  props: TProps
) => TProps & {primaryPointOfContactOptions: SelectFieldOption[]}

export const addPrimaryPointOfContactProviderAndOptions: AddPrimaryPointOfContactProviderAndOptionsType = (
  schema
) =>
  flowMax(
    addFormikTyped(schema),
    addProps(
      ({application}) => ({
        primaryPointOfContactPeople: getPrimaryPointOfContactPeople(
          application.person,
          application.person.relationships
        ),
      }),
      ['application']
    ),
    addProps(
      ({
        formik: {
          values: {
            application: {primaryPointOfContactId},
          },
        },
        primaryPointOfContactPeople,
      }) => ({
        primaryPointOfContact:
          primaryPointOfContactPeople.find(
            (person) => person.id === primaryPointOfContactId
          ) ?? null,
      }),
      [
        'formik.values.application.primaryPointOfContactId',
        'primaryPointOfContactPeople',
      ]
    ),
    addPrimaryPointOfContactContextProvider,
    addTranslationHelpers,
    addProps(
      ({primaryPointOfContactPeople, t}) => ({
        primaryPointOfContactOptions: buildPrimaryPointOfContactOptions(
          primaryPointOfContactPeople,
          t
        ),
      }),
      ['primaryPointOfContactPeople', 't']
    )
  )
