import React, {ChangeEvent, FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addWrapper,
  addState,
  addHandlers,
  // addMemoBoundary,
  addStateHandlers,
  addProps,
  addEffect,
} from 'ad-hok'
import {addPropIdentityStabilization} from 'ad-hok-utils'
import CircularProgress from '@material-ui/core/CircularProgress'

import {addTranslationHelpers} from 'utils/i18n'
import {addLoadingIndicator} from 'utils/dataLoading'
import {
  addAccountAuditsQuery,
  addAuditZipfilesQuery,
  addAuditsQuery,
  addCreateZipAndUploadMutation,
} from 'graphql/generated'
import AuditItem from 'components/AuditItem'
import {
  addFacilities,
  addBenefits,
  addCharityCareTypes,
} from 'utils/configContext'
import addDialogState from 'utils/addDialogState'
import AddButton from 'components/AddButton'
import AuditCsvDialog from 'components/AuditCsvDialog'
import Checkbox from './Checkbox'
import typedAs from 'utils/typedAs'
import Grid from './Grid'
import {addClasses, makeClasses} from 'theme'
import Button from './Button'
import PreviewZipFileListDialog from './PreviewZipFileListDialog'
import {AuditZipfiles_auditZipfiles} from 'graphql/deserializedTypes/AuditZipfiles'
import {StandaloneSelectField} from './SelectField'
import {Audits_audits} from 'graphql/deserializedTypes/Audits'

interface CreateUploadAuditCsvButtonProps {
  className?: string
  onUploadComplete: () => void
}
export const CreateUploadAuditCsvButton: FC<CreateUploadAuditCsvButtonProps> = flowMax(
  addDisplayName('CreateUploadAuditCsvButton'),
  addDialogState,
  addTranslationHelpers,
  addState('isSubmitting', 'setIsSubmitting', false),
  addHandlers({
    onUploadSuccess: ({hideDialog, onUploadComplete}) => () => {
      onUploadComplete()
      hideDialog()
    },
  }),
  ({
    isShowingDialog,
    showDialog,
    hideDialog,
    onUploadSuccess,
    isSubmitting,
    className,
    t,
  }) => (
    <>
      <AddButton onClick={showDialog} className={className}>
        {t('auditCsvDialog.add')}
      </AddButton>
      <AuditCsvDialog
        open={isShowingDialog}
        onClose={hideDialog}
        onUploadSuccess={onUploadSuccess}
        isSubmitting={isSubmitting}
      />
    </>
  )
)

interface GenerateZipFileButtonProps {
  onClick: () => void
  disabled: boolean
  isZippingFiles: boolean
}
const GenerateZipFileForm: FC<GenerateZipFileButtonProps> = flowMax(
  addDisplayName('GenerateZipFileButton'),
  addTranslationHelpers,
  ({t, onClick, disabled, isZippingFiles}) => (
    <Grid className="testing-zip-file-form" direction="row" alignItems="center">
      <Button
        onClick={() => (!isZippingFiles ? onClick() : null)}
        color="primary"
        variant="contained"
        disabled={disabled}
        endIcon={
          isZippingFiles && <CircularProgress color="inherit" size={20} />
        }
      >
        {isZippingFiles
          ? t('auditList.generatingZipFileButton')
          : t('auditList.generateZipFileButton')}
      </Button>
    </Grid>
  )
)

interface ShowDownloadedZipFileButtonProps {
  zipFileList: Array<AuditZipfiles_auditZipfiles>
  auditBatch: string | undefined
}
const ShowDownloadedZipFileButton: FC<ShowDownloadedZipFileButtonProps> = flowMax(
  addDisplayName('ShowDownloadedZipFileButton'),
  addTranslationHelpers,
  addStateHandlers(
    {
      isShowingDialog: typedAs<boolean>(false),
      isShowingAlert: typedAs<boolean>(false),
      fileList: typedAs<Array<AuditZipfiles_auditZipfiles>>([]),
    },
    {
      // eslint-disable-next-line
      showPreviewDialog: () => () => ({
        isShowingDialog: true,
      }),
      // eslint-disable-next-line
      hidePreviewDialog: () => () => ({
        isShowingDialog: false,
      }),
      // eslint-disable-next-line
      showAlert: () => () => ({
        isShowingAlert: true,
      }),
      // eslint-disable-next-line
      hideAlert: () => () => ({
        isShowingAlert: false,
      }),
      updateFileList: () => (fileList: Array<AuditZipfiles_auditZipfiles>) => ({
        fileList,
      }),
    }
  ),
  addEffect(
    ({zipFileList, updateFileList}) => () => {
      const _auditZipFiles: Array<any> = []
      zipFileList.forEach((item: AuditZipfiles_auditZipfiles) => {
        _auditZipFiles.push(...item.documentFiles)
      })
      updateFileList(_auditZipFiles)
    },
    ['zipFileList']
  ),
  addHandlers({
    onShowDialog: ({showPreviewDialog}) => () => {
      showPreviewDialog()
    },
    onConfirm: ({hidePreviewDialog}) => () => {
      hidePreviewDialog()
    },
  }),
  ({
    t,
    showPreviewDialog,
    isShowingDialog,
    onConfirm,
    fileList,
    auditBatch,
  }) => (
    <>
      <Button
        disabled={!fileList || fileList.length === 0}
        onClick={showPreviewDialog}
        color="primary"
        variant="contained"
      >
        {t('auditList.previewGeneratedFilesButton')}
      </Button>
      <PreviewZipFileListDialog
        open={isShowingDialog}
        onConfirm={onConfirm}
        title={auditBatch || t('auditList.zipFile')}
        fileList={fileList}
      />
    </>
  )
)

interface DownloadAllUb04ButtonProps {
  className?: string
  onUploadComplete: () => void
}
export const DownloadAllUb04Button: FC<DownloadAllUb04ButtonProps> = flowMax(
  addDisplayName('CreateUploadAuditCsvButton'),
  addDialogState,
  addTranslationHelpers,
  addState('isSubmitting', 'setIsSubmitting', false),
  addHandlers({
    onUploadSuccess: ({hideDialog, onUploadComplete}) => () => {
      onUploadComplete()
      hideDialog()
    },
  }),
  ({
    isShowingDialog,
    showDialog,
    hideDialog,
    onUploadSuccess,
    isSubmitting,
    className,
    t,
  }) => (
    <>
      <AddButton onClick={showDialog} className={className}>
        {t('auditCsvDialog.add')}
      </AddButton>
      <AuditCsvDialog
        open={isShowingDialog}
        onClose={hideDialog}
        onUploadSuccess={onUploadSuccess}
        isSubmitting={isSubmitting}
      />
    </>
  )
)

const classes = makeClasses((theme) => ({
  listHeader: {
    paddingLeft: '16px',
    margin: '10px 0',
  },
  zipFileButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  auditBatchIdDropdown: {
    width: '180px',
  },
  scrollableAuditList: {
    maxHeight: 'calc(100vh - 215px)',
    overflowY: 'auto',
  },
}))

const AuditWorklist: FC = flowMax(
  addDisplayName('AuditWorklist'),
  addTranslationHelpers,
  addFacilities,
  addBenefits,
  addCharityCareTypes,
  addClasses(classes),
  addWrapper((render) => <>{render()}</>),
  addAuditsQuery({}),
  addCreateZipAndUploadMutation({}),
  addStateHandlers(
    {
      selectedAuditIds: typedAs<Set<string>>(new Set()),
      selectAllAudits: typedAs<boolean>(false),
      selectedAuditBatchId: typedAs<string>(''),
      auditBatchList: typedAs<Array<{value: string; label: string}>>([]),
      isZippingFiles: typedAs<boolean>(false),
    },
    {
      setSelectedAuditIds: () => (selectedAuditIds: Set<string>) => ({
        selectedAuditIds,
      }),
      toggleAllAuditSelected: () => (selectAllAudits: boolean) => ({
        selectAllAudits,
      }),
      setZippingFiles: () => (isZippingFiles: boolean) => ({
        isZippingFiles,
      }),
      setSelectedAuditBatchId: () => (selectedAuditBatchId: string) => ({
        selectedAuditBatchId,
      }),
      setAuditBatchList: () => (
        auditBatchList: Array<{value: string; label: string}>
      ) => ({
        auditBatchList,
      }),
    }
  ),
  addLoadingIndicator({}),
  addEffect(
    ({setAuditBatchList, setSelectedAuditBatchId, audits}) => () => {
      if (audits && Array.isArray(audits) && audits.length) {
        setSelectedAuditBatchId(audits[0].id)
        setAuditBatchList(
          audits.map((audit: Audits_audits) => ({
            label: `${audit.name.replace('.csv', '')} [${audit.id}]`,
            value: audit.id,
          }))
        )
      }
    },
    ['audits']
  ),
  addAccountAuditsQuery({
    variables: ({selectedAuditBatchId}) => ({
      auditId: selectedAuditBatchId,
    }),
  }),
  addAuditZipfilesQuery({
    variables: ({selectedAuditBatchId}) => ({
      auditId: selectedAuditBatchId,
    }),
  }),
  addPropIdentityStabilization('accountAudits'),
  addProps(
    ({selectedAuditIds}) => ({
      disableGenerateFileButton: selectedAuditIds.size === 0,
    }),
    ['selectedAuditIds']
  ),
  addEffect(
    ({
      toggleAllAuditSelected,
      setSelectedAuditIds,
      refetchAccountAudits,
      refetchAuditZipfiles,
      selectedAuditBatchId,
    }) => () => {
      refetchAccountAudits({auditId: selectedAuditBatchId})
      refetchAuditZipfiles({auditId: selectedAuditBatchId})
      toggleAllAuditSelected(false)
      setSelectedAuditIds(new Set())
    },
    ['selectedAuditBatchId']
  ),
  addLoadingIndicator({}),
  addHandlers({
    onAuditSelectionChange: ({
      accountAudits,
      selectedAuditIds,
      setSelectedAuditIds,
      toggleAllAuditSelected,
    }) => (checked: boolean, changedAuditId: string) => {
      const newSelectAuditList = new Set(selectedAuditIds)
      if (checked) {
        if (!newSelectAuditList.has(changedAuditId)) {
          newSelectAuditList.add(changedAuditId)
        }
      } else {
        if (newSelectAuditList.has(changedAuditId)) {
          newSelectAuditList.delete(changedAuditId)
        }
      }
      setSelectedAuditIds(newSelectAuditList)
      toggleAllAuditSelected(accountAudits.length === newSelectAuditList.size)
    },
    toggleAuditSelection: ({
      accountAudits,
      setSelectedAuditIds,
      toggleAllAuditSelected,
    }) => (checked: boolean) => {
      toggleAllAuditSelected(checked)
      if (checked) {
        setSelectedAuditIds(
          new Set(
            accountAudits
              .filter(({application, account}) => {
                const isSelectable: boolean =
                  application !== null &&
                  application?.id !== null &&
                  account !== null &&
                  account?.id !== null &&
                  account?.documentFiles !== null &&
                  account.readyForAudit &&
                  application.readyForAudit &&
                  Array.isArray(account?.documentFiles) &&
                  account?.documentFiles?.length > 0
                return isSelectable
              })
              .map((item) => item.id)
          )
        )
      } else {
        setSelectedAuditIds(new Set())
      }
    },
    handleGenerateZipFileCall: ({
      selectedAuditBatchId: filteredAuditBatchId,
      selectedAuditIds,
      accountAudits,
      mutateCreateZipAndUpload,
      toggleAllAuditSelected,
      setSelectedAuditIds,
      refetchAuditZipfiles,
      setZippingFiles,
    }) => () => {
      const payload: any[] = accountAudits
        .filter((audit) => selectedAuditIds.has(audit.id))
        .map((audit) => ({
          auditExternalId: audit.auditExternalId,
          accountId: audit.account?.id,
          applicationId: audit.application?.id,
          editableFileId:
            audit.editableFiles &&
            Array.isArray(audit.editableFiles) &&
            audit.editableFiles.length > 0
              ? audit.editableFiles[0].id
              : '',
        }))
      setZippingFiles(true)
      mutateCreateZipAndUpload({
        variables: {
          audit_id: filteredAuditBatchId,
          audit_data: payload,
        },
      })
        .then(() => {
          setZippingFiles(false)
          refetchAuditZipfiles({auditId: filteredAuditBatchId})
        })
        .catch(() => {
          setZippingFiles(false)
        })
      toggleAllAuditSelected(false)
      setSelectedAuditIds(new Set())
    },
  }),
  // addMemoBoundary(['accountAudits']),
  ({
    accountAudits,
    refetchAudits,
    selectAllAudits,
    selectedAuditIds,
    toggleAuditSelection,
    onAuditSelectionChange,
    disableGenerateFileButton,
    handleGenerateZipFileCall,
    setSelectedAuditBatchId,
    selectedAuditBatchId,
    classes,
    auditBatchList,
    auditZipfiles,
    isZippingFiles,
    t,
  }) => (
    <>
      <Grid
        container
        className={classes.listHeader}
        direction="row"
        alignItems="center"
        justify="space-between"
        wrap="nowrap"
      >
        <Grid item direction="row" alignItems="center">
          <Checkbox
            checked={selectAllAudits}
            indeterminate={
              selectedAuditIds.size > 0 &&
              selectedAuditIds.size < accountAudits.length
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              toggleAuditSelection(e.target.checked)
            }
          />
          <CreateUploadAuditCsvButton
            onUploadComplete={() => {
              refetchAudits()
            }}
          />
        </Grid>
        <Grid
          className={classes.zipFileButtons}
          direction="row"
          alignItems="center"
        >
          {auditBatchList.length > 0 && (
            <StandaloneSelectField
              className={classes.auditBatchIdDropdown}
              value={selectedAuditBatchId}
              onChange={(event: React.ChangeEvent<{value: unknown}>) => {
                if (typeof event.target.value === 'string') {
                  setSelectedAuditBatchId(event.target.value)
                }
              }}
              id={'audit-batch-ids'}
              label={t('auditList.auditBatch')}
              options={auditBatchList}
            />
          )}
          {accountAudits &&
            Array.isArray(accountAudits) &&
            accountAudits.length > 0 && (
              <GenerateZipFileForm
                onClick={handleGenerateZipFileCall}
                disabled={disableGenerateFileButton}
                isZippingFiles={isZippingFiles}
              />
            )}
          <ShowDownloadedZipFileButton
            zipFileList={auditZipfiles}
            auditBatch={
              auditBatchList.find(
                (auditBatch) => auditBatch.value === selectedAuditBatchId
              )?.label
            }
          />
        </Grid>
      </Grid>
      <Grid className={classes.scrollableAuditList}>
        {accountAudits.map((accountAudit) => (
          <AuditItem
            onAuditSelectionChange={onAuditSelectionChange}
            isSelected={selectedAuditIds.has(accountAudit.id)}
            accountAudit={accountAudit}
            key={accountAudit.id}
            open={false}
            // updateEditableFile={updateEditableFile}
          />
        ))}
      </Grid>
    </>
  )
)

export default AuditWorklist
