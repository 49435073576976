// THIS FILE IS AUTO-GENERATED
/* eslint-disable import/order */
import {addProps} from 'ad-hok'
import {useQuery, useLazyQuery, useMutation} from '@apollo/react-hooks'
import {MutationFunction} from '@apollo/react-common'
import {ApolloQueryResult} from 'apollo-boost'
import {isFunction} from 'lodash'
import {useMemo} from 'react'

import {
  AddQueryType,
  AddLazyQueryType,
  AddMutationType,
  makeDataLoaded,
  makeDataLoading,
} from '../utils/dataLoading'

// Queries
import {
  ME_QUERY,
  PERSON_MATCH_QUERY,
  PERSON_QUERY,
  ACCOUNT_AUDITS_QUERY,
  PERSON_FOR_AWAITING_PHONE_NUMBER_ESIGN_SESSION_ID_QUERY,
  TASKS_QUERY,
  TRIAGES_QUERY,
  ACCOUNT_QUERY,
  FEDERAL_POVERTY_LEVELS_QUERY,
  APPLICATIONS_QUERY,
  APPLICATION_QUERY,
  APPLICATION_FOR_VIEW_QUERY,
  ACCOUNTS_QUERY,
  SIGNED_DOCUMENT_UPLOAD_URL_QUERY,
  SIGNED_DOCUMENT_DOWNLOAD_URL_QUERY,
  ACCOUNTS_FOR_CSV_QUERY,
  PEOPLE_QUERY,
  PEOPLE_WITH_UNREAD_TEXT_MESSAGES_QUERY,
  DUPLICATE_PEOPLE_QUERY,
  USERS_QUERY,
  APPLICATIONS_REPORT_QUERY,
  BENEFIT_OUTCOMES_REPORT_QUERY,
  WEBFORMS_QUERY,
  RECEIVED_WEBFORMS_QUERY,
  CONFIG_QUERY,
  INBOUNDS_QUERY,
  FILE_TEMPLATES_QUERY,
  EDITABLE_FILES_QUERY,
  INBOUND_WEBFORMS_QUERY,
  FILE_TEMPLATE_MAPPING_QUERY,
  PERSON_RECEIVED_WEBFORM_FILE_TEMPLATES_QUERY,
  PEOPLE_BY_MRN_QUERY,
  REMOTE_DOCUMENT_REQUESTS_QUERY,
  TEXT_MESSAGES_QUERY,
  SENTWEBFORMS_PENDING_QUERY,
  EVENT_LOG_QUERY,
  NUMBER_OF_PEOPLE_WITH_UNREVIEWED_RECEIVED_WEBFORMS_QUERY,
  NJMMIS_CAPTURE_RESULT_QUERY,
  COUNTY_FAXES_QUERY,
  AUDIT_ZIPFILES_QUERY,
  AUDIT_QUERY,
  SLIDE_APPLICATION_RULES_QUERY,
  NJ_MEDICAID_STATE_QUERY,
} from './queries'

import {Me as MeOrig} from './types/Me'
import {Me, deserializeMe} from './deserializedTypes/Me'
import {
  PersonMatchVariables as PersonMatchVariablesOrig,
  PersonMatch as PersonMatchOrig,
} from './types/PersonMatch'
import {
  PersonMatchVariables,
  PersonMatch,
  serializePersonMatchVariables,
  deserializePersonMatch,
} from './deserializedTypes/PersonMatch'
import {
  PersonVariables as PersonVariablesOrig,
  Person as PersonOrig,
} from './types/Person'
import {
  PersonVariables,
  Person,
  serializePersonVariables,
  deserializePerson,
} from './deserializedTypes/Person'
import {
  AccountAuditsVariables as AccountAuditsVariablesOrig,
  AccountAudits as AccountAuditsOrig,
} from './types/AccountAudits'
import {
  AccountAuditsVariables,
  AccountAudits,
  serializeAccountAuditsVariables,
  deserializeAccountAudits,
} from './deserializedTypes/AccountAudits'
import {
  PersonForAwaitingPhoneNumberEsignSessionIdVariables as PersonForAwaitingPhoneNumberEsignSessionIdVariablesOrig,
  PersonForAwaitingPhoneNumberEsignSessionId as PersonForAwaitingPhoneNumberEsignSessionIdOrig,
} from './types/PersonForAwaitingPhoneNumberEsignSessionId'
import {
  PersonForAwaitingPhoneNumberEsignSessionIdVariables,
  PersonForAwaitingPhoneNumberEsignSessionId,
  serializePersonForAwaitingPhoneNumberEsignSessionIdVariables,
  deserializePersonForAwaitingPhoneNumberEsignSessionId,
} from './deserializedTypes/PersonForAwaitingPhoneNumberEsignSessionId'
import {
  TasksVariables as TasksVariablesOrig,
  Tasks as TasksOrig,
} from './types/Tasks'
import {
  TasksVariables,
  Tasks,
  serializeTasksVariables,
  deserializeTasks,
} from './deserializedTypes/Tasks'
import {
  TriagesVariables as TriagesVariablesOrig,
  Triages as TriagesOrig,
} from './types/Triages'
import {
  TriagesVariables,
  Triages,
  serializeTriagesVariables,
  deserializeTriages,
} from './deserializedTypes/Triages'
import {
  AccountVariables as AccountVariablesOrig,
  Account as AccountOrig,
} from './types/Account'
import {
  AccountVariables,
  Account,
  serializeAccountVariables,
  deserializeAccount,
} from './deserializedTypes/Account'
import {
  FederalPovertyLevelsVariables as FederalPovertyLevelsVariablesOrig,
  FederalPovertyLevels as FederalPovertyLevelsOrig,
} from './types/FederalPovertyLevels'
import {
  FederalPovertyLevelsVariables,
  FederalPovertyLevels,
  serializeFederalPovertyLevelsVariables,
  deserializeFederalPovertyLevels,
} from './deserializedTypes/FederalPovertyLevels'
import {
  ApplicationsVariables as ApplicationsVariablesOrig,
  Applications as ApplicationsOrig,
} from './types/Applications'
import {
  ApplicationsVariables,
  Applications,
  serializeApplicationsVariables,
  deserializeApplications,
} from './deserializedTypes/Applications'
import {
  ApplicationVariables as ApplicationVariablesOrig,
  Application as ApplicationOrig,
} from './types/Application'
import {
  ApplicationVariables,
  Application,
  serializeApplicationVariables,
  deserializeApplication,
} from './deserializedTypes/Application'
import {
  ApplicationForViewVariables as ApplicationForViewVariablesOrig,
  ApplicationForView as ApplicationForViewOrig,
} from './types/ApplicationForView'
import {
  ApplicationForViewVariables,
  ApplicationForView,
  serializeApplicationForViewVariables,
  deserializeApplicationForView,
} from './deserializedTypes/ApplicationForView'
import {
  AccountsVariables as AccountsVariablesOrig,
  Accounts as AccountsOrig,
} from './types/Accounts'
import {
  AccountsVariables,
  Accounts,
  serializeAccountsVariables,
  deserializeAccounts,
} from './deserializedTypes/Accounts'
import {
  SignedDocumentUploadUrlVariables as SignedDocumentUploadUrlVariablesOrig,
  SignedDocumentUploadUrl as SignedDocumentUploadUrlOrig,
} from './types/SignedDocumentUploadUrl'
import {
  SignedDocumentUploadUrlVariables,
  SignedDocumentUploadUrl,
  serializeSignedDocumentUploadUrlVariables,
  deserializeSignedDocumentUploadUrl,
} from './deserializedTypes/SignedDocumentUploadUrl'
import {
  SignedDocumentDownloadUrlVariables as SignedDocumentDownloadUrlVariablesOrig,
  SignedDocumentDownloadUrl as SignedDocumentDownloadUrlOrig,
} from './types/SignedDocumentDownloadUrl'
import {
  SignedDocumentDownloadUrlVariables,
  SignedDocumentDownloadUrl,
  serializeSignedDocumentDownloadUrlVariables,
  deserializeSignedDocumentDownloadUrl,
} from './deserializedTypes/SignedDocumentDownloadUrl'
import {
  AccountsForCsvVariables as AccountsForCsvVariablesOrig,
  AccountsForCsv as AccountsForCsvOrig,
} from './types/AccountsForCsv'
import {
  AccountsForCsvVariables,
  AccountsForCsv,
  serializeAccountsForCsvVariables,
  deserializeAccountsForCsv,
} from './deserializedTypes/AccountsForCsv'
import {
  PeopleVariables as PeopleVariablesOrig,
  People as PeopleOrig,
} from './types/People'
import {
  PeopleVariables,
  People,
  serializePeopleVariables,
  deserializePeople,
} from './deserializedTypes/People'
import {PeopleWithUnreadTextMessages as PeopleWithUnreadTextMessagesOrig} from './types/PeopleWithUnreadTextMessages'
import {
  PeopleWithUnreadTextMessages,
  deserializePeopleWithUnreadTextMessages,
} from './deserializedTypes/PeopleWithUnreadTextMessages'
import {
  DuplicatePeopleVariables as DuplicatePeopleVariablesOrig,
  DuplicatePeople as DuplicatePeopleOrig,
} from './types/DuplicatePeople'
import {
  DuplicatePeopleVariables,
  DuplicatePeople,
  serializeDuplicatePeopleVariables,
  deserializeDuplicatePeople,
} from './deserializedTypes/DuplicatePeople'
import {
  UsersVariables as UsersVariablesOrig,
  Users as UsersOrig,
} from './types/Users'
import {
  UsersVariables,
  Users,
  serializeUsersVariables,
  deserializeUsers,
} from './deserializedTypes/Users'
import {
  ApplicationsReportVariables as ApplicationsReportVariablesOrig,
  ApplicationsReport as ApplicationsReportOrig,
} from './types/ApplicationsReport'
import {
  ApplicationsReportVariables,
  ApplicationsReport,
  serializeApplicationsReportVariables,
  deserializeApplicationsReport,
} from './deserializedTypes/ApplicationsReport'
import {
  BenefitOutcomesReportVariables as BenefitOutcomesReportVariablesOrig,
  BenefitOutcomesReport as BenefitOutcomesReportOrig,
} from './types/BenefitOutcomesReport'
import {
  BenefitOutcomesReportVariables,
  BenefitOutcomesReport,
  serializeBenefitOutcomesReportVariables,
  deserializeBenefitOutcomesReport,
} from './deserializedTypes/BenefitOutcomesReport'
import {Webforms as WebformsOrig} from './types/Webforms'
import {Webforms, deserializeWebforms} from './deserializedTypes/Webforms'
import {
  ReceivedWebformsVariables as ReceivedWebformsVariablesOrig,
  ReceivedWebforms as ReceivedWebformsOrig,
} from './types/ReceivedWebforms'
import {
  ReceivedWebformsVariables,
  ReceivedWebforms,
  serializeReceivedWebformsVariables,
  deserializeReceivedWebforms,
} from './deserializedTypes/ReceivedWebforms'
import {Config as ConfigOrig} from './types/Config'
import {Config, deserializeConfig} from './deserializedTypes/Config'
import {
  InboundsVariables as InboundsVariablesOrig,
  Inbounds as InboundsOrig,
} from './types/Inbounds'
import {
  InboundsVariables,
  Inbounds,
  serializeInboundsVariables,
  deserializeInbounds,
} from './deserializedTypes/Inbounds'
import {FileTemplates as FileTemplatesOrig} from './types/FileTemplates'
import {
  FileTemplates,
  deserializeFileTemplates,
} from './deserializedTypes/FileTemplates'
import {
  EditableFilesVariables as EditableFilesVariablesOrig,
  EditableFiles as EditableFilesOrig,
} from './types/EditableFiles'
import {
  EditableFilesVariables,
  EditableFiles,
  serializeEditableFilesVariables,
  deserializeEditableFiles,
} from './deserializedTypes/EditableFiles'
import {InboundWebforms as InboundWebformsOrig} from './types/InboundWebforms'
import {
  InboundWebforms,
  deserializeInboundWebforms,
} from './deserializedTypes/InboundWebforms'
import {
  FileTemplateMappingVariables as FileTemplateMappingVariablesOrig,
  FileTemplateMapping as FileTemplateMappingOrig,
} from './types/FileTemplateMapping'
import {
  FileTemplateMappingVariables,
  FileTemplateMapping,
  serializeFileTemplateMappingVariables,
  deserializeFileTemplateMapping,
} from './deserializedTypes/FileTemplateMapping'
import {
  PersonReceivedWebformFileTemplatesVariables as PersonReceivedWebformFileTemplatesVariablesOrig,
  PersonReceivedWebformFileTemplates as PersonReceivedWebformFileTemplatesOrig,
} from './types/PersonReceivedWebformFileTemplates'
import {
  PersonReceivedWebformFileTemplatesVariables,
  PersonReceivedWebformFileTemplates,
  serializePersonReceivedWebformFileTemplatesVariables,
  deserializePersonReceivedWebformFileTemplates,
} from './deserializedTypes/PersonReceivedWebformFileTemplates'
import {
  PeopleByMrnVariables as PeopleByMrnVariablesOrig,
  PeopleByMrn as PeopleByMrnOrig,
} from './types/PeopleByMrn'
import {
  PeopleByMrnVariables,
  PeopleByMrn,
  serializePeopleByMrnVariables,
  deserializePeopleByMrn,
} from './deserializedTypes/PeopleByMrn'
import {
  RemoteDocumentRequestsVariables as RemoteDocumentRequestsVariablesOrig,
  RemoteDocumentRequests as RemoteDocumentRequestsOrig,
} from './types/RemoteDocumentRequests'
import {
  RemoteDocumentRequestsVariables,
  RemoteDocumentRequests,
  serializeRemoteDocumentRequestsVariables,
  deserializeRemoteDocumentRequests,
} from './deserializedTypes/RemoteDocumentRequests'
import {
  TextMessagesVariables as TextMessagesVariablesOrig,
  TextMessages as TextMessagesOrig,
} from './types/TextMessages'
import {
  TextMessagesVariables,
  TextMessages,
  serializeTextMessagesVariables,
  deserializeTextMessages,
} from './deserializedTypes/TextMessages'
import {
  SentWebFormsVariables as SentWebFormsVariablesOrig,
  SentWebForms as SentWebFormsOrig,
} from './types/SentWebForms'
import {
  SentWebFormsVariables,
  SentWebForms,
  serializeSentWebFormsVariables,
  deserializeSentWebForms,
} from './deserializedTypes/SentWebForms'
import {
  EventLogsVariables as EventLogsVariablesOrig,
  EventLogs as EventLogsOrig,
} from './types/EventLogs'
import {
  EventLogsVariables,
  EventLogs,
  serializeEventLogsVariables,
  deserializeEventLogs,
} from './deserializedTypes/EventLogs'
import {
  NumberOfPeopleWithUnreviewedReceivedWebformsVariables as NumberOfPeopleWithUnreviewedReceivedWebformsVariablesOrig,
  NumberOfPeopleWithUnreviewedReceivedWebforms as NumberOfPeopleWithUnreviewedReceivedWebformsOrig,
} from './types/NumberOfPeopleWithUnreviewedReceivedWebforms'
import {
  NumberOfPeopleWithUnreviewedReceivedWebformsVariables,
  NumberOfPeopleWithUnreviewedReceivedWebforms,
  serializeNumberOfPeopleWithUnreviewedReceivedWebformsVariables,
  deserializeNumberOfPeopleWithUnreviewedReceivedWebforms,
} from './deserializedTypes/NumberOfPeopleWithUnreviewedReceivedWebforms'
import {
  NjmmisCaptureResultVariables as NjmmisCaptureResultVariablesOrig,
  NjmmisCaptureResult as NjmmisCaptureResultOrig,
} from './types/NjmmisCaptureResult'
import {
  NjmmisCaptureResultVariables,
  NjmmisCaptureResult,
  serializeNjmmisCaptureResultVariables,
  deserializeNjmmisCaptureResult,
} from './deserializedTypes/NjmmisCaptureResult'
import {
  CountyFaxesVariables as CountyFaxesVariablesOrig,
  CountyFaxes as CountyFaxesOrig,
} from './types/CountyFaxes'
import {
  CountyFaxesVariables,
  CountyFaxes,
  serializeCountyFaxesVariables,
  deserializeCountyFaxes,
} from './deserializedTypes/CountyFaxes'
import {
  AuditZipfilesVariables as AuditZipfilesVariablesOrig,
  AuditZipfiles as AuditZipfilesOrig,
} from './types/AuditZipfiles'
import {
  AuditZipfilesVariables,
  AuditZipfiles,
  serializeAuditZipfilesVariables,
  deserializeAuditZipfiles,
} from './deserializedTypes/AuditZipfiles'
import {Audits as AuditsOrig} from './types/Audits'
import {Audits, deserializeAudits} from './deserializedTypes/Audits'
import {SlideApplicationRules as SlideApplicationRulesOrig} from './types/SlideApplicationRules'
import {
  SlideApplicationRules,
  deserializeSlideApplicationRules,
} from './deserializedTypes/SlideApplicationRules'
import {
  NjMedicaidApplicationStatesVariables as NjMedicaidApplicationStatesVariablesOrig,
  NjMedicaidApplicationStates as NjMedicaidApplicationStatesOrig,
} from './types/NjMedicaidApplicationStates'
import {
  NjMedicaidApplicationStatesVariables,
  NjMedicaidApplicationStates,
  serializeNjMedicaidApplicationStatesVariables,
  deserializeNjMedicaidApplicationStates,
} from './deserializedTypes/NjMedicaidApplicationStates'
// Mutations
import {
  CREATE_ACCOUNT_AND_PERSON_MUTATION,
  UPDATE_TASK_MUTATION,
  UPDATE_TRIAGE_MUTATION,
  UPDATE_PERSON_MUTATION,
  UPDATE_ACCOUNT_MUTATION,
  SAVE_ELIGIBILITY_DETERMINATIONS_MUTATION,
  CREATE_APPLICATION_MUTATION,
  UPDATE_APPLICATION_STATUS_MUTATION,
  UPDATE_APPLICATION_MUTATION,
  CREATE_DOCUMENT_MUTATION,
  UPDATE_DOCUMENT_MUTATION,
  CREATE_OR_UPDATE_BENEFIT_OUTCOME_MUTATION,
  DELETE_BENEFIT_OUTCOME_MUTATION,
  DELETE_DOCUMENT_MUTATION,
  UPDATE_ACCOUNT_STATUS_MUTATION,
  UPDATE_READY_FOR_AUDIT_MUTATION,
  CREATE_ZIP_AND_UPLOAD_MUTATION,
  CREATE_DOCUMENT_FILE_MUTATION,
  DELETE_DOCUMENT_FILE_MUTATION,
  CREATE_TASK_MUTATION,
  DELETE_TASK_MUTATION,
  UPDATE_ESIGN_SESSION_MUTATION,
  SEND_WEBFORM_LINK_MUTATION,
  MARK_WEBFORM_MERGE_ATTEMPT_REVIEWED_MUTATION,
  UPDATE_RECEIVED_WEBFORM_MUTATION,
  CREATE_ACCOUNT_AND_PERSON_FROM_WEBFORM_MUTATION,
  START_INBOUND_MUTATION,
  UNSTART_INBOUND_MUTATION,
  COMPLETE_INBOUND_MUTATION,
  UNCOMPLETE_INBOUND_MUTATION,
  CREATE_EDITABLE_FILE_MUTATION,
  UPDATE_EDITABLE_FILE_MUTATION,
  SEND_EDITABLE_FILE_TO_EMR_MUTATION,
  MARK_EDITABLE_FILE_REVIEWED_MUTATION,
  MERGE_EDITABLE_FILES_MUTATION,
  DELETE_EDITABLE_FILE_MUTATION,
  DELETE_EDITABLE_FILE_APPLICATION_ASSOCIATION_MUTATION,
  MARK_RECEIVED_WEBFORM_FILE_TEMPLATE_CLEARED_MUTATION,
  RETRY_INBOUND_NJMMIS_CHECK_MUTATION,
  CREATE_LIGHTICO_WORKFLOWS_MUTATION,
  SEND_DOCUMENT_REQUESTS_MUTATION,
  CREATE_SEND_DOCUMENT_REQUESTS_MUTATION,
  CANCEL_DOCUMENT_REQUESTS_MUTATION,
  UPDATE_PERSON_MESSAGE_READ_MUTATION,
  CREATE_NJMMIS_CAPTURE_RESULT_MUTATION,
  APPLY_STATE_MEDICAID_MUTATION,
} from './mutations'

import {
  CreateAccountAndPersonVariables as CreateAccountAndPersonVariablesOrig,
  CreateAccountAndPerson as CreateAccountAndPersonOrig,
} from './types/CreateAccountAndPerson'
import {
  CreateAccountAndPersonVariables,
  CreateAccountAndPerson,
  serializeCreateAccountAndPersonVariables,
} from './deserializedTypes/CreateAccountAndPerson'
import {
  UpdateTaskVariables as UpdateTaskVariablesOrig,
  UpdateTask as UpdateTaskOrig,
} from './types/UpdateTask'
import {
  UpdateTaskVariables,
  UpdateTask,
  serializeUpdateTaskVariables,
} from './deserializedTypes/UpdateTask'
import {
  UpdateTriageVariables as UpdateTriageVariablesOrig,
  UpdateTriage as UpdateTriageOrig,
} from './types/UpdateTriage'
import {
  UpdateTriageVariables,
  UpdateTriage,
  serializeUpdateTriageVariables,
} from './deserializedTypes/UpdateTriage'
import {
  UpdatePersonVariables as UpdatePersonVariablesOrig,
  UpdatePerson as UpdatePersonOrig,
} from './types/UpdatePerson'
import {
  UpdatePersonVariables,
  UpdatePerson,
  serializeUpdatePersonVariables,
} from './deserializedTypes/UpdatePerson'
import {
  UpdateAccountVariables as UpdateAccountVariablesOrig,
  UpdateAccount as UpdateAccountOrig,
} from './types/UpdateAccount'
import {
  UpdateAccountVariables,
  UpdateAccount,
  serializeUpdateAccountVariables,
} from './deserializedTypes/UpdateAccount'
import {
  SaveEligibilityDeterminationsVariables as SaveEligibilityDeterminationsVariablesOrig,
  SaveEligibilityDeterminations as SaveEligibilityDeterminationsOrig,
} from './types/SaveEligibilityDeterminations'
import {
  SaveEligibilityDeterminationsVariables,
  SaveEligibilityDeterminations,
  serializeSaveEligibilityDeterminationsVariables,
} from './deserializedTypes/SaveEligibilityDeterminations'
import {
  CreateApplicationVariables as CreateApplicationVariablesOrig,
  CreateApplication as CreateApplicationOrig,
} from './types/CreateApplication'
import {
  CreateApplicationVariables,
  CreateApplication,
  serializeCreateApplicationVariables,
} from './deserializedTypes/CreateApplication'
import {
  UpdateApplicationStatusVariables as UpdateApplicationStatusVariablesOrig,
  UpdateApplicationStatus as UpdateApplicationStatusOrig,
} from './types/UpdateApplicationStatus'
import {
  UpdateApplicationStatusVariables,
  UpdateApplicationStatus,
  serializeUpdateApplicationStatusVariables,
} from './deserializedTypes/UpdateApplicationStatus'
import {
  UpdateApplicationVariables as UpdateApplicationVariablesOrig,
  UpdateApplication as UpdateApplicationOrig,
} from './types/UpdateApplication'
import {
  UpdateApplicationVariables,
  UpdateApplication,
  serializeUpdateApplicationVariables,
} from './deserializedTypes/UpdateApplication'
import {
  CreateDocumentVariables as CreateDocumentVariablesOrig,
  CreateDocument as CreateDocumentOrig,
} from './types/CreateDocument'
import {
  CreateDocumentVariables,
  CreateDocument,
  serializeCreateDocumentVariables,
} from './deserializedTypes/CreateDocument'
import {
  UpdateDocumentVariables as UpdateDocumentVariablesOrig,
  UpdateDocument as UpdateDocumentOrig,
} from './types/UpdateDocument'
import {
  UpdateDocumentVariables,
  UpdateDocument,
  serializeUpdateDocumentVariables,
} from './deserializedTypes/UpdateDocument'
import {
  CreateOrUpdateBenefitOutcomeVariables as CreateOrUpdateBenefitOutcomeVariablesOrig,
  CreateOrUpdateBenefitOutcome as CreateOrUpdateBenefitOutcomeOrig,
} from './types/CreateOrUpdateBenefitOutcome'
import {
  CreateOrUpdateBenefitOutcomeVariables,
  CreateOrUpdateBenefitOutcome,
  serializeCreateOrUpdateBenefitOutcomeVariables,
} from './deserializedTypes/CreateOrUpdateBenefitOutcome'
import {
  DeleteBenefitOutcomeVariables as DeleteBenefitOutcomeVariablesOrig,
  DeleteBenefitOutcome as DeleteBenefitOutcomeOrig,
} from './types/DeleteBenefitOutcome'
import {
  DeleteBenefitOutcomeVariables,
  DeleteBenefitOutcome,
  serializeDeleteBenefitOutcomeVariables,
} from './deserializedTypes/DeleteBenefitOutcome'
import {
  DeleteDocumentVariables as DeleteDocumentVariablesOrig,
  DeleteDocument as DeleteDocumentOrig,
} from './types/DeleteDocument'
import {
  DeleteDocumentVariables,
  DeleteDocument,
  serializeDeleteDocumentVariables,
} from './deserializedTypes/DeleteDocument'
import {
  UpdateAccountStatusVariables as UpdateAccountStatusVariablesOrig,
  UpdateAccountStatus as UpdateAccountStatusOrig,
} from './types/UpdateAccountStatus'
import {
  UpdateAccountStatusVariables,
  UpdateAccountStatus,
  serializeUpdateAccountStatusVariables,
} from './deserializedTypes/UpdateAccountStatus'
import {
  UpdateReadyForAuditVariables as UpdateReadyForAuditVariablesOrig,
  UpdateReadyForAudit as UpdateReadyForAuditOrig,
} from './types/UpdateReadyForAudit'
import {
  UpdateReadyForAuditVariables,
  UpdateReadyForAudit,
  serializeUpdateReadyForAuditVariables,
} from './deserializedTypes/UpdateReadyForAudit'
import {
  CreateZipAndUploadVariables as CreateZipAndUploadVariablesOrig,
  CreateZipAndUpload as CreateZipAndUploadOrig,
} from './types/CreateZipAndUpload'
import {
  CreateZipAndUploadVariables,
  CreateZipAndUpload,
  serializeCreateZipAndUploadVariables,
} from './deserializedTypes/CreateZipAndUpload'
import {
  CreateDocumentFileVariables as CreateDocumentFileVariablesOrig,
  CreateDocumentFile as CreateDocumentFileOrig,
} from './types/CreateDocumentFile'
import {
  CreateDocumentFileVariables,
  CreateDocumentFile,
  serializeCreateDocumentFileVariables,
} from './deserializedTypes/CreateDocumentFile'
import {
  DeleteDocumentFileVariables as DeleteDocumentFileVariablesOrig,
  DeleteDocumentFile as DeleteDocumentFileOrig,
} from './types/DeleteDocumentFile'
import {
  DeleteDocumentFileVariables,
  DeleteDocumentFile,
  serializeDeleteDocumentFileVariables,
} from './deserializedTypes/DeleteDocumentFile'
import {
  CreateTaskVariables as CreateTaskVariablesOrig,
  CreateTask as CreateTaskOrig,
} from './types/CreateTask'
import {
  CreateTaskVariables,
  CreateTask,
  serializeCreateTaskVariables,
} from './deserializedTypes/CreateTask'
import {
  DeleteTaskVariables as DeleteTaskVariablesOrig,
  DeleteTask as DeleteTaskOrig,
} from './types/DeleteTask'
import {
  DeleteTaskVariables,
  DeleteTask,
  serializeDeleteTaskVariables,
} from './deserializedTypes/DeleteTask'
import {
  UpdateEsignSessionVariables as UpdateEsignSessionVariablesOrig,
  UpdateEsignSession as UpdateEsignSessionOrig,
} from './types/UpdateEsignSession'
import {
  UpdateEsignSessionVariables,
  UpdateEsignSession,
  serializeUpdateEsignSessionVariables,
} from './deserializedTypes/UpdateEsignSession'
import {
  SendWebformLinkVariables as SendWebformLinkVariablesOrig,
  SendWebformLink as SendWebformLinkOrig,
} from './types/SendWebformLink'
import {
  SendWebformLinkVariables,
  SendWebformLink,
  serializeSendWebformLinkVariables,
} from './deserializedTypes/SendWebformLink'
import {
  MarkWebformMergeAttemptReviewedVariables as MarkWebformMergeAttemptReviewedVariablesOrig,
  MarkWebformMergeAttemptReviewed as MarkWebformMergeAttemptReviewedOrig,
} from './types/MarkWebformMergeAttemptReviewed'
import {
  MarkWebformMergeAttemptReviewedVariables,
  MarkWebformMergeAttemptReviewed,
  serializeMarkWebformMergeAttemptReviewedVariables,
} from './deserializedTypes/MarkWebformMergeAttemptReviewed'
import {
  UpdateReceivedWebformVariables as UpdateReceivedWebformVariablesOrig,
  UpdateReceivedWebform as UpdateReceivedWebformOrig,
} from './types/UpdateReceivedWebform'
import {
  UpdateReceivedWebformVariables,
  UpdateReceivedWebform,
  serializeUpdateReceivedWebformVariables,
} from './deserializedTypes/UpdateReceivedWebform'
import {
  CreateAccountAndPersonFromWebformVariables as CreateAccountAndPersonFromWebformVariablesOrig,
  CreateAccountAndPersonFromWebform as CreateAccountAndPersonFromWebformOrig,
} from './types/CreateAccountAndPersonFromWebform'
import {
  CreateAccountAndPersonFromWebformVariables,
  CreateAccountAndPersonFromWebform,
  serializeCreateAccountAndPersonFromWebformVariables,
} from './deserializedTypes/CreateAccountAndPersonFromWebform'
import {
  StartInboundVariables as StartInboundVariablesOrig,
  StartInbound as StartInboundOrig,
} from './types/StartInbound'
import {
  StartInboundVariables,
  StartInbound,
  serializeStartInboundVariables,
} from './deserializedTypes/StartInbound'
import {
  UnstartInboundVariables as UnstartInboundVariablesOrig,
  UnstartInbound as UnstartInboundOrig,
} from './types/UnstartInbound'
import {
  UnstartInboundVariables,
  UnstartInbound,
  serializeUnstartInboundVariables,
} from './deserializedTypes/UnstartInbound'
import {
  CompleteInboundVariables as CompleteInboundVariablesOrig,
  CompleteInbound as CompleteInboundOrig,
} from './types/CompleteInbound'
import {
  CompleteInboundVariables,
  CompleteInbound,
  serializeCompleteInboundVariables,
} from './deserializedTypes/CompleteInbound'
import {
  UncompleteInboundVariables as UncompleteInboundVariablesOrig,
  UncompleteInbound as UncompleteInboundOrig,
} from './types/UncompleteInbound'
import {
  UncompleteInboundVariables,
  UncompleteInbound,
  serializeUncompleteInboundVariables,
} from './deserializedTypes/UncompleteInbound'
import {
  CreateEditableFileVariables as CreateEditableFileVariablesOrig,
  CreateEditableFile as CreateEditableFileOrig,
} from './types/CreateEditableFile'
import {
  CreateEditableFileVariables,
  CreateEditableFile,
  serializeCreateEditableFileVariables,
} from './deserializedTypes/CreateEditableFile'
import {
  UpdateEditableFileVariables as UpdateEditableFileVariablesOrig,
  UpdateEditableFile as UpdateEditableFileOrig,
} from './types/UpdateEditableFile'
import {
  UpdateEditableFileVariables,
  UpdateEditableFile,
  serializeUpdateEditableFileVariables,
} from './deserializedTypes/UpdateEditableFile'
import {
  SendEditableFileToEmrVariables as SendEditableFileToEmrVariablesOrig,
  SendEditableFileToEmr as SendEditableFileToEmrOrig,
} from './types/SendEditableFileToEmr'
import {
  SendEditableFileToEmrVariables,
  SendEditableFileToEmr,
  serializeSendEditableFileToEmrVariables,
} from './deserializedTypes/SendEditableFileToEmr'
import {
  MarkEditableFileReviewedVariables as MarkEditableFileReviewedVariablesOrig,
  MarkEditableFileReviewed as MarkEditableFileReviewedOrig,
} from './types/MarkEditableFileReviewed'
import {
  MarkEditableFileReviewedVariables,
  MarkEditableFileReviewed,
  serializeMarkEditableFileReviewedVariables,
} from './deserializedTypes/MarkEditableFileReviewed'
import {
  MergeEditableFilesVariables as MergeEditableFilesVariablesOrig,
  MergeEditableFiles as MergeEditableFilesOrig,
} from './types/MergeEditableFiles'
import {
  MergeEditableFilesVariables,
  MergeEditableFiles,
  serializeMergeEditableFilesVariables,
} from './deserializedTypes/MergeEditableFiles'
import {
  DeleteEditableFileVariables as DeleteEditableFileVariablesOrig,
  DeleteEditableFile as DeleteEditableFileOrig,
} from './types/DeleteEditableFile'
import {
  DeleteEditableFileVariables,
  DeleteEditableFile,
  serializeDeleteEditableFileVariables,
} from './deserializedTypes/DeleteEditableFile'
import {
  DeleteEditableFileApplicationAssociationVariables as DeleteEditableFileApplicationAssociationVariablesOrig,
  DeleteEditableFileApplicationAssociation as DeleteEditableFileApplicationAssociationOrig,
} from './types/DeleteEditableFileApplicationAssociation'
import {
  DeleteEditableFileApplicationAssociationVariables,
  DeleteEditableFileApplicationAssociation,
  serializeDeleteEditableFileApplicationAssociationVariables,
} from './deserializedTypes/DeleteEditableFileApplicationAssociation'
import {
  MarkReceivedWebformFileTemplateClearedVariables as MarkReceivedWebformFileTemplateClearedVariablesOrig,
  MarkReceivedWebformFileTemplateCleared as MarkReceivedWebformFileTemplateClearedOrig,
} from './types/MarkReceivedWebformFileTemplateCleared'
import {
  MarkReceivedWebformFileTemplateClearedVariables,
  MarkReceivedWebformFileTemplateCleared,
  serializeMarkReceivedWebformFileTemplateClearedVariables,
} from './deserializedTypes/MarkReceivedWebformFileTemplateCleared'
import {
  RetryInboundNjmmisCheckVariables as RetryInboundNjmmisCheckVariablesOrig,
  RetryInboundNjmmisCheck as RetryInboundNjmmisCheckOrig,
} from './types/RetryInboundNjmmisCheck'
import {
  RetryInboundNjmmisCheckVariables,
  RetryInboundNjmmisCheck,
  serializeRetryInboundNjmmisCheckVariables,
} from './deserializedTypes/RetryInboundNjmmisCheck'
import {
  CreateLighticoWorkflowsVariables as CreateLighticoWorkflowsVariablesOrig,
  CreateLighticoWorkflows as CreateLighticoWorkflowsOrig,
} from './types/CreateLighticoWorkflows'
import {
  CreateLighticoWorkflowsVariables,
  CreateLighticoWorkflows,
  serializeCreateLighticoWorkflowsVariables,
} from './deserializedTypes/CreateLighticoWorkflows'
import {
  SendDocumentRequestsVariables as SendDocumentRequestsVariablesOrig,
  SendDocumentRequests as SendDocumentRequestsOrig,
} from './types/SendDocumentRequests'
import {
  SendDocumentRequestsVariables,
  SendDocumentRequests,
  serializeSendDocumentRequestsVariables,
} from './deserializedTypes/SendDocumentRequests'
import {
  CreateSendDocumentRequestsVariables as CreateSendDocumentRequestsVariablesOrig,
  CreateSendDocumentRequests as CreateSendDocumentRequestsOrig,
} from './types/CreateSendDocumentRequests'
import {
  CreateSendDocumentRequestsVariables,
  CreateSendDocumentRequests,
  serializeCreateSendDocumentRequestsVariables,
} from './deserializedTypes/CreateSendDocumentRequests'
import {
  CancelDocumentRequestsVariables as CancelDocumentRequestsVariablesOrig,
  CancelDocumentRequests as CancelDocumentRequestsOrig,
} from './types/CancelDocumentRequests'
import {
  CancelDocumentRequestsVariables,
  CancelDocumentRequests,
  serializeCancelDocumentRequestsVariables,
} from './deserializedTypes/CancelDocumentRequests'
import {
  UpdatePersonMessageReadVariables as UpdatePersonMessageReadVariablesOrig,
  UpdatePersonMessageRead as UpdatePersonMessageReadOrig,
} from './types/UpdatePersonMessageRead'
import {
  UpdatePersonMessageReadVariables,
  UpdatePersonMessageRead,
  serializeUpdatePersonMessageReadVariables,
} from './deserializedTypes/UpdatePersonMessageRead'
import {
  CreateNjmmisCaptureResultVariables as CreateNjmmisCaptureResultVariablesOrig,
  CreateNjmmisCaptureResult as CreateNjmmisCaptureResultOrig,
} from './types/CreateNjmmisCaptureResult'
import {
  CreateNjmmisCaptureResultVariables,
  CreateNjmmisCaptureResult,
  serializeCreateNjmmisCaptureResultVariables,
} from './deserializedTypes/CreateNjmmisCaptureResult'
import {
  ApplyStateMedicaidVariables as ApplyStateMedicaidVariablesOrig,
  ApplyStateMedicaid as ApplyStateMedicaidOrig,
} from './types/ApplyStateMedicaid'
import {
  ApplyStateMedicaidVariables,
  ApplyStateMedicaid,
  serializeApplyStateMedicaidVariables,
} from './deserializedTypes/ApplyStateMedicaid'
// Queries
export const addMeQuery: AddQueryType<
  MeOrig,
  Me,
  'me',
  {},
  {},
  'refetchMe'
> = ({skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<MeOrig>(ME_QUERY, {
      variables: undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    const deserializedRefetch = refetch

    const fieldName = 'me'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeMe(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchMe: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<Me['me']>(),
      refetchMe: deserializedRefetch,
    }
  })

export const addLazyMeQuery: AddLazyQueryType<
  MeOrig,
  Me,
  'me',
  {},
  {},
  'refetchMe',
  'lazyGetMe'
> = () =>
  addProps(() => {
    const [getData, {data, error, refetch}] = useLazyQuery<MeOrig>(ME_QUERY, {
      variables: undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    const deserializedRefetch = refetch

    const fieldName = 'me'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeMe(data)[fieldName]),
        refetchMe: deserializedRefetch,
        lazyGetMe: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<Me['me']>(),
      refetchMe: deserializedRefetch,
      lazyGetMe: getData,
    }
  })

export const addPersonMatchQuery: AddQueryType<
  PersonMatchOrig,
  PersonMatch,
  'personMatch',
  PersonMatchVariablesOrig,
  PersonMatchVariables,
  'refetchPersonMatch'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      PersonMatchOrig,
      PersonMatchVariablesOrig
    >(PERSON_MATCH_QUERY, {
      variables: variables
        ? serializePersonMatchVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: PersonMatchVariables | undefined
    ) => Promise<ApolloQueryResult<PersonMatchOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializePersonMatchVariables(variables) : undefined)

    const fieldName = 'personMatch'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializePersonMatch(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchPersonMatch: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<PersonMatch['personMatch']>(),
      refetchPersonMatch: deserializedRefetch,
    }
  })

export const addLazyPersonMatchQuery: AddLazyQueryType<
  PersonMatchOrig,
  PersonMatch,
  'personMatch',
  PersonMatchVariablesOrig,
  PersonMatchVariables,
  'refetchPersonMatch',
  'lazyGetPersonMatch'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      PersonMatchOrig,
      PersonMatchVariablesOrig
    >(PERSON_MATCH_QUERY, {
      variables: variables
        ? serializePersonMatchVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: PersonMatchVariables | undefined
    ) => Promise<ApolloQueryResult<PersonMatchOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializePersonMatchVariables(variables) : undefined)

    const fieldName = 'personMatch'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializePersonMatch(data)[fieldName]),
        refetchPersonMatch: deserializedRefetch,
        lazyGetPersonMatch: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<PersonMatch['personMatch']>(),
      refetchPersonMatch: deserializedRefetch,
      lazyGetPersonMatch: getData,
    }
  })

export const addPersonQuery: AddQueryType<
  PersonOrig,
  Person,
  'person',
  PersonVariablesOrig,
  PersonVariables,
  'refetchPerson'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<PersonOrig, PersonVariablesOrig>(
      PERSON_QUERY,
      {
        variables: variables
          ? serializePersonVariables(variables(props))
          : undefined,
        fetchPolicy: fetchPolicy ?? 'cache-and-network',
        skip: skip && isFunction(skip) ? skip(props) : skip,
      }
    )
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: PersonVariables | undefined
    ) => Promise<ApolloQueryResult<PersonOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializePersonVariables(variables) : undefined)

    const fieldName = 'person'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializePerson(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchPerson: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<Person['person']>(),
      refetchPerson: deserializedRefetch,
    }
  })

export const addLazyPersonQuery: AddLazyQueryType<
  PersonOrig,
  Person,
  'person',
  PersonVariablesOrig,
  PersonVariables,
  'refetchPerson',
  'lazyGetPerson'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      PersonOrig,
      PersonVariablesOrig
    >(PERSON_QUERY, {
      variables: variables
        ? serializePersonVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: PersonVariables | undefined
    ) => Promise<ApolloQueryResult<PersonOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializePersonVariables(variables) : undefined)

    const fieldName = 'person'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializePerson(data)[fieldName]),
        refetchPerson: deserializedRefetch,
        lazyGetPerson: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<Person['person']>(),
      refetchPerson: deserializedRefetch,
      lazyGetPerson: getData,
    }
  })

export const addAccountAuditsQuery: AddQueryType<
  AccountAuditsOrig,
  AccountAudits,
  'accountAudits',
  AccountAuditsVariablesOrig,
  AccountAuditsVariables,
  'refetchAccountAudits'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      AccountAuditsOrig,
      AccountAuditsVariablesOrig
    >(ACCOUNT_AUDITS_QUERY, {
      variables: variables
        ? serializeAccountAuditsVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: AccountAuditsVariables | undefined
    ) => Promise<ApolloQueryResult<AccountAuditsOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeAccountAuditsVariables(variables) : undefined
      )

    const fieldName = 'accountAudits'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeAccountAudits(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchAccountAudits: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<AccountAudits['accountAudits']>(),
      refetchAccountAudits: deserializedRefetch,
    }
  })

export const addLazyAccountAuditsQuery: AddLazyQueryType<
  AccountAuditsOrig,
  AccountAudits,
  'accountAudits',
  AccountAuditsVariablesOrig,
  AccountAuditsVariables,
  'refetchAccountAudits',
  'lazyGetAccountAudits'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      AccountAuditsOrig,
      AccountAuditsVariablesOrig
    >(ACCOUNT_AUDITS_QUERY, {
      variables: variables
        ? serializeAccountAuditsVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: AccountAuditsVariables | undefined
    ) => Promise<ApolloQueryResult<AccountAuditsOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeAccountAuditsVariables(variables) : undefined
      )

    const fieldName = 'accountAudits'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeAccountAudits(data)[fieldName]),
        refetchAccountAudits: deserializedRefetch,
        lazyGetAccountAudits: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<AccountAudits['accountAudits']>(),
      refetchAccountAudits: deserializedRefetch,
      lazyGetAccountAudits: getData,
    }
  })

export const addPersonForAwaitingPhoneNumberEsignSessionIdQuery: AddQueryType<
  PersonForAwaitingPhoneNumberEsignSessionIdOrig,
  PersonForAwaitingPhoneNumberEsignSessionId,
  'person',
  PersonForAwaitingPhoneNumberEsignSessionIdVariablesOrig,
  PersonForAwaitingPhoneNumberEsignSessionIdVariables,
  'refetchPersonForAwaitingPhoneNumberEsignSessionId'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      PersonForAwaitingPhoneNumberEsignSessionIdOrig,
      PersonForAwaitingPhoneNumberEsignSessionIdVariablesOrig
    >(PERSON_FOR_AWAITING_PHONE_NUMBER_ESIGN_SESSION_ID_QUERY, {
      variables: variables
        ? serializePersonForAwaitingPhoneNumberEsignSessionIdVariables(
            variables(props)
          )
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?:
        | PersonForAwaitingPhoneNumberEsignSessionIdVariables
        | undefined
    ) => Promise<
      ApolloQueryResult<PersonForAwaitingPhoneNumberEsignSessionIdOrig>
    >

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializePersonForAwaitingPhoneNumberEsignSessionIdVariables(
              variables
            )
          : undefined
      )

    const fieldName = 'person'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializePersonForAwaitingPhoneNumberEsignSessionId(data)[
        fieldName
      ]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchPersonForAwaitingPhoneNumberEsignSessionId: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<
        PersonForAwaitingPhoneNumberEsignSessionId['person']
      >(),
      refetchPersonForAwaitingPhoneNumberEsignSessionId: deserializedRefetch,
    }
  })

export const addLazyPersonForAwaitingPhoneNumberEsignSessionIdQuery: AddLazyQueryType<
  PersonForAwaitingPhoneNumberEsignSessionIdOrig,
  PersonForAwaitingPhoneNumberEsignSessionId,
  'person',
  PersonForAwaitingPhoneNumberEsignSessionIdVariablesOrig,
  PersonForAwaitingPhoneNumberEsignSessionIdVariables,
  'refetchPersonForAwaitingPhoneNumberEsignSessionId',
  'lazyGetPersonForAwaitingPhoneNumberEsignSessionId'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      PersonForAwaitingPhoneNumberEsignSessionIdOrig,
      PersonForAwaitingPhoneNumberEsignSessionIdVariablesOrig
    >(PERSON_FOR_AWAITING_PHONE_NUMBER_ESIGN_SESSION_ID_QUERY, {
      variables: variables
        ? serializePersonForAwaitingPhoneNumberEsignSessionIdVariables(
            variables(props)
          )
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?:
        | PersonForAwaitingPhoneNumberEsignSessionIdVariables
        | undefined
    ) => Promise<
      ApolloQueryResult<PersonForAwaitingPhoneNumberEsignSessionIdOrig>
    >
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializePersonForAwaitingPhoneNumberEsignSessionIdVariables(
              variables
            )
          : undefined
      )

    const fieldName = 'person'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializePersonForAwaitingPhoneNumberEsignSessionId(data)[fieldName]
        ),
        refetchPersonForAwaitingPhoneNumberEsignSessionId: deserializedRefetch,
        lazyGetPersonForAwaitingPhoneNumberEsignSessionId: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<
        PersonForAwaitingPhoneNumberEsignSessionId['person']
      >(),
      refetchPersonForAwaitingPhoneNumberEsignSessionId: deserializedRefetch,
      lazyGetPersonForAwaitingPhoneNumberEsignSessionId: getData,
    }
  })

export const addTasksQuery: AddQueryType<
  TasksOrig,
  Tasks,
  'tasks',
  TasksVariablesOrig,
  TasksVariables,
  'refetchTasks'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<TasksOrig, TasksVariablesOrig>(
      TASKS_QUERY,
      {
        variables: variables
          ? serializeTasksVariables(variables(props))
          : undefined,
        fetchPolicy: fetchPolicy ?? 'cache-and-network',
        skip: skip && isFunction(skip) ? skip(props) : skip,
      }
    )
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: TasksVariables | undefined
    ) => Promise<ApolloQueryResult<TasksOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeTasksVariables(variables) : undefined)

    const fieldName = 'tasks'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeTasks(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchTasks: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<Tasks['tasks']>(),
      refetchTasks: deserializedRefetch,
    }
  })

export const addLazyTasksQuery: AddLazyQueryType<
  TasksOrig,
  Tasks,
  'tasks',
  TasksVariablesOrig,
  TasksVariables,
  'refetchTasks',
  'lazyGetTasks'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      TasksOrig,
      TasksVariablesOrig
    >(TASKS_QUERY, {
      variables: variables
        ? serializeTasksVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: TasksVariables | undefined
    ) => Promise<ApolloQueryResult<TasksOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeTasksVariables(variables) : undefined)

    const fieldName = 'tasks'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeTasks(data)[fieldName]),
        refetchTasks: deserializedRefetch,
        lazyGetTasks: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<Tasks['tasks']>(),
      refetchTasks: deserializedRefetch,
      lazyGetTasks: getData,
    }
  })

export const addTriagesQuery: AddQueryType<
  TriagesOrig,
  Triages,
  'triages',
  TriagesVariablesOrig,
  TriagesVariables,
  'refetchTriages'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<TriagesOrig, TriagesVariablesOrig>(
      TRIAGES_QUERY,
      {
        variables: variables
          ? serializeTriagesVariables(variables(props))
          : undefined,
        fetchPolicy: fetchPolicy ?? 'cache-and-network',
        skip: skip && isFunction(skip) ? skip(props) : skip,
      }
    )
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: TriagesVariables | undefined
    ) => Promise<ApolloQueryResult<TriagesOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeTriagesVariables(variables) : undefined)

    const fieldName = 'triages'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeTriages(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchTriages: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<Triages['triages']>(),
      refetchTriages: deserializedRefetch,
    }
  })

export const addLazyTriagesQuery: AddLazyQueryType<
  TriagesOrig,
  Triages,
  'triages',
  TriagesVariablesOrig,
  TriagesVariables,
  'refetchTriages',
  'lazyGetTriages'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      TriagesOrig,
      TriagesVariablesOrig
    >(TRIAGES_QUERY, {
      variables: variables
        ? serializeTriagesVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: TriagesVariables | undefined
    ) => Promise<ApolloQueryResult<TriagesOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeTriagesVariables(variables) : undefined)

    const fieldName = 'triages'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeTriages(data)[fieldName]),
        refetchTriages: deserializedRefetch,
        lazyGetTriages: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<Triages['triages']>(),
      refetchTriages: deserializedRefetch,
      lazyGetTriages: getData,
    }
  })

export const addAccountQuery: AddQueryType<
  AccountOrig,
  Account,
  'account',
  AccountVariablesOrig,
  AccountVariables,
  'refetchAccount'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<AccountOrig, AccountVariablesOrig>(
      ACCOUNT_QUERY,
      {
        variables: variables
          ? serializeAccountVariables(variables(props))
          : undefined,
        fetchPolicy: fetchPolicy ?? 'cache-and-network',
        skip: skip && isFunction(skip) ? skip(props) : skip,
      }
    )
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: AccountVariables | undefined
    ) => Promise<ApolloQueryResult<AccountOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeAccountVariables(variables) : undefined)

    const fieldName = 'account'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeAccount(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchAccount: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<Account['account']>(),
      refetchAccount: deserializedRefetch,
    }
  })

export const addLazyAccountQuery: AddLazyQueryType<
  AccountOrig,
  Account,
  'account',
  AccountVariablesOrig,
  AccountVariables,
  'refetchAccount',
  'lazyGetAccount'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      AccountOrig,
      AccountVariablesOrig
    >(ACCOUNT_QUERY, {
      variables: variables
        ? serializeAccountVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: AccountVariables | undefined
    ) => Promise<ApolloQueryResult<AccountOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeAccountVariables(variables) : undefined)

    const fieldName = 'account'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeAccount(data)[fieldName]),
        refetchAccount: deserializedRefetch,
        lazyGetAccount: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<Account['account']>(),
      refetchAccount: deserializedRefetch,
      lazyGetAccount: getData,
    }
  })

export const addFederalPovertyLevelsQuery: AddQueryType<
  FederalPovertyLevelsOrig,
  FederalPovertyLevels,
  'federalPovertyLevels',
  FederalPovertyLevelsVariablesOrig,
  FederalPovertyLevelsVariables,
  'refetchFederalPovertyLevels'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      FederalPovertyLevelsOrig,
      FederalPovertyLevelsVariablesOrig
    >(FEDERAL_POVERTY_LEVELS_QUERY, {
      variables: variables
        ? serializeFederalPovertyLevelsVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: FederalPovertyLevelsVariables | undefined
    ) => Promise<ApolloQueryResult<FederalPovertyLevelsOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializeFederalPovertyLevelsVariables(variables)
          : undefined
      )

    const fieldName = 'federalPovertyLevels'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeFederalPovertyLevels(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchFederalPovertyLevels: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<
        FederalPovertyLevels['federalPovertyLevels']
      >(),
      refetchFederalPovertyLevels: deserializedRefetch,
    }
  })

export const addLazyFederalPovertyLevelsQuery: AddLazyQueryType<
  FederalPovertyLevelsOrig,
  FederalPovertyLevels,
  'federalPovertyLevels',
  FederalPovertyLevelsVariablesOrig,
  FederalPovertyLevelsVariables,
  'refetchFederalPovertyLevels',
  'lazyGetFederalPovertyLevels'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      FederalPovertyLevelsOrig,
      FederalPovertyLevelsVariablesOrig
    >(FEDERAL_POVERTY_LEVELS_QUERY, {
      variables: variables
        ? serializeFederalPovertyLevelsVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: FederalPovertyLevelsVariables | undefined
    ) => Promise<ApolloQueryResult<FederalPovertyLevelsOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializeFederalPovertyLevelsVariables(variables)
          : undefined
      )

    const fieldName = 'federalPovertyLevels'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializeFederalPovertyLevels(data)[fieldName]
        ),
        refetchFederalPovertyLevels: deserializedRefetch,
        lazyGetFederalPovertyLevels: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<
        FederalPovertyLevels['federalPovertyLevels']
      >(),
      refetchFederalPovertyLevels: deserializedRefetch,
      lazyGetFederalPovertyLevels: getData,
    }
  })

export const addApplicationsQuery: AddQueryType<
  ApplicationsOrig,
  Applications,
  'applications',
  ApplicationsVariablesOrig,
  ApplicationsVariables,
  'refetchApplications'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      ApplicationsOrig,
      ApplicationsVariablesOrig
    >(APPLICATIONS_QUERY, {
      variables: variables
        ? serializeApplicationsVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: ApplicationsVariables | undefined
    ) => Promise<ApolloQueryResult<ApplicationsOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeApplicationsVariables(variables) : undefined)

    const fieldName = 'applications'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeApplications(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchApplications: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<Applications['applications']>(),
      refetchApplications: deserializedRefetch,
    }
  })

export const addLazyApplicationsQuery: AddLazyQueryType<
  ApplicationsOrig,
  Applications,
  'applications',
  ApplicationsVariablesOrig,
  ApplicationsVariables,
  'refetchApplications',
  'lazyGetApplications'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      ApplicationsOrig,
      ApplicationsVariablesOrig
    >(APPLICATIONS_QUERY, {
      variables: variables
        ? serializeApplicationsVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: ApplicationsVariables | undefined
    ) => Promise<ApolloQueryResult<ApplicationsOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeApplicationsVariables(variables) : undefined)

    const fieldName = 'applications'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeApplications(data)[fieldName]),
        refetchApplications: deserializedRefetch,
        lazyGetApplications: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<Applications['applications']>(),
      refetchApplications: deserializedRefetch,
      lazyGetApplications: getData,
    }
  })

export const addApplicationQuery: AddQueryType<
  ApplicationOrig,
  Application,
  'application',
  ApplicationVariablesOrig,
  ApplicationVariables,
  'refetchApplication'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      ApplicationOrig,
      ApplicationVariablesOrig
    >(APPLICATION_QUERY, {
      variables: variables
        ? serializeApplicationVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: ApplicationVariables | undefined
    ) => Promise<ApolloQueryResult<ApplicationOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeApplicationVariables(variables) : undefined)

    const fieldName = 'application'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeApplication(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchApplication: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<Application['application']>(),
      refetchApplication: deserializedRefetch,
    }
  })

export const addLazyApplicationQuery: AddLazyQueryType<
  ApplicationOrig,
  Application,
  'application',
  ApplicationVariablesOrig,
  ApplicationVariables,
  'refetchApplication',
  'lazyGetApplication'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      ApplicationOrig,
      ApplicationVariablesOrig
    >(APPLICATION_QUERY, {
      variables: variables
        ? serializeApplicationVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: ApplicationVariables | undefined
    ) => Promise<ApolloQueryResult<ApplicationOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeApplicationVariables(variables) : undefined)

    const fieldName = 'application'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeApplication(data)[fieldName]),
        refetchApplication: deserializedRefetch,
        lazyGetApplication: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<Application['application']>(),
      refetchApplication: deserializedRefetch,
      lazyGetApplication: getData,
    }
  })

export const addApplicationForViewQuery: AddQueryType<
  ApplicationForViewOrig,
  ApplicationForView,
  'application',
  ApplicationForViewVariablesOrig,
  ApplicationForViewVariables,
  'refetchApplicationForView'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      ApplicationForViewOrig,
      ApplicationForViewVariablesOrig
    >(APPLICATION_FOR_VIEW_QUERY, {
      variables: variables
        ? serializeApplicationForViewVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: ApplicationForViewVariables | undefined
    ) => Promise<ApolloQueryResult<ApplicationForViewOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeApplicationForViewVariables(variables) : undefined
      )

    const fieldName = 'application'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeApplicationForView(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchApplicationForView: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<ApplicationForView['application']>(),
      refetchApplicationForView: deserializedRefetch,
    }
  })

export const addLazyApplicationForViewQuery: AddLazyQueryType<
  ApplicationForViewOrig,
  ApplicationForView,
  'application',
  ApplicationForViewVariablesOrig,
  ApplicationForViewVariables,
  'refetchApplicationForView',
  'lazyGetApplicationForView'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      ApplicationForViewOrig,
      ApplicationForViewVariablesOrig
    >(APPLICATION_FOR_VIEW_QUERY, {
      variables: variables
        ? serializeApplicationForViewVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: ApplicationForViewVariables | undefined
    ) => Promise<ApolloQueryResult<ApplicationForViewOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeApplicationForViewVariables(variables) : undefined
      )

    const fieldName = 'application'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializeApplicationForView(data)[fieldName]
        ),
        refetchApplicationForView: deserializedRefetch,
        lazyGetApplicationForView: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<ApplicationForView['application']>(),
      refetchApplicationForView: deserializedRefetch,
      lazyGetApplicationForView: getData,
    }
  })

export const addAccountsQuery: AddQueryType<
  AccountsOrig,
  Accounts,
  'accounts',
  AccountsVariablesOrig,
  AccountsVariables,
  'refetchAccounts'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      AccountsOrig,
      AccountsVariablesOrig
    >(ACCOUNTS_QUERY, {
      variables: variables
        ? serializeAccountsVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: AccountsVariables | undefined
    ) => Promise<ApolloQueryResult<AccountsOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeAccountsVariables(variables) : undefined)

    const fieldName = 'accounts'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeAccounts(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchAccounts: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<Accounts['accounts']>(),
      refetchAccounts: deserializedRefetch,
    }
  })

export const addLazyAccountsQuery: AddLazyQueryType<
  AccountsOrig,
  Accounts,
  'accounts',
  AccountsVariablesOrig,
  AccountsVariables,
  'refetchAccounts',
  'lazyGetAccounts'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      AccountsOrig,
      AccountsVariablesOrig
    >(ACCOUNTS_QUERY, {
      variables: variables
        ? serializeAccountsVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: AccountsVariables | undefined
    ) => Promise<ApolloQueryResult<AccountsOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeAccountsVariables(variables) : undefined)

    const fieldName = 'accounts'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeAccounts(data)[fieldName]),
        refetchAccounts: deserializedRefetch,
        lazyGetAccounts: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<Accounts['accounts']>(),
      refetchAccounts: deserializedRefetch,
      lazyGetAccounts: getData,
    }
  })

export const addSignedDocumentUploadUrlQuery: AddQueryType<
  SignedDocumentUploadUrlOrig,
  SignedDocumentUploadUrl,
  'signedDocumentUploadUrl',
  SignedDocumentUploadUrlVariablesOrig,
  SignedDocumentUploadUrlVariables,
  'refetchSignedDocumentUploadUrl'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      SignedDocumentUploadUrlOrig,
      SignedDocumentUploadUrlVariablesOrig
    >(SIGNED_DOCUMENT_UPLOAD_URL_QUERY, {
      variables: variables
        ? serializeSignedDocumentUploadUrlVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: SignedDocumentUploadUrlVariables | undefined
    ) => Promise<ApolloQueryResult<SignedDocumentUploadUrlOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializeSignedDocumentUploadUrlVariables(variables)
          : undefined
      )

    const fieldName = 'signedDocumentUploadUrl'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeSignedDocumentUploadUrl(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchSignedDocumentUploadUrl: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<
        SignedDocumentUploadUrl['signedDocumentUploadUrl']
      >(),
      refetchSignedDocumentUploadUrl: deserializedRefetch,
    }
  })

export const addLazySignedDocumentUploadUrlQuery: AddLazyQueryType<
  SignedDocumentUploadUrlOrig,
  SignedDocumentUploadUrl,
  'signedDocumentUploadUrl',
  SignedDocumentUploadUrlVariablesOrig,
  SignedDocumentUploadUrlVariables,
  'refetchSignedDocumentUploadUrl',
  'lazyGetSignedDocumentUploadUrl'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      SignedDocumentUploadUrlOrig,
      SignedDocumentUploadUrlVariablesOrig
    >(SIGNED_DOCUMENT_UPLOAD_URL_QUERY, {
      variables: variables
        ? serializeSignedDocumentUploadUrlVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: SignedDocumentUploadUrlVariables | undefined
    ) => Promise<ApolloQueryResult<SignedDocumentUploadUrlOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializeSignedDocumentUploadUrlVariables(variables)
          : undefined
      )

    const fieldName = 'signedDocumentUploadUrl'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializeSignedDocumentUploadUrl(data)[fieldName]
        ),
        refetchSignedDocumentUploadUrl: deserializedRefetch,
        lazyGetSignedDocumentUploadUrl: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<
        SignedDocumentUploadUrl['signedDocumentUploadUrl']
      >(),
      refetchSignedDocumentUploadUrl: deserializedRefetch,
      lazyGetSignedDocumentUploadUrl: getData,
    }
  })

export const addSignedDocumentDownloadUrlQuery: AddQueryType<
  SignedDocumentDownloadUrlOrig,
  SignedDocumentDownloadUrl,
  'signedDocumentDownloadUrl',
  SignedDocumentDownloadUrlVariablesOrig,
  SignedDocumentDownloadUrlVariables,
  'refetchSignedDocumentDownloadUrl'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      SignedDocumentDownloadUrlOrig,
      SignedDocumentDownloadUrlVariablesOrig
    >(SIGNED_DOCUMENT_DOWNLOAD_URL_QUERY, {
      variables: variables
        ? serializeSignedDocumentDownloadUrlVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: SignedDocumentDownloadUrlVariables | undefined
    ) => Promise<ApolloQueryResult<SignedDocumentDownloadUrlOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializeSignedDocumentDownloadUrlVariables(variables)
          : undefined
      )

    const fieldName = 'signedDocumentDownloadUrl'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeSignedDocumentDownloadUrl(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchSignedDocumentDownloadUrl: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<
        SignedDocumentDownloadUrl['signedDocumentDownloadUrl']
      >(),
      refetchSignedDocumentDownloadUrl: deserializedRefetch,
    }
  })

export const addLazySignedDocumentDownloadUrlQuery: AddLazyQueryType<
  SignedDocumentDownloadUrlOrig,
  SignedDocumentDownloadUrl,
  'signedDocumentDownloadUrl',
  SignedDocumentDownloadUrlVariablesOrig,
  SignedDocumentDownloadUrlVariables,
  'refetchSignedDocumentDownloadUrl',
  'lazyGetSignedDocumentDownloadUrl'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      SignedDocumentDownloadUrlOrig,
      SignedDocumentDownloadUrlVariablesOrig
    >(SIGNED_DOCUMENT_DOWNLOAD_URL_QUERY, {
      variables: variables
        ? serializeSignedDocumentDownloadUrlVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: SignedDocumentDownloadUrlVariables | undefined
    ) => Promise<ApolloQueryResult<SignedDocumentDownloadUrlOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializeSignedDocumentDownloadUrlVariables(variables)
          : undefined
      )

    const fieldName = 'signedDocumentDownloadUrl'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializeSignedDocumentDownloadUrl(data)[fieldName]
        ),
        refetchSignedDocumentDownloadUrl: deserializedRefetch,
        lazyGetSignedDocumentDownloadUrl: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<
        SignedDocumentDownloadUrl['signedDocumentDownloadUrl']
      >(),
      refetchSignedDocumentDownloadUrl: deserializedRefetch,
      lazyGetSignedDocumentDownloadUrl: getData,
    }
  })

export const addAccountsForCsvQuery: AddQueryType<
  AccountsForCsvOrig,
  AccountsForCsv,
  'accounts',
  AccountsForCsvVariablesOrig,
  AccountsForCsvVariables,
  'refetchAccountsForCsv'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      AccountsForCsvOrig,
      AccountsForCsvVariablesOrig
    >(ACCOUNTS_FOR_CSV_QUERY, {
      variables: variables
        ? serializeAccountsForCsvVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: AccountsForCsvVariables | undefined
    ) => Promise<ApolloQueryResult<AccountsForCsvOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeAccountsForCsvVariables(variables) : undefined
      )

    const fieldName = 'accounts'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeAccountsForCsv(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchAccountsForCsv: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<AccountsForCsv['accounts']>(),
      refetchAccountsForCsv: deserializedRefetch,
    }
  })

export const addLazyAccountsForCsvQuery: AddLazyQueryType<
  AccountsForCsvOrig,
  AccountsForCsv,
  'accounts',
  AccountsForCsvVariablesOrig,
  AccountsForCsvVariables,
  'refetchAccountsForCsv',
  'lazyGetAccountsForCsv'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      AccountsForCsvOrig,
      AccountsForCsvVariablesOrig
    >(ACCOUNTS_FOR_CSV_QUERY, {
      variables: variables
        ? serializeAccountsForCsvVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: AccountsForCsvVariables | undefined
    ) => Promise<ApolloQueryResult<AccountsForCsvOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeAccountsForCsvVariables(variables) : undefined
      )

    const fieldName = 'accounts'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeAccountsForCsv(data)[fieldName]),
        refetchAccountsForCsv: deserializedRefetch,
        lazyGetAccountsForCsv: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<AccountsForCsv['accounts']>(),
      refetchAccountsForCsv: deserializedRefetch,
      lazyGetAccountsForCsv: getData,
    }
  })

export const addPeopleQuery: AddQueryType<
  PeopleOrig,
  People,
  'people',
  PeopleVariablesOrig,
  PeopleVariables,
  'refetchPeople'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<PeopleOrig, PeopleVariablesOrig>(
      PEOPLE_QUERY,
      {
        variables: variables
          ? serializePeopleVariables(variables(props))
          : undefined,
        fetchPolicy: fetchPolicy ?? 'cache-and-network',
        skip: skip && isFunction(skip) ? skip(props) : skip,
      }
    )
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: PeopleVariables | undefined
    ) => Promise<ApolloQueryResult<PeopleOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializePeopleVariables(variables) : undefined)

    const fieldName = 'people'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializePeople(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchPeople: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<People['people']>(),
      refetchPeople: deserializedRefetch,
    }
  })

export const addLazyPeopleQuery: AddLazyQueryType<
  PeopleOrig,
  People,
  'people',
  PeopleVariablesOrig,
  PeopleVariables,
  'refetchPeople',
  'lazyGetPeople'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      PeopleOrig,
      PeopleVariablesOrig
    >(PEOPLE_QUERY, {
      variables: variables
        ? serializePeopleVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: PeopleVariables | undefined
    ) => Promise<ApolloQueryResult<PeopleOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializePeopleVariables(variables) : undefined)

    const fieldName = 'people'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializePeople(data)[fieldName]),
        refetchPeople: deserializedRefetch,
        lazyGetPeople: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<People['people']>(),
      refetchPeople: deserializedRefetch,
      lazyGetPeople: getData,
    }
  })

export const addPeopleWithUnreadTextMessagesQuery: AddQueryType<
  PeopleWithUnreadTextMessagesOrig,
  PeopleWithUnreadTextMessages,
  'people',
  {},
  {},
  'refetchPeopleWithUnreadTextMessages'
> = ({skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<PeopleWithUnreadTextMessagesOrig>(
      PEOPLE_WITH_UNREAD_TEXT_MESSAGES_QUERY,
      {
        variables: undefined,
        fetchPolicy: fetchPolicy ?? 'cache-and-network',
        skip: skip && isFunction(skip) ? skip(props) : skip,
      }
    )
    if (error) throw error

    const deserializedRefetch = refetch

    const fieldName = 'people'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializePeopleWithUnreadTextMessages(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchPeopleWithUnreadTextMessages: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<PeopleWithUnreadTextMessages['people']>(),
      refetchPeopleWithUnreadTextMessages: deserializedRefetch,
    }
  })

export const addLazyPeopleWithUnreadTextMessagesQuery: AddLazyQueryType<
  PeopleWithUnreadTextMessagesOrig,
  PeopleWithUnreadTextMessages,
  'people',
  {},
  {},
  'refetchPeopleWithUnreadTextMessages',
  'lazyGetPeopleWithUnreadTextMessages'
> = () =>
  addProps(() => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      PeopleWithUnreadTextMessagesOrig
    >(PEOPLE_WITH_UNREAD_TEXT_MESSAGES_QUERY, {
      variables: undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    const deserializedRefetch = refetch

    const fieldName = 'people'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializePeopleWithUnreadTextMessages(data)[fieldName]
        ),
        refetchPeopleWithUnreadTextMessages: deserializedRefetch,
        lazyGetPeopleWithUnreadTextMessages: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<PeopleWithUnreadTextMessages['people']>(),
      refetchPeopleWithUnreadTextMessages: deserializedRefetch,
      lazyGetPeopleWithUnreadTextMessages: getData,
    }
  })

export const addDuplicatePeopleQuery: AddQueryType<
  DuplicatePeopleOrig,
  DuplicatePeople,
  'duplicatePeople',
  DuplicatePeopleVariablesOrig,
  DuplicatePeopleVariables,
  'refetchDuplicatePeople'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      DuplicatePeopleOrig,
      DuplicatePeopleVariablesOrig
    >(DUPLICATE_PEOPLE_QUERY, {
      variables: variables
        ? serializeDuplicatePeopleVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: DuplicatePeopleVariables | undefined
    ) => Promise<ApolloQueryResult<DuplicatePeopleOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeDuplicatePeopleVariables(variables) : undefined
      )

    const fieldName = 'duplicatePeople'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeDuplicatePeople(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchDuplicatePeople: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<DuplicatePeople['duplicatePeople']>(),
      refetchDuplicatePeople: deserializedRefetch,
    }
  })

export const addLazyDuplicatePeopleQuery: AddLazyQueryType<
  DuplicatePeopleOrig,
  DuplicatePeople,
  'duplicatePeople',
  DuplicatePeopleVariablesOrig,
  DuplicatePeopleVariables,
  'refetchDuplicatePeople',
  'lazyGetDuplicatePeople'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      DuplicatePeopleOrig,
      DuplicatePeopleVariablesOrig
    >(DUPLICATE_PEOPLE_QUERY, {
      variables: variables
        ? serializeDuplicatePeopleVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: DuplicatePeopleVariables | undefined
    ) => Promise<ApolloQueryResult<DuplicatePeopleOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeDuplicatePeopleVariables(variables) : undefined
      )

    const fieldName = 'duplicatePeople'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializeDuplicatePeople(data)[fieldName]
        ),
        refetchDuplicatePeople: deserializedRefetch,
        lazyGetDuplicatePeople: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<DuplicatePeople['duplicatePeople']>(),
      refetchDuplicatePeople: deserializedRefetch,
      lazyGetDuplicatePeople: getData,
    }
  })

export const addUsersQuery: AddQueryType<
  UsersOrig,
  Users,
  'users',
  UsersVariablesOrig,
  UsersVariables,
  'refetchUsers'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<UsersOrig, UsersVariablesOrig>(
      USERS_QUERY,
      {
        variables: variables
          ? serializeUsersVariables(variables(props))
          : undefined,
        fetchPolicy: fetchPolicy ?? 'cache-and-network',
        skip: skip && isFunction(skip) ? skip(props) : skip,
      }
    )
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: UsersVariables | undefined
    ) => Promise<ApolloQueryResult<UsersOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeUsersVariables(variables) : undefined)

    const fieldName = 'users'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeUsers(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchUsers: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<Users['users']>(),
      refetchUsers: deserializedRefetch,
    }
  })

export const addLazyUsersQuery: AddLazyQueryType<
  UsersOrig,
  Users,
  'users',
  UsersVariablesOrig,
  UsersVariables,
  'refetchUsers',
  'lazyGetUsers'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      UsersOrig,
      UsersVariablesOrig
    >(USERS_QUERY, {
      variables: variables
        ? serializeUsersVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: UsersVariables | undefined
    ) => Promise<ApolloQueryResult<UsersOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeUsersVariables(variables) : undefined)

    const fieldName = 'users'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeUsers(data)[fieldName]),
        refetchUsers: deserializedRefetch,
        lazyGetUsers: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<Users['users']>(),
      refetchUsers: deserializedRefetch,
      lazyGetUsers: getData,
    }
  })

export const addApplicationsReportQuery: AddQueryType<
  ApplicationsReportOrig,
  ApplicationsReport,
  'applications',
  ApplicationsReportVariablesOrig,
  ApplicationsReportVariables,
  'refetchApplicationsReport'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      ApplicationsReportOrig,
      ApplicationsReportVariablesOrig
    >(APPLICATIONS_REPORT_QUERY, {
      variables: variables
        ? serializeApplicationsReportVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: ApplicationsReportVariables | undefined
    ) => Promise<ApolloQueryResult<ApplicationsReportOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeApplicationsReportVariables(variables) : undefined
      )

    const fieldName = 'applications'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeApplicationsReport(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchApplicationsReport: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<ApplicationsReport['applications']>(),
      refetchApplicationsReport: deserializedRefetch,
    }
  })

export const addLazyApplicationsReportQuery: AddLazyQueryType<
  ApplicationsReportOrig,
  ApplicationsReport,
  'applications',
  ApplicationsReportVariablesOrig,
  ApplicationsReportVariables,
  'refetchApplicationsReport',
  'lazyGetApplicationsReport'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      ApplicationsReportOrig,
      ApplicationsReportVariablesOrig
    >(APPLICATIONS_REPORT_QUERY, {
      variables: variables
        ? serializeApplicationsReportVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: ApplicationsReportVariables | undefined
    ) => Promise<ApolloQueryResult<ApplicationsReportOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeApplicationsReportVariables(variables) : undefined
      )

    const fieldName = 'applications'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializeApplicationsReport(data)[fieldName]
        ),
        refetchApplicationsReport: deserializedRefetch,
        lazyGetApplicationsReport: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<ApplicationsReport['applications']>(),
      refetchApplicationsReport: deserializedRefetch,
      lazyGetApplicationsReport: getData,
    }
  })

export const addBenefitOutcomesReportQuery: AddQueryType<
  BenefitOutcomesReportOrig,
  BenefitOutcomesReport,
  'benefitOutcomes',
  BenefitOutcomesReportVariablesOrig,
  BenefitOutcomesReportVariables,
  'refetchBenefitOutcomesReport'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      BenefitOutcomesReportOrig,
      BenefitOutcomesReportVariablesOrig
    >(BENEFIT_OUTCOMES_REPORT_QUERY, {
      variables: variables
        ? serializeBenefitOutcomesReportVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: BenefitOutcomesReportVariables | undefined
    ) => Promise<ApolloQueryResult<BenefitOutcomesReportOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializeBenefitOutcomesReportVariables(variables)
          : undefined
      )

    const fieldName = 'benefitOutcomes'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeBenefitOutcomesReport(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchBenefitOutcomesReport: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<BenefitOutcomesReport['benefitOutcomes']>(),
      refetchBenefitOutcomesReport: deserializedRefetch,
    }
  })

export const addLazyBenefitOutcomesReportQuery: AddLazyQueryType<
  BenefitOutcomesReportOrig,
  BenefitOutcomesReport,
  'benefitOutcomes',
  BenefitOutcomesReportVariablesOrig,
  BenefitOutcomesReportVariables,
  'refetchBenefitOutcomesReport',
  'lazyGetBenefitOutcomesReport'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      BenefitOutcomesReportOrig,
      BenefitOutcomesReportVariablesOrig
    >(BENEFIT_OUTCOMES_REPORT_QUERY, {
      variables: variables
        ? serializeBenefitOutcomesReportVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: BenefitOutcomesReportVariables | undefined
    ) => Promise<ApolloQueryResult<BenefitOutcomesReportOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializeBenefitOutcomesReportVariables(variables)
          : undefined
      )

    const fieldName = 'benefitOutcomes'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializeBenefitOutcomesReport(data)[fieldName]
        ),
        refetchBenefitOutcomesReport: deserializedRefetch,
        lazyGetBenefitOutcomesReport: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<BenefitOutcomesReport['benefitOutcomes']>(),
      refetchBenefitOutcomesReport: deserializedRefetch,
      lazyGetBenefitOutcomesReport: getData,
    }
  })

export const addWebformsQuery: AddQueryType<
  WebformsOrig,
  Webforms,
  'webforms',
  {},
  {},
  'refetchWebforms'
> = ({skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<WebformsOrig>(WEBFORMS_QUERY, {
      variables: undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    const deserializedRefetch = refetch

    const fieldName = 'webforms'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeWebforms(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchWebforms: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<Webforms['webforms']>(),
      refetchWebforms: deserializedRefetch,
    }
  })

export const addLazyWebformsQuery: AddLazyQueryType<
  WebformsOrig,
  Webforms,
  'webforms',
  {},
  {},
  'refetchWebforms',
  'lazyGetWebforms'
> = () =>
  addProps(() => {
    const [getData, {data, error, refetch}] = useLazyQuery<WebformsOrig>(
      WEBFORMS_QUERY,
      {
        variables: undefined,
        fetchPolicy: 'cache-and-network',
      }
    )
    if (error) throw error

    const deserializedRefetch = refetch

    const fieldName = 'webforms'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeWebforms(data)[fieldName]),
        refetchWebforms: deserializedRefetch,
        lazyGetWebforms: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<Webforms['webforms']>(),
      refetchWebforms: deserializedRefetch,
      lazyGetWebforms: getData,
    }
  })

export const addReceivedWebformsQuery: AddQueryType<
  ReceivedWebformsOrig,
  ReceivedWebforms,
  'receivedWebforms',
  ReceivedWebformsVariablesOrig,
  ReceivedWebformsVariables,
  'refetchReceivedWebforms'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      ReceivedWebformsOrig,
      ReceivedWebformsVariablesOrig
    >(RECEIVED_WEBFORMS_QUERY, {
      variables: variables
        ? serializeReceivedWebformsVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: ReceivedWebformsVariables | undefined
    ) => Promise<ApolloQueryResult<ReceivedWebformsOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeReceivedWebformsVariables(variables) : undefined
      )

    const fieldName = 'receivedWebforms'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeReceivedWebforms(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchReceivedWebforms: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<ReceivedWebforms['receivedWebforms']>(),
      refetchReceivedWebforms: deserializedRefetch,
    }
  })

export const addLazyReceivedWebformsQuery: AddLazyQueryType<
  ReceivedWebformsOrig,
  ReceivedWebforms,
  'receivedWebforms',
  ReceivedWebformsVariablesOrig,
  ReceivedWebformsVariables,
  'refetchReceivedWebforms',
  'lazyGetReceivedWebforms'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      ReceivedWebformsOrig,
      ReceivedWebformsVariablesOrig
    >(RECEIVED_WEBFORMS_QUERY, {
      variables: variables
        ? serializeReceivedWebformsVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: ReceivedWebformsVariables | undefined
    ) => Promise<ApolloQueryResult<ReceivedWebformsOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeReceivedWebformsVariables(variables) : undefined
      )

    const fieldName = 'receivedWebforms'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializeReceivedWebforms(data)[fieldName]
        ),
        refetchReceivedWebforms: deserializedRefetch,
        lazyGetReceivedWebforms: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<ReceivedWebforms['receivedWebforms']>(),
      refetchReceivedWebforms: deserializedRefetch,
      lazyGetReceivedWebforms: getData,
    }
  })

export const addConfigQuery: AddQueryType<
  ConfigOrig,
  Config,
  'config',
  {},
  {},
  'refetchConfig'
> = ({skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<ConfigOrig>(CONFIG_QUERY, {
      variables: undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    const deserializedRefetch = refetch

    const fieldName = 'config'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeConfig(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchConfig: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<Config['config']>(),
      refetchConfig: deserializedRefetch,
    }
  })

export const addLazyConfigQuery: AddLazyQueryType<
  ConfigOrig,
  Config,
  'config',
  {},
  {},
  'refetchConfig',
  'lazyGetConfig'
> = () =>
  addProps(() => {
    const [getData, {data, error, refetch}] = useLazyQuery<ConfigOrig>(
      CONFIG_QUERY,
      {
        variables: undefined,
        fetchPolicy: 'cache-and-network',
      }
    )
    if (error) throw error

    const deserializedRefetch = refetch

    const fieldName = 'config'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeConfig(data)[fieldName]),
        refetchConfig: deserializedRefetch,
        lazyGetConfig: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<Config['config']>(),
      refetchConfig: deserializedRefetch,
      lazyGetConfig: getData,
    }
  })

export const addInboundsQuery: AddQueryType<
  InboundsOrig,
  Inbounds,
  'inbounds',
  InboundsVariablesOrig,
  InboundsVariables,
  'refetchInbounds'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      InboundsOrig,
      InboundsVariablesOrig
    >(INBOUNDS_QUERY, {
      variables: variables
        ? serializeInboundsVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: InboundsVariables | undefined
    ) => Promise<ApolloQueryResult<InboundsOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeInboundsVariables(variables) : undefined)

    const fieldName = 'inbounds'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeInbounds(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchInbounds: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<Inbounds['inbounds']>(),
      refetchInbounds: deserializedRefetch,
    }
  })

export const addLazyInboundsQuery: AddLazyQueryType<
  InboundsOrig,
  Inbounds,
  'inbounds',
  InboundsVariablesOrig,
  InboundsVariables,
  'refetchInbounds',
  'lazyGetInbounds'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      InboundsOrig,
      InboundsVariablesOrig
    >(INBOUNDS_QUERY, {
      variables: variables
        ? serializeInboundsVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: InboundsVariables | undefined
    ) => Promise<ApolloQueryResult<InboundsOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeInboundsVariables(variables) : undefined)

    const fieldName = 'inbounds'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeInbounds(data)[fieldName]),
        refetchInbounds: deserializedRefetch,
        lazyGetInbounds: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<Inbounds['inbounds']>(),
      refetchInbounds: deserializedRefetch,
      lazyGetInbounds: getData,
    }
  })

export const addFileTemplatesQuery: AddQueryType<
  FileTemplatesOrig,
  FileTemplates,
  'fileTemplates',
  {},
  {},
  'refetchFileTemplates'
> = ({skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<FileTemplatesOrig>(
      FILE_TEMPLATES_QUERY,
      {
        variables: undefined,
        fetchPolicy: fetchPolicy ?? 'cache-and-network',
        skip: skip && isFunction(skip) ? skip(props) : skip,
      }
    )
    if (error) throw error

    const deserializedRefetch = refetch

    const fieldName = 'fileTemplates'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeFileTemplates(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchFileTemplates: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<FileTemplates['fileTemplates']>(),
      refetchFileTemplates: deserializedRefetch,
    }
  })

export const addLazyFileTemplatesQuery: AddLazyQueryType<
  FileTemplatesOrig,
  FileTemplates,
  'fileTemplates',
  {},
  {},
  'refetchFileTemplates',
  'lazyGetFileTemplates'
> = () =>
  addProps(() => {
    const [getData, {data, error, refetch}] = useLazyQuery<FileTemplatesOrig>(
      FILE_TEMPLATES_QUERY,
      {
        variables: undefined,
        fetchPolicy: 'cache-and-network',
      }
    )
    if (error) throw error

    const deserializedRefetch = refetch

    const fieldName = 'fileTemplates'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeFileTemplates(data)[fieldName]),
        refetchFileTemplates: deserializedRefetch,
        lazyGetFileTemplates: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<FileTemplates['fileTemplates']>(),
      refetchFileTemplates: deserializedRefetch,
      lazyGetFileTemplates: getData,
    }
  })

export const addEditableFilesQuery: AddQueryType<
  EditableFilesOrig,
  EditableFiles,
  'editableFiles',
  EditableFilesVariablesOrig,
  EditableFilesVariables,
  'refetchEditableFiles'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      EditableFilesOrig,
      EditableFilesVariablesOrig
    >(EDITABLE_FILES_QUERY, {
      variables: variables
        ? serializeEditableFilesVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: EditableFilesVariables | undefined
    ) => Promise<ApolloQueryResult<EditableFilesOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeEditableFilesVariables(variables) : undefined
      )

    const fieldName = 'editableFiles'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeEditableFiles(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchEditableFiles: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<EditableFiles['editableFiles']>(),
      refetchEditableFiles: deserializedRefetch,
    }
  })

export const addLazyEditableFilesQuery: AddLazyQueryType<
  EditableFilesOrig,
  EditableFiles,
  'editableFiles',
  EditableFilesVariablesOrig,
  EditableFilesVariables,
  'refetchEditableFiles',
  'lazyGetEditableFiles'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      EditableFilesOrig,
      EditableFilesVariablesOrig
    >(EDITABLE_FILES_QUERY, {
      variables: variables
        ? serializeEditableFilesVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: EditableFilesVariables | undefined
    ) => Promise<ApolloQueryResult<EditableFilesOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeEditableFilesVariables(variables) : undefined
      )

    const fieldName = 'editableFiles'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeEditableFiles(data)[fieldName]),
        refetchEditableFiles: deserializedRefetch,
        lazyGetEditableFiles: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<EditableFiles['editableFiles']>(),
      refetchEditableFiles: deserializedRefetch,
      lazyGetEditableFiles: getData,
    }
  })

export const addInboundWebformsQuery: AddQueryType<
  InboundWebformsOrig,
  InboundWebforms,
  'inboundWebforms',
  {},
  {},
  'refetchInboundWebforms'
> = ({skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<InboundWebformsOrig>(
      INBOUND_WEBFORMS_QUERY,
      {
        variables: undefined,
        fetchPolicy: fetchPolicy ?? 'cache-and-network',
        skip: skip && isFunction(skip) ? skip(props) : skip,
      }
    )
    if (error) throw error

    const deserializedRefetch = refetch

    const fieldName = 'inboundWebforms'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeInboundWebforms(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchInboundWebforms: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<InboundWebforms['inboundWebforms']>(),
      refetchInboundWebforms: deserializedRefetch,
    }
  })

export const addLazyInboundWebformsQuery: AddLazyQueryType<
  InboundWebformsOrig,
  InboundWebforms,
  'inboundWebforms',
  {},
  {},
  'refetchInboundWebforms',
  'lazyGetInboundWebforms'
> = () =>
  addProps(() => {
    const [getData, {data, error, refetch}] = useLazyQuery<InboundWebformsOrig>(
      INBOUND_WEBFORMS_QUERY,
      {
        variables: undefined,
        fetchPolicy: 'cache-and-network',
      }
    )
    if (error) throw error

    const deserializedRefetch = refetch

    const fieldName = 'inboundWebforms'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializeInboundWebforms(data)[fieldName]
        ),
        refetchInboundWebforms: deserializedRefetch,
        lazyGetInboundWebforms: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<InboundWebforms['inboundWebforms']>(),
      refetchInboundWebforms: deserializedRefetch,
      lazyGetInboundWebforms: getData,
    }
  })

export const addFileTemplateMappingQuery: AddQueryType<
  FileTemplateMappingOrig,
  FileTemplateMapping,
  'fileTemplateMapping',
  FileTemplateMappingVariablesOrig,
  FileTemplateMappingVariables,
  'refetchFileTemplateMapping'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      FileTemplateMappingOrig,
      FileTemplateMappingVariablesOrig
    >(FILE_TEMPLATE_MAPPING_QUERY, {
      variables: variables
        ? serializeFileTemplateMappingVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: FileTemplateMappingVariables | undefined
    ) => Promise<ApolloQueryResult<FileTemplateMappingOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeFileTemplateMappingVariables(variables) : undefined
      )

    const fieldName = 'fileTemplateMapping'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeFileTemplateMapping(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchFileTemplateMapping: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<
        FileTemplateMapping['fileTemplateMapping']
      >(),
      refetchFileTemplateMapping: deserializedRefetch,
    }
  })

export const addLazyFileTemplateMappingQuery: AddLazyQueryType<
  FileTemplateMappingOrig,
  FileTemplateMapping,
  'fileTemplateMapping',
  FileTemplateMappingVariablesOrig,
  FileTemplateMappingVariables,
  'refetchFileTemplateMapping',
  'lazyGetFileTemplateMapping'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      FileTemplateMappingOrig,
      FileTemplateMappingVariablesOrig
    >(FILE_TEMPLATE_MAPPING_QUERY, {
      variables: variables
        ? serializeFileTemplateMappingVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: FileTemplateMappingVariables | undefined
    ) => Promise<ApolloQueryResult<FileTemplateMappingOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeFileTemplateMappingVariables(variables) : undefined
      )

    const fieldName = 'fileTemplateMapping'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializeFileTemplateMapping(data)[fieldName]
        ),
        refetchFileTemplateMapping: deserializedRefetch,
        lazyGetFileTemplateMapping: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<
        FileTemplateMapping['fileTemplateMapping']
      >(),
      refetchFileTemplateMapping: deserializedRefetch,
      lazyGetFileTemplateMapping: getData,
    }
  })

export const addPersonReceivedWebformFileTemplatesQuery: AddQueryType<
  PersonReceivedWebformFileTemplatesOrig,
  PersonReceivedWebformFileTemplates,
  'person',
  PersonReceivedWebformFileTemplatesVariablesOrig,
  PersonReceivedWebformFileTemplatesVariables,
  'refetchPersonReceivedWebformFileTemplates'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      PersonReceivedWebformFileTemplatesOrig,
      PersonReceivedWebformFileTemplatesVariablesOrig
    >(PERSON_RECEIVED_WEBFORM_FILE_TEMPLATES_QUERY, {
      variables: variables
        ? serializePersonReceivedWebformFileTemplatesVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: PersonReceivedWebformFileTemplatesVariables | undefined
    ) => Promise<ApolloQueryResult<PersonReceivedWebformFileTemplatesOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializePersonReceivedWebformFileTemplatesVariables(variables)
          : undefined
      )

    const fieldName = 'person'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializePersonReceivedWebformFileTemplates(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchPersonReceivedWebformFileTemplates: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<
        PersonReceivedWebformFileTemplates['person']
      >(),
      refetchPersonReceivedWebformFileTemplates: deserializedRefetch,
    }
  })

export const addLazyPersonReceivedWebformFileTemplatesQuery: AddLazyQueryType<
  PersonReceivedWebformFileTemplatesOrig,
  PersonReceivedWebformFileTemplates,
  'person',
  PersonReceivedWebformFileTemplatesVariablesOrig,
  PersonReceivedWebformFileTemplatesVariables,
  'refetchPersonReceivedWebformFileTemplates',
  'lazyGetPersonReceivedWebformFileTemplates'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      PersonReceivedWebformFileTemplatesOrig,
      PersonReceivedWebformFileTemplatesVariablesOrig
    >(PERSON_RECEIVED_WEBFORM_FILE_TEMPLATES_QUERY, {
      variables: variables
        ? serializePersonReceivedWebformFileTemplatesVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: PersonReceivedWebformFileTemplatesVariables | undefined
    ) => Promise<ApolloQueryResult<PersonReceivedWebformFileTemplatesOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializePersonReceivedWebformFileTemplatesVariables(variables)
          : undefined
      )

    const fieldName = 'person'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializePersonReceivedWebformFileTemplates(data)[fieldName]
        ),
        refetchPersonReceivedWebformFileTemplates: deserializedRefetch,
        lazyGetPersonReceivedWebformFileTemplates: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<
        PersonReceivedWebformFileTemplates['person']
      >(),
      refetchPersonReceivedWebformFileTemplates: deserializedRefetch,
      lazyGetPersonReceivedWebformFileTemplates: getData,
    }
  })

export const addPeopleByMrnQuery: AddQueryType<
  PeopleByMrnOrig,
  PeopleByMrn,
  'peopleByMrn',
  PeopleByMrnVariablesOrig,
  PeopleByMrnVariables,
  'refetchPeopleByMrn'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      PeopleByMrnOrig,
      PeopleByMrnVariablesOrig
    >(PEOPLE_BY_MRN_QUERY, {
      variables: variables
        ? serializePeopleByMrnVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: PeopleByMrnVariables | undefined
    ) => Promise<ApolloQueryResult<PeopleByMrnOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializePeopleByMrnVariables(variables) : undefined)

    const fieldName = 'peopleByMrn'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializePeopleByMrn(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchPeopleByMrn: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<PeopleByMrn['peopleByMrn']>(),
      refetchPeopleByMrn: deserializedRefetch,
    }
  })

export const addLazyPeopleByMrnQuery: AddLazyQueryType<
  PeopleByMrnOrig,
  PeopleByMrn,
  'peopleByMrn',
  PeopleByMrnVariablesOrig,
  PeopleByMrnVariables,
  'refetchPeopleByMrn',
  'lazyGetPeopleByMrn'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      PeopleByMrnOrig,
      PeopleByMrnVariablesOrig
    >(PEOPLE_BY_MRN_QUERY, {
      variables: variables
        ? serializePeopleByMrnVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: PeopleByMrnVariables | undefined
    ) => Promise<ApolloQueryResult<PeopleByMrnOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializePeopleByMrnVariables(variables) : undefined)

    const fieldName = 'peopleByMrn'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializePeopleByMrn(data)[fieldName]),
        refetchPeopleByMrn: deserializedRefetch,
        lazyGetPeopleByMrn: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<PeopleByMrn['peopleByMrn']>(),
      refetchPeopleByMrn: deserializedRefetch,
      lazyGetPeopleByMrn: getData,
    }
  })

export const addRemoteDocumentRequestsQuery: AddQueryType<
  RemoteDocumentRequestsOrig,
  RemoteDocumentRequests,
  'remoteDocumentRequests',
  RemoteDocumentRequestsVariablesOrig,
  RemoteDocumentRequestsVariables,
  'refetchRemoteDocumentRequests'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      RemoteDocumentRequestsOrig,
      RemoteDocumentRequestsVariablesOrig
    >(REMOTE_DOCUMENT_REQUESTS_QUERY, {
      variables: variables
        ? serializeRemoteDocumentRequestsVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: RemoteDocumentRequestsVariables | undefined
    ) => Promise<ApolloQueryResult<RemoteDocumentRequestsOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializeRemoteDocumentRequestsVariables(variables)
          : undefined
      )

    const fieldName = 'remoteDocumentRequests'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeRemoteDocumentRequests(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchRemoteDocumentRequests: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<
        RemoteDocumentRequests['remoteDocumentRequests']
      >(),
      refetchRemoteDocumentRequests: deserializedRefetch,
    }
  })

export const addLazyRemoteDocumentRequestsQuery: AddLazyQueryType<
  RemoteDocumentRequestsOrig,
  RemoteDocumentRequests,
  'remoteDocumentRequests',
  RemoteDocumentRequestsVariablesOrig,
  RemoteDocumentRequestsVariables,
  'refetchRemoteDocumentRequests',
  'lazyGetRemoteDocumentRequests'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      RemoteDocumentRequestsOrig,
      RemoteDocumentRequestsVariablesOrig
    >(REMOTE_DOCUMENT_REQUESTS_QUERY, {
      variables: variables
        ? serializeRemoteDocumentRequestsVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: RemoteDocumentRequestsVariables | undefined
    ) => Promise<ApolloQueryResult<RemoteDocumentRequestsOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializeRemoteDocumentRequestsVariables(variables)
          : undefined
      )

    const fieldName = 'remoteDocumentRequests'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializeRemoteDocumentRequests(data)[fieldName]
        ),
        refetchRemoteDocumentRequests: deserializedRefetch,
        lazyGetRemoteDocumentRequests: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<
        RemoteDocumentRequests['remoteDocumentRequests']
      >(),
      refetchRemoteDocumentRequests: deserializedRefetch,
      lazyGetRemoteDocumentRequests: getData,
    }
  })

export const addTextMessagesQuery: AddQueryType<
  TextMessagesOrig,
  TextMessages,
  'textMessages',
  TextMessagesVariablesOrig,
  TextMessagesVariables,
  'refetchTextMessages'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      TextMessagesOrig,
      TextMessagesVariablesOrig
    >(TEXT_MESSAGES_QUERY, {
      variables: variables
        ? serializeTextMessagesVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: TextMessagesVariables | undefined
    ) => Promise<ApolloQueryResult<TextMessagesOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeTextMessagesVariables(variables) : undefined)

    const fieldName = 'textMessages'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeTextMessages(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchTextMessages: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<TextMessages['textMessages']>(),
      refetchTextMessages: deserializedRefetch,
    }
  })

export const addLazyTextMessagesQuery: AddLazyQueryType<
  TextMessagesOrig,
  TextMessages,
  'textMessages',
  TextMessagesVariablesOrig,
  TextMessagesVariables,
  'refetchTextMessages',
  'lazyGetTextMessages'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      TextMessagesOrig,
      TextMessagesVariablesOrig
    >(TEXT_MESSAGES_QUERY, {
      variables: variables
        ? serializeTextMessagesVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: TextMessagesVariables | undefined
    ) => Promise<ApolloQueryResult<TextMessagesOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeTextMessagesVariables(variables) : undefined)

    const fieldName = 'textMessages'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeTextMessages(data)[fieldName]),
        refetchTextMessages: deserializedRefetch,
        lazyGetTextMessages: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<TextMessages['textMessages']>(),
      refetchTextMessages: deserializedRefetch,
      lazyGetTextMessages: getData,
    }
  })

export const addSentWebFormsQuery: AddQueryType<
  SentWebFormsOrig,
  SentWebForms,
  'sentWebForms',
  SentWebFormsVariablesOrig,
  SentWebFormsVariables,
  'refetchSentWebForms'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      SentWebFormsOrig,
      SentWebFormsVariablesOrig
    >(SENTWEBFORMS_PENDING_QUERY, {
      variables: variables
        ? serializeSentWebFormsVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: SentWebFormsVariables | undefined
    ) => Promise<ApolloQueryResult<SentWebFormsOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeSentWebFormsVariables(variables) : undefined)

    const fieldName = 'sentWebForms'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeSentWebForms(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchSentWebForms: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<SentWebForms['sentWebForms']>(),
      refetchSentWebForms: deserializedRefetch,
    }
  })

export const addLazySentWebFormsQuery: AddLazyQueryType<
  SentWebFormsOrig,
  SentWebForms,
  'sentWebForms',
  SentWebFormsVariablesOrig,
  SentWebFormsVariables,
  'refetchSentWebForms',
  'lazyGetSentWebForms'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      SentWebFormsOrig,
      SentWebFormsVariablesOrig
    >(SENTWEBFORMS_PENDING_QUERY, {
      variables: variables
        ? serializeSentWebFormsVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: SentWebFormsVariables | undefined
    ) => Promise<ApolloQueryResult<SentWebFormsOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeSentWebFormsVariables(variables) : undefined)

    const fieldName = 'sentWebForms'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeSentWebForms(data)[fieldName]),
        refetchSentWebForms: deserializedRefetch,
        lazyGetSentWebForms: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<SentWebForms['sentWebForms']>(),
      refetchSentWebForms: deserializedRefetch,
      lazyGetSentWebForms: getData,
    }
  })

export const addEventLogsQuery: AddQueryType<
  EventLogsOrig,
  EventLogs,
  'eventLogs',
  EventLogsVariablesOrig,
  EventLogsVariables,
  'refetchEventLogs'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      EventLogsOrig,
      EventLogsVariablesOrig
    >(EVENT_LOG_QUERY, {
      variables: variables
        ? serializeEventLogsVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: EventLogsVariables | undefined
    ) => Promise<ApolloQueryResult<EventLogsOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeEventLogsVariables(variables) : undefined)

    const fieldName = 'eventLogs'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeEventLogs(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchEventLogs: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<EventLogs['eventLogs']>(),
      refetchEventLogs: deserializedRefetch,
    }
  })

export const addLazyEventLogsQuery: AddLazyQueryType<
  EventLogsOrig,
  EventLogs,
  'eventLogs',
  EventLogsVariablesOrig,
  EventLogsVariables,
  'refetchEventLogs',
  'lazyGetEventLogs'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      EventLogsOrig,
      EventLogsVariablesOrig
    >(EVENT_LOG_QUERY, {
      variables: variables
        ? serializeEventLogsVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: EventLogsVariables | undefined
    ) => Promise<ApolloQueryResult<EventLogsOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeEventLogsVariables(variables) : undefined)

    const fieldName = 'eventLogs'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeEventLogs(data)[fieldName]),
        refetchEventLogs: deserializedRefetch,
        lazyGetEventLogs: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<EventLogs['eventLogs']>(),
      refetchEventLogs: deserializedRefetch,
      lazyGetEventLogs: getData,
    }
  })

export const addNumberOfPeopleWithUnreviewedReceivedWebformsQuery: AddQueryType<
  NumberOfPeopleWithUnreviewedReceivedWebformsOrig,
  NumberOfPeopleWithUnreviewedReceivedWebforms,
  'numberOfPeopleWithUnreviewedReceivedWebforms',
  NumberOfPeopleWithUnreviewedReceivedWebformsVariablesOrig,
  NumberOfPeopleWithUnreviewedReceivedWebformsVariables,
  'refetchNumberOfPeopleWithUnreviewedReceivedWebforms'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      NumberOfPeopleWithUnreviewedReceivedWebformsOrig,
      NumberOfPeopleWithUnreviewedReceivedWebformsVariablesOrig
    >(NUMBER_OF_PEOPLE_WITH_UNREVIEWED_RECEIVED_WEBFORMS_QUERY, {
      variables: variables
        ? serializeNumberOfPeopleWithUnreviewedReceivedWebformsVariables(
            variables(props)
          )
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?:
        | NumberOfPeopleWithUnreviewedReceivedWebformsVariables
        | undefined
    ) => Promise<
      ApolloQueryResult<NumberOfPeopleWithUnreviewedReceivedWebformsOrig>
    >

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializeNumberOfPeopleWithUnreviewedReceivedWebformsVariables(
              variables
            )
          : undefined
      )

    const fieldName = 'numberOfPeopleWithUnreviewedReceivedWebforms'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeNumberOfPeopleWithUnreviewedReceivedWebforms(data)[
        fieldName
      ]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchNumberOfPeopleWithUnreviewedReceivedWebforms: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<
        NumberOfPeopleWithUnreviewedReceivedWebforms['numberOfPeopleWithUnreviewedReceivedWebforms']
      >(),
      refetchNumberOfPeopleWithUnreviewedReceivedWebforms: deserializedRefetch,
    }
  })

export const addLazyNumberOfPeopleWithUnreviewedReceivedWebformsQuery: AddLazyQueryType<
  NumberOfPeopleWithUnreviewedReceivedWebformsOrig,
  NumberOfPeopleWithUnreviewedReceivedWebforms,
  'numberOfPeopleWithUnreviewedReceivedWebforms',
  NumberOfPeopleWithUnreviewedReceivedWebformsVariablesOrig,
  NumberOfPeopleWithUnreviewedReceivedWebformsVariables,
  'refetchNumberOfPeopleWithUnreviewedReceivedWebforms',
  'lazyGetNumberOfPeopleWithUnreviewedReceivedWebforms'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      NumberOfPeopleWithUnreviewedReceivedWebformsOrig,
      NumberOfPeopleWithUnreviewedReceivedWebformsVariablesOrig
    >(NUMBER_OF_PEOPLE_WITH_UNREVIEWED_RECEIVED_WEBFORMS_QUERY, {
      variables: variables
        ? serializeNumberOfPeopleWithUnreviewedReceivedWebformsVariables(
            variables(props)
          )
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?:
        | NumberOfPeopleWithUnreviewedReceivedWebformsVariables
        | undefined
    ) => Promise<
      ApolloQueryResult<NumberOfPeopleWithUnreviewedReceivedWebformsOrig>
    >
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializeNumberOfPeopleWithUnreviewedReceivedWebformsVariables(
              variables
            )
          : undefined
      )

    const fieldName = 'numberOfPeopleWithUnreviewedReceivedWebforms'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializeNumberOfPeopleWithUnreviewedReceivedWebforms(data)[
            fieldName
          ]
        ),
        refetchNumberOfPeopleWithUnreviewedReceivedWebforms: deserializedRefetch,
        lazyGetNumberOfPeopleWithUnreviewedReceivedWebforms: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<
        NumberOfPeopleWithUnreviewedReceivedWebforms['numberOfPeopleWithUnreviewedReceivedWebforms']
      >(),
      refetchNumberOfPeopleWithUnreviewedReceivedWebforms: deserializedRefetch,
      lazyGetNumberOfPeopleWithUnreviewedReceivedWebforms: getData,
    }
  })

export const addNjmmisCaptureResultQuery: AddQueryType<
  NjmmisCaptureResultOrig,
  NjmmisCaptureResult,
  'njmmisCaptureResult',
  NjmmisCaptureResultVariablesOrig,
  NjmmisCaptureResultVariables,
  'refetchNjmmisCaptureResult'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      NjmmisCaptureResultOrig,
      NjmmisCaptureResultVariablesOrig
    >(NJMMIS_CAPTURE_RESULT_QUERY, {
      variables: variables
        ? serializeNjmmisCaptureResultVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: NjmmisCaptureResultVariables | undefined
    ) => Promise<ApolloQueryResult<NjmmisCaptureResultOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeNjmmisCaptureResultVariables(variables) : undefined
      )

    const fieldName = 'njmmisCaptureResult'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeNjmmisCaptureResult(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchNjmmisCaptureResult: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<
        NjmmisCaptureResult['njmmisCaptureResult']
      >(),
      refetchNjmmisCaptureResult: deserializedRefetch,
    }
  })

export const addLazyNjmmisCaptureResultQuery: AddLazyQueryType<
  NjmmisCaptureResultOrig,
  NjmmisCaptureResult,
  'njmmisCaptureResult',
  NjmmisCaptureResultVariablesOrig,
  NjmmisCaptureResultVariables,
  'refetchNjmmisCaptureResult',
  'lazyGetNjmmisCaptureResult'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      NjmmisCaptureResultOrig,
      NjmmisCaptureResultVariablesOrig
    >(NJMMIS_CAPTURE_RESULT_QUERY, {
      variables: variables
        ? serializeNjmmisCaptureResultVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: NjmmisCaptureResultVariables | undefined
    ) => Promise<ApolloQueryResult<NjmmisCaptureResultOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeNjmmisCaptureResultVariables(variables) : undefined
      )

    const fieldName = 'njmmisCaptureResult'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializeNjmmisCaptureResult(data)[fieldName]
        ),
        refetchNjmmisCaptureResult: deserializedRefetch,
        lazyGetNjmmisCaptureResult: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<
        NjmmisCaptureResult['njmmisCaptureResult']
      >(),
      refetchNjmmisCaptureResult: deserializedRefetch,
      lazyGetNjmmisCaptureResult: getData,
    }
  })

export const addCountyFaxesQuery: AddQueryType<
  CountyFaxesOrig,
  CountyFaxes,
  'countyFaxes',
  CountyFaxesVariablesOrig,
  CountyFaxesVariables,
  'refetchCountyFaxes'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      CountyFaxesOrig,
      CountyFaxesVariablesOrig
    >(COUNTY_FAXES_QUERY, {
      variables: variables
        ? serializeCountyFaxesVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: CountyFaxesVariables | undefined
    ) => Promise<ApolloQueryResult<CountyFaxesOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeCountyFaxesVariables(variables) : undefined)

    const fieldName = 'countyFaxes'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeCountyFaxes(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchCountyFaxes: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<CountyFaxes['countyFaxes']>(),
      refetchCountyFaxes: deserializedRefetch,
    }
  })

export const addLazyCountyFaxesQuery: AddLazyQueryType<
  CountyFaxesOrig,
  CountyFaxes,
  'countyFaxes',
  CountyFaxesVariablesOrig,
  CountyFaxesVariables,
  'refetchCountyFaxes',
  'lazyGetCountyFaxes'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      CountyFaxesOrig,
      CountyFaxesVariablesOrig
    >(COUNTY_FAXES_QUERY, {
      variables: variables
        ? serializeCountyFaxesVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: CountyFaxesVariables | undefined
    ) => Promise<ApolloQueryResult<CountyFaxesOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(variables ? serializeCountyFaxesVariables(variables) : undefined)

    const fieldName = 'countyFaxes'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeCountyFaxes(data)[fieldName]),
        refetchCountyFaxes: deserializedRefetch,
        lazyGetCountyFaxes: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<CountyFaxes['countyFaxes']>(),
      refetchCountyFaxes: deserializedRefetch,
      lazyGetCountyFaxes: getData,
    }
  })

export const addAuditZipfilesQuery: AddQueryType<
  AuditZipfilesOrig,
  AuditZipfiles,
  'auditZipfiles',
  AuditZipfilesVariablesOrig,
  AuditZipfilesVariables,
  'refetchAuditZipfiles'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      AuditZipfilesOrig,
      AuditZipfilesVariablesOrig
    >(AUDIT_ZIPFILES_QUERY, {
      variables: variables
        ? serializeAuditZipfilesVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: AuditZipfilesVariables | undefined
    ) => Promise<ApolloQueryResult<AuditZipfilesOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeAuditZipfilesVariables(variables) : undefined
      )

    const fieldName = 'auditZipfiles'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeAuditZipfiles(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchAuditZipfiles: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<AuditZipfiles['auditZipfiles']>(),
      refetchAuditZipfiles: deserializedRefetch,
    }
  })

export const addLazyAuditZipfilesQuery: AddLazyQueryType<
  AuditZipfilesOrig,
  AuditZipfiles,
  'auditZipfiles',
  AuditZipfilesVariablesOrig,
  AuditZipfilesVariables,
  'refetchAuditZipfiles',
  'lazyGetAuditZipfiles'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      AuditZipfilesOrig,
      AuditZipfilesVariablesOrig
    >(AUDIT_ZIPFILES_QUERY, {
      variables: variables
        ? serializeAuditZipfilesVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: AuditZipfilesVariables | undefined
    ) => Promise<ApolloQueryResult<AuditZipfilesOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables ? serializeAuditZipfilesVariables(variables) : undefined
      )

    const fieldName = 'auditZipfiles'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeAuditZipfiles(data)[fieldName]),
        refetchAuditZipfiles: deserializedRefetch,
        lazyGetAuditZipfiles: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<AuditZipfiles['auditZipfiles']>(),
      refetchAuditZipfiles: deserializedRefetch,
      lazyGetAuditZipfiles: getData,
    }
  })

export const addAuditsQuery: AddQueryType<
  AuditsOrig,
  Audits,
  'audits',
  {},
  {},
  'refetchAudits'
> = ({skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<AuditsOrig>(AUDIT_QUERY, {
      variables: undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    const deserializedRefetch = refetch

    const fieldName = 'audits'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeAudits(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchAudits: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<Audits['audits']>(),
      refetchAudits: deserializedRefetch,
    }
  })

export const addLazyAuditsQuery: AddLazyQueryType<
  AuditsOrig,
  Audits,
  'audits',
  {},
  {},
  'refetchAudits',
  'lazyGetAudits'
> = () =>
  addProps(() => {
    const [getData, {data, error, refetch}] = useLazyQuery<AuditsOrig>(
      AUDIT_QUERY,
      {
        variables: undefined,
        fetchPolicy: 'cache-and-network',
      }
    )
    if (error) throw error

    const deserializedRefetch = refetch

    const fieldName = 'audits'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializeAudits(data)[fieldName]),
        refetchAudits: deserializedRefetch,
        lazyGetAudits: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<Audits['audits']>(),
      refetchAudits: deserializedRefetch,
      lazyGetAudits: getData,
    }
  })

export const addSlideApplicationRulesQuery: AddQueryType<
  SlideApplicationRulesOrig,
  SlideApplicationRules,
  'slideApplicationRules',
  {},
  {},
  'refetchSlideApplicationRules'
> = ({skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<SlideApplicationRulesOrig>(
      SLIDE_APPLICATION_RULES_QUERY,
      {
        variables: undefined,
        fetchPolicy: fetchPolicy ?? 'cache-and-network',
        skip: skip && isFunction(skip) ? skip(props) : skip,
      }
    )
    if (error) throw error

    const deserializedRefetch = refetch

    const fieldName = 'slideApplicationRules'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeSlideApplicationRules(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchSlideApplicationRules: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<
        SlideApplicationRules['slideApplicationRules']
      >(),
      refetchSlideApplicationRules: deserializedRefetch,
    }
  })

export const addLazySlideApplicationRulesQuery: AddLazyQueryType<
  SlideApplicationRulesOrig,
  SlideApplicationRules,
  'slideApplicationRules',
  {},
  {},
  'refetchSlideApplicationRules',
  'lazyGetSlideApplicationRules'
> = () =>
  addProps(() => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      SlideApplicationRulesOrig
    >(SLIDE_APPLICATION_RULES_QUERY, {
      variables: undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    const deserializedRefetch = refetch

    const fieldName = 'slideApplicationRules'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializeSlideApplicationRules(data)[fieldName]
        ),
        refetchSlideApplicationRules: deserializedRefetch,
        lazyGetSlideApplicationRules: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<
        SlideApplicationRules['slideApplicationRules']
      >(),
      refetchSlideApplicationRules: deserializedRefetch,
      lazyGetSlideApplicationRules: getData,
    }
  })

export const addNjMedicaidApplicationStatesQuery: AddQueryType<
  NjMedicaidApplicationStatesOrig,
  NjMedicaidApplicationStates,
  'njMedicaidApplicationStates',
  NjMedicaidApplicationStatesVariablesOrig,
  NjMedicaidApplicationStatesVariables,
  'refetchNjMedicaidApplicationStates'
> = ({variables, skip, fetchPolicy} = {}) =>
  addProps((props) => {
    const {data, error, refetch} = useQuery<
      NjMedicaidApplicationStatesOrig,
      NjMedicaidApplicationStatesVariablesOrig
    >(NJ_MEDICAID_STATE_QUERY, {
      variables: variables
        ? serializeNjMedicaidApplicationStatesVariables(variables(props))
        : undefined,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      skip: skip && isFunction(skip) ? skip(props) : skip,
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: NjMedicaidApplicationStatesVariables | undefined
    ) => Promise<ApolloQueryResult<NjMedicaidApplicationStatesOrig>>

    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializeNjMedicaidApplicationStatesVariables(variables)
          : undefined
      )

    const fieldName = 'njMedicaidApplicationStates'
    const deserializedData = useMemo(() => {
      if (!data) return null
      return deserializeNjMedicaidApplicationStates(data)[fieldName]
    }, [data])
    if (data) {
      return {
        [fieldName]: makeDataLoaded(deserializedData!),
        refetchNjMedicaidApplicationStates: deserializedRefetch,
      }
    }

    return {
      [fieldName]: makeDataLoading<
        NjMedicaidApplicationStates['njMedicaidApplicationStates']
      >(),
      refetchNjMedicaidApplicationStates: deserializedRefetch,
    }
  })

export const addLazyNjMedicaidApplicationStatesQuery: AddLazyQueryType<
  NjMedicaidApplicationStatesOrig,
  NjMedicaidApplicationStates,
  'njMedicaidApplicationStates',
  NjMedicaidApplicationStatesVariablesOrig,
  NjMedicaidApplicationStatesVariables,
  'refetchNjMedicaidApplicationStates',
  'lazyGetNjMedicaidApplicationStates'
> = ({variables} = {}) =>
  addProps((props) => {
    const [getData, {data, error, refetch}] = useLazyQuery<
      NjMedicaidApplicationStatesOrig,
      NjMedicaidApplicationStatesVariablesOrig
    >(NJ_MEDICAID_STATE_QUERY, {
      variables: variables
        ? serializeNjMedicaidApplicationStatesVariables(variables(props))
        : undefined,
      fetchPolicy: 'cache-and-network',
    })
    if (error) throw error

    type DeserializedRefetchType = (
      variables?: NjMedicaidApplicationStatesVariables | undefined
    ) => Promise<ApolloQueryResult<NjMedicaidApplicationStatesOrig>>
    const deserializedRefetch: DeserializedRefetchType = (variables) =>
      refetch(
        variables
          ? serializeNjMedicaidApplicationStatesVariables(variables)
          : undefined
      )

    const fieldName = 'njMedicaidApplicationStates'
    if (data) {
      return {
        [fieldName]: makeDataLoaded(
          deserializeNjMedicaidApplicationStates(data)[fieldName]
        ),
        refetchNjMedicaidApplicationStates: deserializedRefetch,
        lazyGetNjMedicaidApplicationStates: getData,
      }
    }
    return {
      [fieldName]: makeDataLoading<
        NjMedicaidApplicationStates['njMedicaidApplicationStates']
      >(),
      refetchNjMedicaidApplicationStates: deserializedRefetch,
      lazyGetNjMedicaidApplicationStates: getData,
    }
  })

// Mutations
export const addCreateAccountAndPersonMutation: AddMutationType<
  CreateAccountAndPersonOrig,
  CreateAccountAndPerson,
  CreateAccountAndPersonVariablesOrig,
  CreateAccountAndPersonVariables,
  'mutateCreateAccountAndPerson'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      CreateAccountAndPersonOrig,
      CreateAccountAndPersonVariablesOrig
    >(CREATE_ACCOUNT_AND_PERSON_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeCreateAccountAndPersonVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      CreateAccountAndPersonOrig,
      CreateAccountAndPersonVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeCreateAccountAndPersonVariables(
          variables
        )
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateCreateAccountAndPerson'
    return {[mutateName]: deserializedMutate}
  })

export const addUpdateTaskMutation: AddMutationType<
  UpdateTaskOrig,
  UpdateTask,
  UpdateTaskVariablesOrig,
  UpdateTaskVariables,
  'mutateUpdateTask'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<UpdateTaskOrig, UpdateTaskVariablesOrig>(
      UPDATE_TASK_MUTATION,
      {
        onCompleted: onCompleted?.(props),
        onError: onError?.(props),
        variables: variables
          ? serializeUpdateTaskVariables(variables(props))
          : undefined,
        refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
        awaitRefetchQueries,
      }
    )

    type DeserializedMutateType = MutationFunction<
      UpdateTaskOrig,
      UpdateTaskVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeUpdateTaskVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateUpdateTask'
    return {[mutateName]: deserializedMutate}
  })

export const addUpdateTriageMutation: AddMutationType<
  UpdateTriageOrig,
  UpdateTriage,
  UpdateTriageVariablesOrig,
  UpdateTriageVariables,
  'mutateUpdateTriage'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<UpdateTriageOrig, UpdateTriageVariablesOrig>(
      UPDATE_TRIAGE_MUTATION,
      {
        onCompleted: onCompleted?.(props),
        onError: onError?.(props),
        variables: variables
          ? serializeUpdateTriageVariables(variables(props))
          : undefined,
        refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
        awaitRefetchQueries,
      }
    )

    type DeserializedMutateType = MutationFunction<
      UpdateTriageOrig,
      UpdateTriageVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeUpdateTriageVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateUpdateTriage'
    return {[mutateName]: deserializedMutate}
  })

export const addUpdatePersonMutation: AddMutationType<
  UpdatePersonOrig,
  UpdatePerson,
  UpdatePersonVariablesOrig,
  UpdatePersonVariables,
  'mutateUpdatePerson'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<UpdatePersonOrig, UpdatePersonVariablesOrig>(
      UPDATE_PERSON_MUTATION,
      {
        onCompleted: onCompleted?.(props),
        onError: onError?.(props),
        variables: variables
          ? serializeUpdatePersonVariables(variables(props))
          : undefined,
        refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
        awaitRefetchQueries,
      }
    )

    type DeserializedMutateType = MutationFunction<
      UpdatePersonOrig,
      UpdatePersonVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeUpdatePersonVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateUpdatePerson'
    return {[mutateName]: deserializedMutate}
  })

export const addUpdateAccountMutation: AddMutationType<
  UpdateAccountOrig,
  UpdateAccount,
  UpdateAccountVariablesOrig,
  UpdateAccountVariables,
  'mutateUpdateAccount'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<UpdateAccountOrig, UpdateAccountVariablesOrig>(
      UPDATE_ACCOUNT_MUTATION,
      {
        onCompleted: onCompleted?.(props),
        onError: onError?.(props),
        variables: variables
          ? serializeUpdateAccountVariables(variables(props))
          : undefined,
        refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
        awaitRefetchQueries,
      }
    )

    type DeserializedMutateType = MutationFunction<
      UpdateAccountOrig,
      UpdateAccountVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeUpdateAccountVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateUpdateAccount'
    return {[mutateName]: deserializedMutate}
  })

export const addSaveEligibilityDeterminationsMutation: AddMutationType<
  SaveEligibilityDeterminationsOrig,
  SaveEligibilityDeterminations,
  SaveEligibilityDeterminationsVariablesOrig,
  SaveEligibilityDeterminationsVariables,
  'mutateSaveEligibilityDeterminations'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      SaveEligibilityDeterminationsOrig,
      SaveEligibilityDeterminationsVariablesOrig
    >(SAVE_ELIGIBILITY_DETERMINATIONS_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeSaveEligibilityDeterminationsVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      SaveEligibilityDeterminationsOrig,
      SaveEligibilityDeterminationsVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeSaveEligibilityDeterminationsVariables(
          variables
        )
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateSaveEligibilityDeterminations'
    return {[mutateName]: deserializedMutate}
  })

export const addCreateApplicationMutation: AddMutationType<
  CreateApplicationOrig,
  CreateApplication,
  CreateApplicationVariablesOrig,
  CreateApplicationVariables,
  'mutateCreateApplication'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      CreateApplicationOrig,
      CreateApplicationVariablesOrig
    >(CREATE_APPLICATION_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeCreateApplicationVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      CreateApplicationOrig,
      CreateApplicationVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeCreateApplicationVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateCreateApplication'
    return {[mutateName]: deserializedMutate}
  })

export const addUpdateApplicationStatusMutation: AddMutationType<
  UpdateApplicationStatusOrig,
  UpdateApplicationStatus,
  UpdateApplicationStatusVariablesOrig,
  UpdateApplicationStatusVariables,
  'mutateUpdateApplicationStatus'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      UpdateApplicationStatusOrig,
      UpdateApplicationStatusVariablesOrig
    >(UPDATE_APPLICATION_STATUS_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeUpdateApplicationStatusVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      UpdateApplicationStatusOrig,
      UpdateApplicationStatusVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeUpdateApplicationStatusVariables(
          variables
        )
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateUpdateApplicationStatus'
    return {[mutateName]: deserializedMutate}
  })

export const addUpdateApplicationMutation: AddMutationType<
  UpdateApplicationOrig,
  UpdateApplication,
  UpdateApplicationVariablesOrig,
  UpdateApplicationVariables,
  'mutateUpdateApplication'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      UpdateApplicationOrig,
      UpdateApplicationVariablesOrig
    >(UPDATE_APPLICATION_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeUpdateApplicationVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      UpdateApplicationOrig,
      UpdateApplicationVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeUpdateApplicationVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateUpdateApplication'
    return {[mutateName]: deserializedMutate}
  })

export const addCreateDocumentMutation: AddMutationType<
  CreateDocumentOrig,
  CreateDocument,
  CreateDocumentVariablesOrig,
  CreateDocumentVariables,
  'mutateCreateDocument'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      CreateDocumentOrig,
      CreateDocumentVariablesOrig
    >(CREATE_DOCUMENT_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeCreateDocumentVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      CreateDocumentOrig,
      CreateDocumentVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeCreateDocumentVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateCreateDocument'
    return {[mutateName]: deserializedMutate}
  })

export const addUpdateDocumentMutation: AddMutationType<
  UpdateDocumentOrig,
  UpdateDocument,
  UpdateDocumentVariablesOrig,
  UpdateDocumentVariables,
  'mutateUpdateDocument'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      UpdateDocumentOrig,
      UpdateDocumentVariablesOrig
    >(UPDATE_DOCUMENT_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeUpdateDocumentVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      UpdateDocumentOrig,
      UpdateDocumentVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeUpdateDocumentVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateUpdateDocument'
    return {[mutateName]: deserializedMutate}
  })

export const addCreateOrUpdateBenefitOutcomeMutation: AddMutationType<
  CreateOrUpdateBenefitOutcomeOrig,
  CreateOrUpdateBenefitOutcome,
  CreateOrUpdateBenefitOutcomeVariablesOrig,
  CreateOrUpdateBenefitOutcomeVariables,
  'mutateCreateOrUpdateBenefitOutcome'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      CreateOrUpdateBenefitOutcomeOrig,
      CreateOrUpdateBenefitOutcomeVariablesOrig
    >(CREATE_OR_UPDATE_BENEFIT_OUTCOME_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeCreateOrUpdateBenefitOutcomeVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      CreateOrUpdateBenefitOutcomeOrig,
      CreateOrUpdateBenefitOutcomeVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeCreateOrUpdateBenefitOutcomeVariables(
          variables
        )
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateCreateOrUpdateBenefitOutcome'
    return {[mutateName]: deserializedMutate}
  })

export const addDeleteBenefitOutcomeMutation: AddMutationType<
  DeleteBenefitOutcomeOrig,
  DeleteBenefitOutcome,
  DeleteBenefitOutcomeVariablesOrig,
  DeleteBenefitOutcomeVariables,
  'mutateDeleteBenefitOutcome'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      DeleteBenefitOutcomeOrig,
      DeleteBenefitOutcomeVariablesOrig
    >(DELETE_BENEFIT_OUTCOME_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeDeleteBenefitOutcomeVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      DeleteBenefitOutcomeOrig,
      DeleteBenefitOutcomeVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeDeleteBenefitOutcomeVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateDeleteBenefitOutcome'
    return {[mutateName]: deserializedMutate}
  })

export const addDeleteDocumentMutation: AddMutationType<
  DeleteDocumentOrig,
  DeleteDocument,
  DeleteDocumentVariablesOrig,
  DeleteDocumentVariables,
  'mutateDeleteDocument'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      DeleteDocumentOrig,
      DeleteDocumentVariablesOrig
    >(DELETE_DOCUMENT_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeDeleteDocumentVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      DeleteDocumentOrig,
      DeleteDocumentVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeDeleteDocumentVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateDeleteDocument'
    return {[mutateName]: deserializedMutate}
  })

export const addUpdateAccountStatusMutation: AddMutationType<
  UpdateAccountStatusOrig,
  UpdateAccountStatus,
  UpdateAccountStatusVariablesOrig,
  UpdateAccountStatusVariables,
  'mutateUpdateAccountStatus'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      UpdateAccountStatusOrig,
      UpdateAccountStatusVariablesOrig
    >(UPDATE_ACCOUNT_STATUS_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeUpdateAccountStatusVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      UpdateAccountStatusOrig,
      UpdateAccountStatusVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeUpdateAccountStatusVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateUpdateAccountStatus'
    return {[mutateName]: deserializedMutate}
  })

export const addUpdateReadyForAuditMutation: AddMutationType<
  UpdateReadyForAuditOrig,
  UpdateReadyForAudit,
  UpdateReadyForAuditVariablesOrig,
  UpdateReadyForAuditVariables,
  'mutateUpdateReadyForAudit'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      UpdateReadyForAuditOrig,
      UpdateReadyForAuditVariablesOrig
    >(UPDATE_READY_FOR_AUDIT_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeUpdateReadyForAuditVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      UpdateReadyForAuditOrig,
      UpdateReadyForAuditVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeUpdateReadyForAuditVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateUpdateReadyForAudit'
    return {[mutateName]: deserializedMutate}
  })

export const addCreateZipAndUploadMutation: AddMutationType<
  CreateZipAndUploadOrig,
  CreateZipAndUpload,
  CreateZipAndUploadVariablesOrig,
  CreateZipAndUploadVariables,
  'mutateCreateZipAndUpload'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      CreateZipAndUploadOrig,
      CreateZipAndUploadVariablesOrig
    >(CREATE_ZIP_AND_UPLOAD_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeCreateZipAndUploadVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      CreateZipAndUploadOrig,
      CreateZipAndUploadVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeCreateZipAndUploadVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateCreateZipAndUpload'
    return {[mutateName]: deserializedMutate}
  })

export const addCreateDocumentFileMutation: AddMutationType<
  CreateDocumentFileOrig,
  CreateDocumentFile,
  CreateDocumentFileVariablesOrig,
  CreateDocumentFileVariables,
  'mutateCreateDocumentFile'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      CreateDocumentFileOrig,
      CreateDocumentFileVariablesOrig
    >(CREATE_DOCUMENT_FILE_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeCreateDocumentFileVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      CreateDocumentFileOrig,
      CreateDocumentFileVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeCreateDocumentFileVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateCreateDocumentFile'
    return {[mutateName]: deserializedMutate}
  })

export const addDeleteDocumentFileMutation: AddMutationType<
  DeleteDocumentFileOrig,
  DeleteDocumentFile,
  DeleteDocumentFileVariablesOrig,
  DeleteDocumentFileVariables,
  'mutateDeleteDocumentFile'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      DeleteDocumentFileOrig,
      DeleteDocumentFileVariablesOrig
    >(DELETE_DOCUMENT_FILE_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeDeleteDocumentFileVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      DeleteDocumentFileOrig,
      DeleteDocumentFileVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeDeleteDocumentFileVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateDeleteDocumentFile'
    return {[mutateName]: deserializedMutate}
  })

export const addCreateTaskMutation: AddMutationType<
  CreateTaskOrig,
  CreateTask,
  CreateTaskVariablesOrig,
  CreateTaskVariables,
  'mutateCreateTask'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<CreateTaskOrig, CreateTaskVariablesOrig>(
      CREATE_TASK_MUTATION,
      {
        onCompleted: onCompleted?.(props),
        onError: onError?.(props),
        variables: variables
          ? serializeCreateTaskVariables(variables(props))
          : undefined,
        refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
        awaitRefetchQueries,
      }
    )

    type DeserializedMutateType = MutationFunction<
      CreateTaskOrig,
      CreateTaskVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeCreateTaskVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateCreateTask'
    return {[mutateName]: deserializedMutate}
  })

export const addDeleteTaskMutation: AddMutationType<
  DeleteTaskOrig,
  DeleteTask,
  DeleteTaskVariablesOrig,
  DeleteTaskVariables,
  'mutateDeleteTask'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<DeleteTaskOrig, DeleteTaskVariablesOrig>(
      DELETE_TASK_MUTATION,
      {
        onCompleted: onCompleted?.(props),
        onError: onError?.(props),
        variables: variables
          ? serializeDeleteTaskVariables(variables(props))
          : undefined,
        refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
        awaitRefetchQueries,
      }
    )

    type DeserializedMutateType = MutationFunction<
      DeleteTaskOrig,
      DeleteTaskVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeDeleteTaskVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateDeleteTask'
    return {[mutateName]: deserializedMutate}
  })

export const addUpdateEsignSessionMutation: AddMutationType<
  UpdateEsignSessionOrig,
  UpdateEsignSession,
  UpdateEsignSessionVariablesOrig,
  UpdateEsignSessionVariables,
  'mutateUpdateEsignSession'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      UpdateEsignSessionOrig,
      UpdateEsignSessionVariablesOrig
    >(UPDATE_ESIGN_SESSION_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeUpdateEsignSessionVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      UpdateEsignSessionOrig,
      UpdateEsignSessionVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeUpdateEsignSessionVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateUpdateEsignSession'
    return {[mutateName]: deserializedMutate}
  })

export const addSendWebformLinkMutation: AddMutationType<
  SendWebformLinkOrig,
  SendWebformLink,
  SendWebformLinkVariablesOrig,
  SendWebformLinkVariables,
  'mutateSendWebformLink'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      SendWebformLinkOrig,
      SendWebformLinkVariablesOrig
    >(SEND_WEBFORM_LINK_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeSendWebformLinkVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      SendWebformLinkOrig,
      SendWebformLinkVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeSendWebformLinkVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateSendWebformLink'
    return {[mutateName]: deserializedMutate}
  })

export const addMarkWebformMergeAttemptReviewedMutation: AddMutationType<
  MarkWebformMergeAttemptReviewedOrig,
  MarkWebformMergeAttemptReviewed,
  MarkWebformMergeAttemptReviewedVariablesOrig,
  MarkWebformMergeAttemptReviewedVariables,
  'mutateMarkWebformMergeAttemptReviewed'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      MarkWebformMergeAttemptReviewedOrig,
      MarkWebformMergeAttemptReviewedVariablesOrig
    >(MARK_WEBFORM_MERGE_ATTEMPT_REVIEWED_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeMarkWebformMergeAttemptReviewedVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      MarkWebformMergeAttemptReviewedOrig,
      MarkWebformMergeAttemptReviewedVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeMarkWebformMergeAttemptReviewedVariables(
          variables
        )
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateMarkWebformMergeAttemptReviewed'
    return {[mutateName]: deserializedMutate}
  })

export const addUpdateReceivedWebformMutation: AddMutationType<
  UpdateReceivedWebformOrig,
  UpdateReceivedWebform,
  UpdateReceivedWebformVariablesOrig,
  UpdateReceivedWebformVariables,
  'mutateUpdateReceivedWebform'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      UpdateReceivedWebformOrig,
      UpdateReceivedWebformVariablesOrig
    >(UPDATE_RECEIVED_WEBFORM_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeUpdateReceivedWebformVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      UpdateReceivedWebformOrig,
      UpdateReceivedWebformVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeUpdateReceivedWebformVariables(
          variables
        )
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateUpdateReceivedWebform'
    return {[mutateName]: deserializedMutate}
  })

export const addCreateAccountAndPersonFromWebformMutation: AddMutationType<
  CreateAccountAndPersonFromWebformOrig,
  CreateAccountAndPersonFromWebform,
  CreateAccountAndPersonFromWebformVariablesOrig,
  CreateAccountAndPersonFromWebformVariables,
  'mutateCreateAccountAndPersonFromWebform'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      CreateAccountAndPersonFromWebformOrig,
      CreateAccountAndPersonFromWebformVariablesOrig
    >(CREATE_ACCOUNT_AND_PERSON_FROM_WEBFORM_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeCreateAccountAndPersonFromWebformVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      CreateAccountAndPersonFromWebformOrig,
      CreateAccountAndPersonFromWebformVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeCreateAccountAndPersonFromWebformVariables(
          variables
        )
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateCreateAccountAndPersonFromWebform'
    return {[mutateName]: deserializedMutate}
  })

export const addStartInboundMutation: AddMutationType<
  StartInboundOrig,
  StartInbound,
  StartInboundVariablesOrig,
  StartInboundVariables,
  'mutateStartInbound'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<StartInboundOrig, StartInboundVariablesOrig>(
      START_INBOUND_MUTATION,
      {
        onCompleted: onCompleted?.(props),
        onError: onError?.(props),
        variables: variables
          ? serializeStartInboundVariables(variables(props))
          : undefined,
        refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
        awaitRefetchQueries,
      }
    )

    type DeserializedMutateType = MutationFunction<
      StartInboundOrig,
      StartInboundVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeStartInboundVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateStartInbound'
    return {[mutateName]: deserializedMutate}
  })

export const addUnstartInboundMutation: AddMutationType<
  UnstartInboundOrig,
  UnstartInbound,
  UnstartInboundVariablesOrig,
  UnstartInboundVariables,
  'mutateUnstartInbound'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      UnstartInboundOrig,
      UnstartInboundVariablesOrig
    >(UNSTART_INBOUND_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeUnstartInboundVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      UnstartInboundOrig,
      UnstartInboundVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeUnstartInboundVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateUnstartInbound'
    return {[mutateName]: deserializedMutate}
  })

export const addCompleteInboundMutation: AddMutationType<
  CompleteInboundOrig,
  CompleteInbound,
  CompleteInboundVariablesOrig,
  CompleteInboundVariables,
  'mutateCompleteInbound'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      CompleteInboundOrig,
      CompleteInboundVariablesOrig
    >(COMPLETE_INBOUND_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeCompleteInboundVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      CompleteInboundOrig,
      CompleteInboundVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeCompleteInboundVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateCompleteInbound'
    return {[mutateName]: deserializedMutate}
  })

export const addUncompleteInboundMutation: AddMutationType<
  UncompleteInboundOrig,
  UncompleteInbound,
  UncompleteInboundVariablesOrig,
  UncompleteInboundVariables,
  'mutateUncompleteInbound'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      UncompleteInboundOrig,
      UncompleteInboundVariablesOrig
    >(UNCOMPLETE_INBOUND_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeUncompleteInboundVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      UncompleteInboundOrig,
      UncompleteInboundVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeUncompleteInboundVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateUncompleteInbound'
    return {[mutateName]: deserializedMutate}
  })

export const addCreateEditableFileMutation: AddMutationType<
  CreateEditableFileOrig,
  CreateEditableFile,
  CreateEditableFileVariablesOrig,
  CreateEditableFileVariables,
  'mutateCreateEditableFile'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      CreateEditableFileOrig,
      CreateEditableFileVariablesOrig
    >(CREATE_EDITABLE_FILE_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeCreateEditableFileVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      CreateEditableFileOrig,
      CreateEditableFileVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeCreateEditableFileVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateCreateEditableFile'
    return {[mutateName]: deserializedMutate}
  })

export const addUpdateEditableFileMutation: AddMutationType<
  UpdateEditableFileOrig,
  UpdateEditableFile,
  UpdateEditableFileVariablesOrig,
  UpdateEditableFileVariables,
  'mutateUpdateEditableFile'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      UpdateEditableFileOrig,
      UpdateEditableFileVariablesOrig
    >(UPDATE_EDITABLE_FILE_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeUpdateEditableFileVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      UpdateEditableFileOrig,
      UpdateEditableFileVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeUpdateEditableFileVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateUpdateEditableFile'
    return {[mutateName]: deserializedMutate}
  })

export const addSendEditableFileToEmrMutation: AddMutationType<
  SendEditableFileToEmrOrig,
  SendEditableFileToEmr,
  SendEditableFileToEmrVariablesOrig,
  SendEditableFileToEmrVariables,
  'mutateSendEditableFileToEmr'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      SendEditableFileToEmrOrig,
      SendEditableFileToEmrVariablesOrig
    >(SEND_EDITABLE_FILE_TO_EMR_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeSendEditableFileToEmrVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      SendEditableFileToEmrOrig,
      SendEditableFileToEmrVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeSendEditableFileToEmrVariables(
          variables
        )
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateSendEditableFileToEmr'
    return {[mutateName]: deserializedMutate}
  })

export const addMarkEditableFileReviewedMutation: AddMutationType<
  MarkEditableFileReviewedOrig,
  MarkEditableFileReviewed,
  MarkEditableFileReviewedVariablesOrig,
  MarkEditableFileReviewedVariables,
  'mutateMarkEditableFileReviewed'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      MarkEditableFileReviewedOrig,
      MarkEditableFileReviewedVariablesOrig
    >(MARK_EDITABLE_FILE_REVIEWED_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeMarkEditableFileReviewedVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      MarkEditableFileReviewedOrig,
      MarkEditableFileReviewedVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeMarkEditableFileReviewedVariables(
          variables
        )
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateMarkEditableFileReviewed'
    return {[mutateName]: deserializedMutate}
  })

export const addMergeEditableFilesMutation: AddMutationType<
  MergeEditableFilesOrig,
  MergeEditableFiles,
  MergeEditableFilesVariablesOrig,
  MergeEditableFilesVariables,
  'mutateMergeEditableFiles'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      MergeEditableFilesOrig,
      MergeEditableFilesVariablesOrig
    >(MERGE_EDITABLE_FILES_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeMergeEditableFilesVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      MergeEditableFilesOrig,
      MergeEditableFilesVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeMergeEditableFilesVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateMergeEditableFiles'
    return {[mutateName]: deserializedMutate}
  })

export const addDeleteEditableFileMutation: AddMutationType<
  DeleteEditableFileOrig,
  DeleteEditableFile,
  DeleteEditableFileVariablesOrig,
  DeleteEditableFileVariables,
  'mutateDeleteEditableFile'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      DeleteEditableFileOrig,
      DeleteEditableFileVariablesOrig
    >(DELETE_EDITABLE_FILE_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeDeleteEditableFileVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      DeleteEditableFileOrig,
      DeleteEditableFileVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeDeleteEditableFileVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateDeleteEditableFile'
    return {[mutateName]: deserializedMutate}
  })

export const addDeleteEditableFileApplicationAssociationMutation: AddMutationType<
  DeleteEditableFileApplicationAssociationOrig,
  DeleteEditableFileApplicationAssociation,
  DeleteEditableFileApplicationAssociationVariablesOrig,
  DeleteEditableFileApplicationAssociationVariables,
  'mutateDeleteEditableFileApplicationAssociation'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      DeleteEditableFileApplicationAssociationOrig,
      DeleteEditableFileApplicationAssociationVariablesOrig
    >(DELETE_EDITABLE_FILE_APPLICATION_ASSOCIATION_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeDeleteEditableFileApplicationAssociationVariables(
            variables(props)
          )
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      DeleteEditableFileApplicationAssociationOrig,
      DeleteEditableFileApplicationAssociationVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeDeleteEditableFileApplicationAssociationVariables(
          variables
        )
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateDeleteEditableFileApplicationAssociation'
    return {[mutateName]: deserializedMutate}
  })

export const addMarkReceivedWebformFileTemplateClearedMutation: AddMutationType<
  MarkReceivedWebformFileTemplateClearedOrig,
  MarkReceivedWebformFileTemplateCleared,
  MarkReceivedWebformFileTemplateClearedVariablesOrig,
  MarkReceivedWebformFileTemplateClearedVariables,
  'mutateMarkReceivedWebformFileTemplateCleared'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      MarkReceivedWebformFileTemplateClearedOrig,
      MarkReceivedWebformFileTemplateClearedVariablesOrig
    >(MARK_RECEIVED_WEBFORM_FILE_TEMPLATE_CLEARED_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeMarkReceivedWebformFileTemplateClearedVariables(
            variables(props)
          )
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      MarkReceivedWebformFileTemplateClearedOrig,
      MarkReceivedWebformFileTemplateClearedVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeMarkReceivedWebformFileTemplateClearedVariables(
          variables
        )
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateMarkReceivedWebformFileTemplateCleared'
    return {[mutateName]: deserializedMutate}
  })

export const addRetryInboundNjmmisCheckMutation: AddMutationType<
  RetryInboundNjmmisCheckOrig,
  RetryInboundNjmmisCheck,
  RetryInboundNjmmisCheckVariablesOrig,
  RetryInboundNjmmisCheckVariables,
  'mutateRetryInboundNjmmisCheck'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      RetryInboundNjmmisCheckOrig,
      RetryInboundNjmmisCheckVariablesOrig
    >(RETRY_INBOUND_NJMMIS_CHECK_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeRetryInboundNjmmisCheckVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      RetryInboundNjmmisCheckOrig,
      RetryInboundNjmmisCheckVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeRetryInboundNjmmisCheckVariables(
          variables
        )
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateRetryInboundNjmmisCheck'
    return {[mutateName]: deserializedMutate}
  })

export const addCreateLighticoWorkflowsMutation: AddMutationType<
  CreateLighticoWorkflowsOrig,
  CreateLighticoWorkflows,
  CreateLighticoWorkflowsVariablesOrig,
  CreateLighticoWorkflowsVariables,
  'mutateCreateLighticoWorkflows'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      CreateLighticoWorkflowsOrig,
      CreateLighticoWorkflowsVariablesOrig
    >(CREATE_LIGHTICO_WORKFLOWS_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeCreateLighticoWorkflowsVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      CreateLighticoWorkflowsOrig,
      CreateLighticoWorkflowsVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeCreateLighticoWorkflowsVariables(
          variables
        )
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateCreateLighticoWorkflows'
    return {[mutateName]: deserializedMutate}
  })

export const addSendDocumentRequestsMutation: AddMutationType<
  SendDocumentRequestsOrig,
  SendDocumentRequests,
  SendDocumentRequestsVariablesOrig,
  SendDocumentRequestsVariables,
  'mutateSendDocumentRequests'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      SendDocumentRequestsOrig,
      SendDocumentRequestsVariablesOrig
    >(SEND_DOCUMENT_REQUESTS_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeSendDocumentRequestsVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      SendDocumentRequestsOrig,
      SendDocumentRequestsVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeSendDocumentRequestsVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateSendDocumentRequests'
    return {[mutateName]: deserializedMutate}
  })

export const addCreateSendDocumentRequestsMutation: AddMutationType<
  CreateSendDocumentRequestsOrig,
  CreateSendDocumentRequests,
  CreateSendDocumentRequestsVariablesOrig,
  CreateSendDocumentRequestsVariables,
  'mutateCreateSendDocumentRequests'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      CreateSendDocumentRequestsOrig,
      CreateSendDocumentRequestsVariablesOrig
    >(CREATE_SEND_DOCUMENT_REQUESTS_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeCreateSendDocumentRequestsVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      CreateSendDocumentRequestsOrig,
      CreateSendDocumentRequestsVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeCreateSendDocumentRequestsVariables(
          variables
        )
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateCreateSendDocumentRequests'
    return {[mutateName]: deserializedMutate}
  })

export const addCancelDocumentRequestsMutation: AddMutationType<
  CancelDocumentRequestsOrig,
  CancelDocumentRequests,
  CancelDocumentRequestsVariablesOrig,
  CancelDocumentRequestsVariables,
  'mutateCancelDocumentRequests'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      CancelDocumentRequestsOrig,
      CancelDocumentRequestsVariablesOrig
    >(CANCEL_DOCUMENT_REQUESTS_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeCancelDocumentRequestsVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      CancelDocumentRequestsOrig,
      CancelDocumentRequestsVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeCancelDocumentRequestsVariables(
          variables
        )
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateCancelDocumentRequests'
    return {[mutateName]: deserializedMutate}
  })

export const addUpdatePersonMessageReadMutation: AddMutationType<
  UpdatePersonMessageReadOrig,
  UpdatePersonMessageRead,
  UpdatePersonMessageReadVariablesOrig,
  UpdatePersonMessageReadVariables,
  'mutateUpdatePersonMessageRead'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      UpdatePersonMessageReadOrig,
      UpdatePersonMessageReadVariablesOrig
    >(UPDATE_PERSON_MESSAGE_READ_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeUpdatePersonMessageReadVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      UpdatePersonMessageReadOrig,
      UpdatePersonMessageReadVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeUpdatePersonMessageReadVariables(
          variables
        )
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateUpdatePersonMessageRead'
    return {[mutateName]: deserializedMutate}
  })

export const addCreateNjmmisCaptureResultMutation: AddMutationType<
  CreateNjmmisCaptureResultOrig,
  CreateNjmmisCaptureResult,
  CreateNjmmisCaptureResultVariablesOrig,
  CreateNjmmisCaptureResultVariables,
  'mutateCreateNjmmisCaptureResult'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      CreateNjmmisCaptureResultOrig,
      CreateNjmmisCaptureResultVariablesOrig
    >(CREATE_NJMMIS_CAPTURE_RESULT_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeCreateNjmmisCaptureResultVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      CreateNjmmisCaptureResultOrig,
      CreateNjmmisCaptureResultVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeCreateNjmmisCaptureResultVariables(
          variables
        )
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateCreateNjmmisCaptureResult'
    return {[mutateName]: deserializedMutate}
  })

export const addApplyStateMedicaidMutation: AddMutationType<
  ApplyStateMedicaidOrig,
  ApplyStateMedicaid,
  ApplyStateMedicaidVariablesOrig,
  ApplyStateMedicaidVariables,
  'mutateApplyStateMedicaid'
> = ({onCompleted, onError, variables, refetchQueries, awaitRefetchQueries}) =>
  addProps((props) => {
    const [mutate] = useMutation<
      ApplyStateMedicaidOrig,
      ApplyStateMedicaidVariablesOrig
    >(APPLY_STATE_MEDICAID_MUTATION, {
      onCompleted: onCompleted?.(props),
      onError: onError?.(props),
      variables: variables
        ? serializeApplyStateMedicaidVariables(variables(props))
        : undefined,
      refetchQueries: refetchQueries ? refetchQueries(props) : undefined,
      awaitRefetchQueries,
    })

    type DeserializedMutateType = MutationFunction<
      ApplyStateMedicaidOrig,
      ApplyStateMedicaidVariables
    >

    const deserializedMutate: DeserializedMutateType = (opts) => {
      const {variables, ...passthroughOpts} = opts ?? {}
      const mutateOpts: Parameters<typeof mutate>[0] = passthroughOpts
      if (variables) {
        mutateOpts.variables = serializeApplyStateMedicaidVariables(variables)
      }

      return mutate(mutateOpts)
    }

    const mutateName = 'mutateApplyStateMedicaid'
    return {[mutateName]: deserializedMutate}
  })
