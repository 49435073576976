import React, {FC} from 'react'
import {flowMax, addDisplayName} from 'ad-hok'

import addMaterialUI from 'utils/addMaterialUI'
import {addApolloProvider} from 'config/apolloClient'
import 'i18n-init'
import 'config/apolloClient'
import TopLevelRoutes from 'components/TopLevelRoutes'
import {addRouterProvider} from 'utils/addRouterProvider'
import addAppSnackbar from 'utils/addAppSnackbar'
import addCatchAllErrorBoundary from 'utils/addCatchAllErrorBoundary'
import {addAuth0Provider} from 'utils/auth0'

const App: FC = flowMax(
  addDisplayName('App'),
  addCatchAllErrorBoundary,
  addMaterialUI,
  addRouterProvider,
  addAuth0Provider,
  addApolloProvider,
  addAppSnackbar,
  () => <TopLevelRoutes />
)

export default App
