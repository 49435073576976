import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addMemoBoundary,
  addWrapper,
} from 'ad-hok'
import {generatePath} from 'react-router'

import {makeClasses, addClasses} from 'theme'
import {FormValues, ExtractFormSchemaFields} from 'utils/form/schema'
import {editPersonFormSchema} from 'components/EditPersonForm/schema'
import {addTranslationHelpers} from 'utils/i18n'
import {getExtendedName} from 'utils/name'
import Heading from 'components/Heading'
import Link from 'components/Link'
import {personDetailDefaultPath} from 'components/TopLevelRoutes'
import zIndices from 'utils/zIndices'
import Grid from 'components/Grid'
import Body1 from 'components/Body1'
import Trans from 'components/Trans'
import {personDetailPath} from 'components/PersonDetail/index'

const classes = makeClasses((theme) => ({
  container: {
    position: 'sticky',
    top: -6,
    zIndex: zIndices.stickyPersonHeader,
    backgroundColor: theme.palette.background.default,
  },
  openApplicationsLink: {
    fontSize: 18,
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  personValues?: Pick<
    FormValues<ExtractFormSchemaFields<typeof editPersonFormSchema>>['person'],
    'firstName' | 'lastName' | 'preferredName' | 'middleName' | 'suffix' | 'id'
  >
  name?: string
  id?: string
  numOpenApplications?: number
}

const PersonSectionHeader: FC<Props> = flowMax(
  addDisplayName('PersonSectionHeader'),
  addTranslationHelpers,
  addProps(
    ({personValues, id, name, t}) => ({
      name: name || getExtendedName({...personValues!, t}),
      id: id || personValues?.id,
    }),
    ['personValues', 'name', 'id', 't']
  ),
  addMemoBoundary(['name', 'id', 'numOpenApplications']),
  addClasses(classes),
  addWrapper((render, {id, numOpenApplications, classes}) => (
    <Grid
      container
      direction="row"
      alignItems="baseline"
      className={classes.container}
    >
      {render()}
      {!!id && !!numOpenApplications && (
        <Body1 className={classes.openApplicationsLink}>
          <Trans
            i18nKey="personForm.relationships.openApplicationsLink"
            count={numOpenApplications}
          >
            <Link
              to={`${generatePath(personDetailPath, {id})}/applications`}
              target="_self"
              highlight
            />
          </Trans>
        </Body1>
      )}
    </Grid>
  )),
  addWrapper((render, {id}) =>
    id ? (
      <Link
        to={generatePath(personDetailDefaultPath, {id})}
        target="_self"
        highlight
      >
        {render()}
      </Link>
    ) : (
      render()
    )
  ),
  ({name}) => <Heading variant="h6">{name}</Heading>
)

export default PersonSectionHeader
