import {mapValues, reduce, isEqual} from 'lodash'

import {
  FormSchemaValue,
  isField,
  isFormCollection,
  ExtractFormCollectionFields,
  extractFormCollectionFields,
  FormSchemaFields,
  FormSchema,
  FormCanonicalValues,
  FormValues,
  FormObjectCanonicalValues,
  FormObjectValues,
  FormSchemaObjectFields,
  FormSchemaCollection,
  FormInitialValues,
  FormObjectInitialValues,
} from 'utils/form/schema'
import typedAs from 'utils/typedAs'
import {invoke} from 'utils/fp'

type GetCanonicalValuesType = <TFields extends FormSchemaFields>(
  formData: FormValues<TFields>,
  schema: FormSchema<TFields>
) => FormCanonicalValues<TFields>

export const getCanonicalValues: GetCanonicalValuesType = (formData, schema) =>
  mapValues(schema.fields, (objectSchema, objectName) =>
    getObjectCanonicalValues(formData[objectName], objectSchema)
  ) as any

type GetCanonicalValuesMemoizedType = <TFields extends FormSchemaFields>(
  formData: FormValues<TFields>,
  schema: FormSchema<TFields>,
  formDataPrevious: FormValues<TFields>,
  canonicalValuesPrevious: FormCanonicalValues<TFields> | undefined
) => FormCanonicalValues<TFields>

export const getCanonicalValuesMemoized: GetCanonicalValuesMemoizedType = (
  formData,
  schema,
  formDataPrevious,
  canonicalValuesPrevious
) => {
  if (!canonicalValuesPrevious) return getCanonicalValues(formData, schema)
  if (formData === formDataPrevious) return canonicalValuesPrevious
  return mapValues(schema.fields, (objectSchema, objectName) =>
    getObjectCanonicalValuesMemoized(
      formData[objectName],
      objectSchema,
      formDataPrevious[objectName],
      canonicalValuesPrevious[objectName]
    )
  ) as any
}

type GetObjectCanonicalValuesType = <TFields extends FormSchemaObjectFields>(
  objectData: FormObjectValues<TFields>,
  fields: TFields
) => FormObjectCanonicalValues<TFields>

const getObjectCanonicalValues: GetObjectCanonicalValuesType = (
  objectData,
  objectSchema
) =>
  mapValues(objectSchema, (schema: FormSchemaValue, fieldName) => {
    const value = objectData[fieldName]
    if (isField(schema)) {
      return schema.mapToCanonical(value)
    }

    if (isFormCollection(schema)) {
      return getCollectionCanonicalValues(value as any[], schema)
    }

    return getObjectCanonicalValues(
      value as FormObjectValues<typeof schema>,
      schema
    )
  })

type GetObjectCanonicalValuesMemoizedType = <
  TFields extends FormSchemaObjectFields
>(
  objectData: FormObjectValues<TFields>,
  fields: TFields,
  objectDataPrevious: FormObjectValues<TFields>,
  objectCanonicalValuesPrevious: FormObjectCanonicalValues<TFields> | undefined
) => FormObjectCanonicalValues<TFields>

const getObjectCanonicalValuesMemoized: GetObjectCanonicalValuesMemoizedType = (
  objectData,
  objectSchema,
  objectDataPrevious,
  objectCanonicalValuesPrevious
) => {
  if (!objectCanonicalValuesPrevious)
    return getObjectCanonicalValues(objectData, objectSchema)
  if (objectData === objectDataPrevious) return objectCanonicalValuesPrevious
  return mapValues(objectSchema, (schema: FormSchemaValue, fieldName) => {
    const value = objectData[fieldName]
    if (isField(schema)) {
      return schema.mapToCanonical(value)
    }

    if (isFormCollection(schema)) {
      return getCollectionCanonicalValuesMemoized(
        value as any[],
        schema,
        objectDataPrevious[fieldName] as any[],
        objectCanonicalValuesPrevious[fieldName] as any[]
      )
    }

    return getObjectCanonicalValuesMemoized(
      value as FormObjectValues<typeof schema>,
      schema,
      objectDataPrevious[fieldName] as FormObjectValues<typeof schema>,
      objectCanonicalValuesPrevious[fieldName] as FormObjectCanonicalValues<
        typeof schema
      >
    )
  })
}

type GetCollectionCanonicalValues = <TFields extends FormSchemaObjectFields>(
  collectionData: Array<TFields>,
  collectionSchema: FormSchemaCollection<TFields>
) => Array<FormObjectCanonicalValues<TFields>>

export const getCollectionCanonicalValues: GetCollectionCanonicalValues = (
  collectionData,
  collectionSchema
) =>
  collectionData.map((object) =>
    getObjectCanonicalValues(
      object as FormObjectCanonicalValues<
        ExtractFormCollectionFields<typeof collectionSchema>
      >,
      extractFormCollectionFields(collectionSchema)
    )
  )

type GetCollectionCanonicalValuesMemoized = <
  TFields extends FormSchemaObjectFields
>(
  collectionData: Array<TFields>,
  collectionSchema: FormSchemaCollection<TFields>,
  collectionDataPrevious: Array<TFields>,
  collectionCanonicalValuesPrevious:
    | Array<FormObjectCanonicalValues<TFields>>
    | undefined
) => Array<FormObjectCanonicalValues<TFields>>

export const getCollectionCanonicalValuesMemoized: GetCollectionCanonicalValuesMemoized = (
  collectionData,
  collectionSchema,
  collectionDataPrevious,
  collectionCanonicalValuesPrevious
) => {
  if (!collectionCanonicalValuesPrevious)
    return getCollectionCanonicalValues(collectionData, collectionSchema)
  if (collectionData === collectionDataPrevious)
    return collectionCanonicalValuesPrevious
  return collectionData.map((object, index) =>
    getObjectCanonicalValuesMemoized(
      object as FormObjectCanonicalValues<
        ExtractFormCollectionFields<typeof collectionSchema>
      >,
      extractFormCollectionFields(collectionSchema),
      collectionDataPrevious[index] as FormObjectCanonicalValues<
        ExtractFormCollectionFields<typeof collectionSchema>
      >,
      collectionCanonicalValuesPrevious[index]
    )
  )
}

type GetInitialValuesType = <TFields extends FormSchemaFields>(
  schema: FormSchema<TFields>,
  initialValues?: FormInitialValues<TFields>
) => FormValues<TFields>

export const getInitialValues: GetInitialValuesType = (schema, initialValues) =>
  mapValues(schema.fields, (fields, objectName) =>
    getObjectInitialValues(fields, initialValues && initialValues[objectName])
  ) as any

type GetObjectInitialValuesType = <TFields extends FormSchemaObjectFields>(
  fields: TFields,
  initialValues?: FormObjectInitialValues<TFields>
) => FormObjectValues<TFields>

export const getObjectInitialValues: GetObjectInitialValuesType = (
  fields,
  initialValues
) =>
  mapValues(fields, (fieldOrCollection, name) => {
    if (isField(fieldOrCollection)) {
      return initialValues && name in initialValues
        ? fieldOrCollection.mapFromCanonical(initialValues[name])
        : (fieldOrCollection.initialValue as any)
    }

    if (isFormCollection(fieldOrCollection)) {
      return getCollectionInitialValues(
        fieldOrCollection,
        initialValues && (initialValues[name] as any[])
      )
    }

    return getObjectInitialValues(
      fieldOrCollection as FormSchemaObjectFields,
      initialValues && (initialValues[name] as any)
    )
  }) as any

type GetCollectionInitialValuesType = (
  collectionSchema: FormSchemaCollection<any>,
  initialValues?: any[]
) => any

const getCollectionInitialValues: GetCollectionInitialValuesType = (
  collectionSchema,
  initialValues
) => {
  if (!initialValues) return []

  return initialValues.map((item: any) =>
    getObjectInitialValues(extractFormCollectionFields(collectionSchema), item)
  )
}

type FormValueChangeType = 'added' | 'changed' | 'unchanged'

export interface FormValueChange {
  path: string
  type: FormValueChangeType
}

type GetFormValueChangesType = <TFields extends FormSchemaFields>(
  schema: FormSchema<TFields>,
  previousValues: FormValues<TFields>,
  currentValues: FormValues<TFields>
) => FormValueChange[]

export const getFormValueChanges: GetFormValueChangesType = (
  schema,
  previousValues,
  currentValues
) =>
  reduce(
    schema.fields,
    (changes, objectSchema, objectName) => [
      ...changes,
      ...getObjectFormValueChanges(
        objectSchema,
        previousValues[objectName],
        currentValues[objectName],
        objectName
      ),
    ],
    typedAs<FormValueChange[]>([])
  )

type GetObjectFormValueChangesType = <TFields extends FormSchemaObjectFields>(
  fields: TFields,
  previousValues: FormObjectValues<TFields> | undefined,
  currentValues: FormObjectValues<TFields> | undefined,
  currentPath: string,
  knownChangeType?: FormValueChangeType
) => FormValueChange[]

const getObjectFormValueChanges: GetObjectFormValueChangesType = (
  fields,
  previousValues,
  currentValues,
  currentPath,
  knownChangeType
) =>
  reduce(
    fields,
    (objectChanges, fieldOrCollection, name) => [
      ...objectChanges,
      ...invoke((): FormValueChange[] => {
        const fieldPath = `${currentPath}.${name}`

        if (isField(fieldOrCollection)) {
          const type: FormValueChangeType =
            knownChangeType ??
            (previousValues?.[name] === undefined &&
              currentValues?.[name] !== undefined)
              ? 'added'
              : !isEqual(previousValues?.[name], currentValues?.[name])
              ? 'changed'
              : 'unchanged'

          const change = {
            path: fieldPath,
            type,
          }

          return [change]
        }

        if (isFormCollection(fieldOrCollection)) {
          return getCollectionFormValueChanges(
            fieldOrCollection,
            previousValues?.[name] as any[] | undefined,
            currentValues?.[name] as any[] | undefined,
            fieldPath,
            knownChangeType
          )
        }

        return getObjectFormValueChanges(
          fieldOrCollection as FormSchemaObjectFields,
          previousValues?.[name] as FormObjectValues<any> | undefined,
          currentValues?.[name] as FormObjectValues<any> | undefined,
          fieldPath,
          knownChangeType
        )
      }),
    ],
    typedAs<FormValueChange[]>([])
  )

type GetCollectionFormValueChangesType = <
  TFields extends FormSchemaObjectFields
>(
  collectionSchema: FormSchemaCollection<TFields>,
  previousValues: Array<FormObjectValues<TFields>> | undefined,
  currentValues: Array<FormObjectValues<TFields>> | undefined,
  currentPath: string,
  knownChangeType?: FormValueChangeType
) => FormValueChange[]

const getCollectionFormValueChanges: GetCollectionFormValueChangesType = (
  collectionSchema,
  previousValues,
  currentValues,
  currentPath
) => {
  if (!currentValues) return []
  const isDeleting =
    previousValues && previousValues.length > currentValues.length
  return currentValues.reduce(
    (collectionPaths, _item, index) => [
      ...collectionPaths,
      ...getObjectFormValueChanges(
        extractFormCollectionFields(collectionSchema),
        previousValues?.[index],
        currentValues?.[index],
        `${currentPath}[${index}]`,
        isDeleting ? 'unchanged' : undefined
      ),
    ],
    typedAs<FormValueChange[]>([])
  )
}
