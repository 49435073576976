/* eslint-disable */
import {RemoteDocumentRequestsVariables as RemoteDocumentRequestsVariablesOrig} from '../types/RemoteDocumentRequests'
import {RemoteDocumentRequests as RemoteDocumentRequestsOrig} from '../types/RemoteDocumentRequests'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {RemoteDocumentRequests_remoteDocumentRequests as RemoteDocumentRequests_remoteDocumentRequestsOrig} from '../types/RemoteDocumentRequests'
import {RemoteDocumentRequests_remoteDocumentRequests_requestInitiatedBy as RemoteDocumentRequests_remoteDocumentRequests_requestInitiatedByOrig} from '../types/RemoteDocumentRequests'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: RemoteDocumentRequests
// ====================================================
export interface RemoteDocumentRequests_remoteDocumentRequests_requestInitiatedBy {
  __typename: 'User'
  id: string
  name: string | null
}
export const deserializeRemoteDocumentRequests_remoteDocumentRequests_requestInitiatedBy = (
  serialized: RemoteDocumentRequests_remoteDocumentRequests_requestInitiatedByOrig
): RemoteDocumentRequests_remoteDocumentRequests_requestInitiatedBy => ({
  ...serialized,
})
export const serializeRemoteDocumentRequests_remoteDocumentRequests_requestInitiatedBy = (
  deserialized: RemoteDocumentRequests_remoteDocumentRequests_requestInitiatedBy
): RemoteDocumentRequests_remoteDocumentRequests_requestInitiatedByOrig => ({
  ...deserialized,
})
export interface RemoteDocumentRequests_remoteDocumentRequests {
  __typename: 'RemoteDocumentRequest'
  id: string
  phoneNumber: string
  requestReceivedAt: Date | null
  requestCancelled: boolean | null
  sessionId: string
  requestInitiatedBy: RemoteDocumentRequests_remoteDocumentRequests_requestInitiatedBy
  personId: number
  applicationId: number
  createdAt: Date
  updatedAt: Date
  documentType: string | null
  requestorType: string | null
  editableFileId: number | null
  documentName: string | null
}
export const deserializeRemoteDocumentRequests_remoteDocumentRequests = (
  serialized: RemoteDocumentRequests_remoteDocumentRequestsOrig
): RemoteDocumentRequests_remoteDocumentRequests => ({
  ...serialized,
  requestReceivedAt:
    serialized.requestReceivedAt != null
      ? parseDate(serialized.requestReceivedAt)
      : serialized.requestReceivedAt,
  requestInitiatedBy: deserializeRemoteDocumentRequests_remoteDocumentRequests_requestInitiatedBy(
    serialized.requestInitiatedBy
  ),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
})
export const serializeRemoteDocumentRequests_remoteDocumentRequests = (
  deserialized: RemoteDocumentRequests_remoteDocumentRequests
): RemoteDocumentRequests_remoteDocumentRequestsOrig => ({
  ...deserialized,
  requestReceivedAt:
    deserialized.requestReceivedAt != null
      ? formatISO(deserialized.requestReceivedAt)
      : deserialized.requestReceivedAt,
  requestInitiatedBy: serializeRemoteDocumentRequests_remoteDocumentRequests_requestInitiatedBy(
    deserialized.requestInitiatedBy
  ),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
})
export interface RemoteDocumentRequests {
  /**
   * List of document requested for this patient
   */
  remoteDocumentRequests: RemoteDocumentRequests_remoteDocumentRequests[]
}
export const deserializeRemoteDocumentRequests = (
  serialized: RemoteDocumentRequestsOrig
): RemoteDocumentRequests => ({
  ...serialized,
  remoteDocumentRequests: serialized.remoteDocumentRequests.map(
    deserializeRemoteDocumentRequests_remoteDocumentRequests
  ),
})
export const serializeRemoteDocumentRequests = (
  deserialized: RemoteDocumentRequests
): RemoteDocumentRequestsOrig => ({
  ...deserialized,
  remoteDocumentRequests: deserialized.remoteDocumentRequests.map(
    serializeRemoteDocumentRequests_remoteDocumentRequests
  ),
})
export interface RemoteDocumentRequestsVariables {
  personId: string
}
export const deserializeRemoteDocumentRequestsVariables = (
  serialized: RemoteDocumentRequestsVariablesOrig
): RemoteDocumentRequestsVariables => ({...serialized})
export const serializeRemoteDocumentRequestsVariables = (
  deserialized: RemoteDocumentRequestsVariables
): RemoteDocumentRequestsVariablesOrig => ({...deserialized})
