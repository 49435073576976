import React, {FC} from 'react'
import {flowMax, addDisplayName, addHandlers, addProps, addState} from 'ad-hok'
import {PureQueryOptions} from 'apollo-boost'

import {addTranslationHelpers} from 'utils/i18n'
import {addUpdateApplicationStatusMutation} from 'graphql/generated'
import StatusUpdateSelect, {
  StatusUpdateMutateUpdates,
  StatusOption,
} from 'components/StatusUpdateSelect'
import AlertDialog from 'components/AlertDialog'
import {BenefitType} from 'utils/benefits'
import {
  getStatusesForBenefit,
  APPLICATION_STATUS_CLOSED,
} from 'utils/applicationStatuses'
import addDialogState from 'utils/addDialogState'

const getStatusOptions = (benefit: BenefitType): StatusOption[] =>
  getStatusesForBenefit(benefit).map(({status, closedOrVoid}) => ({
    value: status,
    label: status.toUpperCase(),
    confirm: !!closedOrVoid,
  }))

type ApplicationType = {
  id: string
  benefit: BenefitType
  facility?: string
  status: string
  isUnclosableDueToMissingBenefitOutcomes: boolean
  person: {
    openApplications: {
      id: string
      benefit: string
      facility?: string | null
    }[]
    openHouseholdMemberApplications: {
      id: string
      benefit: string
    }[]
  }
}

interface Props {
  application: ApplicationType
  refetchQueries?: (benefit: BenefitType, status: string) => PureQueryOptions[]
  className?: string
}

const ApplicationStatusUpdateSelect: FC<Props> = flowMax(
  addDisplayName('ApplicationStatusUpdateSelect'),
  addTranslationHelpers,
  addUpdateApplicationStatusMutation({}),
  addDialogState,
  addState(
    'isAlertDueToMissingBenefitOutcomes',
    'setIsAlertDueToMissingBenefitOutcomes',
    (): boolean | null => null
  ),
  addHandlers({
    mutate: ({
      mutateUpdateApplicationStatus,
      application: {id, benefit},
      refetchQueries,
    }) => (updates: StatusUpdateMutateUpdates): Promise<any> =>
      mutateUpdateApplicationStatus({
        variables: {
          application: {
            id,
            ...updates,
          },
        },
        refetchQueries: refetchQueries?.(benefit, updates.status),
      }),
    rejectSelect: ({
      application: {isUnclosableDueToMissingBenefitOutcomes},
      showDialog,
      setIsAlertDueToMissingBenefitOutcomes,
    }) => ({status}: {status: string}) => {
      if (
        status === APPLICATION_STATUS_CLOSED &&
        isUnclosableDueToMissingBenefitOutcomes
      ) {
        setIsAlertDueToMissingBenefitOutcomes(true)
        showDialog()
        return true
      }

      return false
    },
  }),
  addProps(
    ({isAlertDueToMissingBenefitOutcomes, t}) => ({
      alertTitle: isAlertDueToMissingBenefitOutcomes
        ? t('applicationItem.closeWithoutBenefitOutcomesDialog.title')
        : t('applicationItem.uncloseDuplicateDialog.title'),
      alertMessage: isAlertDueToMissingBenefitOutcomes
        ? t('applicationItem.closeWithoutBenefitOutcomesDialog.message')
        : t('applicationItem.uncloseDuplicateDialog.message'),
    }),
    ['isAlertDueToMissingBenefitOutcomes', 't']
  ),
  ({
    application: {id, status, benefit},
    t,
    mutate,
    isShowingDialog,
    hideDialog,
    rejectSelect,
    alertTitle,
    alertMessage,
    className,
  }) => (
    <>
      <StatusUpdateSelect
        className={className}
        id={`application-${id}-status-select`}
        updateFailedMessage={t('applicationItem.statusUpdateFailed')}
        status={status}
        statusOptions={getStatusOptions(benefit)}
        rejectSelect={rejectSelect}
        mutate={mutate}
        confirmationDialogTitleTranslationKey={(status) =>
          status === APPLICATION_STATUS_CLOSED
            ? 'applicationStatusSelect.confirmDialog.titleClosing'
            : 'applicationStatusSelect.confirmDialog.title'
        }
      />
      <AlertDialog
        title={alertTitle}
        message={alertMessage}
        dismissText={t(
          'applicationItem.closeWithoutBenefitOutcomesDialog.dismiss'
        )}
        open={isShowingDialog}
        onDismiss={hideDialog}
      />
    </>
  )
)

export default ApplicationStatusUpdateSelect
