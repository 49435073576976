export const ACCOUNT_STATUSES = [
  'Unknown',
  'Verified',
  'Invoiced',
  'Disputing',
  'Recalled',
  'Transfer to 3rd party',
  'Other',
  'Void',
]

export const DEFAULT_ACCOUNT_STATUS = ACCOUNT_STATUSES[0]
