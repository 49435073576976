import React from 'react'
import {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addStateHandlers,
  addHandlers,
  addWrapper,
  AddWrapperRenderCallback,
  addMemoBoundary,
  SimplePropsAdder,
} from 'ad-hok'
import AddIcon from '@material-ui/icons/Add'
import {ArrayHelpers} from 'formik'
import SendIcon from '@material-ui/icons/Send'
import {
  branchIfEmpty,
  branchIfNullish,
  getContextHelpersFromInitialValues,
} from 'ad-hok-utils'

import {addTranslationHelpers} from 'utils/i18n'
import {makeClasses, addClasses} from 'theme'
import FieldArray, {FieldArrayItem} from 'components/FieldArray'
import Button from 'components/Button'
import Grid from 'components/Grid'
import Body1 from 'components/Body1'
import {getFormattedPhoneNumber} from 'utils/phone'
import PhoneNumberDialog from 'components/PhoneNumberDialog'
import {addFormik} from 'utils/form/formik'
import {addFormContext} from 'utils/form/context'
import NestedFormListItem from 'components/NestedFormListItem'
import ConfirmationDialog from './ConfirmationDialog'
import {addFormPrefixContext} from './FormPrefix'
import FormFeedbackIcon from 'components/FormFeedbackIcon'
import {OnSubmitSuccessOptions} from 'components/Form'
import {ExtractFormSchemaFields} from 'utils/form/schema'
import {phoneNumberFormSchema} from 'components/PhoneNumberDialog/index'
import addDialogState from 'utils/addDialogState'
import {PersonFields_phoneNumbers} from 'graphql/deserializedTypes/PersonFields'
import EsignSessionIcon from 'components/EsignSessionIcon'
import {addPhoneNumberOrIncomeSourceEsignSessionUrlsContext} from 'components/EditPersonForm/phoneNumberEsignSessionUrlsContext'
import IconButton from 'components/IconButton'
import SendWebformDialog from 'components/SendWebformDialog'
import {addWebformsContext} from 'components/EditPersonForm/webformsContext'
import Tooltip from 'components/Tooltip'
import {Person_person} from 'graphql/deserializedTypes/Person'

const classes = makeClasses((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  feedbackIconAdd: {
    position: 'relative',
    top: -5,
  },
  sendWebformButtonContainer: {
    marginLeft: 'auto',
  },
}))

interface FormatPhoneDetailsOptions {
  language?: string | null
}

const formatPhoneDetails = ({language}: FormatPhoneDetailsOptions) =>
  language ?? ''

type PhoneNumbersType = PersonFields_phoneNumbers

interface SendWebformIconProps {
  onClick: () => void
  phoneNumber: {
    id: string | undefined
  }
}

const SendWebformIcon: FC<SendWebformIconProps> = flowMax(
  addDisplayName('SendWebformIcon'),
  addWebformsContext,
  branchIfEmpty('webforms'),
  addClasses(classes),
  addTranslationHelpers,
  addProps(({phoneNumber: {id}}) => ({
    id,
  })),
  branchIfNullish('id', {
    returns: ({t, classes}) => (
      <Tooltip
        title={t('sendWebformIcon.disabled')}
        className={classes.sendWebformButtonContainer}
      >
        <span>
          <IconButton onClick={() => {}} disabled>
            <SendIcon />
          </IconButton>
        </span>
      </Tooltip>
    ),
  }),
  ({onClick, classes}) => (
    <IconButton
      onClick={onClick}
      className={classes.sendWebformButtonContainer}
    >
      <SendIcon />
    </IconButton>
  )
)

const [
  addShouldShowApplicationPromptContextProvider,
  addShouldShowApplicationPromptContext,
] = getContextHelpersFromInitialValues({
  shouldShowApplicationPrompt: false,
})

type AddShouldShowApplicationPromptContextProviderFromRightColumnContext = <
  TProps extends {
    person: Person_person
  }
>(
  props: TProps
) => TProps

export const addShouldShowApplicationPromptContextProviderFromRightColumnContext: AddShouldShowApplicationPromptContextProviderFromRightColumnContext = flowMax(
  addProps(
    ({person: {openApplications}}) => ({
      shouldShowApplicationPrompt: !openApplications.length,
    }),
    ['person']
  ),
  addShouldShowApplicationPromptContextProvider
)

type AddShouldShowApplicationPrompt = SimplePropsAdder<{
  shouldShowApplicationPrompt: boolean
}>

export const addShouldShowApplicationPrompt: AddShouldShowApplicationPrompt = flowMax(
  addShouldShowApplicationPromptContext
)

interface PhoneNumberItemProps {
  phoneNumber: PhoneNumbersType
  index: number
  arrayHelpers: ArrayHelpers
  formPrefix: string
}

const PhoneNumberItem: FC<PhoneNumberItemProps> = flowMax(
  addDisplayName('PhoneNumberItem'),
  addFormik,
  addMemoBoundary(['phoneNumber', 'index', 'formPrefix']),
  addStateHandlers(
    {
      isShowingEditDialog: false,
      isShowingDeleteDialog: false,
      isShowingSendWebformDialog: false,
    },
    {
      showEditDialog: () => () => ({isShowingEditDialog: true}),
      hideEditDialog: () => () => ({isShowingEditDialog: false}),
      showDeleteDialog: () => () => ({isShowingDeleteDialog: true}),
      hideDeleteDialog: () => () => ({isShowingDeleteDialog: false}),
      showSendWebformDialog: () => () => ({isShowingSendWebformDialog: true}),
      hideSendWebformDialog: () => () => ({isShowingSendWebformDialog: false}),
    }
  ),
  addProps(
    ({formPrefix, index}) => ({
      itemName: `${formPrefix}phoneNumbers[${index}]`,
    }),
    ['formPrefix', 'index']
  ),
  addFormContext,
  addPhoneNumberOrIncomeSourceEsignSessionUrlsContext,
  addProps(
    ({phoneNumberOrIncomeSourceEsignSessionUrls, phoneNumber: {id}}) => ({
      esignSessionUrl: id
        ? phoneNumberOrIncomeSourceEsignSessionUrls[`phoneNumber-${id}`]
        : null,
    })
  ),
  addHandlers({
    onDialogSave: ({
      hideEditDialog,
      formik: {setFieldValue},
      itemName,
      setCollectionItemFieldsFreshness,
    }) => ({
      formValues: {phoneNumber},
      fieldsFreshness,
    }: OnSubmitSuccessOptions<
      ExtractFormSchemaFields<typeof phoneNumberFormSchema>
    >) => {
      setCollectionItemFieldsFreshness(itemName, fieldsFreshness.phoneNumber)
      setFieldValue(itemName, phoneNumber)
      hideEditDialog()
    },
    deleteNumber: ({
      index,
      arrayHelpers,
      formPrefix,
      removeCollectionItemFieldsFreshness,
      removeCollectionItemFieldsUpdatedAt,
      hideDeleteDialog,
    }) => () => {
      removeCollectionItemFieldsFreshness(`${formPrefix}phoneNumbers`, index)
      removeCollectionItemFieldsUpdatedAt(`${formPrefix}phoneNumbers`, index)
      arrayHelpers.remove(index)
      hideDeleteDialog()
    },
  }),
  addShouldShowApplicationPrompt,
  addTranslationHelpers,
  ({
    t,
    phoneNumber,
    showEditDialog,
    hideEditDialog,
    isShowingEditDialog,
    onDialogSave,
    showDeleteDialog,
    hideDeleteDialog,
    isShowingDeleteDialog,
    deleteNumber,
    itemName,
    esignSessionUrl,
    showSendWebformDialog,
    isShowingSendWebformDialog,
    hideSendWebformDialog,
    shouldShowApplicationPrompt,
  }) => (
    <>
      <NestedFormListItem
        title={getFormattedPhoneNumber(phoneNumber.number)}
        subtitle={formatPhoneDetails(phoneNumber)}
        starred={phoneNumber.usedInApp}
        info={phoneNumber.comment}
        onEditClick={showEditDialog}
        onDeleteClick={showDeleteDialog}
        itemName={itemName}
        renderAdditionalIcons={() => (
          <>
            <EsignSessionIcon
              phoneNumberOrIncomeSource={{
                ...phoneNumber,
                esignSessionUrl,
              }}
              shouldShowApplicationPrompt={shouldShowApplicationPrompt}
            />
            <SendWebformIcon
              onClick={showSendWebformDialog}
              phoneNumber={phoneNumber}
            />
          </>
        )}
      />
      <PhoneNumberDialog
        phoneNumber={phoneNumber}
        itemName={itemName}
        open={isShowingEditDialog}
        onClose={hideEditDialog}
        onSave={onDialogSave}
      />
      <ConfirmationDialog
        title={t('deletePhoneNumberDialog.title', {
          number: getFormattedPhoneNumber(phoneNumber.number),
        })}
        cancelText={t('deletePhoneNumberDialog.cancel')}
        confirmText={t('deletePhoneNumberDialog.confirm')}
        open={isShowingDeleteDialog}
        onCancel={hideDeleteDialog}
        onConfirm={deleteNumber}
      />
      <SendWebformDialog
        open={isShowingSendWebformDialog}
        onCancel={hideSendWebformDialog}
        phoneNumber={phoneNumber}
      />
    </>
  )
)

interface Props {
  name: string
}

const PhoneFieldArray: FC<Props> = flowMax(
  addDisplayName('PhoneFieldArray'),
  addFormPrefixContext,
  addWrapper(
    (
      render: AddWrapperRenderCallback<{
        items: FieldArrayItem<PhoneNumbersType>[]
        arrayHelpers: ArrayHelpers
        formPrefix: string
      }>,
      props
    ) => (
      <FieldArray<PhoneNumbersType>
        name={props.name}
        render={(arrayHelpers, items) =>
          render({arrayHelpers, items, formPrefix: props.formPrefix})
        }
      />
    )
  ),
  addFormContext,
  addMemoBoundary(['name', 'items', 'formPrefix', 'formName']),
  addDialogState,
  addHandlers({
    onDialogSave: ({
      formPrefix,
      items,
      arrayHelpers,
      hideDialog,
      setCollectionItemFieldsFreshness,
    }) => ({
      formValues: {phoneNumber},
      fieldsFreshness,
    }: OnSubmitSuccessOptions<
      ExtractFormSchemaFields<typeof phoneNumberFormSchema>
    >) => {
      const itemName = `${formPrefix}phoneNumbers[${items.length}]`
      setCollectionItemFieldsFreshness(itemName, fieldsFreshness.phoneNumber)
      arrayHelpers.push(phoneNumber)
      hideDialog()
    },
  }),
  addTranslationHelpers,
  addProps(({formName, name, t}) => ({
    addLabel: t(`${formName}.collectionControls.${name}.add`),
    noItemsLabel: t(`${formName}.collectionControls.${name}.noItems`),
  })),
  addClasses(classes),
  ({
    classes,
    items,
    arrayHelpers,
    addLabel,
    noItemsLabel,
    showDialog,
    hideDialog,
    isShowingDialog,
    onDialogSave,
    formPrefix,
  }) => (
    <Grid className={classes.container}>
      {items.length === 0 && <Body1>{noItemsLabel}</Body1>}
      {items.map(({key, record}, index) => (
        <PhoneNumberItem
          formPrefix={formPrefix}
          phoneNumber={record}
          index={index}
          arrayHelpers={arrayHelpers}
          key={key}
        />
      ))}
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <Button startIcon={<AddIcon />} color="primary" onClick={showDialog}>
          {addLabel}
        </Button>
        <FormFeedbackIcon
          name={`${formPrefix}phoneNumbers`}
          className={classes.feedbackIconAdd}
          shouldRegisterToFormSection
        />
      </Grid>
      <PhoneNumberDialog
        open={isShowingDialog}
        onClose={hideDialog}
        onSave={onDialogSave}
      />
    </Grid>
  )
)

export default PhoneFieldArray
