import React, {ReactNode, ReactElement} from 'react'
import {FieldArray as BaseFieldArray, ArrayHelpers} from 'formik'
import {flowMax, addDisplayName, addProps, addMemoBoundary} from 'ad-hok'
import {get} from 'lodash'
import {branchIfNullish} from 'ad-hok-utils'

import {addFormContext, addFormCanonicalValuesContext} from 'utils/form/context'
import FormPrefix, {addFormPrefixContext} from 'components/FormPrefix'

export interface FieldArrayItem<TCanonicalData> {
  name: string
  key: string
  record: TCanonicalData
}

interface Props<TCanonicalData> {
  name: string
  render: (
    arrayHelpers: ArrayHelpers,
    items: Array<FieldArrayItem<TCanonicalData>>
  ) => ReactNode
}

type FieldArrayType = <TCanonicalData>(
  props: Props<TCanonicalData>
) => ReactElement<any, any> | null

const FieldArray: FieldArrayType = flowMax(
  addDisplayName('FieldArray'),
  addFormContext,
  addFormCanonicalValuesContext,
  addFormPrefixContext,
  addProps(({name, formPrefix}) => ({
    name: `${formPrefix}${name}`,
  })),
  addProps(
    ({formCanonicalValues, name}) => ({
      canonicalItems: get(formCanonicalValues, name) as any[],
    }),
    ['formCanonicalValues', 'name']
  ),
  addMemoBoundary(['name', 'canonicalItems']),
  branchIfNullish('canonicalItems'),
  addProps(
    ({name, canonicalItems}) => ({
      items: canonicalItems.map((canonicalItem, index) => ({
        name: `${name}[${index}]`,
        key: canonicalItem.id ?? `temp-${index}`,
        record: canonicalItem,
      })),
    }),
    ['name', 'canonicalItems']
  ),
  ({name, render, items}) => (
    <FormPrefix prefix="">
      <BaseFieldArray
        name={name}
        render={(arrayHelpers) => render(arrayHelpers, items)}
      />
    </FormPrefix>
  )
)

export default FieldArray
