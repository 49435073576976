import React, {FC, ReactElement, ComponentProps} from 'react'
import BaseTooltip from '@material-ui/core/Tooltip'
import {flowMax, addDisplayName} from 'ad-hok'
import {branchIfNullish} from 'ad-hok-utils'

import {makeClasses, addClasses} from 'theme'

const classes = makeClasses((theme) => ({
  title: {
    fontSize: 14,
  },
}))

interface Props {
  title: ComponentProps<typeof BaseTooltip>['title'] | undefined | null
  children: ReactElement<any, any>
  className?: string
}

const Tooltip: FC<Props> = flowMax(
  addDisplayName('Tooltip'),
  branchIfNullish('title'),
  addClasses(classes),
  ({title, children, classes, className}) => (
    <BaseTooltip
      title={title}
      classes={{
        tooltip: classes.title,
      }}
      className={className}
    >
      {children}
    </BaseTooltip>
  )
)

export default Tooltip
