import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addWrapper,
  addMemoBoundary,
  branch,
  renderNothing,
} from 'ad-hok'

import {addClasses, makeClasses} from 'theme'
import Grid from 'components/Grid'
import FormFeedbackIcon, {
  getFormFeedbackIconStatus,
} from 'components/FormFeedbackIcon'
import {addFormFieldStatusesContext} from 'utils/form/context'

const classes = makeClasses((/*theme*/) => ({
  fieldContainer: {
    flex: 1,
    position: 'relative',
  },
  iconContainer: {
    height: 56,
    width: 48,
  },
}))

interface IconContainerProps {
  name?: string
  prefix?: string
  lazy?: boolean
  shouldRegisterToFormSection?: boolean
}

const IconContainer: FC<IconContainerProps> = flowMax(
  addDisplayName('IconContainer'),
  addFormFieldStatusesContext,
  addProps(
    ({formFieldStatuses, name, prefix}) => ({
      status: getFormFeedbackIconStatus({formFieldStatuses, name, prefix}),
    }),
    ['formFieldStatuses', 'name', 'prefix']
  ),
  addMemoBoundary([
    'lazy',
    'prefix',
    'shouldRegisterToFormSection',
    'status',
    'name',
  ]),
  branch(({lazy, status}) => !!lazy && !status, renderNothing()),
  addClasses(classes),
  ({name, prefix, shouldRegisterToFormSection, classes}) => (
    <Grid item>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="flex-end"
        className={classes.iconContainer}
      >
        <Grid item>
          <FormFeedbackIcon
            name={name}
            prefix={prefix}
            shouldRegisterToFormSection={shouldRegisterToFormSection}
          />
        </Grid>
      </Grid>
    </Grid>
  )
)

interface Props {
  name?: string
  prefix?: string
  lazy?: boolean
  shouldRegisterToFormSection?: boolean
}

export const FormFieldWithFeedbackIconContainer: FC<Props> = flowMax(
  addDisplayName('FormFieldWithFeedbackIconContainer'),
  addClasses(classes),
  addWrapper(
    (render, {lazy, name, prefix, shouldRegisterToFormSection, classes}) => (
      <Grid container direction="row">
        <Grid item className={classes.fieldContainer}>
          {render()}
        </Grid>
        <IconContainer
          name={name}
          lazy={lazy}
          prefix={prefix}
          shouldRegisterToFormSection={shouldRegisterToFormSection}
        />
      </Grid>
    )
  ),
  ({children}) => <>{children}</>
)
