import {
  flowMax,
  addEffect,
  addRef,
  addState,
  SimplePropsAdder,
  addProps,
  addHandlers,
} from 'ad-hok'
import {addEffectOnMount, cleanupProps} from 'ad-hok-utils'
import {MutableRefObject} from 'react'

import {addFormik} from 'utils/form/formik'
import typedAs from 'utils/typedAs'

type AddSubmitFormType = SimplePropsAdder<{
  submitForm: () => void
  submitFormRef: MutableRefObject<() => void>
}>

export const addSubmitForm: AddSubmitFormType = flowMax(
  addRef('submitFormRef', () => () => {}),
  addHandlers({
    submitForm: ({submitFormRef}) => () => {
      submitFormRef.current?.()
    },
  })
)

type AddFormikHoistedStateType = SimplePropsAdder<{
  submitForm: () => void
  submitFormRef: MutableRefObject<() => void>
  isSubmitting: boolean
  setIsSubmitting: (
    isSubmitting: boolean | ((previousState: boolean) => boolean)
  ) => void
}>

const addFormikHoistedState: AddFormikHoistedStateType = flowMax(
  addSubmitForm,
  addState('isSubmitting', 'setIsSubmitting', typedAs<boolean>(false))
)

export default addFormikHoistedState

type AddFormikSubmitFormCallbackType = <
  TProps extends {
    submitFormRef: MutableRefObject<() => void>
  }
>(
  props: TProps
) => TProps

export const addFormikSubmitFormCallback: AddFormikSubmitFormCallbackType = flowMax(
  addProps((props) => ({
    _formikIncoming: (props as any).formik,
  })),
  addFormik,
  addEffectOnMount(({submitFormRef, formik: {submitForm}}) => () => {
    submitFormRef.current = submitForm
  }),
  addProps(
    ({_formikIncoming}) =>
      (_formikIncoming ? {formik: _formikIncoming} : {}) as {}
  ),
  cleanupProps(['_formikIncoming'])
)

type AddFormikHoistedStateCallbacksType = <
  TProps extends {
    submitFormRef: MutableRefObject<() => void>
    setIsSubmitting: (
      isSubmitting: boolean | ((previousState: boolean) => boolean)
    ) => void
  }
>(
  props: TProps
) => TProps

export const addFormikHoistedStateCallbacks: AddFormikHoistedStateCallbacksType = flowMax(
  addFormikSubmitFormCallback,
  addProps((props) => ({
    _formikIncoming: (props as any).formik,
  })),
  addFormik,
  // eslint-disable-next-line ad-hok/dependencies
  addEffect(
    ({formik: {isSubmitting}, setIsSubmitting}) => () => {
      setIsSubmitting(isSubmitting)
    },
    ['formik.isSubmitting']
  ),
  addProps(
    ({_formikIncoming}) =>
      (_formikIncoming ? {formik: _formikIncoming} : {}) as {}
  ),
  cleanupProps(['_formikIncoming'])
)
