/* eslint-disable */
import {ReceivedWebformsVariables as ReceivedWebformsVariablesOrig} from '../types/ReceivedWebforms'
import {ReceivedWebforms as ReceivedWebformsOrig} from '../types/ReceivedWebforms'
import {ReceivedWebformResponse} from 'utils/form/getReceivedWebformResponse'
import {parseReceivedWebformResponse} from 'utils/form/getReceivedWebformResponse'
import {serializeReceivedWebformResponse} from 'utils/form/getReceivedWebformResponse'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {ReceivedWebforms_receivedWebforms as ReceivedWebforms_receivedWebformsOrig} from '../types/ReceivedWebforms'
import {ReceivedWebforms_receivedWebforms_webform as ReceivedWebforms_receivedWebforms_webformOrig} from '../types/ReceivedWebforms'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: ReceivedWebforms
// ====================================================
export interface ReceivedWebforms_receivedWebforms_webform {
  __typename: 'Webform'
  id: string
  name: string
  slug: string
}
export const deserializeReceivedWebforms_receivedWebforms_webform = (
  serialized: ReceivedWebforms_receivedWebforms_webformOrig
): ReceivedWebforms_receivedWebforms_webform => ({...serialized})
export const serializeReceivedWebforms_receivedWebforms_webform = (
  deserialized: ReceivedWebforms_receivedWebforms_webform
): ReceivedWebforms_receivedWebforms_webformOrig => ({...deserialized})
export interface ReceivedWebforms_receivedWebforms {
  __typename: 'ReceivedWebform'
  id: string
  completedAt: Date
  response: ReceivedWebformResponse
  noPersonMatch: boolean
  createdAt: Date
  webform: ReceivedWebforms_receivedWebforms_webform
}
export const deserializeReceivedWebforms_receivedWebforms = (
  serialized: ReceivedWebforms_receivedWebformsOrig
): ReceivedWebforms_receivedWebforms => ({
  ...serialized,
  completedAt: parseDate(serialized.completedAt),
  response: parseReceivedWebformResponse(serialized.response),
  createdAt: parseDate(serialized.createdAt),
  webform: deserializeReceivedWebforms_receivedWebforms_webform(
    serialized.webform
  ),
})
export const serializeReceivedWebforms_receivedWebforms = (
  deserialized: ReceivedWebforms_receivedWebforms
): ReceivedWebforms_receivedWebformsOrig => ({
  ...deserialized,
  completedAt: formatISO(deserialized.completedAt),
  response: serializeReceivedWebformResponse(deserialized.response),
  createdAt: formatISO(deserialized.createdAt),
  webform: serializeReceivedWebforms_receivedWebforms_webform(
    deserialized.webform
  ),
})
export interface ReceivedWebforms {
  /**
   * Received webforms
   */
  receivedWebforms: ReceivedWebforms_receivedWebforms[]
}
export const deserializeReceivedWebforms = (
  serialized: ReceivedWebformsOrig
): ReceivedWebforms => ({
  ...serialized,
  receivedWebforms: serialized.receivedWebforms.map(
    deserializeReceivedWebforms_receivedWebforms
  ),
})
export const serializeReceivedWebforms = (
  deserialized: ReceivedWebforms
): ReceivedWebformsOrig => ({
  ...deserialized,
  receivedWebforms: deserialized.receivedWebforms.map(
    serializeReceivedWebforms_receivedWebforms
  ),
})
export interface ReceivedWebformsVariables {
  matchedStatus?: string | null
  webformId?: string[] | null
  appointmentDate?: string[] | null
  isScreeningOutcomeEligible?: boolean | null
}
export const deserializeReceivedWebformsVariables = (
  serialized: ReceivedWebformsVariablesOrig
): ReceivedWebformsVariables => ({...serialized})
export const serializeReceivedWebformsVariables = (
  deserialized: ReceivedWebformsVariables
): ReceivedWebformsVariablesOrig => ({...deserialized})
