import ApolloClient from 'apollo-boost'

import {makeFormSchema} from 'utils/form/schema'
import {
  makeTextField,
  makeDateField,
  makeTimeField,
  makeServiceTimeZoneField,
  makeFacilityField,
  makeDepartmentField,
  makeInsuranceTypeField,
  makeGenderField,
  makeSsnField,
  makeLanguageField,
  makeBooleanField,
  makeStateField,
  makeZipCodeField,
  makeEmailField,
  ValidatorTest,
  makeNjmmisCheckField,
  makePhoneNumbersCollection,
} from 'utils/form/fieldTypes'
import {ACCOUNTS_QUERY} from 'graphql/queries'
import {FacilityFields} from 'graphql/deserializedTypes/FacilityFields'
import {DepartmentFields} from 'graphql/deserializedTypes/DepartmentFields'

export const getExistingHospitalAccountIdValidatorTest = ({
  apolloClient,
}: {
  apolloClient: ApolloClient<object> | null
}): ValidatorTest => [
  'journalingForm.hospitalAccountIdExists',
  async function (value: string) {
    if (!value) return true
    if (!apolloClient) return true
    const {
      data: {accounts},
    } = await apolloClient.query({
      query: ACCOUNTS_QUERY,
      variables: {
        hospitalAccountId: value,
      },
    })
    return accounts.length === 0
  },
]

export const getCreateAccountAndPatientFormSchema = ({
  facilities,
  departments,
  apolloClient,
}: {
  facilities: FacilityFields[]
  departments: DepartmentFields[]
  apolloClient: ApolloClient<object> | null
}) =>
  makeFormSchema({
    fields: {
      account: {
        hospitalAccountId: makeTextField({
          isRequired: true,
          validatorTest: getExistingHospitalAccountIdValidatorTest({
            apolloClient,
          }),
        }),
        dateOfService: makeDateField({
          isRequired: true,
          defaultToToday: true,
        }),
        timeOfService: makeTimeField(),
        serviceTimeZone: makeServiceTimeZoneField(),
        facility: makeFacilityField({
          facilities,
          defaultsToSingularOption: true,
          isRequired: true,
        }),
        department: makeDepartmentField({
          departments,
          defaultsToSingularOption: true,
        }),
        location: makeTextField(),
        insuranceType: makeInsuranceTypeField(),
        status: makeTextField({
          isRequired: true,
        }),
        njmmisCheck: makeNjmmisCheckField(),
        previousInsurance: makeTextField(),
        isScheduled: makeBooleanField({
          isRequired: true,
        }),
      },
      person: {
        id: makeTextField(),
        firstName: makeTextField({
          isRequired: true,
        }),
        middleName: makeTextField(),
        lastName: makeTextField({
          isRequired: true,
        }),
        suffix: makeTextField(),
        dob: makeDateField({
          isRequired: true,
        }),
        gender: makeGenderField(),
        ssn: makeSsnField(),
        phoneNumbers: makePhoneNumbersCollection(),
        homeAddressStreet: makeTextField(),
        homeAddressCity: makeTextField(),
        homeAddressState: makeStateField(),
        homeAddressZip: makeZipCodeField(),
        homeAddressComment: makeTextField(),
        mailingAddressStreet: makeTextField(),
        mailingAddressCity: makeTextField(),
        mailingAddressState: makeStateField(),
        mailingAddressZip: makeZipCodeField(),
        mailingAddressComment: makeTextField(),
        email: makeEmailField(),
        emailComment: makeTextField(),
        preferredLanguage: makeLanguageField(),
        hospitalPatientId: makeTextField(),
      },
    },
  })

export const staticCreateAccountAndPatientFormSchema = getCreateAccountAndPatientFormSchema(
  {facilities: [], departments: [], apolloClient: null}
)
