import React, {FC} from 'react'
import {SimpleUnchangedProps, addWrapper, flowMax, addDisplayName} from 'ad-hok'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import ErrorBoundary from 'components/ErrorBoundary'
import Grid from 'components/Grid'
import Heading from 'components/Heading'
import {addClasses, makeClasses} from 'theme'
import Button from 'components/Button'
import ExpansionPanel from 'components/ExpansionPanel'
import ExpansionPanelSummary from 'components/ExpansionPanelSummary'
import ExpansionPanelDetails from 'components/ExpansionPanelDetails'
import Body1 from 'components/Body1'
import {addTranslationHelpers} from 'utils/i18n'

interface Props {
  error: Error | null
}

const classes = makeClasses((/*theme*/) => ({
  container: {
    height: '100vh',
  },
  top: {
    flex: 1,
  },
  bottom: {
    flex: 0,
  },
}))

const CatchAllErrorFallback: FC<Props> = flowMax(
  addDisplayName('CatchAllErrorFallback'),
  addClasses(classes),
  addTranslationHelpers,
  ({classes, error, t}) => (
    <Grid
      className={classes.container}
      container
      direction="column"
      alignItems="center"
      justify="space-between"
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.top}
      >
        <Heading variant="h2">{t('topLevelErrorBoundary.message')}</Heading>
        <Button variant="contained" onClick={() => window.location.reload()}>
          {t('topLevelErrorBoundary.reload')}
        </Button>
      </Grid>
      {error && (
        <ExpansionPanel className={classes.bottom}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Body1>{t('topLevelErrorBoundary.errorLabel')}</Body1>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Body1>{error.stack}</Body1>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    </Grid>
  )
)

const addCatchAllErrorBoundary: SimpleUnchangedProps = addWrapper((render) => (
  <ErrorBoundary
    renderFallback={(error) => <CatchAllErrorFallback error={error} />}
  >
    {render()}
  </ErrorBoundary>
))

export default addCatchAllErrorBoundary
