import React, {FC} from 'react'
import EditIcon from '@material-ui/icons/Edit'
import {flowMax, addDisplayName} from 'ad-hok'
import {PureQueryOptions} from 'apollo-boost'

import addDialogState from 'utils/addDialogState'
import {addTranslationHelpers} from 'utils/i18n'
import IconButton from 'components/IconButton'
import TaskDialog from 'components/TaskDialog'
import {TaskFields} from 'graphql/deserializedTypes/TaskFields'

interface Props {
  task: TaskFields
  refetchQueriesOnUpdate: PureQueryOptions[]
  className?: string
}

const EditTaskButton: FC<Props> = flowMax(
  addDisplayName('EditTaskButton'),
  addDialogState,
  addTranslationHelpers,
  ({
    task,
    showDialog,
    hideDialog,
    isShowingDialog,
    refetchQueriesOnUpdate,
    className,
  }) => (
    <>
      <IconButton onClick={showDialog} className={className}>
        <EditIcon />
      </IconButton>
      <TaskDialog
        open={isShowingDialog}
        onClose={hideDialog}
        task={task}
        refetchQueries={refetchQueriesOnUpdate}
      />
    </>
  )
)

export default EditTaskButton
