import React from 'react'
import {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addStateHandlers,
  addHandlers,
  addWrapper,
  AddWrapperRenderCallback,
  addMemoBoundary,
} from 'ad-hok'
import {identity} from 'lodash'
import {ArrayHelpers} from 'formik'
import {format, parse} from 'date-fns'

import {addFormContext} from 'utils/form/context'
import {addFormik} from 'utils/form/formik'
import {addTranslationHelpers} from 'utils/i18n'
import {makeClasses, addClasses} from 'theme'
import FieldArray, {FieldArrayItem} from 'components/FieldArray'
import Grid from 'components/Grid'
import Body1 from 'components/Body1'
import NestedFormListItem from 'components/NestedFormListItem'
import ConfirmationDialog from 'components/ConfirmationDialog'
import IncomeSourceDialog from 'components/IncomeSourceDialog'
import {formatCurrency} from 'utils/number'
import {addFormPrefixContext} from './FormPrefix'
import addDialogState from 'utils/addDialogState'
import AddButton from 'components/AddButton'
import {OnSubmitSuccessOptions} from 'components/Form'
import {ExtractFormSchemaFields} from 'utils/form/schema'
import {incomeSourceFormSchema} from 'components/IncomeSourceDialog/index'
import {PersonFields_incomeSources} from 'graphql/deserializedTypes/PersonFields'
import {addPhoneNumberOrIncomeSourceEsignSessionUrlsContext} from 'components/EditPersonForm/phoneNumberEsignSessionUrlsContext'
import EsignSessionIcon from 'components/EsignSessionIcon'
import {addShouldShowApplicationPrompt} from 'components/PhoneFieldArray'

const classes = makeClasses((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  title: {
    maxWidth: 220,
  },
}))

type FormatIncomeSourceTitleOptions = Pick<
  PersonFields_incomeSources,
  'incomeType' | 'amount' | 'payFrequency'
>
const formatIncomeSourceTitle = ({
  incomeType,
  amount,
  payFrequency,
}: FormatIncomeSourceTitleOptions) =>
  [
    incomeType,
    amount && payFrequency ? `${formatCurrency(amount)}/${payFrequency}` : null,
  ]
    .filter(identity)
    .join(', ')

const formatDate = (month: string | null, year: string | null) =>
  month && year
    ? `${format(parse(month, 'MM', new Date()), 'MMMM')} ${year}`
    : null

export const formatDateRange = ({
  startMonth,
  startYear,
  endMonth,
  endYear,
}: {
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
}) =>
  [formatDate(startMonth, startYear), formatDate(endMonth, endYear)]
    .filter(identity)
    .join(' - ')

type FormatIncomeSourceDetailsOptions = Pick<
  PersonFields_incomeSources,
  'employerName' | 'startMonth' | 'startYear' | 'endMonth' | 'endYear'
>
const formatIncomeSourceDetails = ({
  employerName,
  ...dates
}: FormatIncomeSourceDetailsOptions) =>
  [employerName, formatDateRange(dates)].filter(identity).join(', ')

interface IncomeSourceItemProps {
  incomeSource: PersonFields_incomeSources
  index: number
  arrayHelpers: ArrayHelpers
  formPrefix: string
}

const IncomeSourceItem: FC<IncomeSourceItemProps> = flowMax(
  addDisplayName('IncomeSourceItem'),
  addFormik,
  addMemoBoundary(['incomeSource', 'index', 'formPrefix']),
  addStateHandlers(
    {
      isShowingEditDialog: false,
      isShowingDeleteDialog: false,
    },
    {
      showEditDialog: () => () => ({isShowingEditDialog: true}),
      hideEditDialog: () => () => ({isShowingEditDialog: false}),
      showDeleteDialog: () => () => ({isShowingDeleteDialog: true}),
      hideDeleteDialog: () => () => ({isShowingDeleteDialog: false}),
    }
  ),
  addProps(({formPrefix, index}) => ({
    itemName: `${formPrefix}incomeSources[${index}]`,
  })),
  addFormContext,
  addPhoneNumberOrIncomeSourceEsignSessionUrlsContext,
  addProps(
    ({phoneNumberOrIncomeSourceEsignSessionUrls, incomeSource: {id}}) => ({
      esignSessionUrl: id
        ? phoneNumberOrIncomeSourceEsignSessionUrls[`incomeSource-${id}`]
        : null,
    })
  ),
  addHandlers({
    onDialogSave: ({
      hideEditDialog,
      formik: {setFieldValue},
      itemName,
      setCollectionItemFieldsFreshness,
    }) => ({
      formValues: {incomeSource},
      fieldsFreshness,
    }: OnSubmitSuccessOptions<
      ExtractFormSchemaFields<typeof incomeSourceFormSchema>
    >) => {
      setCollectionItemFieldsFreshness(itemName, fieldsFreshness.incomeSource)
      setFieldValue(itemName, incomeSource)
      hideEditDialog()
    },
    deleteIncomeSource: ({
      index,
      arrayHelpers,
      formPrefix,
      removeCollectionItemFieldsFreshness,
      removeCollectionItemFieldsUpdatedAt,
      hideDeleteDialog,
    }) => () => {
      removeCollectionItemFieldsFreshness(`${formPrefix}incomeSources`, index)
      removeCollectionItemFieldsUpdatedAt(`${formPrefix}incomeSources`, index)
      arrayHelpers.remove(index)
      hideDeleteDialog()
    },
  }),
  addShouldShowApplicationPrompt,
  addTranslationHelpers,
  addClasses(classes),
  ({
    t,
    incomeSource,
    showEditDialog,
    hideEditDialog,
    isShowingEditDialog,
    onDialogSave,
    showDeleteDialog,
    hideDeleteDialog,
    isShowingDeleteDialog,
    deleteIncomeSource,
    itemName,
    esignSessionUrl,
    shouldShowApplicationPrompt,
    classes,
  }) => (
    <div data-testid={`income-source-${incomeSource.id ?? 'new'}`}>
      <NestedFormListItem
        title={formatIncomeSourceTitle(incomeSource)}
        subtitle={formatIncomeSourceDetails(incomeSource)}
        onEditClick={showEditDialog}
        onDeleteClick={showDeleteDialog}
        itemName={itemName}
        renderAdditionalIcons={() => (
          <EsignSessionIcon
            phoneNumberOrIncomeSource={{
              ...incomeSource,
              esignSessionUrl,
            }}
            shouldShowApplicationPrompt={shouldShowApplicationPrompt}
          />
        )}
        titleClassName={classes.title}
      />
      <IncomeSourceDialog
        incomeSource={incomeSource}
        itemName={itemName}
        open={isShowingEditDialog}
        onClose={hideEditDialog}
        onSave={onDialogSave}
      />
      <ConfirmationDialog
        title={t('deleteIncomeSourceDialog.title', {
          title: formatIncomeSourceTitle(incomeSource),
        })}
        cancelText={t('deleteIncomeSourceDialog.cancel')}
        confirmText={t('deleteIncomeSourceDialog.confirm')}
        open={isShowingDeleteDialog}
        onCancel={hideDeleteDialog}
        onConfirm={deleteIncomeSource}
      />
    </div>
  )
)

interface Props {
  name: string
}

const IncomeSourceFieldArray: FC<Props> = flowMax(
  addDisplayName('IncomeSourceFieldArray'),
  addFormPrefixContext,
  addWrapper(
    (
      render: AddWrapperRenderCallback<{
        items: FieldArrayItem<PersonFields_incomeSources>[]
        arrayHelpers: ArrayHelpers
        formPrefix: string
      }>,
      {name, formPrefix}
    ) => (
      <FieldArray<PersonFields_incomeSources>
        name={name}
        render={(arrayHelpers, items) =>
          render({arrayHelpers, items, formPrefix})
        }
      />
    )
  ),
  addFormContext,
  addMemoBoundary(['name', 'items', 'formPrefix', 'formName']),
  addDialogState,
  addHandlers({
    onDialogSave: ({
      formPrefix,
      items,
      arrayHelpers,
      hideDialog,
      setCollectionItemFieldsFreshness,
    }) => ({
      formValues: {incomeSource},
      fieldsFreshness,
    }: OnSubmitSuccessOptions<
      ExtractFormSchemaFields<typeof incomeSourceFormSchema>
    >) => {
      const itemName = `${formPrefix}incomeSources[${items.length}]`
      setCollectionItemFieldsFreshness(itemName, fieldsFreshness.incomeSource)
      arrayHelpers.push(incomeSource)
      hideDialog()
    },
  }),
  addTranslationHelpers,
  addProps(({formName, name, t}) => ({
    addLabel: t(`${formName}.collectionControls.${name}.add`),
    noItemsLabel: t(`${formName}.collectionControls.${name}.noItems`),
  })),
  addClasses(classes),
  ({
    classes,
    items,
    arrayHelpers,
    addLabel,
    noItemsLabel,
    showDialog,
    hideDialog,
    isShowingDialog,
    onDialogSave,
    formPrefix,
  }) => (
    <Grid className={classes.container}>
      {items.length === 0 && <Body1>{noItemsLabel}</Body1>}
      {items.map(({key, record}, index) => (
        <IncomeSourceItem
          formPrefix={formPrefix}
          incomeSource={record}
          index={index}
          arrayHelpers={arrayHelpers}
          key={key}
        />
      ))}
      <AddButton onClick={showDialog}>{addLabel}</AddButton>
      <IncomeSourceDialog
        open={isShowingDialog}
        onClose={hideDialog}
        onSave={onDialogSave}
      />
    </Grid>
  )
)

export default IncomeSourceFieldArray
