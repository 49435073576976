/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {AuditDataInput} from './globalTypes' // ====================================================
// GraphQL mutation operation: CreateZipAndUpload
// ====================================================

import {deserializeAuditDataInput} from './globalTypes'
import {serializeAuditDataInput} from './globalTypes'
import {CreateZipAndUploadVariables as CreateZipAndUploadVariablesOrig} from '../types/CreateZipAndUpload'
import {CreateZipAndUpload as CreateZipAndUploadOrig} from '../types/CreateZipAndUpload'
import {CreateZipAndUpload_createZipAndUpload as CreateZipAndUpload_createZipAndUploadOrig} from '../types/CreateZipAndUpload'
export interface CreateZipAndUpload_createZipAndUpload {
  __typename: 'CreateZipAndUploadPayload'
  success: boolean
}
export const deserializeCreateZipAndUpload_createZipAndUpload = (
  serialized: CreateZipAndUpload_createZipAndUploadOrig
): CreateZipAndUpload_createZipAndUpload => ({...serialized})
export const serializeCreateZipAndUpload_createZipAndUpload = (
  deserialized: CreateZipAndUpload_createZipAndUpload
): CreateZipAndUpload_createZipAndUploadOrig => ({...deserialized})
export interface CreateZipAndUpload {
  createZipAndUpload: CreateZipAndUpload_createZipAndUpload | null
}
export const deserializeCreateZipAndUpload = (
  serialized: CreateZipAndUploadOrig
): CreateZipAndUpload => ({
  ...serialized,
  createZipAndUpload:
    serialized.createZipAndUpload != null
      ? deserializeCreateZipAndUpload_createZipAndUpload(
          serialized.createZipAndUpload
        )
      : serialized.createZipAndUpload,
})
export const serializeCreateZipAndUpload = (
  deserialized: CreateZipAndUpload
): CreateZipAndUploadOrig => ({
  ...deserialized,
  createZipAndUpload:
    deserialized.createZipAndUpload != null
      ? serializeCreateZipAndUpload_createZipAndUpload(
          deserialized.createZipAndUpload
        )
      : deserialized.createZipAndUpload,
})
export interface CreateZipAndUploadVariables {
  audit_id: string
  audit_data: AuditDataInput[]
}
export const deserializeCreateZipAndUploadVariables = (
  serialized: CreateZipAndUploadVariablesOrig
): CreateZipAndUploadVariables => ({
  ...serialized,
  audit_data: serialized.audit_data.map(deserializeAuditDataInput),
})
export const serializeCreateZipAndUploadVariables = (
  deserialized: CreateZipAndUploadVariables
): CreateZipAndUploadVariablesOrig => ({
  ...deserialized,
  audit_data: deserialized.audit_data.map(serializeAuditDataInput),
})
