import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'

import DisplayItem from 'components/DisplayItem'
import {getFormattedDateOfBirth} from 'utils/date'
import {addTranslationHelpers} from 'utils/i18n'

interface Props {
  dob: Date | null
}

const DisplayDob: FC<Props> = flowMax(
  addDisplayName('DisplayDob'),
  addTranslationHelpers,
  addProps(
    ({dob, t}) => ({
      dobFormatted: getFormattedDateOfBirth({dob, t}),
    }),
    ['dob', 't']
  ),
  ({dobFormatted}) => (
    <DisplayItem
      i18nKey="applicationForm.dobDisplay"
      translations={{
        dob: dobFormatted,
      }}
    />
  )
)

export default DisplayDob
