import React, {FC} from 'react'
import AddIcon from '@material-ui/icons/Add'

import Button from 'components/Button'

interface Props {
  onClick: () => void
  variant?: React.ComponentProps<typeof Button>['variant']
  className?: string
  disabled?: boolean
}

const AddButton: FC<Props> = ({
  onClick,
  variant,
  disabled,
  className,
  children,
}) => (
  <Button
    startIcon={<AddIcon />}
    color="primary"
    variant={variant}
    onClick={onClick}
    className={className}
    disabled={disabled}
  >
    {children}
  </Button>
)

export default AddButton
