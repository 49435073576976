import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addHandlers,
  addProps,
  addStateHandlers,
} from 'ad-hok'
import {branchIfFalsy, branchIfEmpty} from 'ad-hok-utils'
import {format} from 'date-fns/fp'

import {addFetchDocumentFileUrl} from 'components/DocumentFileLink'
import {
  addFileTemplatesQuery,
  addPersonReceivedWebformFileTemplatesQuery,
} from 'graphql/generated'
import {addLoadingIndicator} from 'utils/dataLoading'
import FormSection from 'components/FormSection'
import {StandaloneSelectField} from 'components/SelectField'
import {addTranslationHelpers} from 'utils/i18n'
import typedAs from 'utils/typedAs'
import EditPdfDialog, {
  FileTemplateWithSignedUrl,
} from 'components/EditPdfDialog'
import {addApplicationFormContext} from 'components/EditApplicationForm/applicationFormContext'
import {fileTemplatesSectionForLink} from 'components/EditApplicationForm/ApplicationFormLeftColumn'
import {getShouldShowEditableFilesSections} from 'utils/editableFiles'
import {PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates} from 'graphql/deserializedTypes/PersonReceivedWebformFileTemplates'
import LinkButton from 'components/LinkButton'
import {makeClasses, addClasses} from 'theme'
import Subtitle2 from 'components/Subtitle2'
import {toEasternTime} from 'utils/date'

const classes = makeClasses((theme) => ({
  receivedWebformFileTemplateButton: {
    display: 'block',
  },
  receivedWebformFileTemplatesContainer: {
    marginTop: theme.spacing(3),
  },
  receivedWebformFileTemplatesHeader: {
    marginBottom: theme.spacing(1),
  },
  receivedWebformFileTemplatesReceived: {
    fontWeight: 400,
  },
}))

type ReceivedWebformFileTemplateType = PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates

interface ReceivedWebformFileTemplatesProps {
  onReceivedWebformFileTemplateClick: (
    receivedWebformFileTemplate: ReceivedWebformFileTemplateType
  ) => void
}

const ReceivedWebformFileTemplates: FC<ReceivedWebformFileTemplatesProps> = flowMax(
  addDisplayName('ReceivedWebformFileTemplates'),
  addApplicationFormContext,
  addPersonReceivedWebformFileTemplatesQuery({
    variables: ({
      application: {
        person: {id: personId},
      },
    }) => ({
      id: personId,
    }),
  }),
  addLoadingIndicator({}),
  addProps(({person: {mostRecentReceivedWebform}}) => ({
    receivedWebformFileTemplates:
      mostRecentReceivedWebform?.receivedWebformFileTemplates.filter(
        ({cleared}) => !cleared
      ) ?? [],
    createdAtFormatted: mostRecentReceivedWebform
      ? format('M/d/yy HH:mm')(
          toEasternTime(mostRecentReceivedWebform.createdAt)
        )
      : '',
  })),
  branchIfEmpty('receivedWebformFileTemplates'),
  addClasses(classes),
  addTranslationHelpers,
  ({
    receivedWebformFileTemplates,
    onReceivedWebformFileTemplateClick,
    createdAtFormatted,
    classes,
    t,
  }) => (
    <div className={classes.receivedWebformFileTemplatesContainer}>
      <Subtitle2 className={classes.receivedWebformFileTemplatesHeader}>
        {t('receivedWebformFileTemplates.header')}{' '}
        <span className={classes.receivedWebformFileTemplatesReceived}>
          {t('receivedWebformFileTemplates.received', {
            time: createdAtFormatted,
          })}
        </span>
      </Subtitle2>
      {receivedWebformFileTemplates.map((receivedWebformFileTemplate) => (
        <LinkButton
          onClick={() =>
            onReceivedWebformFileTemplateClick(receivedWebformFileTemplate)
          }
          className={classes.receivedWebformFileTemplateButton}
          key={receivedWebformFileTemplate.id}
        >
          {receivedWebformFileTemplate.fileTemplate.name}
        </LinkButton>
      ))}
    </div>
  )
)

const FileTemplateDropdown: FC = flowMax(
  addDisplayName('FileTemplateDropdown'),
  addFileTemplatesQuery({}),
  addLoadingIndicator({}),
  addStateHandlers(
    {
      selectedTemplate: typedAs<FileTemplateWithSignedUrl | null>(null),
      receivedWebformFileTemplate: typedAs<ReceivedWebformFileTemplateType | null>(
        null
      ),
    },
    {
      setSelectedTemplate: () => (
        selectedTemplate: FileTemplateWithSignedUrl
      ) => ({
        selectedTemplate,
      }),
      setReceivedWebformFileTemplate: () => (
        receivedWebformFileTemplate: ReceivedWebformFileTemplateType
      ) => ({
        receivedWebformFileTemplate,
      }),
      onEditPdfDialogClose: () => () => ({
        selectedTemplate: null,
        receivedWebformFileTemplate: null,
      }),
    }
  ),
  addFetchDocumentFileUrl,
  addHandlers({
    onFileTemplateSelect: ({
      fetchDocumentFileUrl,
      setSelectedTemplate,
      fileTemplates,
    }) => async (fileTemplateId: string) => {
      const fileTemplate = fileTemplates.find(
        (fileTemplate) => fileTemplate.id === fileTemplateId
      )!
      const signedUrl = await fetchDocumentFileUrl({
        fileKey: fileTemplate.fileKey,
      })
      setSelectedTemplate({...fileTemplate, signedUrl})
    },
  }),
  addHandlers({
    onChange: ({onFileTemplateSelect}) => ({
      target: {value},
    }: React.ChangeEvent<{value: unknown}>) => {
      if (!value) return
      onFileTemplateSelect(value as string)
    },
    onReceivedWebformFileTemplateClick: ({
      onFileTemplateSelect,
      setReceivedWebformFileTemplate,
    }) => (receivedWebformFileTemplate: ReceivedWebformFileTemplateType) => {
      onFileTemplateSelect(receivedWebformFileTemplate.fileTemplate.id)
      setReceivedWebformFileTemplate(receivedWebformFileTemplate)
    },
  }),
  addProps(({fileTemplates}) => ({
    options: [
      {
        value: '',
        label: '',
      },
      ...fileTemplates.map(({name, id}) => ({
        value: id,
        label: name,
      })),
    ],
  })),
  addApplicationFormContext,
  addTranslationHelpers,
  ({
    application: {
      id: applicationId,
      person: {id: personId},
    },
    options,
    onChange,
    selectedTemplate,
    onEditPdfDialogClose,
    receivedWebformFileTemplate,
    onReceivedWebformFileTemplateClick,
    t,
  }) => (
    <>
      <StandaloneSelectField
        options={options}
        onChange={onChange}
        label={t('fileTemplateDropdown.label')}
        value=""
        id="file-template-dropdown"
      />
      <ReceivedWebformFileTemplates
        onReceivedWebformFileTemplateClick={onReceivedWebformFileTemplateClick}
      />
      <EditPdfDialog
        personId={personId}
        applicationId={applicationId}
        file={selectedTemplate}
        faxEnabled={false}
        receivedWebformFileTemplate={receivedWebformFileTemplate}
        onClose={onEditPdfDialogClose}
      />
    </>
  )
)

const FileTemplateSection: FC = flowMax(
  addDisplayName('FileTemplateSection'),
  addApplicationFormContext,
  addProps(
    ({application}) => ({
      shouldShow: getShouldShowEditableFilesSections(application),
    }),
    ['application']
  ),
  branchIfFalsy('shouldShow'),
  () => (
    <FormSection labelTranslationKey={fileTemplatesSectionForLink.name}>
      <FileTemplateDropdown />
    </FormSection>
  )
)

export default FileTemplateSection
