import React, {FC} from 'react'
import Typography from '@material-ui/core/Typography'

import {TextProps} from 'components/Heading'

const Body1: FC<TextProps> = ({variant = 'body1', ...props}) => (
  <Typography variant={variant} {...props} />
)

export default Body1
