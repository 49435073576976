import React from 'react'
import BaseContainer from '@material-ui/core/Container'
import cx from 'classnames'
import {flowMax, addDisplayName} from 'ad-hok'

import {makeClasses, addClasses} from 'theme'

const classes = makeClasses((/*theme*/) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    position: 'relative',
  },
}))

const Container: typeof BaseContainer = flowMax(
  addDisplayName('Container'),
  addClasses(classes),
  ({classes, className, ...props}) => (
    <BaseContainer
      maxWidth="xl"
      className={cx(classes.container, className)}
      {...props}
    />
  )
)

export default Container
