import {TestContext} from 'yup'
import {TFunction} from 'i18next'
import * as Yup from 'yup'

import {
  getIsFacilitySpecificBenefitType,
  BenefitType,
  getIsInitialDateOfServiceRequiredWhenCreatingBenefitType,
} from 'utils/benefits'
import {
  ValidatorTest,
  makeTextField,
  makeBenefitField,
  makeFacilityField,
  makeMcdTypeField,
  makeCharityCareTypeField,
  makeSlideTypeField,
  makeDateField,
  makeSelectField,
  makeMcdSubtypeField,
} from 'utils/form/fieldTypes'
import {FacilityFields} from 'graphql/deserializedTypes/FacilityFields'
import {
  Config_config_applicationBenefits,
  Config_config_charityCareTypes,
} from 'graphql/deserializedTypes/Config'
import {makeFormSchema} from 'utils/form/schema'

const facilityValidatorTest: ValidatorTest = [
  'form.required',
  function (this: TestContext, value: string) {
    const {benefit} = this.parent
    if (!getIsFacilitySpecificBenefitType(benefit)) return true
    if (value) return true
    return false
  },
]

const ccTypeValidatorTest: ValidatorTest = [
  'form.required',
  function (this: TestContext, value: string) {
    const {benefit} = this.parent
    if (benefit !== 'charityCare') return true
    if (value) return true
    return false
  },
]

const mcdTypeValidatorTest: ValidatorTest = [
  'form.required',
  function (this: TestContext, value: string) {
    const {benefit} = this.parent
    if (benefit !== 'medicaid') return true
    if (value) return true
    return false
  },
]

// const mcdSubtypeValidatorTest: ValidatorTest = [
//   'form.required',
//   function (this: TestContext, value: string) {
//     const {benefit} = this.parent
//     if (benefit !== 'medicaid') return true
//     if (value) return true
//     return false
//   },
// ]

const slideTypeValidatorTest: ValidatorTest = [
  'form.required',
  function (this: TestContext, value: string) {
    const {benefit} = this.parent
    if (benefit !== 'slide') return true
    if (value) return true
    return false
  },
]

const dateNotBefore2020 = ({t}: {t: TFunction}) =>
  Yup.date().min(
    new Date('2020-01-01'),
    t('applicationForm.errors.dateOfService')
  )

const initialDateOfServiceValidatorTest: ValidatorTest = [
  'form.required',
  function (this: TestContext, value: string) {
    const {benefit} = this.parent
    if (
      !getIsInitialDateOfServiceRequiredWhenCreatingBenefitType(
        benefit as BenefitType
      )
    ) {
      return true
    }
    if (value) return true
    return false
  },
]

export const getCreateApplicationFormSchema = ({
  facilities,
  benefits,
  charityCareTypes,
  t,
}: {
  facilities: FacilityFields[]
  benefits: Config_config_applicationBenefits[]
  charityCareTypes: Config_config_charityCareTypes[]
  t: TFunction
}) =>
  makeFormSchema({
    fields: {
      application: {
        personId: makeTextField({isRequired: true}),
        status: makeTextField({isRequired: true}),
        benefit: makeBenefitField({
          benefits,
          t,
          isRequired: true,
          defaultsToSingularOption: true,
        }),
        facility: makeFacilityField({
          facilities,
          defaultsToSingularOption: true,
          validatorTest: facilityValidatorTest,
        }),
        mcdType: makeMcdTypeField({
          validatorTest: mcdTypeValidatorTest,
        }),
        charityCareType: makeCharityCareTypeField({
          charityCareTypes,
          defaultsToSingularOption: true,
          validatorTest: ccTypeValidatorTest,
        }),
        assignedToId: makeSelectField({isRequired: true}),
        initialDateOfService: makeDateField({
          validatorTest: initialDateOfServiceValidatorTest,
          validator: dateNotBefore2020,
        }),
        slideType: makeSlideTypeField({
          validatorTest: slideTypeValidatorTest,
        }),
        mcdSubtype: makeMcdSubtypeField({
          isRequired: false,
        }),
      },
    },
  })

export const createApplicationFormSchemaStatic = getCreateApplicationFormSchema(
  {
    facilities: [],
    benefits: [],
    charityCareTypes: [],
    t: () => '',
  }
)
