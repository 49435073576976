import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addStateHandlers,
  addWrapper,
  addState,
  addEffect,
  branch,
  renderNothing,
  addProps,
  returns,
  addHandlers,
} from 'ad-hok'
import {
  branchIfTruthy,
  branchIfEmpty,
  addEffectOnMount,
  branchIfNullish,
} from 'ad-hok-utils'
import {format} from 'date-fns/fp'
import cx from 'classnames'
import AddIcon from '@material-ui/icons/Add'

import Heading from 'components/Heading'
import SearchInput from 'components/SearchInput'
import {addTranslationHelpers} from 'utils/i18n'
import {addClasses, makeClasses} from 'theme'
import {addLoadingIndicator} from 'utils/dataLoading'
import {addPeopleByMrnQuery} from 'graphql/generated'
import typedAs from 'utils/typedAs'
import {PeopleByMrn_peopleByMrn} from 'graphql/deserializedTypes/PeopleByMrn'
import Body1 from 'components/Body1'
import {getExtendedName} from 'utils/name'
import {EditableFileStatus} from 'graphql/deserializedTypes/globalTypes'
import addRemountingWhenPropsChange from 'utils/addRemountingWhenPropsChange'
import EditPdfDialog, {
  EditableFileWithSignedUrl,
} from 'components/EditPdfDialog'
import {addFetchDocumentFileUrl} from 'components/DocumentFileLink'
import UploadEditableFileDialog from './UploadEditableFileDialog'
import addDialogState from 'utils/addDialogState'
import Tooltip from 'components/Tooltip'
import Button from 'components/Button'

const classes = makeClasses((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  topSectionContainer: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#f2f2f2',
    marginBottom: theme.spacing(2),
  },
  header: {
    fontSize: 19,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),
  },
  headerName: {
    textTransform: 'none',
  },
  searchInput: {
    width: 250,
  },
  notFound: {
    color: 'red',
    fontWeight: 'bold',
  },
  doneEnglish: {
    marginTop: theme.spacing(6),
  },
  doneSpanish: {
    marginTop: theme.spacing(2),
  },
  addFileButton: {
    marginTop: theme.spacing(30),
    width: 80,
  },
}))

const NotFound: FC = flowMax(
  addDisplayName('NotFound'),
  addClasses(classes),
  addTranslationHelpers,
  ({classes, t}) => (
    <Body1 className={classes.notFound}>{t('patientPortal.notFound')}</Body1>
  )
)

interface DoneSigningProps {
  personId: string
  addFileButtonDisabled: boolean
}

const DoneSigning: FC<DoneSigningProps> = flowMax(
  addDisplayName('DoneSigning'),
  addClasses(classes),
  addTranslationHelpers,
  addDialogState,
  ({
    classes,
    t,
    personId,
    isShowingDialog,
    hideDialog,
    showDialog,
    addFileButtonDisabled,
  }) => (
    <>
      <Body1 className={classes.doneEnglish}>
        {t('patientPortal.doneEnglish')}
      </Body1>
      <Body1 className={classes.doneSpanish}>
        {t('patientPortal.doneSpanish')}
      </Body1>
      {addFileButtonDisabled ? (
        <Tooltip title={t('patientPortal.addFileDisabledTooltip')}>
          <div className={classes.addFileButton}>
            <Button
              startIcon={<AddIcon />}
              color="primary"
              onClick={() => {}}
              disabled={addFileButtonDisabled}
            >
              {t('editableFiles.addButton')}
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button
          startIcon={<AddIcon />}
          color="primary"
          onClick={showDialog}
          className={classes.addFileButton}
          disabled={addFileButtonDisabled}
        >
          {t('editableFiles.addButton')}
        </Button>
      )}
      <UploadEditableFileDialog
        personId={personId}
        uploadInstructionsText={t('patientPortal.fileUploadInstructions')}
        setNewEditableFileAsUnreviewed
        open={isShowingDialog}
        onClose={hideDialog}
      />
    </>
  )
)

interface PersonViewProps {
  person: PeopleByMrn_peopleByMrn
}

const PersonView: FC<PersonViewProps> = flowMax(
  addDisplayName('PersonView'),
  addTranslationHelpers,
  addProps(({person, t}) => ({
    dobFormatted: person.dob
      ? format('M/d/yyyy')(person.dob)
      : t('general.unknown'),
    nameFormatted: getExtendedName({...person, t}),
    hasNoOpenApplications: person.openApplications.length === 0,
  })),
  addClasses(classes),
  addWrapper((render, {dobFormatted, nameFormatted, classes, t}) => (
    <>
      <Heading
        component="h3"
        className={cx(classes.header, classes.headerName)}
      >
        {t('patientPortal.nameAndDob', {
          name: nameFormatted,
          dob: dobFormatted,
        })}
      </Heading>
      {render()}
    </>
  )),
  addState(
    'outForSigningEditableFiles',
    'setOutForSigningEditableFiles',
    ({person: {editableFiles}}) =>
      editableFiles.filter(
        ({status, hasIncompleteRemoteDocumentRequest}) =>
          !hasIncompleteRemoteDocumentRequest &&
          status === EditableFileStatus.outForSigning
      )
  ),
  branchIfEmpty('outForSigningEditableFiles', {
    returns: ({person, hasNoOpenApplications}) => (
      <DoneSigning
        personId={person.id}
        addFileButtonDisabled={hasNoOpenApplications}
      />
    ),
  }),
  addStateHandlers(
    {
      currentFileIndex: 0,
    },
    {
      onFileSubmit: ({currentFileIndex}) => () => ({
        currentFileIndex: currentFileIndex + 1,
      }),
    }
  ),
  branch(
    ({currentFileIndex, outForSigningEditableFiles}) =>
      currentFileIndex >= outForSigningEditableFiles.length,
    returns(({person, hasNoOpenApplications}) => (
      <DoneSigning
        personId={person.id}
        addFileButtonDisabled={hasNoOpenApplications}
      />
    ))
  ),
  addProps(
    ({outForSigningEditableFiles, currentFileIndex}) => ({
      currentFile: outForSigningEditableFiles[currentFileIndex],
    }),
    ['currentFileIndex', 'outForSigningEditableFiles']
  ),
  addRemountingWhenPropsChange('currentFileIndex'),
  addStateHandlers(
    {
      currentFileWithSignedUrl: typedAs<EditableFileWithSignedUrl | null>(null),
    },
    {
      setCurrentFileWithSignedUrl: () => (
        currentFileWithSignedUrl: EditableFileWithSignedUrl
      ) => ({
        currentFileWithSignedUrl,
      }),
    }
  ),
  addFetchDocumentFileUrl,
  addHandlers({
    fetchSignedUrl: ({
      fetchDocumentFileUrl,
      setCurrentFileWithSignedUrl,
      currentFile,
    }) => async () => {
      const signedUrl = await fetchDocumentFileUrl({
        fileKey: currentFile.fileKey,
      })
      setCurrentFileWithSignedUrl({...currentFile, signedUrl})
    },
  }),
  addEffectOnMount(({fetchSignedUrl}) => () => {
    fetchSignedUrl()
  }),
  branchIfNullish('currentFileWithSignedUrl'),
  addProps(({currentFileIndex, outForSigningEditableFiles, t}) => ({
    dialogTitle: t('patientPortal.editPdfDialogTitle', {
      index: currentFileIndex + 1,
      numFiles: outForSigningEditableFiles.length,
    }),
  })),
  ({
    currentFileWithSignedUrl,
    person: {id: personId},
    onFileSubmit,
    dialogTitle,
  }) => (
    <EditPdfDialog
      personId={personId}
      file={currentFileWithSignedUrl}
      faxEnabled={false}
      onClose={onFileSubmit}
      disallowClosingWithoutSave
      title={dialogTitle}
    />
  )
)

interface SearchByMrnProps {
  onFoundPerson: (foundPerson: PeopleByMrn_peopleByMrn) => void
}

const SearchByMrn: FC<SearchByMrnProps> = flowMax(
  addDisplayName('SearchByMrn'),
  addStateHandlers(
    {
      search: '',
      searchExposed: '',
      hasTriggeredSearch: false,
    },
    {
      onSearchChange: () => (search: string) => ({
        search,
      }),
      onSearch: ({search}) => () =>
        search
          ? {
              hasTriggeredSearch: true,
              searchExposed: search,
            }
          : {},
      onClear: () => () => ({
        search: '',
        searchExposed: '',
        hasTriggeredSearch: false,
      }),
    }
  ),
  addClasses(classes),
  addTranslationHelpers,
  addWrapper(
    (render, {search, onSearch, onSearchChange, onClear, classes, t}) => (
      <>
        <div className={classes.topSectionContainer}>
          <Heading component="h3" className={classes.header}>
            {t('patientPortal.mrn')}
          </Heading>
          <SearchInput
            id="patient-portal-mrn-search"
            inputClassName={classes.searchInput}
            onChange={onSearchChange}
            onSearch={onSearch}
            onClear={onClear}
            value={search}
          />
        </div>
        {render()}
      </>
    )
  ),
  addPeopleByMrnQuery({
    variables: ({searchExposed: hospitalPatientId}) => ({hospitalPatientId}),
    skip: ({hasTriggeredSearch}) => !hasTriggeredSearch,
  }),
  addLoadingIndicator({}),
  addEffect(
    ({peopleByMrn, onFoundPerson}) => () => {
      if (!peopleByMrn.length) return
      onFoundPerson(peopleByMrn[0])
    },
    ['peopleByMrn']
  ),
  branch(({peopleByMrn}) => !!peopleByMrn.length, renderNothing()),
  () => <NotFound />
)

const PatientPortal: FC = flowMax(
  addDisplayName('PatientPortal'),
  addClasses(classes),
  addWrapper((render, {classes}) => (
    <section className={classes.container}>{render()}</section>
  )),
  addStateHandlers(
    {
      foundPerson: typedAs<PeopleByMrn_peopleByMrn | null>(null),
    },
    {
      onFoundPerson: () => (foundPerson: PeopleByMrn_peopleByMrn) => ({
        foundPerson,
      }),
    }
  ),
  branchIfTruthy('foundPerson', {
    returns: ({foundPerson}) => <PersonView person={foundPerson} />,
  }),
  ({onFoundPerson}) => <SearchByMrn onFoundPerson={onFoundPerson} />
)

export default PatientPortal
