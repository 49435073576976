import React, {FC, ChangeEvent} from 'react'
import {flowMax, addDisplayName, addHandlers} from 'ad-hok'
import SearchIcon from '@material-ui/icons/Search'

import Grid from 'components/Grid'
import Button from 'components/Button'
import {addClasses, makeClasses} from 'theme'
import InputWithClearButton, {
  InputWithClearButtonProps,
} from 'components/InputWithClearButton'

const classes = makeClasses((theme) => ({
  searchButton: {
    minWidth: 48,
  },
  searchIcon: {
    marginLeft: 0,
    marginRight: 0,
  },
  clearButtonPlaceholder: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    marginRight: -12,
  },
}))

interface SearchInputProps extends Omit<InputWithClearButtonProps, 'onEnter'> {
  onSearch: () => void
}

const SearchInput: FC<SearchInputProps> = flowMax(
  addDisplayName('SearchInput'),
  addHandlers({
    onValueChange: ({onChange}) => (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value)
    },
  }),
  addClasses(classes),
  ({onSearch, classes, ...props}) => (
    <Grid container direction="row" wrap="nowrap">
      <InputWithClearButton onEnter={onSearch} {...props} />
      <Button
        startIcon={<SearchIcon />}
        classes={{
          startIcon: classes.searchIcon,
        }}
        variant="contained"
        color="primary"
        onClick={onSearch}
        className={classes.searchButton}
      >
        {''}
      </Button>
    </Grid>
  )
)

export default SearchInput
