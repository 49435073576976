import React, {FC} from 'react'
import {generatePath} from 'react-router'
import {flowMax, addDisplayName, addProps} from 'ad-hok'

import {addTranslationHelpers} from 'utils/i18n'
import {getExtendedName} from 'utils/name'
import Trans from 'components/Trans'
import Link from 'components/Link'
import {personDetailDefaultPath} from 'components/TopLevelRoutes'
import {Account_account} from 'graphql/deserializedTypes/Account'

interface Props {
  account: Account_account
}

const AccountTitle: FC<Props> = flowMax(
  addDisplayName('AccountTitle'),
  addTranslationHelpers,
  addProps(
    ({account: {person}, t}) => ({
      personName: getExtendedName({...person, t}),
    }),
    ['account', 't']
  ),
  ({
    account: {
      hospitalAccountId,
      person: {id: personId},
    },
    personName,
  }) => (
    <Trans i18nKey="accountItem.title">
      <Link
        to={generatePath(personDetailDefaultPath, {id: personId})}
        highlight
      />
      {{accountNumber: hospitalAccountId}}
      {{personName}}
    </Trans>
  )
)

export default AccountTitle
