/* eslint-disable */
import {SlideApplicationRules as SlideApplicationRulesOrig} from '../types/SlideApplicationRules'
import {SlideApplicationRules_slideApplicationRules as SlideApplicationRules_slideApplicationRulesOrig} from '../types/SlideApplicationRules'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: SlideApplicationRules
// ====================================================
export interface SlideApplicationRules_slideApplicationRules {
  __typename: 'SlideApplicationRule'
  id: string
  ruleType: string
  ruleNumber: number
  ruleSequence: number
  incomePercentage: number
  plan: string
  copayInsuredUninsured: string | null
  reducedFee: string | null
}
export const deserializeSlideApplicationRules_slideApplicationRules = (
  serialized: SlideApplicationRules_slideApplicationRulesOrig
): SlideApplicationRules_slideApplicationRules => ({...serialized})
export const serializeSlideApplicationRules_slideApplicationRules = (
  deserialized: SlideApplicationRules_slideApplicationRules
): SlideApplicationRules_slideApplicationRulesOrig => ({...deserialized})
export interface SlideApplicationRules {
  /**
   * Slide Application Rules
   */
  slideApplicationRules: SlideApplicationRules_slideApplicationRules[]
}
export const deserializeSlideApplicationRules = (
  serialized: SlideApplicationRulesOrig
): SlideApplicationRules => ({
  ...serialized,
  slideApplicationRules: serialized.slideApplicationRules.map(
    deserializeSlideApplicationRules_slideApplicationRules
  ),
})
export const serializeSlideApplicationRules = (
  deserialized: SlideApplicationRules
): SlideApplicationRulesOrig => ({
  ...deserialized,
  slideApplicationRules: deserialized.slideApplicationRules.map(
    serializeSlideApplicationRules_slideApplicationRules
  ),
})
