import React, {FC} from 'react'
import parseISO from 'date-fns/parseISO'
import {flowMax, addDisplayName, addProps, addWrapper} from 'ad-hok'

import {Assert, SchemaDoesntHaveExtraFields} from 'utils/form/typeHelpers'
import {addUpdateApplicationMutation} from 'graphql/generated'
import {addApplicationFormContext} from 'components/EditApplicationForm/applicationFormContext'
import {Application_application_RyanWhiteApplication} from 'graphql/deserializedTypes/Application'
import {addClasses} from 'theme'
import {applicationFormClasses} from 'components/EditMedicaidApplicationForm/index'
import Form from 'components/Form'
import {editRyanWhiteApplicationFormSchema} from 'components/EditRyanWhiteApplicationForm/schema'
import {addFormikTyped} from 'utils/form/formik'
import {addApplicationBenefitOutcomesContextProvider} from 'components/EditApplicationForm/applicationBenefitOutcomesContext'
import Grid from 'components/Grid'
import ApplicationFormLeftColumn from 'components/EditApplicationForm/ApplicationFormLeftColumn'
import FormSection from 'components/FormSection'
import ReadOnlyTextField from 'components/ReadOnlyTextField'
import DateField from 'components/DateField'
import TextField from 'components/TextField'
import AccountOnDateOfServiceDetails from 'components/AccountOnDateOfServiceDetails'
import HouseholdSection from 'components/EditApplicationForm/HouseholdSection'
import OutcomesSection from 'components/EditApplicationForm/OutcomesSection'
import ApplicationStatusUpdateSelect from 'components/ApplicationStatusUpdateSelect'
import MultilineTextField from 'components/MultilineTextField'
import DataAndDocumentsSection from 'components/EditApplicationForm/DataAndDocumentsSection'
import AssignedToField from 'components/AssignedToField'
import FileTemplateSection from 'components/FileTemplateSection'
import EditableFilesSection from 'components/EditableFilesSection'
import DocumentRequestSection from 'components/EditApplicationForm/DocumentRequestSection'
import CommunicationHistorySection from 'components/EditApplicationForm/CommunicationHistorySection'
import EventLogSection from 'components/EditApplicationForm/EventLogSection'

type Check = Assert<
  SchemaDoesntHaveExtraFields<
    typeof editRyanWhiteApplicationFormSchema,
    typeof addUpdateApplicationMutation
  >
>

const EditRyanWhiteApplicationForm: FC = flowMax(
  addDisplayName('EditRyanWhiteApplicationForm'),
  addApplicationFormContext,
  addProps(({application}) => ({
    application: application as Application_application_RyanWhiteApplication,
  })),
  addProps(
    ({application}) => ({
      initialValues: {
        application: {
          ...application,
          assignedToId: application.assignedTo?.id ?? null,
        },
      },
    }),
    ['application']
  ),
  addClasses(applicationFormClasses),
  addWrapper(
    (
      render,
      {classes, onUpdateSuccess, mutateUpdateApplication, initialValues}
    ) => (
      <Form
        name="applicationForm"
        className={classes.form}
        schema={editRyanWhiteApplicationFormSchema}
        initialValues={initialValues}
        mutate={mutateUpdateApplication}
        onSubmitSuccess={onUpdateSuccess}
        shouldResetFormOnInitialValuesChange
        resetOnSaveSuccess
      >
        {render()}
      </Form>
    )
  ),
  addFormikTyped(editRyanWhiteApplicationFormSchema),
  addProps(
    ({
      application: {person},
      formik: {
        values: {
          application: {initialDateOfService},
        },
      },
    }) => ({
      initialDateOfService: initialDateOfService
        ? parseISO(initialDateOfService)
        : null,
      outcomesPeople: [person],
    })
  ),
  addApplicationBenefitOutcomesContextProvider,
  ({application, refetchQueriesOnStatusChange, classes}) => (
    <Grid container className={classes.container} direction="row">
      <ApplicationFormLeftColumn application={application} />
      <Grid item className={classes.formColumnContainer}>
        <FormSection
          labelTranslationKey="applicationProcessing"
          shouldMemoize={false}
        >
          <ReadOnlyTextField name="application.id" />
          <DateField name="application.initialDateOfService" />
          {application.accountOnDateOfService ? (
            <ReadOnlyTextField name="application.accountNumber" />
          ) : (
            <TextField name="application.accountNumber" />
          )}
          <AccountOnDateOfServiceDetails application={application} />
          <DateField name="application.dateOfApplication" />
          <TextField type="number" name="application.coveragePeriod" />
          <DateField name="application.receivedDate" />
        </FormSection>
        <HouseholdSection />
        <FileTemplateSection />
        <EditableFilesSection />
        <OutcomesSection />
        <DataAndDocumentsSection />
      </Grid>
      <Grid item className={classes.rightColumnContainer}>
        <ApplicationStatusUpdateSelect
          className={classes.statusSelect}
          application={application}
          refetchQueries={refetchQueriesOnStatusChange}
        />
        <AssignedToField
          name="application.assignedToId"
          assignable={application}
        />
        <MultilineTextField rows={2} rowsMax={20} name="application.notes" />
        <EventLogSection />
        <DocumentRequestSection />
        <CommunicationHistorySection />
      </Grid>
    </Grid>
  )
)

export default EditRyanWhiteApplicationForm
