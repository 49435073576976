import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addWrapper,
  addHandlers,
  addProps,
} from 'ad-hok'

import {makeFormSchema, FormCanonicalValuesFromSchema} from 'utils/form/schema'
import {makeTextField} from 'utils/form/fieldTypes'
import {addTranslationHelpers} from 'utils/i18n'
import {addClasses, makeClasses} from 'theme'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogContent from 'components/DialogContent'
import Form from 'components/Form'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import {addFormik} from 'utils/form/formik'
import TextField from 'components/TextField'
import DocumentFileUploader, {
  UploadedFile,
} from 'components/DocumentFileUploader'
import {addFieldError} from 'utils/field'
import FormHelperText from 'components/FormHelperText'
import {addDocumentFilesContext} from 'components/DocumentFiles/context'
import {
  addSubmitForm,
  addFormikSubmitFormCallback,
} from 'utils/addFormikHoistedState'

const classes = makeClasses((theme) => ({
  contentContainer: {
    width: 600,
  },
  error: {
    color: '#f44336',
    marginLeft: theme.spacing(2),
  },
}))

interface FileUploadFieldProps {
  accept?: string
}
const FileUploadField: FC<FileUploadFieldProps> = flowMax(
  addDisplayName('FileUploadField'),
  addProps({
    name: 'documentFile.fileKey',
  }),
  addFormik,
  addHandlers({
    onFileUpload: ({formik: {setFieldValue}, name}) => ({
      file: {fileKey},
    }: {
      file: UploadedFile
    }) => {
      setFieldValue(name, fileKey)
    },
    onFileRemove: ({formik: {setFieldValue}, name}) => () => {
      setFieldValue(name, '')
    },
  }),
  addFieldError,
  addClasses(classes),
  ({onFileUpload, onFileRemove, fieldError, classes, accept}) => (
    <>
      <DocumentFileUploader
        accept={accept || ''}
        onFileUpload={onFileUpload}
        onFileRemove={onFileRemove}
      />
      {fieldError && (
        <FormHelperText className={classes.error}>{fieldError}</FormHelperText>
      )}
    </>
  )
)

export const documentFileFormSchema = makeFormSchema({
  fields: {
    documentFile: {
      name: makeTextField({isRequired: true}),
      fileKey: makeTextField({isRequired: true}),
    },
  },
})

interface Props {
  open: boolean
  onClose: () => void
  accept?: string
  onSubmit: (opts: {
    canonicalValues: FormCanonicalValuesFromSchema<
      typeof documentFileFormSchema
    >
  }) => void
  isSubmitting: boolean
}

const DocumentFileDialog: FC<Props> = flowMax(
  addDisplayName('DocumentFileDialog'),
  addDocumentFilesContext,
  addSubmitForm,
  addTranslationHelpers,
  addClasses(classes),
  addWrapper(
    (
      render,
      {
        open,
        onClose,
        submitForm,
        onSubmit,
        documentFileDialogTitle,
        isSubmitting,
        classes,
        t,
      }
    ) => (
      <Dialog open={open} onClose={onClose} scroll="paper">
        <DialogTitle>{documentFileDialogTitle}</DialogTitle>
        <DialogContent className={classes.contentContainer}>
          <Form
            name="documentFileForm"
            schema={documentFileFormSchema}
            onSubmitSuccess={onSubmit}
          >
            {render()}
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t('documentFileDialog.cancel')}
          </Button>
          <Button
            onClick={submitForm}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {t('documentFileDialog.save')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  ),
  addFormikSubmitFormCallback,
  ({accept}) => (
    <>
      <TextField name="documentFile.name" />
      <FileUploadField accept={accept || ''} />
    </>
  )
)

export default DocumentFileDialog
