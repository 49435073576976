import React from 'react'
import {SimpleUnchangedProps, addWrapper} from 'ad-hok'

import AuthenticationErrorBoundary from 'components/AuthenticationErrorBoundary'

const addAuthenticationErrorBoundary: SimpleUnchangedProps = addWrapper(
  (render) => (
    <AuthenticationErrorBoundary>{render()}</AuthenticationErrorBoundary>
  )
)

export default addAuthenticationErrorBoundary
