import React from 'react'
import {FC} from 'react'
import {flowMax, addDisplayName} from 'ad-hok'

import Button from 'components/Button'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogActions from 'components/DialogActions'
import DialogContent from 'components/DialogContent'
import DialogContentText from 'components/DialogContentText'

interface AlertDialogProps {
  open: boolean
  onDismiss: () => void
  title: string
  dismissText: string
  message?: string
}

const AlertDialog: FC<AlertDialogProps> = flowMax(
  addDisplayName('AlertDialog'),
  ({open, onDismiss, title, dismissText, message}) => (
    <Dialog open={open} onClose={onDismiss} data-testid="confirmation-dialog">
      <DialogTitle>{title}</DialogTitle>
      {message && (
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onDismiss} color="primary">
          {dismissText}
        </Button>
      </DialogActions>
    </Dialog>
  )
)

export default AlertDialog
