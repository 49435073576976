import {flowMax, addEffect} from 'ad-hok'
import * as Sentry from '@sentry/browser'

import {addAuth0Context} from 'utils/auth0'
import {sentryEnabled} from 'config/sentry'

export const addSentrySetUser = flowMax(
  addAuth0Context,
  addEffect(({auth0Context: {isAuthenticated, user}}) => () => {
    if (isAuthenticated && user?.email) {
      if (sentryEnabled) {
        Sentry.configureScope((scope) => {
          scope.setUser({email: user.email})
        })
      }
    }
  })
)
