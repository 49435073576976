import React, {FC} from 'react'
import {flowMax, addDisplayName, addStateHandlers, addProps} from 'ad-hok'
import TableChartIcon from '@material-ui/icons/TableChart'
import {TFunction} from 'i18next'
import {isArray, flow, map, sum} from 'lodash/fp'
import cx from 'classnames'

import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import IconButton from 'components/IconButton'
import {getShortDate} from 'utils/date'
//import {getGenderAbbreviation} from 'utils/gender'
import Table from 'components/Table'
import TableBody from 'components/TableBody'
import TableRow from 'components/TableRow'
import TableCell from 'components/TableCell'
import {addTranslationHelpers} from 'utils/i18n'
import {makeClasses, addClasses} from 'theme'
import {orUnknown} from 'utils/string'
import {formatCurrency} from 'utils/number'
import TableContainer from 'components/TableContainer'
import Paper from 'components/Paper'
import TableHead from 'components/TableHead'
import {callWith} from 'utils/fp'
import {getAmountPerYear} from 'utils/income'
import DisplayItem from 'components/DisplayItem'
import {addFormCanonicalValuesContextTyped} from 'utils/form/context'
import {editPersonFormSchema} from 'components/EditPersonForm/schema'
import {FormCanonicalValues, ExtractFormSchemaFields} from 'utils/form/schema'
import {addRightColumnContext} from 'components/EditPersonForm/rightColumnContext'
import Grid from 'components/Grid'
import EligibilityDeterminationStatus from 'components/EligibilityDeterminationStatus'

const classes = makeClasses((theme) => ({
  rowLabelCell: {
    fontWeight: 'bold',
    verticalAlign: 'top',
    width: 180,
    minWidth: 180,
  },
  bold: {
    fontWeight: 'bold',
  },
  listCellItem: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  valueCell: {
    width: 240,
    minWidth: 240,
    verticalAlign: 'top',
  },
  paper: {
    maxWidth: '100%',
  },
  headerCell: {
    paddingBottom: 0,
    fontWeight: 400,
  },
  totalItem: {
    fontSize: 14,
  },
}))

interface PersonData {
  relationshipToPatientFormatted: string | null
  firstNameFormatted: string | null
  lastNameFormatted: string | null
  dobFormatted: string | null
  genderFormatted: string | null
  maritalStatusFormatted: string | null
  pregnantFormatted: string | null
  citizenshipStatusFormatted: string | null
  legalMoreThan5YearsFormatted: string | null
  incomesFormatted: string[] | string | null
  assetsFormatted: string[] | string | null
  supporterNameFormatted: string | null
  supporterRelationshipFormatted: string | null
}

type CanonicalValues = FormCanonicalValues<
  ExtractFormSchemaFields<typeof editPersonFormSchema>
>
type PersonType = CanonicalValues['person']

type PersonWithRelationshipType = (
  | PersonType
  | PersonType['relationships'][0]['otherPerson']
) & {
  relationshipType: string | null
}

const RELATIONSHIP_TYPE_SELF = 'Self'

const toYesNo = (value: boolean | null): string | null =>
  value ? 'Yes' : value === false ? 'No' : null

const formatIncomeSource = (
  {incomeType, amount, payFrequency}: PersonType['incomeSources'][0],
  t: TFunction
): string =>
  [
    incomeType,
    amount != null ? formatCurrency(amount, {noCentsIfZero: true}) : null,
    payFrequency,
  ]
    .map(orUnknown(t))
    .join(', ')

const formatAsset = (
  {assetType, amount}: PersonType['assets'][0],
  t: TFunction
): string =>
  [
    assetType,
    amount != null ? formatCurrency(amount, {noCentsIfZero: true}) : null,
  ]
    .map(orUnknown(t))
    .join(', ')

const getPersonData = (
  person: PersonWithRelationshipType,
  t: TFunction
): PersonData => ({
  relationshipToPatientFormatted: person.relationshipType,
  firstNameFormatted: person.firstName,
  lastNameFormatted: person.lastName,
  dobFormatted: person.dob
    ? getShortDate(person.dob, {showFullYear: true})
    : null,
  genderFormatted: person.gender ? person.gender : null,
  maritalStatusFormatted: person.maritalStatus,
  pregnantFormatted: toYesNo(person.pregnant),
  citizenshipStatusFormatted: person.ciStatus,
  legalMoreThan5YearsFormatted: toYesNo(person.fiveYearsInUs),
  incomesFormatted:
    person.incomeSources.length > 0
      ? person.incomeSources.map((incomeSource) =>
          formatIncomeSource(incomeSource, t)
        )
      : toYesNo(person.income),
  assetsFormatted:
    person.assets.length > 0
      ? person.assets.map((asset) => formatAsset(asset, t))
      : toYesNo(person.hasAsset),
  supporterNameFormatted: person.othersSupportWithoutIncome
    ? person.othersSupportWithoutIncome
    : null,
  supporterRelationshipFormatted: person.supporterRelationship
    ? person.supporterRelationship
    : null,
})

const getPeople = (person: PersonType): PersonWithRelationshipType[] => [
  {...person, relationshipType: RELATIONSHIP_TYPE_SELF},
  ...person.relationships.map(({relationshipType, otherPerson}) => ({
    ...otherPerson,
    relationshipType,
  })),
]

const getPeopleData = (person: PersonType, t: TFunction): PersonData[] =>
  getPeople(person).map((personObject) => getPersonData(personObject, t))

interface RowSpec {
  key: keyof PersonData
  className?: keyof ReturnType<typeof classes>
}

const ROWS: RowSpec[] = [
  {
    key: 'relationshipToPatientFormatted',
    className: 'bold',
  },
  {
    key: 'firstNameFormatted',
  },
  {
    key: 'lastNameFormatted',
  },
  {
    key: 'dobFormatted',
  },
  {
    key: 'genderFormatted',
  },
  {
    key: 'maritalStatusFormatted',
  },
  {
    key: 'pregnantFormatted',
  },
  {
    key: 'citizenshipStatusFormatted',
  },
  {
    key: 'legalMoreThan5YearsFormatted',
  },
  {
    key: 'incomesFormatted',
  },
  {
    key: 'assetsFormatted',
  },
  {
    key: 'supporterNameFormatted',
  },
  {
    key: 'supporterRelationshipFormatted',
  },
]

interface ProfileSummaryTableRowProps {
  rowSpec: RowSpec
  peopleData: PersonData[]
}

const ProfileSummaryTableRow: FC<ProfileSummaryTableRowProps> = flowMax(
  addDisplayName('ProfileSummaryTableRow'),
  addProps(({peopleData, rowSpec}) => ({
    peopleValues: peopleData.map((personData) => personData[rowSpec.key]),
  })),
  addTranslationHelpers,
  addClasses(classes),
  addProps(({rowSpec: {key, className}, classes, t}) => ({
    rowLabel: t(`profileSummary.rowLabels.${key}`),
    valueCellClassName: className ? classes[className] : undefined,
  })),
  ({peopleValues, rowLabel, valueCellClassName, classes}) => (
    <TableRow>
      <TableCell className={classes.rowLabelCell}>{rowLabel}</TableCell>
      {peopleValues.map((personValue, index) => (
        <TableCell
          className={cx(classes.valueCell, valueCellClassName)}
          key={index}
        >
          {isArray(personValue)
            ? personValue.map((item, index) => (
                <div className={classes.listCellItem} key={index}>
                  {item}
                </div>
              ))
            : personValue}
        </TableCell>
      ))}
    </TableRow>
  )
)

const ProfileSummaryTable: FC = flowMax(
  addDisplayName('ProfileSummaryTable'),
  addFormCanonicalValuesContextTyped(editPersonFormSchema),
  addTranslationHelpers,
  addProps(
    ({formCanonicalValues: {person}, t}) => ({
      peopleData: getPeopleData(person, t),
    }),
    ['formCanonicalValues', 't']
  ),
  addClasses(classes),
  ({peopleData, classes}) => (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerCell} />
            {peopleData.map((_, index) => (
              <TableCell className={classes.headerCell} key={index}>
                {index + 1}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {ROWS.map((rowSpec) => (
            <ProfileSummaryTableRow
              rowSpec={rowSpec}
              peopleData={peopleData}
              key={rowSpec.key}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
)

const getPersonTotalIncome = (person: PersonWithRelationshipType): number =>
  callWith(
    person.incomeSources,
    flow(
      map(({amount, payFrequency}) =>
        getAmountPerYear({amount, frequency: payFrequency})
      ),
      sum
    )
  )

const getTotalAnnualIncomeFormatted = (person: PersonType): string =>
  callWith(
    person,
    flow(getPeople, map(getPersonTotalIncome), sum, (total) =>
      formatCurrency(total, {noCentsIfZero: true})
    )
  )

const getPersonTotalAssets = (person: PersonWithRelationshipType): number =>
  callWith(
    person.assets,
    flow(
      map(({amount}) => amount),
      sum
    )
  )

const getTotalAssetsFormatted = (person: PersonType): string =>
  callWith(
    person,
    flow(getPeople, map(getPersonTotalAssets), sum, (total) =>
      formatCurrency(total, {noCentsIfZero: true})
    )
  )

const Totals: FC = flowMax(
  addDisplayName('Totals'),
  addFormCanonicalValuesContextTyped(editPersonFormSchema),
  addProps(
    ({formCanonicalValues: {person}}) => ({
      totalAnnualIncomeFormatted: getTotalAnnualIncomeFormatted(person),
      totalAssetsFormatted: getTotalAssetsFormatted(person),
    }),
    ['formCanonicalValues']
  ),
  addClasses(classes),
  ({totalAnnualIncomeFormatted, totalAssetsFormatted, classes}) => (
    <div>
      <DisplayItem
        i18nKey="profileSummary.totalAnnualIncomeDisplay"
        translations={{
          amount: totalAnnualIncomeFormatted,
        }}
        boldLabel
        className={classes.totalItem}
      />
      <DisplayItem
        i18nKey="profileSummary.totalAssetDisplay"
        translations={{
          amount: totalAssetsFormatted,
        }}
        boldLabel
        className={classes.totalItem}
      />
    </div>
  )
)

interface ProfileSummaryProps {
  open: boolean
  onClose: () => void
}

const ProfileSummary: FC<ProfileSummaryProps> = flowMax(
  addDisplayName('ProfileSummary'),
  addRightColumnContext,
  addClasses(classes),
  ({open, onClose, person: {mostRecentEligibilityDeterminations}, classes}) => (
    <Dialog open={open} onClose={onClose} classes={{paper: classes.paper}}>
      <DialogContent>
        <Grid container direction="row" justify="space-between">
          <Totals />
          <EligibilityDeterminationStatus
            labelKey="medicaid"
            benefits={['medicaid', 'medicaid-lastMonth']}
            mostRecentEligibilityDeterminations={
              mostRecentEligibilityDeterminations
            }
          />
        </Grid>
        <ProfileSummaryTable />
      </DialogContent>
    </Dialog>
  )
)

const ProfileSummaryButton: FC = flowMax(
  addDisplayName('ProfileSummaryButton'),
  addStateHandlers(
    {
      isShowingProfileSummary: false,
    },
    {
      onClick: () => () => ({
        isShowingProfileSummary: true,
      }),
      closeProfileSummary: () => () => ({
        isShowingProfileSummary: false,
      }),
    }
  ),
  ({onClick, closeProfileSummary, isShowingProfileSummary}) => (
    <>
      <IconButton onClick={onClick}>
        <TableChartIcon />
      </IconButton>
      <ProfileSummary
        open={isShowingProfileSummary}
        onClose={closeProfileSummary}
      />
    </>
  )
)

export default ProfileSummaryButton
