import React, {ComponentProps} from 'react'
import {TextField as BaseTextField} from 'formik-material-ui'
import BaseStandaloneTextField from '@material-ui/core/TextField'
import {FC} from 'react'

import Field from 'components/Field'

export interface FieldComponentProps {
  labelTranslationKey?: string
  type?: string
  name: string
  noIcon?: boolean
  noConfirmButton?: boolean
  lazyIcon?: boolean
  InputProps?: ComponentProps<typeof BaseTextField>['InputProps']
  disabled?: boolean
}

const TextField: FC<FieldComponentProps> = (props) => (
  <Field component={BaseTextField} exampleValue={'' as string} {...props} />
)

export default TextField

interface StandaloneTextFieldProps {
  label: string
  value: string
  onChange: (event: React.ChangeEvent<{value: string}>) => void
  className?: string
  disabled?: boolean
  placeholder?: string
  type?: string
}

export const StandaloneTextField: FC<StandaloneTextFieldProps> = ({
  className,
  type = 'text',
  ...props
}) => (
  <BaseStandaloneTextField
    type={type}
    variant="outlined"
    InputLabelProps={{shrink: true}}
    className={className}
    {...props}
  />
)
