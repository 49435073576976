import React, {FC} from 'react'
import {ApolloError} from 'apollo-boost'
import {flowMax, addDisplayName} from 'ad-hok'
import {addEffectOnMount} from 'ad-hok-utils'

import {isAuth0TokenInvalid} from 'config/apolloClient'
import {addSignOut} from 'utils/auth0'
import {addTranslationHelpers} from 'utils/i18n'

interface PerformLogoutProps {
  onResolvedError: () => void
}

const PerformLogout: FC<PerformLogoutProps> = flowMax(
  addDisplayName('PerformLogout'),
  addTranslationHelpers,
  addSignOut,
  addEffectOnMount(({onResolvedError, signOut}) => () => {
    signOut(onResolvedError)
  }),
  () => null
)

const isAuthenticationError = (error: Error | null) =>
  error instanceof ApolloError && isAuth0TokenInvalid(error)

interface Props {}

interface ErrorBoundaryState {
  hasError: boolean
}

class AuthenticationErrorBoundary extends React.Component<
  Props,
  ErrorBoundaryState
> {
  constructor(props: Props) {
    super(props)
    this.state = {hasError: false}
  }

  static getDerivedStateFromError(error: Error) {
    return {hasError: isAuthenticationError(error)}
  }

  componentDidCatch(error: Error | null /*errorInfo: object*/) {
    if (!isAuthenticationError(error)) throw error
  }

  onResolvedError = () => {
    this.setState({hasError: false})
  }

  render() {
    const {children} = this.props
    const {hasError} = this.state

    if (hasError) {
      return <PerformLogout onResolvedError={this.onResolvedError} />
    }

    return children
  }
}

export default AuthenticationErrorBoundary
