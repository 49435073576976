import React, {Fragment, FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addStateHandlers,
  addProps,
  addWrapper,
  branch,
  returns,
  addEffect,
  addHandlers,
} from 'ad-hok'
import {get, map, identity} from 'lodash/fp'
import {isEqual} from 'lodash'
import {format} from 'date-fns/fp'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import LaunchIcon from '@material-ui/icons/Launch'
import {generatePath} from 'react-router'
import {addDebouncedCopy, addExtendedHandlers} from 'ad-hok-utils'

import {makeClasses, addClasses} from 'theme'
import {addPersonMatchQuery} from 'graphql/generated'
import {addTranslationHelpers} from 'utils/i18n'
import Paper from 'components/Paper'
import Heading from 'components/Heading'
import {addLoadingIndicator} from 'utils/dataLoading'
import Body1 from 'components/Body1'
import List from 'components/List'
import ListItem from 'components/ListItem'
import ListItemText from 'components/ListItemText'
import Divider from 'components/Divider'
import Dialog from 'components/Dialog'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import DialogTitle from 'components/DialogTitle'
import DialogContent from 'components/DialogContent'
import DialogContentText from 'components/DialogContentText'
import {staticCreateAccountAndPatientFormSchema} from 'components/CreateAccountAndPatientForm/schema'
import ListItemIcon from 'components/ListItemIcon'
import ListItemSecondaryAction from 'components/ListItemSecondaryAction'
import Link from 'components/Link'
import {personDetailDefaultPath} from 'components/TopLevelRoutes'
import {formColumnWidth} from 'components/Form'
import {isField, FormValues, ExtractFormSchemaFields} from 'utils/form/schema'
import {addFormikTyped} from 'utils/form/formik'
import {getCanonicalValues, getObjectInitialValues} from 'utils/form/getValues'
import {getExtendedName} from 'utils/name'
import {getMaskedSsn} from 'utils/ssn'
import {
  PersonMatch_personMatch,
  PersonMatchVariables,
} from 'graphql/deserializedTypes/PersonMatch'

const classes = makeClasses((theme) => ({
  container: {
    position: 'absolute',
    left: formColumnWidth + theme.spacing(4),
    top: 0,
    width: 328,
    minHeight: 242,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
  },
  header: {
    fontSize: 20,
    lineHeight: '24px',
    letterSpacing: 0.15,
    fontWeight: 500,
  },
  subheader: {
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0.25,
  },
  emptyMessage: {
    fontSize: 17,
    lineHeight: '24px',
    letterSpacing: 0.15,
    fontStyle: 'italic',
    color: theme.palette.grey[800],
    marginTop: theme.spacing(3.5),
  },
  primaryInfo: {
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: 0.1,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  secondaryInfo: {
    fontSize: 12,
    lineHeight: '20px',
    letterSpacing: 0.25,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  unlinkButton: {
    marginBottom: theme.spacing(3),
  },
  matchingPersonDetailLinkContainer: {
    right: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  matchingPersonDetailLink: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: theme.spacing(3),
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: '#dadada',
    },
  },
  matchingPersonDetailLinkIconContainer: {
    minWidth: 48,
  },
}))

const personMatchFieldNames = [
  'firstName',
  'lastName',
  'dob',
  'ssn',
  'hospitalPatientId',
] as const

const PERSON_MATCHING_DEBOUNCE_INTERVAL = 300

type MatchedPerson = PersonMatch_personMatch

const getPrimaryDescription = getExtendedName

const getSecondaryDescription = ({
  ssn,
  dob,
  hospitalPatientId,
}: MatchedPerson) => {
  const chunks = []
  if (ssn) {
    chunks.push(getMaskedSsn(ssn))
  }
  if (dob) {
    chunks.push(`DOB ${format('MM/dd/yyyy')(dob)}`)
  }
  if (hospitalPatientId) {
    chunks.push(hospitalPatientId)
  }
  return chunks.join(', ')
}

type CreateAccountAndPatientFormValues = FormValues<
  ExtractFormSchemaFields<typeof staticCreateAccountAndPatientFormSchema>
>

type IsFormDirtyForLinkingType = (opts: {
  formValues: CreateAccountAndPatientFormValues
  matchedPerson: MatchedPerson
}) => boolean

const isCollectionDirty = (formCollection: any[], personCollection: any[]) => {
  if (formCollection.length === 0) return false
  return !isEqual(formCollection, personCollection)
}

const isFormDirtyForLinking: IsFormDirtyForLinkingType = ({
  formValues,
  matchedPerson,
}) => {
  const personFromForm = getCanonicalValues(
    formValues,
    staticCreateAccountAndPatientFormSchema
  ).person
  return Object.entries(personFromForm)
    .filter(([_name, value]) => !!value)
    .map(([name, formValue]) => {
      const fieldOrCollection = (staticCreateAccountAndPatientFormSchema.fields
        .person as any)[name]
      const matchedPersonValue = (matchedPerson as any)[name]
      return isField(fieldOrCollection)
        ? matchedPersonValue !== formValue
        : isCollectionDirty(formValue as any[], matchedPersonValue)
    })
    .some(identity)
}

type AddVariablesDebouncingType = <TProps extends {variables: any}>(
  props: TProps
) => TProps

const addVariablesDebouncing: AddVariablesDebouncingType = flowMax(
  addDebouncedCopy(
    PERSON_MATCHING_DEBOUNCE_INTERVAL,
    'variables',
    'variablesDebounced'
  ),
  addProps(({variablesDebounced: variables}) => ({
    variables,
  }))
)

interface Props {
  setIsPersonLinked: (isLinked: boolean) => void
}

const PersonMatcher: FC<Props> = flowMax(
  addDisplayName('PersonMatcher'),
  addFormikTyped(staticCreateAccountAndPatientFormSchema),
  addStateHandlers(
    {
      linkedPersonId: null as string | null,
      hasEditedPersonFieldsSinceLinking: false,
      shouldSuppressNextEditMarking: false,
    },
    {
      linkPerson: () => ({id}: {id: string}) => ({
        linkedPersonId: id,
        hasEditedPersonFieldsSinceLinking: false,
        shouldSuppressNextEditMarking: false,
      }),
      unlinkPerson: () => () => ({
        linkedPersonId: null,
      }),
      onEditPersonField: () => () => ({
        hasEditedPersonFieldsSinceLinking: true,
      }),
      markNotEditedSinceLinking: () => () => ({
        shouldSuppressNextEditMarking: true,
      }),
      unmarkNotEditedSinceLinking: () => () => ({
        shouldSuppressNextEditMarking: false,
      }),
    }
  ),
  // eslint-disable-next-line ad-hok/dependencies
  addEffect(
    ({linkedPersonId, setIsPersonLinked}) => () => {
      setIsPersonLinked(!!linkedPersonId)
    },
    ['linkedPersonId']
  ),
  // eslint-disable-next-line ad-hok/dependencies
  addEffect(
    ({
      onEditPersonField,
      shouldSuppressNextEditMarking,
      unmarkNotEditedSinceLinking,
    }) => () => {
      if (shouldSuppressNextEditMarking) {
        unmarkNotEditedSinceLinking()
        return
      }
      onEditPersonField()
    },
    // eslint-disable-next-line ad-hok/dependencies
    ['formik.values.person']
  ),
  addExtendedHandlers({
    linkPerson: ({
      formik: {values, setFieldValue},
      markNotEditedSinceLinking,
    }) => (linkedPerson: MatchedPerson) => {
      const personFormValues = values.person as FormValues<
        ExtractFormSchemaFields<typeof staticCreateAccountAndPatientFormSchema>
      >['person']

      const linkedPersonForForm = getObjectInitialValues(
        staticCreateAccountAndPatientFormSchema.fields.person,
        linkedPerson
      )

      Object.keys(personFormValues).forEach((fieldName) => {
        setFieldValue(
          `person.${fieldName}`,
          (linkedPersonForForm as any)[fieldName]
        )
      })
      markNotEditedSinceLinking()
    },
    unlinkPerson: ({formik: {values, setFieldValue}}) => () => {
      setFieldValue('person.id', '')
      const phoneNumbers = values.person.phoneNumbers as any[]
      phoneNumbers.forEach((phoneNumber, index) =>
        setFieldValue(`person.phoneNumbers[${index}].id`, '')
      )
    },
  }),
  addStateHandlers(
    {
      isLinkingConfirmationDialogOpen: false,
      pendingLinkedPerson: null as MatchedPerson | null,
    },
    {
      showLinkingConfirmationDialog: () => (person: MatchedPerson) => ({
        isLinkingConfirmationDialogOpen: true,
        pendingLinkedPerson: person,
      }),
      closeLinkingConfirmationDialog: () => () => ({
        isLinkingConfirmationDialogOpen: false,
        pendingLinkedPerson: null,
      }),
    }
  ),
  addHandlers({
    onPersonClick: ({
      linkPerson,
      hasEditedPersonFieldsSinceLinking,
      showLinkingConfirmationDialog,
      formik: {values},
    }) => (matchedPerson: MatchedPerson) => {
      if (
        !hasEditedPersonFieldsSinceLinking ||
        !isFormDirtyForLinking({
          formValues: values,
          matchedPerson,
        })
      ) {
        linkPerson(matchedPerson)
        return
      }
      showLinkingConfirmationDialog(matchedPerson)
    },
    onConfirmLinking: ({
      linkPerson,
      closeLinkingConfirmationDialog,
      pendingLinkedPerson,
    }) => () => {
      linkPerson(pendingLinkedPerson!)
      closeLinkingConfirmationDialog()
    },
  }),
  addProps(
    ({formik: {values, errors}}) => {
      const variables: PersonMatchVariables = {}
      for (let matchFieldName of personMatchFieldNames) {
        const fieldPath = `person.${matchFieldName}`
        const fieldValue = get(fieldPath)(values)
        const fieldSchema = get(fieldPath)(
          staticCreateAccountAndPatientFormSchema.fields
        )
        if (fieldValue && !get(fieldPath)(errors)) {
          try {
            variables[matchFieldName] = fieldSchema.mapToCanonical(fieldValue)
          } catch (e) {}
        }
      }
      return {variables}
    },
    [
      ...map((fieldName) => `formik.values.person.${fieldName}`)(
        personMatchFieldNames
      ),
      ...map((fieldName) => `formik.errors.person.${fieldName}`)(
        personMatchFieldNames
      ),
    ]
  ),
  addVariablesDebouncing,
  addPersonMatchQuery({
    variables: ({variables}) => variables,
  }),
  addClasses(classes),
  addTranslationHelpers,
  addWrapper((render, {classes, t, linkedPersonId, unlinkPerson}) => (
    <Paper
      elevation={3}
      className={classes.container}
      data-testid="person-matcher"
    >
      {!!linkedPersonId && (
        <Button
          onClick={unlinkPerson}
          startIcon={<LinkOffIcon />}
          color="primary"
          className={classes.unlinkButton}
        >
          {t('personMatcher.unlink')}
        </Button>
      )}
      <Heading variant="h4" className={classes.header}>
        {!!linkedPersonId
          ? t('personMatcher.headerLinked')
          : t('personMatcher.header')}
      </Heading>
      <Heading variant="h5" className={classes.subheader}>
        {t('personMatcher.subheader')}
      </Heading>
      {render()}
    </Paper>
  )),
  addLoadingIndicator({
    renderLoading: () => <span>loading</span>,
  }),
  addProps(
    ({personMatch, linkedPersonId}) => ({
      nonselectedMatchingPeople: personMatch.filter(
        ({id}) => !linkedPersonId || id !== linkedPersonId
      ),
    }),
    ['personMatch', 'linkedPersonId']
  ),
  branch(
    ({nonselectedMatchingPeople}) => !nonselectedMatchingPeople.length,
    returns(({classes, t}) => (
      <Body1 className={classes.emptyMessage}>{t('personMatcher.empty')}</Body1>
    ))
  ),
  ({
    nonselectedMatchingPeople,
    classes,
    onPersonClick,
    isLinkingConfirmationDialogOpen,
    closeLinkingConfirmationDialog,
    onConfirmLinking,
    t,
  }) => (
    <>
      <List>
        {nonselectedMatchingPeople.map((matchingPerson, index) => (
          <Fragment key={matchingPerson.id}>
            <ListItem
              button
              onClick={() => onPersonClick(matchingPerson)}
              disableGutters
            >
              <ListItemText
                primary={getPrimaryDescription({...matchingPerson, t})}
                secondary={getSecondaryDescription(matchingPerson)}
                classes={{
                  primary: classes.primaryInfo,
                  secondary: classes.secondaryInfo,
                }}
              />
              <ListItemSecondaryAction
                className={classes.matchingPersonDetailLinkContainer}
              >
                <Link
                  to={generatePath(personDetailDefaultPath, {
                    id: matchingPerson.id,
                  })}
                  target="_self"
                  className={classes.matchingPersonDetailLink}
                >
                  <ListItemIcon
                    className={classes.matchingPersonDetailLinkIconContainer}
                  >
                    <LaunchIcon />
                  </ListItemIcon>
                </Link>
              </ListItemSecondaryAction>
            </ListItem>
            {index !== nonselectedMatchingPeople.length - 1 && <Divider />}
          </Fragment>
        ))}
      </List>
      <Dialog
        open={isLinkingConfirmationDialogOpen}
        onClose={closeLinkingConfirmationDialog}
      >
        <DialogTitle>{t('personMatcher.confirmTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('personMatcher.confirmDescription')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeLinkingConfirmationDialog} color="primary">
            {t('personMatcher.cancelLinkingAction')}
          </Button>
          <Button onClick={onConfirmLinking} color="primary">
            {t('personMatcher.confirmLinkingAction')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
)

export default PersonMatcher
