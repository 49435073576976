import React, {FC} from 'react'
import {TFunction} from 'i18next'
import {
  flowMax,
  addDisplayName,
  addProps,
  branch,
  returns,
  addWrapper,
} from 'ad-hok'
import {addInterval} from 'ad-hok-utils'
import subYears from 'date-fns/subYears'
import subDays from 'date-fns/subDays'
import addDays from 'date-fns/addDays'

import addFilterControls, {makeFilters} from 'utils/addFilterControls'
import {
  TASK_STATUSES,
  TASK_STATUS_CLOSED,
} from 'components/TaskStatusUpdateSelect'
import {addTasksQuery, addUsersQuery} from 'graphql/generated'
import {
  WORKLIST_POLL_INTERVAL,
  addResultsLimitedMessage,
} from 'components/TriageWorklist'
import {WorklistNoResults} from 'components/ApplicationWorklist'
import TaskItem from 'components/TaskItem'
import {addTranslationHelpers} from 'utils/i18n'
import {addLoadingIndicator} from 'utils/dataLoading'
import {TASKS_QUERY} from 'graphql/queries'
import {TASK_TYPES, usersToSelectOptions} from 'components/TaskDialog'
import {makeClasses, addClasses} from 'theme'
import FilterChips from 'components/FilterChips'
import {UserFields} from 'graphql/deserializedTypes/UserFields'

const classes = makeClasses((theme) => ({
  selectField: {
    maxWidth: 280,
    marginBottom: theme.spacing(2),
  },
}))

interface DueDateRangeSpec {
  label: string
  value: string
  getDateRange: () => [Date, Date]
}

const DUE_DATE_RANGES: DueDateRangeSpec[] = [
  {
    label: 'Past due',
    value: 'past-due',
    getDateRange: () => [subYears(new Date(), 100), subDays(new Date(), 1)],
  },
  {
    label: 'Today',
    value: 'today',
    getDateRange: () => [new Date(), new Date()],
  },
  {
    label: 'Due within 7 days',
    value: 'due-within-7-days',
    getDateRange: () => [new Date(), addDays(new Date(), 7)],
  },
]

const getTaskFilters = ({users, t}: {users: UserFields[]; t: TFunction}) =>
  makeFilters({
    statuses: {
      type: 'multiselect',
      label: t('worklist.taskFilters.statuses'),
      options: TASK_STATUSES.map((status) => ({
        value: status,
        label: status,
        isClosed: status === TASK_STATUS_CLOSED,
      })),
    },
    taskTypes: {
      type: 'multiselect',
      label: t('worklist.taskFilters.taskTypes'),
      options: TASK_TYPES.map((status) => ({
        value: status,
        label: status,
      })),
    },
    assignedToIds: {
      type: 'multiselect',
      label: t('worklist.taskFilters.assignedToIds'),
      options: usersToSelectOptions(users),
    },
    dueDateRanges: {
      type: 'multiselect',
      label: t('worklist.taskFilters.dueDateRanges'),
      options: DUE_DATE_RANGES.map(({label, value}) => ({
        label,
        value,
      })),
    },
  })

const TaskWorklist: FC = flowMax(
  addDisplayName('TaskWorklist'),
  addUsersQuery({
    variables: () => ({
      includeBlockedUsersWithAssignedTasks: true,
    }),
  }),
  addLoadingIndicator({}),
  addTranslationHelpers,
  addProps(
    ({users, t}) => ({
      filters: getTaskFilters({users, t}),
      initialFilterValues: {
        statuses: [],
        taskTypes: [],
        assignedToIds: users
          .filter(({isCurrentUser}) => isCurrentUser)
          .map(({id}) => id),
        dueDateRanges: [],
      },
    }),
    ['users', 't']
  ),
  addClasses(classes),
  addFilterControls<ReturnType<typeof getTaskFilters>>({
    noFilterSelectionTranslationKey:
      'worklist.accountFilters.noFilterSelection',
    filterFieldClassName: ({classes}) => classes.selectField,
    persistenceKey: 'tasks',
  }),
  addProps(({filterValues: {dueDateRanges}, filterValues}) => ({
    variables: {
      ...filterValues,
      dueDateRanges: dueDateRanges
        .map((dueDateRangeValue) =>
          DUE_DATE_RANGES.find(({value}) => value === dueDateRangeValue)
        )
        .filter((x) => x)
        .map((dueDateRangeSpec) => dueDateRangeSpec!.getDateRange())
        .map(([start, end]) => ({start, end})),
    },
  })),
  addTasksQuery({
    variables: ({variables}) => variables,
  }),
  addInterval(
    ({variables, refetchTasks}) => () => {
      refetchTasks(variables)
    },
    WORKLIST_POLL_INTERVAL,
    ['variables']
  ),
  addLoadingIndicator({}),
  addWrapper((render, {filterValues: {taskTypes}, t}) => (
    <>
      {taskTypes.length > 0 && (
        <FilterChips
          labels={taskTypes}
          label={t('worklist.taskFilters.activeTaskTypeFilters')}
        />
      )}
      {render()}
    </>
  )),
  branch(
    ({tasks}) => !tasks.length,
    returns(() => <WorklistNoResults />)
  ),
  addProps(
    ({variables}) => ({
      refetchQueriesOnDeleteOrUpdate: [
        {
          query: TASKS_QUERY,
          variables,
        },
      ],
    }),
    ['variables']
  ),
  addResultsLimitedMessage('tasks'),
  ({tasks, refetchQueriesOnDeleteOrUpdate}) => (
    <>
      {tasks.map((task) => (
        <TaskItem
          task={task}
          refetchQueriesOnDeleteOrUpdate={refetchQueriesOnDeleteOrUpdate}
          showPersonName
          key={task.id}
        />
      ))}
    </>
  )
)

export default TaskWorklist
