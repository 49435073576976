import React, {FC} from 'react'
import {flowMax, addDisplayName} from 'ad-hok'
import {PureQueryOptions} from 'apollo-boost'
import EditIcon from '@material-ui/icons/Edit'

import {addTranslationHelpers} from 'utils/i18n'
import BenefitOutcomeFormDialog, {
  OutcomePerson,
  OutcomeApplication,
  BenefitOutcome,
} from 'components/BenefitOutcomeFormDialog'
import addDialogState from 'utils/addDialogState'
import IconButton from 'components/IconButton'
import {isBenefitOutcomeEditable} from 'utils/benefitOutcome'

interface Props {
  benefitOutcome: BenefitOutcome
  person: OutcomePerson
  application: OutcomeApplication
  refetchQueriesOnUpdate: PureQueryOptions[]
  userIsApprover: boolean
}

const EditBenefitOutcomeButton: FC<Props> = flowMax(
  addDisplayName('EditBenefitOutcomeButton'),
  addDialogState,
  addTranslationHelpers,
  ({
    benefitOutcome,
    person,
    application,
    showDialog,
    hideDialog,
    isShowingDialog,
    refetchQueriesOnUpdate,
    userIsApprover,
  }) => (
    <>
      <IconButton onClick={showDialog}>
        <EditIcon />
      </IconButton>
      <BenefitOutcomeFormDialog
        open={isShowingDialog}
        onSaveSuccess={hideDialog}
        onCancel={hideDialog}
        benefitOutcome={benefitOutcome}
        person={person}
        application={application}
        refetchQueriesOnCreateOrUpdate={refetchQueriesOnUpdate}
        userIsApprover={userIsApprover}
        editable={isBenefitOutcomeEditable({benefitOutcome, userIsApprover})}
      />
    </>
  )
)

export default EditBenefitOutcomeButton
