import React, {FC} from 'react'
import {flowMax, addDisplayName} from 'ad-hok'
import LockIcon from '@material-ui/icons/Lock'

import {addClasses, makeClasses} from 'theme'
import Alert from 'components/Alert'
import Button from 'components/Button'
import Grid from 'components/Grid'
import Tooltip from 'components/Tooltip'
import {addAuth0} from 'utils/auth0'
import {addTranslationHelpers} from 'utils/i18n'

const classes = makeClasses({
  error: {
    marginTop: 20,
  },
  container: {
    height: 105,
  },
  scriptLoadError: {
    margin: 20,
  },
})

const SignInControls: FC = flowMax(
  addDisplayName('SignInControls'),
  addClasses(classes),
  addTranslationHelpers,
  addAuth0,
  ({t, classes, auth0: {error, isLoading, loginWithPopup}}) => (
    <Grid
      className={classes.container}
      container
      direction="column"
      alignItems="center"
    >
      <Button
        disabled={isLoading}
        onClick={() => loginWithPopup({prompt: 'login'})}
        variant="contained"
        startIcon={<LockIcon />}
      >
        Sign In
      </Button>
      {error && (
        <Tooltip title={error.name || ''}>
          <Alert severity="error" className={classes.error}>
            {error.message || t('login.failed')}
          </Alert>
        </Tooltip>
      )}
    </Grid>
  )
)

export default SignInControls
