/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {UpdateAccountInput, NjmmisCheckResult} from './globalTypes' // ====================================================
// GraphQL mutation operation: UpdateAccount
// ====================================================

import {deserializeUpdateAccountInput} from './globalTypes'
import {serializeUpdateAccountInput} from './globalTypes'
import {UpdateAccountVariables as UpdateAccountVariablesOrig} from '../types/UpdateAccount'
import {UpdateAccount as UpdateAccountOrig} from '../types/UpdateAccount'
import {deserializeNjmmisCheckResult} from 'graphql/converters'
import {serializeNjmmisCheckResult} from 'graphql/converters'
import formatISO from 'date-fns/formatISO'
import {parseDate} from 'utils/form/fieldTypes'
import {formatISODate} from 'utils/date'
import {UpdateAccount_updateAccount as UpdateAccount_updateAccountOrig} from '../types/UpdateAccount'
export interface UpdateAccount_updateAccount {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
  timeOfService: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location: string | null
  insuranceType: string
  status: string
  createdAt: Date
  updatedAt: Date
  notes: string | null
  accountAmount: number | null
  njmmisCheck: string | null
  previousInsurance: string | null
  invoiceMonth: string | null
  invoiceYear: string | null
  dischargeDate: Date | null
  isScheduled: boolean
}
export const deserializeUpdateAccount_updateAccount = (
  serialized: UpdateAccount_updateAccountOrig
): UpdateAccount_updateAccount => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
})
export const serializeUpdateAccount_updateAccount = (
  deserialized: UpdateAccount_updateAccount
): UpdateAccount_updateAccountOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
})
export interface UpdateAccount {
  /**
   * Update existing account
   */
  updateAccount: UpdateAccount_updateAccount
}
export const deserializeUpdateAccount = (
  serialized: UpdateAccountOrig
): UpdateAccount => ({
  ...serialized,
  updateAccount: deserializeUpdateAccount_updateAccount(
    serialized.updateAccount
  ),
})
export const serializeUpdateAccount = (
  deserialized: UpdateAccount
): UpdateAccountOrig => ({
  ...deserialized,
  updateAccount: serializeUpdateAccount_updateAccount(
    deserialized.updateAccount
  ),
})
export interface UpdateAccountVariables {
  account: UpdateAccountInput
}
export const deserializeUpdateAccountVariables = (
  serialized: UpdateAccountVariablesOrig
): UpdateAccountVariables => ({
  ...serialized,
  account: deserializeUpdateAccountInput(serialized.account),
})
export const serializeUpdateAccountVariables = (
  deserialized: UpdateAccountVariables
): UpdateAccountVariablesOrig => ({
  ...deserialized,
  account: serializeUpdateAccountInput(deserialized.account),
})
