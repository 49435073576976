import {Benefit} from 'graphql/deserializedTypes/globalTypes'

export {Benefit as BenefitType}

export const getIsFacilitySpecificBenefitType = (benefit: Benefit) =>
  [Benefit.charityCare, Benefit.slide, Benefit.ryanWhite].includes(benefit)

export const getIsInitialDateOfServiceRequiredWhenCreatingBenefitType = (
  benefit: Benefit
) =>
  [
    Benefit.charityCare,
    Benefit.medicaid,
    Benefit.slide,
    Benefit.ryanWhite,
  ].includes(benefit)
