/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {TaskInput} from './globalTypes' // ====================================================
// GraphQL mutation operation: CreateTask
// ====================================================

import {deserializeTaskInput} from './globalTypes'
import {serializeTaskInput} from './globalTypes'
import {CreateTaskVariables as CreateTaskVariablesOrig} from '../types/CreateTask'
import {CreateTask as CreateTaskOrig} from '../types/CreateTask'
import formatISO from 'date-fns/formatISO'
import {parseDate} from 'utils/form/fieldTypes'
import {formatISODate} from 'utils/date'
import {CreateTask_createTask as CreateTask_createTaskOrig} from '../types/CreateTask'
import {CreateTask_createTask_linkedTo as CreateTask_createTask_linkedToOrig} from '../types/CreateTask'
import {CreateTask_createTask_createdBy as CreateTask_createTask_createdByOrig} from '../types/CreateTask'
import {CreateTask_createTask_assignedTo as CreateTask_createTask_assignedToOrig} from '../types/CreateTask'
export interface CreateTask_createTask_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeCreateTask_createTask_assignedTo = (
  serialized: CreateTask_createTask_assignedToOrig
): CreateTask_createTask_assignedTo => ({...serialized})
export const serializeCreateTask_createTask_assignedTo = (
  deserialized: CreateTask_createTask_assignedTo
): CreateTask_createTask_assignedToOrig => ({...deserialized})
export interface CreateTask_createTask_createdBy {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeCreateTask_createTask_createdBy = (
  serialized: CreateTask_createTask_createdByOrig
): CreateTask_createTask_createdBy => ({...serialized})
export const serializeCreateTask_createTask_createdBy = (
  deserialized: CreateTask_createTask_createdBy
): CreateTask_createTask_createdByOrig => ({...deserialized})
export interface CreateTask_createTask_linkedTo {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
}
export const deserializeCreateTask_createTask_linkedTo = (
  serialized: CreateTask_createTask_linkedToOrig
): CreateTask_createTask_linkedTo => ({...serialized})
export const serializeCreateTask_createTask_linkedTo = (
  deserialized: CreateTask_createTask_linkedTo
): CreateTask_createTask_linkedToOrig => ({...deserialized})
export interface CreateTask_createTask {
  __typename: 'Task'
  id: string
  taskType: string
  assignedTo: CreateTask_createTask_assignedTo
  createdBy: CreateTask_createTask_createdBy
  status: string
  note: string | null
  durationMin: number | null
  dueDate: Date
  linkedTo: CreateTask_createTask_linkedTo
  updatedAt: Date
}
export const deserializeCreateTask_createTask = (
  serialized: CreateTask_createTaskOrig
): CreateTask_createTask => ({
  ...serialized,
  assignedTo: deserializeCreateTask_createTask_assignedTo(
    serialized.assignedTo
  ),
  createdBy: deserializeCreateTask_createTask_createdBy(serialized.createdBy),
  dueDate: parseDate(serialized.dueDate),
  linkedTo: deserializeCreateTask_createTask_linkedTo(serialized.linkedTo),
  updatedAt: parseDate(serialized.updatedAt),
})
export const serializeCreateTask_createTask = (
  deserialized: CreateTask_createTask
): CreateTask_createTaskOrig => ({
  ...deserialized,
  assignedTo: serializeCreateTask_createTask_assignedTo(
    deserialized.assignedTo
  ),
  createdBy: serializeCreateTask_createTask_createdBy(deserialized.createdBy),
  dueDate: formatISODate(deserialized.dueDate),
  linkedTo: serializeCreateTask_createTask_linkedTo(deserialized.linkedTo),
  updatedAt: formatISO(deserialized.updatedAt),
})
export interface CreateTask {
  /**
   * Create a new task
   */
  createTask: CreateTask_createTask
}
export const deserializeCreateTask = (
  serialized: CreateTaskOrig
): CreateTask => ({
  ...serialized,
  createTask: deserializeCreateTask_createTask(serialized.createTask),
})
export const serializeCreateTask = (
  deserialized: CreateTask
): CreateTaskOrig => ({
  ...deserialized,
  createTask: serializeCreateTask_createTask(deserialized.createTask),
})
export interface CreateTaskVariables {
  task: TaskInput
}
export const deserializeCreateTaskVariables = (
  serialized: CreateTaskVariablesOrig
): CreateTaskVariables => ({
  ...serialized,
  task: deserializeTaskInput(serialized.task),
})
export const serializeCreateTaskVariables = (
  deserialized: CreateTaskVariables
): CreateTaskVariablesOrig => ({
  ...deserialized,
  task: serializeTaskInput(deserialized.task),
})
