/* eslint-disable */
import {UpdatePersonMessageReadVariables as UpdatePersonMessageReadVariablesOrig} from '../types/UpdatePersonMessageRead'
import {UpdatePersonMessageRead as UpdatePersonMessageReadOrig} from '../types/UpdatePersonMessageRead'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL mutation operation: UpdatePersonMessageRead
// ====================================================
export interface UpdatePersonMessageRead {
  updatePersonMessageRead: boolean
}
export const deserializeUpdatePersonMessageRead = (
  serialized: UpdatePersonMessageReadOrig
): UpdatePersonMessageRead => ({...serialized})
export const serializeUpdatePersonMessageRead = (
  deserialized: UpdatePersonMessageRead
): UpdatePersonMessageReadOrig => ({...deserialized})
export interface UpdatePersonMessageReadVariables {
  personId: string
}
export const deserializeUpdatePersonMessageReadVariables = (
  serialized: UpdatePersonMessageReadVariablesOrig
): UpdatePersonMessageReadVariables => ({...serialized})
export const serializeUpdatePersonMessageReadVariables = (
  deserialized: UpdatePersonMessageReadVariables
): UpdatePersonMessageReadVariablesOrig => ({...deserialized})
