import React, {FC} from 'react'
import {flowMax, addDisplayName} from 'ad-hok'
import {TFunction} from 'i18next'
import {some, identity} from 'lodash/fp'

import {addTranslationHelpers} from 'utils/i18n'
import DisplayItem from 'components/DisplayItem'
import {getCommaSeparated} from 'utils/string'
import {AccountFields} from 'graphql/deserializedTypes/AccountFields'

interface LocationOptions {
  facility?: string | null
  department?: string | null
  location?: string | null
}

const formatLocation = ({
  facility,
  department,
  location,
  t,
}: LocationOptions & {t: TFunction}) => {
  const locationFields = [facility, department, location]
  if (!some(identity, locationFields)) return t('triageItem.unknown')

  return getCommaSeparated(locationFields)
}

interface Props {
  account: AccountFields
}

const DisplayLocation: FC<Props> = flowMax(
  addDisplayName('DisplayLocation'),
  addTranslationHelpers,
  ({account, t}) => (
    <DisplayItem
      i18nKey="triageItem.location"
      translations={{
        location: formatLocation({
          ...account,
          t,
        }),
      }}
    />
  )
)

export default DisplayLocation
