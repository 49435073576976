import React from 'react'
import BaseFab from '@material-ui/core/Fab'
import cx from 'classnames'
import {flowMax, addDisplayName} from 'ad-hok'

import {makeClasses, addClasses} from 'theme'
import Container from 'components/Container'

const classes = makeClasses((/*theme*/) => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
  },
  fab: {
    position: 'absolute',
    pointerEvents: 'auto',
  },
}))

const Fab: typeof BaseFab = flowMax(
  addDisplayName('Fab'),
  addClasses(classes),
  ({className, classes, ...props}) => (
    <Container className={classes.container}>
      <BaseFab className={cx(classes.fab, className)} {...props} />
    </Container>
  )
) as typeof BaseFab

export default Fab
