import {TFunction} from 'i18next'
import {identity} from 'lodash'

export const getFormattedAddress = ({
  addressStreet,
  addressCity,
  addressState,
  addressZip,
  t,
}: {
  addressStreet?: string | null
  addressCity?: string | null
  addressState?: string | null
  addressZip?: string | null
  t: TFunction
}) =>
  !(addressStreet && addressCity && addressState && addressZip)
    ? t('general.unknown')
    : [
        addressStreet,
        addressCity,
        [addressState, addressZip].filter(identity).join(' '),
      ]
        .filter(identity)
        .join(', ')

export const getFormattedHomeAddress = ({
  homeAddressStreet,
  homeAddressCity,
  homeAddressState,
  homeAddressZip,
  t,
}: {
  homeAddressStreet?: string | null
  homeAddressCity?: string | null
  homeAddressState?: string | null
  homeAddressZip?: string | null
  t: TFunction
}) =>
  getFormattedAddress({
    addressStreet: homeAddressStreet,
    addressCity: homeAddressCity,
    addressState: homeAddressState,
    addressZip: homeAddressZip,
    t,
  })

export const getFormattedMailingAddress = ({
  mailingAddressStreet,
  mailingAddressCity,
  mailingAddressState,
  mailingAddressZip,
  t,
}: {
  mailingAddressStreet?: string | null
  mailingAddressCity?: string | null
  mailingAddressState?: string | null
  mailingAddressZip?: string | null
  t: TFunction
}) =>
  getFormattedAddress({
    addressStreet: mailingAddressStreet,
    addressCity: mailingAddressCity,
    addressState: mailingAddressState,
    addressZip: mailingAddressZip,
    t,
  })
