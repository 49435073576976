import React, {FC} from 'react'
import {
  flowMax,
  addWrapper,
  addHandlers,
  addProps,
  addDisplayName,
} from 'ad-hok'
import {MutationFunctionOptions} from '@apollo/react-common'

import {makeFormSchema, FormCanonicalValuesFromSchema} from 'utils/form/schema'
import {makeTextField} from 'utils/form/fieldTypes'
import {addTranslationHelpers} from 'utils/i18n'
import {addClasses, makeClasses} from 'theme'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogContent from 'components/DialogContent'
import Form from 'components/Form'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import {addFormik} from 'utils/form/formik'
import TextField from 'components/TextField'
import EditableFileUploader, {
  UploadedFile,
} from 'components/EditableFileUploader'
import {addFieldError} from 'utils/field'
import FormHelperText from 'components/FormHelperText'
import {
  addSubmitForm,
  addFormikSubmitFormCallback,
} from 'utils/addFormikHoistedState'
import {addCreateEditableFileMutation} from 'graphql/generated'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import HiddenField from 'components/HiddenField'

const classes = makeClasses((theme) => ({
  contentContainer: {
    width: 600,
  },
  error: {
    color: '#f44336',
    marginLeft: theme.spacing(2),
  },
}))

interface FileUploadFieldProps {
  personId: string
  uploadInstructionsText: string
}

const FileUploadField: FC<FileUploadFieldProps> = flowMax(
  addDisplayName('FileUploadField'),
  addProps({
    name: 'documentFile.fileKey',
  }),
  addFormik,
  addHandlers({
    onFileUpload: ({formik: {setFieldValue}, name}) => ({
      file: {fileKey, extension},
    }: {
      file: UploadedFile
    }) => {
      setFieldValue(name, fileKey)
      if (extension) {
        setFieldValue('documentFile.extension', extension)
      }
    },
    onFileRemove: ({formik: {setFieldValue}, name}) => () => {
      setFieldValue(name, '')
      setFieldValue('documentFile.extension', '')
    },
  }),
  addFieldError,
  addClasses(classes),
  ({
    personId,
    uploadInstructionsText,
    onFileUpload,
    onFileRemove,
    fieldError,
    classes,
  }) => (
    <>
      <EditableFileUploader
        personId={personId}
        uploadInstructionsText={uploadInstructionsText}
        onFileUpload={onFileUpload}
        onFileRemove={onFileRemove}
      />
      {fieldError && (
        <FormHelperText className={classes.error}>{fieldError}</FormHelperText>
      )}
    </>
  )
)

export const editableFileFormSchema = makeFormSchema({
  fields: {
    documentFile: {
      name: makeTextField({isRequired: true}),
      fileKey: makeTextField({isRequired: true}),
      extension: makeTextField({isRequired: false}),
    },
  },
})

interface Props {
  personId: string
  applicationId?: string
  open: boolean
  onClose: () => void
  uploadInstructionsText: string
  setNewEditableFileAsUnreviewed?: boolean
  refetchQueriesOnCreateEditableFileSuccess?: MutationFunctionOptions['refetchQueries']
}

const UploadEditableFileDialog: FC<Props> = flowMax(
  addDisplayName('EditableFileDialog'),
  addSubmitForm,
  addCreateEditableFileMutation({}),
  addTranslationHelpers,
  addAppSnackbarContext,
  addHandlers({
    onSubmit: ({
      personId,
      applicationId,
      mutateCreateEditableFile,
      onClose,
      showSnackbarMessage,
      t,
      refetchQueriesOnCreateEditableFileSuccess,
      setNewEditableFileAsUnreviewed,
    }) => async (values: {
      canonicalValues: FormCanonicalValuesFromSchema<
        typeof editableFileFormSchema
      >
    }) => {
      try {
        await mutateCreateEditableFile({
          variables: {
            editableFile: {
              personId,
              fileKey: values.canonicalValues.documentFile.fileKey,
              documentName: values.canonicalValues.documentFile.name,
              extension: values.canonicalValues.documentFile.extension,
            },
            applicationId,
            setUnreviewed: setNewEditableFileAsUnreviewed,
          },
          ...(refetchQueriesOnCreateEditableFileSuccess
            ? {
                refetchQueries: refetchQueriesOnCreateEditableFileSuccess,
              }
            : {}),
        })

        showSnackbarMessage(t('editableFiles.savedMessage'))
        onClose()
      } catch (error) {
        window.alert(t('editableFiles.failedToSave'))
      }
    },
  }),
  addClasses(classes),
  addWrapper((render, {open, onClose, submitForm, onSubmit, classes, t}) => (
    <Dialog open={open} onClose={onClose} scroll="paper">
      <DialogTitle>{t('editableFiles.addFile')}</DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <Form
          name="documentFileForm"
          schema={editableFileFormSchema}
          onSubmitSuccess={onSubmit}
        >
          {render()}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('documentFileDialog.cancel')}
        </Button>
        <Button onClick={submitForm} variant="contained" color="primary">
          {t('documentFileDialog.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )),
  addFormikSubmitFormCallback,
  ({personId, uploadInstructionsText}) => (
    <>
      <TextField name="documentFile.name" />
      <FileUploadField
        personId={personId}
        uploadInstructionsText={uploadInstructionsText}
      />
      <HiddenField name="documentFile.extension" />
    </>
  )
)

export default UploadEditableFileDialog
