import {
  get,
  flatMap,
  identity,
  map,
  filter,
  flow,
  uniqBy,
  sortBy,
} from 'lodash/fp'
import {uniq} from 'lodash'

import {
  NJ_RESIDENCY_DOC_OTHERS_ATTESTATION,
  NJ_RESIDENCY_DOC_CANT_PROVIDE,
  CI_DOCUMENT_CANT_PROVIDE,
  editPersonFormSchema,
  WHO_SUPPORT_WITHOUT_INCOME_OTHER,
  WHO_SUPPORT_WITHOUT_INCOME_SELF,
  RELATIONSHIP_TYPE_CHILD,
  RELATIONSHIP_TYPE_PARENT,
  RELATIONSHIP_TYPE_STEPCHILD,
  RELATIONSHIP_TYPE_STEPPARENT,
  IDENTITY_DOCUMENT_STATE_ID,
  IDENTITY_DOCUMENT_GOVERNMENT_ID,
  IDENTITY_DOCUMENT_PASSPORT,
  IDENTITY_DOCUMENT_MILITARY_ID,
  IDENTITY_DOCUMENT_SCHOOL_ID,
  IDENTITY_DOCUMENT_OTHER,
  IDENTITY_DOCUMENT_UTILITY,
  NJ_RESIDENCY_DOC_HOMELESS_ATTESTATION,
} from 'components/EditPersonForm/schema'
import {FormCanonicalValues, ExtractFormSchemaFields} from 'utils/form/schema'
import {
  INCOME_SOURCE_TYPE_SALARY_WAGES,
  INCOME_SOURCE_TYPE_FREELANCE,
  INCOME_SOURCE_TYPE_SELF_EMPLOYED,
  INCOME_SOURCE_TYPE_UNEMPLOYMENT,
  DEDUCTION_TYPE_ALIMONY,
  DEDUCTION_TYPE_STUDENT_LOAN_INTEREST,
  DEDUCTION_TYPE_HEALTH_SAVINGS_ACCOUNT,
  DEDUCTION_TYPE_IRA_DEDUCTION,
  DEDUCTION_TYPE_MOVING_EXPENSES,
  DEDUCTION_TYPE_EDUCATOR_EXPENSES,
  DEDUCTION_TYPE_SELF_EMPLOYMENT_TAX,
  DEDUCTION_TYPE_SELF_EMPLOYED_IRA,
  DEDUCTION_TYPE_SELF_EMPLOYED_HEALTH_INSURANCE,
  ASSET_TYPE_SAVINGS_ACCOUNTS,
  ASSET_TYPE_CHECKING_ACCOUNTS,
  ASSET_TYPE_EQUITY_IN_REAL_ESTATE,
  ASSET_TYPE_CERTIFICATES_OF_DEPOSIT_IRA,
  ASSET_TYPE_OTHER,
  INCOME_SOURCE_TYPE_CASH_SUPPORT_CHARITY_CARE,
} from 'utils/form/fieldTypes'
import {callWith} from 'utils/fp'
import {getValuesWithResolvedNames, isBlank} from 'utils/form/determiners'
import {isApplicationHouseholdFrozen} from 'utils/applicationStatuses'
import {BenefitType} from 'utils/benefits'
import {ApplicationFields} from 'graphql/deserializedTypes/ApplicationFields'
import {
  CHARITY_CARE_EXCLUDE_INCOME_SOURCE_TYPES,
  evaluateHouseholdIncomeFullCharityCare,
  evaluateHouseholdIncomeFullMedicaid,
  isPersonWithoutIncome,
} from 'utils/income'
import {
  determineHouseholdSizeFullCharityCare,
  determineHouseholdSizePartsFullCharityCare,
  determineHouseholdSizePartsFullMedicaid,
  findPersonHouseholdSizeFactor,
  HouseholdSizeFactor,
  isPersonInHousehold,
} from 'utils/householdSize'
import {isUnder18} from 'utils/age'

export interface RequiredDocument {
  documentType: string
  personId?: string
  applicationId: string
  relationshipIndex?: number
  isNecessary: boolean
}

interface DocumentRule {
  requiredIf: string
  getDocumentTypes: (opts: {
    value: any
    personValues: any
    values: any
    resolvedIndexes: number[]
    isClient: boolean
    isHouseholdMember: boolean
    application: any
  }) => string[]
  deduplicatePerPerson?: boolean
  checkClientOnly?: boolean
  isNecessary?: boolean
  isApplicationDocument?: boolean
}

const makeDocumentRule = (rule: DocumentRule) => rule

export const NJ_RESIDENCY_DOC_OTHERS_PROOF_OF_RESIDENCY_DOCUMENT =
  "Other's proof of residency"

const VERIFICATION_OF_SUPPORT_FROM_OTHERS_DOCUMENT =
  'Verification of support from others'

export const PATIENT_ATTESTATION_OF_SELF_SUPPORT_DOCUMENT =
  'Patient Attestation of Self-Support'

const documentRulesMedicaid = [
  makeDocumentRule({
    requiredIf: 'njResidencyDoc',
    getDocumentTypes: ({value}: any) =>
      value === NJ_RESIDENCY_DOC_OTHERS_ATTESTATION
        ? [value, NJ_RESIDENCY_DOC_OTHERS_PROOF_OF_RESIDENCY_DOCUMENT]
        : [
            NJ_RESIDENCY_DOC_CANT_PROVIDE,
            NJ_RESIDENCY_DOC_HOMELESS_ATTESTATION,
          ].includes(value)
        ? []
        : [value],
  }),
  makeDocumentRule({
    requiredIf: 'ciDocumentType',
    getDocumentTypes: ({value}: any) =>
      value === CI_DOCUMENT_CANT_PROVIDE ? [] : [value],
  }),
  makeDocumentRule({
    requiredIf: 'incomeSources[].incomeType',
    getDocumentTypes: ({value, personValues, resolvedIndexes}: any) => {
      const incomeSource = get(
        `incomeSources[${resolvedIndexes[0]}]`,
        personValues
      )
      const useEmployerName = incomeSource.employerName || '<unknown>'
      if (value === INCOME_SOURCE_TYPE_SALARY_WAGES)
        return [`Paystub/employer letter - ${useEmployerName}`]
      if (
        [
          INCOME_SOURCE_TYPE_FREELANCE,
          INCOME_SOURCE_TYPE_SELF_EMPLOYED,
        ].includes(value)
      )
        return [`Profit & Loss - ${useEmployerName}`]
      if (value === INCOME_SOURCE_TYPE_UNEMPLOYMENT)
        return ['Unemployment Award Letter']
      return []
    },
    deduplicatePerPerson: true,
    isNecessary: true,
  }),
  makeDocumentRule({
    requiredIf: 'deductions[].deductionType',
    getDocumentTypes: ({value}: any) => {
      if (value === DEDUCTION_TYPE_ALIMONY) return ['Alimony agreement']
      if (value === DEDUCTION_TYPE_STUDENT_LOAN_INTEREST)
        return ['Student loan payment statement or taxes']
      if (value === DEDUCTION_TYPE_HEALTH_SAVINGS_ACCOUNT)
        return ['Contribution history or payroll deduction for HSA']
      if (value === DEDUCTION_TYPE_IRA_DEDUCTION)
        return ['Payroll deduction to show IRA']
      if (value === DEDUCTION_TYPE_MOVING_EXPENSES)
        return ['Paystub showing relocation reimbursement']
      if (value === DEDUCTION_TYPE_EDUCATOR_EXPENSES)
        return ['Paystub showing educator expenses reimbursement']
      if (
        [
          DEDUCTION_TYPE_SELF_EMPLOYMENT_TAX,
          DEDUCTION_TYPE_SELF_EMPLOYED_IRA,
        ].includes(value)
      )
        return ['Latest tax return']
      if (value === DEDUCTION_TYPE_SELF_EMPLOYED_HEALTH_INSURANCE)
        return ['Premium contribution history or latest tax return']
      return []
    },
    deduplicatePerPerson: true,
  }),
  makeDocumentRule({
    requiredIf: 'whoSupportWithoutIncome',
    getDocumentTypes: ({
      value,
      values,
      personValues: {wantsCoverage},
      isClient,
    }: any) => {
      if (
        !(
          evaluateHouseholdIncomeFullMedicaid({
            values,
            lastMonth: true,
          }).netIncome === 0 ||
          evaluateHouseholdIncomeFullMedicaid({
            values,
          }).netIncome === 0
        )
      )
        return []
      if (!isClient && !wantsCoverage) return []
      if (value === WHO_SUPPORT_WITHOUT_INCOME_OTHER)
        return [VERIFICATION_OF_SUPPORT_FROM_OTHERS_DOCUMENT]
      if (value === WHO_SUPPORT_WITHOUT_INCOME_SELF)
        return [PATIENT_ATTESTATION_OF_SELF_SUPPORT_DOCUMENT]
      return []
    },
  }),
  makeDocumentRule({
    requiredIf: 'taxFilingStatus',
    getDocumentTypes: ({personValues}: any) => {
      const householdMembers = determineHouseholdSizePartsFullMedicaid({
        person: personValues,
      }).flatMap(([_factor, people]) => people)
      const hasChildOrParentInHousehold = householdMembers.some(
        (householdMember) =>
          !isPersonWithoutIncome(householdMember) &&
          [
            RELATIONSHIP_TYPE_CHILD,
            RELATIONSHIP_TYPE_PARENT,
            RELATIONSHIP_TYPE_STEPCHILD,
            RELATIONSHIP_TYPE_STEPPARENT,
          ].includes(householdMember.relationshipType!)
      )
      if (hasChildOrParentInHousehold)
        return ['NJFC Certification of Identity Form']
      return []
    },
    deduplicatePerPerson: true,
    checkClientOnly: true,
    isApplicationDocument: true,
  }),
  makeDocumentRule({
    requiredIf: 'identityDocument',
    getDocumentTypes: ({value}: any) => {
      if (
        [
          IDENTITY_DOCUMENT_STATE_ID,
          IDENTITY_DOCUMENT_GOVERNMENT_ID,
          IDENTITY_DOCUMENT_PASSPORT,
          IDENTITY_DOCUMENT_MILITARY_ID,
          IDENTITY_DOCUMENT_SCHOOL_ID,
        ].includes(value)
      )
        return [value]
      return []
    },
  }),
  makeDocumentRule({
    requiredIf: 'id',
    getDocumentTypes: () => ['DAR'],
    checkClientOnly: true,
    isNecessary: true,
    isApplicationDocument: true,
  }),
  makeDocumentRule({
    requiredIf: 'id',
    getDocumentTypes: () => ['Sage Consent'],
    checkClientOnly: true,
    isNecessary: true,
  }),
]

const documentRulesMonitor = [
  makeDocumentRule({
    requiredIf: 'id',
    getDocumentTypes: () => ['DAR'],
    checkClientOnly: true,
    isNecessary: true,
    isApplicationDocument: true,
  }),
  makeDocumentRule({
    requiredIf: 'id',
    getDocumentTypes: () => ['Sage Consent'],
    checkClientOnly: true,
    isNecessary: true,
  }),
]

const documentRulesCharityCare = [
  makeDocumentRule({
    requiredIf: 'id',
    getDocumentTypes: () => ['Sage Consent'],
    checkClientOnly: true,
    isNecessary: true,
  }),
  makeDocumentRule({
    requiredIf: 'id',
    getDocumentTypes: () => [
      'CC certification form',
      'Charity Care application form',
      'CC determination letter',
      'NJMMIS check',
      'UB04',
    ],
    checkClientOnly: true,
    isNecessary: true,
    isApplicationDocument: true,
  }),
  makeDocumentRule({
    requiredIf: 'id',
    getDocumentTypes: ({personValues}: any) => {
      const householdSize = determineHouseholdSizeFullCharityCare({
        person: personValues,
      })
      return householdSize && householdSize > 1
        ? ['Proof of household relationships']
        : []
    },
    checkClientOnly: true,
    isNecessary: true,
    isApplicationDocument: true,
  }),
  makeDocumentRule({
    requiredIf: 'assets[].assetType',
    getDocumentTypes: ({value}: any) => {
      if (
        [ASSET_TYPE_SAVINGS_ACCOUNTS, ASSET_TYPE_CHECKING_ACCOUNTS].includes(
          value
        )
      )
        return ['Bank account statement']
      if (value === ASSET_TYPE_EQUITY_IN_REAL_ESTATE)
        return ['Mortgage document']
      if (value === ASSET_TYPE_CERTIFICATES_OF_DEPOSIT_IRA)
        return ['401k/pension']
      if (value === ASSET_TYPE_OTHER) return ['Other asset documents']
      return []
    },
    deduplicatePerPerson: true,
    isNecessary: true,
  }),
  makeDocumentRule({
    requiredIf: 'identityDocument',
    getDocumentTypes: ({value}: any) => {
      if (value && value !== IDENTITY_DOCUMENT_OTHER) return [value]
      return []
    },
    isNecessary: true,
  }),
  makeDocumentRule({
    requiredIf: 'incomeSources[].incomeType',
    getDocumentTypes: ({value, personValues, resolvedIndexes}: any) => {
      const incomeSource = get(
        `incomeSources[${resolvedIndexes[0]}]`,
        personValues
      )
      const useEmployerName = incomeSource.employerName || '<unknown>'
      if (value === INCOME_SOURCE_TYPE_SALARY_WAGES)
        return [`Paystub/employer letter - ${useEmployerName}`]
      if (value === INCOME_SOURCE_TYPE_FREELANCE)
        return [`Employer letter - ${useEmployerName}`]
      if (value === INCOME_SOURCE_TYPE_SELF_EMPLOYED)
        return [
          `Profit & Loss - ${useEmployerName}`,
          '3 months of bank statements',
        ]
      if (value === INCOME_SOURCE_TYPE_UNEMPLOYMENT)
        return ['Unemployment Award Letter']
      if (value === INCOME_SOURCE_TYPE_CASH_SUPPORT_CHARITY_CARE)
        return [VERIFICATION_OF_SUPPORT_FROM_OTHERS_DOCUMENT]
      if (CHARITY_CARE_EXCLUDE_INCOME_SOURCE_TYPES.includes(value)) return []
      if (!value) return []
      return [`Proof of income - ${value}`]
    },
    deduplicatePerPerson: true,
    isNecessary: true,
  }),
  makeDocumentRule({
    requiredIf: 'njResidencyDoc',
    getDocumentTypes: ({value}: any) =>
      [
        IDENTITY_DOCUMENT_STATE_ID,
        IDENTITY_DOCUMENT_UTILITY,
        NJ_RESIDENCY_DOC_OTHERS_ATTESTATION,
        NJ_RESIDENCY_DOC_HOMELESS_ATTESTATION,
      ].includes(value)
        ? [value]
        : [],
    isNecessary: true,
    checkClientOnly: true,
  }),
  makeDocumentRule({
    requiredIf: 'isStudent',
    getDocumentTypes: ({
      value,
      personValues: {dob},
      personValues,
      values,
      isClient,
    }: any) => {
      if (!value) return []
      if (!(dob && !isUnder18(dob))) return []
      const foundFactor = findPersonHouseholdSizeFactor(
        BenefitType.charityCare
      )(values, personValues)
      if (!foundFactor) return []
      const householdSizeParts = determineHouseholdSizePartsFullCharityCare(
        values
      )
      const parentsLivingWith =
        householdSizeParts.find(
          ([factor]) => factor === 'parentsLivingWith'
        )?.[1] ?? []
      if (isClient && parentsLivingWith.length === 0) return []
      const factorsToInclude: HouseholdSizeFactor[] = [
        'childrenLivingWith',
        'siblingsLivingWith',
      ]
      if (!isClient && !factorsToInclude.includes(foundFactor)) return []
      return ['School schedule/payment', IDENTITY_DOCUMENT_SCHOOL_ID]
    },
    isNecessary: true,
  }),
  makeDocumentRule({
    requiredIf: 'whoSupportWithoutIncome',
    getDocumentTypes: ({
      value,
      isClient,
      values,
      application: {initialDateOfService},
    }: any) => {
      if (!isClient) return []
      if (!initialDateOfService) return []
      const {netIncome} = evaluateHouseholdIncomeFullCharityCare({
        values,
        initialDateOfService,
      })
      if (netIncome > 0) return []
      if (value === WHO_SUPPORT_WITHOUT_INCOME_OTHER)
        return [VERIFICATION_OF_SUPPORT_FROM_OTHERS_DOCUMENT]
      if (value === WHO_SUPPORT_WITHOUT_INCOME_SELF)
        return [PATIENT_ATTESTATION_OF_SELF_SUPPORT_DOCUMENT]
      return []
    },
  }),
]

const deduplicationsPerPersonAcrossRules = [
  IDENTITY_DOCUMENT_STATE_ID,
  IDENTITY_DOCUMENT_SCHOOL_ID,
  IDENTITY_DOCUMENT_UTILITY,
  VERIFICATION_OF_SUPPORT_FROM_OTHERS_DOCUMENT,
]

const deduplicatePerPersonAcrossRules = (
  documents: RequiredDocument[]
): RequiredDocument[] => {
  let counter = 0
  return uniqBy(
    ({documentType, personId, relationshipIndex, applicationId}) =>
      `${
        deduplicationsPerPersonAcrossRules.includes(documentType)
          ? documentType
          : `unique-${counter++}`
      }-${
        personId ?? `relationshipIndex-${relationshipIndex}`
      }-${applicationId}`,
    documents
  )
}

type ApplicationType = ApplicationFields & {
  householdMembers: {
    person: {
      id: string
    }
  }[]
}

type FormCanonicalValuesType = FormCanonicalValues<
  ExtractFormSchemaFields<typeof editPersonFormSchema>
>
type PersonCanonicalValuesType = FormCanonicalValuesType['person']

type RunRulesType = (
  rules: DocumentRule[],
  personCanonicalValues: PersonCanonicalValuesType
) => (applications: ApplicationType[]) => RequiredDocument[]

const runRules: RunRulesType = (rules, personCanonicalValues) =>
  flow(
    flatMap((application) => {
      const {id: applicationId} = application
      let householdMemberIds: string[] | null = null
      if (isApplicationHouseholdFrozen(application)) {
        householdMemberIds = application.householdMembers.map(
          ({person: {id}}) => id
        )
      }
      return rules.flatMap(
        ({
          requiredIf,
          getDocumentTypes,
          deduplicatePerPerson,
          checkClientOnly,
          isNecessary,
          isApplicationDocument,
        }) => {
          const personValuesWithResolvedNames = getValuesWithResolvedNames({
            canonicalValues: personCanonicalValues,
            name: requiredIf,
          })
          const personDocuments = callWith(
            personValuesWithResolvedNames,
            flow(
              flatMap(({value, resolvedIndexes}) => {
                const personMatches = !isBlank(value)
                return personMatches
                  ? getDocumentTypes({
                      value,
                      personValues: personCanonicalValues,
                      values: {
                        person: personCanonicalValues,
                      },
                      resolvedIndexes,
                      isClient: true,
                      isHouseholdMember: true,
                      application,
                    })
                  : []
              }),
              deduplicatePerPerson ? uniq : identity,
              map((documentType) => ({
                documentType,
                personId: isApplicationDocument
                  ? undefined
                  : personCanonicalValues.id,
                applicationId,
                isNecessary: !!isNecessary,
              }))
            )
          )

          const relatedPeopleDocuments = checkClientOnly
            ? []
            : personCanonicalValues.relationships
                .map(({otherPerson}) => otherPerson)
                .flatMap((relatedPerson, relatedPersonIndex) => {
                  const relatedPersonFormValues = get(
                    `relationships[${relatedPersonIndex}].otherPerson`,
                    personCanonicalValues
                  )
                  const relatedPersonValuesWithResolvedNames = getValuesWithResolvedNames(
                    {
                      canonicalValues: relatedPersonFormValues,
                      name: requiredIf,
                    }
                  )
                  const isHouseholdMember = householdMemberIds
                    ? !!relatedPerson.id &&
                      householdMemberIds.includes(relatedPerson.id)
                    : isPersonInHousehold(application.benefit)(
                        {person: personCanonicalValues},
                        relatedPerson
                      )
                  return callWith(
                    relatedPersonValuesWithResolvedNames,
                    flow(
                      flatMap(({value, resolvedIndexes}) => {
                        const relatedPersonMatches =
                          !isBlank(value) && isHouseholdMember
                        return relatedPersonMatches
                          ? getDocumentTypes({
                              value,
                              personValues: relatedPersonFormValues,
                              values: {
                                person: personCanonicalValues,
                              },
                              resolvedIndexes,
                              isClient: false,
                              isHouseholdMember,
                              application,
                            })
                          : []
                      }),
                      deduplicatePerPerson ? uniq : identity,
                      map((documentType) => ({
                        documentType,
                        applicationId,
                        personId: relatedPersonFormValues.id,
                        relationshipIndex: relatedPersonIndex,
                        isNecessary: !!isNecessary,
                      }))
                    )
                  )
                })

          return [...personDocuments, ...relatedPeopleDocuments]
        }
      )
    }),
    deduplicatePerPersonAcrossRules,
    sortBy((document) =>
      'relationshipIndex' in document ? document.relationshipIndex : -1
    )
  )

export const determineDocuments = (
  {person: personCanonicalValues}: FormCanonicalValuesType,
  applications: ApplicationType[]
): RequiredDocument[] => [
  ...callWith(
    applications,
    flow(
      filter(({benefit}) => benefit === 'medicaid'),
      runRules(documentRulesMedicaid, personCanonicalValues)
    )
  ),
  ...callWith(
    applications,
    flow(
      filter(({benefit}) => benefit === 'monitor'),
      runRules(documentRulesMonitor, personCanonicalValues)
    )
  ),
  ...callWith(
    applications,
    flow(
      filter(({benefit}) => benefit === 'charityCare'),
      runRules(documentRulesCharityCare, personCanonicalValues)
    )
  ),
]
