import React, {FC} from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  flowMax,
  addDisplayName,
  addProps,
  addStateHandlers,
  addHandlers,
  addWrapper,
  AddWrapperRenderCallback,
  addMemoBoundary,
} from 'ad-hok'
import {branchIfNullish} from 'ad-hok-utils'

import {FormValues, ExtractFormSchemaFields} from 'utils/form/schema'
import {editPersonFormSchema} from 'components/EditPersonForm/schema'
import FieldArray from 'components/FieldArray'
import {addClasses, makeClasses} from 'theme'
import {addTranslationHelpers} from 'utils/i18n'
import IconButton from 'components/IconButton'
import SelectField from 'components/SelectField'
import FormPrefix from 'components/FormPrefix'
import FormSection from 'components/FormSection'
import {
  SharedPersonDataFields,
  SharedPersonSections,
} from 'components/EditPersonForm/sharedFields'
import {addFormikTyped} from 'utils/form/formik'
import OptionalBooleanField from 'components/OptionalBooleanField'
import ConfirmationDialog from 'components/ConfirmationDialog'
import {getExtendedName} from 'utils/name'
import PersonSectionHeader from 'components/EditPersonForm/PersonSectionHeader'
import zIndices from 'utils/zIndices'
import {addFormContext} from 'utils/form/context'
import {addRightColumnContext} from 'components/EditPersonForm/rightColumnContext'
import {Person_person} from 'graphql/deserializedTypes/Person'

const classes = makeClasses((theme) => ({
  container: {
    marginTop: theme.spacing(5),
  },
  deleteIconOuterContainer: {
    position: 'relative',
    marginBottom: theme.spacing(2),
  },
  deleteIconContainer: {
    position: 'absolute',
    top: -40,
    right: 12,
    zIndex: zIndices.relatedPersonDeleteIcon,
  },
}))

export const getRelationshipPrefix = (relationshipIndex: number) =>
  `person.relationships[${relationshipIndex}]`

interface RelationshipFormProps {
  relationship: FormValues<
    ExtractFormSchemaFields<typeof editPersonFormSchema>
  >['person']['relationships'][0]
  index: number
}

export const getNumOpenApplications = ({
  relationshipIndex: index,
  person: {id: clientId, relationships},
}: {
  relationshipIndex: number
  person: Person_person
}): number => {
  if (index >= relationships.length) return 0
  const {
    otherPerson: {openApplications, openHouseholdMemberApplications},
  } = relationships[index]
  return (
    openApplications.length +
    openHouseholdMemberApplications.filter(({person}) => person.id !== clientId)
      .length
  )
}

const RelationshipForm: FC<RelationshipFormProps> = flowMax(
  addDisplayName('RelationshipForm'),
  addTranslationHelpers,
  addProps(
    ({relationship: {otherPerson: person}, t}) => ({
      name: `${getExtendedName({...person, t})}${
        person.id ? ` (${person.id})` : ''
      }`,
      id: person.id,
    }),
    ['relationship.otherPerson', 't']
  ),
  addWrapper(
    (
      render: AddWrapperRenderCallback<{
        deleteRelationship: () => void
      }>,
      {index}
    ) => (
      <FieldArray
        name="person.relationships"
        render={({remove}) =>
          render({
            deleteRelationship: () => {
              remove(index)
            },
          })
        }
      />
    )
  ),
  addRightColumnContext,
  addProps(
    ({index, person}) => ({
      numOpenApplications: getNumOpenApplications({
        relationshipIndex: index,
        person,
      }),
    }),
    ['index', 'person']
  ),
  addFormContext,
  addMemoBoundary([
    'name',
    'id',
    'index',
    'hasCharityCareApplication',
    'numOpenApplications',
  ]),
  addProps(
    ({index}) => ({
      prefix: getRelationshipPrefix(index),
    }),
    ['index']
  ),
  addStateHandlers(
    {
      isDeleteConfirmationDialogOpen: false,
    },
    {
      showDeleteConfirmationDialog: () => () => ({
        isDeleteConfirmationDialogOpen: true,
      }),
      closeDeleteConfirmationDialog: () => () => ({
        isDeleteConfirmationDialogOpen: false,
      }),
    }
  ),
  addHandlers({
    onConfirm: ({
      closeDeleteConfirmationDialog,
      deleteRelationship,
      index,
      removeCollectionItemFieldsFreshness,
      removeCollectionItemFieldsUpdatedAt,
    }) => () => {
      removeCollectionItemFieldsFreshness('person.relationships', index)
      removeCollectionItemFieldsUpdatedAt('person.relationships', index)
      deleteRelationship()
      closeDeleteConfirmationDialog()
    },
  }),
  addClasses(classes),
  ({
    prefix,
    isDeleteConfirmationDialogOpen,
    showDeleteConfirmationDialog,
    closeDeleteConfirmationDialog,
    onConfirm,
    name,
    id,
    numOpenApplications,
    classes,
    t,
  }) => (
    <>
      <PersonSectionHeader
        name={name}
        id={id}
        numOpenApplications={numOpenApplications}
      />
      <div className={classes.deleteIconOuterContainer}>
        <IconButton
          onClick={showDeleteConfirmationDialog}
          className={classes.deleteIconContainer}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <FormPrefix prefix={`${prefix}.`}>
        <FormSection labelTranslationKey="relationshipData">
          <SelectField name="relationshipType" />
          <OptionalBooleanField name="liveTogether" />
        </FormSection>
      </FormPrefix>
      <FormPrefix prefix={`${prefix}.otherPerson.`}>
        <FormSection labelTranslationKey="personData">
          <SharedPersonDataFields />
        </FormSection>
        <SharedPersonSections />
      </FormPrefix>
      <ConfirmationDialog
        open={isDeleteConfirmationDialogOpen}
        onCancel={closeDeleteConfirmationDialog}
        onConfirm={onConfirm}
        title={t('personForm.deleteRelationship.dialogTitle')}
        cancelText={t('personForm.deleteRelationship.cancel')}
        confirmText={t('personForm.deleteRelationship.confirm')}
        message={t('personForm.deleteRelationship.dialogMessage')}
      />
    </>
  )
)

interface RelationshipSectionProps {
  relationshipIndex: number
}

export const RelationshipSection: FC<RelationshipSectionProps> = flowMax(
  addDisplayName('RelationshipSection'),
  addFormikTyped(editPersonFormSchema),
  addProps(({formik: {values: {person: {relationships}}}}) => ({
    relationships,
  })),
  addProps(({relationships, relationshipIndex}) => ({
    relationship: relationships[relationshipIndex],
  })),
  branchIfNullish('relationship'),
  addMemoBoundary(['relationship']),
  ({relationship, relationshipIndex}) => (
    <RelationshipForm
      relationship={relationship}
      index={relationshipIndex}
      key={relationship.id || `index-${relationshipIndex}`}
    />
  )
)
