/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {PersonInput, AccountInput, NjmmisCheckResult} from './globalTypes' // ====================================================
// GraphQL mutation operation: CreateAccountAndPerson
// ====================================================

import {deserializeAccountInput} from './globalTypes'
import {serializeAccountInput} from './globalTypes'
import {deserializePersonInput} from './globalTypes'
import {serializePersonInput} from './globalTypes'
import {CreateAccountAndPersonVariables as CreateAccountAndPersonVariablesOrig} from '../types/CreateAccountAndPerson'
import {CreateAccountAndPerson as CreateAccountAndPersonOrig} from '../types/CreateAccountAndPerson'
import {deserializeNjmmisCheckResult} from 'graphql/converters'
import {serializeNjmmisCheckResult} from 'graphql/converters'
import {CreateAccountAndPerson_createAccountAndPerson as CreateAccountAndPerson_createAccountAndPersonOrig} from '../types/CreateAccountAndPerson'
import {formatISODate} from 'utils/date'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {CreateAccountAndPerson_createAccountAndPerson_person as CreateAccountAndPerson_createAccountAndPerson_personOrig} from '../types/CreateAccountAndPerson'
import {CreateAccountAndPerson_createAccountAndPerson_person_assets as CreateAccountAndPerson_createAccountAndPerson_person_assetsOrig} from '../types/CreateAccountAndPerson'
import {CreateAccountAndPerson_createAccountAndPerson_person_deductions as CreateAccountAndPerson_createAccountAndPerson_person_deductionsOrig} from '../types/CreateAccountAndPerson'
import {CreateAccountAndPerson_createAccountAndPerson_person_incomeSources as CreateAccountAndPerson_createAccountAndPerson_person_incomeSourcesOrig} from '../types/CreateAccountAndPerson'
import {CreateAccountAndPerson_createAccountAndPerson_person_phoneNumbers as CreateAccountAndPerson_createAccountAndPerson_person_phoneNumbersOrig} from '../types/CreateAccountAndPerson'
export interface CreateAccountAndPerson_createAccountAndPerson_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializeCreateAccountAndPerson_createAccountAndPerson_person_phoneNumbers = (
  serialized: CreateAccountAndPerson_createAccountAndPerson_person_phoneNumbersOrig
): CreateAccountAndPerson_createAccountAndPerson_person_phoneNumbers => ({
  ...serialized,
})
export const serializeCreateAccountAndPerson_createAccountAndPerson_person_phoneNumbers = (
  deserialized: CreateAccountAndPerson_createAccountAndPerson_person_phoneNumbers
): CreateAccountAndPerson_createAccountAndPerson_person_phoneNumbersOrig => ({
  ...deserialized,
})
export interface CreateAccountAndPerson_createAccountAndPerson_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
}
export const deserializeCreateAccountAndPerson_createAccountAndPerson_person_incomeSources = (
  serialized: CreateAccountAndPerson_createAccountAndPerson_person_incomeSourcesOrig
): CreateAccountAndPerson_createAccountAndPerson_person_incomeSources => ({
  ...serialized,
})
export const serializeCreateAccountAndPerson_createAccountAndPerson_person_incomeSources = (
  deserialized: CreateAccountAndPerson_createAccountAndPerson_person_incomeSources
): CreateAccountAndPerson_createAccountAndPerson_person_incomeSourcesOrig => ({
  ...deserialized,
})
export interface CreateAccountAndPerson_createAccountAndPerson_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeCreateAccountAndPerson_createAccountAndPerson_person_deductions = (
  serialized: CreateAccountAndPerson_createAccountAndPerson_person_deductionsOrig
): CreateAccountAndPerson_createAccountAndPerson_person_deductions => ({
  ...serialized,
})
export const serializeCreateAccountAndPerson_createAccountAndPerson_person_deductions = (
  deserialized: CreateAccountAndPerson_createAccountAndPerson_person_deductions
): CreateAccountAndPerson_createAccountAndPerson_person_deductionsOrig => ({
  ...deserialized,
})
export interface CreateAccountAndPerson_createAccountAndPerson_person_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeCreateAccountAndPerson_createAccountAndPerson_person_assets = (
  serialized: CreateAccountAndPerson_createAccountAndPerson_person_assetsOrig
): CreateAccountAndPerson_createAccountAndPerson_person_assets => ({
  ...serialized,
})
export const serializeCreateAccountAndPerson_createAccountAndPerson_person_assets = (
  deserialized: CreateAccountAndPerson_createAccountAndPerson_person_assets
): CreateAccountAndPerson_createAccountAndPerson_person_assetsOrig => ({
  ...deserialized,
})
export interface CreateAccountAndPerson_createAccountAndPerson_person {
  __typename: 'Person'
  id: string
  updatedAt: Date
  firstName: string | null
  middleName: string | null
  lastName: string | null
  suffix: string | null
  preferredName: string | null
  dob: Date | null
  gender: string | null
  ssn: string | null
  phoneNumbers: CreateAccountAndPerson_createAccountAndPerson_person_phoneNumbers[]
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  email: string | null
  emailComment: string | null
  preferredLanguage: string | null
  hospitalPatientId: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  incomeSources: CreateAccountAndPerson_createAccountAndPerson_person_incomeSources[]
  deductions: CreateAccountAndPerson_createAccountAndPerson_person_deductions[]
  assets: CreateAccountAndPerson_createAccountAndPerson_person_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  unreadMessageStatus: boolean | null
}
export const deserializeCreateAccountAndPerson_createAccountAndPerson_person = (
  serialized: CreateAccountAndPerson_createAccountAndPerson_personOrig
): CreateAccountAndPerson_createAccountAndPerson_person => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeCreateAccountAndPerson_createAccountAndPerson_person_phoneNumbers
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeCreateAccountAndPerson_createAccountAndPerson_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeCreateAccountAndPerson_createAccountAndPerson_person_deductions
  ),
  assets: serialized.assets.map(
    deserializeCreateAccountAndPerson_createAccountAndPerson_person_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
})
export const serializeCreateAccountAndPerson_createAccountAndPerson_person = (
  deserialized: CreateAccountAndPerson_createAccountAndPerson_person
): CreateAccountAndPerson_createAccountAndPerson_personOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeCreateAccountAndPerson_createAccountAndPerson_person_phoneNumbers
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeCreateAccountAndPerson_createAccountAndPerson_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeCreateAccountAndPerson_createAccountAndPerson_person_deductions
  ),
  assets: deserialized.assets.map(
    serializeCreateAccountAndPerson_createAccountAndPerson_person_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
})
export interface CreateAccountAndPerson_createAccountAndPerson {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
  timeOfService: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location: string | null
  insuranceType: string
  status: string
  createdAt: Date
  updatedAt: Date
  notes: string | null
  accountAmount: number | null
  njmmisCheck: string | null
  previousInsurance: string | null
  invoiceMonth: string | null
  invoiceYear: string | null
  dischargeDate: Date | null
  isScheduled: boolean
  person: CreateAccountAndPerson_createAccountAndPerson_person
}
export const deserializeCreateAccountAndPerson_createAccountAndPerson = (
  serialized: CreateAccountAndPerson_createAccountAndPersonOrig
): CreateAccountAndPerson_createAccountAndPerson => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
  person: deserializeCreateAccountAndPerson_createAccountAndPerson_person(
    serialized.person
  ),
})
export const serializeCreateAccountAndPerson_createAccountAndPerson = (
  deserialized: CreateAccountAndPerson_createAccountAndPerson
): CreateAccountAndPerson_createAccountAndPersonOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
  person: serializeCreateAccountAndPerson_createAccountAndPerson_person(
    deserialized.person
  ),
})
export interface CreateAccountAndPerson {
  /**
   * Create a new account and associated person
   */
  createAccountAndPerson: CreateAccountAndPerson_createAccountAndPerson
}
export const deserializeCreateAccountAndPerson = (
  serialized: CreateAccountAndPersonOrig
): CreateAccountAndPerson => ({
  ...serialized,
  createAccountAndPerson: deserializeCreateAccountAndPerson_createAccountAndPerson(
    serialized.createAccountAndPerson
  ),
})
export const serializeCreateAccountAndPerson = (
  deserialized: CreateAccountAndPerson
): CreateAccountAndPersonOrig => ({
  ...deserialized,
  createAccountAndPerson: serializeCreateAccountAndPerson_createAccountAndPerson(
    deserialized.createAccountAndPerson
  ),
})
export interface CreateAccountAndPersonVariables {
  person: PersonInput
  account: AccountInput
}
export const deserializeCreateAccountAndPersonVariables = (
  serialized: CreateAccountAndPersonVariablesOrig
): CreateAccountAndPersonVariables => ({
  ...serialized,
  person: deserializePersonInput(serialized.person),
  account: deserializeAccountInput(serialized.account),
})
export const serializeCreateAccountAndPersonVariables = (
  deserialized: CreateAccountAndPersonVariables
): CreateAccountAndPersonVariablesOrig => ({
  ...deserialized,
  person: serializePersonInput(deserialized.person),
  account: serializeAccountInput(deserialized.account),
})
