import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addWrapper,
  addProps,
  addMemoBoundary,
} from 'ad-hok'
import {get} from 'lodash'
import {addPropIdentityStabilization} from 'ad-hok-utils'

import {makeClasses, addClasses} from 'theme'
import {makeFormSchema, ExtractFormSchemaFields} from 'utils/form/schema'
import {assetSchema} from 'components/EditPersonForm/schema'
import Form, {OnSubmitSuccessOptions} from 'components/Form'
import {addTranslationHelpers} from 'utils/i18n'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogContent from 'components/DialogContent'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import SelectField from 'components/SelectField'
import CurrencyField from 'components/CurrencyField'
import {determinerAssetForm} from 'components/AssetDialog/determiner'
import {
  addSubmitForm,
  addFormikSubmitFormCallback,
} from 'utils/addFormikHoistedState'
import addHasSaved from 'utils/addHasSaved'
import {addFieldsFreshnessContext} from 'utils/form/context'
import {PersonFields_assets} from 'graphql/deserializedTypes/PersonFields'

const classes = makeClasses(() => ({
  contentContainer: {
    width: 600,
  },
}))

export const assetFormSchema = makeFormSchema({
  fields: {
    asset: assetSchema,
  },
})

interface Props {
  asset?: PersonFields_assets
  itemName?: string
  onSave: (
    options: OnSubmitSuccessOptions<
      ExtractFormSchemaFields<typeof assetFormSchema>
    >
  ) => void
  open: boolean
  onClose: () => void
  shouldFlagNecessaryFields?: boolean
}

const AssetDialog: FC<Props> = flowMax(
  addDisplayName('AssetDialog'),
  addFieldsFreshnessContext,
  addProps(({fieldsFreshness, itemName}) => ({
    initialFieldsFreshness: {
      asset: itemName ? get(fieldsFreshness, itemName, {}) : {},
    },
  })),
  addPropIdentityStabilization('initialFieldsFreshness'),
  addMemoBoundary([
    'asset',
    'onSave',
    'open',
    'onClose',
    'initialFieldsFreshness',
    'shouldFlagNecessaryFields',
  ]),
  addSubmitForm,
  addHasSaved('onSave'),
  addClasses(classes),
  addTranslationHelpers,
  addWrapper(
    (
      render,
      {
        classes,
        open,
        onClose,
        asset,
        onSave,
        submitForm,
        shouldFlagNecessaryFields,
        hasSaved,
        initialFieldsFreshness,
        t,
      }
    ) => (
      <Dialog
        open={open}
        onClose={onClose}
        data-testid={`asset-dialog-${asset?.id ?? 'new'}`}
        scroll="paper"
      >
        <DialogTitle>{t('assetDialog.title')}</DialogTitle>
        <DialogContent className={classes.contentContainer} dividers>
          <Form
            name="assetForm"
            schema={assetFormSchema}
            onSubmitSuccess={onSave}
            initialValues={asset && {asset}}
            initialFieldsFreshness={initialFieldsFreshness}
            initialTouched={{asset: {startMonth: true}}}
            shouldTrackFieldsUpdatedAt
            determiners={shouldFlagNecessaryFields ? [determinerAssetForm] : []}
          >
            {render()}
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t('assetDialog.cancel')}
          </Button>
          <Button
            onClick={submitForm}
            variant="contained"
            color="primary"
            disabled={hasSaved}
          >
            {t('assetDialog.save')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  ),
  addFormikSubmitFormCallback,
  () => (
    <>
      <SelectField name="asset.assetType" />
      <CurrencyField name="asset.amount" />
    </>
  )
)

export default AssetDialog
