import {getContextHelpers, toObjectKeys} from 'ad-hok-utils'

import {RequiredDocument} from 'components/EditPersonForm/documents'
import {Person_person} from 'graphql/deserializedTypes/Person'

const {
  addProvider: addDocumentsSectionContextProvider,
  addConsumer: addDocumentsSectionContext,
} = getContextHelpers<{
  person: Person_person
  documents: RequiredDocument[]
  hasNecessaryDocuments: boolean
  setHasNecessaryDocuments: (
    personOrApplication: string,
    hasNecessaryDocuments: boolean
  ) => void
}>(
  toObjectKeys([
    'person',
    'documents',
    'hasNecessaryDocuments',
    'setHasNecessaryDocuments',
  ])
)

export {addDocumentsSectionContextProvider, addDocumentsSectionContext}
