/* eslint-disable */
import {PersonReceivedWebformFileTemplatesVariables as PersonReceivedWebformFileTemplatesVariablesOrig} from '../types/PersonReceivedWebformFileTemplates'
import {PersonReceivedWebformFileTemplates as PersonReceivedWebformFileTemplatesOrig} from '../types/PersonReceivedWebformFileTemplates'
import {PersonReceivedWebformFileTemplates_person as PersonReceivedWebformFileTemplates_personOrig} from '../types/PersonReceivedWebformFileTemplates'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform as PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebformOrig} from '../types/PersonReceivedWebformFileTemplates'
import {PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates as PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplatesOrig} from '../types/PersonReceivedWebformFileTemplates'
import {ReceivedWebformResponse} from 'utils/form/getReceivedWebformResponse'
import {parseReceivedWebformResponse} from 'utils/form/getReceivedWebformResponse'
import {serializeReceivedWebformResponse} from 'utils/form/getReceivedWebformResponse'
import {PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_receivedWebform as PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_receivedWebformOrig} from '../types/PersonReceivedWebformFileTemplates'
import {PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_fileTemplate as PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_fileTemplateOrig} from '../types/PersonReceivedWebformFileTemplates'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: PersonReceivedWebformFileTemplates
// ====================================================
export interface PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_fileTemplate {
  __typename: 'FileTemplate'
  id: string
  name: string
}
export const deserializePersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_fileTemplate = (
  serialized: PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_fileTemplateOrig
): PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_fileTemplate => ({
  ...serialized,
})
export const serializePersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_fileTemplate = (
  deserialized: PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_fileTemplate
): PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_fileTemplateOrig => ({
  ...deserialized,
})
export interface PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_receivedWebform {
  __typename: 'ReceivedWebform'
  id: string
  response: ReceivedWebformResponse
}
export const deserializePersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_receivedWebform = (
  serialized: PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_receivedWebformOrig
): PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_receivedWebform => ({
  ...serialized,
  response: parseReceivedWebformResponse(serialized.response),
})
export const serializePersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_receivedWebform = (
  deserialized: PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_receivedWebform
): PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_receivedWebformOrig => ({
  ...deserialized,
  response: serializeReceivedWebformResponse(deserialized.response),
})
export interface PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates {
  __typename: 'ReceivedWebformFileTemplate'
  id: string
  cleared: boolean
  fileTemplate: PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_fileTemplate
  receivedWebform: PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_receivedWebform
}
export const deserializePersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates = (
  serialized: PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplatesOrig
): PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates => ({
  ...serialized,
  fileTemplate: deserializePersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_fileTemplate(
    serialized.fileTemplate
  ),
  receivedWebform: deserializePersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_receivedWebform(
    serialized.receivedWebform
  ),
})
export const serializePersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates = (
  deserialized: PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates
): PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplatesOrig => ({
  ...deserialized,
  fileTemplate: serializePersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_fileTemplate(
    deserialized.fileTemplate
  ),
  receivedWebform: serializePersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates_receivedWebform(
    deserialized.receivedWebform
  ),
})
export interface PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform {
  __typename: 'ReceivedWebform'
  id: string
  createdAt: Date
  receivedWebformFileTemplates: PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates[]
}
export const deserializePersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform = (
  serialized: PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebformOrig
): PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
  receivedWebformFileTemplates: serialized.receivedWebformFileTemplates.map(
    deserializePersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates
  ),
})
export const serializePersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform = (
  deserialized: PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform
): PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebformOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
  receivedWebformFileTemplates: deserialized.receivedWebformFileTemplates.map(
    serializePersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates
  ),
})
export interface PersonReceivedWebformFileTemplates_person {
  __typename: 'Person'
  id: string
  mostRecentReceivedWebform: PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform | null
}
export const deserializePersonReceivedWebformFileTemplates_person = (
  serialized: PersonReceivedWebformFileTemplates_personOrig
): PersonReceivedWebformFileTemplates_person => ({
  ...serialized,
  mostRecentReceivedWebform:
    serialized.mostRecentReceivedWebform != null
      ? deserializePersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform(
          serialized.mostRecentReceivedWebform
        )
      : serialized.mostRecentReceivedWebform,
})
export const serializePersonReceivedWebformFileTemplates_person = (
  deserialized: PersonReceivedWebformFileTemplates_person
): PersonReceivedWebformFileTemplates_personOrig => ({
  ...deserialized,
  mostRecentReceivedWebform:
    deserialized.mostRecentReceivedWebform != null
      ? serializePersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform(
          deserialized.mostRecentReceivedWebform
        )
      : deserialized.mostRecentReceivedWebform,
})
export interface PersonReceivedWebformFileTemplates {
  /**
   * Person
   */
  person: PersonReceivedWebformFileTemplates_person
}
export const deserializePersonReceivedWebformFileTemplates = (
  serialized: PersonReceivedWebformFileTemplatesOrig
): PersonReceivedWebformFileTemplates => ({
  ...serialized,
  person: deserializePersonReceivedWebformFileTemplates_person(
    serialized.person
  ),
})
export const serializePersonReceivedWebformFileTemplates = (
  deserialized: PersonReceivedWebformFileTemplates
): PersonReceivedWebformFileTemplatesOrig => ({
  ...deserialized,
  person: serializePersonReceivedWebformFileTemplates_person(
    deserialized.person
  ),
})
export interface PersonReceivedWebformFileTemplatesVariables {
  id: string
}
export const deserializePersonReceivedWebformFileTemplatesVariables = (
  serialized: PersonReceivedWebformFileTemplatesVariablesOrig
): PersonReceivedWebformFileTemplatesVariables => ({...serialized})
export const serializePersonReceivedWebformFileTemplatesVariables = (
  deserialized: PersonReceivedWebformFileTemplatesVariables
): PersonReceivedWebformFileTemplatesVariablesOrig => ({...deserialized})
