import React from 'react'
import {TFunction} from 'i18next'
import {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import cx from 'classnames'

import {addTranslationHelpers} from 'utils/i18n'
import {addClasses, makeClasses} from 'theme'
import Grid from 'components/Grid'
import Body1 from 'components/Body1'
import {FormCanonicalValues, ExtractFormSchemaFields} from 'utils/form/schema'
import {editPersonFormSchema} from 'components/EditPersonForm/schema'
import {addRightColumnContext} from 'components/EditPersonForm/rightColumnContext'
import {DeterminedEligibilitiesForBenefit} from 'utils/form/determiners'
import {
  EligibilityStatusType,
  getEligibilityStatus,
} from 'utils/eligibilityStatus'

const classes = makeClasses((theme) => ({
  container: {
    marginBottom: theme.spacing(1),
  },
  benefitNameContainer: {
    width: 180,
    flexShrink: 0,
  },
  benefitName: {
    color: '#000',
  },
  determination: {
    lineHeight: 1.3,
  },
  determinationIneligible: {
    color: '#fd0000',
  },
  determinationEligible: {
    color: '#219653',
  },
  householdSizeDescription: {
    color: '#737373',
    lineHeight: 1.2,
    marginTop: -2,
  },
  secondaryInfo: {
    marginLeft: '0.3em',
    color: '#828282',
  },
}))

const getEligibilityDescription = ({
  eligibilityStatus,
  reasonOrInfo,
  t,
}: {
  eligibilityStatus: EligibilityStatusType
  reasonOrInfo: string | null
  t: TFunction
}) =>
  ({
    ineligible: t('personForm.eligibilityCheck.ineligible', {
      reason: reasonOrInfo,
    }),
    eligible: t('personForm.eligibilityCheck.eligible'),
    undecided: t('personForm.eligibilityCheck.undecided'),
  }[eligibilityStatus])

const getEligibilityDescriptionClassName = ({
  eligibilityStatus,
  classes,
}: {
  eligibilityStatus: EligibilityStatusType
  classes: {[className: string]: string}
}) =>
  ({
    ineligible: classes.determinationIneligible,
    eligible: classes.determinationEligible,
    undecided: '',
  }[eligibilityStatus])

type CanonicalValues = FormCanonicalValues<
  ExtractFormSchemaFields<typeof editPersonFormSchema>
>

interface Props {
  determinedEligibility: DeterminedEligibilitiesForBenefit
}

const EligibilityDeterminationPreliminary: FC<Props> = flowMax(
  addDisplayName('EligibilityDeterminationPreliminary'),
  addTranslationHelpers,
  addProps(
    ({
      determinedEligibility: {
        isEligible,
        reasonOrInfo,
        name,
        isPreliminary,
        secondaryInfo,
      },
    }) => ({
      isEligible,
      reasonOrInfo,
      name,
      isPreliminary,
      secondaryInfo,
    }),
    ['determinedEligibility']
  ),
  addProps(
    ({isEligible}) => ({
      eligibilityStatus: getEligibilityStatus({isEligible}),
    }),
    ['isEligible']
  ),
  addClasses(classes),
  addProps(
    ({eligibilityStatus, reasonOrInfo, classes, t}) => ({
      description: getEligibilityDescription({
        eligibilityStatus,
        reasonOrInfo,
        t,
      }),
      descriptionClassName: getEligibilityDescriptionClassName({
        eligibilityStatus,
        classes,
      }),
    }),
    ['eligibilityStatus', 'reasonOrInfo', 'classes', 't']
  ),
  addRightColumnContext,
  addProps(
    ({
      name,
      householdSizeDescriptionMedicaidPreliminary,
      householdSizeDescriptionCharityCarePreliminary,
    }) => ({
      householdSizeDescription:
        name === 'medicaid'
          ? householdSizeDescriptionMedicaidPreliminary
          : name === 'charityCare'
          ? householdSizeDescriptionCharityCarePreliminary
          : '',
    })
  ),
  ({
    description,
    t,
    classes,
    descriptionClassName,
    name,
    isPreliminary,
    householdSizeDescription,
    secondaryInfo,
  }) => (
    <Grid container direction="column" className={classes.container}>
      <Grid container direction="row" wrap="nowrap">
        <Grid item className={classes.benefitNameContainer}>
          <Body1 className={classes.benefitName}>
            {t(`benefitTypeLabels.${name}`)}
          </Body1>
        </Grid>
        <Grid item>
          <Body1
            data-testid={`${name}-${isPreliminary ? 'preliminary' : 'full'}`}
            className={cx(classes.determination, descriptionClassName)}
            component="span"
          >
            {description}
          </Body1>
          {secondaryInfo && (
            <Body1
              className={cx(classes.determination, classes.secondaryInfo)}
              component="span"
            >
              ({secondaryInfo})
            </Body1>
          )}
        </Grid>
      </Grid>
      {!!householdSizeDescription && (
        <Body1 className={classes.householdSizeDescription}>
          {householdSizeDescription}
        </Body1>
      )}
    </Grid>
  )
)

export default EligibilityDeterminationPreliminary
