import WebViewer, {WebViewerInstance} from '@pdftron/webviewer'
import React, {FC} from 'react'
// import {Select, MenuItem, FormControl} from '@material-ui/core'
import {
  flowMax,
  addDisplayName,
  addRef,
  addState,
  addEffect,
  addWrapper,
  addProps,
  addStateHandlers,
  addHandlers,
} from 'ad-hok'
import {Modal, Card, CardContent} from '@material-ui/core'
import {branchIfFalsy, branchIfTruthy} from 'ad-hok-utils'

import {makeClasses, addClasses} from 'theme'
import typedAs from 'utils/typedAs'
import Body1 from 'components/Body1'
import {FileTemplates_fileTemplates} from 'graphql/deserializedTypes/FileTemplates'
import Button from 'components/Button'
import {addTranslationHelpers} from 'utils/i18n'
import addFetchSignedDocumentUploadUrl from 'utils/addFetchSignedDocumentUploadUrl'
import Tooltip from 'components/Tooltip'
import {getPdftronLicenseKey} from 'utils/pdftron'
import {PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates} from 'graphql/deserializedTypes/PersonReceivedWebformFileTemplates'
import {addIsPatientPortal} from 'utils/patientPortal'
import {Applications_applications} from 'graphql/deserializedTypes/Applications'
import {
  AccountAudits_accountAudits_account_documentFiles,
  AccountAudits_accountAudits_application,
} from 'graphql/deserializedTypes/AccountAudits'
import ConfirmationDialog from './ConfirmationDialog'

const classes = makeClasses((theme) => ({
  backdrop: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
  card: {
    pointerEvents: 'auto',
    width: 'calc(100% - 100px)',
    height: 'calc(100% - 100px)',
    display: 'flex',
  },
  contents: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  pdfContainer: {
    flex: 1,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonSubContainer: {
    display: 'flex',
  },
  outForSigning: {
    textAlign: 'center',
  },
  deleteButtonWrapper: {
    marginRight: 'auto',
  },
  discardButton: {
    marginRight: 'auto',
  },
  phoneNumbersContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginRight: 'auto',
  },
  sendToCellForSigningButton: {
    marginLeft: theme.spacing(1),
  },
}))

type AddTooltipIfDisabled = <
  TProps extends {
    disabled?: boolean
  }
>(
  props: TProps
) => TProps

const addTooltipIfDisabled: AddTooltipIfDisabled = flowMax(
  addTranslationHelpers,
  addWrapper((render, {disabled, t}) =>
    disabled ? (
      <Tooltip title={t('editPdfDialog.saveInProgress')}>
        <div>{render()}</div>
      </Tooltip>
    ) : (
      <>{render()}</>
    )
  )
)

type AddSetReadonlyOnMountType = <
  TProps extends {
    instance: WebViewerInstance | null
    file: FileTemplateWithSignedUrl | ReadOnlyFileWithSignedUrl
  }
>(
  props: TProps
) => TProps

const addSetReadonlyOnMount: AddSetReadonlyOnMountType = addEffect(
  ({instance}) => () => {
    if (!instance) {
      return
    }

    const {annotationManager, Annotations} = instance.Core

    const readonly = true
    if (readonly) {
      annotationManager.enableReadOnlyMode()

      // https://www.pdftron.com/documentation/web/guides/forms/modify-fields/
      annotationManager.addEventListener(
        'annotationChanged',
        (annotations, action, {imported}) => {
          if (imported && action === 'add') {
            annotations.forEach((annot: any) => {
              if (annot instanceof Annotations.WidgetAnnotation) {
                annot.fieldFlags.set('ReadOnly', true)
              }
            })
          }
        }
      )
    }
  },
  ['instance', 'file']
)

export interface FileTemplateWithSignedUrl extends FileTemplates_fileTemplates {
  signedUrl: string
}

export interface ReadOnlyFileWithSignedUrl
  extends AccountAudits_accountAudits_account_documentFiles {
  signedUrl: string
}

interface DeleteButtonProps {
  onDelete: () => void | undefined
  disabled?: boolean
}

const DeleteButton: FC<DeleteButtonProps> = flowMax(
  addDisplayName('DeleteButton'),
  addIsPatientPortal,
  branchIfTruthy('isPatientPortal'),
  addTooltipIfDisabled,
  addStateHandlers(
    {
      isShowingConfirmDialog: false,
    },
    {
      onClick: () => () => ({
        isShowingConfirmDialog: true,
      }),
      onCancel: () => () => ({
        isShowingConfirmDialog: false,
      }),
    }
  ),
  addTranslationHelpers,
  addHandlers({
    onConfirm: ({onDelete}) => () => {
      onDelete()
    },
  }),
  ({onClick, disabled, isShowingConfirmDialog, onCancel, onConfirm, t}) => (
    <>
      <Button onClick={onClick} color="secondary" disabled={disabled}>
        {t('buttons.delete')}
      </Button>
      <ConfirmationDialog
        open={isShowingConfirmDialog}
        onConfirm={onConfirm}
        onCancel={onCancel}
        cancelText={t('buttons.cancel')}
        confirmText={t('buttons.delete')}
        title={t('readOnlyDialog.confirmDeleteTitle')}
        message={t('readOnlyDialog.confirmDeleteMessage')}
      />
    </>
  )
)

interface CloseButtonProps {
  onClick: () => void
  disabled?: boolean
}

const CloseButton: FC<CloseButtonProps> = flowMax(
  addDisplayName('CloseButton'),
  addIsPatientPortal,
  branchIfTruthy('isPatientPortal'),
  addTooltipIfDisabled,
  addTranslationHelpers,
  ({onClick, disabled, t}) => (
    <Button onClick={onClick} color="secondary" disabled={disabled}>
      {t('readOnlyDialog.close')}
    </Button>
  )
)

type ReceivedWebformFileTemplateType = PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates

interface Props {
  file: FileTemplateWithSignedUrl | ReadOnlyFileWithSignedUrl | null
  onClose: () => void
  receivedWebformFileTemplate?: ReceivedWebformFileTemplateType | null
  allowDelete?: boolean
  onDelete?: () => void
  title?: string
  application?:
    | Applications_applications
    | AccountAudits_accountAudits_application
}

const ReadOnlyFileDialogWithDelete: FC<Props> = flowMax(
  addDisplayName('EditPdfDialog'),
  addWrapper((render, {file, onClose}) => (
    <Modal open={!!file} onClose={onClose}>
      <>{render()}</>
    </Modal>
  )),
  branchIfFalsy('file'),
  addRef('viewerRef', typedAs<HTMLDivElement | null>(null)),
  addState('instance', 'setInstance', typedAs<WebViewerInstance | null>(null)),
  addEffect(
    ({file: {signedUrl}, viewerRef, setInstance}) => () => {
      async function initWebViewer() {
        const response = await fetch(decodeURIComponent(signedUrl))
        const contentType = response.headers.get('content-type')
        const extension =
          contentType === 'image/jpeg'
            ? 'jpeg'
            : contentType === 'image/png'
            ? 'png'
            : 'pdf'
        const webViewer = await WebViewer(
          {
            path: '/assets/webviewer/lib',
            initialDoc: decodeURIComponent(signedUrl),
            extension,
            licenseKey: getPdftronLicenseKey(),
          },
          viewerRef.current!
        )
        setInstance(webViewer)
      }
      initWebViewer()
    },
    ['signedUrl']
  ),
  addSetReadonlyOnMount,
  addFetchSignedDocumentUploadUrl,
  addStateHandlers(
    {
      isSaving: false,
    },
    {
      onSaving: () => () => ({
        isSaving: true,
      }),
      onNoLongerSaving: () => () => ({
        isSaving: false,
      }),
    }
  ),
  addIsPatientPortal,
  addTranslationHelpers,
  addProps(({file, title}) => ({
    name: title ?? file?.name,
    editableFile: undefined,
  })),
  addProps(({isSaving}) => ({areActionButtonsDisabled: isSaving})),
  addClasses(classes),
  ({
    name,
    viewerRef,
    onClose,
    allowDelete,
    onDelete,
    areActionButtonsDisabled,
    classes,
  }) => (
    <div className={classes.backdrop}>
      <Card className={classes.card}>
        <CardContent className={classes.contents}>
          <Body1>{name}</Body1>
          <div ref={viewerRef} className={classes.pdfContainer} />
          <div className={classes.buttonContainer}>
            {allowDelete && (
              <div className={classes.buttonSubContainer}>
                <DeleteButton
                  onDelete={allowDelete && onDelete ? onDelete : () => {}}
                  disabled={areActionButtonsDisabled}
                />
              </div>
            )}

            <div className={classes.buttonSubContainer}>
              <CloseButton
                onClick={onClose}
                disabled={areActionButtonsDisabled}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
)

export default ReadOnlyFileDialogWithDelete
