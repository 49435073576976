import typedAs from 'utils/typedAs'

type EligibilityStatusApiValue = 'Ineligible' | 'Pass' | 'Not enough data'

export const eligibilityStatusToApiValue = (
  status: EligibilityStatusType
): EligibilityStatusApiValue =>
  typedAs<Record<EligibilityStatusType, EligibilityStatusApiValue>>({
    ineligible: 'Ineligible',
    eligible: 'Pass',
    undecided: 'Not enough data',
  })[status]

export type EligibilityStatusType = 'ineligible' | 'eligible' | 'undecided'

export const getEligibilityStatus = ({
  isEligible,
}: {
  isEligible: boolean | null
}): EligibilityStatusType => {
  if (isEligible) return 'eligible'
  if (isEligible === false) return 'ineligible'
  return 'undecided'
}
