import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps, addWrapper} from 'ad-hok'
import {PureQueryOptions} from 'apollo-boost'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import cx from 'classnames'
import {generatePath} from 'react-router'
import {truncate} from 'lodash/fp'

import {makeClasses, addClasses} from 'theme'
import {addTranslationHelpers} from 'utils/i18n'
import Paper from 'components/Paper'
import Grid from 'components/Grid'
import Heading from 'components/Heading'
import DisplayItem from 'components/DisplayItem'
import DisplayShortDate from 'components/DisplayShortDate'
import Body1 from 'components/Body1'
import TaskStatusUpdateSelect, {
  TASK_STATUS_CLOSED,
} from 'components/TaskStatusUpdateSelect'
import Caption from 'components/Caption'
import {formatShortDateTimeEastern} from 'utils/date'
import DeleteTaskButton from 'components/DeleteTaskButton'
import EditTaskButton from 'components/EditTaskButton'
import {addTaskDialogContextProvider} from 'components/TaskDialog'
import Trans from 'components/Trans'
import Link from 'components/Link'
import {personDetailPath} from 'components/PersonDetail/index'
import {getExtendedName} from 'utils/name'
import Tooltip from 'components/Tooltip'
import {Tasks_tasks} from 'graphql/deserializedTypes/Tasks'

const classes = makeClasses((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(10),
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
  header: {
    fontSize: 23,
    marginBottom: theme.spacing(1),
  },
  note: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
    maxWidth: 350,
  },
  closeDate: {
    marginTop: theme.spacing(1),
    display: 'block',
  },
  deleteButton: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(1),
  },
  editButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1),
  },
  rightColumnContainer: {
    width: 180,
  },
  dueDate: {
    display: 'inline',
  },
  dueStatusOverdue: {
    color: '#b00020',
  },
  dueStatus: {
    fontWeight: 'bold',
    display: 'inline',
  },
  leftColumnsContainer: {
    flex: 1,
  },
  leftColumnContainer: {
    flex: 1,
    maxWidth: 350,
  },
}))

interface DueStatusProps {
  dueDate: Date
}

const DueStatus: FC<DueStatusProps> = flowMax(
  addDisplayName('DueStatus'),
  addProps(
    ({dueDate}) => ({
      numDaysSinceDueDate: differenceInCalendarDays(new Date(), dueDate),
    }),
    ['dueDate']
  ),
  addProps(
    ({numDaysSinceDueDate}) => ({
      isOverdue: numDaysSinceDueDate > 0,
    }),
    ['numDaysSinceDueDate']
  ),
  addClasses(classes),
  addTranslationHelpers,
  addProps(({isOverdue, numDaysSinceDueDate, classes, t}) => ({
    className: isOverdue ? classes.dueStatusOverdue : '',
    message: isOverdue
      ? t('taskItem.overdue')
      : numDaysSinceDueDate === 0
      ? t('taskItem.dueToday')
      : t('taskItem.dueInDays', {count: -numDaysSinceDueDate}),
  })),
  ({message, className, classes}) => (
    <Body1 className={cx(classes.dueStatus, className)}>({message})</Body1>
  )
)

const NOTE_TRUNCATE_LENGTH = 130

interface NoteProps {
  note: string
}

const Note: FC<NoteProps> = flowMax(
  addDisplayName('Note'),
  addWrapper((render, {note}) =>
    note.length >= NOTE_TRUNCATE_LENGTH ? (
      <Tooltip title={note}>
        <div>{render()}</div>
      </Tooltip>
    ) : (
      render()
    )
  ),
  addClasses(classes),
  ({note, classes}) => (
    <Body1 className={classes.note}>
      {truncate({length: NOTE_TRUNCATE_LENGTH}, note)}
    </Body1>
  )
)

interface Props {
  task: Tasks_tasks
  refetchQueriesOnDeleteOrUpdate: PureQueryOptions[]
  showPersonName?: boolean
}

const TaskItem: FC<Props> = flowMax(
  addDisplayName('TaskItem'),
  addProps(({task: {linkedTo: person}}) => ({
    person,
  })),
  addTaskDialogContextProvider,
  addTranslationHelpers,
  addClasses(classes),
  ({
    task: {
      id,
      taskType,
      assignedTo,
      dueDate,
      durationMin,
      note,
      status,
      updatedAt,
      linkedTo,
      createdBy,
    },
    task,
    refetchQueriesOnDeleteOrUpdate,
    showPersonName,
    t,
    classes,
  }) => (
    <Paper className={classes.paper} data-testid={`task-card-${id}`}>
      <Grid container direction="row" justify="space-between">
        <div className={classes.leftColumnsContainer}>
          <Heading component="h4" className={classes.header}>
            {showPersonName ? (
              <Trans i18nKey="taskItem.taskTypeForPerson">
                <Link
                  highlight
                  to={generatePath(personDetailPath, {id: linkedTo.id})}
                />
                {{taskType}}
                {{person: getExtendedName({...linkedTo, t})}}
              </Trans>
            ) : (
              taskType
            )}
          </Heading>
          <Grid container direction="row">
            <div className={classes.leftColumnContainer}>
              <DisplayItem
                i18nKey="taskItem.assignedToDisplay"
                translations={{
                  user: assignedTo.name ?? t('general.unknown')!,
                }}
              />
              <DisplayShortDate
                label={t('taskItem.dueDate')}
                date={dueDate}
                className={classes.dueDate}
              />{' '}
              <DueStatus dueDate={dueDate} />
              {!!durationMin && (
                <DisplayItem
                  i18nKey="taskItem.durationMinDisplay"
                  translations={{
                    minutes: durationMin,
                  }}
                />
              )}
            </div>
            <Grid item>
              {!!note && (
                <Grid container direction="row">
                  <Body1>{t('taskItem.note')}</Body1>
                  <Note note={note} />
                </Grid>
              )}
              <DisplayItem
                i18nKey="taskItem.createdByDisplay"
                translations={{
                  user: createdBy.name ?? t('general.unknown')!,
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.rightColumnContainer}>
          <TaskStatusUpdateSelect task={task} key={task.status} />
          {status === TASK_STATUS_CLOSED && (
            <Caption className={classes.closeDate}>
              {t('taskItem.closeDate', {
                date: formatShortDateTimeEastern(updatedAt),
              })}
            </Caption>
          )}
        </div>
      </Grid>
      {status !== TASK_STATUS_CLOSED && (
        <>
          <EditTaskButton
            task={task}
            className={classes.editButton}
            refetchQueriesOnUpdate={refetchQueriesOnDeleteOrUpdate}
          />
          <DeleteTaskButton
            task={task}
            refetchQueriesOnDelete={refetchQueriesOnDeleteOrUpdate}
            className={classes.deleteButton}
          />
        </>
      )}
    </Paper>
  )
)

export default TaskItem
