import React from 'react'
import {SimplePropsAdder, addWrapper, AddWrapperRenderCallback} from 'ad-hok'
import {ApolloClient} from 'apollo-boost'
import {ApolloConsumer} from '@apollo/react-hooks'

type AddApolloClientType = SimplePropsAdder<{
  apolloClient: ApolloClient<object>
}>

const addApolloClient: AddApolloClientType = addWrapper(
  (
    render: AddWrapperRenderCallback<{
      apolloClient: ApolloClient<object>
    }>
  ) => (
    <ApolloConsumer>{(apolloClient) => render({apolloClient})}</ApolloConsumer>
  )
)

export default addApolloClient
