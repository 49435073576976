import React, {FC} from 'react'
import {flowMax, addDisplayName, addHandlers} from 'ad-hok'

import {addUpdateTaskMutation} from 'graphql/generated'
import StatusUpdateSelect, {
  StatusUpdateMutateUpdates,
} from 'components/StatusUpdateSelect'
import {addTranslationHelpers} from 'utils/i18n'
import {TaskFields} from 'graphql/deserializedTypes/TaskFields'

export const TASK_STATUS_CLOSED = 'Closed'
export const TASK_STATUSES = ['New', 'In progress', TASK_STATUS_CLOSED]
const statusOptions = TASK_STATUSES.map((value) => ({
  label: value.toUpperCase(),
  value,
}))

interface Props {
  task: TaskFields
}

const TaskStatusUpdateSelect: FC<Props> = flowMax(
  addDisplayName('TaskStatusUpdateSelect'),
  addUpdateTaskMutation({}),
  addHandlers({
    mutate: ({mutateUpdateTask, task}) => (
      updates: StatusUpdateMutateUpdates
    ): Promise<any> =>
      mutateUpdateTask({
        variables: {
          task: {
            id: task.id,
            ...updates,
          },
        },
      }),
  }),
  addTranslationHelpers,
  ({task: {id, status}, mutate, t}) => (
    <StatusUpdateSelect
      id={`task-${id}-status-select`}
      updateFailedMessage={t('taskItem.statusUpdateFailed')}
      status={status}
      statusOptions={statusOptions}
      mutate={mutate}
    />
  )
)

export default TaskStatusUpdateSelect
