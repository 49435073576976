import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps, addMemoBoundary} from 'ad-hok'
import {filter} from 'lodash/fp'

import {FormFieldStatus, addFormFieldStatusesContext} from 'utils/form/context'
import {addTranslationHelpers} from 'utils/i18n'
import {addClasses, makeClasses} from 'theme'
import Body1 from 'components/Body1'
import Grid from 'components/Grid'
import Divider from 'components/Divider'
import {RequiredDocument} from 'components/EditPersonForm/documents'

const classes = makeClasses((theme) => ({
  container: {
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
}))

interface Props {
  documents?: RequiredDocument[]
  testId?: string
}

const NecessaryItemsCount: FC<Props> = flowMax(
  addDisplayName('NecessaryItemsCount'),
  addFormFieldStatusesContext,
  addProps(
    ({formFieldStatuses, documents}) => ({
      count:
        filter((status: FormFieldStatus) => status === 'necessary')(
          formFieldStatuses
        ).length +
        (documents?.filter(({isNecessary}) => isNecessary).length ?? 0),
    }),
    ['formFieldStatuses', 'documents']
  ),
  addMemoBoundary(['count']),
  addTranslationHelpers,
  addClasses(classes),
  ({count, t, classes, testId}) => (
    <>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.container}
      >
        <Grid item>
          <Body1>{t('personForm.necessaryItems')}</Body1>
        </Grid>
        <Grid item>
          <Body1 data-testid={testId}>{count}</Body1>
        </Grid>
      </Grid>
      <Divider variant="middle" className={classes.divider} />
    </>
  )
)

export default NecessaryItemsCount
