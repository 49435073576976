/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {DateRangeInput} from './globalTypes' // ====================================================
// GraphQL query operation: Tasks
// ====================================================

import {deserializeDateRangeInput} from './globalTypes'
import {serializeDateRangeInput} from './globalTypes'
import {TasksVariables as TasksVariablesOrig} from '../types/Tasks'
import {Tasks as TasksOrig} from '../types/Tasks'
import formatISO from 'date-fns/formatISO'
import {parseDate} from 'utils/form/fieldTypes'
import {formatISODate} from 'utils/date'
import {Tasks_tasks as Tasks_tasksOrig} from '../types/Tasks'
import {Tasks_tasks_linkedTo as Tasks_tasks_linkedToOrig} from '../types/Tasks'
import {Tasks_tasks_createdBy as Tasks_tasks_createdByOrig} from '../types/Tasks'
import {Tasks_tasks_assignedTo as Tasks_tasks_assignedToOrig} from '../types/Tasks'
export interface Tasks_tasks_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeTasks_tasks_assignedTo = (
  serialized: Tasks_tasks_assignedToOrig
): Tasks_tasks_assignedTo => ({...serialized})
export const serializeTasks_tasks_assignedTo = (
  deserialized: Tasks_tasks_assignedTo
): Tasks_tasks_assignedToOrig => ({...deserialized})
export interface Tasks_tasks_createdBy {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeTasks_tasks_createdBy = (
  serialized: Tasks_tasks_createdByOrig
): Tasks_tasks_createdBy => ({...serialized})
export const serializeTasks_tasks_createdBy = (
  deserialized: Tasks_tasks_createdBy
): Tasks_tasks_createdByOrig => ({...deserialized})
export interface Tasks_tasks_linkedTo {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
}
export const deserializeTasks_tasks_linkedTo = (
  serialized: Tasks_tasks_linkedToOrig
): Tasks_tasks_linkedTo => ({...serialized})
export const serializeTasks_tasks_linkedTo = (
  deserialized: Tasks_tasks_linkedTo
): Tasks_tasks_linkedToOrig => ({...deserialized})
export interface Tasks_tasks {
  __typename: 'Task'
  id: string
  taskType: string
  assignedTo: Tasks_tasks_assignedTo
  createdBy: Tasks_tasks_createdBy
  status: string
  note: string | null
  durationMin: number | null
  dueDate: Date
  linkedTo: Tasks_tasks_linkedTo
  updatedAt: Date
}
export const deserializeTasks_tasks = (
  serialized: Tasks_tasksOrig
): Tasks_tasks => ({
  ...serialized,
  assignedTo: deserializeTasks_tasks_assignedTo(serialized.assignedTo),
  createdBy: deserializeTasks_tasks_createdBy(serialized.createdBy),
  dueDate: parseDate(serialized.dueDate),
  linkedTo: deserializeTasks_tasks_linkedTo(serialized.linkedTo),
  updatedAt: parseDate(serialized.updatedAt),
})
export const serializeTasks_tasks = (
  deserialized: Tasks_tasks
): Tasks_tasksOrig => ({
  ...deserialized,
  assignedTo: serializeTasks_tasks_assignedTo(deserialized.assignedTo),
  createdBy: serializeTasks_tasks_createdBy(deserialized.createdBy),
  dueDate: formatISODate(deserialized.dueDate),
  linkedTo: serializeTasks_tasks_linkedTo(deserialized.linkedTo),
  updatedAt: formatISO(deserialized.updatedAt),
})
export interface Tasks {
  /**
   * List of tasks
   */
  tasks: Tasks_tasks[]
}
export const deserializeTasks = (serialized: TasksOrig): Tasks => ({
  ...serialized,
  tasks: serialized.tasks.map(deserializeTasks_tasks),
})
export const serializeTasks = (deserialized: Tasks): TasksOrig => ({
  ...deserialized,
  tasks: deserialized.tasks.map(serializeTasks_tasks),
})
export interface TasksVariables {
  taskTypes?: string[] | null
  statuses?: string[] | null
  assignedToIds?: string[] | null
  dueDateRanges?: DateRangeInput[] | null
}
export const deserializeTasksVariables = (
  serialized: TasksVariablesOrig
): TasksVariables => ({
  ...serialized,
  dueDateRanges:
    serialized.dueDateRanges != null
      ? serialized.dueDateRanges.map(deserializeDateRangeInput)
      : serialized.dueDateRanges,
})
export const serializeTasksVariables = (
  deserialized: TasksVariables
): TasksVariablesOrig => ({
  ...deserialized,
  dueDateRanges:
    deserialized.dueDateRanges != null
      ? deserialized.dueDateRanges.map(serializeDateRangeInput)
      : deserialized.dueDateRanges,
})
