import React, {FC} from 'react'
import {flowMax, addDisplayName, addHandlers, addProps} from 'ad-hok'

import {addTranslationHelpers} from 'utils/i18n'
import {addUpdateTriageMutation, addUsersQuery} from 'graphql/generated'
import {
  Triages_triages,
  serializeTriages_triages,
} from 'graphql/deserializedTypes/Triages'
import {StandaloneSelectField} from 'components/SelectField'
import {addLoadingIndicator} from 'utils/dataLoading'
import {getAssignedToOptions} from 'components/TaskDialog'
import {UserFields} from 'graphql/deserializedTypes/UserFields'

const findUserObject = (users: UserFields[], triage: Triages_triages) => (
  assignedToId: string
): UserFields => {
  const possibleUsers = triage.assignedTo
    ? [...users, triage.assignedTo]
    : users
  return possibleUsers.find(({id}) => id === assignedToId)!
}

interface Props {
  triage: Triages_triages
  className?: string
}

const TriageAssignedToSelect: FC<Props> = flowMax(
  addDisplayName('TriageAssignedToSelect'),
  addUsersQuery({}),
  addLoadingIndicator({}),
  addProps(
    ({users, triage}) => ({
      options: getAssignedToOptions(users, triage, {includeBlank: true}),
    }),
    ['users', 'triage']
  ),
  addUpdateTriageMutation({}),
  addProps(({triage: {assignedTo}}) => ({
    value: assignedTo?.id ?? '',
  })),
  addTranslationHelpers,
  addHandlers({
    onChange: ({mutateUpdateTriage, triage: {id}, triage, users, t}) => (
      event: React.ChangeEvent<{value: unknown}>
    ) => {
      const value = event.target.value as string
      const assignedToId = value || null
      mutateUpdateTriage({
        variables: {
          triage: {
            id,
            assignedToId,
          },
        },
        optimisticResponse: {
          updateTriage: serializeTriages_triages({
            ...triage,
            assignedTo: assignedToId
              ? findUserObject(users, triage)(assignedToId)
              : null,
          }),
        },
      }).catch(() => {
        window.alert(t('form.failedToSave'))
      })
    },
  }),
  ({value, t, onChange, options, className}) => (
    <StandaloneSelectField
      value={value}
      onChange={onChange}
      label={t('assignedToSelect.label')}
      options={options}
      id="triage-assigned-to-select"
      className={className}
    />
  )
)

export default TriageAssignedToSelect
