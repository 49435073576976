/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {
  ServiceType,
  InboundType,
  InboundInsuranceOnDateOfService,
  Benefit,
  NjmmisCheckStatus,
} from './globalTypes' // ====================================================
// GraphQL mutation operation: RetryInboundNjmmisCheck
// ====================================================

import {RetryInboundNjmmisCheckVariables as RetryInboundNjmmisCheckVariablesOrig} from '../types/RetryInboundNjmmisCheck'
import {RetryInboundNjmmisCheck as RetryInboundNjmmisCheckOrig} from '../types/RetryInboundNjmmisCheck'
import {RetryInboundNjmmisCheck_retryInboundNjmmisCheck as RetryInboundNjmmisCheck_retryInboundNjmmisCheckOrig} from '../types/RetryInboundNjmmisCheck'
import {RetryInboundNjmmisCheck_retryInboundNjmmisCheck_account as RetryInboundNjmmisCheck_retryInboundNjmmisCheck_accountOrig} from '../types/RetryInboundNjmmisCheck'
import {RetryInboundNjmmisCheck_retryInboundNjmmisCheck_mostRecentNjmmisCheck as RetryInboundNjmmisCheck_retryInboundNjmmisCheck_mostRecentNjmmisCheckOrig} from '../types/RetryInboundNjmmisCheck'
import {RetryInboundNjmmisCheck_retryInboundNjmmisCheck_personFormData as RetryInboundNjmmisCheck_retryInboundNjmmisCheck_personFormDataOrig} from '../types/RetryInboundNjmmisCheck'
import {RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person as RetryInboundNjmmisCheck_retryInboundNjmmisCheck_personOrig} from '../types/RetryInboundNjmmisCheck'
import {AdditionalData} from 'utils/getAdditionalData'
import {parseAdditionalData} from 'utils/getAdditionalData'
import {serializeAdditionalData} from 'utils/getAdditionalData'
import formatISO from 'date-fns/formatISO'
import {parseDate} from 'utils/form/fieldTypes'
import {formatISODate} from 'utils/date'
import {RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_mostRecentEligibilityDeterminations as RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_mostRecentEligibilityDeterminationsOrig} from '../types/RetryInboundNjmmisCheck'
import {BenefitType} from 'utils/benefits'
import {deserializeBenefit} from 'graphql/converters'
import {serializeBenefit} from 'graphql/converters'
import {RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_openApplications as RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_openApplicationsOrig} from '../types/RetryInboundNjmmisCheck'
import {RetryInboundNjmmisCheck_retryInboundNjmmisCheck_completedBy as RetryInboundNjmmisCheck_retryInboundNjmmisCheck_completedByOrig} from '../types/RetryInboundNjmmisCheck'
import {RetryInboundNjmmisCheck_retryInboundNjmmisCheck_startedBy as RetryInboundNjmmisCheck_retryInboundNjmmisCheck_startedByOrig} from '../types/RetryInboundNjmmisCheck'
export interface RetryInboundNjmmisCheck_retryInboundNjmmisCheck_startedBy {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_startedBy = (
  serialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_startedByOrig
): RetryInboundNjmmisCheck_retryInboundNjmmisCheck_startedBy => ({
  ...serialized,
})
export const serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_startedBy = (
  deserialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_startedBy
): RetryInboundNjmmisCheck_retryInboundNjmmisCheck_startedByOrig => ({
  ...deserialized,
})
export interface RetryInboundNjmmisCheck_retryInboundNjmmisCheck_completedBy {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_completedBy = (
  serialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_completedByOrig
): RetryInboundNjmmisCheck_retryInboundNjmmisCheck_completedBy => ({
  ...serialized,
})
export const serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_completedBy = (
  deserialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_completedBy
): RetryInboundNjmmisCheck_retryInboundNjmmisCheck_completedByOrig => ({
  ...deserialized,
})
export interface RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_openApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_openApplications = (
  serialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_openApplicationsOrig
): RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_openApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_openApplications = (
  deserialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_openApplications
): RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_openApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_mostRecentEligibilityDeterminations {
  __typename: 'EligibilityDetermination'
  id: string
  benefit: string
  status: string
  reasonOrInfo: string | null
  secondaryInfo: string | null
  householdSizeDescription: string | null
  month: Date | null
  numMonths: number | null
  createdAt: Date
  additionalData: AdditionalData | null
}
export const deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_mostRecentEligibilityDeterminations = (
  serialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_mostRecentEligibilityDeterminationsOrig
): RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_mostRecentEligibilityDeterminations => ({
  ...serialized,
  month:
    serialized.month != null ? parseDate(serialized.month) : serialized.month,
  createdAt: parseDate(serialized.createdAt),
  additionalData:
    serialized.additionalData != null
      ? parseAdditionalData(serialized.additionalData)
      : serialized.additionalData,
})
export const serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_mostRecentEligibilityDeterminations = (
  deserialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_mostRecentEligibilityDeterminations
): RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_mostRecentEligibilityDeterminationsOrig => ({
  ...deserialized,
  month:
    deserialized.month != null
      ? formatISODate(deserialized.month)
      : deserialized.month,
  createdAt: formatISO(deserialized.createdAt),
  additionalData:
    deserialized.additionalData != null
      ? serializeAdditionalData(deserialized.additionalData)
      : deserialized.additionalData,
})
export interface RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  middleName: string | null
  lastName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  openApplications: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_openApplications[]
  mostRecentEligibilityDeterminations: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_mostRecentEligibilityDeterminations[]
}
export const deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_person = (
  serialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_personOrig
): RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  openApplications: serialized.openApplications.map(
    deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_openApplications
  ),
  mostRecentEligibilityDeterminations: serialized.mostRecentEligibilityDeterminations.map(
    deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_mostRecentEligibilityDeterminations
  ),
})
export const serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_person = (
  deserialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person
): RetryInboundNjmmisCheck_retryInboundNjmmisCheck_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  openApplications: deserialized.openApplications.map(
    serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_openApplications
  ),
  mostRecentEligibilityDeterminations: deserialized.mostRecentEligibilityDeterminations.map(
    serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_person_mostRecentEligibilityDeterminations
  ),
})
export interface RetryInboundNjmmisCheck_retryInboundNjmmisCheck_personFormData {
  __typename: 'InboundPersonFormData'
  firstName: string | null
  lastName: string | null
  dob: Date | null
  phoneNumber: string | null
}
export const deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_personFormData = (
  serialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_personFormDataOrig
): RetryInboundNjmmisCheck_retryInboundNjmmisCheck_personFormData => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
})
export const serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_personFormData = (
  deserialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_personFormData
): RetryInboundNjmmisCheck_retryInboundNjmmisCheck_personFormDataOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
})
export interface RetryInboundNjmmisCheck_retryInboundNjmmisCheck_mostRecentNjmmisCheck {
  __typename: 'NjmmisCheck'
  id: string
  status: NjmmisCheckStatus
  medicaidId: string | null
  eligibleServices: string[] | null
}
export const deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_mostRecentNjmmisCheck = (
  serialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_mostRecentNjmmisCheckOrig
): RetryInboundNjmmisCheck_retryInboundNjmmisCheck_mostRecentNjmmisCheck => ({
  ...serialized,
})
export const serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_mostRecentNjmmisCheck = (
  deserialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_mostRecentNjmmisCheck
): RetryInboundNjmmisCheck_retryInboundNjmmisCheck_mostRecentNjmmisCheckOrig => ({
  ...deserialized,
})
export interface RetryInboundNjmmisCheck_retryInboundNjmmisCheck_account {
  __typename: 'Account'
  id: string
  department: string
  insuranceType: string
}
export const deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_account = (
  serialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_accountOrig
): RetryInboundNjmmisCheck_retryInboundNjmmisCheck_account => ({...serialized})
export const serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_account = (
  deserialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_account
): RetryInboundNjmmisCheck_retryInboundNjmmisCheck_accountOrig => ({
  ...deserialized,
})
export interface RetryInboundNjmmisCheck_retryInboundNjmmisCheck {
  __typename: 'Inbound'
  id: string
  reason: string
  dateOfService: Date | null
  serviceType: ServiceType | null
  createdAt: Date
  lastIntakeWebformReceivedAt: Date | null
  inboundType: InboundType
  insuranceOnDateOfService: InboundInsuranceOnDateOfService | null
  startedBy: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_startedBy | null
  startedAt: Date | null
  completedBy: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_completedBy | null
  completedAt: Date | null
  person: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_person | null
  personFormData: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_personFormData | null
  mostRecentNjmmisCheck: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_mostRecentNjmmisCheck | null
  account: RetryInboundNjmmisCheck_retryInboundNjmmisCheck_account | null
}
export const deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck = (
  serialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheckOrig
): RetryInboundNjmmisCheck_retryInboundNjmmisCheck => ({
  ...serialized,
  dateOfService:
    serialized.dateOfService != null
      ? parseDate(serialized.dateOfService)
      : serialized.dateOfService,
  createdAt: parseDate(serialized.createdAt),
  lastIntakeWebformReceivedAt:
    serialized.lastIntakeWebformReceivedAt != null
      ? parseDate(serialized.lastIntakeWebformReceivedAt)
      : serialized.lastIntakeWebformReceivedAt,
  startedBy:
    serialized.startedBy != null
      ? deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_startedBy(
          serialized.startedBy
        )
      : serialized.startedBy,
  startedAt:
    serialized.startedAt != null
      ? parseDate(serialized.startedAt)
      : serialized.startedAt,
  completedBy:
    serialized.completedBy != null
      ? deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_completedBy(
          serialized.completedBy
        )
      : serialized.completedBy,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
  person:
    serialized.person != null
      ? deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_person(
          serialized.person
        )
      : serialized.person,
  personFormData:
    serialized.personFormData != null
      ? deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_personFormData(
          serialized.personFormData
        )
      : serialized.personFormData,
  mostRecentNjmmisCheck:
    serialized.mostRecentNjmmisCheck != null
      ? deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_mostRecentNjmmisCheck(
          serialized.mostRecentNjmmisCheck
        )
      : serialized.mostRecentNjmmisCheck,
  account:
    serialized.account != null
      ? deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_account(
          serialized.account
        )
      : serialized.account,
})
export const serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck = (
  deserialized: RetryInboundNjmmisCheck_retryInboundNjmmisCheck
): RetryInboundNjmmisCheck_retryInboundNjmmisCheckOrig => ({
  ...deserialized,
  dateOfService:
    deserialized.dateOfService != null
      ? formatISODate(deserialized.dateOfService)
      : deserialized.dateOfService,
  createdAt: formatISO(deserialized.createdAt),
  lastIntakeWebformReceivedAt:
    deserialized.lastIntakeWebformReceivedAt != null
      ? formatISO(deserialized.lastIntakeWebformReceivedAt)
      : deserialized.lastIntakeWebformReceivedAt,
  startedBy:
    deserialized.startedBy != null
      ? serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_startedBy(
          deserialized.startedBy
        )
      : deserialized.startedBy,
  startedAt:
    deserialized.startedAt != null
      ? formatISO(deserialized.startedAt)
      : deserialized.startedAt,
  completedBy:
    deserialized.completedBy != null
      ? serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_completedBy(
          deserialized.completedBy
        )
      : deserialized.completedBy,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
  person:
    deserialized.person != null
      ? serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_person(
          deserialized.person
        )
      : deserialized.person,
  personFormData:
    deserialized.personFormData != null
      ? serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_personFormData(
          deserialized.personFormData
        )
      : deserialized.personFormData,
  mostRecentNjmmisCheck:
    deserialized.mostRecentNjmmisCheck != null
      ? serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_mostRecentNjmmisCheck(
          deserialized.mostRecentNjmmisCheck
        )
      : deserialized.mostRecentNjmmisCheck,
  account:
    deserialized.account != null
      ? serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck_account(
          deserialized.account
        )
      : deserialized.account,
})
export interface RetryInboundNjmmisCheck {
  retryInboundNjmmisCheck: RetryInboundNjmmisCheck_retryInboundNjmmisCheck
}
export const deserializeRetryInboundNjmmisCheck = (
  serialized: RetryInboundNjmmisCheckOrig
): RetryInboundNjmmisCheck => ({
  ...serialized,
  retryInboundNjmmisCheck: deserializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck(
    serialized.retryInboundNjmmisCheck
  ),
})
export const serializeRetryInboundNjmmisCheck = (
  deserialized: RetryInboundNjmmisCheck
): RetryInboundNjmmisCheckOrig => ({
  ...deserialized,
  retryInboundNjmmisCheck: serializeRetryInboundNjmmisCheck_retryInboundNjmmisCheck(
    deserialized.retryInboundNjmmisCheck
  ),
})
export interface RetryInboundNjmmisCheckVariables {
  id: string
}
export const deserializeRetryInboundNjmmisCheckVariables = (
  serialized: RetryInboundNjmmisCheckVariablesOrig
): RetryInboundNjmmisCheckVariables => ({...serialized})
export const serializeRetryInboundNjmmisCheckVariables = (
  deserialized: RetryInboundNjmmisCheckVariables
): RetryInboundNjmmisCheckVariablesOrig => ({...deserialized})
