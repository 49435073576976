import React, {FC} from 'react'
import {flowMax, addDisplayName, addWrapper} from 'ad-hok'

import {addTranslationHelpers} from 'utils/i18n'
import {addClasses, makeClasses} from 'theme'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogContent from 'components/DialogContent'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import AuditCsvUploader from 'components/AuditCsvUploader'

const classes = makeClasses((theme) => ({
  contentContainer: {
    width: 600,
  },
  error: {
    color: '#f44336',
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  open: boolean
  onClose: () => void
  onUploadSuccess: () => void
  isSubmitting: boolean
}

const AuditCsvDialog: FC<Props> = flowMax(
  addDisplayName('AuditCsvDialog'),
  addTranslationHelpers,
  addClasses(classes),
  addWrapper((render, {open, onClose, classes, t}) => (
    <Dialog open={open} onClose={onClose} scroll="paper">
      <DialogTitle>{t('auditCsvDialog.title')}</DialogTitle>
      <DialogContent className={classes.contentContainer}>
        {render()}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('auditCsvDialog.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )),
  ({onUploadSuccess}) => (
    <>
      <AuditCsvUploader onFileUpload={onUploadSuccess} />
    </>
  )
)

export default AuditCsvDialog
