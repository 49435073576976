import {Person_person_openApplications_RyanWhiteApplication} from 'graphql/deserializedTypes/Person'
import {isUnder18} from 'utils/age'

export type RyanWhiteApplication = Omit<
  Person_person_openApplications_RyanWhiteApplication,
  'eligibilityDeterminations' | 'person'
>

export type RyanWhiteApplicationWithInitialDateOfService = RyanWhiteApplication & {
  initialDateOfService: Date
}

export const isMinorCharityCare = (
  {
    dob,
    isStudent,
  }: {
    dob: Date | null
    isStudent: boolean | null
  },
  {
    livesWithParents,
  }: {
    livesWithParents: boolean
  }
) => dob && (isUnder18(dob) || (isStudent && livesWithParents))

export const isRyanWhiteApplicationWithInitialDateOfService = (
  application: RyanWhiteApplication
): application is RyanWhiteApplicationWithInitialDateOfService =>
  !!application.initialDateOfService
