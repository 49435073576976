/* eslint-disable */
import {DeleteBenefitOutcomeVariables as DeleteBenefitOutcomeVariablesOrig} from '../types/DeleteBenefitOutcome'
import {DeleteBenefitOutcome as DeleteBenefitOutcomeOrig} from '../types/DeleteBenefitOutcome'
import {DeleteBenefitOutcome_deleteBenefitOutcome as DeleteBenefitOutcome_deleteBenefitOutcomeOrig} from '../types/DeleteBenefitOutcome'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL mutation operation: DeleteBenefitOutcome
// ====================================================
export interface DeleteBenefitOutcome_deleteBenefitOutcome {
  __typename: 'BenefitOutcome'
  id: string
}
export const deserializeDeleteBenefitOutcome_deleteBenefitOutcome = (
  serialized: DeleteBenefitOutcome_deleteBenefitOutcomeOrig
): DeleteBenefitOutcome_deleteBenefitOutcome => ({...serialized})
export const serializeDeleteBenefitOutcome_deleteBenefitOutcome = (
  deserialized: DeleteBenefitOutcome_deleteBenefitOutcome
): DeleteBenefitOutcome_deleteBenefitOutcomeOrig => ({...deserialized})
export interface DeleteBenefitOutcome {
  /**
   * Delete benefit outcome
   */
  deleteBenefitOutcome: DeleteBenefitOutcome_deleteBenefitOutcome
}
export const deserializeDeleteBenefitOutcome = (
  serialized: DeleteBenefitOutcomeOrig
): DeleteBenefitOutcome => ({
  ...serialized,
  deleteBenefitOutcome: deserializeDeleteBenefitOutcome_deleteBenefitOutcome(
    serialized.deleteBenefitOutcome
  ),
})
export const serializeDeleteBenefitOutcome = (
  deserialized: DeleteBenefitOutcome
): DeleteBenefitOutcomeOrig => ({
  ...deserialized,
  deleteBenefitOutcome: serializeDeleteBenefitOutcome_deleteBenefitOutcome(
    deserialized.deleteBenefitOutcome
  ),
})
export interface DeleteBenefitOutcomeVariables {
  id: string
}
export const deserializeDeleteBenefitOutcomeVariables = (
  serialized: DeleteBenefitOutcomeVariablesOrig
): DeleteBenefitOutcomeVariables => ({...serialized})
export const serializeDeleteBenefitOutcomeVariables = (
  deserialized: DeleteBenefitOutcomeVariables
): DeleteBenefitOutcomeVariablesOrig => ({...deserialized})
