/* eslint-disable */
import {SendWebformLinkVariables as SendWebformLinkVariablesOrig} from '../types/SendWebformLink'
import {SendWebformLink as SendWebformLinkOrig} from '../types/SendWebformLink'
import {SendWebformLink_sendWebformLink as SendWebformLink_sendWebformLinkOrig} from '../types/SendWebformLink'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL mutation operation: SendWebformLink
// ====================================================
export interface SendWebformLink_sendWebformLink {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializeSendWebformLink_sendWebformLink = (
  serialized: SendWebformLink_sendWebformLinkOrig
): SendWebformLink_sendWebformLink => ({...serialized})
export const serializeSendWebformLink_sendWebformLink = (
  deserialized: SendWebformLink_sendWebformLink
): SendWebformLink_sendWebformLinkOrig => ({...deserialized})
export interface SendWebformLink {
  sendWebformLink: SendWebformLink_sendWebformLink
}
export const deserializeSendWebformLink = (
  serialized: SendWebformLinkOrig
): SendWebformLink => ({
  ...serialized,
  sendWebformLink: deserializeSendWebformLink_sendWebformLink(
    serialized.sendWebformLink
  ),
})
export const serializeSendWebformLink = (
  deserialized: SendWebformLink
): SendWebformLinkOrig => ({
  ...deserialized,
  sendWebformLink: serializeSendWebformLink_sendWebformLink(
    deserialized.sendWebformLink
  ),
})
export interface SendWebformLinkVariables {
  phoneNumberId: string
  webformId: string
}
export const deserializeSendWebformLinkVariables = (
  serialized: SendWebformLinkVariablesOrig
): SendWebformLinkVariables => ({...serialized})
export const serializeSendWebformLinkVariables = (
  deserialized: SendWebformLinkVariables
): SendWebformLinkVariablesOrig => ({...deserialized})
