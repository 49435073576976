/* eslint-disable */
import {DeleteTaskVariables as DeleteTaskVariablesOrig} from '../types/DeleteTask'
import {DeleteTask as DeleteTaskOrig} from '../types/DeleteTask'
import {DeleteTask_deleteTask as DeleteTask_deleteTaskOrig} from '../types/DeleteTask'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL mutation operation: DeleteTask
// ====================================================
export interface DeleteTask_deleteTask {
  __typename: 'Task'
  id: string
}
export const deserializeDeleteTask_deleteTask = (
  serialized: DeleteTask_deleteTaskOrig
): DeleteTask_deleteTask => ({...serialized})
export const serializeDeleteTask_deleteTask = (
  deserialized: DeleteTask_deleteTask
): DeleteTask_deleteTaskOrig => ({...deserialized})
export interface DeleteTask {
  /**
   * Delete task
   */
  deleteTask: DeleteTask_deleteTask
}
export const deserializeDeleteTask = (
  serialized: DeleteTaskOrig
): DeleteTask => ({
  ...serialized,
  deleteTask: deserializeDeleteTask_deleteTask(serialized.deleteTask),
})
export const serializeDeleteTask = (
  deserialized: DeleteTask
): DeleteTaskOrig => ({
  ...deserialized,
  deleteTask: serializeDeleteTask_deleteTask(deserialized.deleteTask),
})
export interface DeleteTaskVariables {
  id: string
}
export const deserializeDeleteTaskVariables = (
  serialized: DeleteTaskVariablesOrig
): DeleteTaskVariables => ({...serialized})
export const serializeDeleteTaskVariables = (
  deserialized: DeleteTaskVariables
): DeleteTaskVariablesOrig => ({...deserialized})
