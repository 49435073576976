import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addDefaultProps,
  addStateHandlers,
} from 'ad-hok'
import {addInterval} from 'ad-hok-utils'

import {addTranslationHelpers} from 'utils/i18n'
import Caption from 'components/Caption'
import {formatTimeAgo} from 'utils/date'

const REFRESH_INTERVAL = 10 * 1000

interface Props {
  time: Date
  translationKey?: string
}

const TimeAgo: FC<Props> = flowMax(
  addDisplayName('TimeAgo'),
  addDefaultProps({
    translationKey: 'form.lastSaved',
  }),
  addStateHandlers(
    {
      count: 0,
    },
    {
      triggerRerender: ({count}) => () => ({
        count: count + 1,
      }),
    }
  ),
  addInterval(
    ({triggerRerender}) => () => {
      triggerRerender()
    },
    REFRESH_INTERVAL
  ),
  addTranslationHelpers,
  ({time, translationKey, t}) => (
    <Caption>
      {t(translationKey, {
        time: formatTimeAgo(time),
      })}
    </Caption>
  )
)

export default TimeAgo
