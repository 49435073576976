import React, {FC} from 'react'
import {flowMax, addDisplayName} from 'ad-hok'

import {addClasses, makeClasses} from 'theme'
import Grid from 'components/Grid'
import Page from 'components/Page'
import SignInControls from 'components/SignInControls'
import {addRedirectIfSignedIn} from 'utils/auth0'

const classes = makeClasses({
  container: {
    flexGrow: 1,
    flexBasis: '150px',
  },
})

const Login: FC = flowMax(
  addDisplayName('Login'),
  addRedirectIfSignedIn('/'),
  addClasses(classes),
  ({classes}) => (
    <Page>
      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.container}
      >
        <Grid item>
          <SignInControls />
        </Grid>
      </Grid>
    </Page>
  )
)

export default Login
