import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addHandlers,
  addMemoBoundary,
  addDefaultProps,
} from 'ad-hok'
import {getContextHelpersFromInitialValues} from 'ad-hok-utils'
import cx from 'classnames'

import {addTranslationHelpers} from 'utils/i18n'
import Subtitle2 from 'components/Subtitle2'
import {makeClasses, addClasses} from 'theme'
import {addFormContext} from 'utils/form/context'
import {addFormPrefixContext} from 'components/FormPrefix'

const classes = makeClasses((theme) => ({
  label: {
    marginBottom: theme.spacing(2),
  },
  container: {
    marginBottom: theme.spacing(5),
  },
}))

const {
  addProvider: addFormSectionContextProvider,
  addConsumer: addFormSectionContext,
} = getContextHelpersFromInitialValues({
  formSectionName: '',
})

export {addFormSectionContext}

interface Props {
  labelTranslationKey: string
  'data-testid'?: string
  className?: string
  shouldMemoize?: boolean
}

const FormSection: FC<Props> = flowMax(
  addDisplayName('FormSection'),
  addDefaultProps({
    shouldMemoize: true,
  }),
  addMemoBoundary((prevProps, props) => {
    if (!props.shouldMemoize) return false
    if (
      props.labelTranslationKey !== prevProps.labelTranslationKey ||
      props['data-testid'] !== prevProps['data-testid'] ||
      props.className !== prevProps.className
    )
      return false
    return true
  }),
  addTranslationHelpers,
  addFormPrefixContext,
  addProps(
    ({labelTranslationKey, formPrefix}) => ({
      formSectionName: `${formPrefix}${labelTranslationKey}`,
    }),
    ['labelTranslationKey', 'formPrefix']
  ),
  addFormSectionContextProvider,
  addFormContext,
  addProps(({formName, labelTranslationKey, t}) => ({
    label: t(`${formName}.sectionLabels.${labelTranslationKey}`),
  })),
  addHandlers({
    callbackRef: ({addFormSectionRef, formSectionName}) => (
      element: HTMLElement | null
    ) => {
      if (!element) return
      addFormSectionRef(formSectionName, element)
    },
  }),
  addClasses(classes),
  ({
    label,
    children,
    classes,
    callbackRef,
    'data-testid': testId,
    className,
  }) => (
    <div
      ref={callbackRef}
      className={cx(classes.container, className)}
      data-testid={testId}
    >
      <Subtitle2 className={classes.label}>{label}</Subtitle2>
      {children}
    </div>
  )
)

export default FormSection
