import {editMonitorApplicationFormSchema} from 'components/EditMonitorApplicationForm/schema'
import {makeFormSchema} from 'utils/form/schema'
import {
  makeTextField,
  makeDateField,
  makeNumberField,
  makeSelectField,
} from 'utils/form/fieldTypes'
// import {Config_config_charityCareTypes} from 'graphql/deserializedTypes/Config'
const {notes} = editMonitorApplicationFormSchema.fields.application

export const editCharityCareApplicationFormSchema = makeFormSchema({
  fields: {
    application: {
      id: makeTextField({
        isRequired: true,
      }),
      initialDateOfService: makeDateField(),
      accountNumber: makeTextField(),
      dateOfApplication: makeDateField(),
      requestedDateOfApplication: makeDateField(),
      coveragePeriod: makeNumberField(),
      assignedToId: makeSelectField({}),
      notes,
      charityCareType: makeSelectField({
        options: [
          {value: 'Full'},
          {value: 'ER-Admit'},
          {value: 'L & D'},
          {value: 'ED Treat & Release'},
        ],
      }),
    },
  },
})
