import React, {FC} from 'react'
import {flowMax, addDisplayName, addMemoBoundary} from 'ad-hok'

import {addClasses, makeClasses, sharedStyles} from 'theme'
import Grid from 'components/Grid'
import ApplicationEligibilityCheckList from 'components/ApplicationEligibilityCheckList'
import MultilineTextField from 'components/MultilineTextField'
import OpenTriages from 'components/EditPersonForm/OpenTriages'
import UnreviewedWebformMerges from 'components/EditPersonForm/UnreviewedWebformMerges'
import PersonCommunicationSection from 'components/EditPersonForm/PersonCommunicationSection'
import PersonEventLogSection from 'components/EditPersonForm/PersonEventLogSection'

const classes = makeClasses((theme) => ({
  container: sharedStyles.formRightColumnContainer,
}))

const RightColumn: FC = flowMax(
  addDisplayName('RightColumn'),
  addMemoBoundary([]),
  addClasses(classes),
  ({classes}) => (
    <Grid item className={classes.container}>
      <Grid container direction="column">
        <UnreviewedWebformMerges />
        <OpenTriages />
        <ApplicationEligibilityCheckList />
        <MultilineTextField
          name="person.note"
          rows={3}
          rowsMax={20}
          noConfirmButton
        />
        <PersonEventLogSection />
        <PersonCommunicationSection name="person.phoneNumbers"></PersonCommunicationSection>
      </Grid>
    </Grid>
  )
)

export default RightColumn
