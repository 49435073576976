import {format} from 'date-fns/fp'
import {flow} from 'lodash/fp'

import {toEasternTime} from 'utils/date'
import {getCommaSeparated} from 'utils/string'
import {PhoneNumberFields} from 'graphql/deserializedTypes/PhoneNumberFields'

type PhoneNumber = PhoneNumberFields
const getFormattedPhoneNumber = ({
  number,
  language,
  usedInApp,
  comment,
}: PhoneNumber) =>
  `${number} (${language}/${usedInApp ? 'used in app' : ''}/${comment})`

export const getFormattedPhoneNumbers = (phoneNumbers: PhoneNumber[]) =>
  getCommaSeparated(
    phoneNumbers.map((phoneNumber) => getFormattedPhoneNumber(phoneNumber))
  )

export const toDate = (date: Date | null | undefined) =>
  date ? format('M/d/yyyy')(date) : ''

export const toDatetime = flow(
  (date: Date | null | undefined) => (date ? toEasternTime(date) : null),
  (date) => (date ? format('M/d/yyyy HH:mm:ss')(date) : '')
)
