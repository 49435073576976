import React from 'react'
import {
  addContext,
  addEffect,
  addHandlers,
  addProps,
  addWrapper,
  branch,
  CurriedUnchangedProps,
  flowMax,
  renderNothing,
  SimplePropsAdder,
} from 'ad-hok'
import {
  Auth0Context,
  Auth0ContextInterface,
  Auth0Provider,
  useAuth0,
} from '@auth0/auth0-react'
import * as Sentry from '@sentry/browser'

import {AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN} from 'config/environment'
import {addNavigate} from './routing'
import {sentryEnabled} from 'config/sentry'
import {getAuthToken} from 'config/apolloClient'

interface PotentiallyTestingWindow extends Window {
  Cypress: any
}
declare let window: PotentiallyTestingWindow

export const addAuth0Provider = flowMax(
  addNavigate,
  addWrapper((render, {navigate}) => (
    <Auth0Provider
      audience={AUTH0_AUDIENCE}
      cacheLocation={window.Cypress ? 'localstorage' : 'memory'}
      clientId={AUTH0_CLIENT_ID}
      domain={AUTH0_DOMAIN}
      onRedirectCallback={(appState) =>
        navigate(appState.returnTo ?? window.location.pathname)
      }
    >
      {render()}
    </Auth0Provider>
  ))
)

type AddAuth0 = SimplePropsAdder<{
  auth0: Auth0ContextInterface
}>

export const addAuth0: AddAuth0 = addProps(() => ({
  auth0: useAuth0(),
}))

type AddAuth0Context = SimplePropsAdder<{
  auth0Context: Auth0ContextInterface
}>

export const addAuth0Context: AddAuth0Context = addContext(
  Auth0Context,
  'auth0Context'
)

type RedirectType = <TProps>(
  redirectTo: string
) => CurriedUnchangedProps<TProps>

export const addRedirectUnlessSignedIn: RedirectType = (redirectTo) =>
  flowMax(
    addAuth0Context,
    addNavigate,
    addEffect(
      ({auth0Context: {isAuthenticated, isLoading}, navigate}) => () => {
        if (!isLoading && !isAuthenticated) {
          if (sentryEnabled) {
            Sentry.captureMessage('user redirected to login', {
              level: Sentry.Severity.Log,
              extra: {
                token: getAuthToken(),
              },
              tags: {
                logout_debug: 'true',
              },
            })
          }
          navigate(redirectTo)
        }
      }
    ),
    branch(
      ({auth0Context: {isAuthenticated, isLoading}}) =>
        !isLoading && !isAuthenticated,
      renderNothing()
    )
  )

export const addRedirectIfSignedIn: RedirectType = (redirectTo) =>
  flowMax(
    addAuth0Context,
    addNavigate,
    addEffect(({auth0Context: {isAuthenticated}, navigate}) => () => {
      if (isAuthenticated) {
        navigate(redirectTo)
      }
    }),
    branch(
      ({auth0Context: {isAuthenticated}}) => isAuthenticated,
      renderNothing()
    )
  )

type AddSignOut = SimplePropsAdder<{
  signOut: (callback?: () => void) => void
}>

export const addSignOut: AddSignOut = flowMax(
  addAuth0Context,
  addHandlers({
    signOut: ({auth0Context: {logout}}) => (callback?: () => void) => {
      logout({returnTo: `${window.location.origin}/login`})
      fetch('/session', {method: 'DELETE'}).then(() => {
        callback?.()
      })
    },
  })
)
