import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addStateHandlers,
  addHandlers,
} from 'ad-hok'
import {generatePath} from 'react-router'
import {PureQueryOptions} from 'apollo-boost'
import {format} from 'date-fns/fp'
import {truncate} from 'lodash/fp'

import {makeClasses, addClasses} from 'theme'
import {addTranslationHelpers} from 'utils/i18n'
import {APPLICATION_STATUS_CLOSED} from 'utils/applicationStatuses'
import {getLongDate, formatShortDateTimeEastern} from 'utils/date'
import Paper from 'components/Paper'
import Grid from 'components/Grid'
import Heading from 'components/Heading'
import Button from 'components/Button'
import Link from 'components/Link'
import Tooltip from 'components/Tooltip'
import LinkButton from 'components/LinkButton'
import {editApplicationPath} from 'components/TopLevelRoutes'
import ApplicationStatusUpdateSelect from './ApplicationStatusUpdateSelect'
import ApplicationTitle from 'components/ApplicationTitle'
import DisplayItem from 'components/DisplayItem'
import LabeledDisplayItem from 'components/LabeledDisplayItem'
import Caption from 'components/Caption'
import {
  Applications_applications_MedicaidApplication,
  Applications_applications_MonitorApplication,
  Applications_applications,
} from 'graphql/deserializedTypes/Applications'
import {addEditableFilesQuery} from 'graphql/generated'
import {addLoadingIndicator} from 'utils/dataLoading'
import {addFetchDocumentFileUrl} from 'components/DocumentFileLink'
import typedAs from 'utils/typedAs'
import {EditableFileFields} from 'graphql/deserializedTypes/EditableFileFields'
import ReadOnlyPdfDialog, {
  EditableFileWithSignedUrl,
} from 'components/ReadOnlyPdfDialog'
import {EditableFiles_editableFiles} from 'graphql/deserializedTypes/EditableFiles'

const classes = makeClasses((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  applicationId: {
    fontWeight: 'bold',
  },
  createdAt: {
    fontWeight: 'bold',
  },
  actionContainer: {
    alignSelf: 'flex-end',
  },
  titleHeader: {
    marginBottom: theme.spacing(2),
  },
  rightColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(5),
  },
  rightColumnInnerContainer: {
    flex: 1,
  },
  statusContainer: {
    marginBottom: theme.spacing(2),
  },
  closeDate: {
    marginTop: theme.spacing(1),
  },
  leftColumnsContainer: {
    flex: 1,
  },
  leftColumnContainer: {
    flex: 1,
    maxWidth: 350,
  },
  unreviewed: {
    fontWeight: 700,
  },
  reviewed: {
    fontWeight: 400,
  },
}))

interface FilenameLinkProps {
  editableFile: EditableFiles_editableFiles
  onClick: () => void
}

const FilenameLink: FC<FilenameLinkProps> = flowMax(
  addDisplayName('FilenameLink'),
  addProps(({editableFile: {filename}}) => ({
    filenameFormatted: truncate({length: 35}, filename),
  })),
  addClasses(classes),
  ({
    onClick,
    editableFile: {isUnreviewed, filename},
    filenameFormatted,
    classes,
  }) => (
    <Tooltip title={filename}>
      <span>
        <LinkButton
          onClick={onClick}
          className={isUnreviewed ? classes.unreviewed : classes.reviewed}
        >
          {filenameFormatted}
        </LinkButton>
      </span>
    </Tooltip>
  )
)

const isMedicaidOrMonitorApplication = (
  application: Applications_applications
): application is
  | Applications_applications_MedicaidApplication
  | Applications_applications_MonitorApplication =>
  ['medicaid', 'monitor'].includes(application.benefit)

interface Props {
  application: Applications_applications
  label?: string
  refetchQueriesOnStatusUpdate?: PureQueryOptions[]
}

const ApplicationItem: FC<Props> = flowMax(
  addDisplayName('ApplicationItem'),
  addProps(
    ({application: {id, createdAt}}) => ({
      actionUrl: generatePath(editApplicationPath, {id}),
      createdAt: getLongDate(createdAt),
    }),
    ['application']
  ),
  addProps(({application}) => ({
    benefitOutcomes: application.person.benefitOutcomes.filter(
      (benefitOutcome) => benefitOutcome.application?.id === application.id
    ),
  })),
  addProps(({application: {id: applicationId}}) => ({
    variables: {applicationId},
  })),
  addEditableFilesQuery({
    variables: ({variables}) => variables,
  }),
  addStateHandlers(
    {
      selectedFile: typedAs<EditableFileWithSignedUrl | null>(null),
    },
    {
      setSelectedFile: () => (selectedFile: EditableFileWithSignedUrl) => ({
        selectedFile,
      }),
      onEditPdfDialogClose: () => () => ({
        selectedFile: null,
      }),
    }
  ),
  addFetchDocumentFileUrl,
  addHandlers({
    onSelect: ({fetchDocumentFileUrl, setSelectedFile}) => async (
      editableFile: EditableFileFields
    ) => {
      const signedUrl = await fetchDocumentFileUrl({
        fileKey: editableFile.fileKey,
      })
      setSelectedFile({...editableFile, signedUrl})
    },
  }),
  addLoadingIndicator({}),
  addTranslationHelpers,
  addClasses(classes),
  ({
    application: {id, assignedTo},
    application,
    actionUrl,
    t,
    classes,
    createdAt,
    label,
    refetchQueriesOnStatusUpdate,
    benefitOutcomes,
    selectedFile,
    onSelect,
    onEditPdfDialogClose,
    editableFiles,
  }) => (
    <>
      <Paper className={classes.paper} data-testid={`application-card-${id}`}>
        <Grid container direction="row" justify="space-between">
          <div className={classes.leftColumnsContainer}>
            {label && <Caption>{label}</Caption>}
            <Heading
              variant="h5"
              component="h2"
              className={classes.titleHeader}
            >
              <ApplicationTitle
                application={application}
                shouldShowMcdType
                shouldShowSlideType
                shouldLinkToPerson={false}
              />
            </Heading>
            <Grid container direction="row">
              <div className={classes.leftColumnsContainer}>
                {!!assignedTo?.name && (
                  <DisplayItem
                    i18nKey="applicationItem.assignedTo"
                    translations={{
                      name: assignedTo.name,
                    }}
                  />
                )}
                <DisplayItem
                  i18nKey="applicationItem.applicationId"
                  translations={{
                    id,
                  }}
                />
                <DisplayItem
                  i18nKey="applicationItem.createdAt"
                  translations={{
                    createdAt,
                  }}
                />
              </div>
              <div className={classes.leftColumnsContainer}>
                <Grid item justify="space-between">
                  <LabeledDisplayItem
                    label={t('applicationItem.initialDateOfService')}
                    value={
                      application.initialDateOfService
                        ? format('M/d/yyyy')(application.initialDateOfService)
                        : null
                    }
                  />
                  {isMedicaidOrMonitorApplication(application) && (
                    <>
                      <LabeledDisplayItem
                        label={t('applicationItem.policyId')}
                        value={application.policyId}
                      />
                      <LabeledDisplayItem
                        label={t('applicationItem.confirmationNumber')}
                        value={application.confirmationNumber}
                      />
                    </>
                  )}
                </Grid>
              </div>
              <div className={classes.leftColumnsContainer}>
                <Grid item justify="space-between">
                  <>
                    <LabeledDisplayItem
                      label="DOB"
                      value={
                        application.person.dob
                          ? format('M/d/yyyy')(application.person.dob)
                          : null
                      }
                    />
                    <LabeledDisplayItem
                      label="MRN"
                      value={application.person.hospitalPatientId}
                    />
                  </>
                </Grid>
              </div>
              <div className={classes.leftColumnsContainer}>
                <Grid item>
                  {benefitOutcomes.map((benefitOutcome) => (
                    <>
                      <LabeledDisplayItem
                        label="Benefit Start"
                        value={
                          benefitOutcome.effectiveStartDate
                            ? format('M/d/yyyy')(
                                benefitOutcome.effectiveStartDate
                              )
                            : null
                        }
                      />
                      <LabeledDisplayItem
                        label="Benefit End"
                        value={
                          benefitOutcome.effectiveEndDate
                            ? format('M/d/yyyy')(
                                benefitOutcome.effectiveEndDate
                              )
                            : null
                        }
                      />
                      {benefitOutcome.benefit !== 'medicaid' && (
                        <LabeledDisplayItem
                          label={t('applicationItem.patientResponsibility')}
                          value={
                            benefitOutcome.patientResponsibility !==
                              undefined &&
                            benefitOutcome.patientResponsibility !== null
                              ? `${benefitOutcome.patientResponsibility}%`
                              : null
                          }
                        />
                      )}
                    </>
                  ))}
                </Grid>
              </div>
              <div className={classes.leftColumnsContainer}>
                <Grid alignItems="center">
                  {editableFiles.map((editableFile) => (
                    <>
                      {editableFile.filename.includes('FINAL') && (
                        <FilenameLink
                          editableFile={editableFile}
                          onClick={() => {
                            onSelect(editableFile)
                          }}
                        />
                      )}
                    </>
                  ))}
                </Grid>
                <ReadOnlyPdfDialog
                  personId={application.person.id}
                  file={selectedFile}
                  onClose={onEditPdfDialogClose}
                  application={application}
                />
              </div>
            </Grid>
          </div>

          <Grid item className={classes.rightColumnContainer}>
            <Grid
              container
              direction="column"
              justify="space-between"
              className={classes.rightColumnInnerContainer}
            >
              <Grid
                container
                direction="column"
                className={classes.statusContainer}
              >
                <ApplicationStatusUpdateSelect
                  application={application}
                  refetchQueries={
                    refetchQueriesOnStatusUpdate
                      ? () => refetchQueriesOnStatusUpdate
                      : undefined
                  }
                />
                {application.status === APPLICATION_STATUS_CLOSED && (
                  <Caption className={classes.closeDate}>
                    {t('applicationItem.closeDate', {
                      date: formatShortDateTimeEastern(application.updatedAt),
                    })}
                  </Caption>
                )}
              </Grid>
              <Grid item className={classes.actionContainer}>
                <Button
                  color="primary"
                  component={Link}
                  variant="contained"
                  to={actionUrl}
                >
                  {t('applicationItem.actionButtonLabel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
)

export default ApplicationItem
