import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addStateHandlers,
  addHandlers,
  addEffect,
  addWrapper,
  addProps,
} from 'ad-hok'

import {makeFormSchema, ExtractFormSchemaFields} from 'utils/form/schema'
import {makeTextField} from 'utils/form/fieldTypes'
import {ReceivedWebforms_receivedWebforms} from 'graphql/deserializedTypes/ReceivedWebforms'
import {addUpdateReceivedWebformMutation} from 'graphql/generated'
import {addTranslationHelpers} from 'utils/i18n'
import Form, {OnSubmitSuccessOptions} from 'components/Form'
import addHasSaved from 'utils/addHasSaved'
import {addClasses, makeClasses} from 'theme'
import Dialog from 'components/Dialog'
import {addFormikTyped} from 'utils/form/formik'
import DialogContent from 'components/DialogContent'
import TextField from 'components/TextField'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import {unmatchedWebformsRefetchOptions} from 'components/UnmatchedWebformsWorklist/shared'
import {SnackbarProfileLink} from 'components/SnackbarProfileLink'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'

const classes = makeClasses((theme) => ({
  modalContainer: {
    minWidth: 400,
  },
}))

const hospitalPatientIdFormSchema = makeFormSchema({
  fields: {
    receivedWebform: {
      hospitalPatientId: makeTextField({isRequired: true}),
    },
  },
})

interface Props {
  receivedWebform: ReceivedWebforms_receivedWebforms
  open: boolean
  onClose: () => void
}

export const PersonHospitalPatientIdModal: FC<Props> = flowMax(
  addDisplayName('PersonHospitalPatientIdModal'),
  addUpdateReceivedWebformMutation(unmatchedWebformsRefetchOptions),
  addTranslationHelpers,
  addStateHandlers(
    {
      failureCount: 0,
    },
    {
      incrementFailureCount: ({failureCount}) => () => ({
        failureCount: failureCount + 1,
      }),
    }
  ),
  addAppSnackbarContext,
  addHandlers({
    onConfirm: ({
      onClose,
      mutateUpdateReceivedWebform,
      receivedWebform,
      incrementFailureCount,
      showSnackbarMessageAndAction,
      t,
    }) => ({
      canonicalValues: {
        receivedWebform: {hospitalPatientId},
      },
    }: OnSubmitSuccessOptions<
      ExtractFormSchemaFields<typeof hospitalPatientIdFormSchema>
    >) => {
      mutateUpdateReceivedWebform({
        variables: {
          receivedWebform: {
            id: receivedWebform.id,
            hospitalPatientId,
            noPersonMatch: false,
          },
        },
      })
        .then(({data}) => {
          if (!data) return
          const {
            updateReceivedWebform: {person},
          } = data
          if (!person) {
            window.alert(t('worklist.unmatchedWebforms.noMatchFound'))
            incrementFailureCount()
            return
          }
          onClose()
          showSnackbarMessageAndAction(
            t('worklist.unmatchedWebforms.matchedPerson', {
              personId: person.id,
            }),
            <SnackbarProfileLink personId={person.id} />
          )
        })
        .catch(() => {
          window.alert(t('worklist.unmatchedWebforms.failedToSave'))
          incrementFailureCount()
        })
    },
  }),
  addHasSaved('onConfirm'),
  addEffect(
    ({clearHasSaved}) => () => {
      clearHasSaved()
    },
    ['failureCount']
  ),
  addClasses(classes),
  addWrapper((render, {open, onClose, onConfirm, classes}) => (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{paper: classes.modalContainer}}
    >
      <Form
        name="personMatchForm"
        schema={hospitalPatientIdFormSchema}
        onSubmitSuccess={onConfirm}
      >
        {render()}
      </Form>
    </Dialog>
  )),
  addFormikTyped(hospitalPatientIdFormSchema),
  addHandlers({
    onSaveClick: ({formik: {submitForm}}) => () => {
      submitForm()
    },
  }),
  addProps(({formik: {values}, hasSaved}) => ({
    disabled: hasSaved || !values.receivedWebform.hospitalPatientId,
  })),
  ({onClose, onSaveClick, disabled, t}) => (
    <>
      <DialogContent>
        <TextField name="receivedWebform.hospitalPatientId" />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('buttons.cancel')}
        </Button>
        <Button
          onClick={onSaveClick}
          color="primary"
          variant="contained"
          disabled={disabled}
        >
          {t('buttons.save')}
        </Button>
      </DialogActions>
    </>
  )
)
