import {uniq} from 'lodash'
import {values, sum, flow, filter, map} from 'lodash/fp'
import {TFunction} from 'i18next'
import numeral from 'numeral'

import {
  Application_application,
  Application_application_MedicaidApplication,
  Application_application_MedicaidApplication_householdMembers_person,
} from 'graphql/deserializedTypes/Application'
import {Person_person} from 'graphql/deserializedTypes/Person'
import {PerIncomeSource} from 'utils/getAdditionalData'
import {
  isCharityCareApplication,
  isMedicaidApplication,
} from 'components/EditApplicationForm'
import {FederalPovertyLevels_federalPovertyLevels} from 'graphql/deserializedTypes/FederalPovertyLevels'
import {SlideApplicationRules_slideApplicationRules} from 'graphql/deserializedTypes/SlideApplicationRules'
import {callWith, mapKeysWithKeyUncurried} from 'utils/fp'
import {
  INCOME_FREQUENCY_WEEKLY,
  INCOME_FREQUENCY_BIWEEKLY,
  INCOME_FREQUENCY_TWICE_A_MONTH,
  INCOME_FREQUENCY_MONTHLY,
  INCOME_SOURCE_TYPE_SALARY_WAGES,
  INCOME_SOURCE_TYPE_FREELANCE,
  INCOME_SOURCE_TYPE_SELF_EMPLOYED,
  INCOME_SOURCE_TYPE_UNEMPLOYMENT,
  INCOME_SOURCE_TYPE_PENSION,
  INCOME_SOURCE_TYPE_SOCIAL_SECURITY_DISABILITY,
  INCOME_SOURCE_TYPE_SOCIAL_SECURITY_RETIREMENT,
  INCOME_SOURCE_TYPE_SSI,
  INCOME_SOURCE_TYPE_STATE_DISABILITY,
  INCOME_SOURCE_TYPE_NET_FARMING,
  INCOME_SOURCE_TYPE_RENTAL_INCOME,
  INCOME_SOURCE_TYPE_CASH_SUPPORT,
  INCOME_SOURCE_TYPE_WORKERS_COMPENSATION,
  INCOME_SOURCE_TYPE_PUBLIC_ASSISTANCE,
  INCOME_SOURCE_TYPE_VETERANS_BENEFITS,
  INCOME_SOURCE_TYPE_INSURANCE_PAYMENTS,
  INCOME_SOURCE_TYPE_ANNUITY_PAYMENTS,
  INCOME_SOURCE_TYPE_INTEREST,
  INCOME_SOURCE_TYPE_LOTTERY,
  INCOME_SOURCE_TYPE_ALIMONY,
  DEDUCTION_TYPE_ALIMONY,
  DEDUCTION_TYPE_STUDENT_LOAN_INTEREST,
  DEDUCTION_TYPE_SELF_EMPLOYMENT_TAX,
  DEDUCTION_TYPE_SELF_EMPLOYMENT_EXPENSES,
  DEDUCTION_TYPE_HEALTH_SAVINGS_ACCOUNT,
  DEDUCTION_TYPE_IRA_DEDUCTION,
  DEDUCTION_TYPE_MOVING_EXPENSES,
  DEDUCTION_TYPE_EDUCATOR_EXPENSES,
  DEDUCTION_TYPE_SELF_EMPLOYED_IRA,
  DEDUCTION_TYPE_SELF_EMPLOYED_HEALTH_INSURANCE,
  DEDUCTION_TYPE_OTHER,
  INCOME_SOURCE_TYPE_OTHER,
  INCOME_FREQUENCY_LUMP_SUM,
} from 'utils/form/fieldTypes'
import typedAs from 'utils/typedAs'
import {IncomeSourceFields} from 'graphql/deserializedTypes/IncomeSourceFields'
import {McdType} from 'graphql/deserializedTypes/globalTypes'
import {DeductionFields} from 'graphql/deserializedTypes/DeductionFields'
import {
  filterCharityCareIncomeSourceTypes,
  getAmountPerYear,
  getHouseholdMembersToCountTowardIncomeCharityCare,
  isHouseholdIncomeIneligibleFullCharityCare,
  getIsIncludedIncomeSourceCharityCare,
  filterMedicaidIncomeSourceTypes,
  isHouseholdIncomeIneligibleFullSlideWithFPL,
} from 'utils/income'
import {isCharityCareApplicationWithInitialDateOfService} from 'utils/charityCare'
import {isMinorMedicaid} from 'utils/medicaid'
import {PersonType} from 'components/EditPersonForm/schema'
import {getHouseholdMembersWithRelationshipTypes} from 'utils/householdMembers'
import {getLongDate} from './date'
//import {CountyFaxes_countyFaxes} from 'graphql/types/CountyFaxes'

const formatCurrency = (amount: number): string =>
  numeral(amount).format('0.00')

const CASH_SUPPORT_TYPE = '(CC only) Cash support'
const CHARITY_CARE_INCOME_TYPE_MAPPING = {
  'Unemployment Compensation': 'unemployment_workscomp',
  'Supplemental Security Income (SSI)': 'social_security_benefits',
  "Veteran's Benefits": 'va_benefits',
  'Public Assistance': 'public_assistance',
  [CASH_SUPPORT_TYPE]: 'other_monetary_support',
  'Social Security Disability (SSDI)': 'social_security_benefits',
  'Social Security Retirement': 'social_security_benefits',
  'Alimony/Child support': 'alimony',
  'Net Farming/Fishing': 'net_business_income',
  'Workers Compensation': 'unemployment_workscomp',
  'Insurance payments': 'insurance_annuity',
  'Annuity payments': 'insurance_annuity',
  'Interest/Dividends': 'dividends_interest',
  'Lottery or Gambling Lump Sums >$80k': 'dividends_interest',
  'Pension/retirement account/annuity': 'pension',
  'Rental income': 'rental_income',
  'State Disability': 'social_security_benefits',
  Other: 'other_income',
  'Salary/Wages (has paystub)': 'salary',
  'Freelance/Paid Cash': 'salary',
  'Self employed/Business owner': 'net_business_income',
} as const

type CharityCareIncomeType = keyof typeof CHARITY_CARE_INCOME_TYPE_MAPPING
type CharityCareMappedIncomeType = typeof CHARITY_CARE_INCOME_TYPE_MAPPING[keyof typeof CHARITY_CARE_INCOME_TYPE_MAPPING]

const MAPPED_INCOME_TYPES: CharityCareMappedIncomeType[] = uniq(
  values(CHARITY_CARE_INCOME_TYPE_MAPPING)
)

type Mapping = Record<string, string | null>

const getMappedCharityCareIncomeType = (
  incomeType: string
): CharityCareMappedIncomeType | undefined =>
  CHARITY_CARE_INCOME_TYPE_MAPPING[incomeType as CharityCareIncomeType]

const getCharityCareYearlyMapping = (
  mappedIncomeType: CharityCareMappedIncomeType,
  perIncomeSource: PerIncomeSource
): Mapping => {
  const amount = callWith(
    perIncomeSource,
    flow(
      filter(
        ({incomeType}) =>
          !!incomeType &&
          getMappedCharityCareIncomeType(incomeType) === mappedIncomeType
      ),
      map(({amount}) => amount),
      sum
    )
  )

  return {
    [`cc_${mappedIncomeType}`]: formatCurrency(amount),
    ...(amount > 0
      ? {
          [`cc_${mappedIncomeType}_yearly`]: 'Yes',
        }
      : {}),
  }
}

const getCharityCareTotalMapping = (
  perIncomeSource: PerIncomeSource
): Mapping => {
  const totalIncome = sum(perIncomeSource.map(({amount}) => amount))
  return {
    cc_total_income: formatCurrency(totalIncome),
    last_1_month_x12: formatCurrency(totalIncome),
    ...(totalIncome > 0
      ? {
          cc_monthly_income: formatCurrency(totalIncome / 12.0),
        }
      : {}),
    ...(totalIncome > 0
      ? {
          cc_total_income_yearly: 'Yes',
        }
      : {}),
  }
}

const getPayFrequencyMonthlyMultiplier = (payFrequency: string): number => {
  if (payFrequency === INCOME_FREQUENCY_WEEKLY) return 4
  if (
    [INCOME_FREQUENCY_BIWEEKLY, INCOME_FREQUENCY_TWICE_A_MONTH].includes(
      payFrequency
    )
  )
    return 2
  return 1
}

const getPayFrequencyYearlyMultiplier = (payFrequency: string): number => {
  if (payFrequency === INCOME_FREQUENCY_WEEKLY) return 52
  if (payFrequency === INCOME_FREQUENCY_BIWEEKLY) return 26
  if (payFrequency === INCOME_FREQUENCY_TWICE_A_MONTH) return 24
  if (payFrequency === INCOME_FREQUENCY_MONTHLY) return 12
  return 1 // lump sum
}

const getMonthlyAmount = ({
  amount,
  payFrequency,
}: Pick<IncomeSourceFields, 'amount' | 'payFrequency'>): number | null => {
  if (!(amount != null && amount > 0)) return null
  if (!payFrequency) return null
  return amount * getPayFrequencyMonthlyMultiplier(payFrequency)
}

const getYearlyAmount = ({
  amount,
  payFrequency,
}: Pick<IncomeSourceFields, 'amount' | 'payFrequency'>): number | null => {
  if (!(amount != null && amount > 0)) return null
  if (!payFrequency) return null
  return amount * getPayFrequencyYearlyMultiplier(payFrequency)
}

const getClientCashSupportMonthlyAmount = (
  clientCashSupportIncomeSources: IncomeSourceWithFullName[]
): string =>
  callWith(
    clientCashSupportIncomeSources,
    flow(
      map(getMonthlyAmount),
      filter((monthlyAmount) => monthlyAmount != null),
      sum,
      (totalMonthlyAmount) => formatCurrency(totalMonthlyAmount)
    )
  )

const getCharityCareCashSupportMapping = (
  incomeSources: IncomeSourceWithFullName[]
): Mapping => {
  const clientCashSupportIncomeSources = incomeSources.filter(
    ({incomeType, isClient}) => incomeType === CASH_SUPPORT_TYPE && isClient
  )
  const clientCashSupportTotal = callWith(
    clientCashSupportIncomeSources,
    flow(
      map(({amount}) => amount),
      sum
    )
  )
  return {
    cash_support: clientCashSupportTotal > 0 ? '1' : '2',
    cc_cash_support_monthly_amount: getClientCashSupportMonthlyAmount(
      clientCashSupportIncomeSources
    ),
  }
}

type WebformNestedPersonRecordType = Record<string, any>

export const getWebformRecordsFileTemplateMapping = (
  nestedPerson: WebformNestedPersonRecordType
): Mapping => ({
  ...getCharityCarePerIncomeSourceMappings(
    getWebformRecordsPerIncomeSource(nestedPerson)
  ),
  ...getCharityCareIncomeSourcesMappings(
    getWebformRecordsIncomeSources(nestedPerson)
  ),
})

const getWebformRecordsIncomeSources = (
  nestedPersonRecord: WebformNestedPersonRecordType
): IncomeSourceWithFullName[] =>
  [
    nestedPersonRecord,
    ...nestedPersonRecord.relationships.map(
      ({otherPerson}: any) => otherPerson
    ),
  ]
    .flatMap((person) =>
      person.incomeSources.map((incomeSource: any) => ({
        ...incomeSource,
        fullName: getFullName(person),
        isClient: person === nestedPersonRecord,
      }))
    )
    .filter((incomeSource) => filterCharityCareIncomeSourceTypes(incomeSource))

const getWebformRecordsPerIncomeSource = (
  nestedPersonRecord: WebformNestedPersonRecordType
): PerIncomeSource =>
  getWebformRecordsIncomeSources(nestedPersonRecord).map(
    ({id, incomeType, amount, payFrequency}) => ({
      id,
      incomeType,
      amount: getAmountPerYear({amount, frequency: payFrequency}),
      payFrequency,
      amountPer: amount,
    })
  )

const mergeWithNumberedSuffixes = (mappings: Mapping[]): Mapping =>
  mappings
    .map((mapping: Mapping, index) =>
      mapKeysWithKeyUncurried(
        (_, key: string) => `${key}_${index + 1}`,
        mapping
      )
    )
    .reduce(
      (merged: Mapping, mapping) => ({
        ...merged,
        ...mapping,
      }),
      typedAs<Mapping>({})
    )

type IncomeSourceWithFullName = Omit<
  IncomeSourceFields,
  'esignSessionUrl' | '__typename' | 'id'
> & {
  fullName: string | null
  isClient: boolean
  id: string | null
}

const getFormattedPhoneNumber = (phoneNumber: string) =>
  `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(
    6,
    10
  )}`

const getPhoneNumberAreaCode = (phoneNumber: string) => phoneNumber.slice(0, 3)

const getPhoneNumberFirst3 = (phoneNumber: string) => phoneNumber.slice(3, 6)

const getPhoneNumberLast4 = (phoneNumber: string) => phoneNumber.slice(6, 10)

const getCharityCareNoProofOfIncomeMapping = (
  incomeSources: IncomeSourceWithFullName[]
): Mapping => {
  const noProofOfIncomeIncomeSources = incomeSources.filter(
    ({proofOfIncome}) => proofOfIncome === false
  )
  return mergeWithNumberedSuffixes(
    noProofOfIncomeIncomeSources.map(
      ({
        startMonth,
        startYear,
        endMonth,
        endYear,
        fullName,
        employerName,
        amount,
        payFrequency,
        employerAddressStreet,
        employerAddressCity,
        employerAddressState,
        employerAddressZip,
        employerPhone,
        noProofOfIncomeReason,
      }) => ({
        no_proof_income_person_name: fullName,
        no_proof_income_start:
          startMonth && startYear ? `${startMonth}/${startYear}` : null,
        no_proof_income_end:
          endMonth && endYear ? `${endMonth}/${endYear}` : 'Present',
        no_proof_income_employer_name: employerName,
        no_proof_income_pay: amount ? numeral(amount).format('0.00') : null,
        no_proof_income_pay_frequency: payFrequency,
        no_proof_income_employer_address_street: employerAddressStreet,
        no_proof_income_employer_address_city_state_zip:
          employerAddressCity || employerAddressState || employerAddressZip
            ? [employerAddressCity, employerAddressState, employerAddressZip]
                .filter((x) => x)
                .join(', ')
            : null,
        no_proof_income_employer_phone: employerPhone
          ? getFormattedPhoneNumber(employerPhone)
          : null,
        no_proof_income_reason: noProofOfIncomeReason,
      })
    )
  )
}

const getCharityCareIncomeSourcesMappings = (
  incomeSources: IncomeSourceWithFullName[]
): Mapping => ({
  ...getCharityCareNoProofOfIncomeMapping(incomeSources),
  ...getCharityCareCashSupportMapping(incomeSources),
})

const getCharityCarePerIncomeSourceMappings = (
  perIncomeSource: PerIncomeSource
): Mapping => ({
  ...MAPPED_INCOME_TYPES.map((mappedIncomeType) =>
    getCharityCareYearlyMapping(mappedIncomeType, perIncomeSource)
  ).reduce(
    (obj, incomeTypeFields) => ({
      ...obj,
      ...incomeTypeFields,
    }),
    {}
  ),
  ...getCharityCareTotalMapping(perIncomeSource),
})

const getHouseholdMemberMappings = ({
  application,
  person,
}: {
  application: Application_application
  person: Person_person
}) => {
  const householdMembersWithRelationshipTypes = getHouseholdMembersWithRelationshipTypes(
    {application, person}
  )

  return mergeWithNumberedSuffixes(
    householdMembersWithRelationshipTypes.map((householdMember) => ({
      hh_member_name: getFullName(householdMember.person),
      hh_member_dob:
        householdMember.person.dob && getLongDate(householdMember.person.dob),
      hh_member_relationship_to_client:
        householdMember.relationshipType || 'unknown',
    }))
  )
}

const getFullName = ({
  firstName,
  middleName,
  lastName,
}: {
  firstName: string | null
  lastName: string | null
  middleName: string | null
}): string | null => {
  if (!(firstName || lastName)) return null
  return [firstName, middleName, lastName].filter((x) => x).join(' ')
}

export const getSlideApplicationMapping = ({
  person,
  federalPovertyLevels,
  slideApplicationRules,
  initialDateOfService,
  t,
}: {
  application: Application_application
  person: Person_person
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  slideApplicationRules: SlideApplicationRules_slideApplicationRules[]
  initialDateOfService: Date
  t: TFunction
}): Mapping => {
  let mapping: Mapping = {}
  const eligibilityInfo = isHouseholdIncomeIneligibleFullSlideWithFPL({
    values: {
      person,
    },
    federalPovertyLevels,
    slideApplicationRules,
    initialDateOfService,
    t,
  })
  if (eligibilityInfo) {
    const {
      isEligible,
      plan,
      copayInsuredUninsured,
      reducedFee,
      additionalData: {perIncomeSource},
    } = eligibilityInfo
    if (perIncomeSource) {
      Object.assign(
        mapping,
        getCharityCarePerIncomeSourceMappings(perIncomeSource)
      )
    }
    if (isEligible) {
      Object.assign(mapping, {
        slide_copay_insured_uninsured: `${copayInsuredUninsured}`,
        slide_copay_reducedfee: `${reducedFee}`,
        slide_plan: `${plan}`,
      })
    }
  }
  return mapping
}

export const getCharityCareApplicationMapping = ({
  application,
  person,
  federalPovertyLevels,
  t,
}: {
  application: Application_application
  person: Person_person
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  t: TFunction
}): Mapping => {
  let mapping: Mapping = {}
  if (
    !(
      isCharityCareApplication(application) &&
      isCharityCareApplicationWithInitialDateOfService(application)
    )
  )
    return mapping
  const eligibilityInfo = isHouseholdIncomeIneligibleFullCharityCare({
    values: {
      person,
    },
    federalPovertyLevels,
    application,
    t,
  })
  if (eligibilityInfo) {
    const {
      additionalData: {perIncomeSource},
      percentPaidByPatient,
    } = eligibilityInfo
    if (perIncomeSource) {
      Object.assign(
        mapping,
        getCharityCarePerIncomeSourceMappings(perIncomeSource)
      )
    }
    if (percentPaidByPatient != null) {
      Object.assign(mapping, {
        cc_patient_responsibility: `${percentPaidByPatient}`,
        cc_hospital_responsibility: `${100 - percentPaidByPatient}`,
      })
    }
  }
  const incomeSources = getHouseholdMembersToCountTowardIncomeCharityCare({
    person,
  })
    .flatMap((householdPerson) =>
      householdPerson.incomeSources.map((incomeSource) => ({
        ...incomeSource,
        fullName: getFullName(householdPerson),
        isClient: householdPerson.id === person.id,
      }))
    )
    .filter(
      getIsIncludedIncomeSourceCharityCare(application.initialDateOfService)
    )
  mapping = {
    ...mapping,
    ...getCharityCareIncomeSourcesMappings(incomeSources),
    ...getHouseholdMemberMappings({application, person}),
  }
  return mapping
}

const getRelatedMother = (person: Person_person): PersonType | null =>
  person.relationships.find(
    ({relationshipType, otherPerson: {gender}}) =>
      relationshipType === 'Parent/legal guardian' && gender === 'Female'
  )?.otherPerson ?? null

const getRelatedFather = (person: Person_person): PersonType | null =>
  person.relationships.find(
    ({relationshipType, otherPerson: {gender}}) =>
      relationshipType === 'Parent/legal guardian' && gender === 'Male'
  )?.otherPerson ?? null

const getRelatedParentNotPerson = (
  person: Person_person,
  notPerson: PersonType
): PersonType | null =>
  person.relationships.find(
    ({relationshipType, otherPerson}) =>
      relationshipType === 'Parent/legal guardian' && otherPerson !== notPerson
  )?.otherPerson ?? null

const getRelatedParents = (person: Person_person): PersonType[] =>
  person.relationships
    .filter(
      ({relationshipType}) => relationshipType === 'Parent/legal guardian'
    )
    .map(({otherPerson}) => otherPerson)

const getRelatedSpouse = (person: Person_person): PersonType | null =>
  person.relationships.find(
    ({relationshipType}) => relationshipType === 'Spouse'
  )?.otherPerson ?? null

const getPersonOrNewbornMother = ({
  application,
  person,
}: {
  application: Application_application_MedicaidApplication
  person: Person_person
}): PersonType | null => {
  if (application.mcdType !== McdType.Newborn) return person
  return getRelatedMother(person)
}

const getSpouseOrParents = ({
  application,
  person,
  personOrNewbornMother,
}: {
  application: Application_application_MedicaidApplication
  person: Person_person
  personOrNewbornMother: PersonType | null
}): PersonType[] => {
  if (application.mcdType === McdType.Newborn) {
    const foundRelatedPerson = personOrNewbornMother
      ? getRelatedParentNotPerson(person, personOrNewbornMother)
      : getRelatedFather(person)
    return foundRelatedPerson ? [foundRelatedPerson] : []
  }
  if (isMinorMedicaid(person)) {
    return getRelatedParents(person)
  }
  const spouse = getRelatedSpouse(person)
  return spouse ? [spouse] : []
}

const getMedicaidMonthlyIncome = (person: PersonType | null): string | null =>
  formatCurrency(
    sum(getMedicaidIncomeSources(person)?.map(getMonthlyAmount) ?? [])
  )

type IncomeSourceType = Pick<
  IncomeSourceFields,
  | 'payFrequency'
  | 'incomeType'
  | 'amount'
  | 'employerName'
  | 'employerAddressStreet'
  | 'employerAddressCity'
  | 'employerAddressState'
  | 'employerAddressZip'
  | 'employerPhone'
>

const getMedicaidIncomeSources = (
  person: PersonType | HouseholdPersonType | null
): IncomeSourceType[] | null =>
  person?.incomeSources.filter(filterMedicaidIncomeSourceTypes) ?? null

const getFormattedEmployerAddress = ({
  employerAddressStreet,
  employerAddressCity,
  employerAddressState,
  employerAddressZip,
}: {
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
}): string | null => {
  if (
    !(
      employerAddressStreet &&
      employerAddressCity &&
      employerAddressState &&
      employerAddressZip
    )
  )
    return null
  return `${employerAddressStreet}, ${employerAddressCity}, ${employerAddressState} ${employerAddressZip}`
}

type HouseholdPersonType = Application_application_MedicaidApplication_householdMembers_person

const getMedicaidHouseholdPeople = ({
  application,
  person,
}: {
  application: Application_application
  person: Person_person
}): HouseholdPersonType[] => {
  const spouse = getRelatedSpouse(person)
  const householdPeople = application.householdMembers.map(({person}) => person)
  householdPeople.sort((householdPersonA, householdPersonB) => {
    if (householdPersonA.id === person.id) return -1
    if (householdPersonB.id === person.id) return 1
    if (spouse) {
      if (householdPersonA.id === spouse.id) return -1
      if (householdPersonB.id === spouse.id) return 1
    }
    return householdPersonA.id < householdPersonB.id ? -1 : 1
  })
  return householdPeople
}

const toTrueFalseOrNull = (value: boolean | null): string | null =>
  value === null ? null : value === true ? 'true' : 'false'

const OTHER_DEDUCTION_TYPES = [
  DEDUCTION_TYPE_SELF_EMPLOYMENT_EXPENSES,
  DEDUCTION_TYPE_HEALTH_SAVINGS_ACCOUNT,
  DEDUCTION_TYPE_IRA_DEDUCTION,
  DEDUCTION_TYPE_MOVING_EXPENSES,
  DEDUCTION_TYPE_EDUCATOR_EXPENSES,
  DEDUCTION_TYPE_SELF_EMPLOYMENT_TAX,
  DEDUCTION_TYPE_SELF_EMPLOYED_IRA,
  DEDUCTION_TYPE_SELF_EMPLOYED_HEALTH_INSURANCE,
  DEDUCTION_TYPE_OTHER,
]

const PAY_FREQUENCY_CODES: Record<string, string> = {
  [INCOME_FREQUENCY_WEEKLY]: '2',
  [INCOME_FREQUENCY_BIWEEKLY]: '3',
  [INCOME_FREQUENCY_TWICE_A_MONTH]: '4',
  [INCOME_FREQUENCY_MONTHLY]: '5',
  [INCOME_FREQUENCY_LUMP_SUM]: '6',
}

const getPayFrequencyCode = ({payFrequency}: IncomeSourceType): string | null =>
  (payFrequency && PAY_FREQUENCY_CODES[payFrequency]) ?? null

const getMedicaidPerIncomeSourceMappings = (
  incomeSources: IncomeSourceType[],
  suffix: string
): Mapping => {
  let mapping: Mapping = {}
  incomeSources
    .filter(
      ({incomeType}) =>
        incomeType &&
        [
          INCOME_SOURCE_TYPE_SALARY_WAGES,
          INCOME_SOURCE_TYPE_FREELANCE,
        ].includes(incomeType)
    )
    .forEach((incomeSource, index) => {
      const incomeSourceIndex = index + 1
      const full_suffix = `${incomeSourceIndex}_${suffix}`
      Object.assign(mapping, {
        [`mcd_employer_address_${full_suffix}`]: getFormattedEmployerAddress(
          incomeSource
        ),
        [`mcd_employer_name_${full_suffix}`]: incomeSource.employerName,
        [`mcd_employer_phone_${incomeSourceIndex}_areacode_${suffix}`]: incomeSource.employerPhone
          ? getPhoneNumberAreaCode(incomeSource.employerPhone)
          : null,
        [`mcd_employer_phone_${incomeSourceIndex}_first3_${suffix}`]: incomeSource.employerPhone
          ? getPhoneNumberFirst3(incomeSource.employerPhone)
          : null,
        [`mcd_employer_phone_${incomeSourceIndex}_last4_${suffix}`]: incomeSource.employerPhone
          ? getPhoneNumberLast4(incomeSource.employerPhone)
          : null,
        [`mcd_payfrequency_${full_suffix}`]: getPayFrequencyCode(incomeSource),
        [`mcd_income_pay_${full_suffix}`]: incomeSource.amount
          ? formatCurrency(incomeSource.amount)
          : null,
      })
    })
  incomeSources
    .filter(
      ({incomeType}) =>
        incomeType && [INCOME_SOURCE_TYPE_SELF_EMPLOYED].includes(incomeType)
    )
    .forEach((incomeSource, index) => {
      const incomeSourceIndex = index + 1
      const full_suffix = `${incomeSourceIndex}_${suffix}`
      const monthlyAmount = getMonthlyAmount(incomeSource)
      Object.assign(mapping, {
        [`mcd_self_employed_monthly_amount_${full_suffix}`]: monthlyAmount
          ? formatCurrency(monthlyAmount)
          : null,
      })
    })
  return mapping
}

const getMedicaidHouseholdMapping = ({
  application,
  person,
}: {
  application: Application_application
  person: Person_person
}): Mapping => {
  const householdPeople = getMedicaidHouseholdPeople({application, person})
  let mapping: Mapping = {}
  householdPeople.forEach((householdPerson, householdPersonIndex) => {
    const incomeSources = getMedicaidIncomeSources(householdPerson)!
    const suffix = `${householdPersonIndex + 1}`
    Object.assign(
      mapping,
      {
        [`mcd_employment_employed_${suffix}`]: toTrueFalseOrNull(
          incomeSources.some(
            ({incomeType}) =>
              incomeType &&
              [
                INCOME_SOURCE_TYPE_SALARY_WAGES,
                INCOME_SOURCE_TYPE_FREELANCE,
              ].includes(incomeType)
          )
        ),
        [`mcd_employment_notemployed_${suffix}`]: toTrueFalseOrNull(
          !incomeSources.some(
            ({incomeType}) =>
              incomeType &&
              [
                INCOME_SOURCE_TYPE_SALARY_WAGES,
                INCOME_SOURCE_TYPE_FREELANCE,
                INCOME_SOURCE_TYPE_SELF_EMPLOYED,
              ].includes(incomeType)
          )
        ),
        [`mcd_employment_selfemployed_${suffix}`]: toTrueFalseOrNull(
          incomeSources.some(
            ({incomeType}) =>
              incomeType &&
              [INCOME_SOURCE_TYPE_SELF_EMPLOYED].includes(incomeType)
          )
        ),
        [`mcd_self_employed_type_${suffix}`]: incomeSources
          .filter(
            ({incomeType, employerName}) =>
              incomeType &&
              [INCOME_SOURCE_TYPE_SELF_EMPLOYED].includes(incomeType) &&
              employerName
          )
          .map(({employerName}) => employerName)
          .join(', '),
        [`mcd_other_income_none_${suffix}`]: toTrueFalseOrNull(
          !incomeSources.some(
            ({incomeType}) =>
              incomeType &&
              ![
                INCOME_SOURCE_TYPE_SALARY_WAGES,
                INCOME_SOURCE_TYPE_FREELANCE,
                INCOME_SOURCE_TYPE_SELF_EMPLOYED,
              ].includes(incomeType)
          )
        ),
        [`mcd_deduct_other_type_${suffix}`]: householdPerson.deductions
          .filter(
            ({deductionType}) =>
              deductionType && OTHER_DEDUCTION_TYPES.includes(deductionType)
          )
          .map(({deductionType}) => deductionType)
          .join(', '),
        [`mcd_total_income_this_year_${suffix}`]: formatCurrency(
          sum(incomeSources.map(getYearlyAmount))
        ),
      },
      getMedicaidPerIncomeSourceMappings(incomeSources, suffix),
      getMedicaidIncomeCategoryMappings(
        'unemployment_income',
        [INCOME_SOURCE_TYPE_UNEMPLOYMENT],
        incomeSources,
        suffix
      ),
      getMedicaidIncomeCategoryMappings(
        'pensions_income',
        [INCOME_SOURCE_TYPE_PENSION],
        incomeSources,
        suffix
      ),
      getMedicaidIncomeCategoryMappings(
        'social_security_income',
        [
          INCOME_SOURCE_TYPE_SOCIAL_SECURITY_DISABILITY,
          INCOME_SOURCE_TYPE_SOCIAL_SECURITY_RETIREMENT,
          INCOME_SOURCE_TYPE_SSI,
          INCOME_SOURCE_TYPE_STATE_DISABILITY,
        ],
        incomeSources,
        suffix
      ),
      getMedicaidIncomeCategoryMappings(
        'farming_fishing_income',
        [INCOME_SOURCE_TYPE_NET_FARMING],
        incomeSources,
        suffix
      ),
      getMedicaidIncomeCategoryMappings(
        'rental_income',
        [INCOME_SOURCE_TYPE_RENTAL_INCOME],
        incomeSources,
        suffix
      ),
      getMedicaidIncomeCategoryMappings(
        'other_income',
        [
          INCOME_SOURCE_TYPE_CASH_SUPPORT,
          INCOME_SOURCE_TYPE_WORKERS_COMPENSATION,
          INCOME_SOURCE_TYPE_PUBLIC_ASSISTANCE,
          INCOME_SOURCE_TYPE_VETERANS_BENEFITS,
          INCOME_SOURCE_TYPE_INSURANCE_PAYMENTS,
          INCOME_SOURCE_TYPE_INTEREST,
          INCOME_SOURCE_TYPE_ANNUITY_PAYMENTS,
          INCOME_SOURCE_TYPE_LOTTERY,
          INCOME_SOURCE_TYPE_OTHER,
        ],
        incomeSources,
        suffix
      ),
      getMedicaidIncomeCategoryMappings(
        'abd_other_income',
        [
          INCOME_SOURCE_TYPE_PUBLIC_ASSISTANCE,
          INCOME_SOURCE_TYPE_VETERANS_BENEFITS,
          INCOME_SOURCE_TYPE_NET_FARMING,
          INCOME_SOURCE_TYPE_INSURANCE_PAYMENTS,
          INCOME_SOURCE_TYPE_INTEREST,
          INCOME_SOURCE_TYPE_LOTTERY,
          INCOME_SOURCE_TYPE_OTHER,
        ],
        incomeSources,
        suffix
      ),
      getMedicaidIncomeCategoryMappings(
        'childsupport',
        [INCOME_SOURCE_TYPE_ALIMONY],
        incomeSources,
        suffix
      ),
      getMedicaidIncomeCategoryMappings(
        'workcomp',
        [INCOME_SOURCE_TYPE_WORKERS_COMPENSATION],
        incomeSources,
        suffix
      ),
      getMedicaidIncomeCategoryMappings(
        'cashsupport',
        [INCOME_SOURCE_TYPE_CASH_SUPPORT],
        incomeSources,
        suffix
      ),
      getMedicaidIncomeCategoryMappings(
        'annuity',
        [INCOME_SOURCE_TYPE_ANNUITY_PAYMENTS],
        incomeSources,
        suffix
      ),
      getMedicaidDeductionCategoryMappings(
        'alimony',
        [DEDUCTION_TYPE_ALIMONY],
        householdPerson.deductions,
        suffix
      ),
      getMedicaidDeductionCategoryMappings(
        'studentloaninterests',
        [DEDUCTION_TYPE_STUDENT_LOAN_INTEREST],
        householdPerson.deductions,
        suffix
      ),
      getMedicaidDeductionCategoryMappings(
        'other',
        OTHER_DEDUCTION_TYPES,
        householdPerson.deductions,
        suffix
      )
    )
  })
  return mapping
}

const getMedicaidIncomeCategoryMappings = (
  name: string,
  incomeTypes: string[],
  incomeSources: IncomeSourceType[],
  suffix: string
): Mapping => {
  const incomeSourcesInCategory = incomeSources.filter(
    ({incomeType}) => incomeType && incomeTypes.includes(incomeType)
  )
  return {
    [`mcd_${name}_flag_${suffix}`]: toTrueFalseOrNull(
      incomeSourcesInCategory.length > 0
    ),
    [`mcd_${name}_${suffix}`]:
      incomeSourcesInCategory.length > 0
        ? formatCurrency(sum(incomeSourcesInCategory.map(getMonthlyAmount)))
        : null,
    [`mcd_${name}_pay_frequency_${suffix}`]:
      incomeSourcesInCategory.length > 0 ? 'Monthly' : null,
  }
}

const getMedicaidDeductionCategoryMappings = (
  name: string,
  deductionTypes: string[],
  deductions: DeductionFields[],
  suffix: string
): Mapping => {
  const deductionsInCategory = deductions
    .filter(
      ({deductionType}) =>
        deductionType && deductionTypes.includes(deductionType)
    )
    .map(({amount, deductionFrequency: payFrequency}) => ({
      amount,
      payFrequency,
    }))
  return {
    [`mcd_deduct_${name}_flag_${suffix}`]: toTrueFalseOrNull(
      deductionsInCategory.length > 0
    ),
    [`mcd_deduct_${name}_amount_${suffix}`]: formatCurrency(
      sum(deductionsInCategory.map(getMonthlyAmount))
    ),
    [`mcd_deduct_${name}_frequency_${suffix}`]:
      deductionsInCategory.length > 0 ? 'Monthly' : null,
  }
}

export const getMedicaidApplicationMapping = ({
  application,
  person,
}: {
  application: Application_application
  person: Person_person
}): Mapping => {
  let mapping: Mapping = {}
  if (!isMedicaidApplication(application)) return mapping
  const personOrNewbornMother = getPersonOrNewbornMother({application, person})
  const spouseOrParents = getSpouseOrParents({
    application,
    person,
    personOrNewbornMother,
  })
  Object.assign(
    mapping,
    {
      mcd_income_source_pa1c:
        getMedicaidIncomeSources(person)
          ?.map((incomeSource) => ({
            incomeType: incomeSource.incomeType,
            monthlyAmount: getMonthlyAmount(incomeSource),
          }))
          .filter(
            ({monthlyAmount}) => monthlyAmount != null && monthlyAmount > 0
          )
          .map(({incomeType}) => incomeType)
          .join(', ') ?? null,
      mcd_monthly_income_applicant_pa1c: getMedicaidMonthlyIncome(
        personOrNewbornMother
      ),
      mcd_monthly_income_spouse_parent_pa1c: formatCurrency(
        sum(spouseOrParents.map(getMedicaidMonthlyIncome))
      ),
      mcd_employer_applicant_pa1c:
        personOrNewbornMother?.incomeSources
          .filter(filterMedicaidIncomeSourceTypes)
          .map(({employerName}) => employerName)
          .filter((employerName) => !!employerName)
          .join(', ') ?? null,
      mcd_employer_applicant_full_address_pa1c:
        personOrNewbornMother?.incomeSources
          .filter(filterMedicaidIncomeSourceTypes)
          .map(getFormattedEmployerAddress)
          .filter((employerAddress) => !!employerAddress)
          .join('; ') ?? null,
      mcd_employer_spouse_parent_pa1c:
        spouseOrParents
          .flatMap(({incomeSources}) => incomeSources)
          .filter(filterMedicaidIncomeSourceTypes)
          .map(({employerName}) => employerName)
          .filter((employerName) => !!employerName)
          .join(', ') ?? null,
      mcd_employer_spouse_parent_full_address_pa1c:
        spouseOrParents
          .flatMap(({incomeSources}) => incomeSources)
          .filter(filterMedicaidIncomeSourceTypes)
          .map(getFormattedEmployerAddress)
          .filter((employerAddress) => !!employerAddress)
          .join('; ') ?? null,
    },
    getMedicaidHouseholdMapping({application, person}),
    getHouseholdMemberMappings({application, person})
  )
  return mapping
}
