/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {DocumentFileInput} from './globalTypes' // ====================================================
// GraphQL mutation operation: CreateDocumentFile
// ====================================================

import {deserializeDocumentFileInput} from './globalTypes'
import {serializeDocumentFileInput} from './globalTypes'
import {CreateDocumentFileVariables as CreateDocumentFileVariablesOrig} from '../types/CreateDocumentFile'
import {CreateDocumentFile as CreateDocumentFileOrig} from '../types/CreateDocumentFile'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {CreateDocumentFile_createDocumentFile as CreateDocumentFile_createDocumentFileOrig} from '../types/CreateDocumentFile'
export interface CreateDocumentFile_createDocumentFile {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeCreateDocumentFile_createDocumentFile = (
  serialized: CreateDocumentFile_createDocumentFileOrig
): CreateDocumentFile_createDocumentFile => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeCreateDocumentFile_createDocumentFile = (
  deserialized: CreateDocumentFile_createDocumentFile
): CreateDocumentFile_createDocumentFileOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface CreateDocumentFile {
  /**
   * Create a new document file
   */
  createDocumentFile: CreateDocumentFile_createDocumentFile
}
export const deserializeCreateDocumentFile = (
  serialized: CreateDocumentFileOrig
): CreateDocumentFile => ({
  ...serialized,
  createDocumentFile: deserializeCreateDocumentFile_createDocumentFile(
    serialized.createDocumentFile
  ),
})
export const serializeCreateDocumentFile = (
  deserialized: CreateDocumentFile
): CreateDocumentFileOrig => ({
  ...deserialized,
  createDocumentFile: serializeCreateDocumentFile_createDocumentFile(
    deserialized.createDocumentFile
  ),
})
export interface CreateDocumentFileVariables {
  documentFile: DocumentFileInput
}
export const deserializeCreateDocumentFileVariables = (
  serialized: CreateDocumentFileVariablesOrig
): CreateDocumentFileVariables => ({
  ...serialized,
  documentFile: deserializeDocumentFileInput(serialized.documentFile),
})
export const serializeCreateDocumentFileVariables = (
  deserialized: CreateDocumentFileVariables
): CreateDocumentFileVariablesOrig => ({
  ...deserialized,
  documentFile: serializeDocumentFileInput(deserialized.documentFile),
})
