/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {EditableFileStatus} from './globalTypes' // ====================================================
// GraphQL mutation operation: MarkEditableFileReviewed
// ====================================================

import {MarkEditableFileReviewedVariables as MarkEditableFileReviewedVariablesOrig} from '../types/MarkEditableFileReviewed'
import {MarkEditableFileReviewed as MarkEditableFileReviewedOrig} from '../types/MarkEditableFileReviewed'
import {MarkEditableFileReviewed_markEditableFileReviewed as MarkEditableFileReviewed_markEditableFileReviewedOrig} from '../types/MarkEditableFileReviewed'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {MarkEditableFileReviewed_markEditableFileReviewed_sentFax as MarkEditableFileReviewed_markEditableFileReviewed_sentFaxOrig} from '../types/MarkEditableFileReviewed'
import {MarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax as MarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFaxOrig} from '../types/MarkEditableFileReviewed'
import {MarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax_state as MarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax_stateOrig} from '../types/MarkEditableFileReviewed'
export interface MarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax_state {
  __typename: 'State'
  stateCode: string
}
export const deserializeMarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax_state = (
  serialized: MarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax_stateOrig
): MarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax_state => ({
  ...serialized,
})
export const serializeMarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax_state = (
  deserialized: MarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax_state
): MarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax_stateOrig => ({
  ...deserialized,
})
export interface MarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax {
  __typename: 'CountyFax'
  countyName: string
  state: MarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax_state
}
export const deserializeMarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax = (
  serialized: MarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFaxOrig
): MarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax => ({
  ...serialized,
  state: deserializeMarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax_state(
    serialized.state
  ),
})
export const serializeMarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax = (
  deserialized: MarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax
): MarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFaxOrig => ({
  ...deserialized,
  state: serializeMarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax_state(
    deserialized.state
  ),
})
export interface MarkEditableFileReviewed_markEditableFileReviewed_sentFax {
  __typename: 'SentFax'
  toNumber: string | null
  faxStatus: string | null
  message: string | null
  updatedAt: Date
  countyFax: MarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax
}
export const deserializeMarkEditableFileReviewed_markEditableFileReviewed_sentFax = (
  serialized: MarkEditableFileReviewed_markEditableFileReviewed_sentFaxOrig
): MarkEditableFileReviewed_markEditableFileReviewed_sentFax => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  countyFax: deserializeMarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax(
    serialized.countyFax
  ),
})
export const serializeMarkEditableFileReviewed_markEditableFileReviewed_sentFax = (
  deserialized: MarkEditableFileReviewed_markEditableFileReviewed_sentFax
): MarkEditableFileReviewed_markEditableFileReviewed_sentFaxOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  countyFax: serializeMarkEditableFileReviewed_markEditableFileReviewed_sentFax_countyFax(
    deserialized.countyFax
  ),
})
export interface MarkEditableFileReviewed_markEditableFileReviewed {
  __typename: 'EditableFile'
  id: string
  fileKey: string
  filename: string
  updatedAt: Date
  status: EditableFileStatus
  allowSigning: boolean | null
  documentSendType: string | null
  faxEnabled: boolean | null
  sentFax: MarkEditableFileReviewed_markEditableFileReviewed_sentFax | null
  isUnreviewed: boolean
}
export const deserializeMarkEditableFileReviewed_markEditableFileReviewed = (
  serialized: MarkEditableFileReviewed_markEditableFileReviewedOrig
): MarkEditableFileReviewed_markEditableFileReviewed => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  sentFax:
    serialized.sentFax != null
      ? deserializeMarkEditableFileReviewed_markEditableFileReviewed_sentFax(
          serialized.sentFax
        )
      : serialized.sentFax,
})
export const serializeMarkEditableFileReviewed_markEditableFileReviewed = (
  deserialized: MarkEditableFileReviewed_markEditableFileReviewed
): MarkEditableFileReviewed_markEditableFileReviewedOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  sentFax:
    deserialized.sentFax != null
      ? serializeMarkEditableFileReviewed_markEditableFileReviewed_sentFax(
          deserialized.sentFax
        )
      : deserialized.sentFax,
})
export interface MarkEditableFileReviewed {
  markEditableFileReviewed: MarkEditableFileReviewed_markEditableFileReviewed
}
export const deserializeMarkEditableFileReviewed = (
  serialized: MarkEditableFileReviewedOrig
): MarkEditableFileReviewed => ({
  ...serialized,
  markEditableFileReviewed: deserializeMarkEditableFileReviewed_markEditableFileReviewed(
    serialized.markEditableFileReviewed
  ),
})
export const serializeMarkEditableFileReviewed = (
  deserialized: MarkEditableFileReviewed
): MarkEditableFileReviewedOrig => ({
  ...deserialized,
  markEditableFileReviewed: serializeMarkEditableFileReviewed_markEditableFileReviewed(
    deserialized.markEditableFileReviewed
  ),
})
export interface MarkEditableFileReviewedVariables {
  editableFileId: string
  applicationId: string
}
export const deserializeMarkEditableFileReviewedVariables = (
  serialized: MarkEditableFileReviewedVariablesOrig
): MarkEditableFileReviewedVariables => ({...serialized})
export const serializeMarkEditableFileReviewedVariables = (
  deserialized: MarkEditableFileReviewedVariables
): MarkEditableFileReviewedVariablesOrig => ({...deserialized})
