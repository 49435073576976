import React, {FC} from 'react'
import {flowMax, addProps, addDisplayName, addMemoBoundary} from 'ad-hok'

import {addTranslationHelpers} from 'utils/i18n'
import Body2 from 'components/Body2'
import {makeClasses, addClasses} from 'theme'
import {addFormContext} from 'utils/form/context'

interface Props {
  labelTranslationKey: string
}

const classes = makeClasses((theme) => ({
  label: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
}))

const FieldSet: FC<Props> = flowMax(
  addDisplayName('FieldSet'),
  addTranslationHelpers,
  addFormContext,
  addMemoBoundary(['formName']),
  addProps(({formName, labelTranslationKey, t}) => ({
    label: t(`${formName}.fieldSetLabels.${labelTranslationKey}`),
  })),
  addClasses(classes),
  ({label, children, classes}) => (
    <div>
      <Body2 className={classes.label}>{label}</Body2>
      {children}
    </div>
  )
)

export default FieldSet
