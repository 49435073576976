import React from 'react'
import {FC} from 'react'
import {flowMax, addDisplayName} from 'ad-hok'
import cx from 'classnames'

import Button from 'components/Button'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogActions from 'components/DialogActions'
import DialogContent from 'components/DialogContent'
import DialogContentText from 'components/DialogContentText'
import {makeClasses, addClasses} from 'theme'
import addHasSaved from 'utils/addHasSaved'

const classes = makeClasses((theme) => ({
  paper: {
    minWidth: 400,
  },
}))

interface Props {
  open: boolean
  onCancel: () => void
  onConfirm: () => void
  title: string
  cancelText: string
  confirmText: string
  message?: string
  className?: string
}

const ConfirmationDialog: FC<Props> = flowMax(
  addDisplayName('ConfirmationDialog'),
  addHasSaved('onConfirm'),
  addClasses(classes),
  ({
    open,
    onCancel,
    onConfirm,
    title,
    cancelText,
    confirmText,
    message,
    hasSaved,
    classes,
    className,
  }) => (
    <Dialog
      open={open}
      onClose={onCancel}
      classes={{paper: cx(classes.paper, className)}}
      data-testid="confirmation-dialog"
    >
      <DialogTitle>{title}</DialogTitle>
      {message && (
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {cancelText}
        </Button>
        <Button onClick={onConfirm} color="primary" disabled={hasSaved}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
)

export default ConfirmationDialog
