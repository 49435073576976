/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {DocumentInput} from './globalTypes' // ====================================================
// GraphQL mutation operation: CreateDocument
// ====================================================

import {deserializeDocumentInput} from './globalTypes'
import {serializeDocumentInput} from './globalTypes'
import {CreateDocumentVariables as CreateDocumentVariablesOrig} from '../types/CreateDocument'
import {CreateDocument as CreateDocumentOrig} from '../types/CreateDocument'
import {CreateDocument_createDocument as CreateDocument_createDocumentOrig} from '../types/CreateDocument'
import {CreateDocument_createDocument_person as CreateDocument_createDocument_personOrig} from '../types/CreateDocument'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {CreateDocument_createDocument_files as CreateDocument_createDocument_filesOrig} from '../types/CreateDocument'
export interface CreateDocument_createDocument_files {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeCreateDocument_createDocument_files = (
  serialized: CreateDocument_createDocument_filesOrig
): CreateDocument_createDocument_files => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeCreateDocument_createDocument_files = (
  deserialized: CreateDocument_createDocument_files
): CreateDocument_createDocument_filesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface CreateDocument_createDocument_person {
  __typename: 'Person'
  id: string
}
export const deserializeCreateDocument_createDocument_person = (
  serialized: CreateDocument_createDocument_personOrig
): CreateDocument_createDocument_person => ({...serialized})
export const serializeCreateDocument_createDocument_person = (
  deserialized: CreateDocument_createDocument_person
): CreateDocument_createDocument_personOrig => ({...deserialized})
export interface CreateDocument_createDocument {
  __typename: 'Document'
  id: string
  documentType: string
  complete: boolean | null
  files: CreateDocument_createDocument_files[]
  updatedAt: Date
  notes: string | null
  person: CreateDocument_createDocument_person | null
}
export const deserializeCreateDocument_createDocument = (
  serialized: CreateDocument_createDocumentOrig
): CreateDocument_createDocument => ({
  ...serialized,
  files: serialized.files.map(deserializeCreateDocument_createDocument_files),
  updatedAt: parseDate(serialized.updatedAt),
  person:
    serialized.person != null
      ? deserializeCreateDocument_createDocument_person(serialized.person)
      : serialized.person,
})
export const serializeCreateDocument_createDocument = (
  deserialized: CreateDocument_createDocument
): CreateDocument_createDocumentOrig => ({
  ...deserialized,
  files: deserialized.files.map(serializeCreateDocument_createDocument_files),
  updatedAt: formatISO(deserialized.updatedAt),
  person:
    deserialized.person != null
      ? serializeCreateDocument_createDocument_person(deserialized.person)
      : deserialized.person,
})
export interface CreateDocument {
  /**
   * Create a new document
   */
  createDocument: CreateDocument_createDocument
}
export const deserializeCreateDocument = (
  serialized: CreateDocumentOrig
): CreateDocument => ({
  ...serialized,
  createDocument: deserializeCreateDocument_createDocument(
    serialized.createDocument
  ),
})
export const serializeCreateDocument = (
  deserialized: CreateDocument
): CreateDocumentOrig => ({
  ...deserialized,
  createDocument: serializeCreateDocument_createDocument(
    deserialized.createDocument
  ),
})
export interface CreateDocumentVariables {
  document: DocumentInput
}
export const deserializeCreateDocumentVariables = (
  serialized: CreateDocumentVariablesOrig
): CreateDocumentVariables => ({
  ...serialized,
  document: deserializeDocumentInput(serialized.document),
})
export const serializeCreateDocumentVariables = (
  deserialized: CreateDocumentVariables
): CreateDocumentVariablesOrig => ({
  ...deserialized,
  document: serializeDocumentInput(deserialized.document),
})
