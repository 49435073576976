import {CurriedPropsAdder, flowMax, addRef, addEffect, addProps} from 'ad-hok'

const REF_PROP_NAME = '_addPrevious_ref'

type AddPrevious = <
  TProps,
  TPropName extends keyof TProps,
  TPreviousPropName extends string
>(
  propName: TPropName,
  previousPropName: TPreviousPropName
) => CurriedPropsAdder<
  TProps,
  {
    [previousPropName in TPreviousPropName]: TProps[TPropName]
  }
>

const addPrevious: AddPrevious = (propName, previousPropName) =>
  flowMax(
    addRef(REF_PROP_NAME, ({[propName]: propValue}) => propValue),
    addEffect(({[propName]: propValue, [REF_PROP_NAME]: ref}) => () => {
      ref.current = propValue
    }),
    addProps(
      ({[REF_PROP_NAME]: ref}) =>
        ({
          [previousPropName]: ref.current,
        } as {
          [previousPropName in typeof previousPropName]: typeof ref.current
        })
    )
  )

export default addPrevious
