import {
  flowMax,
  branch,
  renderNothing,
  CurriedPropsAdder,
  returns,
} from 'ad-hok'
import {some} from 'lodash/fp'
import {ApolloError, ApolloQueryResult, FetchPolicy} from 'apollo-boost'
import {MutationFunction} from '@apollo/react-common'
import {QueryLazyOptions} from '@apollo/react-hooks'
import {ReactElement} from 'react'
import {DocumentNode} from 'graphql'

const DATA_LOADING = 'loading'
const DATA_LOADED = 'data'
type DataLoading = {
  dataLoadingState: 'loading'
}
type DataLoaded<TFieldData> = {
  dataLoadingState: 'data'
  data: TFieldData
}

type DataLoadingState<TFieldData> = DataLoading | DataLoaded<TFieldData>

type ExtractDataLoadingFieldData<
  TDataLoadingState
> = TDataLoadingState extends DataLoadingState<infer TFieldData>
  ? TFieldData
  : never

type MakeDataLoadedType = <TFieldData>(
  fieldData: TFieldData
) => DataLoaded<TFieldData>

export const makeDataLoaded: MakeDataLoadedType = (fieldData) => ({
  dataLoadingState: DATA_LOADED,
  data: fieldData,
})

type MakeDataLoadingType = <TFieldData>() => DataLoading

export const makeDataLoading: MakeDataLoadingType = () => ({
  dataLoadingState: DATA_LOADING,
})

export const isDataLoading = (propValue: any): propValue is DataLoading =>
  propValue?.dataLoadingState === DATA_LOADING
export const isDataLoaded = (propValue: any): propValue is DataLoaded<any> =>
  propValue?.dataLoadingState === DATA_LOADED

type AddQueryOptionsType<TProps, TVariablesDeserialized> = {
  variables?: (props: TProps) => TVariablesDeserialized
  skip?: boolean | ((props: TProps) => boolean)
  fetchPolicy?: FetchPolicy
}

export type AddQueryType<
  TDataSerialized,
  TDataDeserialized,
  TFieldName extends keyof TDataDeserialized,
  TVariablesSerialized,
  TVariablesDeserialized,
  TRefetchName extends string
> = <TProps>(
  opts: AddQueryOptionsType<TProps, TVariablesDeserialized>
) => CurriedPropsAdder<
  TProps,
  {
    [key in TFieldName]: DataLoadingState<TDataDeserialized[TFieldName]>
  } &
    {
      [key in TRefetchName]: (
        variables?: TVariablesDeserialized
      ) => Promise<ApolloQueryResult<TDataSerialized>>
    }
>

export type AddLazyQueryType<
  TDataSerialized,
  TDataDeserialized,
  TFieldName extends keyof TDataDeserialized,
  TVariablesSerialized,
  TVariablesDeserialized,
  TRefetchName extends string,
  TLazyGetName extends string
> = <TProps>(
  opts: AddQueryOptionsType<TProps, TVariablesDeserialized>
) => CurriedPropsAdder<
  TProps,
  {
    [key in TFieldName]: DataLoadingState<TDataDeserialized[TFieldName]>
  } &
    {
      [key in TRefetchName]: (
        variables?: TVariablesDeserialized
      ) => Promise<ApolloQueryResult<TDataSerialized>>
    } &
    {
      [key in TLazyGetName]: (
        options?: QueryLazyOptions<TVariablesSerialized> | undefined
      ) => void
    }
>

type AddMutationOptionsType<TProps, TDataSerialized, TVariablesDeserialized> = {
  onCompleted?: (props: TProps) => (data: TDataSerialized) => void
  onError?: (props: TProps) => (error: ApolloError) => void
  variables?: (props: TProps) => TVariablesDeserialized
  refetchQueries?: (
    props: TProps
  ) => {
    query: DocumentNode
    variables?: {}
  }[]
  awaitRefetchQueries?: boolean
}

export type AddMutationType<
  TDataSerialized,
  TDataDeserialized,
  TVariablesSerialized,
  TVariablesDeserialized,
  TMutatePropName extends string
> = <TProps>(
  opts: AddMutationOptionsType<TProps, TDataSerialized, TVariablesDeserialized>
) => CurriedPropsAdder<
  TProps,
  {
    [key in TMutatePropName]: MutationFunction<
      TDataSerialized,
      TVariablesDeserialized
    >
  }
>

interface AddLoadingIndicatorOptions<TProps> {
  renderLoading?: (props: TProps) => ReactElement<any, any> | null
}

type AddLoadingIndicatorType = <TProps>(
  options: AddLoadingIndicatorOptions<TProps>
) => (
  props: TProps
) => {
  [key in keyof TProps]: TProps[key] extends DataLoadingState<infer TFieldData>
    ? TFieldData
    : TProps[key]
}

export const addLoadingIndicator: AddLoadingIndicatorType = ({
  renderLoading,
} = {}) =>
  flowMax(
    branch(
      (props: {}) => some(isDataLoading)(props),
      renderLoading ? returns(renderLoading) : renderNothing()
    ),
    (props: any) => {
      const mappedProps = {...props}
      Object.keys(mappedProps).forEach((propName) => {
        const propValue: any = mappedProps[propName]
        if (isDataLoaded(propValue)) {
          mappedProps[propName] = propValue.data
        }
      })
      return mappedProps
    }
  )

type AddLoadingIndicatorForType = <
  // TPropName extends string,
  // TProps extends {
  //   [propName in TPropName]: DataLoadingState<any>
  // }
  TProps,
  TPropName extends keyof TProps
>(
  propName: TPropName,
  options: AddLoadingIndicatorOptions<TProps>
) => (
  props: TProps
) => // {
// [key in keyof TProps]: key extends TPropName
//   ? TProps[key] extends DataLoadingState<infer TFieldData>
//     ? TFieldData
//     : never
//   : TProps[key]
// }
Omit<TProps, TPropName> &
  {
    [propName in TPropName]: ExtractDataLoadingFieldData<TProps[propName]>
  }

export const addLoadingIndicatorFor: AddLoadingIndicatorForType = (
  propName,
  {renderLoading}
) =>
  flowMax(
    branch(
      (props) => isDataLoading(props[propName]),
      renderLoading ? returns(renderLoading) : renderNothing()
    ),
    (props) => {
      const mappedProps = {...props}
      mappedProps[propName] = ((mappedProps[propName] as unknown) as DataLoaded<
        any
      >).data
      // return mappedProps as typeof props &
      //   {
      //     [propName in typeof propName]: DataLoadingState<any>
      //   }
      return mappedProps as any
    }
  )
