import React, {FC} from 'react'
import {flowMax, addDisplayName} from 'ad-hok'

import FormControl from 'components/FormControl'
import FormLabel from 'components/FormLabel'
import RadioGroup from 'components/RadioGroup'
import FormControlLabel from 'components/FormControlLabel'
import Radio from 'components/Radio'
import {SelectFieldOption} from 'utils/form/fieldTypes'

interface Props {
  label: string
  name: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  options: SelectFieldOption[]
  disabled?: boolean
}

const RadioButtons: FC<Props> = flowMax(
  addDisplayName('RadioButtons'),
  ({label, name, value, onChange, options, disabled}) => (
    <FormControl component="fieldset" disabled={disabled}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup name={name} value={value} onChange={onChange} row>
        {options.map(({label, value}) => (
          <FormControlLabel
            value={value}
            label={label}
            control={<Radio />}
            key={value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
)

export default RadioButtons
