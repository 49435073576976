import {
  makeDeterminerRule,
  START_TOKEN,
  makeDeterminer,
} from 'utils/form/determiners'

const assetType = makeDeterminerRule({
  name: 'asset.assetType',
  necessaryIf: [START_TOKEN],
})

const assetAmount = makeDeterminerRule({
  name: 'asset.amount',
  necessaryIf: [START_TOKEN],
})

export const determinerAssetForm = makeDeterminer({
  name: 'asset',
  rules: [assetType, assetAmount],
})
