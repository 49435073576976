import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  branch,
  renderNothing,
  addHandlers,
  addState,
  addEffect,
} from 'ad-hok'

import {addDocumentFilesContext} from 'components/DocumentFiles/context'
import addDialogState from 'utils/addDialogState'
import {addCreateDocumentFileMutation} from 'graphql/generated'
import {addTranslationHelpers} from 'utils/i18n'
import {FormCanonicalValues, ExtractFormSchemaFields} from 'utils/form/schema'
import AddButton from 'components/AddButton'
import DocumentFileDialog, {
  documentFileFormSchema,
} from 'components/DocumentFileDialog'

interface AddUB04DocumentFileButtonProps {
  className?: string
  onDialogOpened?: () => void
  accept?: string
}

export const AddUB04DocumentFileButton: FC<AddUB04DocumentFileButtonProps> = flowMax(
  addDisplayName('AddUB04DocumentFileButton'),
  addDocumentFilesContext,
  branch(({isFileEditingFrozen}) => isFileEditingFrozen, renderNothing()),
  addDialogState,
  addEffect(
    ({isShowingDialog, onDialogOpened}) => () => {
      if (!isShowingDialog) return
      onDialogOpened?.()
    },
    ['isShowingDialog']
  ),
  addCreateDocumentFileMutation({
    refetchQueries: ({refetchQueries}) => refetchQueries,
    awaitRefetchQueries: true,
  }),
  addTranslationHelpers,
  addState('isSubmitting', 'setIsSubmitting', false),
  addHandlers({
    onSubmit: ({
      mutateCreateDocumentFile,
      hideDialog,
      additionalCreateDocumentFileMutateVariables,
      setIsSubmitting,
      t,
    }) => ({
      canonicalValues,
    }: {
      canonicalValues: FormCanonicalValues<
        ExtractFormSchemaFields<typeof documentFileFormSchema>
      >
    }) => {
      mutateCreateDocumentFile({
        variables: {
          documentFile: {
            ...canonicalValues.documentFile,
            ...additionalCreateDocumentFileMutateVariables,
          },
        },
      })
        .then(() => {
          hideDialog()
          setIsSubmitting(false)
        })
        .catch(() => {
          window.alert(t('auditItem.documentFiles.failedToSave'))
          setIsSubmitting(false)
        })
      setIsSubmitting(true)
    },
  }),
  ({
    isShowingDialog,
    showDialog,
    hideDialog,
    onSubmit,
    isSubmitting,
    className,
    accept,
    t,
  }) => (
    <>
      <AddButton onClick={showDialog} className={className}>
        {t('auditItem.documentFile.add')}
      </AddButton>
      <DocumentFileDialog
        accept={accept}
        open={isShowingDialog}
        onClose={hideDialog}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
      />
    </>
  )
)
