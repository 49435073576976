import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addHandlers,
  addProps,
  addState,
  addStateHandlers,
  addEffect,
} from 'ad-hok'

import {PhoneNumberFields} from 'graphql/deserializedTypes/PhoneNumberFields'
import {makeClasses, addClasses} from 'theme'
import addHasSaved from 'utils/addHasSaved'
import {addTranslationHelpers} from 'utils/i18n'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogContent from 'components/DialogContent'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import Body1 from 'components/Body1'
import {getFormattedPhoneNumber} from 'utils/phone'
import {StandaloneSelectField} from 'components/SelectField'
import {addWebformsContext} from 'components/EditPersonForm/webformsContext'
import {WebformFields} from 'graphql/deserializedTypes/WebformFields'
import {addSendWebformLinkMutation} from 'graphql/generated'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'

const classes = makeClasses((theme) => ({
  paper: {
    minWidth: 400,
  },
  webformSelect: {
    minWidth: 200,
  },
  phoneNumber: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
}))

type AddAutoselectionOfSingleWebformOption = <
  TProps extends {
    webformId: string
    setWebformId: (webformId: string) => void
    webforms: WebformFields[]
  }
>(
  props: TProps
) => TProps

const addAutoselectionOfSingleWebformOption: AddAutoselectionOfSingleWebformOption = flowMax(
  addStateHandlers(
    {
      hasAutoselectedSingleWebformOption: false,
    },
    {
      markHasAutoselectedSingleWebformOption: () => () => ({
        hasAutoselectedSingleWebformOption: true,
      }),
    }
  ),
  addEffect(
    ({
      webforms,
      webformId,
      setWebformId,
      hasAutoselectedSingleWebformOption,
      markHasAutoselectedSingleWebformOption,
    }) => () => {
      if (hasAutoselectedSingleWebformOption) return
      if (!!webformId) return
      if (webforms.length !== 1) return
      setWebformId(webforms[0].id)
      markHasAutoselectedSingleWebformOption()
    },
    ['webforms']
  )
)

interface Props {
  open: boolean
  onCancel: () => void
  phoneNumber: PhoneNumberFields
}

const SendWebformDialog: FC<Props> = flowMax(
  addDisplayName('SendWebformDialog'),
  addState('webformId', 'setWebformId', ''),
  addSendWebformLinkMutation({}),
  addTranslationHelpers,
  addAppSnackbarContext,
  addHandlers({
    onConfirm: ({
      webformId,
      onCancel,
      mutateSendWebformLink,
      phoneNumber,
      showSnackbarMessage,
      t,
    }) => () => {
      if (!webformId) return
      mutateSendWebformLink({
        variables: {
          webformId,
          phoneNumberId: phoneNumber.id,
        },
      })
        .then(() => {
          showSnackbarMessage(t('sendWebformDialog.sent'))
        })
        .catch((_error) => {
          window.alert(t('sendWebformDialog.failedToSend'))
        })
      onCancel()
    },
    onWebformChange: ({setWebformId}) => ({
      target: {value: webformId},
    }: React.ChangeEvent<{value: unknown}>) => {
      setWebformId(webformId as string)
    },
  }),
  addHasSaved('onConfirm'),
  addWebformsContext,
  addAutoselectionOfSingleWebformOption,
  addProps(
    ({webforms}) => ({
      webformOptions: [
        {
          value: '',
          label: '',
        },
        ...webforms.map(({id, name}) => ({
          value: id,
          label: name,
        })),
      ],
    }),
    ['webforms']
  ),
  addClasses(classes),
  ({
    open,
    onCancel,
    onConfirm,
    hasSaved,
    webformId,
    phoneNumber,
    onWebformChange,
    webformOptions,
    classes,
    t,
  }) => (
    <Dialog open={open} onClose={onCancel} classes={{paper: classes.paper}}>
      <DialogTitle>{t('sendWebformDialog.title')}</DialogTitle>
      <DialogContent>
        <Body1>{t('sendWebformDialog.phoneNumber')}</Body1>
        <Body1 className={classes.phoneNumber}>
          {getFormattedPhoneNumber(phoneNumber.number)}
        </Body1>
        <StandaloneSelectField
          value={webformId}
          id="webform-name"
          onChange={onWebformChange}
          label={t('sendWebformDialog.webformName')}
          options={webformOptions}
          className={classes.webformSelect}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t('buttons.cancel')}
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          variant="contained"
          disabled={hasSaved || !webformId}
        >
          {t('buttons.send')}
        </Button>
      </DialogActions>
    </Dialog>
  )
)

export default SendWebformDialog
