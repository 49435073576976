/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {UpdateReceivedWebformInput} from './globalTypes' // ====================================================
// GraphQL mutation operation: UpdateReceivedWebform
// ====================================================

import {deserializeUpdateReceivedWebformInput} from './globalTypes'
import {serializeUpdateReceivedWebformInput} from './globalTypes'
import {UpdateReceivedWebformVariables as UpdateReceivedWebformVariablesOrig} from '../types/UpdateReceivedWebform'
import {UpdateReceivedWebform as UpdateReceivedWebformOrig} from '../types/UpdateReceivedWebform'
import {ReceivedWebformResponse} from 'utils/form/getReceivedWebformResponse'
import {parseReceivedWebformResponse} from 'utils/form/getReceivedWebformResponse'
import {serializeReceivedWebformResponse} from 'utils/form/getReceivedWebformResponse'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {UpdateReceivedWebform_updateReceivedWebform as UpdateReceivedWebform_updateReceivedWebformOrig} from '../types/UpdateReceivedWebform'
import {UpdateReceivedWebform_updateReceivedWebform_person as UpdateReceivedWebform_updateReceivedWebform_personOrig} from '../types/UpdateReceivedWebform'
export interface UpdateReceivedWebform_updateReceivedWebform_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdateReceivedWebform_updateReceivedWebform_person = (
  serialized: UpdateReceivedWebform_updateReceivedWebform_personOrig
): UpdateReceivedWebform_updateReceivedWebform_person => ({...serialized})
export const serializeUpdateReceivedWebform_updateReceivedWebform_person = (
  deserialized: UpdateReceivedWebform_updateReceivedWebform_person
): UpdateReceivedWebform_updateReceivedWebform_personOrig => ({...deserialized})
export interface UpdateReceivedWebform_updateReceivedWebform {
  __typename: 'ReceivedWebform'
  id: string
  completedAt: Date
  response: ReceivedWebformResponse
  noPersonMatch: boolean
  createdAt: Date
  person: UpdateReceivedWebform_updateReceivedWebform_person | null
}
export const deserializeUpdateReceivedWebform_updateReceivedWebform = (
  serialized: UpdateReceivedWebform_updateReceivedWebformOrig
): UpdateReceivedWebform_updateReceivedWebform => ({
  ...serialized,
  completedAt: parseDate(serialized.completedAt),
  response: parseReceivedWebformResponse(serialized.response),
  createdAt: parseDate(serialized.createdAt),
  person:
    serialized.person != null
      ? deserializeUpdateReceivedWebform_updateReceivedWebform_person(
          serialized.person
        )
      : serialized.person,
})
export const serializeUpdateReceivedWebform_updateReceivedWebform = (
  deserialized: UpdateReceivedWebform_updateReceivedWebform
): UpdateReceivedWebform_updateReceivedWebformOrig => ({
  ...deserialized,
  completedAt: formatISO(deserialized.completedAt),
  response: serializeReceivedWebformResponse(deserialized.response),
  createdAt: formatISO(deserialized.createdAt),
  person:
    deserialized.person != null
      ? serializeUpdateReceivedWebform_updateReceivedWebform_person(
          deserialized.person
        )
      : deserialized.person,
})
export interface UpdateReceivedWebform {
  /**
   * Update existing received webform
   */
  updateReceivedWebform: UpdateReceivedWebform_updateReceivedWebform
}
export const deserializeUpdateReceivedWebform = (
  serialized: UpdateReceivedWebformOrig
): UpdateReceivedWebform => ({
  ...serialized,
  updateReceivedWebform: deserializeUpdateReceivedWebform_updateReceivedWebform(
    serialized.updateReceivedWebform
  ),
})
export const serializeUpdateReceivedWebform = (
  deserialized: UpdateReceivedWebform
): UpdateReceivedWebformOrig => ({
  ...deserialized,
  updateReceivedWebform: serializeUpdateReceivedWebform_updateReceivedWebform(
    deserialized.updateReceivedWebform
  ),
})
export interface UpdateReceivedWebformVariables {
  receivedWebform: UpdateReceivedWebformInput
}
export const deserializeUpdateReceivedWebformVariables = (
  serialized: UpdateReceivedWebformVariablesOrig
): UpdateReceivedWebformVariables => ({
  ...serialized,
  receivedWebform: deserializeUpdateReceivedWebformInput(
    serialized.receivedWebform
  ),
})
export const serializeUpdateReceivedWebformVariables = (
  deserialized: UpdateReceivedWebformVariables
): UpdateReceivedWebformVariablesOrig => ({
  ...deserialized,
  receivedWebform: serializeUpdateReceivedWebformInput(
    deserialized.receivedWebform
  ),
})
