import React, {ReactNode, ComponentProps, ElementType, FC} from 'react'
import Typography from '@material-ui/core/Typography'

export interface TextProps {
  children?: ReactNode
  className?: string
  variant?: ComponentProps<typeof Typography>['variant']
  component?: ElementType<any>
}

const Heading: FC<TextProps> = ({
  variant = 'h1',
  component = 'h1',
  ...props
}) => <Typography variant={variant} component={component} {...props} />

export default Heading
