import React from 'react'
import {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core/Checkbox'
import {FieldProps} from 'formik'
import {FormControlLabelProps as MuiFormControlLabelProps} from '@material-ui/core/FormControlLabel'

import {FieldComponentProps} from 'components/TextField'
import Field, {addFieldInfo} from 'components/Field'
import FormControlLabel from 'components/FormControlLabel'
import FormHelperText from 'components/FormHelperText'
import {addFieldError} from 'utils/field'
import {makeClasses, addClasses} from 'theme'

const classes = makeClasses((theme) => ({
  fieldContainer: {
    marginBottom: theme.spacing(-2),
  },
  error: {
    color: '#f44336',
  },
}))

interface CheckboxProps
  extends FieldProps,
    Omit<
      MuiCheckboxProps,
      | 'name'
      | 'value'
      | 'error'
      | 'form'
      | 'checked'
      | 'defaultChecked'
      // Excluded for conflict with Field type
      | 'type'
    > {
  type?: string
}

function fieldToCheckbox({
  disabled,
  field,
  form: {isSubmitting},
  ...props
}: CheckboxProps): MuiCheckboxProps {
  const indeterminate = !Array.isArray(field.value) && field.value == null

  return {
    disabled: disabled ?? isSubmitting,
    indeterminate,
    type: 'checkbox',
    checked: !!field.value,
    ...props,
    ...field,
  }
}

interface CheckboxWithLabelProps extends FieldProps, CheckboxProps {
  Label: Omit<
    MuiFormControlLabelProps,
    'checked' | 'name' | 'onChange' | 'value' | 'control'
  >
}

const CheckboxWithLabel: FC<CheckboxWithLabelProps> = flowMax(
  addDisplayName('CheckboxWithLabel'),
  ({Label, ...props}) => (
    <FormControlLabel
      control={<MuiCheckbox {...fieldToCheckbox(props)} />}
      {...Label}
    />
  )
)

const CheckboxField: FC<FieldComponentProps> = flowMax(
  addDisplayName('CheckboxField'),
  addProps({
    exampleValue: true,
  }),
  addFieldInfo,
  addFieldError,
  addProps(
    ({label}) => ({
      Label: {label},
    }),
    ['label']
  ),
  addClasses(classes),
  ({fieldError, classes, ...props}) => (
    <div>
      <Field
        component={CheckboxWithLabel}
        omitProps={['helperText', 'fullWidth']}
        className={classes.fieldContainer}
        {...props}
      />
      {fieldError && (
        <FormHelperText className={classes.error}>{fieldError}</FormHelperText>
      )}
    </div>
  )
)

export default CheckboxField
