import React, {FC} from 'react'
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
} from '@material-ui/core'
import {flowMax, addDisplayName, addProps, addState, addHandlers} from 'ad-hok'
import {addInterval} from 'ad-hok-utils'
import {Grid} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import {editPersonFormSchema} from 'components/EditPersonForm/schema'
import {addFormikTyped} from 'utils/form/formik'
import {addTranslationHelpers} from 'utils/i18n'
import {addEventLogsQuery} from 'graphql/generated'
import {addLoadingIndicator} from 'utils/dataLoading'
import {makeClasses, addClasses} from 'theme'
import {EVENT_LOG_QUERY} from 'graphql/queries'
import {getLongDate} from 'utils/date'
import Box from 'components/Box'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import IconButton from 'components/IconButton'

const classes = makeClasses((theme) => ({
  boxBorder: {
    color: '#000000',
    width: 375,
    border: '1px solid lightgrey',
    borderRadius: 8,
    padding: 7,
    margin: 1,
  },
  tableHeader: {
    backgroundColor: '#3f51b5',
    color: 'white',
  },
  tableCellHeader: {
    padding: '0px 5px 0px 0px',
    color: 'white',
  },
  tableCellHeaderIcon: {
    color: 'white',
  },
  tableCell: {
    padding: '0px 5px 0px 0px',
    fontSize: '11px',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#dfe3e8',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
}))

export const EVENT_LOG_POLL_INTERVAL = 15 * 1000

const EventLogSection: FC = flowMax(
  addDisplayName('PersonEventLogSection'),
  addAppSnackbarContext,
  addState('open', 'setOpen', false),
  addFormikTyped(editPersonFormSchema),
  addProps(({formik: {values: {person}}}) => ({
    personValues: person,
  })),
  addProps(({personValues: {id: personId}}) => ({personId})),
  addEventLogsQuery({
    variables: ({personId}) => ({personId}),
  }),
  addLoadingIndicator({}),
  addInterval(
    ({refetchEventLogs}) => () => {
      refetchEventLogs()
    },
    EVENT_LOG_POLL_INTERVAL,
    ['personId']
  ),
  addProps(
    ({personId}) => ({
      refetchQueries: [
        {
          query: EVENT_LOG_QUERY,
          variables: {personId},
        },
      ],
    }),
    ['personId']
  ),
  addClasses(classes),
  addHandlers({
    updateStatus: ({open, setOpen}) => () => {
      setOpen(!open)
    },
  }),
  addTranslationHelpers,
  ({classes, eventLogs, open, updateStatus}) => (
    <div>
      <Box className={classes.boxBorder}>
        <Grid container>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={updateStatus}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Typography style={{fontStyle: 'Bold'}}>Event Log </Typography>
          {eventLogs.length > 0 && (
            <TableContainer component={Paper} style={{maxHeight: 300}}>
              <TableBody>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Table aria-label="Event Log Table">
                    <TableHead className={classes.tableHeader}>
                      <TableRow>
                        <TableCell
                          className={classes.tableCellHeader}
                          align="center"
                          style={{minWidth: 40}}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          className={classes.tableCellHeader}
                          align="center"
                          style={{minWidth: 80}}
                        >
                          User
                        </TableCell>
                        <TableCell
                          className={classes.tableCellHeader}
                          align="center"
                          style={{minWidth: 30}}
                        >
                          App ID
                        </TableCell>
                        <TableCell
                          className={classes.tableCellHeader}
                          align="center"
                          style={{minWidth: 200}}
                        >
                          Log Details
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {eventLogs.map((eventLog) => (
                      <TableRow className={classes.tableRow} key={eventLog.id}>
                        <TableCell
                          className={classes.tableCell}
                          style={{minWidth: 40}}
                        >
                          [{getLongDate(eventLog.createdAt)}]
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{minWidth: 80}}
                        >
                          [{eventLog.eventInitiatedBy.name}]
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{minWidth: 30}}
                        >
                          {eventLog.applicationId}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{minWidth: 200}}
                        >
                          {eventLog.eventMessage}
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Collapse>
              </TableBody>
            </TableContainer>
          )}
          {eventLogs.length === 0 && (
            <Typography style={{fontStyle: 'italic'}}>
              &nbsp;&nbsp;- None
            </Typography>
          )}
        </Grid>
      </Box>
    </div>
  )
)
export default EventLogSection
