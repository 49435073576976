/* eslint-disable */
import {NjMedicaidApplicationStatesVariables as NjMedicaidApplicationStatesVariablesOrig} from '../types/NjMedicaidApplicationStates'
import {NjMedicaidApplicationStates as NjMedicaidApplicationStatesOrig} from '../types/NjMedicaidApplicationStates'
import {NjMedicaidApplicationStates_njMedicaidApplicationStates as NjMedicaidApplicationStates_njMedicaidApplicationStatesOrig} from '../types/NjMedicaidApplicationStates'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: NjMedicaidApplicationStates
// ====================================================
export interface NjMedicaidApplicationStates_njMedicaidApplicationStates {
  __typename: 'ApplyStateMedicaid'
  id: string
  status: string
  processState: string
  errorMessage: string | null
}
export const deserializeNjMedicaidApplicationStates_njMedicaidApplicationStates = (
  serialized: NjMedicaidApplicationStates_njMedicaidApplicationStatesOrig
): NjMedicaidApplicationStates_njMedicaidApplicationStates => ({...serialized})
export const serializeNjMedicaidApplicationStates_njMedicaidApplicationStates = (
  deserialized: NjMedicaidApplicationStates_njMedicaidApplicationStates
): NjMedicaidApplicationStates_njMedicaidApplicationStatesOrig => ({
  ...deserialized,
})
export interface NjMedicaidApplicationStates {
  /**
   * NJ Medicaid Application States
   */
  njMedicaidApplicationStates:
    | NjMedicaidApplicationStates_njMedicaidApplicationStates[]
    | null
}
export const deserializeNjMedicaidApplicationStates = (
  serialized: NjMedicaidApplicationStatesOrig
): NjMedicaidApplicationStates => ({
  ...serialized,
  njMedicaidApplicationStates:
    serialized.njMedicaidApplicationStates != null
      ? serialized.njMedicaidApplicationStates.map(
          deserializeNjMedicaidApplicationStates_njMedicaidApplicationStates
        )
      : serialized.njMedicaidApplicationStates,
})
export const serializeNjMedicaidApplicationStates = (
  deserialized: NjMedicaidApplicationStates
): NjMedicaidApplicationStatesOrig => ({
  ...deserialized,
  njMedicaidApplicationStates:
    deserialized.njMedicaidApplicationStates != null
      ? deserialized.njMedicaidApplicationStates.map(
          serializeNjMedicaidApplicationStates_njMedicaidApplicationStates
        )
      : deserialized.njMedicaidApplicationStates,
})
export interface NjMedicaidApplicationStatesVariables {
  applicationId: string
}
export const deserializeNjMedicaidApplicationStatesVariables = (
  serialized: NjMedicaidApplicationStatesVariablesOrig
): NjMedicaidApplicationStatesVariables => ({...serialized})
export const serializeNjMedicaidApplicationStatesVariables = (
  deserialized: NjMedicaidApplicationStatesVariables
): NjMedicaidApplicationStatesVariablesOrig => ({...deserialized})
