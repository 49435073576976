/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {NjmmisCheckResult} from './globalTypes' // ====================================================
// GraphQL query operation: Accounts
// ====================================================

import {AccountsVariables as AccountsVariablesOrig} from '../types/Accounts'
import {Accounts as AccountsOrig} from '../types/Accounts'
import {deserializeNjmmisCheckResult} from 'graphql/converters'
import {serializeNjmmisCheckResult} from 'graphql/converters'
import formatISO from 'date-fns/formatISO'
import {Accounts_accounts as Accounts_accountsOrig} from '../types/Accounts'
import {parseDate} from 'utils/form/fieldTypes'
import {formatISODate} from 'utils/date'
import {Accounts_accounts_person as Accounts_accounts_personOrig} from '../types/Accounts'
import {Accounts_accounts_person_phoneNumbers as Accounts_accounts_person_phoneNumbersOrig} from '../types/Accounts'
export interface Accounts_accounts_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializeAccounts_accounts_person_phoneNumbers = (
  serialized: Accounts_accounts_person_phoneNumbersOrig
): Accounts_accounts_person_phoneNumbers => ({...serialized})
export const serializeAccounts_accounts_person_phoneNumbers = (
  deserialized: Accounts_accounts_person_phoneNumbers
): Accounts_accounts_person_phoneNumbersOrig => ({...deserialized})
export interface Accounts_accounts_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  dob: Date | null
  hospitalPatientId: string | null
  suffix: string | null
  gender: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: Accounts_accounts_person_phoneNumbers[]
  email: string | null
  emailComment: string | null
  preferredLanguage: string | null
}
export const deserializeAccounts_accounts_person = (
  serialized: Accounts_accounts_personOrig
): Accounts_accounts_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeAccounts_accounts_person_phoneNumbers
  ),
})
export const serializeAccounts_accounts_person = (
  deserialized: Accounts_accounts_person
): Accounts_accounts_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeAccounts_accounts_person_phoneNumbers
  ),
})
export interface Accounts_accounts {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
  timeOfService: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location: string | null
  insuranceType: string
  status: string
  createdAt: Date
  updatedAt: Date
  notes: string | null
  accountAmount: number | null
  njmmisCheck: string | null
  previousInsurance: string | null
  invoiceMonth: string | null
  invoiceYear: string | null
  dischargeDate: Date | null
  isScheduled: boolean
  person: Accounts_accounts_person
}
export const deserializeAccounts_accounts = (
  serialized: Accounts_accountsOrig
): Accounts_accounts => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
  person: deserializeAccounts_accounts_person(serialized.person),
})
export const serializeAccounts_accounts = (
  deserialized: Accounts_accounts
): Accounts_accountsOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
  person: serializeAccounts_accounts_person(deserialized.person),
})
export interface Accounts {
  /**
   * List of accounts
   */
  accounts: Accounts_accounts[]
}
export const deserializeAccounts = (serialized: AccountsOrig): Accounts => ({
  ...serialized,
  accounts: serialized.accounts.map(deserializeAccounts_accounts),
})
export const serializeAccounts = (deserialized: Accounts): AccountsOrig => ({
  ...deserialized,
  accounts: deserialized.accounts.map(serializeAccounts_accounts),
})
export interface AccountsVariables {
  statuses?: string[] | null
  insuranceTypes?: string[] | null
  dateOfServiceAfter?: Date | null
  dateOfServiceBefore?: Date | null
  facility?: string[] | null
  hasExternalVendorCode?: boolean | null
  coveredByBenefitType?: string[] | null
  coveredByBenefitOutcome?: string[] | null
  coveredByOpenApplicationStatus?: string[] | null
  search?: string | null
  hospitalAccountId?: string | null
  invoiceMonth?: string[] | null
  invoiceYear?: string[] | null
}
export const deserializeAccountsVariables = (
  serialized: AccountsVariablesOrig
): AccountsVariables => ({
  ...serialized,
  dateOfServiceAfter:
    serialized.dateOfServiceAfter != null
      ? parseDate(serialized.dateOfServiceAfter)
      : serialized.dateOfServiceAfter,
  dateOfServiceBefore:
    serialized.dateOfServiceBefore != null
      ? parseDate(serialized.dateOfServiceBefore)
      : serialized.dateOfServiceBefore,
})
export const serializeAccountsVariables = (
  deserialized: AccountsVariables
): AccountsVariablesOrig => ({
  ...deserialized,
  dateOfServiceAfter:
    deserialized.dateOfServiceAfter != null
      ? formatISODate(deserialized.dateOfServiceAfter)
      : deserialized.dateOfServiceAfter,
  dateOfServiceBefore:
    deserialized.dateOfServiceBefore != null
      ? formatISODate(deserialized.dateOfServiceBefore)
      : deserialized.dateOfServiceBefore,
})
