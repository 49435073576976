import gql from 'graphql-tag'

import {
  personFragment,
  accountFragment,
  taskFragment,
  triageFragment,
  applicationFragment,
  personQueryFragment,
  applicationQueryFragment,
  documentFragment,
  documentFileFragment,
  benefitOutcomeFragment,
  esignSessionFragment,
  phoneNumberFragment,
  webformMergeAttemptFragment,
  receivedWebformFragment,
  userFragment,
  inboundsQueryFragment,
  editableFileFragment,
} from './fragments'

export const CREATE_ACCOUNT_AND_PERSON_MUTATION = gql`
  mutation CreateAccountAndPerson(
    $person: PersonInput!
    $account: AccountInput!
  ) {
    createAccountAndPerson(person: $person, account: $account) {
      ...AccountFields
      person {
        ...PersonFields
      }
    }
  }
  ${personFragment}
  ${accountFragment}
`

export const UPDATE_TASK_MUTATION = gql`
  mutation UpdateTask($task: UpdateTaskInput!) {
    updateTask(task: $task) {
      ...TaskFields
    }
  }
  ${taskFragment}
`

export const UPDATE_TRIAGE_MUTATION = gql`
  mutation UpdateTriage($triage: TriageInput!) {
    updateTriage(triage: $triage) {
      ...TriageFields
      assignedTo {
        ...UserFields
      }
    }
  }
  ${triageFragment}
  ${userFragment}
`

export const UPDATE_PERSON_MUTATION = gql`
  mutation UpdatePerson($person: UpdatePersonInput!) {
    updatePerson(person: $person) {
      ...PersonQueryFields
    }
  }
  ${personQueryFragment}
`

export const UPDATE_ACCOUNT_MUTATION = gql`
  mutation UpdateAccount($account: UpdateAccountInput!) {
    updateAccount(account: $account) {
      ...AccountFields
    }
  }
  ${accountFragment}
`

export const SAVE_ELIGIBILITY_DETERMINATIONS_MUTATION = gql`
  mutation SaveEligibilityDeterminations(
    $determinations: [EligibilityDeterminationInput!]!
    $person: UpdatePersonInput!
    $householdMembers: [ApplicationHouseholdMembersInput!]
  ) {
    saveEligibilityDeterminations(
      determinations: $determinations
      person: $person
      householdMembers: $householdMembers
    ) {
      ...PersonQueryFields
    }
  }
  ${personQueryFragment}
`

export const CREATE_APPLICATION_MUTATION = gql`
  mutation CreateApplication($application: ApplicationInput!) {
    createApplication(application: $application) {
      ...ApplicationFields
    }
  }
  ${applicationFragment}
`

export const UPDATE_APPLICATION_STATUS_MUTATION = gql`
  mutation UpdateApplicationStatus(
    $application: UpdateApplicationStatusInput!
  ) {
    updateApplicationStatus(application: $application) {
      ...ApplicationFields
    }
  }
  ${applicationFragment}
`

export const UPDATE_APPLICATION_MUTATION = gql`
  mutation UpdateApplication($application: UpdateApplicationInput!) {
    updateApplication(application: $application) {
      ...ApplicationQueryFields
    }
  }
  ${applicationQueryFragment}
`

export const CREATE_DOCUMENT_MUTATION = gql`
  mutation CreateDocument($document: DocumentInput!) {
    createDocument(document: $document) {
      ...DocumentFields
    }
  }
  ${documentFragment}
`

export const UPDATE_DOCUMENT_MUTATION = gql`
  mutation UpdateDocument($document: UpdateDocumentInput!) {
    updateDocument(document: $document) {
      ...DocumentFields
    }
  }
  ${documentFragment}
`

export const CREATE_OR_UPDATE_BENEFIT_OUTCOME_MUTATION = gql`
  mutation CreateOrUpdateBenefitOutcome($benefitOutcome: BenefitOutcomeInput!) {
    createOrUpdateBenefitOutcome(benefitOutcome: $benefitOutcome) {
      ...BenefitOutcomeFields
    }
  }
  ${benefitOutcomeFragment}
`

export const DELETE_BENEFIT_OUTCOME_MUTATION = gql`
  mutation DeleteBenefitOutcome($id: ID!) {
    deleteBenefitOutcome(id: $id) {
      id
    }
  }
`

export const DELETE_DOCUMENT_MUTATION = gql`
  mutation DeleteDocument($id: ID!) {
    deleteDocument(id: $id) {
      id
    }
  }
`

export const UPDATE_ACCOUNT_STATUS_MUTATION = gql`
  mutation UpdateAccountStatus($account: UpdateAccountStatusInput!) {
    updateAccountStatus(account: $account) {
      ...AccountFields
    }
  }
  ${accountFragment}
`

export const UPDATE_READY_FOR_AUDIT_MUTATION = gql`
  mutation UpdateReadyForAudit($auditFlag: UpdateReadyForAuditInput!) {
    updateReadyForAudit(auditFlag: $auditFlag)
  }
`

export const CREATE_ZIP_AND_UPLOAD_MUTATION = gql`
  mutation CreateZipAndUpload($audit_id: ID!, $audit_data: [AuditDataInput!]!) {
    createZipAndUpload(auditId: $audit_id, auditData: $audit_data) {
      success
    }
  }
`

export const CREATE_DOCUMENT_FILE_MUTATION = gql`
  mutation CreateDocumentFile($documentFile: DocumentFileInput!) {
    createDocumentFile(documentFile: $documentFile) {
      ...DocumentFileFields
    }
  }
  ${documentFileFragment}
`

export const DELETE_DOCUMENT_FILE_MUTATION = gql`
  mutation DeleteDocumentFile($id: ID!) {
    deleteDocumentFile(id: $id) {
      id
    }
  }
`

export const CREATE_TASK_MUTATION = gql`
  mutation CreateTask($task: TaskInput!) {
    createTask(task: $task) {
      ...TaskFields
    }
  }
  ${taskFragment}
`

export const DELETE_TASK_MUTATION = gql`
  mutation DeleteTask($id: ID!) {
    deleteTask(id: $id) {
      id
    }
  }
`

export const UPDATE_ESIGN_SESSION_MUTATION = gql`
  mutation UpdateEsignSession($esignSession: UpdateEsignSessionInput!) {
    updateEsignSession(esignSession: $esignSession) {
      ...EsignSessionFields
    }
  }
  ${esignSessionFragment}
`

export const SEND_WEBFORM_LINK_MUTATION = gql`
  mutation SendWebformLink($phoneNumberId: ID!, $webformId: ID!) {
    sendWebformLink(phoneNumberId: $phoneNumberId, webformId: $webformId) {
      ...PhoneNumberFields
    }
  }
  ${phoneNumberFragment}
`

export const MARK_WEBFORM_MERGE_ATTEMPT_REVIEWED_MUTATION = gql`
  mutation MarkWebformMergeAttemptReviewed($webformMergeAttemptId: ID!) {
    markWebformMergeAttemptReviewed(
      webformMergeAttemptId: $webformMergeAttemptId
    ) {
      ...WebformMergeAttemptFields
    }
  }
  ${webformMergeAttemptFragment}
`

export const UPDATE_RECEIVED_WEBFORM_MUTATION = gql`
  mutation UpdateReceivedWebform(
    $receivedWebform: UpdateReceivedWebformInput!
  ) {
    updateReceivedWebform(receivedWebform: $receivedWebform) {
      ...ReceivedWebformFields
      person {
        id
      }
    }
  }
  ${receivedWebformFragment}
`

export const CREATE_ACCOUNT_AND_PERSON_FROM_WEBFORM_MUTATION = gql`
  mutation CreateAccountAndPersonFromWebform(
    $receivedWebformId: ID!
    $account: AccountInput!
  ) {
    createAccountAndPersonFromWebform(
      receivedWebformId: $receivedWebformId
      account: $account
    ) {
      ...AccountFields
      person {
        ...PersonFields
      }
    }
  }
  ${personFragment}
  ${accountFragment}
`

export const START_INBOUND_MUTATION = gql`
  mutation StartInbound($id: ID!, $associateWithPersonHavingMrn: String) {
    startInbound(
      id: $id
      associateWithPersonHavingMrn: $associateWithPersonHavingMrn
    ) {
      ...InboundsQueryFields
    }
  }
  ${inboundsQueryFragment}
`

export const UNSTART_INBOUND_MUTATION = gql`
  mutation UnstartInbound($id: ID!) {
    unstartInbound(id: $id) {
      ...InboundsQueryFields
    }
  }
  ${inboundsQueryFragment}
`

export const COMPLETE_INBOUND_MUTATION = gql`
  mutation CompleteInbound($id: ID!) {
    completeInbound(id: $id) {
      ...InboundsQueryFields
    }
  }
  ${inboundsQueryFragment}
`

export const UNCOMPLETE_INBOUND_MUTATION = gql`
  mutation UncompleteInbound($id: ID!) {
    uncompleteInbound(id: $id) {
      ...InboundsQueryFields
    }
  }
  ${inboundsQueryFragment}
`

export const CREATE_EDITABLE_FILE_MUTATION = gql`
  mutation CreateEditableFile(
    $editableFile: EditableFileInput!
    $sendForSigning: Boolean
    $sendForRemoteSigningSessionId: String
    $setUnreviewed: Boolean
    $applicationId: ID
    $sendDocumentType: String
    $isDocumentSendOnly: Boolean
    $isDocumentSendAndSave: Boolean
    $isEsignDocument: Boolean
  ) {
    createEditableFile(
      editableFile: $editableFile
      sendForSigning: $sendForSigning
      sendForRemoteSigningSessionId: $sendForRemoteSigningSessionId
      setUnreviewed: $setUnreviewed
      applicationId: $applicationId
      sendDocumentType: $sendDocumentType
      isDocumentSendOnly: $isDocumentSendOnly
      isDocumentSendAndSave: $isDocumentSendAndSave
      isEsignDocument: $isEsignDocument
    ) {
      ...EditableFileFields
    }
  }
  ${editableFileFragment}
`

export const UPDATE_EDITABLE_FILE_MUTATION = gql`
  mutation UpdateEditableFile(
    $editableFile: UpdateEditableFileInput!
    $sendForSigning: Boolean
    $sendForRemoteSigningSessionId: String
    $sendForFax: String
    $markUnreviewed: Boolean
    $applicationId: ID
    $sendDocumentType: String
    $isDocumentSendOnly: Boolean
    $isDocumentSendAndSave: Boolean
    $isEsignDocument: Boolean
  ) {
    updateEditableFile(
      editableFile: $editableFile
      sendForSigning: $sendForSigning
      sendForRemoteSigningSessionId: $sendForRemoteSigningSessionId
      sendForFax: $sendForFax
      markUnreviewed: $markUnreviewed
      applicationId: $applicationId
      sendDocumentType: $sendDocumentType
      isDocumentSendOnly: $isDocumentSendOnly
      isDocumentSendAndSave: $isDocumentSendAndSave
      isEsignDocument: $isEsignDocument
    ) {
      ...EditableFileFields
    }
  }
  ${editableFileFragment}
`

export const SEND_EDITABLE_FILE_TO_EMR_MUTATION = gql`
  mutation SendEditableFileToEmr($id: ID!) {
    sendEditableFileToEmr(id: $id) {
      ...EditableFileFields
    }
  }
  ${editableFileFragment}
`

export const MARK_EDITABLE_FILE_REVIEWED_MUTATION = gql`
  mutation MarkEditableFileReviewed($editableFileId: ID!, $applicationId: ID!) {
    markEditableFileReviewed(
      editableFileId: $editableFileId
      applicationId: $applicationId
    ) {
      ...EditableFileFields
      isUnreviewed(applicationId: $applicationId)
    }
  }
  ${editableFileFragment}
`

export const MERGE_EDITABLE_FILES_MUTATION = gql`
  mutation MergeEditableFiles($editableFileIds: [ID!]!, $applicationId: ID!) {
    mergeEditableFiles(
      editableFileIds: $editableFileIds
      applicationId: $applicationId
    ) {
      ...EditableFileFields
    }
  }
  ${editableFileFragment}
`

export const DELETE_EDITABLE_FILE_MUTATION = gql`
  mutation DeleteEditableFile($id: ID!) {
    deleteEditableFile(id: $id) {
      id
    }
  }
`

export const DELETE_EDITABLE_FILE_APPLICATION_ASSOCIATION_MUTATION = gql`
  mutation DeleteEditableFileApplicationAssociation(
    $editableFileId: ID!
    $applicationId: ID!
  ) {
    deleteEditableFileApplicationAssociation(
      editableFileId: $editableFileId
      applicationId: $applicationId
    ) {
      id
    }
  }
`

export const MARK_RECEIVED_WEBFORM_FILE_TEMPLATE_CLEARED_MUTATION = gql`
  mutation MarkReceivedWebformFileTemplateCleared($id: ID!) {
    markReceivedWebformFileTemplateCleared(id: $id) {
      id
      cleared
    }
  }
`

export const RETRY_INBOUND_NJMMIS_CHECK_MUTATION = gql`
  mutation RetryInboundNjmmisCheck($id: ID!) {
    retryInboundNjmmisCheck(id: $id) {
      ...InboundsQueryFields
    }
  }
  ${inboundsQueryFragment}
`

export const CREATE_LIGHTICO_WORKFLOWS_MUTATION = gql`
  mutation CreateLighticoWorkflows($esignSessionId: ID!, $workflowIds: [ID!]!) {
    createLighticoWorkflows(
      esignSessionId: $esignSessionId
      workflowIds: $workflowIds
    )
  }
`

export const SEND_DOCUMENT_REQUESTS_MUTATION = gql`
  mutation SendDocumentRequests(
    $esignSessionId: String!
    $phoneNumber: String
    $language: String
    $applicationId: ID!
    $personId: ID!
    $requestorIds: [ID!]!
    $documentIds: [ID!]!
    $smsMessage: String
  ) {
    sendDocumentRequests(
      esignSessionId: $esignSessionId
      phoneNumber: $phoneNumber
      language: $language
      applicationId: $applicationId
      personId: $personId
      requestorIds: $requestorIds
      documentIds: $documentIds
      smsMessage: $smsMessage
    )
  }
`

export const CREATE_SEND_DOCUMENT_REQUESTS_MUTATION = gql`
  mutation CreateSendDocumentRequests(
    $phoneNumber: String
    $language: String
    $applicationId: ID!
    $personId: ID!
    $requestorIds: [ID!]!
    $documentIds: [ID!]!
    $smsMessage: String
  ) {
    createSendDocumentRequests(
      phoneNumber: $phoneNumber
      language: $language
      applicationId: $applicationId
      personId: $personId
      requestorIds: $requestorIds
      documentIds: $documentIds
      smsMessage: $smsMessage
    )
  }
`
export const CANCEL_DOCUMENT_REQUESTS_MUTATION = gql`
  mutation CancelDocumentRequests($requestIds: [ID!]!) {
    cancelDocumentRequests(requestIds: $requestIds)
  }
`

export const UPDATE_PERSON_MESSAGE_READ_MUTATION = gql`
  mutation UpdatePersonMessageRead($personId: ID!) {
    updatePersonMessageRead(personId: $personId)
  }
`

export const CREATE_NJMMIS_CAPTURE_RESULT_MUTATION = gql`
  mutation CreateNjmmisCaptureResult($applicationId: ID!) {
    createNjmmisCaptureResult(applicationId: $applicationId) {
      id
      status
    }
  }
`

export const APPLY_STATE_MEDICAID_MUTATION = gql`
  mutation ApplyStateMedicaid(
    $applicationId: ID!
    $personId: ID!
    $stateCode: String
  ) {
    applyStateMedicaid(
      applicationId: $applicationId
      personId: $personId
      stateCode: $stateCode
    ) {
      id
      status
      errorMessage
    }
  }
`
