import React from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import {FC} from 'react'

import {addClasses, makeClasses} from 'theme'
import {addTranslationHelpers} from 'utils/i18n'
import Body1 from 'components/Body1'

const classes = makeClasses(() => ({
  itemValue: {
    fontWeight: 'bold',
  },
}))

interface Props {
  label: string
  value: string | null
  className?: string
}

const LabeledDisplayItem: FC<Props> = flowMax(
  addDisplayName('LabeledDisplayItem'),
  addTranslationHelpers,
  addProps(
    ({value, t}) => ({
      value: value || t('general.unknown'),
    }),
    ['value', 't']
  ),
  addClasses(classes),
  ({label, value, className, classes}) => (
    <Body1 className={className}>
      {label}: <span className={classes.itemValue}>{value}</span>
    </Body1>
  )
)
export default LabeledDisplayItem
