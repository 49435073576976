import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'

import {makeClasses, addClasses} from 'theme'
import {addPersonContext} from 'components/PersonDetail/personContext'
import TriageItem from 'components/TriageItem'

const classes = makeClasses((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
}))

const PersonDetailTriages: FC = flowMax(
  addDisplayName('PersonDetailTriages'),
  addPersonContext,
  addProps(({person: {triages}, person}) => ({
    triages: triages.map((triage) => ({
      ...triage,
      account: {
        ...triage.account,
        person,
      },
    })),
  })),
  addClasses(classes),
  ({triages, classes}) => (
    <div className={classes.container}>
      {triages.map((triage) => (
        <TriageItem triage={triage} key={triage.id} />
      ))}
    </div>
  )
)

export default PersonDetailTriages
