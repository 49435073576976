import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addHandlers,
  addStateHandlers,
} from 'ad-hok'
import {branchIfEmpty} from 'ad-hok-utils'
import {format} from 'date-fns/fp'
import {pickBy, flow, mapValues, isEmpty, omit} from 'lodash/fp'
import cx from 'classnames'

import {addRightColumnContext} from 'components/EditPersonForm/rightColumnContext'
import {makeClasses, addClasses} from 'theme'
import {addTranslationHelpers} from 'utils/i18n'
import Subtitle2 from 'components/Subtitle2'
import Paper from 'components/Paper'
import Grid from 'components/Grid'
import {Person_person_webformMergeAttempts} from 'graphql/deserializedTypes/Person'
import Body1 from 'components/Body1'
import {toEasternTime} from 'utils/date'
import {WebformMergeAttemptFields} from 'graphql/deserializedTypes/WebformMergeAttemptFields'
import Button from 'components/Button'
import {addMarkWebformMergeAttemptReviewedMutation} from 'graphql/generated'
import {
  WebformMergeResult,
  WebformRecordMergeResult,
  MergeRecordResultFields,
} from 'utils/form/getWebformMergeResult'
import typedAs from 'utils/typedAs'
import {
  WebformResponse,
  getFormattedResponseSections,
  WebformResponseSection,
  FormattedSection,
  getWebformRecordsSpec,
} from 'utils/webforms'
import ConfirmationDialog from 'components/ConfirmationDialog'

const classes = makeClasses((theme) => ({
  header: {
    marginBottom: theme.spacing(2),
  },
  itemContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  webformName: {
    fontWeight: 'bold',
  },
  completedAt: {
    fontStyle: 'italic',
  },
  confirmationDialog: {
    maxWidth: 400,
  },
  noConflictsFound: {
    fontSize: 18,
    fontWeight: 500,
  },
  conflictsFound: {
    color: 'red',
  },
}))

interface MarkMergedButtonProps {
  webformMergeAttempt: WebformMergeAttemptFields
}

const MarkMergedButton: FC<MarkMergedButtonProps> = flowMax(
  addDisplayName('MarkMergedButton'),
  addMarkWebformMergeAttemptReviewedMutation({}),
  addHandlers({
    onConfirm: ({
      webformMergeAttempt,
      mutateMarkWebformMergeAttemptReviewed,
    }) => () => {
      mutateMarkWebformMergeAttemptReviewed({
        variables: {
          webformMergeAttemptId: webformMergeAttempt.id,
        },
      })
    },
  }),
  addStateHandlers(
    {
      isShowingConfirmationDialog: false,
    },
    {
      onClick: () => () => ({
        isShowingConfirmationDialog: true,
      }),
      hideConfirmationDialog: () => () => ({
        isShowingConfirmationDialog: false,
      }),
    }
  ),
  addClasses(classes),
  addTranslationHelpers,
  ({
    onClick,
    isShowingConfirmationDialog,
    hideConfirmationDialog,
    onConfirm,
    classes,
    t,
  }) => (
    <>
      <Button variant="contained" onClick={onClick}>
        {t('personForm.unreviewedWebformMerges.markReviewed')}
      </Button>
      <ConfirmationDialog
        title={t('markWebformMergeAttemptReviewedDialog.title')}
        cancelText={t('buttons.cancel')}
        confirmText={t('markWebformMergeAttemptReviewedDialog.confirm')}
        message={t('markWebformMergeAttemptReviewedDialog.description')}
        open={isShowingConfirmationDialog}
        onCancel={hideConfirmationDialog}
        onConfirm={onConfirm}
        className={classes.confirmationDialog}
      />
    </>
  )
)

const accepts = <TArg,>() => (arg: TArg) => arg

const getIsMeaninglessIgnored = (recordName: string) =>
  /^(phone|relationship)/.test(recordName)

const IGNORE_FIELD_NAMES = ['personId']

const extractFieldsConflicts = (result: WebformRecordMergeResult) =>
  flow(
    accepts<MergeRecordResultFields>(),
    (fields) =>
      result === 'updated'
        ? pickBy(({result}) => result === 'conflict', fields)
        : fields,
    omit(IGNORE_FIELD_NAMES)
  )

const webformMergeResultToRecords = (
  mergeResult: WebformMergeResult
): WebformResponse =>
  mapValues(
    ({fields}) => mapValues(({webformValue}) => webformValue, fields),
    mergeResult
  )

const extractConflicts = flow(
  accepts<WebformMergeResult>(),
  mapValues(({result, fields}) => ({
    result,
    fields: extractFieldsConflicts(result)(fields),
  })),
  pickBy(
    ({result, fields}, recordName) =>
      typedAs<WebformRecordMergeResult[]>(['updated', 'ignored']).includes(
        result
      ) &&
      !isEmpty(fields) &&
      !(result === 'ignored' && getIsMeaninglessIgnored(recordName))
  ),
  accepts<WebformMergeResult>(),
  webformMergeResultToRecords
)

const swapInRelatedPersonGroupLabels = (
  formattedResponseSections: FormattedSection[],
  formattedResponseSectionsUnfiltered: FormattedSection[]
): FormattedSection[] =>
  formattedResponseSections.map((section) => ({
    ...section,
    groupLabel:
      formattedResponseSectionsUnfiltered.find(
        ({groupLabel}) =>
          section.groupLabel && groupLabel?.startsWith(section.groupLabel)
      )?.groupLabel || section.groupLabel,
  }))

interface MergeConflictsProps {
  webformMergeAttempt: Person_person_webformMergeAttempts
}

const MergeConflicts: FC<MergeConflictsProps> = flowMax(
  addDisplayName('MergeConflicts'),
  addProps(({webformMergeAttempt: {mergeResult}}) => ({
    mergeResultRecords: extractConflicts(mergeResult),
    mergeResultRecordsUnfiltered: webformMergeResultToRecords(mergeResult),
  })),
  addTranslationHelpers,
  addClasses(classes),
  branchIfEmpty('mergeResultRecords', {
    returns: ({t, classes}) => (
      <Body1 className={classes.noConflictsFound}>
        {t('personForm.unreviewedWebformMerges.noConflictsFound')}
      </Body1>
    ),
  }),
  addProps(({webformMergeAttempt: {receivedWebform: {webform}}}) => ({
    webformRecordsSpec: getWebformRecordsSpec(webform),
  })),
  addProps(
    ({
      webformRecordsSpec,
      mergeResultRecords,
      mergeResultRecordsUnfiltered,
      t,
    }) => ({
      formattedResponseSections: getFormattedResponseSections(
        webformRecordsSpec,
        mergeResultRecords,
        t
      ),
      formattedResponseSectionsUnfiltered: getFormattedResponseSections(
        webformRecordsSpec,
        mergeResultRecordsUnfiltered,
        t
      ),
    }),
    [
      'webformRecordsSpec',
      'mergeResultRecords',
      'mergeResultRecordsUnfiltered',
      't',
    ]
  ),
  addProps(
    ({formattedResponseSections, formattedResponseSectionsUnfiltered}) => ({
      formattedResponseSections: swapInRelatedPersonGroupLabels(
        formattedResponseSections,
        formattedResponseSectionsUnfiltered
      ),
    }),
    ['formattedResponseSections', 'formattedResponseSectionsUnfiltered']
  ),
  ({formattedResponseSections, classes, t}) => (
    <>
      <Body1 className={cx(classes.noConflictsFound, classes.conflictsFound)}>
        {t('personForm.unreviewedWebformMerges.conflictsFound')}
      </Body1>
      {formattedResponseSections.map((section, index) => (
        <WebformResponseSection section={section} key={index} />
      ))}
    </>
  )
)

interface UnreviewedWebformMergeProps {
  webformMergeAttempt: Person_person_webformMergeAttempts
}

const UnreviewedWebformMerge: FC<UnreviewedWebformMergeProps> = flowMax(
  addDisplayName('UnreviewedWebformMerge'),
  addClasses(classes),
  addTranslationHelpers,
  ({
    webformMergeAttempt: {
      receivedWebform: {completedAt, webform},
    },
    webformMergeAttempt,
    classes,
    t,
  }) => (
    <Paper className={classes.itemContainer}>
      <Grid container direction="row" justify="space-between">
        <Grid item>
          <Body1 className={classes.webformName}>
            {t('personForm.unreviewedWebformMerges.webformName', {
              name: webform.name,
            })}
          </Body1>
          <Body1 className={classes.completedAt}>
            {t('personForm.unreviewedWebformMerges.completedAt', {
              date: format('M/d/yy HH:mm:ss')(toEasternTime(completedAt)),
            })}
          </Body1>
        </Grid>
        <Grid item>
          <MarkMergedButton webformMergeAttempt={webformMergeAttempt} />
        </Grid>
      </Grid>
      <MergeConflicts webformMergeAttempt={webformMergeAttempt} />
    </Paper>
  )
)

const UnreviewedWebformMerges: FC = flowMax(
  addDisplayName('UnreviewedWebformMerges'),
  addRightColumnContext,
  addProps(
    ({person: {webformMergeAttempts}}) => ({
      unreviewedMerges: webformMergeAttempts.filter(({reviewed}) => !reviewed),
    }),
    ['person']
  ),
  branchIfEmpty('unreviewedMerges'),
  addTranslationHelpers,
  addClasses(classes),
  ({unreviewedMerges, classes, t}) => (
    <>
      <Subtitle2 className={classes.header}>
        {t('personForm.unreviewedWebformMerges.header')}
      </Subtitle2>
      {unreviewedMerges.map((webformMergeAttempt) => (
        <UnreviewedWebformMerge
          webformMergeAttempt={webformMergeAttempt}
          key={webformMergeAttempt.id}
        />
      ))}
    </>
  )
)

export default UnreviewedWebformMerges
