import React from 'react'
import {
  flowMax,
  addDisplayName,
  addWrapper,
  addHandlers,
  addProps,
  addMemoBoundary,
} from 'ad-hok'
import {FC} from 'react'
import {get} from 'lodash'
import {addPropIdentityStabilization} from 'ad-hok-utils'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {TestContext} from 'yup'

import Form, {formColumnWidth, OnSubmitSuccessOptions} from 'components/Form'
import {addTranslationHelpers} from 'utils/i18n'
import theme, {addClasses, makeClasses} from 'theme'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogContent from 'components/DialogContent'
import SelectField from 'components/SelectField'
import MultilineTextField from 'components/MultilineTextField'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import TextField from 'components/TextField'
import OptionalBooleanField from 'components/OptionalBooleanField'
import {makeFormSchema, ExtractFormSchemaFields} from 'utils/form/schema'
import {
  makeTextField,
  makePhoneField,
  makeLanguageField,
  makeBooleanField,
  makeEsignSessionStatusField,
  ValidatorTest,
} from 'utils/form/fieldTypes'
import addHasSaved from 'utils/addHasSaved'
import {determinerPhoneNumberForm} from 'components/PhoneNumberDialog/determiner'
import {addFieldsFreshnessContext} from 'utils/form/context'
import {PersonFields_phoneNumbers} from 'graphql/deserializedTypes/PersonFields'
import Accordion from 'components/Accordion'
import AccordionSummary from 'components/AccordionSummary'
import Body1 from 'components/Body1'
import AccordionDetails from 'components/AccordionDetails'
import {addFormikTyped} from 'utils/form/formik'

const classes = makeClasses(() => ({
  contentContainer: {
    width: formColumnWidth,
  },
  esignSessionContainer: {
    marginBottom: theme.spacing(2),
  },
  esignSessionContentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const languageRequiredForEsignValidatorTest: ValidatorTest = [
  'form.phoneNumberLanguageRequiredForEsign',
  function (this: TestContext, value: string) {
    const {usedForEsign} = this.parent
    if (!usedForEsign) return true
    if (value) return true
    return false
  },
]

export const phoneNumberFormSchema = makeFormSchema({
  fields: {
    phoneNumber: {
      id: makeTextField(),
      number: makePhoneField({isRequired: true}),
      language: makeLanguageField({
        validatorTest: languageRequiredForEsignValidatorTest,
      }),
      comment: makeTextField(),
      usedInApp: makeBooleanField(),
      usedForEsign: makeBooleanField(),
      esignSessionId: makeTextField(),
      esignSessionStatus: makeEsignSessionStatusField(),
    },
  },
})

interface Props {
  phoneNumber?: PersonFields_phoneNumbers
  itemName?: string
  onSave: (
    options: OnSubmitSuccessOptions<
      ExtractFormSchemaFields<typeof phoneNumberFormSchema>
    >
  ) => void
  open: boolean
  onClose: () => void
}

const PhoneNumberDialog: FC<Props> = flowMax(
  addDisplayName('PhoneNumberDialog'),
  addFieldsFreshnessContext,
  addProps(({fieldsFreshness, itemName}) => ({
    initialFieldsFreshness: {
      phoneNumber: itemName ? get(fieldsFreshness, itemName, {}) : {},
    },
  })),
  addPropIdentityStabilization('initialFieldsFreshness'),
  addMemoBoundary([
    'phoneNumber',
    'onSave',
    'open',
    'onClose',
    'initialFieldsFreshness',
  ]),
  addHasSaved('onSave'),
  addWrapper(
    (render, {open, onClose, phoneNumber, onSave, initialFieldsFreshness}) => (
      <Dialog
        open={open}
        onClose={onClose}
        data-testid={`phone-dialog-${phoneNumber?.id ?? 'new'}`}
      >
        <Form
          name="phoneNumberForm"
          schema={phoneNumberFormSchema}
          onSubmitSuccess={onSave}
          initialValues={phoneNumber && {phoneNumber}}
          initialFieldsFreshness={initialFieldsFreshness}
          shouldTrackFieldsUpdatedAt
          determiners={[determinerPhoneNumberForm]}
        >
          {render()}
        </Form>
      </Dialog>
    )
  ),
  addFormikTyped(phoneNumberFormSchema),
  addHandlers({
    onSaveClick: ({formik: {submitForm}}) => () => {
      submitForm()
    },
  }),
  addProps(
    ({phoneNumber}) => ({
      isNumberFieldDisabled: !!phoneNumber?.esignSessionId,
    }),
    ['phoneNumber']
  ),
  addTranslationHelpers,
  addClasses(classes),
  ({onClose, onSaveClick, hasSaved, classes, isNumberFieldDisabled, t}) => (
    <>
      <DialogTitle>{t('phoneNumberDialog.title')}</DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <TextField
          type="tel"
          name={'phoneNumber.number'}
          disabled={isNumberFieldDisabled}
        />
        <SelectField name={'phoneNumber.language'} />
        <MultilineTextField name={'phoneNumber.comment'} />
        <OptionalBooleanField name={'phoneNumber.usedInApp'} />
        <OptionalBooleanField name={'phoneNumber.usedForEsign'} />
        <Accordion className={classes.esignSessionContainer}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Body1>{t('phoneNumberDialog.esignSession')}</Body1>
          </AccordionSummary>
          <AccordionDetails className={classes.esignSessionContentContainer}>
            <TextField name={'phoneNumber.esignSessionId'} />
            <SelectField name={'phoneNumber.esignSessionStatus'} disabled />
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('phoneNumberDialog.cancel')}
        </Button>
        <Button
          onClick={onSaveClick}
          variant="contained"
          color="primary"
          disabled={hasSaved}
        >
          {t('phoneNumberDialog.save')}
        </Button>
      </DialogActions>
    </>
  )
)

export default PhoneNumberDialog
