import React, {FC} from 'react'
import {flowMax, addDisplayName, addHandlers} from 'ad-hok'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import {saveAs as saveFileAs} from 'file-saver'

import {addFetchDocumentFileUrl, getBasename} from 'components/DocumentFileLink'
import IconButton from 'components/IconButton'
import {DocumentFileFields} from 'graphql/deserializedTypes/DocumentFileFields'

interface Props {
  documentFile: DocumentFileFields
}

const DocumentFileDownloadLink: FC<Props> = flowMax(
  addDisplayName('DocumentFileDownloadLink'),
  addFetchDocumentFileUrl,
  addHandlers({
    onClick: ({fetchDocumentFileUrl, documentFile}) => async () => {
      const url = await fetchDocumentFileUrl(documentFile)
      const response = await fetch(url, {
        method: 'GET',
      })
      const fileBlob = await response.blob()
      saveFileAs(fileBlob, getBasename(documentFile.fileKey))
    },
  }),
  ({onClick}) => (
    <IconButton onClick={onClick}>
      <SaveAltIcon />
    </IconButton>
  )
)

export default DocumentFileDownloadLink
