import React from 'react'
import {
  flowMax,
  addDisplayName,
  addWrapper,
  addHandlers,
  addProps,
  addEffect,
} from 'ad-hok'
import {FC} from 'react'
import {TFunction} from 'i18next'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import grey from '@material-ui/core/colors/grey'
import {TestContext} from 'yup'
import isAfter from 'date-fns/isAfter'
import parseISO from 'date-fns/parseISO'
import {PureQueryOptions} from 'apollo-boost'

import Form from 'components/Form'
import {addTranslationHelpers} from 'utils/i18n'
import {addClasses, makeClasses} from 'theme'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogContent from 'components/DialogContent'
import SelectField from 'components/SelectField'
import DateField from 'components/DateField'
import TextField from 'components/TextField'
import FormRow from 'components/FormRow'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import MultilineTextField from 'components/MultilineTextField'
import DisplayItem from 'components/DisplayItem'
import Grid from 'components/Grid'
import Caption from 'components/Caption'
import OverlappingBenefitOutcomes from 'components/BenefitOutcomeFormDialog/OverlappingBenefitOutcomes'
import {makeFormSchema} from 'utils/form/schema'
import {
  makeTextField,
  makeSelectField,
  makeDateField,
  ValidatorTest,
  makeNumericSelectField,
} from 'utils/form/fieldTypes'
import {addFormikTyped} from 'utils/form/formik'
import {addCreateOrUpdateBenefitOutcomeMutation} from 'graphql/generated'
import {getInitialValues, getCanonicalValues} from 'utils/form/getValues'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import {getExtendedName} from 'utils/name'
import {getApplicationName} from 'utils/application'
import {formatISODate} from 'utils/date'
import {
  getMedicaidEffectiveStartDateDefault,
  isBenefitOutcomeOverlapping,
  getOverlappingBenefitOutcomes,
} from 'utils/benefitOutcome'
import addFormikHoistedState, {
  addFormikHoistedStateCallbacks,
} from 'utils/addFormikHoistedState'
import {Assert, SchemaDoesntHaveExtraFields} from 'utils/form/typeHelpers'
import {FacilityFields} from 'graphql/deserializedTypes/FacilityFields'
import {addFacilities} from 'utils/configContext'
import {BenefitType} from 'utils/benefits'

const classes = makeClasses((theme) => ({
  contentContainer: {
    width: 600,
  },
  personDisplay: {
    marginBottom: theme.spacing(3),
  },
  saveInfo: {
    width: 'auto',
    marginRight: theme.spacing(2),
  },
  saveInfoIcon: {
    marginRight: theme.spacing(1),
    color: grey[500],
  },
}))

export const BENEFIT_OUTCOME_OUTCOME_APPROVED = 'Approved'
const BENEFIT_OUTCOME_OUTCOME_DENIED = 'Denied'
const BENEFIT_OUTCOME_OUTCOME_INELIGIBLE = 'Discovered ineligible'
export const BENEFIT_OUTCOME_OUTCOME_CLIENT_ACQUIRED_OTHER_INSURANCE =
  'Client acquired other insurance'
export const BENEFIT_OUTCOME_OUTCOMES = [
  BENEFIT_OUTCOME_OUTCOME_APPROVED,
  BENEFIT_OUTCOME_OUTCOME_DENIED,
  BENEFIT_OUTCOME_OUTCOME_INELIGIBLE,
  "Client didn't attend visit",
  'Insufficient documents',
  'Client unreachable',
  'Client no longer interested',
  'Transfer to 3rd party',
  'Deceased',
  BENEFIT_OUTCOME_OUTCOME_CLIENT_ACQUIRED_OTHER_INSURANCE,
  'Other',
]

const BENEFIT_OUTCOME_INELIGIBLE_REASONS = [
  'C/I',
  'Income',
  'NJ residency',
  'Asset',
  'Insufficient verification',
  'Other',
]

export const BENEFIT_OUTCOME_COVERED_FACILITY_ALL = 'All'

const deniedIneligibleReasonRequiredIfOutcomeDeniedOrIneligible: ValidatorTest = [
  'form.required',
  function (this: TestContext /*value: string*/) {
    const {outcome, deniedIneligibleReason} = this.parent
    return !(
      [
        BENEFIT_OUTCOME_OUTCOME_DENIED,
        BENEFIT_OUTCOME_OUTCOME_INELIGIBLE,
      ].includes(outcome) && !deniedIneligibleReason
    )
  },
]

const patientResponsibilityRequiredIfOutcomeApproved: ValidatorTest = [
  'form.required',
  function (this: TestContext /*value: string*/) {
    const {outcome, patientResponsibility} = this.parent
    return !(
      outcome === BENEFIT_OUTCOME_OUTCOME_APPROVED &&
      (patientResponsibility === null || patientResponsibility === undefined)
    )
  },
]

const isInvalidDate = (date: Date) => /Invalid Date/.test(`${date}`)

export const effectiveDateEndBeforeStart: ValidatorTest = [
  'form.endBeforeStart',
  function (this: TestContext /*value: string*/) {
    const {effectiveStartDate, effectiveEndDate} = this.parent
    if (!effectiveStartDate || !effectiveEndDate) return true
    const startDate = parseISO(effectiveStartDate)
    const endDate = parseISO(effectiveEndDate)
    if ([startDate, endDate].some(isInvalidDate)) return true
    return !isAfter(startDate, endDate)
  },
]

export const getBenefitsNonOverlappingValidatorTest = ({
  benefitOutcomeId,
  benefitOutcomes,
  applicationCharityCareType,
  applicationSlideType,
}: {
  benefitOutcomeId?: string
  benefitOutcomes: BenefitOutcome[]
  applicationCharityCareType: string
  applicationSlideType: string | null
}) => [
  'benefitOutcomeForm.overlappingBenefits',
  function (this: TestContext) {
    const {
      effectiveStartDate,
      effectiveEndDate,
      benefit,
      coveredFacility,
    } = this.parent
    if (!effectiveStartDate || !effectiveEndDate) return true
    const startDate = parseISO(effectiveStartDate)
    const endDate = parseISO(effectiveEndDate)
    if ([startDate, endDate].some(isInvalidDate)) return true

    return !isBenefitOutcomeOverlapping({
      benefitOutcomeId,
      effectiveStartDate: startDate,
      effectiveEndDate: endDate,
      benefit,
      coveredFacility,
      benefitOutcomes,
      applicationCharityCareType,
      applicationSlideType,
    })
  },
]

export const BENEFIT_OUTCOME_BENEFIT_OPTION_OTHER = 'other'
export const BENEFIT_OUTCOME_BENEFIT_OPTIONS = [
  {
    label: 'Medicaid',
    value: 'medicaid',
  },
  {
    label: 'Charity Care',
    value: 'charityCare',
  },
  {
    label: 'Slide',
    value: 'slide',
  },
  {
    label: 'Ryan White',
    value: 'ryanWhite',
  },
  {
    label: 'Other',
    value: BENEFIT_OUTCOME_BENEFIT_OPTION_OTHER,
  },
]

const getBenefitOutcomeFormSchema = ({
  benefitOutcomeId,
  benefitOutcomes,
  facilities,
  userIsApprover,
  editable,
  applicationCharityCareType,
  applicationSlideType,
}: {
  benefitOutcomeId?: string
  benefitOutcomes: BenefitOutcome[]
  facilities: FacilityFields[]
  userIsApprover: boolean
  editable: boolean
  applicationCharityCareType: string
  applicationSlideType: string | null
}) =>
  makeFormSchema({
    fields: {
      benefitOutcome: {
        id: makeTextField(),
        benefit: makeSelectField({
          isRequired: true,
          options: BENEFIT_OUTCOME_BENEFIT_OPTIONS,
        }),
        coveredFacility: makeSelectField({
          isRequired: true,
          options: [
            BENEFIT_OUTCOME_COVERED_FACILITY_ALL,
            ...facilities.map(({name}) => name),
          ].map((value) => ({value})),
        }),
        outcome: makeSelectField({
          isRequired: true,
          options: BENEFIT_OUTCOME_OUTCOMES.filter(
            (outcome) =>
              !editable ||
              userIsApprover ||
              outcome !== BENEFIT_OUTCOME_OUTCOME_APPROVED
          ).map((value) => ({value})),
        }),
        patientResponsibility: makeNumericSelectField({
          options: ['0', '20', '40', '60', '80'].map((value) => ({
            value,
            label: `${value}%`,
          })),
          validatorTest: patientResponsibilityRequiredIfOutcomeApproved,
        }),
        outcomeDate: makeDateField(),
        deniedIneligibleReason: makeSelectField({
          options: BENEFIT_OUTCOME_INELIGIBLE_REASONS.map((value) => ({value})),
          validatorTest: deniedIneligibleReasonRequiredIfOutcomeDeniedOrIneligible,
        }),
        effectiveStartDate: makeDateField({
          isRequired: true,
          validatorTests: [
            effectiveDateEndBeforeStart,
            benefitOutcomes &&
              getBenefitsNonOverlappingValidatorTest({
                benefitOutcomeId,
                benefitOutcomes,
                applicationCharityCareType,
                applicationSlideType,
              }),
          ].filter((v) => v) as ValidatorTest[],
        }),
        effectiveEndDate: makeDateField({
          isRequired: true,
          validatorTests: [
            effectiveDateEndBeforeStart,
            benefitOutcomes &&
              getBenefitsNonOverlappingValidatorTest({
                benefitOutcomeId,
                benefitOutcomes,
                applicationCharityCareType,
                applicationSlideType,
              }),
          ].filter((v) => v) as ValidatorTest[],
        }),
        insuranceName: makeTextField(),
        policyId: makeTextField(),
        personId: makeTextField({
          isRequired: true,
        }),
        applicationId: makeSelectField({}),
        notes: makeTextField(),
      },
    },
  })

const benefitOutcomeFormSchemaStatic = getBenefitOutcomeFormSchema({
  benefitOutcomes: [],
  facilities: [],
  userIsApprover: false,
  editable: false,
  applicationCharityCareType: '',
  applicationSlideType: '',
})

const getOutcomeBenefit = (benefit: string | undefined) =>
  benefit === 'medicaid' || benefit === 'monitor' ? 'medicaid' : benefit

const getApplicationSelectOptions = ({
  application,
  t,
}: {
  application: OutcomeApplication
  t: TFunction
}) => [{value: application.id, label: getApplicationName({...application, t})}]

const getNewBenefitOutcome = ({
  personId,
  application: {id: applicationId, benefit, facility},
  initialDateOfService,
  applicationCharityCareType,
  applicationSlideType,
}: {
  personId: string
  application: OutcomeApplication
  initialDateOfService?: Date | null
  applicationCharityCareType?: string | null
  applicationSlideType?: string | null
}) => ({
  ...getCanonicalValues(
    getInitialValues(benefitOutcomeFormSchemaStatic),
    benefitOutcomeFormSchemaStatic
  ).benefitOutcome,
  personId,
  applicationId,
  applicationCharityCareType: ['charityCare'].includes(benefit)
    ? applicationCharityCareType ?? null
    : null,
  applicationSlideType: ['slide'].includes(benefit)
    ? applicationSlideType ?? null
    : null,
  outcomeDate: new Date(),
  effectiveStartDate: ['charityCare', 'slide', 'ryanWhite'].includes(benefit)
    ? initialDateOfService ?? null
    : null,
  effectiveEndDate: new Date(),
  benefit: getOutcomeBenefit(benefit) ?? '',
  policyId: ['charityCare', 'slide', 'ryanWhite'].includes(benefit)
    ? applicationId
    : '',
  coveredFacility: facility || BENEFIT_OUTCOME_COVERED_FACILITY_ALL,
  patientResponsibility: ['medicaid', 'slide', 'ryanWhite'].includes(benefit)
    ? 0
    : null, // TODO: add business logic for defaulting this
})

export interface BenefitOutcome {
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  application: {
    id: string
    charityCareType?: string | null
    slideType?: string | null
  } | null
  patientResponsibility: number | null
}

export interface OutcomePerson {
  id: string
  firstName: string | null
  lastName: string | null
  preferredName: string | null
  middleName: string | null
  suffix: string | null
  benefitOutcomes: BenefitOutcome[]
}

export interface OutcomeApplication {
  id: string
  benefit: BenefitType
  facility?: string
  initialDateOfService?: Date | null
  charityCareType?: string | null
  slideType?: string | null
}

type Check = Assert<
  SchemaDoesntHaveExtraFields<
    typeof benefitOutcomeFormSchemaStatic,
    typeof addCreateOrUpdateBenefitOutcomeMutation
  >
>

interface Props {
  benefitOutcome?: BenefitOutcome
  person: OutcomePerson
  application: OutcomeApplication
  submitDate?: Date | null
  initialDateOfService?: Date | null
  refetchQueriesOnCreateOrUpdate: PureQueryOptions[]
  open: boolean
  onCancel: () => void
  onSaveSuccess: () => void
  userIsApprover: boolean
  editable: boolean
}

const BenefitOutcomeFormDialog: FC<Props> = flowMax(
  addDisplayName('BenefitOutcomeFormDialog'),
  addAppSnackbarContext,
  addTranslationHelpers,
  addHandlers({
    onSave: ({benefitOutcome, showSnackbarMessage, onSaveSuccess, t}) => () => {
      showSnackbarMessage(
        benefitOutcome
          ? t('benefitOutcomeForm.updatedMessage')
          : t('benefitOutcomeForm.createdMessage')
      )
      onSaveSuccess()
    },
  }),
  addProps(
    ({
      benefitOutcome,
      person: {id: personId},
      application: {
        id: applicationId,
        charityCareType: applicationCharityCareType,
        slideType: applicationSlideType,
      },
      application,
      initialDateOfService,
    }) => ({
      initialValues: {
        benefitOutcome: benefitOutcome
          ? {
              ...benefitOutcome,
              personId,
              applicationId,
              applicationCharityCareType,
              applicationSlideType,
            }
          : getNewBenefitOutcome({
              personId,
              application,
              initialDateOfService,
              applicationCharityCareType,
              applicationSlideType,
            }),
      },
    })
  ),
  addFacilities,
  addProps(
    ({
      benefitOutcome,
      person: {benefitOutcomes},
      facilities,
      userIsApprover,
      editable,
      application: {charityCareType: applicationCharityCareType},
      application: {slideType: applicationSlideType},
    }) => ({
      benefitOutcomeFormSchema: getBenefitOutcomeFormSchema({
        benefitOutcomeId: benefitOutcome?.id,
        benefitOutcomes,
        facilities,
        userIsApprover,
        editable,
        applicationCharityCareType: applicationCharityCareType
          ? applicationCharityCareType
          : '',
        applicationSlideType: applicationSlideType ? applicationSlideType : '',
      }),
    }),
    [
      'benefitOutcome',
      'person.benefitOutcomes',
      'facilities',
      'userIsApprover',
      'editable',
      'application.charityCareType',
      'application.slideType',
    ]
  ),
  addCreateOrUpdateBenefitOutcomeMutation({
    refetchQueries: ({refetchQueriesOnCreateOrUpdate}) =>
      refetchQueriesOnCreateOrUpdate,
  }),
  addFormikHoistedState,
  addClasses(classes),
  addWrapper(
    (
      render,
      {
        benefitOutcomeFormSchema,
        open,
        onCancel,
        benefitOutcome,
        initialValues,
        onSave,
        submitFormRef,
        mutateCreateOrUpdateBenefitOutcome,
        isSubmitting,
        classes,
        t,
        editable,
      }
    ) => (
      <Dialog
        open={open}
        onClose={onCancel}
        data-testid={`benefit-outcome-dialog-${benefitOutcome?.id ?? 'new'}`}
        scroll="paper"
      >
        <DialogTitle>{t('benefitOutcomeDialog.title')}</DialogTitle>
        <DialogContent className={classes.contentContainer} dividers>
          <Form
            name="benefitOutcomeForm"
            schema={benefitOutcomeFormSchema}
            mutate={mutateCreateOrUpdateBenefitOutcome}
            onSubmitSuccess={onSave}
            initialValues={initialValues}
          >
            {render()}
          </Form>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.saveInfo}
          >
            {editable && (
              <>
                <InfoOutlinedIcon className={classes.saveInfoIcon} />
                <Caption>{t('benefitOutcomeForm.saveInfo')}</Caption>
              </>
            )}
          </Grid>
          <Button onClick={onCancel} color="primary">
            {t('benefitOutcomeDialog.cancel')}
          </Button>
          {editable && (
            <Button
              onClick={() => submitFormRef.current?.()}
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              {t('benefitOutcomeDialog.save')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  ),
  addFormikHoistedStateCallbacks,
  addFormikTyped(benefitOutcomeFormSchemaStatic),
  // eslint-disable-next-line ad-hok/dependencies
  addEffect(
    ({
      person: {benefitOutcomes},
      application: {benefit},
      submitDate,
      initialDateOfService,
      formik: {
        setFieldValue,
        values: {
          benefitOutcome: {effectiveStartDate, outcome},
        },
      },
    }) => () => {
      if (
        outcome === '' ||
        outcome === BENEFIT_OUTCOME_OUTCOME_APPROVED ||
        effectiveStartDate ||
        !['medicaid', 'monitor'].includes(benefit) ||
        !(submitDate || initialDateOfService)
      )
        return
      setFieldValue(
        'benefitOutcome.effectiveStartDate',
        formatISODate(
          initialDateOfService ??
            getMedicaidEffectiveStartDateDefault({
              submitDate: submitDate!,
              benefitOutcomes,
            })
        )
      )
    },
    ['formik.values.benefitOutcome.outcome']
  ),
  // eslint-disable-next-line ad-hok/dependencies
  addProps(
    ({
      benefitOutcome,
      person: {benefitOutcomes},
      application,
      formik: {
        values: {
          benefitOutcome: {
            effectiveStartDate,
            effectiveEndDate,
            benefit,
            coveredFacility,
            // charityCareType,
          },
        },
      },
    }) => ({
      overlappingBenefitOutcomes: getOverlappingBenefitOutcomes({
        benefitOutcomeId: benefitOutcome?.id,
        effectiveStartDate: parseISO(effectiveStartDate),
        effectiveEndDate: parseISO(effectiveEndDate),
        benefit,
        coveredFacility,
        benefitOutcomes,
        applicationCharityCareType: application.charityCareType
          ? application.charityCareType
          : '',
        applicationSlideType: application.slideType
          ? application.slideType
          : '',
      }),
    }),
    ['person.benefitOutcome', 'formik.values']
  ),
  ({person, application, overlappingBenefitOutcomes, classes, t, editable}) => (
    <>
      <div className={classes.personDisplay}>
        <DisplayItem
          i18nKey="benefitOutcomeForm.personIdDisplay"
          translations={{
            id: person.id,
          }}
        />
        <DisplayItem
          i18nKey="benefitOutcomeForm.personNameDisplay"
          translations={{
            name: getExtendedName({...person, t}),
          }}
        />
      </div>
      <FormRow variant="twoColumn_1_1_true">
        <SelectField
          name={'benefitOutcome.benefit'}
          disabled={!!application || !editable}
          noIcon
        />
        <SelectField
          name={'benefitOutcome.coveredFacility'}
          disabled={!!application || !editable}
          noIcon
        />
      </FormRow>
      <FormRow variant="twoColumn_1_1_true">
        <SelectField
          name="benefitOutcome.outcome"
          noIcon
          disabled={!editable}
        />
        {application.benefit === 'charityCare' && (
          <SelectField
            name="benefitOutcome.patientResponsibility"
            noIcon
            disabled={!editable}
          />
        )}
      </FormRow>
      <DateField
        name="benefitOutcome.outcomeDate"
        noIcon
        disabled={!editable}
      />
      <SelectField
        name="benefitOutcome.deniedIneligibleReason"
        noIcon
        disabled={!editable}
      />
      <FormRow variant="twoColumn_1_1_true">
        <DateField
          name="benefitOutcome.effectiveStartDate"
          noIcon
          disabled={!editable}
        />
        <DateField
          name="benefitOutcome.effectiveEndDate"
          noIcon
          disabled={!editable}
        />
      </FormRow>
      <OverlappingBenefitOutcomes
        overlappingBenefitOutcomes={overlappingBenefitOutcomes}
      />
      <FormRow variant="twoColumn_1_1_true">
        <TextField
          name="benefitOutcome.insuranceName"
          noIcon
          disabled={!editable}
        />
        <TextField name="benefitOutcome.policyId" noIcon disabled={!editable} />
      </FormRow>
      <SelectField
        name="benefitOutcome.applicationId"
        options={getApplicationSelectOptions({application, t})}
        disabled={!!application || !editable}
        noIcon
      />
      <MultilineTextField
        name={'benefitOutcome.notes'}
        noIcon
        disabled={!editable}
      />
    </>
  )
)

export default BenefitOutcomeFormDialog
