import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addMemoBoundary,
  addProps,
  addHandlers,
  addDefaultProps,
} from 'ad-hok'
import cx from 'classnames'

import {addFormContext} from 'utils/form/context'
import {addTranslationHelpers} from 'utils/i18n'
import {addClasses, makeClasses} from 'theme'
import ListItem from 'components/ListItem'
import Subtitle2 from 'components/Subtitle2'
import FormFeedbackIcon from 'components/FormFeedbackIcon'

const classes = makeClasses((_theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

interface Props {
  name: string
  shouldFlagNecessary?: boolean
  renderLabel?: (label: string) => JSX.Element
  className?: string
  onClick?: () => void
}

const FormSectionLinkManual: FC<Props> = flowMax(
  addDisplayName('FormSectionLinkManual'),
  addFormContext,
  addMemoBoundary(['name', 'shouldFlagNecessary', 'formName', 'className']),
  addTranslationHelpers,
  addProps(({formName, name, t}) => ({
    label: t(`${formName}.sectionLabels.${name}`),
  })),
  addHandlers({
    onClick: ({scrollToFormSection, name, onClick}) => () => {
      if (onClick) {
        onClick()
      } else {
        scrollToFormSection(name)
      }
    },
  }),
  addDefaultProps({
    renderLabel: (label: string) => <Subtitle2>{label}</Subtitle2>,
  }),
  addClasses(classes),
  ({
    onClick,
    label,
    shouldFlagNecessary,
    name,
    renderLabel,
    className,
    classes,
  }) => (
    <ListItem
      button
      onClick={onClick}
      className={cx(classes.container, className)}
      data-testid={`${name}-section-link`}
    >
      {renderLabel(label)}
      {shouldFlagNecessary && <FormFeedbackIcon status="necessary" />}
    </ListItem>
  )
)

export default FormSectionLinkManual
