import React from 'react'
import {flowMax, addDisplayName, addState, addHandlers, addProps} from 'ad-hok'
import {FC} from 'react'

import {
  addCreateAccountAndPersonMutation,
  addWebformsQuery,
} from 'graphql/generated'
import {addClasses, makeClasses} from 'theme'
import Form, {formColumnWidth} from 'components/Form'
import FormSection from 'components/FormSection'
import FieldSet from 'components/FieldSet'
import TextField from 'components/TextField'
import MultilineTextField from 'components/MultilineTextField'
import TimeField from 'components/TimeField'
import DateField from 'components/DateField'
import SubmitButton from 'components/SubmitButton'
import {addNavigate} from 'utils/routing'
import SelectField from 'components/SelectField'
import FormRow from 'components/FormRow'
import PhoneFieldArray from 'components/PhoneFieldArray'
import PersonMatcher from 'components/PersonMatcher'
import {
  getCreateAccountAndPatientFormSchema,
  staticCreateAccountAndPatientFormSchema,
} from 'components/CreateAccountAndPatientForm/schema'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import {addTranslationHelpers} from 'utils/i18n'
import {getCanonicalValues, getInitialValues} from 'utils/form/getValues'
import {DEFAULT_ACCOUNT_STATUS} from 'utils/accountStatuses'
import {
  FormCanonicalValues,
  FormSchemaFields,
  FormSchema,
} from 'utils/form/schema'
import addApolloClient from 'utils/addApolloClient'
import OptionalBooleanField from 'components/OptionalBooleanField'
import {Assert, SchemaDoesntHaveExtraFields} from 'utils/form/typeHelpers'
import {addPhoneNumberOrIncomeSourceEsignSessionUrlsProviding} from 'components/EditPersonForm/phoneNumberEsignSessionUrlsContext'
import {addLoadingIndicator} from 'utils/dataLoading'
import {addWebformsContextProvider} from 'components/EditPersonForm/webformsContext'
import {addFacilities, addDepartments} from 'utils/configContext'

const classes = makeClasses((theme) => ({
  container: {
    width: formColumnWidth,
    textAlign: 'left',
    alignSelf: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  matchesFieldContainer: {
    position: 'relative',
  },
}))

const computeInitialValues = <TFields extends FormSchemaFields>(
  schema: FormSchema<TFields>
): FormCanonicalValues<TFields> => {
  const initialValues = getCanonicalValues(getInitialValues(schema), schema)
  return {
    ...initialValues,
    account: {
      ...initialValues.account,
      status: DEFAULT_ACCOUNT_STATUS,
      njmmisCheck: 'Not checked',
    },
  }
}

type Check = Assert<
  SchemaDoesntHaveExtraFields<
    typeof staticCreateAccountAndPatientFormSchema,
    typeof addCreateAccountAndPersonMutation
  >
>

const CreateAccountAndPatientForm: FC = flowMax(
  addDisplayName('CreateAccountAndPatientForm'),
  addWebformsQuery({}),
  addLoadingIndicator({}),
  addWebformsContextProvider,
  addNavigate,
  addAppSnackbarContext,
  addState('isPersonLinked', 'setIsPersonLinked', false),
  addTranslationHelpers,
  addCreateAccountAndPersonMutation({}),
  addHandlers({
    onCreateSuccess: ({
      navigate,
      showSnackbarMessage,
      isPersonLinked,
      t,
    }) => () => {
      showSnackbarMessage(
        isPersonLinked
          ? t('journalingForm.savedExistingPerson')
          : t('journalingForm.savedNewPerson')
      )
      navigate('/')
    },
  }),
  addFacilities,
  addDepartments,
  addApolloClient,
  addProps(
    ({facilities, departments, apolloClient}) => ({
      schema: getCreateAccountAndPatientFormSchema({
        facilities,
        departments,
        apolloClient,
      }),
    }),
    ['facilities', 'departments', 'apolloClient']
  ),
  addProps(
    ({schema}) => ({
      initialValues: computeInitialValues(schema),
    }),
    ['schema']
  ),
  addPhoneNumberOrIncomeSourceEsignSessionUrlsProviding,
  addClasses(classes),
  ({
    classes,
    mutateCreateAccountAndPerson,
    setIsPersonLinked,
    onCreateSuccess,
    initialValues,
    schema,
  }) => (
    <Form
      name="journalingForm"
      mutate={mutateCreateAccountAndPerson}
      className={classes.container}
      schema={schema}
      onSubmitSuccess={onCreateSuccess}
      resetOnSaveSuccess
      initialValues={initialValues}
    >
      <FormSection labelTranslationKey="accountData">
        <FieldSet labelTranslationKey="basicInformation">
          <TextField name="account.hospitalAccountId" />
          <DateField name="account.dateOfService" />
          <FormRow variant="twoColumn_2_1">
            <TimeField name="account.timeOfService" noIcon />
            <SelectField name="account.serviceTimeZone" />
          </FormRow>
          <OptionalBooleanField name="account.isScheduled" />
          <SelectField name="account.facility" />
          <SelectField name="account.department" />
          <TextField name="account.location" />
          <SelectField name="account.insuranceType" />
          <SelectField name="account.njmmisCheck" />
          <TextField name="account.previousInsurance" />
        </FieldSet>
      </FormSection>
      <FormSection labelTranslationKey="clientData">
        <div className={classes.matchesFieldContainer}>
          <TextField name="person.hospitalPatientId" />
          <PersonMatcher setIsPersonLinked={setIsPersonLinked} />
        </div>
        <TextField name="person.firstName" />
        <TextField name="person.middleName" />
        <TextField name="person.lastName" />
        <TextField name="person.suffix" />
        <DateField name="person.dob" />
        <SelectField name="person.gender" />
        <TextField name="person.ssn" />
        <PhoneFieldArray name="person.phoneNumbers" />
        <TextField name="person.homeAddressStreet" />
        <FormRow variant="twoColumn_2_1">
          <TextField name="person.homeAddressCity" noIcon />
          <SelectField name="person.homeAddressState" />
        </FormRow>
        <TextField name="person.homeAddressZip" />
        <MultilineTextField name="person.homeAddressComment" />
        <TextField name="person.mailingAddressStreet" />
        <FormRow variant="twoColumn_2_1">
          <TextField name="person.mailingAddressCity" noIcon />
          <SelectField name="person.mailingAddressState" />
        </FormRow>
        <TextField name="person.mailingAddressZip" />
        <MultilineTextField name="person.mailingAddressComment" />
        <TextField name="person.email" />
        <MultilineTextField name="person.emailComment" />
        <SelectField name="person.preferredLanguage" />
      </FormSection>
      <SubmitButton />
    </Form>
  )
)

export default CreateAccountAndPatientForm
