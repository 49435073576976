/* eslint-disable */
import {MarkWebformMergeAttemptReviewedVariables as MarkWebformMergeAttemptReviewedVariablesOrig} from '../types/MarkWebformMergeAttemptReviewed'
import {MarkWebformMergeAttemptReviewed as MarkWebformMergeAttemptReviewedOrig} from '../types/MarkWebformMergeAttemptReviewed'
import {WebformMergeResult} from 'utils/form/getWebformMergeResult'
import {parseWebformMergeResult} from 'utils/form/getWebformMergeResult'
import {serializeWebformMergeResult} from 'utils/form/getWebformMergeResult'
import {MarkWebformMergeAttemptReviewed_markWebformMergeAttemptReviewed as MarkWebformMergeAttemptReviewed_markWebformMergeAttemptReviewedOrig} from '../types/MarkWebformMergeAttemptReviewed'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL mutation operation: MarkWebformMergeAttemptReviewed
// ====================================================
export interface MarkWebformMergeAttemptReviewed_markWebformMergeAttemptReviewed {
  __typename: 'WebformMergeAttempt'
  id: string
  reviewed: boolean
  mergeResult: WebformMergeResult
}
export const deserializeMarkWebformMergeAttemptReviewed_markWebformMergeAttemptReviewed = (
  serialized: MarkWebformMergeAttemptReviewed_markWebformMergeAttemptReviewedOrig
): MarkWebformMergeAttemptReviewed_markWebformMergeAttemptReviewed => ({
  ...serialized,
  mergeResult: parseWebformMergeResult(serialized.mergeResult),
})
export const serializeMarkWebformMergeAttemptReviewed_markWebformMergeAttemptReviewed = (
  deserialized: MarkWebformMergeAttemptReviewed_markWebformMergeAttemptReviewed
): MarkWebformMergeAttemptReviewed_markWebformMergeAttemptReviewedOrig => ({
  ...deserialized,
  mergeResult: serializeWebformMergeResult(deserialized.mergeResult),
})
export interface MarkWebformMergeAttemptReviewed {
  markWebformMergeAttemptReviewed: MarkWebformMergeAttemptReviewed_markWebformMergeAttemptReviewed
}
export const deserializeMarkWebformMergeAttemptReviewed = (
  serialized: MarkWebformMergeAttemptReviewedOrig
): MarkWebformMergeAttemptReviewed => ({
  ...serialized,
  markWebformMergeAttemptReviewed: deserializeMarkWebformMergeAttemptReviewed_markWebformMergeAttemptReviewed(
    serialized.markWebformMergeAttemptReviewed
  ),
})
export const serializeMarkWebformMergeAttemptReviewed = (
  deserialized: MarkWebformMergeAttemptReviewed
): MarkWebformMergeAttemptReviewedOrig => ({
  ...deserialized,
  markWebformMergeAttemptReviewed: serializeMarkWebformMergeAttemptReviewed_markWebformMergeAttemptReviewed(
    deserialized.markWebformMergeAttemptReviewed
  ),
})
export interface MarkWebformMergeAttemptReviewedVariables {
  webformMergeAttemptId: string
}
export const deserializeMarkWebformMergeAttemptReviewedVariables = (
  serialized: MarkWebformMergeAttemptReviewedVariablesOrig
): MarkWebformMergeAttemptReviewedVariables => ({...serialized})
export const serializeMarkWebformMergeAttemptReviewedVariables = (
  deserialized: MarkWebformMergeAttemptReviewedVariables
): MarkWebformMergeAttemptReviewedVariablesOrig => ({...deserialized})
