import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addWrapper,
  branch,
  returns,
  addProps,
  addHandlers,
} from 'ad-hok'
import {generatePath} from 'react-router'
import {format} from 'date-fns/fp'
import {branchIfNullish} from 'ad-hok-utils'
import cx from 'classnames'

import FormSection from 'components/FormSection'
import {addApplicationFormContext} from 'components/EditApplicationForm/applicationFormContext'
import {addTranslationHelpers} from 'utils/i18n'
import Body1 from 'components/Body1'
import {addPersonQuery, addUpdateEsignSessionMutation} from 'graphql/generated'
import {addLoadingIndicator} from 'utils/dataLoading'
import {householdSectionForLink} from 'components/EditApplicationForm/ApplicationFormLeftColumn'
import Trans from 'components/Trans'
import Link from 'components/Link'
import {personDetailDefaultPath} from 'components/TopLevelRoutes'
import {getExtendedName} from 'utils/name'
import {makeClasses, addClasses} from 'theme'
import {toEasternTime} from 'utils/date'
import Caption from 'components/Caption'
import {
  Application_application_MedicaidApplication_householdMembers_person_phoneNumbers,
  Application_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession,
  Application_application_MedicaidApplication_householdMembers_person_incomeSources,
} from 'graphql/deserializedTypes/Application'
import {getFormattedPhoneNumber} from 'utils/phone'
import InfoTooltip from 'components/InfoTooltip'
import EsignSessionIcon from 'components/EsignSessionIcon'
import CheckmarkToggle from 'components/CheckmarkToggle'
import {addConfigContext, addShowEsignReview} from 'utils/configContext'
import addDialogState from 'utils/addDialogState'
import Button from 'components/Button'
import CreateLighticoWorkflowsDialog from 'components/CreateLighticoWorkflowsDialog'
import {
  getHouseholdMembersWithRelationshipTypes,
  HouseholdMemberWithRelationshipType,
} from 'utils/householdMembers'

const classes = makeClasses((theme) => ({
  lastSaved: {
    color: '#979797',
  },
  phoneNumberRowContainer: {
    height: 38,
    whiteSpace: 'nowrap',
  },
  phoneNumbersContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(-1),
  },
  phoneNumberInfo: {
    position: 'relative',
    top: 6,
    left: 4,
  },
  phoneNumberEsignIcon: {
    position: 'relative',
    top: -2,
  },
  esignSessionReviewedUnreviewedIcon: {
    position: 'relative',
    top: -2,
  },
  esignSessionUnreviewedIcon: {
    color: '#bdbdbd',
  },
  esignSessionReviewedIcon: {
    color: '#219653',
  },
  esignSessionReviewedUnreviewed: {
    fontWeight: 500,
  },
  esignSessionUnreviewed: {
    color: '#ff0000',
  },
}))

interface EsignSessionWizardsCompletedProps {
  esignSession: Application_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession
}

const EsignSessionWizardsCompleted: FC<EsignSessionWizardsCompletedProps> = flowMax(
  addDisplayName('EsignSessionWizardsCompleted'),
  addProps(({esignSession: {esignWizards}}) => ({
    numTotal: esignWizards.length,
    numCompleted: esignWizards.filter(({completedAt}) => !!completedAt).length,
  })),
  ({numCompleted, numTotal}) => (
    <span>
      {numCompleted}/{numTotal}
    </span>
  )
)

interface EsignSessionWizardsReviewedProps {
  esignSession: Application_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession
}

const EsignSessionWizardsReviewed: FC<EsignSessionWizardsReviewedProps> = flowMax(
  addDisplayName('EsignSessionWizardsReviewed'),
  addUpdateEsignSessionMutation({}),
  addHandlers({
    onClick: ({mutateUpdateEsignSession, esignSession}) => () => {
      mutateUpdateEsignSession({
        variables: {
          esignSession: {
            id: esignSession.id,
            unreviewed: !esignSession.unreviewed,
          },
        },
        optimisticResponse: {
          updateEsignSession: {
            __typename: 'EsignSession',
            id: esignSession.id,
            unreviewed: !esignSession.unreviewed,
          },
        },
      })
    },
  }),
  addTranslationHelpers,
  addProps(({esignSession: {unreviewed}, t}) => ({
    label: unreviewed
      ? t('applicationForm.esignWizards.unreviewed')
      : t('applicationForm.esignWizards.reviewed'),
  })),
  addClasses(classes),
  ({onClick, label, esignSession: {unreviewed}, classes}) => (
    <>
      <CheckmarkToggle
        onToggle={onClick}
        isSelected={!unreviewed}
        className={classes.esignSessionReviewedUnreviewedIcon}
      />
      <span
        className={cx(
          classes.esignSessionReviewedUnreviewed,
          unreviewed && classes.esignSessionUnreviewed
        )}
      >
        {label}
      </span>
    </>
  )
)

interface EsignSessionWizardsProps {
  esignSession: Application_application_MedicaidApplication_householdMembers_person_phoneNumbers_esignSession | null
}

const EsignSessionWizards: FC<EsignSessionWizardsProps> = flowMax(
  addDisplayName('EsignSessionWizards'),
  branchIfNullish('esignSession'),
  addShowEsignReview,
  ({esignSession, showEsignReview}) => (
    <>
      <EsignSessionWizardsCompleted esignSession={esignSession} />
      {showEsignReview && (
        <EsignSessionWizardsReviewed esignSession={esignSession} />
      )}
    </>
  )
)

interface PhoneNumberUsedForEsignProps {
  phoneNumber: Application_application_MedicaidApplication_householdMembers_person_phoneNumbers
}

const PhoneNumberUsedForEsign: FC<PhoneNumberUsedForEsignProps> = flowMax(
  addDisplayName('PhoneNumberUsedForEsign'),
  addDialogState,
  addConfigContext,
  addClasses(classes),
  addTranslationHelpers,
  ({
    phoneNumber: {number, comment, esignSessionId},
    phoneNumber,
    config: {lighticoWorkflows},
    isShowingDialog,
    showDialog,
    hideDialog,
    classes,
    t,
  }) => (
    <div>
      <Body1 className={classes.phoneNumberRowContainer}>
        <span>{getFormattedPhoneNumber(number)}</span>
        <InfoTooltip info={comment} className={classes.phoneNumberInfo} />
        <EsignSessionIcon
          phoneNumberOrIncomeSource={phoneNumber}
          className={classes.phoneNumberEsignIcon}
        />
        <EsignSessionWizards esignSession={phoneNumber.esignSession} />
      </Body1>
      {lighticoWorkflows.length > 0 && (
        <Button onClick={showDialog} variant="contained" color="primary">
          {t('applicationForm.createLighticoWorkflowDialog.open')}
        </Button>
      )}
      <CreateLighticoWorkflowsDialog
        esignSessionId={esignSessionId!}
        open={isShowingDialog}
        onClose={hideDialog}
      />
    </div>
  )
)

interface IncomeSourceUsedForEsignProps {
  incomeSource: Application_application_MedicaidApplication_householdMembers_person_incomeSources
}

const IncomeSourceUsedForEsign: FC<IncomeSourceUsedForEsignProps> = flowMax(
  addDisplayName('IncomeSourceUsedForEsign'),
  addProps(({incomeSource: {employerPhone}}) => ({
    employerPhone,
  })),
  branchIfNullish('employerPhone'),
  addClasses(classes),
  addTranslationHelpers,
  ({employerPhone, incomeSource, classes, t}) => (
    <Body1 className={classes.phoneNumberRowContainer}>
      <span>
        {getFormattedPhoneNumber(employerPhone)} {t('applicationForm.employer')}
      </span>
      <EsignSessionIcon
        phoneNumberOrIncomeSource={incomeSource}
        className={classes.phoneNumberEsignIcon}
      />
      <EsignSessionWizards esignSession={incomeSource.esignSession} />
    </Body1>
  )
)

interface HouseholdMemberProps {
  householdMember: HouseholdMemberWithRelationshipType
}

const HouseholdMember: FC<HouseholdMemberProps> = flowMax(
  addDisplayName('HouseholdMember'),
  addApplicationFormContext,
  addProps(
    ({application}) => ({
      isMedicaidApplication: application.benefit === 'medicaid',
    }),
    ['application']
  ),
  addProps(
    ({
      householdMember: {
        person: {phoneNumbers, incomeSources},
      },
    }) => ({
      phoneNumbersUsedForEsign: phoneNumbers.filter(
        ({usedForEsign}) => usedForEsign
      ),
      incomeSourcesUsedForEsign: incomeSources.filter(
        ({usedForEsign}) => usedForEsign
      ),
    }),
    ['householdMember.person']
  ),
  addTranslationHelpers,
  addClasses(classes),
  ({
    householdMember: {relationshipType, wantsCoverage, person},
    phoneNumbersUsedForEsign,
    incomeSourcesUsedForEsign,
    isMedicaidApplication,
    classes,
    t,
  }) => (
    <div>
      <Body1>
        <Trans i18nKey="applicationForm.householdMember">
          <Link
            highlight
            to={generatePath(personDetailDefaultPath, {id: person.id})}
          />
          {{name: getExtendedName({...person, t})}}
          {{
            relationshipType:
              relationshipType === undefined
                ? t(
                    'applicationForm.householdMemberRelationshipTypeRemovedFromProfile'
                  )
                : relationshipType ?? t('general.unknown'),
          }}
          {{
            wantsCoverage:
              isMedicaidApplication && wantsCoverage
                ? ` (${t('applicationForm.wantsCoverage')})`
                : '',
          }}
        </Trans>
      </Body1>
      {!!(
        phoneNumbersUsedForEsign.length + incomeSourcesUsedForEsign.length
      ) && (
        <div className={classes.phoneNumbersContainer}>
          {phoneNumbersUsedForEsign.map((phoneNumber, index) => (
            <PhoneNumberUsedForEsign phoneNumber={phoneNumber} key={index} />
          ))}
          {incomeSourcesUsedForEsign.map((incomeSource, index) => (
            <IncomeSourceUsedForEsign incomeSource={incomeSource} key={index} />
          ))}
        </div>
      )}
    </div>
  )
)

const HouseholdSection: FC = flowMax(
  addDisplayName('HouseholdSection'),
  addWrapper((render) => (
    <FormSection labelTranslationKey={householdSectionForLink.name}>
      {render()}
    </FormSection>
  )),
  addApplicationFormContext,
  addTranslationHelpers,
  branch(
    ({application: {householdMembers}}) => !householdMembers.length,
    returns(({t}) => <Body1>{t('applicationForm.noHousehold')}</Body1>)
  ),
  addPersonQuery({
    variables: ({
      application: {
        person: {id},
      },
    }) => ({id}),
  }),
  addLoadingIndicator({}),
  addProps(
    ({application, person}) => ({
      householdMembersWithRelationshipTypes: getHouseholdMembersWithRelationshipTypes(
        {application, person}
      ),
    }),
    ['application', 'person']
  ),
  addClasses(classes),
  ({
    householdMembersWithRelationshipTypes,
    householdMembersWithRelationshipTypes: {
      0: {createdAt},
    },
    classes,
    t,
  }) => (
    <>
      <Caption className={classes.lastSaved}>
        {t('applicationForm.householdLastSaved', {
          date: `${format('M/d/yyyy H:mm')(toEasternTime(createdAt))}ET`,
        })}
      </Caption>
      {householdMembersWithRelationshipTypes.map(
        (householdMemberWithRelationshipType) => (
          <HouseholdMember
            householdMember={householdMemberWithRelationshipType}
            key={householdMemberWithRelationshipType.id}
          />
        )
      )}
    </>
  )
)

export default HouseholdSection
