import React from 'react'
import {TextField} from 'formik-material-ui'
import {FC} from 'react'
import BaseTextField from '@material-ui/core/TextField'

import Field from 'components/Field'
import {FieldComponentProps} from 'components/TextField'

const DateField: FC<FieldComponentProps> = (props) => (
  <Field
    component={TextField}
    type="date"
    InputLabelProps={{shrink: true}}
    exampleValue={'' as string}
    {...props}
  />
)

export default DateField

interface StandaloneDateFieldProps {
  label: string
  value: string
  onChange: (event: React.ChangeEvent<{value: string}>) => void
  className?: string
  disabled?: boolean
}

export const StandaloneDateField: FC<StandaloneDateFieldProps> = ({
  className,
  ...props
}) => (
  <BaseTextField
    type="date"
    variant="outlined"
    InputLabelProps={{shrink: true}}
    className={className}
    {...props}
  />
)
