import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import {addClasses, makeClasses} from 'theme'
import IconButton from 'components/IconButton'

const classes = makeClasses((_theme) => ({
  unselected: {
    color: '#bdbdbd',
  },
  selected: {
    color: '#219653',
  },
}))

interface Props {
  isSelected: boolean
  onToggle: () => void
  className?: string
}

const CheckmarkToggle: FC<Props> = flowMax(
  addDisplayName('CheckmarkToggle'),
  addClasses(classes),
  addProps(
    ({isSelected, classes}) => ({
      icon: isSelected ? (
        <CheckCircleIcon className={classes.selected} />
      ) : (
        <CheckCircleOutlineIcon className={classes.unselected} />
      ),
    }),
    ['isSelected', 'classes']
  ),
  ({icon, onToggle, className}) => (
    <IconButton onClick={onToggle} className={className}>
      {icon}
    </IconButton>
  )
)

export default CheckmarkToggle
