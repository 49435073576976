import {round} from 'lodash'

export const formatCurrency = (
  amount: number,
  {
    noCents,
    noCentsIfZero,
  }: {
    noCents?: boolean
    noCentsIfZero?: boolean
  } = {}
) => {
  let formatted = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: noCents ? 0 : 2,
  }).format(noCents ? round(amount) : amount)
  if (noCentsIfZero) formatted = formatted.replace(/\.00$/, '')
  return formatted
}
