import React from 'react'
import {TextField} from 'formik-material-ui'
import {FC} from 'react'

import Field from 'components/Field'
import {FieldComponentProps} from 'components/TextField'

const TimeField: FC<FieldComponentProps> = (props) => (
  <Field
    component={TextField}
    type="text"
    exampleValue={'' as string}
    {...props}
  />
)

export default TimeField
