import React from 'react'
import {FC} from 'react'
import {flowMax, addDisplayName} from 'ad-hok'

import SelectField, {SelectFieldProps} from 'components/SelectField'

const MultiSelectField: FC<SelectFieldProps> = flowMax(
  addDisplayName('MultiSelectField'),
  (props) => <SelectField {...props} multiple />
)

export default MultiSelectField
