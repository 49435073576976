/* eslint-disable */
import {Audits as AuditsOrig} from '../types/Audits'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {Audits_audits as Audits_auditsOrig} from '../types/Audits'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: Audits
// ====================================================
export interface Audits_audits {
  __typename: 'Audit'
  id: string
  name: string
  fileCounter: number
  createdAt: Date
}
export const deserializeAudits_audits = (
  serialized: Audits_auditsOrig
): Audits_audits => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeAudits_audits = (
  deserialized: Audits_audits
): Audits_auditsOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface Audits {
  /**
   * List of file audits
   */
  audits: Audits_audits[]
}
export const deserializeAudits = (serialized: AuditsOrig): Audits => ({
  ...serialized,
  audits: serialized.audits.map(deserializeAudits_audits),
})
export const serializeAudits = (deserialized: Audits): AuditsOrig => ({
  ...deserialized,
  audits: deserialized.audits.map(serializeAudits_audits),
})
