import {getContextHelpers, toObjectKeys} from 'ad-hok-utils'
import {fromPairs} from 'lodash/fp'
import {SimplePropsAdder, flowMax, addStateHandlers} from 'ad-hok'

import typedAs from 'utils/typedAs'

export interface PhoneNumberOrIncomeSourceEsignSessionUrls {
  [phoneNumberOrIncomeSourceId: string]: string | null
}

const [
  addPhoneNumberOrIncomeSourceEsignSessionUrlsContextProvider,
  addPhoneNumberOrIncomeSourceEsignSessionUrlsContext,
] = getContextHelpers<{
  phoneNumberOrIncomeSourceEsignSessionUrls: PhoneNumberOrIncomeSourceEsignSessionUrls
  updatePhoneNumberOrIncomeSourceEsignSessionUrls: (
    phoneNumberOrIncomeSourceEsignSessionUrls: PhoneNumberOrIncomeSourceEsignSessionUrls
  ) => void
}>(
  toObjectKeys([
    'phoneNumberOrIncomeSourceEsignSessionUrls',
    'updatePhoneNumberOrIncomeSourceEsignSessionUrls',
  ])
)

export {
  addPhoneNumberOrIncomeSourceEsignSessionUrlsContextProvider,
  addPhoneNumberOrIncomeSourceEsignSessionUrlsContext,
}

interface PhoneNumberOrIncomeSource {
  id: string | null
  esignSessionUrl: string | null
}

interface PersonWithPhoneNumbersAndIncomeSources {
  phoneNumbers: PhoneNumberOrIncomeSource[]
  incomeSources: PhoneNumberOrIncomeSource[]
}

const getIsPhoneNumberOrIncomeSourceWithId = (
  phoneNumberOrIncomeSource: PhoneNumberOrIncomeSource
): phoneNumberOrIncomeSource is PhoneNumberOrIncomeSource & {
  id: string
} => !!phoneNumberOrIncomeSource.id

export const getPhoneNumberOrIncomeSourceEsignSessionUrls = (
  person: PersonWithPhoneNumbersAndIncomeSources & {
    relationships?: {
      otherPerson: PersonWithPhoneNumbersAndIncomeSources
    }[]
  }
): PhoneNumberOrIncomeSourceEsignSessionUrls => ({
  ...fromPairs(
    [
      person,
      ...(person.relationships?.map(({otherPerson}) => otherPerson) ?? []),
    ]
      .flatMap(({phoneNumbers}) => phoneNumbers)
      .filter(getIsPhoneNumberOrIncomeSourceWithId)
      .map(({id, esignSessionUrl}) => [`phoneNumber-${id}`, esignSessionUrl])
  ),
  ...fromPairs(
    [
      person,
      ...(person.relationships?.map(({otherPerson}) => otherPerson) ?? []),
    ]
      .flatMap(({incomeSources}) => incomeSources)
      .filter(getIsPhoneNumberOrIncomeSourceWithId)
      .map(({id, esignSessionUrl}) => [`incomeSource-${id}`, esignSessionUrl])
  ),
})

type AddPhoneNumberOrIncomeSourceEsignSessionUrlsProviding = SimplePropsAdder<{
  updatePhoneNumberOrIncomeSourceEsignSessionUrls: (
    sessionUrls: PhoneNumberOrIncomeSourceEsignSessionUrls
  ) => void
}>

export const addPhoneNumberOrIncomeSourceEsignSessionUrlsProviding: AddPhoneNumberOrIncomeSourceEsignSessionUrlsProviding = flowMax(
  addStateHandlers(
    {
      phoneNumberOrIncomeSourceEsignSessionUrls: typedAs<
        PhoneNumberOrIncomeSourceEsignSessionUrls
      >({}),
    },
    {
      updatePhoneNumberOrIncomeSourceEsignSessionUrls: ({
        phoneNumberOrIncomeSourceEsignSessionUrls,
      }) => (
        updatedPhoneNumberOrIncomeSourceEsignSessionUrls: PhoneNumberOrIncomeSourceEsignSessionUrls
      ) => ({
        phoneNumberOrIncomeSourceEsignSessionUrls: {
          ...phoneNumberOrIncomeSourceEsignSessionUrls,
          ...updatedPhoneNumberOrIncomeSourceEsignSessionUrls,
        },
      }),
    }
  ),
  addPhoneNumberOrIncomeSourceEsignSessionUrlsContextProvider
)
