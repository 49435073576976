import React, {FC} from 'react'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import ErrorIcon from '@material-ui/icons/Error'
import {
  flowMax,
  addDisplayName,
  addProps,
  addEffect,
  addMemoBoundary,
} from 'ad-hok'
import {branchIfNullish} from 'ad-hok-utils'

import {
  FormFieldStatus,
  FormFieldStatuses,
  addFormContext,
  addFormFieldStatusesContext,
} from 'utils/form/context'
import {addFormSectionContext} from 'components/FormSection'

const icons = {
  necessary: RemoveCircleIcon,
  invalid: ErrorIcon,
}

const colors = {
  necessary: '#f2994a',
  invalid: '#b00020',
}

const getIcon = (
  status: NonNullable<FormFieldStatus>
): typeof RemoveCircleIcon => icons[status]

const getColor = (status: NonNullable<FormFieldStatus>): string =>
  colors[status]

const getStatusByPrefix = (prefix: string) => (
  statuses: FormFieldStatuses
): FormFieldStatus => {
  if (
    Object.entries(statuses).find(
      ([name, status]) => status === 'invalid' && name.startsWith(prefix)
    )
  )
    return 'invalid'
  if (
    Object.entries(statuses).find(
      ([name, status]) => status === 'necessary' && name.startsWith(prefix)
    )
  )
    return 'necessary'
  return undefined
}

export const getFormFeedbackIconStatus = ({
  formFieldStatuses,
  name,
  prefix,
}: {
  formFieldStatuses: FormFieldStatuses
  name?: string | undefined
  prefix?: string
}): FormFieldStatus =>
  (name
    ? formFieldStatuses[name]
    : prefix
    ? getStatusByPrefix(prefix)(formFieldStatuses)
    : undefined) as FormFieldStatus

interface Props {
  name?: string
  prefix?: string
  status?: FormFieldStatus
  nameForTestId?: string
  className?: string
  shouldRegisterToFormSection?: boolean
}

const FormFeedbackIcon: FC<Props> = flowMax(
  addDisplayName('FormFeedbackIcon'),
  addFormFieldStatusesContext,
  addFormContext,
  addProps(
    ({formFieldStatuses, name, prefix, status}) => ({
      status:
        status ?? getFormFeedbackIconStatus({formFieldStatuses, name, prefix}),
    }),
    ['formFieldStatuses', 'name', 'prefix', 'status']
  ),
  addFormSectionContext,
  addMemoBoundary([
    'status',
    'name',
    'prefix',
    'shouldRegisterToFormSection',
    'formSectionName',
    'formName',
    'nameForTestId',
    'className',
  ]),
  // eslint-disable-next-line ad-hok/dependencies
  addEffect(
    ({
      shouldRegisterToFormSection,
      formSectionName,
      registerFieldToFormSection,
      status,
      name,
      prefix,
    }) => () => {
      if (!shouldRegisterToFormSection) return
      if (!formSectionName) return
      if (!(name || prefix)) return
      registerFieldToFormSection({
        fieldName: (name || prefix)!,
        sectionName: formSectionName,
        status,
      })
    },
    ['status']
  ),
  branchIfNullish('status'),
  addProps(
    ({status}) => ({
      Icon: getIcon(status),
      color: getColor(status),
    }),
    ['status']
  ),
  addProps(
    ({formName, name, status, nameForTestId}) => ({
      testId: `${formName}-${nameForTestId ?? name}-${status}`,
    }),
    ['formName', 'name', 'status', 'nameForTestId']
  ),
  ({testId, Icon, color, className}) => (
    <Icon style={{color}} className={className} data-testid={testId} />
  )
)

export default FormFeedbackIcon
