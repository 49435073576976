import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'

import {getShortDate} from 'utils/date'
import LabeledDisplayItem from 'components/LabeledDisplayItem'

interface Props {
  label: string
  date: Date | null
  className?: string
}

const DisplayShortDate: FC<Props> = flowMax(
  addDisplayName('DisplayShortDate'),
  addProps(
    ({date}) => ({
      value: date && getShortDate(date),
    }),
    ['date']
  ),
  ({label, value, className}) => (
    <LabeledDisplayItem label={label} value={value} className={className} />
  )
)

export default DisplayShortDate
