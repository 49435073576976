import {CurriedPropsAdder, flowMax, addStateHandlers, addEffect} from 'ad-hok'
import {addExtendedHandlers} from 'ad-hok-utils'
import {delay} from 'lodash/fp'

type AddHasSavedType = <
  THandlerName extends string,
  TProps extends {
    [handlerName in THandlerName]: Function
  } & {
    open: boolean
  }
>(
  handlerName: THandlerName
) => CurriedPropsAdder<
  TProps,
  {
    hasSaved: boolean
    clearHasSaved: () => void
  }
>

const addHasSaved: AddHasSavedType = (handlerName) =>
  flowMax(
    addStateHandlers(
      {
        hasSaved: false,
      },
      {
        markSaved: () => () => ({
          hasSaved: true,
        }),
        clearHasSaved: () => () => ({
          hasSaved: false,
        }),
      }
    ),
    addExtendedHandlers({
      [handlerName]: ({markSaved}) => () => {
        markSaved()
      },
    }),
    // eslint-disable-next-line ad-hok/dependencies
    addEffect(
      ({open, clearHasSaved}) => () => {
        if (open) return

        delay(200, clearHasSaved)
      },
      ['open']
    )
  )

export default addHasSaved
