import {TFunction} from 'i18next'
import {
  upperFirst,
  flow,
  replace,
  lowerCase,
  snakeCase,
  startCase,
} from 'lodash/fp'

type UnknownVariant = 'lowercaseSquareBrackets' | 'capitalizedAngleBrackets'

const UNKNOWN_VARIANT_TRANSLATION_KEYS: Record<UnknownVariant, string> = {
  lowercaseSquareBrackets: 'general.unknown',
  capitalizedAngleBrackets: 'general.unknownCapitalizedAngleBrackets',
}

export const orUnknown = (
  t: TFunction,
  variant: UnknownVariant = 'lowercaseSquareBrackets'
) => (value: string | null | undefined): string =>
  value || t(UNKNOWN_VARIANT_TRANSLATION_KEYS[variant])

type SeparableValues = string | undefined | null
export const getDelimiterSeparated = (delimiter: string) => (
  values: SeparableValues[]
) => values.filter((v) => v).join(delimiter)

export const getCommaSeparated = getDelimiterSeparated(', ')

export const snakeCaseToSentenceCase = flow(
  replace(/_/g, ' '),
  lowerCase,
  upperFirst
)

export const sentenceCaseToSnakeCase = snakeCase

export const camelCaseToSentenceCase = flow(startCase, lowerCase, upperFirst)

export const camelCaseToLowerCaseWords = flow(startCase, lowerCase)
