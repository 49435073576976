import {getContextHelpers, toObjectKeys} from 'ad-hok-utils'

import {Person_person} from 'graphql/deserializedTypes/Person'

interface PersonContextValue {
  person: Person_person
}

const [addPersonContextProvider, addPersonContext] = getContextHelpers<
  PersonContextValue
>(toObjectKeys(['person']))

export {addPersonContextProvider, addPersonContext}
