/* eslint-disable */
import {AuditZipfilesVariables as AuditZipfilesVariablesOrig} from '../types/AuditZipfiles'
import {AuditZipfiles as AuditZipfilesOrig} from '../types/AuditZipfiles'
import {AuditZipfiles_auditZipfiles as AuditZipfiles_auditZipfilesOrig} from '../types/AuditZipfiles'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {AuditZipfiles_auditZipfiles_documentFiles as AuditZipfiles_auditZipfiles_documentFilesOrig} from '../types/AuditZipfiles'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: AuditZipfiles
// ====================================================
export interface AuditZipfiles_auditZipfiles_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeAuditZipfiles_auditZipfiles_documentFiles = (
  serialized: AuditZipfiles_auditZipfiles_documentFilesOrig
): AuditZipfiles_auditZipfiles_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeAuditZipfiles_auditZipfiles_documentFiles = (
  deserialized: AuditZipfiles_auditZipfiles_documentFiles
): AuditZipfiles_auditZipfiles_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface AuditZipfiles_auditZipfiles {
  __typename: 'ZipFile'
  id: string
  zipCategory: string
  documentFiles: AuditZipfiles_auditZipfiles_documentFiles[]
}
export const deserializeAuditZipfiles_auditZipfiles = (
  serialized: AuditZipfiles_auditZipfilesOrig
): AuditZipfiles_auditZipfiles => ({
  ...serialized,
  documentFiles: serialized.documentFiles.map(
    deserializeAuditZipfiles_auditZipfiles_documentFiles
  ),
})
export const serializeAuditZipfiles_auditZipfiles = (
  deserialized: AuditZipfiles_auditZipfiles
): AuditZipfiles_auditZipfilesOrig => ({
  ...deserialized,
  documentFiles: deserialized.documentFiles.map(
    serializeAuditZipfiles_auditZipfiles_documentFiles
  ),
})
export interface AuditZipfiles {
  /**
   * List of all zip files generated for batch audit
   */
  auditZipfiles: AuditZipfiles_auditZipfiles[]
}
export const deserializeAuditZipfiles = (
  serialized: AuditZipfilesOrig
): AuditZipfiles => ({
  ...serialized,
  auditZipfiles: serialized.auditZipfiles.map(
    deserializeAuditZipfiles_auditZipfiles
  ),
})
export const serializeAuditZipfiles = (
  deserialized: AuditZipfiles
): AuditZipfilesOrig => ({
  ...deserialized,
  auditZipfiles: deserialized.auditZipfiles.map(
    serializeAuditZipfiles_auditZipfiles
  ),
})
export interface AuditZipfilesVariables {
  auditId?: string | null
}
export const deserializeAuditZipfilesVariables = (
  serialized: AuditZipfilesVariablesOrig
): AuditZipfilesVariables => ({...serialized})
export const serializeAuditZipfilesVariables = (
  deserialized: AuditZipfilesVariables
): AuditZipfilesVariablesOrig => ({...deserialized})
