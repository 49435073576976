import React, {FC} from 'react'
import {flowMax, addDisplayName} from 'ad-hok'
import {Theme} from '@material-ui/core'

import Grid from 'components/Grid'
import Caption from 'components/Caption'
import Chip from 'components/Chip'
import {makeClasses, addClasses} from 'theme'

const classes = makeClasses((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  label: {
    marginRight: theme.spacing(1),
  },
  chip: {
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}))

interface Props {
  labels: string[]
  label: string
}

const FilterChips: FC<Props> = flowMax(
  addDisplayName('FilterChips'),
  addClasses(classes),
  ({labels, label, classes}) => (
    <Grid
      container
      direction="row"
      alignItems="baseline"
      className={classes.container}
    >
      <Caption className={classes.label}>{label}</Caption>
      {labels.map((label) => (
        <Chip className={classes.chip} size="small" label={label} key={label} />
      ))}
    </Grid>
  )
)

export default FilterChips
