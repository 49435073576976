import React, {ReactNode} from 'react'
import {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import BaseSelect from '@material-ui/core/Select'

import FormControl from 'components/FormControl'
import InputLabel from 'components/InputLabel'
import MenuItem from 'components/MenuItem'
import Checkbox from 'components/Checkbox'
import ListItemText from 'components/ListItemText'
import Button from 'components/Button'
import {addTranslationHelpers} from 'utils/i18n'

const defaultRenderValue = (selected: string[]) => selected.join(', ')

interface FilterSelectFieldProps {
  value: string[]
  onChange: (event: React.ChangeEvent<{value: string[]}>) => void
  onFilterFocus: (value: string) => void
  id: string
  label: string
  options: {
    label: string
    value: string
  }[]
  className?: string
  renderValue?: (selected: string[]) => ReactNode
  disabled?: boolean
}

const FilterSelectField: FC<FilterSelectFieldProps> = flowMax(
  addDisplayName('FilterSelectField'),
  addProps(({id}) => ({
    labelId: `${id}-label`,
  })),
  addTranslationHelpers,
  ({
    label,
    id,
    options,
    labelId,
    value: currentValue,
    onChange,
    onFilterFocus,
    disabled,
    className,
    t,
    renderValue = defaultRenderValue,
  }) => (
    <FormControl variant="outlined" className={className}>
      <InputLabel id={labelId} htmlFor={id} shrink>
        {label}
      </InputLabel>
      <BaseSelect
        value={currentValue}
        onChange={
          onChange as (event: React.ChangeEvent<{value: unknown}>) => void
        }
        id={id}
        labelId={labelId}
        label={label}
        disabled={disabled}
        multiple
        displayEmpty
        renderValue={renderValue as (selected: unknown) => ReactNode}
        MenuProps={{
          variant: 'menu',
          getContentAnchorEl: null,
          id: `${id}-menu`,
        }}
      >
        {options.map(({label, value}) => (
          <MenuItem value={value} key={label}>
            <Checkbox checked={currentValue.includes(value)} />
            <ListItemText primary={label} />
            <Button
              onClick={(e) => {
                e.stopPropagation()
                onFilterFocus(value)
              }}
            >
              {t('worklist.filterFocus')}
            </Button>
          </MenuItem>
        ))}
      </BaseSelect>
    </FormControl>
  )
)

export default FilterSelectField
