/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {UpdateDocumentInput} from './globalTypes' // ====================================================
// GraphQL mutation operation: UpdateDocument
// ====================================================

import {deserializeUpdateDocumentInput} from './globalTypes'
import {serializeUpdateDocumentInput} from './globalTypes'
import {UpdateDocumentVariables as UpdateDocumentVariablesOrig} from '../types/UpdateDocument'
import {UpdateDocument as UpdateDocumentOrig} from '../types/UpdateDocument'
import {UpdateDocument_updateDocument as UpdateDocument_updateDocumentOrig} from '../types/UpdateDocument'
import {UpdateDocument_updateDocument_person as UpdateDocument_updateDocument_personOrig} from '../types/UpdateDocument'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {UpdateDocument_updateDocument_files as UpdateDocument_updateDocument_filesOrig} from '../types/UpdateDocument'
export interface UpdateDocument_updateDocument_files {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeUpdateDocument_updateDocument_files = (
  serialized: UpdateDocument_updateDocument_filesOrig
): UpdateDocument_updateDocument_files => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeUpdateDocument_updateDocument_files = (
  deserialized: UpdateDocument_updateDocument_files
): UpdateDocument_updateDocument_filesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface UpdateDocument_updateDocument_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdateDocument_updateDocument_person = (
  serialized: UpdateDocument_updateDocument_personOrig
): UpdateDocument_updateDocument_person => ({...serialized})
export const serializeUpdateDocument_updateDocument_person = (
  deserialized: UpdateDocument_updateDocument_person
): UpdateDocument_updateDocument_personOrig => ({...deserialized})
export interface UpdateDocument_updateDocument {
  __typename: 'Document'
  id: string
  documentType: string
  complete: boolean | null
  files: UpdateDocument_updateDocument_files[]
  updatedAt: Date
  notes: string | null
  person: UpdateDocument_updateDocument_person | null
}
export const deserializeUpdateDocument_updateDocument = (
  serialized: UpdateDocument_updateDocumentOrig
): UpdateDocument_updateDocument => ({
  ...serialized,
  files: serialized.files.map(deserializeUpdateDocument_updateDocument_files),
  updatedAt: parseDate(serialized.updatedAt),
  person:
    serialized.person != null
      ? deserializeUpdateDocument_updateDocument_person(serialized.person)
      : serialized.person,
})
export const serializeUpdateDocument_updateDocument = (
  deserialized: UpdateDocument_updateDocument
): UpdateDocument_updateDocumentOrig => ({
  ...deserialized,
  files: deserialized.files.map(serializeUpdateDocument_updateDocument_files),
  updatedAt: formatISO(deserialized.updatedAt),
  person:
    deserialized.person != null
      ? serializeUpdateDocument_updateDocument_person(deserialized.person)
      : deserialized.person,
})
export interface UpdateDocument {
  /**
   * Update document
   */
  updateDocument: UpdateDocument_updateDocument
}
export const deserializeUpdateDocument = (
  serialized: UpdateDocumentOrig
): UpdateDocument => ({
  ...serialized,
  updateDocument: deserializeUpdateDocument_updateDocument(
    serialized.updateDocument
  ),
})
export const serializeUpdateDocument = (
  deserialized: UpdateDocument
): UpdateDocumentOrig => ({
  ...deserialized,
  updateDocument: serializeUpdateDocument_updateDocument(
    deserialized.updateDocument
  ),
})
export interface UpdateDocumentVariables {
  document: UpdateDocumentInput
}
export const deserializeUpdateDocumentVariables = (
  serialized: UpdateDocumentVariablesOrig
): UpdateDocumentVariables => ({
  ...serialized,
  document: deserializeUpdateDocumentInput(serialized.document),
})
export const serializeUpdateDocumentVariables = (
  deserialized: UpdateDocumentVariables
): UpdateDocumentVariablesOrig => ({
  ...deserialized,
  document: serializeUpdateDocumentInput(deserialized.document),
})
