/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {TriageInput, NjmmisCheckResult} from './globalTypes' // ====================================================
// GraphQL mutation operation: UpdateTriage
// ====================================================

import {deserializeTriageInput} from './globalTypes'
import {serializeTriageInput} from './globalTypes'
import {UpdateTriageVariables as UpdateTriageVariablesOrig} from '../types/UpdateTriage'
import {UpdateTriage as UpdateTriageOrig} from '../types/UpdateTriage'
import {UpdateTriage_updateTriage as UpdateTriage_updateTriageOrig} from '../types/UpdateTriage'
import {UpdateTriage_updateTriage_assignedTo as UpdateTriage_updateTriage_assignedToOrig} from '../types/UpdateTriage'
import {deserializeNjmmisCheckResult} from 'graphql/converters'
import {serializeNjmmisCheckResult} from 'graphql/converters'
import formatISO from 'date-fns/formatISO'
import {parseDate} from 'utils/form/fieldTypes'
import {formatISODate} from 'utils/date'
import {UpdateTriage_updateTriage_account as UpdateTriage_updateTriage_accountOrig} from '../types/UpdateTriage'
export interface UpdateTriage_updateTriage_account {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
  timeOfService: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location: string | null
  insuranceType: string
  status: string
  createdAt: Date
  updatedAt: Date
  notes: string | null
  accountAmount: number | null
  njmmisCheck: string | null
  previousInsurance: string | null
  invoiceMonth: string | null
  invoiceYear: string | null
  dischargeDate: Date | null
  isScheduled: boolean
}
export const deserializeUpdateTriage_updateTriage_account = (
  serialized: UpdateTriage_updateTriage_accountOrig
): UpdateTriage_updateTriage_account => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
})
export const serializeUpdateTriage_updateTriage_account = (
  deserialized: UpdateTriage_updateTriage_account
): UpdateTriage_updateTriage_accountOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
})
export interface UpdateTriage_updateTriage_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeUpdateTriage_updateTriage_assignedTo = (
  serialized: UpdateTriage_updateTriage_assignedToOrig
): UpdateTriage_updateTriage_assignedTo => ({...serialized})
export const serializeUpdateTriage_updateTriage_assignedTo = (
  deserialized: UpdateTriage_updateTriage_assignedTo
): UpdateTriage_updateTriage_assignedToOrig => ({...deserialized})
export interface UpdateTriage_updateTriage {
  __typename: 'Triage'
  id: string
  account: UpdateTriage_updateTriage_account
  createdAt: Date
  updatedAt: Date
  status: string
  closeReason: string | null
  closeComment: string | null
  assignedTo: UpdateTriage_updateTriage_assignedTo | null
}
export const deserializeUpdateTriage_updateTriage = (
  serialized: UpdateTriage_updateTriageOrig
): UpdateTriage_updateTriage => ({
  ...serialized,
  account: deserializeUpdateTriage_updateTriage_account(serialized.account),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  assignedTo:
    serialized.assignedTo != null
      ? deserializeUpdateTriage_updateTriage_assignedTo(serialized.assignedTo)
      : serialized.assignedTo,
})
export const serializeUpdateTriage_updateTriage = (
  deserialized: UpdateTriage_updateTriage
): UpdateTriage_updateTriageOrig => ({
  ...deserialized,
  account: serializeUpdateTriage_updateTriage_account(deserialized.account),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  assignedTo:
    deserialized.assignedTo != null
      ? serializeUpdateTriage_updateTriage_assignedTo(deserialized.assignedTo)
      : deserialized.assignedTo,
})
export interface UpdateTriage {
  /**
   * Update existing triage
   */
  updateTriage: UpdateTriage_updateTriage
}
export const deserializeUpdateTriage = (
  serialized: UpdateTriageOrig
): UpdateTriage => ({
  ...serialized,
  updateTriage: deserializeUpdateTriage_updateTriage(serialized.updateTriage),
})
export const serializeUpdateTriage = (
  deserialized: UpdateTriage
): UpdateTriageOrig => ({
  ...deserialized,
  updateTriage: serializeUpdateTriage_updateTriage(deserialized.updateTriage),
})
export interface UpdateTriageVariables {
  triage: TriageInput
}
export const deserializeUpdateTriageVariables = (
  serialized: UpdateTriageVariablesOrig
): UpdateTriageVariables => ({
  ...serialized,
  triage: deserializeTriageInput(serialized.triage),
})
export const serializeUpdateTriageVariables = (
  deserialized: UpdateTriageVariables
): UpdateTriageVariablesOrig => ({
  ...deserialized,
  triage: serializeTriageInput(deserialized.triage),
})
