import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addHandlers,
  addWrapper,
  addProps,
} from 'ad-hok'

import addRouteParams from 'utils/addRouteParams'
import {addLoadingIndicator} from 'utils/dataLoading'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import {addTranslationHelpers} from 'utils/i18n'
import {addClasses, makeClasses, sharedStyles} from 'theme'
import Form from 'components/Form'
import FormSection from 'components/FormSection'
import ReadOnlyTextField from 'components/ReadOnlyTextField'
import TextField from 'components/TextField'
import DateField from 'components/DateField'
import TimeField from 'components/TimeField'
import SelectField from 'components/SelectField'
import {addAccountQuery, addUpdateAccountMutation} from 'graphql/generated'
import {getEditAccountFormSchema} from 'components/EditAccountForm/schema'
import FormRow from 'components/FormRow'
import Grid from 'components/Grid'
import AccountFormLeftColumn from 'components/EditAccountForm/AccountFormLeftColumn'
import AccountStatusUpdateSelect from 'components/AccountStatusUpdateSelect'
import CurrencyField from 'components/CurrencyField'
import MultilineTextField from 'components/MultilineTextField'
import OptionalBooleanField from 'components/OptionalBooleanField'
import {Assert, SchemaDoesntHaveExtraFields} from 'utils/form/typeHelpers'
import {addFacilities, addDepartments} from 'utils/configContext'

const classes = makeClasses((theme) => ({
  container: sharedStyles.formColumnsContainer,
  formColumnContainer: {
    ...sharedStyles.formColumnContainer,
    paddingBottom: theme.spacing(4),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  rightColumnContainer: {
    ...sharedStyles.formRightColumnContainer,
    width: 400,
    paddingTop: 38,
  },
  statusSelect: {
    width: 350,
    marginBottom: theme.spacing(4),
  },
}))

type Check = Assert<
  SchemaDoesntHaveExtraFields<
    ReturnType<typeof getEditAccountFormSchema>,
    typeof addUpdateAccountMutation
  >
>

const EditAccountForm: FC = flowMax(
  addDisplayName('EditAccountForm'),
  addRouteParams<{id: string}>(),
  addAccountQuery({
    variables: ({id}) => ({id}),
  }),
  addLoadingIndicator({}),
  addAppSnackbarContext,
  addTranslationHelpers,
  addHandlers({
    onUpdateSuccess: ({showSnackbarMessage, t}) => () => {
      showSnackbarMessage(t('accountForm.updatedAccount'))
    },
  }),
  addUpdateAccountMutation({}),
  addFacilities,
  addDepartments,
  addProps(
    ({facilities, departments}) => ({
      schema: getEditAccountFormSchema({facilities, departments}),
    }),
    ['facilities', 'departments']
  ),
  addClasses(classes),
  addWrapper(
    (
      render,
      {mutateUpdateAccount, onUpdateSuccess, account, schema, classes}
    ) => (
      <Form
        name="accountForm"
        mutate={mutateUpdateAccount}
        schema={schema}
        initialValues={{account}}
        onSubmitSuccess={onUpdateSuccess}
        shouldResetFormOnInitialValuesChange
        className={classes.form}
      >
        {render()}
      </Form>
    )
  ),
  ({account, classes}) => (
    <Grid container className={classes.container} direction="row">
      <AccountFormLeftColumn account={account} />
      <Grid item className={classes.formColumnContainer}>
        <FormSection labelTranslationKey="accountData">
          <ReadOnlyTextField name="account.id" />
          <TextField name="account.hospitalAccountId" />
          <DateField name="account.dateOfService" />
          <FormRow variant="twoColumn_1.5_1_true">
            <TimeField name="account.timeOfService" lazyIcon />
            <SelectField name="account.serviceTimeZone" />
          </FormRow>
          <OptionalBooleanField name="account.isScheduled" />
          <DateField name="account.dischargeDate" />
          <SelectField name="account.facility" />
          <SelectField name="account.department" />
          <TextField name="account.location" />
          <SelectField name="account.insuranceType" />
          <SelectField name="account.njmmisCheck" />
          <TextField name="account.previousInsurance" />
          <CurrencyField name="account.accountAmount" />
          <FormRow variant="twoColumn_1_1">
            <SelectField name="account.invoiceMonth" lazyIcon />
            <SelectField name="account.invoiceYear" />
          </FormRow>
        </FormSection>
      </Grid>
      <Grid item className={classes.rightColumnContainer}>
        <AccountStatusUpdateSelect
          className={classes.statusSelect}
          account={account}
        />
        <MultilineTextField rows={5} rowsMax={20} name="account.notes" />
      </Grid>
    </Grid>
  )
)

export default EditAccountForm
