import React, {FC, forwardRef, ComponentProps} from 'react'
import {Link as BaseLink} from 'react-router-dom'
import cx from 'classnames'
import {flowMax, addDisplayName} from 'ad-hok'

import {makeClasses, addClasses} from 'theme'

export const highlightStyle = {
  color: '#2f80ed', // Blue 1
  textDecoration: 'underline',
  fontWeight: 500,
}

const classes = makeClasses((/*theme*/) => ({
  highlight: highlightStyle,
}))

type RefType = ComponentProps<typeof BaseLink>['ref']
type RefValueType = HTMLAnchorElement

interface LinkWithForwardedRefProps extends ComponentProps<typeof BaseLink> {
  forwardedRef: RefType
  highlight?: boolean
}

const LinkWithForwardedRef: FC<LinkWithForwardedRefProps> = flowMax(
  addDisplayName('Link'),
  addClasses(classes),
  ({className, classes, forwardedRef, highlight, ...props}) => (
    <BaseLink
      ref={forwardedRef}
      className={cx(highlight && classes.highlight, className)}
      {...props}
    />
  )
)

interface LinkProps extends ComponentProps<typeof BaseLink> {
  highlight?: LinkWithForwardedRefProps['highlight']
}

const Link = forwardRef<RefValueType, LinkProps>((props, ref) => (
  <LinkWithForwardedRef forwardedRef={ref} {...props} />
))

export default Link
