import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import {generatePath, Outlet} from 'react-router'
import {omit} from 'lodash/fp'

import addRouteParams from 'utils/addRouteParams'
import {addLoadingIndicator} from 'utils/dataLoading'
import {addTranslationHelpers} from 'utils/i18n'
import {makeClasses, addClasses} from 'theme'
import LinkTab from 'components/LinkTab'
import Tabs from 'components/Tabs'
import {addPersonQuery} from 'graphql/generated'
import {addPersonContextProvider, addPersonContext} from './personContext'
import addActiveRouteIndex from 'utils/addActiveRouteIndex'
import addDialogState from 'utils/addDialogState'
import {PERSON_QUERY} from 'graphql/queries'
import TaskDialog, {addTaskDialogContextProvider} from 'components/TaskDialog'
import AddButton from 'components/AddButton'
import Container from 'components/Container'
import PersonItem from 'components/PersonItem'
import {
  addShowDocumentFiles,
  addShowAccounts,
  addShowTriage,
} from 'utils/configContext'

const classes = makeClasses((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
  },
  addTaskButtonContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
  },
  addTaskButton: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(3),
    pointerEvents: 'auto',
    borderRadius: 24,
    backgroundColor: '#f60157',
  },
}))

export const personDetailPath = '/person/:id'

const TABS = {
  applications: {
    path: 'applications',
  },
  benefitOutcomes: {
    path: 'benefit-history',
  },
  accounts: {
    path: 'accounts',
  },
  triages: {
    path: 'triages',
  },
  files: {
    path: 'files',
  },
  tasks: {
    path: 'tasks',
  },
} as const

export const generatePersonDetailTabPath = ({
  id,
  tabPath,
}: {
  id: string
  tabPath: typeof TABS[keyof typeof TABS]['path']
}) => `${generatePath(personDetailPath, {id})}/${tabPath}`

export const getPersonDetailTabSubpath = (name: keyof typeof TABS) =>
  TABS[name].path

const getTabsWithAbsolutePath = ({
  id,
  showDocumentFiles,
  showAccounts,
  showTriage,
}: {
  id: string
  showDocumentFiles: boolean
  showAccounts: boolean
  showTriage: boolean
}) =>
  Object.entries(
    omit(
      [
        ...(showDocumentFiles ? [] : ['files']),
        ...(showAccounts ? [] : ['accounts']),
        ...(showTriage ? [] : ['triages']),
      ],
      TABS
    ) as typeof TABS
  ).map(([name, {path}]) => ({
    name,
    absolutePath: generatePersonDetailTabPath({id, tabPath: path}),
  }))

const PersonDetailTabs: FC = flowMax(
  addDisplayName('PersonDetailTabs'),
  addPersonContext,
  addShowDocumentFiles,
  addShowAccounts,
  addShowTriage,
  addProps(({person: {id}, showDocumentFiles, showAccounts, showTriage}) => ({
    tabsWithAbsolutePaths: getTabsWithAbsolutePath({
      id,
      showDocumentFiles,
      showAccounts,
      showTriage,
    }),
  })),
  addActiveRouteIndex(({tabsWithAbsolutePaths}) =>
    tabsWithAbsolutePaths.map((tab) => tab.absolutePath)
  ),
  addTranslationHelpers,
  ({tabsWithAbsolutePaths, activeRouteIndex, t}) => (
    <Tabs variant="fullWidth" value={activeRouteIndex}>
      {tabsWithAbsolutePaths.map(({absolutePath, name}) => (
        <LinkTab
          label={t(`personDetail.tabs.${name}`)}
          to={absolutePath}
          key={name}
        />
      ))}
    </Tabs>
  )
)

const AddTaskButton: FC = flowMax(
  addDisplayName('AddTaskButton'),
  addDialogState,
  addPersonContext,
  addProps(
    ({person: {id}}) => ({
      refetchQueries: [
        {
          query: PERSON_QUERY,
          variables: {id},
        },
      ],
    }),
    ['person.id']
  ),
  addClasses(classes),
  addTranslationHelpers,
  addTaskDialogContextProvider,
  ({showDialog, isShowingDialog, hideDialog, refetchQueries, classes, t}) => (
    <>
      <Container className={classes.addTaskButtonContainer}>
        <AddButton
          variant="contained"
          onClick={showDialog}
          className={classes.addTaskButton}
        >
          {t('personDetail.task')}
        </AddButton>
      </Container>
      <TaskDialog
        open={isShowingDialog}
        onClose={hideDialog}
        refetchQueries={refetchQueries}
      />
    </>
  )
)

const PersonDetail: FC = flowMax(
  addDisplayName('PersonDetail'),
  addRouteParams<{id: string}>(),
  addPersonQuery({
    variables: ({id}) => ({id}),
  }),
  addLoadingIndicator({}),
  addPersonContextProvider,
  addClasses(classes),
  ({person, classes}) => (
    <div className={classes.container}>
      <PersonItem person={person} noLink detailView />
      <PersonDetailTabs />
      <Outlet />
      <AddTaskButton />
    </div>
  )
)

export default PersonDetail
