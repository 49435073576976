import {format} from 'date-fns/fp'
import {saveAs as saveFileAs} from 'file-saver'

interface CsvRowObject {
  [key: string]: string | string[] | boolean | null | undefined | number
}

export const toCsvContent = (rowObjects: CsvRowObject[]): string => {
  if (!rowObjects.length) return ''
  const keys = Object.keys(rowObjects[0]) as (keyof CsvRowObject)[]
  const separator = ','
  return `${keys.join(separator)}\n${rowObjects
    .map((rowObject) =>
      keys
        .map((key) => {
          let cellValue = rowObject[key]
          if (cellValue == null) cellValue = ''
          cellValue = `${cellValue}`
          cellValue = cellValue.replace(/"/g, '""')
          if (cellValue.search(/("|,|\n)/g) >= 0) cellValue = `"${cellValue}"`
          return cellValue
        })
        .join(separator)
    )
    .join('\n')}`
}

export const saveCsvFileAs = (csvContent: string, filename: string) => {
  const csvBlob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'})
  saveFileAs(csvBlob, filename)
}

export const getCsvFilename = (baseName: string): string =>
  `${baseName}_${format('MMddyyyy_HHmm')(new Date())}.csv`
